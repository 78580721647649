import React, { Component } from "react";
import Fun from "../../../lib/functions";
import OTP from "./login/otp";

class NewLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      isLoggedIn: true,
    };
  }

  componentDidMount() {
    Fun.addCSS("/uni/css/loginstyle.css");

    this.setState({ loaded: true, content: this.props.content });
  }

  render() {
    if (this.state.loaded) {
      return (
        <div
          style={{
            height: "100%",
            backgroundSize: "cover",
            boxShadow: "inset 0 0 0 2000px rgba(0, 0, 0, 0.1)",
          }}
        >
          <div
            style={{
              minHeight: "calc(100% - 150px)",
              width: "100%",
              display: "table",
            }}
          >
            <div
              style={{
                display: "table-cell",
                height: "100%",
                verticalAlign: "middle",
              }}
              className="text-white"
            >
              {this.state.content ? this.state.content : ""}
            </div>
          </div>
        </div>
      );
    }
    return "";
  }
}

export default NewLogin;
