import React, { Component } from "react";
import Fun from "../../../lib/functions";
import GridScripts from "../js/script";

class Grid extends Component {
  state = {
    data: [],
    loaded: false,
    colsTemplateCount: 12,
    colsFormCount: 12,
  };

  constructor(props) {
    super(props);

    this.createHeaders = this.createHeaders.bind(this);
    this.createBody = this.createBody.bind(this);
    let builder = this.props.builder.state;
    let templateConfig = builder.module.templateConfig;
    if (templateConfig.events) {
      Object.keys(templateConfig.events).map((itm, idx) => {
        this[itm] = templateConfig.events[itm].bind(this);
      });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.action) {
      return false;
    }
    return true;
  }

  componentDidMount() {
    let builder = this.props.builder.state;
    let templateConfig = builder.module.templateConfig;
    let useCard =
      typeof templateConfig.useCard !== "undefined"
        ? templateConfig.useCard
        : true;
    let templateTop = templateConfig.templateTop
      ? templateConfig.templateTop
      : () => {};
    this["templateTop"] = templateTop.bind(this);
    let templateBottom = templateConfig.templateBottom
      ? templateConfig.templateBottom
      : () => {};
    this["templateBottom"] = templateBottom.bind(this);
    let templateLeft = templateConfig.templateLeft
      ? templateConfig.templateLeft
      : () => {};
    this["templateLeft"] = templateLeft.bind(this);
    let templateRight = templateConfig.templateRight
      ? templateConfig.templateRight
      : () => {};
    this["templateRight"] = templateRight.bind(this);
    let cardTemplateTop = templateConfig.cardTemplateTop
      ? templateConfig.cardTemplateTop
      : () => {};
    this["cardTemplateTop"] = cardTemplateTop.bind(this);
    let cardTemplateBottom = templateConfig.cardTemplateBottom
      ? templateConfig.cardTemplateBottom
      : () => {};
    this["cardTemplateBottom"] = cardTemplateBottom.bind(this);
    let content = templateConfig.content ? templateConfig.content : null;
    if (content) {
      this["content"] = content.bind(this);
    }
    let panelTop = templateConfig.panelTop ? templateConfig.panelTop : () => {};
    this["panelTop"] = panelTop.bind(this);
    let panelBottom = templateConfig.panelBottom
      ? templateConfig.panelBottom
      : () => {};
    this["panelBottom"] = panelBottom.bind(this);
    let panelLeft = templateConfig.panelLeft
      ? templateConfig.panelLeft
      : () => {};
    this["panelLeft"] = panelLeft.bind(this);
    let panelRight = templateConfig.panelRight
      ? templateConfig.panelRight
      : () => {};
    this["panelRight"] = panelRight.bind(this);
    let header = templateConfig.header ? templateConfig.header : () => {};
    this["header"] = header.bind(this);

    let emptyFolder = templateConfig.gridConfig.emptyFolder
      ? templateConfig.gridConfig.emptyFolder
      : () => {};
    this["emptyFolder"] = emptyFolder.bind(this);

    let gridConfig = templateConfig.gridConfig;

    let colsTemplateCount = templateConfig.colsTemplateCount
      ? templateConfig.colsTemplateCount
      : this.state.colsTemplateCount;
    let colsFormCount = templateConfig.colsFormCount
      ? templateConfig.colsFormCount
      : this.state.colsFormCount;

    this.setState({
      data: this.props.data,
      rawData: this.props.rawData,
      loaded: true,
      gridConfig: gridConfig,
      colsTemplateCount: colsTemplateCount,
      colsFormCount: colsFormCount,
      useCard: useCard,
    });

    if (this.componentDidMountEvent) {
      this.componentDidMountEvent();
    }

    GridScripts();
  }

  createHeaders() {
    let headers = [];
    let data = this.state.data[0];

    if (this.state.gridConfig.showTableHeaders != false) {
      if (data) {
        Object.keys(data).map((itm, idx) => {
          if (data[itm].showPropertyOnTemplate) {
            let attributes = [];
            if (this.state.gridConfig) {
              if (this.state.gridConfig.headers) {
                if (this.state.gridConfig.headers[itm]) {
                  if (this.state.gridConfig.headers[itm]["attributes"]) {
                    if (
                      typeof this.state.gridConfig.headers[itm][
                        "attributes"
                      ] === "function"
                    ) {
                      attributes = this.state.gridConfig.headers[itm][
                        "attributes"
                      ](data, itm);
                    } else {
                      attributes =
                        this.state.gridConfig.headers[itm]["attributes"];
                    }
                  }
                }
              }
            }

            headers.push(
              <th scope="col" key={Fun.uuid()} {...attributes}>
                {data[itm].field.label}
              </th>
            );
          }
        });

        return (
          <thead key={Fun.uuid()}>
            <tr key={Fun.uuid()}>{headers}</tr>
          </thead>
        );
      }
    }
    return "";
  }

  createBody() {
    let rows = [];

    let data = this.state.data;

    data.map((itm, idx) => {
      let cells = [];

      Object.keys(itm).map((citm, cidx) => {
        if (itm[citm].showPropertyOnTemplate) {
          let itmComponent = itm[citm].component;
          let attributes = [];
          if (this.state.gridConfig) {
            if (this.state.gridConfig.cellTemplates) {
              if (this.state.gridConfig.cellTemplates[citm]) {
                itmComponent = this.state.gridConfig.cellTemplates[citm](
                  itmComponent,
                  itm,
                  this
                );
              }
            }
            if (this.state.gridConfig.cell) {
              if (this.state.gridConfig.cell[citm]) {
                if (this.state.gridConfig.cell[citm]["attributes"]) {
                  attributes = this.state.gridConfig.cell[citm]["attributes"];
                }
              }
            }
          }

          cells.push(
            <td key={Fun.uuid()} {...attributes}>
              {itmComponent}
            </td>
          );
        }
      });

      rows.push(<tr key={Fun.uuid()}>{cells}</tr>);
    });

    return <tbody key={Fun.uuid()}>{rows}</tbody>;
  }

  render() {
    if (this.state.loaded) {
      return (
        <React.Fragment>
          {this.header()}
          {this.panelTop()}
          <div className="container-fluid">
            <div className="row">
              {this.panelLeft()}
              <div className={"col-" + this.state.colsTemplateCount}>
                <div className="row">
                  {this.templateLeft()}
                  <div className={"col-" + this.state.colsFormCount}>
                    {this.templateTop()}
                    {this.state.useCard != false ? (
                      <div className="card">
                        <div
                          className={
                            this.state.gridConfig.cardBodyClass
                              ? "card-body " +
                                this.state.gridConfig.cardBodyClass
                              : "card-body"
                          }
                        >
                          {this.cardTemplateTop()}

                          {this.content ? (
                            this.content()
                          ) : (
                            <table className="table table-hover">
                              {this.createHeaders()}
                              {this.createBody()}
                            </table>
                          )}
                          {this.state.data.length <= 0
                            ? this.emptyFolder()
                            : ""}
                          {this.cardTemplateBottom()}
                        </div>
                      </div>
                    ) : (
                      <React.Fragment>
                        {this.cardTemplateTop()}
                        {this.content ? (
                          this.content()
                        ) : (
                          <table className="table table-hover">
                            {this.createHeaders()}
                            {this.createBody()}
                          </table>
                        )}
                        {this.state.data.length <= 0 ? this.emptyFolder() : ""}
                        {this.cardTemplateBottom()}
                      </React.Fragment>
                    )}
                    {this.templateBottom()}
                  </div>
                  {this.templateRight()}
                </div>
              </div>
              {this.panelRight()}
            </div>
          </div>
          {this.panelBottom()}
        </React.Fragment>
      );
    } else {
      return "loading...";
    }
  }
}

export default Grid;
