import React, { Component } from "react";
import Fun from "../../../lib/functions";
import UniFun from "../functions";

const UniVideoPresentation = {
  auth: "",
  views: {
    detail: {
      auth: "",
      apis: {
        view: {
          data: { data: [{}] },
          parameters: {
            headers: {},
            replace: {},
          },
          formatter: function (data) {
            return UniVideoPresentationFormatter(data);
          },
        },
      },
      template: "form",
      templateConfig: {
        events: {
          onSubmit: function (e) {
            e.preventDefault();

            if (
              Fun.validate(this.props.builder.validator, Fun.formDataToJSON(e))
            ) {
              let submittedData = Fun.getSubmitJSON(e);
              let file = JSON.parse(submittedData["file"]);

              submittedData["file"] = file[Object.keys(file)[0]];
              window["modal2"].setState({ data: submittedData });

              if (window["modal2"].state.callback) {
                window["modal2"].state.callback(submittedData);
              } else {
                if (window["modal2"].state.targetComponent) {
                  window["modal2"].state.targetComponent.setState({
                    data: JSON.stringify({ submittedData }),
                  });
                }
              }

              window["modal2"].setState({ show: false, overflowY: "auto" });
            }
          },
        },
        includeProperties: ["title", "file", "recording", "isAudio"],
      },
      pk: "_id",
      properties: UniVideoPresentationProperties({ editable: true }),
    },
  },
};

export default UniVideoPresentation;

function UniVideoPresentationProperties(input) {
  let editable = input.editable;

  return {
    _id: {
      alias: "_id",
      control: "hidden",
      editable: editable,
    },
    title: {
      alias: "title",
      control: "text",
      label: "Title",
      placeholder: "Type video presentation title",
      validations: "required",
      editable: editable,
      attributes: {
        className: "form-control",
      },
    },
    recording: {
      alias: "recording",
      control: "hidden",
      label: "",
      editable: editable,
      attributes: {
        className: "form-control",
      },
    },
    isAudio: {
      alias: "isAudio",
      control: "hidden",
      label: "",
      editable: editable,
      attributes: {
        className: "form-control",
      },
    },
    file: {
      alias: "file",
      control: "dropzone",
      label: "Attach file",
      editable: editable,
      customContentLayout: function () {
        let files = JSON.parse(this.state.defaultValue);

        let content = [];

        Object.keys(files).map((itm, idx) => {
          content.push(
            UniFun.trainingNodeFileUI(files[itm], this.removeFile, itm)
          );
        });

        this.state.content?.map((itm, idx) => {
          if (typeof files[itm.key] === "undefined") {
            content.push(itm);
          }
        });
        return (
          <React.Fragment>
            {this.state.placeholder()}
            {content}
            {/* {content.length > 0 ? "" : this.state.placeholder()} */}
          </React.Fragment>
        );
      },

      useCustomLayout: true,
      config: {
        placeholder: function () {
          return (
            <div className="p-3">
              <strong>
                Drag & drop here or{" "}
                <a
                  href="javascript:void(0);"
                  className="text-primary"
                  onClick={this.onTargetClick}
                >
                  <strong>browse file</strong>
                </a>{" "}
                to attach
              </strong>
              <br></br>
              <span className="text-dark">
                Maximum size:50MB - File type supported: mp4
              </span>
            </div>
          );
        },
      },
      events: {
        removeFile: function (id) {
          let defaultValue = {};

          Object.keys(JSON.parse(this.state.defaultValue)).map((itm, idx) => {
            if (itm != id) {
              defaultValue[itm] = JSON.parse(this.state.defaultValue)[itm];
            }
          });

          let content = [];
          this.state.content?.map((itm, idx) => {
            if (itm.key != id) {
              content.push(itm);
            }
          });

          this.setState({
            defaultValue: JSON.stringify(defaultValue),
            content: content,
          });

          return;
        },
        onDrop: function (files) {
          this.setState({ defaultValue: JSON.stringify({}), content: [] });

          if (files.length > 1) {
            files = [files[0]];
          }

          if (files[0].type == "video/mp4") {
            UniFun.fileProgress({
              control: this,
              files: files,
              url: "/agenda-file",
              type: "training",
            });
          } else {
            Fun.createNotification({
              message: "Only mp4 files are allowed to upload",
              type: "danger",
            });
          }
        },
      },
      attributes: {
        className: "border rounded  dropzone-trainings",
      },
    },
  };
}

function UniVideoPresentationFormatter(data) {
  let formattedData = [];
  if (data) {
    data.data.map((itm, idx) => {
      let formattedRecord = {};
      formattedRecord["_id"] = itm._id;
      formattedRecord["title"] = itm.title;
      formattedRecord["recording"] = itm.recording ? itm.recording : false;
      formattedRecord["isAudio"] = itm.isAudio ? itm.isAudio : false;
      formattedRecord["file"] = itm._id
        ? {
            [itm._id]: {
              _id: itm._id,
              title: itm.title,
              mimetype: itm.mimetype,
            },
          }
        : {};
      formattedData.push(formattedRecord);
    });

    return formattedData;
  }
}
