import React, { useEffect } from "react";
import Themes from "./themes/themes";
import Builder from "./lib/builder";
import Fun from "./lib/functions";
import { useHistory } from "react-router-dom";
import stringSimilarity from "string-similarity";
import { matchPath } from "react-router";

const Index = Themes[window["c_theme"]]["index"];
const RouterMap = Themes[window["c_theme"]]["routermap"];

const Root = () => {
  const history = useHistory();

  let hasHistory = false;

  useEffect(() => {
    if (!hasHistory) {
      if (RouterMap) {
        // let bestMatch = stringSimilarity.findBestMatch(
        //   history.location.pathname,
        //   Object.keys(RouterMap)
        // ).bestMatch.target;
        if (window["modal"]) {
          window["modal"].setState({ show: false, overflowY: "auto" });
        }

        const routes = Object.keys(RouterMap).filter((item) => {
          return matchPath(history.location.pathname, {
            path: item,
            exact: true,
          });
        });

        let bestMatch = "";
        if (routes.length > 1) {
          bestMatch = routes.filter((itm, idx) => {
            return itm == history.location.pathname;
          });
        } else {
          bestMatch = routes[0];
        }

        Object.keys(RouterMap[bestMatch]).map((itm, idx) => {
          let parameters = null;
          if (RouterMap[bestMatch][itm]) {
            if (RouterMap[bestMatch][itm]["parameters"]) {
              parameters = RouterMap[bestMatch][itm]["parameters"]();
            }

            window["index"].setState({
              [itm]: [
                <Builder
                  module={RouterMap[bestMatch][itm]["module"]}
                  view={
                    typeof RouterMap[bestMatch][itm]["view"] === "function"
                      ? RouterMap[bestMatch][itm]["view"]()
                      : RouterMap[bestMatch][itm]["view"]
                  }
                  key={Fun.uuid()}
                  parameters={parameters}
                  moduleParams={RouterMap[bestMatch][itm]["moduleParams"]}
                  ref={(refComponent) => {
                    window[itm + "ref"] = refComponent;
                  }}
                />,
              ],
            });
          } else {
            window["index"].setState({
              [itm]: "",
            });
          }
        });
      }
    }

    return history.listen((location) => {
      hasHistory = true;
      // let bestMatch = stringSimilarity.findBestMatch(
      //   history.location.pathname,
      //   Object.keys(RouterMap)
      // ).bestMatch.target;

      if (window["modal"]) {
        window["modal"].setState({ show: false, overflowY: "auto" });
      }

      const routes = Object.keys(RouterMap).filter((item) => {
        return matchPath(history.location.pathname, {
          path: item,
          exact: true,
        });
      });
      let bestMatch = "";
      if (routes.length > 1) {
        bestMatch = routes.filter((itm, idx) => {
          return itm == history.location.pathname;
        });
      } else {
        bestMatch = routes[0];
      }

      if (RouterMap) {
        Object.keys(RouterMap[bestMatch]).map((itm, idx) => {
          let parameters = null;
          if (RouterMap[bestMatch][itm]) {
            if (RouterMap[bestMatch][itm]["parameters"]) {
              parameters = RouterMap[bestMatch][itm]["parameters"]();
            }

            window["index"].setState({
              [itm]: [
                <Builder
                  module={RouterMap[bestMatch][itm]["module"]}
                  view={
                    typeof RouterMap[bestMatch][itm]["view"] === "function"
                      ? RouterMap[bestMatch][itm]["view"]()
                      : RouterMap[bestMatch][itm]["view"]
                  }
                  key={Fun.uuid()}
                  parameters={parameters}
                  moduleParams={RouterMap[bestMatch][itm]["moduleParams"]}
                  ref={(refComponent) => {
                    window[itm + "ref"] = refComponent;
                  }}
                />,
              ],
            });
          } else {
            window["index"].setState({
              [itm]: "",
            });
          }
        });
      }
    });
  }, [history]);

  return (
    <Index
      key={Fun.uuid()}
      ref={(refComponent) => {
        window["index"] = refComponent;
      }}
    ></Index>
  );
};

export default Root;
