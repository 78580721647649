import moment from "moment";
import React, { Component } from "react";

let timer = null;
let intervalTimer = null;

class Countdowntimer extends Component {
  state = {
    name: "",
    id: "",
    placeholder: "",
    attributes: "",
    value: "",
    defaultValue: "",
    loaded: false,
    editable: true,
    component: null,
    attributes: "",
    events: {},
    customLayout: null,
    useCustomLayout: true,
    editable: true,
  };

  constructor(props) {
    super(props);

    let events = {};
    this.onTimesUp = function () {};
    if (this.props.field.events) {
      Object.keys(this.props.field.events).map((itm, idx) => {
        this[itm] = this.props.field.events[itm];
        this[itm] = this[itm].bind(this);
        events[itm] = this[itm];
      });
    }

    let defaultValue = { days: 0, hours: 0, minutes: 0, seconds: 0 };
    let remainingTimeInSeconds = 0;
    if (this.props.value) {
      defaultValue = this.props.value;
    }

    let daysInSeconds = defaultValue.days
      ? parseFloat(defaultValue.days) * 86400
      : 0;
    let hoursInSeconds = defaultValue.hours
      ? parseFloat(defaultValue.hours) * 3600
      : 0;
    let minutesInSeconds = defaultValue.minutes
      ? parseFloat(defaultValue.minutes) * 60
      : 0;
    let seconds = defaultValue.seconds ? parseFloat(defaultValue.seconds) : 0;
    remainingTimeInSeconds =
      daysInSeconds + hoursInSeconds + minutesInSeconds + seconds;

    this.countDown = this.countDown.bind(this);
    this.intervalCountDown = this.intervalCountDown.bind(this);
    this.display = this.display.bind(this);

    let intervalEvery = 0;
    this.intervalFun = function () {};

    if (this.props.field.config) {
      if (this.props.field.config.intervalFun) {
        this.intervalFun = this.props.field.config.intervalFun.bind(this);
      }
      if (this.props.field.config.intervalEvery) {
        intervalEvery = this.props.field.config.intervalEvery;
      }
    }

    this.state = {
      events: events,
      intervalEvery: intervalEvery,
      editable:
        this.props.field.editable != null
          ? this.props.field.editable
          : this.state.editable,
      remainingTimeInSeconds: remainingTimeInSeconds,
      defaultValue: this.props.value ? this.props.value : defaultValue,
    };
  }

  toSeconds() {}

  intervalCountDown() {
    let counter = this;

    if (this.intervalFun && this.state.intervalEvery > 0) {
      intervalTimer = setInterval(function () {
        if (counter.state.remainingTimeInSeconds <= 0) {
          clearInterval(intervalTimer);
        } else {
          counter.intervalFun();
        }
      }, counter.state.intervalEvery);
    }
  }

  countDown() {
    let counter = this;
    timer = setInterval(function () {
      if (counter.state.remainingTimeInSeconds <= 0) {
        clearInterval(timer);

        try {
          counter.onTimesUp();
        } catch (e) {}
      } else {
        let remainingTimeInSeconds = counter.state.remainingTimeInSeconds - 1;

        counter.setState({ remainingTimeInSeconds: remainingTimeInSeconds });
      }
    }, 1000);
  }

  componentDidMount() {
    this.setState({
      attributes: this.props.field.attributes,
      name: this.props.field.alias,
      placeholder: this.props.field.placeholder
        ? this.props.field.placeholder
        : "",
      editable:
        this.props.field.editable != null
          ? this.props.field.editable
          : this.state.editable,
      customLayout: this.props.field.customLayout,
      countdown: "",
      useCustomLayout:
        typeof this.props.field.useCustomLayout !== "undefined"
          ? this.props.field.useCustomLayout
          : true,
    });
    try {
      clearInterval(intervalTimer);
      clearInterval(timer);
    } catch (e) {}

    this.countDown();
    this.intervalCountDown();
  }

  display() {
    let seconds = Number(this.state.remainingTimeInSeconds);
    var d = Math.floor(seconds / (3600 * 24));
    var h = Math.floor((seconds % (3600 * 24)) / 3600);
    var m = Math.floor((seconds % 3600) / 60);
    var s = Math.floor(seconds % 60);

    var dDisplay = d > 0 ? d + (d == 1 ? " day, " : " days, ") : "";
    var hDisplay = h > 0 ? h + (h == 1 ? " hrs, " : " hrs, ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? " mns, " : " mns, ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " sec" : " sec") : "";
    return dDisplay + hDisplay + mDisplay + sDisplay;
  }

  render() {
    let output = <span>{this.display()}</span>;

    return output;
  }
}

export default Countdowntimer;
