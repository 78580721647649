import React, { Component } from "react";
import Control from "./../../control";
import SimpleReactValidator from "simple-react-validator";
import Fun from "../../../../../lib/functions";
import UniFun from "../../../../../modules/uni/functions";
import moment from "moment";
import TopTemplateHeader from "./../../topTemplateHeader";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import Header from "./header";
import Menu from "./menu";
import Footer from "./footer";

class PublicSessions extends Component {
  state = {
    data: this.props.data,
    loaded: false,
    colsTemplateCount: 12,
    colsFormCount: 12,
    cardBodyCustomClass: "",
    module: {
      _id: "",
      title: "",
      description: "",
    },
    session: {},
  };

  constructor(props) {
    super(props);
    let builder = this.props.builder.state;
    let templateConfig = builder.module.templateConfig;
    if (templateConfig.events) {
      Object.keys(templateConfig.events).map((itm, idx) => {
        this[itm] = templateConfig.events[itm].bind(this);
      });
    }
    this.sessionsMenu = this.sessionsMenu.bind(this);
    this.sessionsCards = this.sessionsCards.bind(this);
    this.form = React.createRef();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.action) {
      return false;
    }
    return true;
  }

  componentDidMount() {
    let builder = this.props.builder.state;
    let templateConfig = builder.module.templateConfig;
    let useCard =
      typeof templateConfig.useCard !== "undefined"
        ? templateConfig.useCard
        : true;

    let templateTop = templateConfig.templateTop
      ? templateConfig.templateTop
      : () => {};
    this["templateTop"] = templateTop.bind(this);
    let templateBottom = templateConfig.templateBottom
      ? templateConfig.templateBottom
      : () => {};
    this["templateBottom"] = templateBottom.bind(this);
    let templateLeft = templateConfig.templateLeft
      ? templateConfig.templateLeft
      : () => {};
    this["templateLeft"] = templateLeft.bind(this);
    let templateRight = templateConfig.templateRight
      ? templateConfig.templateRight
      : () => {};
    this["templateRight"] = templateRight.bind(this);
    let cardTemplateTop = templateConfig.cardTemplateTop
      ? templateConfig.cardTemplateTop
      : () => {};
    this["cardTemplateTop"] = cardTemplateTop.bind(this);
    let cardTemplateBottom = templateConfig.cardTemplateBottom
      ? templateConfig.cardTemplateBottom
      : () => {};
    this["cardTemplateBottom"] = cardTemplateBottom.bind(this);
    let panelTop = templateConfig.panelTop ? templateConfig.panelTop : () => {};
    this["panelTop"] = panelTop.bind(this);
    let panelBottom = templateConfig.panelBottom
      ? templateConfig.panelBottom
      : () => {};
    this["panelBottom"] = panelBottom.bind(this);
    let panelLeft = templateConfig.panelLeft
      ? templateConfig.panelLeft
      : () => {};
    this["panelLeft"] = panelLeft.bind(this);
    let panelRight = templateConfig.panelRight
      ? templateConfig.panelRight
      : () => {};
    this["panelRight"] = panelRight.bind(this);
    let header = templateConfig.header ? templateConfig.header : () => {};
    this["header"] = header.bind(this);

    let colsTemplateCount = templateConfig.colsTemplateCount
      ? templateConfig.colsTemplateCount
      : this.state.colsTemplateCount;
    let colsFormCount = templateConfig.colsFormCount
      ? templateConfig.colsFormCount
      : this.state.colsFormCount;

    let cardBodyCustomClass = templateConfig.cardBodyCustomClass
      ? templateConfig.cardBodyCustomClass
      : this.state.cardBodyCustomClass;

    this.setState({
      loaded: true,
      rawData: this.props.rawData,
      colsTemplateCount: colsTemplateCount,
      colsFormCount: colsFormCount,
      cardBodyCustomClass: cardBodyCustomClass,
      useCard: useCard,
    });

    this.validator = this.props.builder.validator;
    if (this.componentDidMountEvent) {
      this.componentDidMountEvent();
    }
  }

  sessionsCards1() {
    let output = [];

    let data = this.state.rawData.data[0];

    data.days?.forEach((itm, idx) => {
      if (
        itm._id == Fun.getURLPathValue(4) ||
        (!Fun.getURLPathValue(4) && idx == 0)
      ) {
        itm.sessions.forEach((sitm, sidx) => {
          output.push(
            <div className="col-md-4 mt-3">
              <Link to={"/conference/" + data._id + "/session/" + sitm._id}>
                <article className="card h-100">
                  <div className="card-body">
                    <h3>{sitm.title}</h3>
                    <span className="d-block mt-4">
                      {sitm.speakersRaw.map((spitm, spidx) => {
                        return (
                          <React.Fragment>
                            <span
                              className="pointer d-block mt-2"
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                this.speakerInfoModal(spitm);
                              }}
                            >
                              <span className="text-strong text-primary">
                                {spitm.firstName + " " + spitm.lastName}
                              </span>
                              {", " + spitm.jobTitle}
                            </span>
                          </React.Fragment>
                        );
                      })}
                    </span>
                    <span className="d-block mt-4">
                      {UniFun.substring(
                        UniFun.stripTags(sitm.description),
                        0,
                        300
                      )}
                    </span>
                  </div>
                  <div className="card-footer border-0">
                    <div className="border-top"></div>
                    <div className="row pt-4 pb-3">
                      <div className="col-md-10">
                        <span className="d-block pt-1 ">
                          {moment(itm.date).format("ddd DD MMM YYYY")}
                          {" | "}
                          {moment(sitm.fromRaw).format("HH:mm")}
                          {" - "}
                          {moment(sitm.toRaw).format("HH:mm")}
                        </span>
                      </div>
                      <div className="col-md-2"></div>
                    </div>
                  </div>
                </article>
              </Link>
            </div>
          );
        });
      }
    });

    return <div className="row">{output}</div>;
  }

  sessionsCards() {
    let output = [];

    let data = this.state.rawData.data[0];

    data.days?.forEach((itm, idx) => {
      if (
        itm._id == Fun.getURLPathValue(4) ||
        (!Fun.getURLPathValue(4) && idx == 0)
      ) {
        itm.sessions.forEach((sitm, sidx) => {
          output.push(
            <div className="col-md-3 mt-4">
              <Link to={"/conference/" + data._id + "/session/" + sitm._id}>
                <div className="card h-100">
                  <div className="card-body">
                    <h3>{sitm.title}</h3>
                    <span className="d-block mt-4">
                      {sitm.speakersRaw.map((spitm, spidx) => {
                        return (
                          <React.Fragment>
                            <span
                              className="pointer d-block mt-2"
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                this.speakerInfoModal(spitm);
                              }}
                            >
                              <span className="text-strong text-primary">
                                {spitm.firstName + " " + spitm.lastName}
                              </span>
                              {", " + spitm.jobTitle}
                            </span>
                          </React.Fragment>
                        );
                      })}
                    </span>
                    <span className="d-block mt-4">
                      {UniFun.substring(
                        UniFun.stripTags(sitm.description),
                        0,
                        300
                      )}
                    </span>
                  </div>
                  <div className="card-footer border-0 ">
                    <div className="border-top"></div>
                    <div className="row pt-4 pb-3">
                      <div className="col-md-12">
                        <span className="d-block pt-1 ">
                          {moment(itm.date).format("ddd DD MMM YYYY")}
                          {" | "}
                          {moment(sitm.fromRaw).format("HH:mm")}
                          {" - "}
                          {moment(sitm.toRaw).format("HH:mm")}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          );
        });
      }
    });

    return <div className="row pr-5 pb-5">{output}</div>;
  }

  sessionsMenu() {
    let output = [];

    let data = this.state.data[0];

    data.days.value?.forEach((itm, idx) => {
      let colorClass = "text-dark";
      if (itm._id == Fun.getURLPathValue(4)) {
        colorClass = "active-day";
      }

      if (!Fun.getURLPathValue(4) && idx == 0) {
        colorClass = "active-day";
      }

      output.push(
        <li className="nav-link pl-0">
          <span
            className={
              "rounded pl-3  pr-3 pt-2 pb-2 conference-day-public " + colorClass
            }
          >
            <Link to={"/conference/" + data._id.value + "/sessions/" + itm._id}>
              Day {idx + 1}
            </Link>
          </span>
        </li>
      );
    });

    return (
      <div className="container  pb-5 pt-3">
        <ul className="nav">{output}</ul>
      </div>
    );
  }

  render() {
    if (this.state.loaded) {
      let data = this.state.data[0];

      let banner = data.banner.value[0]
        ? window["baseurl"](
            "/agenda-file/data/" +
              data.banner.value[0]._id +
              "?type=preview&jwt=" +
              localStorage.getItem("jwt")
          )
        : "/uni/svg/conferencePlaceholder.jpg";

      return (
        <React.Fragment>
          <Header
            activeTab="1"
            data={this.state.data}
            interestFormModal={this.interestFormModal}
            rawData={this.state.rawData}
          ></Header>

          <div
            className="pt-5"
            style={{
              backgroundImage: "url(" + banner + ")",
              backgroundSize: "cover",
              boxShadow: "inset 0 0 0 2000px rgba(0, 0, 0, 0.7)",
              minHeight: "100vh",
            }}
          >
            <div style={{ minHeight: "calc(100vh - 150px)" }}>
              <div className="container ">
                <div className="row">
                  <div className="col-md-12 pl-0">
                    <h1
                      className="text-white"
                      style={{ padding: "0.8rem 1rem" }}
                    >
                      <strong>Sessions</strong>
                    </h1>
                  </div>
                  {this.sessionsMenu()}
                </div>
              </div>
              <div className="container" style={{ minHeight: "600px" }}>
                {this.sessionsCards()}
              </div>
            </div>
            <Footer></Footer>
          </div>

          {/* <div className="container ">
            <div className="row">
              <div className="col-md-12 pl-0">
                <h1 className="header-title" style={{ padding: "0.8rem 1rem" }}>
                  <strong>Sessions</strong>
                </h1>
              </div>
              {this.sessionsMenu()}
            </div>
          </div>
          <div className="container" style={{ minHeight: "100%" }}>
            {this.sessionsCards()}
          </div> */}
        </React.Fragment>
      );
    } else {
      return "loading...";
    }
  }
}

export default withRouter(PublicSessions);
