import React, { Component } from "react";

class GeneralSearch extends Component {
  state = {
    name: "",
    id: "",
    placeholder: "",
    attributes: "",
    value: "",
    defaultValue: "",
    loaded: false,
    editable: true,
    component: null,
    attributes: "",
    events: {},
    customLayout: null,
    useCustomLayout: true,
    editable: true,
    showResults: false,
  };

  constructor(props) {
    super(props);

    let events = {};
    if (this.props.field.events) {
      Object.keys(this.props.field.events).map((itm, idx) => {
        this[itm] = this.props.field.events[itm];
        this[itm] = this[itm].bind(this);
        events[itm] = this[itm];
      });
    }

    this.editable = this.editable.bind(this);
    this.nonEditable = this.nonEditable.bind(this);

    let defaultValue = "";
    if (this.props.field.defaultValue) {
      defaultValue = this.props.field.defaultValue;
    }

    let nonEditableDefaultValue = "";
    if (this.props.field.nonEditableDefaultValue) {
      nonEditableDefaultValue = this.props.field.nonEditableDefaultValue;
    }

    this.state = {
      events: events,
      label: "",
      editable:
        this.props.field.editable != null
          ? this.props.field.editable
          : this.state.editable,
      defaultValue: this.props.value ? this.props.value : defaultValue,
      nonEditableDefaultValue: this.props.value
        ? this.props.value
        : nonEditableDefaultValue,
      show: this.props.field.show === false ? false : true,
    };
  }

  validator() {}

  componentDidMount() {
    this.setState({
      attributes: this.props.field.attributes,
      name: this.props.field.alias,
      placeholder: this.props.field.placeholder
        ? this.props.field.placeholder
        : "",
      editable:
        this.props.field.editable != null
          ? this.props.field.editable
          : this.state.editable,
      customLayout: this.props.field.customLayout,
      useCustomLayout:
        typeof this.props.field.useCustomLayout !== "undefined"
          ? this.props.field.useCustomLayout
          : true,
    });

    var ignoreClickOnMeElement = document.getElementsByClassName(
      "general-search-wrapper"
    );

    let _this = this;
    document.addEventListener("click", function (event) {
      if (ignoreClickOnMeElement[0]) {
        var isClickInsideElement = ignoreClickOnMeElement[0].contains(
          event.target
        );
        if (!isClickInsideElement) {
          _this.setState({ showResults: false });
        }
      }
    });
  }

  editable() {
    let defaultValue = this.state.defaultValue;

    if (typeof defaultValue === "object") {
      defaultValue = JSON.stringify(this.state.defaultValue);
    }

    let output = (
      <div
        className="form-group form-inline h-100 ml-5 bg-464545 w-75 p-0 m-0 general-search-wrapper"
        style={{ position: "relative" }}
      >
        <label forHTML="exampleInputEmail1 ">
          <img
            src="/uni/svg/search.svg"
            className=" pl-3  filter-white"
            style={{
              width: "35px",
              paddingBottom: "2px",
            }}
          />
          <span className="text-grey">{this.state.label}</span>
        </label>
        <input
          type="text"
          defaultValue={defaultValue}
          value={defaultValue}
          placeholder={this.state.placeholder}
          name={this.state.name}
          {...this.state.attributes}
          {...this.state.events}
          onChange={(e) => {
            this.setState({ defaultValue: e.target.value });
          }}
          autocomplete="off"
        ></input>

        <div
          className={
            this.state.showResults ? "general-search-results " : "d-none"
          }
          style={{ position: "absolute", top: "54px" }}
        >
          <div className=" text-dark p-3">{this.state.results}</div>
        </div>
      </div>
    );
    if (this.state.customLayout && this.state.useCustomLayout == true) {
      this.state.customLayout = this.state.customLayout.bind(this);
      return this.state.customLayout({ control: output, object: this });
    }

    return output;
  }

  nonEditable() {
    let output = this.state.nonEditableDefaultValue
      ? this.state.nonEditableDefaultValue
      : this.state.defaultValue;

    if (this.state.customLayout && this.state.useCustomLayout == true) {
      this.state.customLayout = this.state.customLayout.bind(this);
      return this.state.customLayout({ control: output, object: this });
    }

    return output;
  }

  render() {
    if (!this.state.show) {
      return "";
    }

    let output = this.state.editable ? this.editable() : this.nonEditable();

    return output;
  }
}

export default GeneralSearch;
