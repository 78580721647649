import React, { Component } from "react";
import Fun from "../../../lib/functions";

class Stream extends Component {
  state = {
    data: [],
    loaded: false,
    output: null,
  };

  constructor(props) {
    super(props);
    let builder = this.props.builder.state;
    let templateConfig = builder.module.templateConfig;
    if (templateConfig.events) {
      Object.keys(templateConfig.events).map((itm, idx) => {
        this[itm] = templateConfig.events[itm].bind(this);
      });
    }
  }

  componentDidMount() {
    window["streamURL"] = this.props.data[0].streamURL.value;
    this.setState({ data: this.props.data });
    if (this.componentDidMountEvent) {
      setTimeout(this.componentDidMountEvent, 0);
    }
  }

  render() {
    if (this.state.loaded) {
      Fun.addCSS(
        "https://cdnjs.cloudflare.com/ajax/libs/video.js/7.11.4/video-js.css"
      );
      Fun.addScript(
        "https://cdnjs.cloudflare.com/ajax/libs/video.js/7.11.4/video.min.js"
      );
      Fun.addScript(
        "https://player.live-video.net/1.3.1/amazon-ivs-videojs-tech.min.js"
      );
      Fun.addScript(
        "https://player.live-video.net/1.3.1/amazon-ivs-quality-plugin.min.js"
      );
      Fun.addScript("/uni/js/stream.js");
      Fun.addCSS("/uni/css/stream.css");
      return (
        <div id="rootstream" className="text-center">
          <div className="video-container">
            <div className="stream-title rounded">
              <h2>
                <img
                  src="/uni/svg/stream_calendar.svg"
                  className="mr-2"
                  style={{ width: "30px" }}
                />
                {this.state.data[0].title.component}
              </h2>
            </div>
            <video
              id="amazon-ivs-videojs"
              className="video-js vjs-4-3 vjs-big-play-centered"
              controls
              autoplay
              playsinline
            ></video>
            <div className="stream-subtitle"></div>
          </div>
        </div>
      );
    } else {
      return "loading...";
    }
  }
}

export default Stream;
