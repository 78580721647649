import Form from "./templates/form";
import Index from "./index";
import Control from "./templates/control";
import Grid from "./templates/grid";
import FolderGrid from "./templates/foldergrid";
import Squares from "./templates/squares";
import Header from "./templates/header";
import Agenda from "./templates/agenda";
import LeftPanel from "./templates/leftpanel";
import LeftMenu from "./templates/leftmenu";
import TreeView from "./templates/treeview";
import TopMenu from "./templates/topmenu";
import SelectBoxes from "./templates/selectboxes";
import TopTemplateHeader from "./templates/topTemplateHeader";
import Fun from "../../lib/functions";
import LoginForm from "./templates/login/loginForm";
import ForgotPassword from "./templates/login/forgotPassword";
import ConfirmPassword from "./templates/login/confirmPassword";
import OTP from "./templates/login/otp";
import TrainingPreview from "./templates/trainingpreview";
import TopMenuTest from "./templates/topmenutest";
import AgendaOverview from "./templates/agendaoverview";
import Meeting from "./templates/meeting";
import TopMenuTestResults from "./templates/topmenutestresults";
import Plain from "./templates/plain";
import Stream from "./templates/stream";
import Cells from "./templates/cells";
import TrainingOverview from "./templates/trainingoverview";
import TrainingSession from "./templates/trainingsession";
import EpubViewerTemplate from "./templates/epubviewertemplate";
import TopMenuEpub from "./templates/topmenuepub";
import PDFViewerTemplate from "./templates/pdfviewertemplate";
import TopMenuPDF from "./templates/topmenupdf";
import VideoViewerTemplate from "./templates/videoviewertemplate";
import TopMenuVideo from "./templates/topmenuvideo";
import EventsOverview from "./templates/eventsoverview";
import Reception from "./templates/conferences/private/reception";
import Sessions from "./templates/conferences/private/sessions";
import Speakers from "./templates/conferences/private/speakers";
import Sponsors from "./templates/conferences/private/sponsors";
import Session from "./templates/conferences/private/session";
import PublicHomepage from "./templates/conferences/public/publichomepage";
import PublicReception from "./templates/conferences/public/publicreception";
import PublicSessions from "./templates/conferences/public/publicsessions";
import PublicSession from "./templates/conferences/public/publicsession";
import PublicSpeakers from "./templates/conferences/public/publicspeakers";
import PublicSponsors from "./templates/conferences/public/publicsponsors";
import UniFun from "../../modules/uni/functions";
import ConferenceLive from "./templates/conferences/live";
import Settings from "./templates/conferences/private/settings";
import InterestForm from "./templates/conferences/public/interestForm";
import Privacy from "./templates/conferences/public/privacy";
import SpacesOverview from "./templates/spacesoverview";
import Cookies from "./templates/conferences/public/cookies";
import NewLoginForm from "./templates/newlogin/loginForm";
import NewLogin from "./templates/newlogin";
import UserProfile from "./templates/userProfile";
import SpacesFiles from "./templates/spacesfiles";
import SquaresForms from "./templates/squaresforms";
const topDefaultMenu = { module: "unitopmenu", view: "list" };

const DefaultTemplates = {
  publichomepage: PublicHomepage,
  publicreception: PublicReception,
  publicsessions: PublicSessions,
  publicspeakers: PublicSpeakers,
  publicsponsors: PublicSponsors,
  publicsession: PublicSession,
  conferencelive: ConferenceLive,
  conferencesettings: Settings,
  squaresforms: SquaresForms,
  interestform: InterestForm,
  cookies: Cookies,
  squaresoverview: SpacesOverview,
  spacesfiles: SpacesFiles,
  userprofile: UserProfile,
  session: Session,
  privacy: Privacy,
  form: Form,
  plain: Plain,
  cells: Cells,
  index: Index,
  stream: Stream,
  control: Control,
  grid: Grid,
  sponsors: Sponsors,
  foldergrid: FolderGrid,
  videoviewertemplate: VideoViewerTemplate,
  topmenupdf: TopMenuPDF,
  pdfviewertemplate: PDFViewerTemplate,
  header: Header,
  agenda: Agenda,
  leftpanel: LeftPanel,
  leftmenu: LeftMenu,
  squares: Squares,
  treeview: TreeView, //TreeViewMUI,
  topmenu: TopMenu,
  topmenutest: TopMenuTest,
  selectboxes: SelectBoxes,
  toptemplateheader: TopTemplateHeader,
  trainingpreview: TrainingPreview,
  loginform: LoginForm,
  newloginform: NewLoginForm,
  newlogin: NewLogin,
  forgotpassword: ForgotPassword,
  confirmpassword: ConfirmPassword,
  otp: OTP,
  agendaoverview: AgendaOverview,
  meeting: Meeting,
  topmenutestresults: TopMenuTestResults,
  trainingoverview: TrainingOverview,
  trainingsession: TrainingSession,
  epubviewer: EpubViewerTemplate,
  topmenuepub: TopMenuEpub,
  topmenuvideo: TopMenuVideo,
  eventsoverview: EventsOverview,
  reception: Reception,
  sessions: Sessions,
  speakers: Speakers,
  routermap: {
    "/login": {
      main: { module: "unilogin", view: "login" },
    },
    "/forgot-password": {
      main: { module: "unilogin", view: "forgotpassword" },
    },
    "/confirm-password": {
      main: { module: "unilogin", view: "confirmpassword" },
    },
    "/account-verify": {
      main: { module: "unilogin", view: "otp" },
    },
    "/change-password": {
      main: { module: "unilogin", view: "changepassword" },
    },
    "/register": {
      main: { module: "unilogin", view: "changepassword" },
    },
    "/templates": {
      main: {
        module: "unitrainingtemplates",
        view: "list",
        browserTab: "Templates - Training",
      },
      leftpanel: {
        module: "unileftmenu",
        view: "events",
      },
      topmenu: topDefaultMenu,
    },
    "/templates/trainings": {
      main: {
        module: "unitrainingtemplates",
        view: "list",
        browserTab: "Templates - Training",
      },
      leftpanel: {
        module: "unileftmenu",
        view: "events",
      },
      topmenu: topDefaultMenu,
    },
    "/templates/tests": {
      main: {
        module: "unitesttemplates",
        view: "list",
        browserTab: "Templates - Tests",
      },
      leftpanel: {
        module: "unileftmenu",
        view: "events",
      },
      topmenu: topDefaultMenu,
    },
    "/testresults": {
      main: { module: "unitestresults", view: "otherlist" },
      leftpanel: {
        module: "unileftmenu",
        view: "testresults",
        moduleParams: { selectedMenuId: "2" },
      },
      topmenu: topDefaultMenu,
    },
    "/testresults/trainings": {
      main: { module: "unitestresults", view: "otherlist" },
      leftpanel: {
        module: "unileftmenu",
        view: "testresults",
        moduleParams: { selectedMenuId: "2" },
      },
      topmenu: topDefaultMenu,
    },
    "/testresults/trainings/:id/results": {
      main: { module: "unitestresults", view: "trainingexams" },
      leftpanel: {
        module: "unileftmenu",
        view: "testresults",
        moduleParams: { selectedMenuId: "1" },
      },
      topmenu: topDefaultMenu,
    },
    "/testresults/others": {
      main: { module: "unitestresults", view: "otherlist" },
      leftpanel: {
        module: "unileftmenu",
        view: "testresults",
        moduleParams: { selectedMenuId: "2" },
      },
      topmenu: topDefaultMenu,
    },
    "/spaces": {
      main: { module: "spaces", view: "squares" },
      leftpanel: {
        module: "unileftmenu",
        view: "spaces",
        moduleParams: { selectedMenuId: "1" },
      },
      topmenu: topDefaultMenu,
    },
    "/spaces/:id": {
      main: {
        module: "spaces",
        view: "details",
        parameters: function () {
          if (Fun.getURLPathValue(2)) {
            return { replace: { id: Fun.getURLPathValue(2) } };
          }
          return false;
        },
      },
      leftpanel: {
        module: "unileftmenu",
        view: "spaces",
        moduleParams: { selectedMenuId: Fun.getURLPathValue(2) },
      },
      topmenu: topDefaultMenu,
    },
    "/group/:id/files": {
      main: {
        module: "spaces",
        view: "detailFiles",
        parameters: function () {
          if (Fun.getURLPathValue(2)) {
            return { replace: { id: Fun.getURLPathValue(2) } };
          }
          return false;
        },
      },
      topmenu: topDefaultMenu,
      leftpanel: {
        module: "unileftmenu",
        view: "spaces",
        moduleParams: { selectedMenuId: Fun.getURLPathValue(2) },
      },
    },
    "/group/:id": {
      main: {
        module: "spaces",
        view: "detailParticipants",
        parameters: function () {
          if (Fun.getURLPathValue(2)) {
            return { replace: { id: Fun.getURLPathValue(2) } };
          }
          return false;
        },
      },
      topmenu: topDefaultMenu,
      leftpanel: {
        module: "unileftmenu",
        view: "spaces",
        moduleParams: { selectedMenuId: "1" },
      },
    },
    "/group/:id/participants": {
      main: {
        module: "spaces",
        view: "detailParticipants",
        parameters: function () {
          if (Fun.getURLPathValue(2)) {
            return { replace: { id: Fun.getURLPathValue(2) } };
          }
          return false;
        },
      },
      leftpanel: {
        module: "unileftmenu",
        view: "spaces",
        moduleParams: { selectedMenuId: "1" },
      },
      topmenu: topDefaultMenu,
    },
    "/spaces/:spaceid/groups/:id/participants": {
      main: {
        module: "spaces",
        view: "detailParticipants",
        parameters: function () {
          if (Fun.getURLPathValue(4)) {
            return { replace: { id: Fun.getURLPathValue(4) } };
          }
          return false;
        },
      },
      leftpanel: {
        module: "unileftmenu",
        view: "spaces",
        moduleParams: { selectedMenuId: "1" },
      },
      topmenu: topDefaultMenu,
    },
    "/spaces/:spaceid/groups/:id/files": {
      main: {
        module: "spaces",
        view: "detailFiles",
        parameters: function () {
          if (Fun.getURLPathValue(4)) {
            return { replace: { id: Fun.getURLPathValue(4) } };
          }
          return false;
        },
      },
      topmenu: topDefaultMenu,
      leftpanel: {
        module: "unileftmenu",
        view: "spaces",
        moduleParams: { selectedMenuId: Fun.getURLPathValue(2) },
      },
    },
    "/spaces/:id/groups": {
      main: {
        module: "spaces",
        view: localStorage.getItem("role") == "admin" ? "detailTree" : "list",
        parameters: function () {
          if (Fun.getURLPathValue(2)) {
            return {
              replace: {
                id: Fun.getURLPathValue(2),
                treeviewtag: function () {
                  if (UniFun.userIsEligible({ forWhat: "default" })) {
                    return "true";
                  }
                  return "false";
                },
              },
            };
          }
          return false;
        },
        moduleParams: { rootSpace: Fun.getURLPathValue(2) },
      },
      leftpanel: {
        module: "unileftmenu",
        view: "spaces",
        moduleParams: { selectedMenuId: Fun.getURLPathValue(2) },
      },
      topmenu: topDefaultMenu,
    },

    "/files": {
      main: {
        module: "unifiles",
        view: "ownedbyme",
      },
      leftpanel: {
        module: "unileftmenu",
        view: "files",
        moduleParams: { selectedMenuId: "1" },
      },
      topmenu: topDefaultMenu,
    },
    "/test/:id": {
      main: {
        module: "unitaketest",
        view: "detail",
        parameters: function () {
          if (Fun.getURLPathValue(2)) {
            return { replace: { _id: Fun.getURLPathValue(2) } };
          }
          return false;
        },
      },
      leftpanel: null,
      topmenu: {
        module: "unitopmenu",
        view: "topmenutest",
        parameters: function () {
          if (Fun.getURLPathValue(2)) {
            return { replace: { _id: Fun.getURLPathValue(2) } };
          }
          return false;
        },
      },
    },
    "/event/test/:id": {
      main: {
        module: "unitaketest",
        view: "preview",
        parameters: function () {
          if (Fun.getURLPathValue(3)) {
            return { replace: { id: Fun.getURLPathValue(3) } };
          }
          return false;
        },
      },
      topmenu: topDefaultMenu,
      leftpanel: {
        module: "unileftmenu",
        view: "previewTest",
        moduleParams: {
          selectedMenuId: "1",
          urlid: function () {
            return Fun.getURLPathValue(3);
          },
        },
      },
    },
    "/event/test/:id/participants": {
      main: {
        module: "unitaketest",
        view: "participants",
        parameters: function () {
          if (Fun.getURLPathValue(3)) {
            return { replace: { id: Fun.getURLPathValue(3) } };
          }
          return false;
        },
      },
      topmenu: topDefaultMenu,
      leftpanel: {
        module: "unileftmenu",
        view: "previewTest",
        moduleParams: {
          selectedMenuId: "3",
          urlid: function () {
            return Fun.getURLPathValue(3);
          },
        },
      },
    },
    "/event/test/:id/results": {
      main: {
        module: "unitaketest",
        view: "results",
        parameters: function () {
          if (Fun.getURLPathValue(3)) {
            return { replace: { id: Fun.getURLPathValue(3) } };
          }
          return false;
        },
      },
      topmenu: topDefaultMenu,
      leftpanel: {
        module: "unileftmenu",
        view: "previewTest",
        moduleParams: {
          selectedMenuId: "2",
          urlid: function () {
            return Fun.getURLPathValue(3);
          },
        },
      },
    },
    "/event/test/:id/result": {
      main: {
        module: "unitaketest",
        view: "resultPreview",
        parameters: function () {
          if (Fun.getURLPathValue(3)) {
            return { replace: { id: Fun.getURLPathValue(3) } };
          }
          return false;
        },
      },
      leftpanel: null,
      topmenu: {
        module: "unitopmenu",
        view: "topmenutestresults",
        parameters: function () {
          if (Fun.getURLPathValue(3)) {
            return { replace: { id: Fun.getURLPathValue(3) } };
          }
          return false;
        },
      },
    },

    "/meeting/:id": {
      main: {
        module: "unimeetings",
        view: "meeting",
        parameters: function () {
          if (Fun.getURLPathValue(3)) {
            return { replace: { id: Fun.getURLPathValue(3) } };
          }
          return false;
        },
      },
      leftpanel: null,
      topmenu: null,
    },
    "/meeting/live": {
      main: {
        module: "unimeetings",
        view: "meeting",
        parameters: function () {
          if (Fun.getURLPathValue(4)) {
            return { replace: { id: Fun.getURLPathValue(4) } };
          }
          return false;
        },
      },
      leftpanel: null,
      topmenu: null,
    },
    "/event/meeting/:id": {
      main: {
        module: "unimeetingevent",
        view: "previewevent",
        parameters: function () {
          if (Fun.getURLPathValue(3)) {
            return { replace: { id: Fun.getURLPathValue(3) } };
          }
          return false;
        },
      },
      topmenu: topDefaultMenu,
      leftpanel: {
        module: "unileftmenu",
        view: "previewmeetingevent",
        moduleParams: {
          selectedMenuId: "1",
          urlid: function () {
            return Fun.getURLPathValue(3);
          },
        },
      },
    },
    "/event/meeting/:id/participants": {
      main: {
        module: "unimeetingevent",
        view: "meetingParticipants",
        parameters: function () {
          if (Fun.getURLPathValue(3)) {
            return { replace: { id: Fun.getURLPathValue(3) } };
          }
          return false;
        },
      },
      topmenu: topDefaultMenu,
      leftpanel: {
        module: "unileftmenu",
        view: "previewmeetingevent",
        moduleParams: {
          selectedMenuId: "2",
          urlid: function () {
            return Fun.getURLPathValue(3);
          },
        },
      },
    },
    "/event/meeting/:id/files": {
      main: {
        module: "unimeetingevent",
        view: "meetingFiles",
        parameters: function () {
          if (Fun.getURLPathValue(3)) {
            return { replace: { id: Fun.getURLPathValue(3) } };
          }
          return false;
        },
      },
      topmenu: topDefaultMenu,
      leftpanel: {
        module: "unileftmenu",
        view: "previewmeetingevent",
        moduleParams: {
          selectedMenuId: "3",
          urlid: function () {
            return Fun.getURLPathValue(3);
          },
        },
      },
    },
    // "/stream/:id": {
    //   main: {
    //     module: "unistreams",
    //     view: "stream",
    //     parameters: function () {
    //       if (Fun.getURLPathValue(3)) {
    //         return { replace: { id: Fun.getURLPathValue(3) } };
    //       }
    //       return false;
    //     },
    //   },
    //   topmenu: null,
    //   leftpanel: null,
    // },
    // "/event/stream/:id": {
    //   main: {
    //     module: "unistreamevent",
    //     view: "previewevent",
    //     parameters: function () {
    //       if (Fun.getURLPathValue(3)) {
    //         return { replace: { id: Fun.getURLPathValue(3) } };
    //       }
    //       return false;
    //     },
    //   },
    //   topmenu: topDefaultMenu,
    //   leftpanel: {
    //     module: "unileftmenu",
    //     view: "previewstreamevent",
    //     moduleParams: {
    //       selectedMenuId: "1",
    //       urlid: function () {
    //         return Fun.getURLPathValue(3);
    //       },
    //     },
    //   },
    // },
    // "/event/stream/:id/participants": {
    //   main: {
    //     module: "unistreamevent",
    //     view: "streamParticipants",
    //     parameters: function () {
    //       if (Fun.getURLPathValue(3)) {
    //         return { replace: { id: Fun.getURLPathValue(3) } };
    //       }
    //       return false;
    //     },
    //   },
    //   topmenu: topDefaultMenu,
    //   leftpanel: {
    //     module: "unileftmenu",
    //     view: "previewstreamevent",
    //     moduleParams: {
    //       selectedMenuId: "2",
    //       urlid: function () {
    //         return Fun.getURLPathValue(3);
    //       },
    //     },
    //   },
    // },
    // "/event/stream/:id/files": {
    //   main: {
    //     module: "unistreamevent",
    //     view: "streamFiles",
    //     parameters: function () {
    //       if (Fun.getURLPathValue(3)) {
    //         return { replace: { id: Fun.getURLPathValue(3) } };
    //       }
    //       return false;
    //     },
    //   },
    //   topmenu: topDefaultMenu,
    //   leftpanel: {
    //     module: "unileftmenu",
    //     view: "previewstreamevent",
    //     moduleParams: {
    //       selectedMenuId: "3",
    //       urlid: function () {
    //         return Fun.getURLPathValue(3);
    //       },
    //     },
    //   },
    // },
    "/event/training/:id": {
      main: {
        module: "unitrainingevents",
        view: "preview",
        parameters: function () {
          if (Fun.getURLPathValue(3)) {
            return { replace: { _id: Fun.getURLPathValue(3) } };
          }
          return false;
        },
      },
      topmenu: topDefaultMenu,
      leftpanel: {
        module: "unileftmenu",
        view: "trainingevents",
        moduleParams: { selectedMenuId: "1" },
        parameters: function () {
          if (Fun.getURLPathValue(3)) {
            return { replace: { _id: Fun.getURLPathValue(3) } };
          }
          return false;
        },
      },
    },

    "/event/training/:id/module/:moduleid/session/:sessionid": {
      main: {
        module: "unitrainingevents",
        view: "previewsession",
        parameters: function () {
          if (Fun.getURLPathValue(3)) {
            return { replace: { _id: Fun.getURLPathValue(3) } };
          }
          return false;
        },
      },
      topmenu: topDefaultMenu,
      leftpanel: {
        module: "unileftmenu",
        view: "trainingevents",
        parameters: function () {
          if (Fun.getURLPathValue(3)) {
            return { replace: { _id: Fun.getURLPathValue(3) } };
          }
          return false;
        },
      },
    },
    "/event/training/:id/results": {
      main: {
        module: "unitrainingevents",
        view: "trainingResults",
        parameters: function () {
          if (Fun.getURLPathValue(3)) {
            return { replace: { _id: Fun.getURLPathValue(3) } };
          }
          return false;
        },
      },
      topmenu: topDefaultMenu,
      leftpanel: {
        module: "unileftmenu",
        view: "trainingevents",
        parameters: function () {
          if (Fun.getURLPathValue(3)) {
            return { replace: { _id: Fun.getURLPathValue(3) } };
          }
          return false;
        },
      },
    },
    "/event/training/:id/exam/:examid/results": {
      main: {
        module: "unitrainingevents",
        view: "trainingResultsParticipants",
        parameters: function () {
          if (Fun.getURLPathValue(3)) {
            return { replace: { _id: Fun.getURLPathValue(5) } };
          }
          return false;
        },
      },
      topmenu: topDefaultMenu,
      leftpanel: {
        module: "unileftmenu",
        view: "trainingevents",
        parameters: function () {
          if (Fun.getURLPathValue(3)) {
            return { replace: { _id: Fun.getURLPathValue(3) } };
          }
          return false;
        },
      },
    },
    "/event/training/:id/module/:moduleid": {
      main: {
        module: "unitrainingevents",
        view: "previewsession",
        parameters: function () {
          if (Fun.getURLPathValue(3)) {
            return { replace: { _id: Fun.getURLPathValue(3) } };
          }
          return false;
        },
      },
      topmenu: topDefaultMenu,
      leftpanel: {
        module: "unileftmenu",
        view: "trainingevents",
        parameters: function () {
          if (Fun.getURLPathValue(3)) {
            return { replace: { _id: Fun.getURLPathValue(3) } };
          }
          return false;
        },
      },
    },
    "/event/training/:id/module/:moduleid/results/:sessionid/test/:testid": {
      main: {
        module: "unitrainingevents",
        view: "results",
        parameters: function () {
          if (Fun.getURLPathValue(3)) {
            return { replace: { _id: Fun.getURLPathValue(3) } };
          }
          return false;
        },
      },
      topmenu: topDefaultMenu,
      leftpanel: {
        module: "unileftmenu",
        view: "trainingevents",
        parameters: function () {
          if (Fun.getURLPathValue(3)) {
            return { replace: { _id: Fun.getURLPathValue(3) } };
          }
          return false;
        },
      },
    },
    "/event/training/:id/participants": {
      main: {
        module: "unitrainingevents",
        view: "participants",
        parameters: function () {
          if (Fun.getURLPathValue(3)) {
            return { replace: { _id: Fun.getURLPathValue(3) } };
          }
          return false;
        },
      },
      topmenu: topDefaultMenu,
      leftpanel: {
        module: "unileftmenu",
        view: "trainingevents",
        moduleParams: { selectedMenuId: "3" },
        parameters: function () {
          if (Fun.getURLPathValue(3)) {
            return { replace: { _id: Fun.getURLPathValue(3) } };
          }
          return false;
        },
      },
    },
    "/event/training/:id/files": {
      main: {
        module: "unitrainingevents",
        view: "trainingModules",
        parameters: function () {
          if (Fun.getURLPathValue(3)) {
            return { replace: { id: Fun.getURLPathValue(3) } };
          }
          return false;
        },
      },
      topmenu: topDefaultMenu,
      leftpanel: {
        module: "unileftmenu",
        view: "trainingevents",
        moduleParams: { selectedMenuId: "4" },
        parameters: function () {
          if (Fun.getURLPathValue(3)) {
            return { replace: { _id: Fun.getURLPathValue(3) } };
          }
          return false;
        },
      },
    },
    "/event/training/:id/files/modules/:moduleid": {
      main: {
        module: "unitrainingevents",
        view: "trainingSessions",
        parameters: function () {
          if (Fun.getURLPathValue(3)) {
            return {
              replace: {
                id: Fun.getURLPathValue(3),
                moduleid: Fun.getURLPathValue(6),
              },
            };
          }
          return false;
        },
      },
      topmenu: topDefaultMenu,
      leftpanel: {
        module: "unileftmenu",
        view: "trainingevents",
        moduleParams: { selectedMenuId: "4" },
        parameters: function () {
          if (Fun.getURLPathValue(3)) {
            return { replace: { _id: Fun.getURLPathValue(3) } };
          }
          return false;
        },
      },
    },
    "/event/training/:id/files/modules/:moduleid/sessions/:sessionId": {
      main: {
        module: "unitrainingevents",
        view: "trainingFiles",
        parameters: function () {
          if (Fun.getURLPathValue(3)) {
            return {
              replace: {
                id: Fun.getURLPathValue(3),
              },
            };
          }
          return false;
        },
      },
      topmenu: topDefaultMenu,
      leftpanel: {
        module: "unileftmenu",
        view: "trainingevents",
        moduleParams: { selectedMenuId: "4" },
        parameters: function () {
          if (Fun.getURLPathValue(3)) {
            return { replace: { _id: Fun.getURLPathValue(3) } };
          }
          return false;
        },
      },
    },
    "/files/ownedbyme": {
      main: {
        module: "unifiles",
        view: "ownedbyme",
        browserTab: "Files - My files",
      },
      leftpanel: {
        module: "unileftmenu",
        view: "files",
        moduleParams: { selectedMenuId: "1" },
      },
      topmenu: topDefaultMenu,
    },
    "/folder": {
      main: {
        module: "unifiles",
        view: "ownedbyme",
        parameters: function () {
          if (Fun.getParameterByName("_id")) {
            return { replace: { _id: Fun.getParameterByName("_id") } };
          }
          return false;
        },
      },
      topmenu: topDefaultMenu,
      leftpanel: {
        module: "unileftmenu",
        view: "files",
        moduleParams: { selectedMenuId: "4" },
      },
    },
    "/files/shared": {
      main: { module: "unifiles", view: "shared" },
      leftpanel: {
        module: "unileftmenu",
        view: "files",
        moduleParams: { selectedMenuId: "2" },
      },
      topmenu: topDefaultMenu,
    },
    "/files/favorites": {
      main: {
        module: "unifiles",
        view: "favorites",
        browserTab: "Files - Favorites",
      },
      topmenu: topDefaultMenu,
      leftpanel: {
        module: "unileftmenu",
        view: "files",
        moduleParams: { selectedMenuId: "4" },
      },
    },
    "/files/gfiles": {
      main: {
        module: "unifiles",
        browserTab: "Files - Groups",
        view: function () {
          if (Fun.getParameterByName("_id")) {
            return "groupsFiles";
          }

          if (Fun.getParameterByName("spacegroups")) {
            return "groupSpaces";
          }
          return "rootSpaces";
        },
        // parameters: function () {
        //   if (Fun.getParameterByName("_id")) {
        //     return { replace: { id: Fun.getParameterByName("_id") } };
        //   }
        //   return { replace: { id: localStorage.getItem("library") } };
        // },
        moduleParams: { selected: "groupsFiles" },
      },
      topmenu: topDefaultMenu,
      leftpanel: {
        module: "unileftmenu",
        view: "files",
        moduleParams: { selectedMenuId: "5" },
      },
    },
    "/files/library": {
      main: {
        module: "unifiles",
        browserTab: "Files - Library",
        view: "library",
        // parameters: function () {
        //   if (Fun.getParameterByName("_id")) {
        //     return { replace: { id: Fun.getParameterByName("_id") } };
        //   }
        //   return { replace: { id: localStorage.getItem("library") } };
        // },
        moduleParams: { selected: "library" },
      },
      topmenu: topDefaultMenu,
      leftpanel: {
        module: "unileftmenu",
        view: "files",
        moduleParams: { selectedMenuId: "3" },
      },
    },
    "/agenda": {
      main: { module: "uniagenda", view: "overview" },
      topmenu: topDefaultMenu,
      leftpanel: {
        module: "unileftmenu",
        view: "agenda",
        moduleParams: { selectedMenuId: "1" },
      },
    },
    "/": {
      main: { module: "unidashboard", view: "list" },
      leftpanel: null,
      topmenu: topDefaultMenu,
    },
    "/dashboard": {
      main: { module: "unidashboard", view: "list", browserTab: "Dashboard" },
      leftpanel: null,
      topmenu: topDefaultMenu,
    },
    "/events/": {
      main: {
        module: "unievents",
        view: "list",
      },
      leftpanel: {
        module: "unileftmenu",
        view: "events",
        moduleParams: { selectedMenuId: "1" },
      },
      topmenu: topDefaultMenu,
    },
    "/events/overview": {
      main: {
        module: "unievents",
        view: "list",
      },
      leftpanel: {
        module: "unileftmenu",
        view: "events",
        moduleParams: { selectedMenuId: "1" },
      },
      topmenu: topDefaultMenu,
    },
    "/events/trainings": {
      main: { module: "uniagenda", view: "list" },
      leftpanel: {
        module: "unileftmenu",
        view: "events",
        moduleParams: { selectedMenuId: "5" },
      },
      topmenu: topDefaultMenu,
    },
    "/events/meetings": {
      main: { module: "uniagenda", view: "list" },
      leftpanel: {
        module: "unileftmenu",
        view: "events",
        moduleParams: { selectedMenuId: "5" },
      },
      topmenu: topDefaultMenu,
    },
    "/events/tests": {
      main: { module: "uniagenda", view: "list" },
      leftpanel: {
        module: "unileftmenu",
        view: "events",
        moduleParams: { selectedMenuId: "5" },
      },
      topmenu: topDefaultMenu,
    },
    "/events/conferences/terms": {
      main: {
        module: "uniconferences",
        view: "publicTerms",
      },
      leftpanel: null,
      topmenu: null,
    },
    "/events/conferences/privacy": {
      main: {
        module: "uniconferences",
        view: "publicPrivacy",
      },
      leftpanel: null,
      topmenu: null,
    },
    "/events/conferences": {
      main: {
        module: "uniconferences",
        view: function () {
          if (UniFun.isLoggedIn()) {
            return "privateList";
          } else {
            return "publicList";
          }
        },
        browserTab: "Agenda  - Conferences",
      },
      leftpanel: {
        module: "unileftmenu",
        view: "events",
        moduleParams: { selectedMenuId: "5" },
      },
      topmenu: topDefaultMenu,
    },
    "/conference/live/:id/:dayId/:sessionId": {
      main: {
        module: "uniconferences",
        view: "live",
        browserTab: "Agenda  - Conferences",
      },
      topmenu: topDefaultMenu,
    },
    "/conference/:id": {
      main: {
        module: "uniconferences",
        view: function () {
          if (UniFun.isLoggedIn()) {
            return "privateViewReception";
          } else {
            return "publicView";
          }
        },
        browserTab: "Agenda  - Conferences",
      },
      leftpanel: {
        module: "unileftmenu",
        view: "conference",
      },
      topmenu: topDefaultMenu,
    },
    "/conference/:id/speakers": {
      main: {
        module: "uniconferences",
        view: function () {
          if (UniFun.isLoggedIn()) {
            return "privateViewReception";
          } else {
            return "publicView";
          }
        },
        browserTab: "Agenda  - Conferences",
      },
      leftpanel: {
        module: "unileftmenu",
        view: "conference",
      },
      topmenu: topDefaultMenu,
    },
    "/conference/:id/settings": {
      main: {
        module: "uniconferences",
        view: "settings",
        browserTab: "Agenda  - Conferences",
      },
      leftpanel: {
        module: "unileftmenu",
        view: "conference",
      },
      topmenu: topDefaultMenu,
    },
    "/conference/:id/participants": {
      main: {
        module: "uniconferences",
        view: "participants",
        browserTab: "Agenda  - Conferences",
      },
      leftpanel: {
        module: "unileftmenu",
        view: "conference",
      },
      topmenu: topDefaultMenu,
    },
    "/conference/:id/sponsors": {
      main: {
        module: "uniconferences",
        view: function () {
          if (UniFun.isLoggedIn()) {
            return "privateViewReception";
          } else {
            return "publicView";
          }
        },
        browserTab: "Agenda  - Conferences",
      },
      leftpanel: {
        module: "unileftmenu",
        view: "conference",
      },
      topmenu: topDefaultMenu,
    },
    "/conference/:id/polls": {
      main: {
        module: "unipolls",
        view: "list",
        browserTab: "Agenda  - Conferences",
      },
      leftpanel: {
        module: "unileftmenu",
        view: "conference",
      },
      topmenu: topDefaultMenu,
    },
    "/conference/:id/sessions": {
      main: {
        module: "uniconferences",
        view: function () {
          if (UniFun.isLoggedIn()) {
            return "privateViewReception";
          } else {
            return "publicView";
          }
        },
        browserTab: "Agenda  - Conferences",
      },
      leftpanel: {
        module: "unileftmenu",
        view: "conference",
      },
      topmenu: topDefaultMenu,
    },
    "/conference/:id/sessions/:sessionId": {
      main: {
        module: "uniconferences",
        view: function () {
          if (UniFun.isLoggedIn()) {
            return "privateViewReception";
          } else {
            return "publicView";
          }
        },
        browserTab: "Agenda  - Conferences",
      },
      leftpanel: {
        module: "unileftmenu",
        view: "conference",
      },
      topmenu: topDefaultMenu,
    },
    "/conference/:id/session/:sessionId": {
      main: {
        module: "uniconferences",
        view: function () {
          if (UniFun.isLoggedIn()) {
            return "privateViewReception";
          } else {
            return "publicView";
          }
        },
        browserTab: "Agenda  - Conferences",
      },
      leftpanel: {
        module: "unileftmenu",
        view: "conference",
      },
      topmenu: topDefaultMenu,
    },
    "/events/conferences/sponsors": {
      main: {
        module: "unisponsors",
        view: "list",
        browserTab: "Sponsors",
      },
      leftpanel: {
        module: "unileftmenu",
        view: "events",
      },
      topmenu: topDefaultMenu,
    },
    "/agenda/overview": {
      main: {
        module: "uniagenda",
        view: "overview",
        browserTab: "Agenda  - Overview",
      },
      leftpanel: {
        module: "unileftmenu",
        view: "agenda",
        moduleParams: { selectedMenuId: "1" },
      },
      topmenu: topDefaultMenu,
    },
    "/agenda/calendar": {
      main: {
        module: "uniagenda",
        view: "agenda",
        browserTab: "Agenda  - Calendar",
      },
      leftpanel: {
        module: "unileftmenu",
        view: "agenda",
        moduleParams: { selectedMenuId: "2" },
      },
      topmenu: topDefaultMenu,
    },
    "/agenda/list": {
      main: { module: "uniagenda", view: "list" },
      leftpanel: {
        module: "unileftmenu",
        view: function () {
          if (Fun.getURLPathValue("3") == "trainings") {
            return "trainings";
          }
          return "agenda";
        },
        moduleParams: {
          selectedMenuId: function () {
            if (Fun.getURLPathValue("3")) {
              return Fun.getURLPathValue("3").toLowerCase();
            }
            return "trainings";
          },
        },
      },
      topmenu: topDefaultMenu,
    },
    "/agenda/list/trainings": {
      main: { module: "uniagenda", view: "list" },
      leftpanel: {
        module: "unileftmenu",
        view: function () {
          if (Fun.getURLPathValue("3") == "trainings") {
            return "trainings";
          }
          return "agenda";
        },
        moduleParams: {
          selectedMenuId: function () {
            if (Fun.getURLPathValue("3")) {
              return Fun.getURLPathValue("3").toLowerCase();
            }
            return "trainings";
          },
        },
      },
      topmenu: topDefaultMenu,
    },
    "/contacts": {
      main: { module: "uniusers", view: "list", browserTab: "Contacts" },
      topmenu: topDefaultMenu,
      leftpanel: {
        module: "unileftmenu",
        view: "contacts",
        moduleParams: { selectedMenuId: "1" },
      },
    },
    "/forms": {
      main: { module: "uniforms", view: "list", browserTab: "Forms" },
      topmenu: topDefaultMenu,
      leftpanel: {
        module: "unileftmenu",
        view: "forms",
        moduleParams: { selectedMenuId: "1" },
      },
    },
    "/form/:id": {
      main: { module: "uniforms", view: "privateForm" },
      leftpanel: null,
      topmenu: null,
    },
    "/challenge-form": {
      main: { module: "uniforms", view: "publicMainForm" },
      leftpanel: null,
      topmenu: null,
    },
    "/challenge-form/:id": {
      main: { module: "uniforms", view: "publicMainForm" },
      leftpanel: null,
      topmenu: null,
    },
    "/forms/results": {
      main: { module: "uniforms", view: "listResults", browserTab: "Forms" },
      topmenu: topDefaultMenu,
      leftpanel: {
        module: "unileftmenu",
        view: "forms",
        moduleParams: { selectedMenuId: "1" },
      },
    },
    "/forms/:id/results": {
      main: { module: "uniforms", view: "results" },
      leftpanel: {
        module: "unileftmenu",
        view: "forms",
        moduleParams: { selectedMenuId: "1" },
      },
      topmenu: topDefaultMenu,
    },
    "/forms/users/:id": {
      main: { module: "uniforms", view: "formResult" },
      leftpanel: null,
      topmenu: null,
    },
    "/contacts/roles": {
      main: { module: "uniusers", view: "roles" },
      topmenu: topDefaultMenu,
      leftpanel: {
        module: "unileftmenu",
        view: "contacts",
        moduleParams: { selectedMenuId: "2" },
      },
    },
    "/epub/viewer/:id": {
      main: { module: "unifiles", view: "epubPreview" },
      leftpanel: null,
      //topmenu: null,
      topmenu: { module: "unitopmenu", view: "topmenuepub" },
    },
    "/pdf/viewer/:id": {
      main: { module: "unifiles", view: "pdfPreview" },
      leftpanel: null,
      //topmenu: null,
      topmenu: { module: "unitopmenu", view: "topmenupdf" },
    },
    "/video/viewer/:id": {
      main: { module: "unifiles", view: "videoPreview" },
      leftpanel: null,
      //topmenu: null,
      topmenu: { module: "unitopmenu", view: "topmenuvideo" },
    },
  },
};

export default DefaultTemplates;
