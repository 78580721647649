import React, { Component } from "react";
import Fun from "../../../lib/functions";
import { FileDrop } from "react-file-drop";
import GridScripts from "../js/script";

class FolderGrid extends Component {
  state = {
    data: [],
    loaded: false,
    colsTemplateCount: 12,
    colsFormCount: 12,
  };

  constructor(props) {
    super(props);

    this.createHeaders = this.createHeaders.bind(this);
    this.createBody = this.createBody.bind(this);
    this.onFrameDragEnter = function () {};
    this.onFrameDragLeave = function () {};
    this.onFrameDrop = function () {};
    this.onDragOver = function () {};
    this.onDragLeave = function () {};
    this.onDrop = function () {};

    this.onFileInputChange = this.onFileInputChange.bind(this);
    this.onTargetClick = this.onTargetClick.bind(this);

    let builder = this.props.builder.state;
    let templateConfig = builder.module.templateConfig;
    if (templateConfig.events) {
      Object.keys(templateConfig.events).map((itm, idx) => {
        this[itm] = templateConfig.events[itm].bind(this);
      });
    }
  }

  componentDidMount() {
    let builder = this.props.builder.state;
    let templateConfig = builder.module.templateConfig;
    let templateTop = templateConfig.templateTop
      ? templateConfig.templateTop
      : () => {};
    this["templateTop"] = templateTop.bind(this);
    let templateBottom = templateConfig.templateBottom
      ? templateConfig.templateBottom
      : () => {};
    this["templateBottom"] = templateBottom.bind(this);
    let templateLeft = templateConfig.templateLeft
      ? templateConfig.templateLeft
      : () => {};
    this["templateLeft"] = templateLeft.bind(this);
    let templateRight = templateConfig.templateRight
      ? templateConfig.templateRight
      : () => {};
    this["templateRight"] = templateRight.bind(this);
    let cardTemplateTop = templateConfig.cardTemplateTop
      ? templateConfig.cardTemplateTop
      : () => {};
    this["cardTemplateTop"] = cardTemplateTop.bind(this);
    let cardTemplateBottom = templateConfig.cardTemplateBottom
      ? templateConfig.cardTemplateBottom
      : () => {};
    this["cardTemplateBottom"] = cardTemplateBottom.bind(this);
    let content = templateConfig.content ? templateConfig.content : null;
    if (content) {
      this["content"] = content.bind(this);
    }
    let panelTop = templateConfig.panelTop ? templateConfig.panelTop : () => {};
    this["panelTop"] = panelTop.bind(this);
    let panelBottom = templateConfig.panelBottom
      ? templateConfig.panelBottom
      : () => {};
    this["panelBottom"] = panelBottom.bind(this);
    let panelLeft = templateConfig.panelLeft
      ? templateConfig.panelLeft
      : () => {};
    this["panelLeft"] = panelLeft.bind(this);
    let panelRight = templateConfig.panelRight
      ? templateConfig.panelRight
      : () => {};
    this["panelRight"] = panelRight.bind(this);
    let header = templateConfig.header ? templateConfig.header : () => {};
    this["header"] = header.bind(this);

    let emptyFolder = templateConfig.gridConfig.emptyFolder
      ? templateConfig.gridConfig.emptyFolder
      : () => {};
    this["emptyFolder"] = emptyFolder.bind(this);

    let gridConfig = templateConfig.gridConfig;

    let colsTemplateCount = templateConfig.colsTemplateCount
      ? templateConfig.colsTemplateCount
      : this.state.colsTemplateCount;
    let colsFormCount = templateConfig.colsFormCount
      ? templateConfig.colsFormCount
      : this.state.colsFormCount;

    this.setState({
      data: this.props.data,
      rawData: this.props.rawData,
      loaded: true,
      gridConfig: gridConfig,
      colsTemplateCount: colsTemplateCount,
      colsFormCount: colsFormCount,
      fileInputRef: React.createRef(),
    });

    GridScripts();
  }

  createHeaders() {
    let headers = [];
    let data = this.state.data[0];

    if (data) {
      Object.keys(data).map((itm, idx) => {
        if (data[itm].showPropertyOnTemplate) {
          let attributes = [];
          if (this.state.gridConfig) {
            if (this.state.gridConfig.headers) {
              if (this.state.gridConfig.headers[itm]) {
                if (this.state.gridConfig.headers[itm]["attributes"]) {
                  attributes = this.state.gridConfig.headers[itm]["attributes"];
                }
              }
            }
          }

          headers.push(
            <th scope="col" key={Fun.uuid()} {...attributes}>
              {data[itm].field.label}
            </th>
          );
        }
      });

      return (
        <thead>
          <tr>{headers}</tr>
        </thead>
      );
    }
    return "";
  }

  createBody() {
    let rows = [];

    let data = this.state.data;

    data.map((itm, idx) => {
      let cells = [];

      Object.keys(itm).map((citm, cidx) => {
        if (itm[citm].showPropertyOnTemplate) {
          let itmComponent = itm[citm].component;
          let attributes = [];
          if (this.state.gridConfig) {
            if (this.state.gridConfig.cellTemplates) {
              if (this.state.gridConfig.cellTemplates[citm]) {
                itmComponent = this.state.gridConfig.cellTemplates[citm](
                  itmComponent,
                  itm,
                  this
                );
              }
            }
            if (this.state.gridConfig.cell) {
              if (this.state.gridConfig.cell[citm]) {
                if (this.state.gridConfig.cell[citm]["attributes"]) {
                  attributes = this.state.gridConfig.cell[citm]["attributes"];
                }
              }
            }
          }

          cells.push(
            <td key={Fun.uuid()} {...attributes}>
              {itmComponent}
            </td>
          );
        }
      });

      rows.push(<tr key={Fun.uuid()}>{cells}</tr>);
    });

    return <tbody>{rows}</tbody>;
  }

  uploadFiles(args) {
    Fun.fetch({
      url: args.url,
      method: args.method,
      submittedData: args.submittedData,
      object: this,
      callback: args.callback ? args.callback : null,
      cancelToken: args.cancelToken ? args.cancelToken : null,
      onUploadProgress: args.onUploadProgress ? args.onUploadProgress : null,
    });
  }

  onFileInputChange = (event) => {
    const { files } = event.target;
    // do something with your files...
    this.onDrop(files, event);
  };

  onTargetClick = () => {
    this.state.fileInputRef.current.click();
  };

  render() {
    let hasParentFolder = false;
    if (this.state.data[0]) {
      if (this.state.data[0].isParentFolder) {
        if (this.state.data[0].isParentFolder.value === true) {
          hasParentFolder = true;
        }
      }
    }

    let realDataCount = hasParentFolder
      ? this.state.data.length - 1
      : this.state.data.length;

    if (this.state.loaded) {
      return (
        <React.Fragment>
          {this.header()}
          {this.panelTop()}
          <div className="container-fluid">
            <div className="row">
              {this.panelLeft()}
              <div className={"col-" + this.state.colsTemplateCount}>
                <div className="row">
                  {this.templateLeft()}
                  <div className={"col-" + this.state.colsFormCount}>
                    {this.templateTop()}
                    <div className="card">
                      <div className="card-body">
                        {this.cardTemplateTop()}
                        {this.content ? (
                          this.content()
                        ) : (
                          <React.Fragment>
                            <FileDrop
                              onFrameDragEnter={(event) =>
                                this.onFrameDragEnter(event)
                              }
                              onFrameDragLeave={(event) =>
                                this.onFrameDragLeave(event)
                              }
                              onFrameDrop={(event) => this.onFrameDrop(event)}
                              onDragOver={(event) => this.onDragOver(event)}
                              onDragLeave={(event) => this.onDragLeave(event)}
                              onDrop={(files, event) =>
                                this.onDrop(files, event)
                              }
                              className={
                                this.state.attributes
                                  ? this.state.attributes["className"]
                                  : ""
                              }
                            >
                              <table className="table table-hover">
                                {this.createHeaders()}
                                {this.createBody()}
                              </table>
                              {realDataCount <= 0 ? this.emptyFolder() : ""}
                            </FileDrop>
                            <input
                              onChange={this.onFileInputChange}
                              ref={this.state.fileInputRef}
                              type="file"
                              style={{ display: "none" }}
                              multiple
                            />
                          </React.Fragment>
                        )}
                        {this.cardTemplateBottom()}
                      </div>
                    </div>
                    {this.templateBottom()}
                  </div>
                  {this.templateRight()}
                </div>
              </div>
              {this.panelRight()}
            </div>
          </div>
          {this.panelBottom()}
        </React.Fragment>
      );
    } else {
      return "loading...";
    }
  }
}

export default FolderGrid;
