import React, { Component } from "react";
import Control from "./control";
import SimpleReactValidator from "simple-react-validator";
import Fun from "../../../lib/functions";
import UniFun from "../../../modules/uni/functions";
import moment from "moment";
import TopTemplateHeader from "./topTemplateHeader";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";

class EventsOverview extends Component {
  state = {
    data: this.props.data,
    loaded: false,
    colsTemplateCount: 12,
    colsFormCount: 12,
    cardBodyCustomClass: "",
    module: {
      _id: "",
      title: "",
      description: "",
    },
    session: {},
  };

  constructor(props) {
    super(props);
    let builder = this.props.builder.state;
    let templateConfig = builder.module.templateConfig;
    if (templateConfig.events) {
      Object.keys(templateConfig.events).map((itm, idx) => {
        this[itm] = templateConfig.events[itm].bind(this);
      });
    }

    this.allEvents = this.allEvents.bind(this);
    this.allTemplates = this.allTemplates.bind(this);
    this.form = React.createRef();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.action) {
      return false;
    }
    return true;
  }

  componentDidMount() {
    let builder = this.props.builder.state;
    let templateConfig = builder.module.templateConfig;
    let useCard =
      typeof templateConfig.useCard !== "undefined"
        ? templateConfig.useCard
        : true;

    let templateTop = templateConfig.templateTop
      ? templateConfig.templateTop
      : () => {};
    this["templateTop"] = templateTop.bind(this);
    let templateBottom = templateConfig.templateBottom
      ? templateConfig.templateBottom
      : () => {};
    this["templateBottom"] = templateBottom.bind(this);
    let templateLeft = templateConfig.templateLeft
      ? templateConfig.templateLeft
      : () => {};
    this["templateLeft"] = templateLeft.bind(this);
    let templateRight = templateConfig.templateRight
      ? templateConfig.templateRight
      : () => {};
    this["templateRight"] = templateRight.bind(this);
    let cardTemplateTop = templateConfig.cardTemplateTop
      ? templateConfig.cardTemplateTop
      : () => {};
    this["cardTemplateTop"] = cardTemplateTop.bind(this);
    let cardTemplateBottom = templateConfig.cardTemplateBottom
      ? templateConfig.cardTemplateBottom
      : () => {};
    this["cardTemplateBottom"] = cardTemplateBottom.bind(this);
    let panelTop = templateConfig.panelTop ? templateConfig.panelTop : () => {};
    this["panelTop"] = panelTop.bind(this);
    let panelBottom = templateConfig.panelBottom
      ? templateConfig.panelBottom
      : () => {};
    this["panelBottom"] = panelBottom.bind(this);
    let panelLeft = templateConfig.panelLeft
      ? templateConfig.panelLeft
      : () => {};
    this["panelLeft"] = panelLeft.bind(this);
    let panelRight = templateConfig.panelRight
      ? templateConfig.panelRight
      : () => {};
    this["panelRight"] = panelRight.bind(this);
    let header = templateConfig.header ? templateConfig.header : () => {};
    this["header"] = header.bind(this);

    let colsTemplateCount = templateConfig.colsTemplateCount
      ? templateConfig.colsTemplateCount
      : this.state.colsTemplateCount;
    let colsFormCount = templateConfig.colsFormCount
      ? templateConfig.colsFormCount
      : this.state.colsFormCount;

    let cardBodyCustomClass = templateConfig.cardBodyCustomClass
      ? templateConfig.cardBodyCustomClass
      : this.state.cardBodyCustomClass;

    this.setState({
      loaded: true,
      rawData: this.props.rawData,
      colsTemplateCount: colsTemplateCount,
      colsFormCount: colsFormCount,
      cardBodyCustomClass: cardBodyCustomClass,
      useCard: useCard,
    });

    this.validator = this.props.builder.validator;
    if (this.componentDidMountEvent) {
      this.componentDidMountEvent();
    }
  }

  allEvents() {
    let data = this.state.data[0].data.value[0];
    let colors = data.colours.events;
    return (
      <React.Fragment>
        <div className="col-md-3">
          <Link to={"/events/meetings"}>
            <div
              className="card rounded bg-events-overview-meetings bg-events-overview-rounded pl-3 pt-3 pointer"
              style={{ backgroundColor: "#" + colors["meeting"] }}
            >
              <div
                className="card-header border-0 bg-events-overview-meetings bg-events-overview-rounded"
                style={{ backgroundColor: "#" + colors["meeting"] }}
              >
                <h4>Meetings</h4>
              </div>
              <div
                className="card-body border-0 pt-0 text-secondary"
                style={{ minHeight: "140px" }}
              >
                {data.meetingEventCount} events
              </div>
              <div
                className="card-footer border-0 bg-events-overview-meetings bg-events-overview-rounded mb-3"
                style={{ backgroundColor: "#" + colors["meeting"] }}
              >
                View events <i className="fas fa-chevron-right ml-4"></i>
              </div>
            </div>
          </Link>
        </div>
        {UniFun.userIsEligible({ forWhat: "isHost" }) ? (
          ""
        ) : (
          <div className="col-md-3">
            <Link to={"/events/trainings"}>
              <div
                className="card rounded bg-events-overview-meetings bg-events-overview-rounded pl-3 pt-3 pointer"
                style={{ backgroundColor: "#" + colors["training"] }}
              >
                <div
                  className="card-header border-0 bg-events-overview-meetings bg-events-overview-rounded"
                  style={{ backgroundColor: "#" + colors["training"] }}
                >
                  <h4>Trainings</h4>
                </div>
                <div
                  className="card-body border-0 pt-0 text-secondary"
                  style={{ minHeight: "140px" }}
                >
                  {data.trainingEventCount} events
                </div>
                <div
                  className="card-footer border-0 bg-events-overview-meetings bg-events-overview-rounded mb-3 "
                  style={{ backgroundColor: "#" + colors["training"] }}
                >
                  View events <i className="fas fa-chevron-right ml-4"></i>
                </div>
              </div>
            </Link>
          </div>
        )}
        {UniFun.userIsEligible({
          forWhat: "isInstructor",
        }) || UniFun.userIsEligible({ forWhat: "isHost" }) ? (
          ""
        ) : (
          <div className="col-md-3">
            <Link to={"/events/tests"}>
              <div
                className="card rounded bg-events-overview-meetings bg-events-overview-rounded pl-3 pt-3 pointer"
                style={{ backgroundColor: "#" + colors["exam"] }}
              >
                <div
                  className="card-header border-0 bg-events-overview-meetings bg-events-overview-rounded"
                  style={{ backgroundColor: "#" + colors["exam"] }}
                >
                  <h4>Tests</h4>
                </div>
                <div
                  className="card-body border-0 pt-0 text-secondary"
                  style={{ minHeight: "140px" }}
                >
                  {data.examEventCount} events
                </div>
                <div
                  className="card-footer border-0 bg-events-overview-meetings bg-events-overview-rounded mb-3"
                  style={{ backgroundColor: "#" + colors["exam"] }}
                >
                  View events <i className="fas fa-chevron-right ml-4"></i>
                </div>
              </div>
            </Link>
          </div>
        )}
        <div className="col-md-3">
          <Link to={"/events/conferences"}>
            <div
              className="card rounded bg-events-overview-meetings bg-events-overview-rounded pl-3 pt-3 pointer"
              style={{ backgroundColor: "#" + colors["conference"] }}
            >
              <div
                className="card-header border-0 bg-events-overview-meetings bg-events-overview-rounded"
                style={{ backgroundColor: "#" + colors["conference"] }}
              >
                <h4>Conferences</h4>
              </div>
              <div
                className="card-body border-0 pt-0 text-secondary"
                style={{ minHeight: "140px" }}
              >
                {data.conferenceEventCount} events
              </div>
              <div
                className="card-footer border-0 bg-events-overview-meetings bg-events-overview-rounded mb-3"
                style={{ backgroundColor: "#" + colors["conference"] }}
              >
                View events <i className="fas fa-chevron-right ml-4"></i>
              </div>
            </div>
          </Link>
        </div>
      </React.Fragment>
    );
  }

  allTemplates() {
    let data = this.state.data[0].data.value[0];
    let colors = data.colours.templates;
    return (
      <React.Fragment>
        <div className="col-md-3">
          <Link to={"/templates/trainings"}>
            <div
              className="card rounded bg-events-overview-meetings bg-events-overview-rounded pl-3 pt-3 pointer"
              style={{ backgroundColor: "#" + colors["training"] }}
            >
              <div
                className="card-header border-0 bg-events-overview-meetings bg-events-overview-rounded"
                style={{ backgroundColor: "#" + colors["training"] }}
              >
                <h4>Trainings</h4>
              </div>
              <div
                className="card-body border-0 pt-0 text-secondary"
                style={{ minHeight: "140px" }}
              >
                {data.trainingTemplateCount} templates
              </div>
              <div
                className="card-footer border-0 bg-events-overview-meetings bg-events-overview-rounded mb-3"
                style={{ backgroundColor: "#" + colors["training"] }}
              >
                View templates <i className="fas fa-chevron-right ml-4"></i>
              </div>
            </div>
          </Link>
        </div>
        <div className="col-md-3">
          <Link to={"/templates/tests"}>
            <div
              className="card rounded bg-events-overview-meetings bg-events-overview-rounded pl-3 pt-3 pointer"
              style={{ backgroundColor: "#" + colors["exam"] }}
            >
              <div
                className="card-header border-0 bg-events-overview-meetings bg-events-overview-rounded"
                style={{ backgroundColor: "#" + colors["exam"] }}
              >
                <h4>Tests</h4>
              </div>
              <div
                className="card-body border-0 pt-0 text-secondary"
                style={{ minHeight: "140px" }}
              >
                {data.examTemplatesCount} templates
              </div>
              <div
                className="card-footer border-0 bg-events-overview-meetings bg-events-overview-rounded mb-3"
                style={{ backgroundColor: "#" + colors["exam"] }}
              >
                View templates <i className="fas fa-chevron-right ml-4"></i>
              </div>
            </div>
          </Link>
        </div>
      </React.Fragment>
    );
  }

  render() {
    if (this.state.loaded) {
      let _this = this;

      return (
        <React.Fragment>
          <div className={"uniTopHeader "}>
            <TopTemplateHeader
              customClass="mb-4 uniFixedHeader"
              title={
                <React.Fragment>
                  <span>Overview</span>
                </React.Fragment>
              }
            ></TopTemplateHeader>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col mb-3">
                <strong>All events</strong>
              </div>
            </div>
            <div className="row  w-75">{this.allEvents()}</div>
          </div>
          {UniFun.userIsEligible({ forWhat: "module" }) ? (
            <div className="container-fluid mt-3">
              <div className="row">
                <div className="col mb-3">
                  <strong>Templates</strong>
                </div>
              </div>
              <div className="row   w-75">{this.allTemplates()}</div>
            </div>
          ) : (
            ""
          )}
        </React.Fragment>
      );
    } else {
      return "loading...";
    }
  }
}

export default withRouter(EventsOverview);
