import React, { Component } from "react";

import Fun from "../../../lib/functions";
import UniFun from "../../../modules/uni/functions";

class VideoViewerTemplate extends Component {
  state = {
    data: [],
    loaded: false,
    output: null,
    location: null,
  };

  constructor(props) {
    super(props);
    let builder = this.props.builder.state;
    let templateConfig = builder.module.templateConfig;
    if (templateConfig.events) {
      Object.keys(templateConfig.events).map((itm, idx) => {
        this[itm] = templateConfig.events[itm].bind(this);
      });
    }
  }

  componentDidMount() {
    if (!localStorage.getItem("isLoggedIn")) {
      UniFun.logout(window.location.href);
    } else {
      this.setState({ data: this.props.data, loaded: true });
    }

    if (this.componentDidMountEvent) {
      setTimeout(this.componentDidMountEvent, 0);
    }
  }

  render() {
    if (this.state.loaded) {
      return (
        <div style={{ borderTop: "1px solid #d8dbe0" }}>
          <div
            // style={{
            //   height: "100vh",
            // }}
            className="myReader"
          >
            <div className="p-5 bg-white text-center">
              <video controls className="pb-5 embed-responsive">
                <source
                  src={window["baseurl"](
                    "/agenda-file/data/" +
                      Fun.getURLPathValue(3) +
                      "?type=preview&jwt=" +
                      localStorage.getItem("jwt")
                  )}
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>
      );
    } else {
      return "loading...";
    }
  }
}

export default VideoViewerTemplate;
