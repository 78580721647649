import moment from "moment";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Fun from "../../../lib/functions";
import UniFun from "../../../modules/uni/functions";

class Meeting extends Component {
  state = {
    data: [],
    loaded: false,
    output: null,
    launch: false,
    texts: {
      theeventwillbestarted: "The event starts on {date}",
      theeventisreadytostart: "The event is ready to start",
      theeventhasended: "The event has ended",
      nolongeravailable: "The event is no longer available",
      pleasewaitforevent: "Please wait till the event starts to launch event",
      allowtolaunch: "You can now launch the event",
      typeyourname: "Type your name to launch the event",
      launchevent: "Launch event",
      alreadyHaveAccount: "Already have an account?",
      logintolaunch: "Log in to launch the event",
      guestNameIsEmpty: "Please type your name to enter",
      waitformoderator: "Please wait for the host to open the event",
    },
    guestNameIsEmptyWarning: "",
  };

  constructor(props) {
    super(props);
    this.nonLoggedIn = this.nonLoggedIn.bind(this);
    this.launch = this.launch.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onChangeGuestName = this.onChangeGuestName.bind(this);
  }

  componentDidMount() {
    this.setState({ data: this.props.data[0], loaded: true });
    //console.log(window["launchEventInterval"]);

    if (typeof window["launchEventTimeout"] !== "undefined") {
      clearTimeout(window["launchEventTimeout"]);
    }

    if (typeof window["launchEventInterval"] === "undefined") {
      Fun.addScript("/uni/js/script.js");
    }
  }

  launch() {
    this.setState({ launch: true });
  }

  onSubmit(e) {
    e.preventDefault();

    if (
      this.state.data._id.data.type == "meeting" &&
      localStorage.getItem("isLoggedIn") !== "true"
    ) {
      if (this.state.guestName) {
        localStorage.setItem("guest", this.state.guestName);
        this.setState({ launch: true });
      } else {
        this.setState({
          guestNameIsEmptyWarning: this.state.texts.guestNameIsEmpty,
        });
      }
    } else {
      this.setState({ launch: true });
    }
  }

  onChangeGuestName(event) {
    this.setState({ guestName: event.target.value });
  }

  nonLoggedIn() {
    let start = this.state.data._id.data.from;
    let end = this.state.data._id.data.to;
    let moderatorHasJoined = this.state.data.moderatorHasJoined.value;
    let moderators = this.state.data.moderators.value;

    let moderatorId = this.state.data.moderatorId.value;

    if (
      this.state.data._id.data.type == "meeting" ||
      this.state.data._id.data.type == "conference"
    ) {
      if (Array.isArray(moderators)) {
        if (moderators.indexOf(localStorage.getItem("userid")) >= 0) {
          moderatorHasJoined = true;
        }
      }
    }

    let launchEventBtn = (
      <button
        className="btn btn-primary btn-block disabled action-btn launch-event"
        data-event-start={moment(start).valueOf()}
        disabled
        type="submit"
        onClick={this.launch}
        data-event-title={this.state.texts.theeventisreadytostart}
        data-event-reload={UniFun.meetingIsAvailable(start, end) ? false : true}
        data-event-subtitle={
          moderatorHasJoined
            ? this.state.texts.allowtolaunch
            : this.state.texts.waitformoderator
        }
        data-event-moderatorHasJoined={moderatorHasJoined}
        style={{ width: "50%", marginLeft: "auto", marginRight: "auto" }}
      >
        {this.state.texts.launchevent}
      </button>
    );

    let typeYourName = (
      <p>
        <input
          type="text"
          placeholder="Type your name"
          className="form-control text-center"
          onChange={this.onChangeGuestName}
        ></input>
        <div className="pt-1 text-danger">
          {this.state.guestNameIsEmptyWarning}
        </div>
      </p>
    );

    let title = this.state.texts.theeventwillbestarted;
    let subtitle = this.state.texts.pleasewaitforevent;
    let alreadyHaveAccount = (
      <div className="mt-5">
        <strong>{this.state.texts.alreadyHaveAccount}</strong>
        <br></br>
        <Link
          className="text-primary"
          to={"/login?redirect=" + window.location}
        >
          {this.state.texts.logintolaunch}
        </Link>
      </div>
    );

    if (UniFun.meetingIsAvailable(start, end)) {
      if (moderatorHasJoined !== true) {
        title = this.state.texts.theeventisreadytostart;
        subtitle = this.state.texts.waitformoderator;

        window["launchEventTimeout"] = setTimeout(
          this.props.builder.rerunComponent,
          5000
        );
      } else {
        title = this.state.texts.theeventisreadytostart;
        subtitle = this.state.texts.allowtolaunch;
      }
    } else if (moment() > moment(end).add("30", "minutes")) {
      title = this.state.texts.theeventhasended;
      subtitle = this.state.texts.nolongeravailable;
      launchEventBtn = "";
      typeYourName = "";
      alreadyHaveAccount = "";
    } else {
      title = this.state.texts.theeventwillbestarted.replace(
        "{date}",
        moment(start).format("ddd DD MMM YY, HH:mm")
      );
      // typeYourName = "";
    }

    if (
      this.state.data._id.data.type != "meeting" ||
      localStorage.getItem("isLoggedIn") === "true"
    ) {
      alreadyHaveAccount = "";
      typeYourName = "";
    }

    return (
      <div
        className="container h-100 w-50 w-md-100"
        style={{
          paddingTop: "10%",
        }}
      >
        <div className="row justify-content-center">
          <div className="col-md-8">
            <div className="text-center">
              <img
                src="/uni/images/company-logo.png"
                style={{ height: "100px" }}
              ></img>
              <h4>
                <strong id="launchTitle">{title}</strong>
              </h4>

              <p className="text-muted" id="launchSubtitle">
                {subtitle}
              </p>
              <p className="pt-3">{typeYourName}</p>
              <form onSubmit={this.onSubmit} ref={this.form}>
                <div className="form-group pt-2">{launchEventBtn}</div>
              </form>
              {alreadyHaveAccount}
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    if (this.state.loaded) {
      let start = this.state.data._id.data.from;
      let end = this.state.data._id.data.to;

      // if (UniFun.meetingIsAvailable(start, end)) {
      //   Fun.addScript("/uni/meeting/meeting-bundle.js");

      //   return <div id="rootmeeting"></div>;
      // } else {
      //   return this.nonLoggedIn();
      // }
      if (!localStorage.getItem("isLoggedIn") && !this.state.launch) {
        return this.nonLoggedIn();
      } else {
        if (UniFun.meetingIsAvailable(start, end) && this.state.launch) {
          Fun.addScript("/uni/meeting/meeting-bundle.js");
          return <div id="rootmeeting"></div>;
        }
        return this.nonLoggedIn();
      }
    } else {
      return "loading...";
    }
  }
}

export default Meeting;
