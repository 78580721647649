import React, { Component } from "react";
import Controls from "../controls";
import Themes from "../../themes/themes";
import Fun from "../../lib/functions";

class Accordion extends Component {
  state = {
    controls: [],
    customLayout: null,
    useCustomLayout: true,
    loaded: false,
    accordions: [],
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    let controlConfig = this.props.field.config;
    let controlFields = this.props.builder.state.module.properties;
    let accordions = controlConfig.accordions;

    let controls = [];

    this.setState({
      controls: controls,
      controlFields: controlFields,
      defaultValue: this.props.value ? this.props.value : "",
      data: this.props.data ? this.props.data : "",
      accordions: accordions,
      customLayout: this.props.field.customLayout,
      useCustomLayout:
        typeof this.props.field.useCustomLayout !== "undefined"
          ? this.props.field.useCustomLayout
          : true,
      loaded: true,
    });
  }

  render() {
    if (this.state.loaded) {
      let output = this.state.controls;

      if (this.state.customLayout && this.state.useCustomLayout) {
        return this.state.customLayout({ control: output, object: this });
      }

      let accordionParent = "a" + Fun.uuid();

      return (
        <React.Fragment>
          <div
            id={accordionParent}
            style={{
              border: "1px solid",
              borderColor: "#eaeaea",
              borderRadius: "#d8dbe0",
            }}
          >
            {this.state.accordions.map((itm, idx) => {
              let accordionId = "a" + Fun.uuid();
              let show = idx == 0 ? "show" : "";

              return (
                <div
                  className="card"
                  style={{
                    marginBottom: "0px",
                    border: "0px",
                    borderRadius: "0px",
                  }}
                >
                  <div
                    className="card-header"
                    data-toggle="collapse"
                    data-target={"#a" + accordionId}
                    style={{ cursor: "pointer", backgroundColor: "#eaeaea" }}
                  >
                    <strong>{Fun.parse(itm.label)}</strong>
                  </div>
                  <div
                    id={"a" + accordionId}
                    className={"collapse " + show}
                    data-parent={"#" + accordionParent}
                  >
                    <div className="card-body">
                      {Object.keys(this.state.controlFields).map(
                        (ptm, indx) => {
                          if (itm.properties.indexOf(ptm) >= 0) {
                            let Control =
                              Controls[this.state.controlFields[ptm].control];

                            let controlValue = this.state.data[ptm];

                            let field = { ...this.state.controlFields[ptm] };
                            let alias = field["alias"];

                            const ControlComponent = (
                              <Control
                                field={field}
                                key={Fun.uuid()}
                                value={controlValue}
                                parent={this}
                              ></Control>
                            );

                            let ControlTemplate =
                              Themes[window["c_theme"]]["control"];

                            return (
                              <ControlTemplate
                                key={Fun.uuid()}
                                control={{
                                  field: field,
                                  component: ControlComponent,
                                }}
                              ></ControlTemplate>
                            );
                          }

                          return "";
                        }
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </React.Fragment>
      );
    }
    return "";
  }
}

export default Accordion;
