import React, { Component } from "react";
import Controls from "../controls";
import Themes from "../../themes/themes";
import Fun from "../../lib/functions";

class Multiple extends Component {
  state = {
    controls: [],
    customLayout: null,
    useCustomLayout: true,
    loaded: false,
    uuid: Fun.uuid(),
  };

  constructor(props) {
    super(props);
    this.addNew = this.addNew.bind(this);
    this.multipleLength = -1;
    this.deleteControl = this.deleteControl.bind(this);
    this.controlsGroup = this.controlsGroup.bind(this);
  }

  controlsGroup() {
    let controlConfig = this.props.field.config;
    let controlFields = controlConfig.properties;
    let controlName = this.props.field.alias;

    let namePrefix = this.props.namePrefix ? this.props.namePrefix : "";
    namePrefix = "";
    let controlsGroup = [];

    this.state.data.map((itmData, idxData) => {
      let controls = [];
      Object.keys(controlFields).map((itm, idx) => {
        let Control = Controls[controlFields[itm].control];

        let controlValue = itmData ? itmData[itm] : "sss";

        let field = { ...controlFields[itm] };

        let aliasPrefix = namePrefix + controlName + "[" + idxData + "]";
        let alias = field["alias"];
        field["alias"] = aliasPrefix + "[" + alias + "]";

        if (alias == "") {
          controlValue = itmData;
        }

        const ControlComponent = (
          <Control
            field={field}
            key={itmData["multiuuid"] + idx}
            value={controlValue}
            multipleIndex={idxData}
            aliasPrefix={aliasPrefix}
            controls={controls}
            parent={this}
            data={itmData}
          ></Control>
        );

        let ControlTemplate = Themes[window["c_theme"]]["control"];

        controls.push(
          <ControlTemplate
            key={"a" + itmData["multiuuid"] + idx}
            control={{
              field: field,
              component: ControlComponent,
            }}
            multipleIndex={idxData}
            aliasPrefix={aliasPrefix}
            controls={controls}
            parent={this}
          ></ControlTemplate>
        );
      });

      controlsGroup.push(controls);

      this.multipleLength++;
    });

    return controlsGroup;
  }

  componentDidMount() {
    let controlName = this.props.field.alias;

    let data =
      this.props.value && Array.isArray(this.props.value)
        ? this.props.value
        : [];

    if (this.props.field.config) {
      if (this.props.field.config.formatDefaultValue) {
        if (typeof this.props.field.config.formatDefaultValue === "function") {
          data = this.props.field.config.formatDefaultValue(data);
        }
      }
    }

    data.map((itm, idx) => {
      itm["multiuuid"] = Fun.uuid();
    });

    this.setState({
      data: data,
      controlName: controlName,
      customLayout: this.props.field.customLayout,
      addNewLabel: this.props.field.config.addNewLabel
        ? this.props.field.config.addNewLabel
        : "Add new +",
      useCustomLayout:
        typeof this.props.field.useCustomLayout !== "undefined"
          ? this.props.field.useCustomLayout
          : true,
      loaded: true,
    });
  }

  addNew() {
    let controlConfig = this.props.field.config;

    if (controlConfig.addNewFunction) {
      this["addNewFunction"] = controlConfig.addNewFunction.bind(this);
      this["addNewFunction"]();
      return;
    }

    let controlFields = controlConfig.properties;
    let controls = [];
    let namePrefix = this.props.namePrefix ? this.props.namePrefix : "";
    namePrefix = "";

    let newDataObject = { ...this.state.newDataObject };
    newDataObject["multiuuid"] = Fun.uuid().toString();

    Object.keys(controlFields).map((itm, idx) => {
      let Control = Controls[controlFields[itm].control];

      let field = { ...controlFields[itm] };
      let alias = field["alias"];
      field["alias"] =
        namePrefix +
        this.state.controlName +
        "[" +
        (this.multipleLength + 1) +
        "][" +
        alias +
        "]";

      field["validator"] = this.props.field.validator;

      const ControlComponent = (
        <Control
          field={field}
          key={newDataObject["multiuuid"] + idx}
          builder={this.props.builder}
        ></Control>
      );

      let ControlTemplate = Themes[window["c_theme"]]["control"];

      controls.push(
        <ControlTemplate
          control={{
            field: field,
            component: ControlComponent,
          }}
          key={"a" + newDataObject["multiuuid"] + idx}
        ></ControlTemplate>
      );
    });

    let data = this.state.data;
    data.push(newDataObject);
    this.setState({
      controls: [...this.state.controls, ...[controls]],
      data: data,
    });
  }

  deleteControl(idx) {
    let data = [...this.state.data];
    data.splice(idx, 1);
    this.setState({ data: data });
  }

  render() {
    if (this.state.loaded) {
      let output = [];

      output = this.controlsGroup(); //this.state.controls;

      if (this.state.customLayout && this.state.useCustomLayout) {
        this.state.customLayout = this.state.customLayout.bind(this);
        return this.state.customLayout({ control: output, object: this });
      }

      return (
        <React.Fragment>
          {output}

          <button
            type="button"
            className="btn btn-link pl-0 pt-3"
            onClick={this.addNew}
          >
            {this.state.addNewLabel}
          </button>
        </React.Fragment>
      );
    }
    return "";
  }
}

export default Multiple;
