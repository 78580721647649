import React, { Component } from "react";
import Fun from "../../../lib/functions";
import { Link } from "react-router-dom";

class Cells extends Component {
  state = {
    data: [],
    loaded: false,
    templateTop: "",
    templateBottom: "",
    templateLeft: "",
    templateRight: "",
    panelTop: "",
    panelBottom: "",
    panelLeft: "",
    panelRight: "",
    header: "",
    colsTemplateCount: 12,
    colsFormCount: 12,
    cellsConfig: {
      titleAttribute: "name",
    },
  };

  constructor(props) {
    super(props);

    // this.createBody = this.createBody.bind(this);
    this.createCells = this.createCells.bind(this);
    this.onClick = this.onClick.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.action) {
      return false;
    }
    return true;
  }

  componentDidMount() {
    let builder = this.props.builder.state;
    let templateConfig = builder.module.templateConfig;
    let useCard =
      typeof templateConfig.useCard !== "undefined"
        ? templateConfig.useCard
        : true;
    let templateTop = templateConfig.templateTop
      ? templateConfig.templateTop
      : () => {};

    this["templateTop"] = templateTop.bind(this);
    let templateBottom = templateConfig.templateBottom
      ? templateConfig.templateBottom
      : () => {};
    this["templateBottom"] = templateBottom.bind(this);
    let templateLeft = templateConfig.templateLeft
      ? templateConfig.templateLeft
      : () => {};
    this["templateLeft"] = templateLeft.bind(this);
    let templateRight = templateConfig.templateRight
      ? templateConfig.templateRight
      : () => {};
    this["templateRight"] = templateRight.bind(this);
    let cardTemplateTop = templateConfig.cardTemplateTop
      ? templateConfig.cardTemplateTop
      : () => {};
    this["cardTemplateTop"] = cardTemplateTop.bind(this);
    let cardTemplateBottom = templateConfig.cardTemplateBottom
      ? templateConfig.cardTemplateBottom
      : () => {};
    this["cardTemplateBottom"] = cardTemplateBottom.bind(this);
    let panelTop = templateConfig.panelTop ? templateConfig.panelTop : () => {};
    this["panelTop"] = panelTop.bind(this);
    let panelBottom = templateConfig.panelBottom
      ? templateConfig.panelBottom
      : () => {};
    this["panelBottom"] = panelBottom.bind(this);
    let panelLeft = templateConfig.panelLeft
      ? templateConfig.panelLeft
      : () => {};
    this["panelLeft"] = panelLeft.bind(this);
    let panelRight = templateConfig.panelRight
      ? templateConfig.panelRight
      : () => {};
    this["panelRight"] = panelRight.bind(this);
    let header = templateConfig.header ? templateConfig.header : () => {};
    this["header"] = header.bind(this);

    let emptyFolder = templateConfig.cellsConfig.emptyFolder
      ? templateConfig.cellsConfig.emptyFolder
      : () => {};
    this["emptyFolder"] = emptyFolder.bind(this);

    let colsTemplateCount = templateConfig.colsTemplateCount
      ? templateConfig.colsTemplateCount
      : this.state.colsTemplateCount;
    let colsFormCount = templateConfig.colsFormCount
      ? templateConfig.colsFormCount
      : this.state.colsFormCount;

    let cellsConfig = templateConfig.cellsConfig;

    this.setState({
      data: this.props.data,
      loaded: true,
      templateTop: templateTop,
      templateBottom: templateBottom,
      templateLeft: templateLeft,
      templateRight: templateRight,
      panelTop: panelTop,
      panelBottom: panelBottom,
      panelLeft: panelLeft,
      panelRight: panelRight,
      header: header,
      colsTemplateCount: colsTemplateCount,
      colsFormCount: colsFormCount,
      cellsConfig: cellsConfig,
    });
  }

  onClick(e, itm) {
    this.props.builder.setState({ loaded: false });
    this.props.builder.rerunComponent();
  }

  createCells() {
    let output = [];

    this.state.data.map((itm, idx) => {
      let cell = "";
      if (this.state.cellsConfig) {
        if (this.state.cellsConfig.cellTemplate) {
          cell = this.state.cellsConfig.cellTemplate(itm, this);
        }
      } else {
        cell = itm[this.state.cellsConfig.titleAttribute].value;
      }

      output.push(
        <div className="col-xl-2 col-lg-4 col-md-4 col-sm-6 p-2 text-center">
          {cell}
        </div>
      );
    });

    return <div className="row ">{output}</div>;
  }

  render() {
    if (this.state.loaded) {
      return (
        <React.Fragment>
          {this.header()}
          {this.panelTop()}
          <div className="container-fluid">
            {this.templateTop()}
            <div className="row">
              {this.panelLeft()}
              <div className={"col-" + this.state.colsTemplateCount}>
                <div className="row">
                  {this.templateLeft()}
                  <div className={"col-" + this.state.colsFormCount}>
                    {this.state.useCard != false ? (
                      <div className="card">
                        <div
                          className={
                            this.state.cellsConfig.cardBodyClass
                              ? "card-body " +
                                this.state.cellsConfig.cardBodyClass
                              : "card-body"
                          }
                        >
                          {this.cardTemplateTop()}

                          {this.content ? this.content() : this.createCells()}
                          {this.state.data.length <= 0
                            ? this.emptyFolder()
                            : ""}
                          {this.cardTemplateBottom()}
                        </div>
                      </div>
                    ) : (
                      this.createCells()
                    )}
                  </div>
                  {this.templateRight()}
                </div>
              </div>
              {this.panelRight()}
            </div>
            {this.templateBottom()}
          </div>
          {this.panelBottom()}
        </React.Fragment>
      );
    } else {
      return "loading...";
    }
  }
}

export default Cells;
