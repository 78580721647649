import React, { Component } from "react";
import moment from "moment";
import Fun from "../../lib/functions";
import TopTemplateHeader from "../../themes/default/templates/topTemplateHeader";
import UniFun from "./functions";
import { parse } from "uuid";
import Builder from "../../lib/builder";
import { Rectangle } from "@mui/icons-material";
import { Link } from "react-router-dom";

const addParticipant = function (id, parentBuilder) {
  let builder = parentBuilder;
  window["modal"].setState({
    title: <h3>Invite people and groups</h3>,
    size: "lg",
    targetComponent: null,
    show: true,
    overflowY: "unset",
    footer: UniFun.modalCommonFooter("modal", "Send invitation"),
    callback: function (args) {
      window["modal"].setState({ show: false, overflowY: "auto" });
      builder.rerunComponent();
    },
    body: (
      <Builder
        module="uniforms"
        view="participants"
        key={Fun.uuid()}
        inputData={{
          data: [
            {
              id: id,
              participants: [],
            },
          ],
        }}
        ref={(refComponent) => {
          window["modalbody"] = refComponent;
        }}
      ></Builder>
    ),
  });
};

function addForm(parentBuilder) {
  let builder = parentBuilder;
  window["modal"].setState({
    title: (
      <React.Fragment>
        <h3 className="pl-3">Create form</h3>
      </React.Fragment>
    ),
    size: "xl",
    targetComponent: this,
    parentBuilder: builder,
    footer: UniFun.modalCommonFooter("modal", "Create"),
    show: true,
    callback: function () {
      Fun.createNotification({
        message: "Form successfully created!",
        type: "success",
      });
      window["modal"].setState({ show: false });

      setTimeout(builder.rerunComponent, 200);
    },
    body: (
      <Builder
        module="uniforms"
        view="add"
        //parameters={parameters}
        key={Fun.uuid()}
        ref={(refComponent) => {
          window["modalbody"] = refComponent;
        }}
      ></Builder>
    ),
  });
}

const editForm = function (formId, parentBuilder) {
  let parameters = {
    replace: {
      id: formId,
    },
  };
  let builder = parentBuilder;
  window["modal"].setState({
    title: (
      <React.Fragment>
        <h3 className="pl-3">Edit form</h3>
      </React.Fragment>
    ),
    size: "xl",
    targetComponent: this,
    parentBuilder: builder,
    callback: function () {
      Fun.createNotification({
        message: "Form successfully saved!",
        type: "success",
      });
      window["modal"].setState({ show: false });
      builder.rerunComponent();
    },
    footer: UniFun.modalCommonFooter("modal", "Save"),
    show: true,
    body: (
      <Builder
        module="uniforms"
        view="edit"
        parameters={parameters}
        key={Fun.uuid()}
        ref={(refComponent) => {
          window["modalbody"] = refComponent;
        }}
      ></Builder>
    ),
  });
};

const deleteForm = function (formId, parentBuilder) {
  let parameters = {
    replace: {
      id: formId,
    },
  };
  let builder = parentBuilder;
  window["modal"].setState({
    title: (
      <React.Fragment>
        <h3 className="pl-3">Delete Form?</h3>
      </React.Fragment>
    ),
    size: "lg",
    targetComponent: this,
    parentBuilder: builder,
    callback: function () {
      Fun.createNotification({
        message: "Form successfully deleted!",
        type: "success",
      });
      window["modal"].setState({ show: false });

      setTimeout(builder.rerunComponent, 500);
    },
    footer: UniFun.modalCommonFooter("modal", "delete", "btn-danger"),
    show: true,
    body: (
      <Builder
        module="uniforms"
        view="delete"
        parameters={parameters}
        key={Fun.uuid()}
        ref={(refComponent) => {
          window["modalbody"] = refComponent;
        }}
      ></Builder>
    ),
  });
};

const UniForms = {
  auth: "",
  views: {
    list: {
      apis: {
        view: {
          url: "/forms",
          parameters: {
            replace: {},
            headers: {},
          },
          formatter: UniFormsFormatter,
        },
      },
      template: "squaresforms",
      templateConfig: {
        gridConfig: {},
        squaresConfig: {
          titleAttribute: "title",
          colorAttribute: "color",
          iconAttribute: "icon",
          btnOptions: {
            edit: {
              label: "Edit",
              onClick: function (e, itm, _this) {
                e.preventDefault();
                editForm(itm._id.value, _this.props.builder);
              },
            },
            copy: {
              label: "Copy link",
              onClick: function (e, itm, _this) {
                e.preventDefault();
                UniFun.copyToClipboard(
                  UniFun.createURL("/form/m-" + itm._id.value)
                );
              },
            },
            invite: {
              label: "Send invitation",
              onClick: function (e, itm, _this) {
                e.preventDefault();
                addParticipant(itm._id.value, _this.props.builder);
              },
            },
            results: {
              label: "View results",
              onClick: function (e, itm, _this) {
                e.preventDefault();
                _this.props.history.push(
                  "/forms/" + itm._id.value + "/results"
                );
              },
            },
            delete: {
              label: <span className="text-danger">Delete form</span>,
              onClick: function (e, itm, _this) {
                e.preventDefault();
                deleteForm(itm._id.value, _this.props.builder);
              },
            },
          },
        },
        colsTemplateCount: 12,
        includeProperties: ["_id", "title"],
        headerTitle: "Overview",
        header: UniFun.commonHeader,
        // cardTemplateTop: function () {
        //   return <button onClick={addForm}>add</button>;
        // },
      },
      pk: "_id",
      properties: UniFormsProperties({ editable: true }),
    },
    listResults: {
      apis: {
        view: {
          url: "/forms",
          parameters: {
            replace: {},
            headers: {},
          },
          formatter: function (data) {
            return UniFormsFormatter(data, false);
          },
        },
      },
      template: "squaresforms",
      templateConfig: {
        gridConfig: {},
        squaresConfig: {
          titleAttribute: "title",
          colorAttribute: "color",
          iconAttribute: "icon",
        },
        colsTemplateCount: 12,
        includeProperties: ["_id", "title"],
        headerTitle: "Results",
        header: UniFun.commonHeader,
        // cardTemplateTop: function () {
        //   return <button onClick={addForm}>add</button>;
        // },
      },
      pk: "_id",
      properties: UniFormsProperties({ editable: true }),
    },
    edit: {
      auth: "",
      apis: {
        view: {
          url: "/forms/{id}",
          parameters: {
            headers: {},
            replace: {},
          },
          formatter: function (data) {
            return UniFormsFormatter(data, false);
          },
        },
        update: {
          url: "/forms/",
          parameters: {
            headers: {},
            replace: {},
          },
          formatter: function (data) {
            return UniFormsFormatter(data, false);
          },
        },
      },
      template: "form",
      templateConfig: {
        events: {
          onSubmit: function (e) {
            e.preventDefault();

            let submitJSON = Fun.getSubmitJSON(e);

            let jsonData = UniFun.formJSONData(submitJSON);

            let _this = this;

            Fun.update(e, {
              url: this.props.builder.state.module.apis.update,
              method: "PUT",
              callback: function (
                submittedData,
                status,
                responsedata,
                responsestatus
              ) {
                if (responsestatus == "200") {
                  if (window["modal"].state.callback) {
                    window["modal"].state.callback(submittedData);
                  }
                } else {
                  if (responsedata.data.exceptions) {
                    responsedata.data.exceptions.map((itm, idx) => {
                      Fun.createNotification({
                        message: itm.errorDescription,
                        type: "danger",
                      });
                    });
                  }
                }
              },
              parameters: {
                headers: {
                  "content-type": "application/json",
                },
              },
              submittedData: JSON.stringify(jsonData),
            });
          },
        },
        includeProperties: [],
        excludeProperties: ["questionsForUserForm"],
      },
      pk: "_id",
      properties: UniFormsProperties({ editable: true }),
    },
    delete: {
      auth: "",
      apis: {
        view: {
          url: "/forms/{id}",
          parameters: {
            headers: {},
            replace: {},
          },
          formatter: function (data) {
            return UniFormsFormatter(data, false);
          },
        },
        delete: {
          url: "/forms/{id}",
          parameters: {
            headers: {},
            replace: {},
          },
          formatter: function (data) {
            return UniFormsFormatter(data, false);
          },
        },
      },
      template: "form",
      templateConfig: {
        includeProperties: ["_id"],
        useCard: false,
        templateTop: function () {
          let data = this.state.data[0];
          return (
            <div className=" p-4">
              <p>
                This action:<br></br>- will delete the form{" "}
                <strong>{data.title.value}</strong>. In the case it is available
                publicly the form will become inactive.<br></br>
                This action cannot be undone
              </p>
            </div>
          );
        },
        events: {
          onSubmit: function (e) {
            e.preventDefault();

            let submitJSON = Fun.getSubmitJSON(e);

            let _this = this;

            Fun.update(e, {
              url: this.props.builder.state.module.apis.delete,
              method: "DELETE",
              callback: function (
                submittedData,
                status,
                responsedata,
                responsestatus
              ) {
                if (responsestatus == "200") {
                  if (window["modal"].state.callback) {
                    window["modal"].state.callback(submittedData);
                  }
                } else {
                  if (responsedata.data.exceptions) {
                    responsedata.data.exceptions.map((itm, idx) => {
                      Fun.createNotification({
                        message: itm.errorDescription,
                        type: "danger",
                      });
                    });
                  }
                }
              },
              parameters: {
                replace: {
                  id: submitJSON["_id"],
                },
                headers: {
                  "content-type": "application/json",
                },
              },
            });
          },
        },
      },
      pk: "_id",
      properties: UniFormsProperties({ editable: true }),
    },
    participants: {
      apis: {
        view: {
          data: { data: [{}] },
          parameters: {
            headers: {},
            replace: {},
          },
          formatter: function (data) {
            if (data) {
              if (data.data) {
                return data.data;
              }
            }
            return [];
          },
        },
        save: {
          url: "/forms/{id}/send-invites",
          parameters: {
            headers: {},
            replace: {},
          },
          formatter: function (data) {
            return [];
          },
        },
      },
      template: "form",
      templateConfig: {
        cardTemplateBottom: function () {
          return (
            <span className="text-dark">
              invitees will receive an email with the link of the form.
            </span>
          );
        },
        events: {
          onSubmit: function (e) {
            e.preventDefault();
            let submittedData = Fun.getSubmitJSON(e);

            let invitees = [];

            try {
              JSON.parse(submittedData["participants"]).map((itm, idx) => {
                let user = {};

                switch (itm.group) {
                  case "group":
                    user = { type: "group", _id: itm._id };
                    break;
                  case "participant":
                    user = { type: "participant", _id: itm._id };
                    break;
                  default:
                    user = { type: "participant", _id: itm._id };
                    break;
                }

                invitees.push(user);
              });
            } catch (e) {}

            Fun.update(e, {
              url: this.props.builder.state.module.apis.save,
              method: "POST",
              callback: function (
                submittedData,
                status,
                responsedata,
                responsestatus
              ) {
                if (responsestatus == "200") {
                  if (window["modal"].state.callback) {
                    window["modal"].state.callback(submittedData);
                  }
                } else {
                  if (responsedata.data.exceptions) {
                    responsedata.data.exceptions.map((itm, idx) => {
                      Fun.createNotification({
                        message: itm.errorDescription,
                        type: "danger",
                      });
                    });
                  }
                }
              },
              parameters: {
                headers: {
                  "content-type": "application/json",
                },
                replace: {
                  id: submittedData["id"],
                },
              },
              submittedData: JSON.stringify({ users: invitees }),
            });
          },
        },
        includeProperties: ["id", "participants"],
      },
      pk: "_id",
      properties: participantsProperties(),
    },
    results: {
      auth: "",
      apis: {
        view: {
          url: "/forms/{id}",
          parameters: {
            headers: {},
            replace: {
              id: function () {
                return Fun.getURLPathValue(2).replace("m-", "");
              },
            },
          },
          formatter: function (data) {
            return UniFormsFormatter(data, false);
          },
        },
      },
      template: "form",
      templateConfig: {
        events: {},
        useCard: false,
        header: function () {
          return (
            <div className={"uniTopHeader "}>
              <TopTemplateHeader
                customClass={"mb-4 uniFixedHeader "}
                subtitle="Results"
                title={
                  <React.Fragment>
                    <span title={this.state.data[0].title.value}>
                      {UniFun.substring(this.state.data[0].title.value, 0, 25)}
                    </span>
                  </React.Fragment>
                }
              ></TopTemplateHeader>
            </div>
          );
        },
        includeProperties: ["formParticipants"],
      },
      pk: "_id",
      properties: UniFormsResultsProperties({ editable: true }),
    },
    formParticipants: {
      auth: "",
      apis: {
        view: {
          url: "/user-forms/forms/{id}",
          parameters: {
            headers: {},
            replace: {
              id: function () {
                return Fun.getURLPathValue(2).replace("m-", "");
              },
            },
          },
          formatter: function (data) {
            return UniFormsUserResultsFormatter(data, true);
          },
        },
      },
      template: "grid",
      templateConfig: {
        excludeProperties: ["_id"],
        gridConfig: {
          cellTemplates: {
            participant: function (data, rec, obj) {
              let uid = Fun.uuid();

              let builder = obj.props.builder;

              return (
                <React.Fragment>
                  <Link to={"/forms/users/" + rec._id.value}>
                    {rec.participant.component}
                  </Link>
                </React.Fragment>
              );
            },
          },
        },
        cardTemplateTop: function () {
          let data = this.state.data;
          return (
            <div className="row">
              <div className="col-6 pt-2 pl-4">
                <span className="text-dark ">
                  There are {data.length} results
                </span>
              </div>
              <div className="col-6 text-right p-2 pr-4"></div>
            </div>
          );
        },
      },
      pk: "_id",
      properties: UniFormsUserResultsProperties({ editable: true }),
    },
    formResult: {
      auth: "",
      apis: {
        view: {
          url: "/user-forms/{id}",
          parameters: {
            headers: {},
            replace: {
              id: function () {
                return Fun.getURLPathValue(3).replace("m-", "");
              },
            },
          },
          formatter: function (data) {
            return UniFormsFormFormatter(data);
          },
        },
      },
      template: "form",
      templateConfig: {
        colsTemplateCount: "md-8",
        cardBodyCustomClass: "p-5",
        header: function () {
          let data = this.state.data[0];

          return (
            <header className=" c-header c-header-light c-header-fixed c-header-with-subheader ">
              <div className="row w-100">
                <div className="col-md-6 pt-3">
                  <img
                    src="/uni/svg/trainings/go_back.svg"
                    className="pr-3 pointer ml-3 pt-1"
                    style={{ width: "45px", paddingBottom: "5px" }}
                    onClick={(e) => {
                      window.location =
                        "/forms/" + data.formId.value + "/results";
                    }}
                  ></img>
                  <div className="d-inline-block pb-2">
                    {data.participant.component}
                  </div>
                </div>
                <div className="col-md-6 text-right pt-4">
                  <strong>Submitted:</strong> {data.createdAt.component}
                </div>
              </div>
            </header>
          );
        },
        panelLeft: function () {
          return <div className="col-md-2"></div>;
        },
        panelTop: function () {
          return (
            <div className="row mt-5">
              <p></p>
            </div>
          );
        },
        panelBottom: function () {
          return (
            <div className="row mt-5">
              <p></p>
            </div>
          );
        },
        panelRight: function () {
          return <div className="col-md-2"></div>;
        },
        includeProperties: ["formId", "questions"],

        cardTemplateTop: function () {
          let data = this.state.data[0];
          return (
            <>
              <div className="row">
                <div className="col-12">
                  <h2>{data.title.component}</h2>
                </div>
                <div className="col-12">
                  <p>
                    {" "}
                    Instructions:{" "}
                    {Fun.parse(
                      data.instructions.value
                        .replaceAll("<p>", "")
                        .replaceAll("</p>", "")
                    )}
                  </p>
                </div>
              </div>
              <hr></hr>
            </>
          );
        },
      },
      pk: "_id",
      properties: UniFormsFormProperties({
        editable: false,
        showQuestionEditable: true,
      }),
    },
    privateForm: {
      auth: "",
      apis: {
        view: {
          url: "/user-forms/new-user-form/{id}",
          parameters: {
            headers: {},
            replace: {
              id: function () {
                return Fun.getURLPathValue(2).replace("m-", "");
              },
            },
          },
          formatter: function (data) {
            return UniFormsFormFormatter(data);
          },
        },
        save: {
          url: "/user-forms",
          parameters: {
            headers: {},
            replace: {},
          },
          formatter: function (data) {
            return UniFormsFormFormatter(data);
          },
        },
      },
      template: "form",
      templateConfig: {
        colsTemplateCount: "md-8",
        cardBodyCustomClass: "p-5",
        panelLeft: function () {
          return <div className="col-md-2"></div>;
        },
        panelTop: function () {
          return (
            <div className="row mt-5">
              <p></p>
            </div>
          );
        },
        panelBottom: function () {
          return (
            <div className="row mt-5">
              <p></p>
            </div>
          );
        },
        panelRight: function () {
          return <div className="col-md-2"></div>;
        },
        includeProperties: ["formId", "questions", "terms"],
        cardTemplateBottom: function () {
          return (
            <>
              <div className="mt-4">{this.state.data[0].submit.component}</div>
              {!UniFun.isLoggedIn() ? (
                <div className="mt-3 ">
                  <small>
                    This site is protected by reCAPTCHA and{" "}
                    <a
                      href="https://policies.google.com/privacy?hl=en"
                      target="_blank"
                      className="text-primary"
                    >
                      Google Privacy Policy
                    </a>{" "}
                    and{" "}
                    <a
                      href="https://policies.google.com/terms?hl=en"
                      target="_blank"
                      className="text-primary"
                    >
                      Term
                    </a>{" "}
                    apply.
                  </small>
                </div>
              ) : (
                ""
              )}
            </>
          );
        },
        cardTemplateTop: function () {
          let data = this.state.data[0];
          return (
            <>
              <div className="row">
                <div className="col-12">
                  <h2>{data.title.component}</h2>
                </div>
                <div className="col-12">
                  <p>
                    {" "}
                    Instructions:{" "}
                    {Fun.parse(
                      data.instructions.value
                        .replaceAll("<p>", "")
                        .replaceAll("</p>", "")
                    )}
                  </p>
                </div>
              </div>
              <hr></hr>
            </>
          );
        },
        events: {
          onSubmit: function (e) {
            e.preventDefault();

            let submitJSON = Fun.getSubmitJSON(e);

            //let jsonData = UniFun.formJSONData(submitJSON);

            if (!UniFun.isLoggedIn()) {
              if (submitJSON["terms"] != "true") {
                Fun.createNotification({
                  message: "Please accept the terms and privacy policy",
                  type: "danger",
                });
                return;
              }
              delete submitJSON["terms"];
            }

            submitJSON["formId"] = Fun.getURLPathValue(2).replace("m-", "");

            //let jsonData = UniFun.formJSONData(submitJSON);

            submitJSON.questions.map((itm, idx) => {
              delete itm["_id"];
            });

            let _this = this;

            Fun.update(e, {
              url: this.props.builder.state.module.apis.save,
              method: "POST",
              callback: function (
                submittedData,
                status,
                responsedata,
                responsestatus
              ) {
                if (responsestatus == "200") {
                  Fun.createNotification({
                    message: "Form successfully submitted",
                    type: "success",
                  });
                } else {
                  if (responsedata.data.exceptions) {
                    responsedata.data.exceptions.map((itm, idx) => {
                      Fun.createNotification({
                        message: itm.errorDescription,
                        type: "danger",
                      });
                    });
                  }
                }
              },
              parameters: {
                headers: {
                  "content-type": "application/json",
                },
              },
              submittedData: JSON.stringify(submitJSON),
            });
          },
        },
      },
      pk: "_id",
      properties: UniFormsFormProperties({
        editable: false,
        showQuestionEditable: true,
      }),
    },
    publicMainForm: {
      auth: "",
      apis: {
        view: {
          url: "{path}",
          parameters: {
            headers: {},
            replace: {
              path: function () {
                if (Fun.getURLPathValue(2)) {
                  return "/user-forms/new-user-form/" + Fun.getURLPathValue(2);
                }
                return "/public-form";
              },
            },
          },
          formatter: function (data) {
            return UniFormsFormFormatter(data);
          },
        },
        save: {
          url: "/user-forms",
          parameters: {
            headers: {},
            replace: {},
          },
          formatter: function (data) {
            return UniFormsFormFormatter(data);
          },
        },
      },
      template: "form",
      templateConfig: {
        colsTemplateCount: "md-8",
        cardBodyCustomClass: "p-5",
        panelLeft: function () {
          return <div className="col-md-2"></div>;
        },
        panelTop: function () {
          return (
            <div className="row mt-5">
              <p></p>
            </div>
          );
        },
        panelBottom: function () {
          return (
            <div className="row mt-5">
              <p></p>
            </div>
          );
        },
        header: function () {
          return (
            <header className=" c-header c-header-light c-header-fixed c-header-with-subheader pl-5 pr-5 pb-2">
              <div className="row w-100">
                <div className="col-md-6 pt-3">
                  {window.location.hostname.toLocaleLowerCase() ==
                    "pella.hapeiron.com" ||
                  window.location.hostname.toLocaleLowerCase() == "localhost" ||
                  window.location.hostname.toLocaleLowerCase() ==
                    "staging-v2.interfima.org" ? (
                    <a href="/challenge">
                      <img
                        src="/challenge/images/logo.png"
                        style={{ height: "50px" }}
                      />
                    </a>
                  ) : (
                    ""
                  )}
                  <div className="d-inline-block pb-2"></div>
                </div>
                <div className="col-md-6 text-right pt-4">
                  <span class="ms-auto me-3">
                    <strong>
                      {window.location.hostname.toLocaleLowerCase() ==
                        "pella.hapeiron.com" ||
                      window.location.hostname.toLocaleLowerCase() ==
                        "localhost" ||
                      window.location.hostname.toLocaleLowerCase() ==
                        "staging-v2.interfima.org"
                        ? "APPLICATIONS open JUL 01,2022"
                        : ""}
                    </strong>
                  </span>
                </div>
              </div>
            </header>
          );
        },
        panelRight: function () {
          return <div className="col-md-2"></div>;
        },
        includeProperties: ["formId", "questions", "terms"],
        cardTemplateBottom: function () {
          return (
            <>
              <div className="mt-4">{this.state.data[0].submit.component}</div>
              {!UniFun.isLoggedIn() ? (
                <div className="mt-3 ">
                  <small>
                    This site is protected by reCAPTCHA and{" "}
                    <a
                      href="https://policies.google.com/privacy?hl=en"
                      target="_blank"
                      className="text-primary"
                    >
                      Google Privacy Policy
                    </a>{" "}
                    and{" "}
                    <a
                      href="https://policies.google.com/terms?hl=en"
                      target="_blank"
                      className="text-primary"
                    >
                      Term
                    </a>{" "}
                    apply.
                  </small>
                </div>
              ) : (
                ""
              )}
            </>
          );
        },
        cardTemplateTop: function () {
          let data = this.state.data[0];
          return (
            <>
              <div className="row">
                <div className="col-12">
                  <h2>{data.title.value}</h2>
                </div>
                <div className="col-12">
                  <p>{Fun.parse(data.instructions.value)}</p>
                </div>
              </div>
              <hr></hr>
            </>
          );
        },
        events: {
          componentDidMountEvent: function () {
            if (!Fun.getURLPathValue(2)) {
              window.location =
                "/challenge-form/" + this.state.data[0]._id.value;
            }
            return;
          },
          onSubmit: function (e) {
            e.preventDefault();

            let submitJSON = Fun.getSubmitJSON(e);

            if (!UniFun.isLoggedIn()) {
              if (submitJSON["terms"] != "true") {
                Fun.createNotification({
                  message: "Please accept the terms and privacy policy",
                  type: "danger",
                });
                return;
              }
              delete submitJSON["terms"];
            }

            //let jsonData = UniFun.formJSONData(submitJSON);

            submitJSON["formId"] = Fun.getURLPathValue(2).replace("m-", "");

            //let jsonData = UniFun.formJSONData(submitJSON);

            submitJSON.questions.map((itm, idx) => {
              delete itm["_id"];
            });

            let _this = this;

            Fun.update(e, {
              url: this.props.builder.state.module.apis.save,
              method: "POST",
              callback: function (
                submittedData,
                status,
                responsedata,
                responsestatus
              ) {
                if (responsestatus == "200") {
                  Fun.createNotification({
                    message: "Form successfully submitted",
                    type: "success",
                  });
                } else {
                  if (responsedata.data.exceptions) {
                    responsedata.data.exceptions.map((itm, idx) => {
                      Fun.createNotification({
                        message: itm.errorDescription,
                        type: "danger",
                      });
                    });
                  }
                }
              },
              parameters: {
                headers: {
                  "content-type": "application/json",
                },
              },
              submittedData: JSON.stringify(submitJSON),
            });
          },
        },
      },

      pk: "_id",
      properties: UniFormsFormProperties({
        editable: false,
        showQuestionEditable: true,
      }),
    },
    add: {
      auth: "",
      apis: {
        view: {
          data: { data: [] },
          parameters: {
            headers: {},
            replace: {},
          },
          formatter: function (data) {
            return UniFormsFormatter(data, true);
          },
        },
        save: {
          url: "/forms",
          parameters: {
            headers: {},
            replace: {},
          },
          formatter: function (data) {
            return UniFormsFormatter(data, false);
          },
        },
      },
      template: "form",
      templateConfig: {
        events: {
          onSubmit: function (e) {
            e.preventDefault();

            let submitJSON = Fun.getSubmitJSON(e);

            let jsonData = UniFun.formJSONData(submitJSON);

            jsonData["questions"].map((itm, idx) => {
              delete itm["_id"];
            });

            delete jsonData["_id"];

            Fun.update(e, {
              url: this.props.builder.state.module.apis.save,
              method: "POST",
              callback: function (
                submittedData,
                status,
                responsedata,
                responsestatus
              ) {
                if (responsestatus == "200") {
                  if (window["modal"].state.callback) {
                    window["modal"].state.callback(submittedData);
                  }
                } else {
                  if (responsedata.data.exceptions) {
                    responsedata.data.exceptions.map((itm, idx) => {
                      Fun.createNotification({
                        message: itm.errorDescription,
                        type: "danger",
                      });
                    });
                  }
                }
              },
              parameters: {
                headers: {
                  "content-type": "application/json",
                },
              },
              submittedData: JSON.stringify(jsonData),
            });
          },
        },
        includeProperties: [],
      },
      pk: "_id",
      properties: UniFormsProperties({ editable: true }),
    },
  },
};

function UniFormsUserResultsProperties(input) {
  let editable = false;
  return {
    _id: {
      alias: "_id",
      control: "text",
      editable: editable,
    },
    participant: {
      control: "text",
      alias: "participant",
      label: "Name",
      editable: false,
      useCustomLayout: true,
      customLayout: function () {
        if (this.props.value) {
          let person = JSON.parse(this.props.value);
          let output = UniFun.createProfileImageBubble([person]);
          return output;
        }
        return "";
      },
    },
    name: {
      alias: "Name",
      control: "text",
      editable: editable,
      attributes: {
        className: "form-control",
      },
    },
    userType: {
      alias: "userType",
      control: "text",
      label: "User type",
      editable: editable,
      attributes: {
        className: "form-control",
      },
    },
    email: {
      alias: "email",
      control: "text",
      label: "Email",
      editable: editable,
      attributes: {
        className: "form-control",
      },
    },
    status: {
      alias: "status",
      control: "text",
      label: "Status",
      editable: editable,
      attributes: {
        className: "form-control",
      },
    },

    createdAt: {
      alias: "createdAt",
      control: "datetime",
      label: "Submitted on",
      editable: editable,
      attributes: {
        className: "form-control",
      },
    },
  };
}

function UniFormsUserResultsFormatter(data) {
  let formattedData = [];

  if (data) {
    if (data.data) {
      data.data.map((itm, idx) => {
        try {
          let formattedRecord = {};

          formattedRecord["_id"] = itm._id;
          formattedRecord["userType"] = itm.userType;
          formattedRecord["email"] = itm.userInfo.email;
          formattedRecord["status"] = itm.status;
          formattedRecord["createdAt"] = itm.createdAt;
          formattedRecord["participant"] = JSON.stringify(itm.userInfo);

          formattedData.push(formattedRecord);
        } catch (e) {
          console.log(e);
        }
      });
    }
  }

  return formattedData;
}

function UniFormsResultsProperties(input) {
  return {
    _id: {
      alias: "_id",
      control: "text",
    },
    title: {
      alias: "title",
      control: "text",
    },
    formParticipants: {
      alias: "formParticipants",
      control: "module",
      editable: false,
      config: {
        module: "uniforms",
        view: "formParticipants",
        parameters: {
          replace: {
            id: function () {
              return Fun.getURLPathValue(2).replace("m-", "");
            },
          },
        },
      },
    },
  };
}

function UniFormsFormFormatter(data) {
  let formattedData = [];

  if (data) {
    if (data.data) {
      data.data.map((itm, idx) => {
        try {
          let formattedRecord = {};

          formattedRecord["_id"] = itm._id;

          formattedRecord["formId"] = itm.formId;
          formattedRecord["title"] = itm.title;
          formattedRecord["instructions"] = itm.instructions;

          formattedRecord["type"] = itm.type;
          formattedRecord["isAnonymous"] = itm.isAnonymous;
          formattedRecord["questions"] = itm.questions;
          formattedRecord["createdAt"] = itm.createdAt;
          formattedRecord["participant"] = JSON.stringify(itm.userInfo);
          formattedRecord["terms"] = "";

          formattedData.push(formattedRecord);
        } catch (e) {
          console.log(e);
        }
      });
    }
  }

  return formattedData;
}

function UniFormsFormProperties(input) {
  let editable = input.editable;
  let showQuestionEditable = input.showQuestionEditable;
  return {
    _id: {
      alias: "_id",
      control: "hidden",
      editable: editable,
      attributes: {},
    },
    formId: {
      alias: "formId",
      control: "hidden",
      editable: editable,
      attributes: {},
    },
    title: {
      alias: "title",
      control: "text",
      label: "Title",
      placeholder: "Type form title",
      validations: "required",
      editable: editable,
      attributes: {
        className: "form-control",
      },
    },
    instructions: {
      alias: "instructions",
      control: "editor",
      label: "Instructions",
      placeholder: "Type form instructions",
      editable: editable,
      attributes: {
        className: "form-control",
      },
    },
    type: {
      alias: "type",
      control: "hidden",
    },
    isAnonymous: {
      alias: "isAnonymous",
      control: "hidden",
    },
    questions: {
      alias: "questions",
      control: "multiple",
      label: "Questions",
      editable: editable,
      customLayout: function (args) {
        let controls = args.control;

        let output = [];

        controls.map((itm, idx) => {
          output.push(
            <div className="row pt-2 pb-4">
              <div className="col-10">
                <span className="mb-2 d-block ">
                  {itm[2].props.control.component}
                  {itm[4].props.control.component}
                </span>
              </div>
              <div className="col-2"></div>
              <div className="col-12">
                {itm[0].props.control.component}
                {itm[1].props.control.component}
                {itm[3].props.control.component}
                {/* {itm[5].props.control.component}  */}
              </div>
            </div>
          );
        });

        return <React.Fragment>{output}</React.Fragment>;
      },
      useCustomLayout: true,
      config: {
        properties: {
          _id: {
            alias: "_id",
            control: "hidden",
            label: "",
            editable: editable,
          },
          type: {
            alias: "type",
            control: "hidden",
            label: "",
            defaultValue: "multipleChoice",
            editable: editable,
            attributes: {
              className: "form-control",
            },
          },

          question: {
            alias: "question",
            control: "hidden",
            label: "Question",
            placeholder: "Type a question",
            editable: editable,
            useCustomLayout: true,
            customLayout: function (args) {
              return (
                <React.Fragment>
                  <strong>{args.object.props.multipleIndex + 1}.</strong>{" "}
                  {Fun.parse(
                    this.props.value
                      .replaceAll("<p>", "")
                      .replaceAll("</p>", "")
                  )}
                  {args.control}
                </React.Fragment>
              );
            },
            attributes: {
              className: "form-control",
            },
          },
          answers: {
            alias: "answers",
            control: "multiple",
            editable: editable,
            customLayout: function (args) {
              let output = [];

              let qtype = this.props.data.type;

              switch (qtype) {
                case "essayType":
                  args.control.map((itm, idx) => {
                    output.push(
                      <React.Fragment>
                        <div className="row  mt-0 pt-2">
                          <div className="col-9 pr-0">
                            {itm[2].props.control.component}
                          </div>
                          <div className="col-3"></div>
                        </div>
                      </React.Fragment>
                    );
                  });

                  break;

                default:
                  args.control.map((itm, idx) => {
                    output.push(
                      <div className="row pt-4 mt-0 pl-3">
                        <div
                          className="col-1"
                          style={{
                            maxWidth: "32px",
                          }}
                        >
                          {itm[1].props.control.component}
                        </div>
                        <div className="col-9 pr-0">
                          {itm[2].props.control.component}
                        </div>
                        <div className="col-2"></div>
                      </div>
                    );
                  });
                  break;
              }

              return <React.Fragment>{output}</React.Fragment>;
            },
            useCustomLayout: true,
            config: {
              formatDefaultValue: function (data) {
                if (data.length <= 0) {
                  return [{}];
                }
                return data;
              },
              properties: {
                _id: {
                  alias: "_id",
                  control: "hidden",
                  label: "",
                  editable: editable,
                },
                userAnswer: {
                  alias: "userAnswer",
                  control: "checkbox",
                  config: {
                    useHiddenField: true,
                    useTrueFalseValue: true,
                  },
                  editable: true,
                  attributes: {
                    className:
                      "form-check-input form-control mt-0 test-answers-checkbox",
                  },
                  useCustomLayout: false,
                },
                answer: {
                  alias: "answer",
                  control: "hidden",
                  placeholder:
                    "Type answer and check the box if it's the correct one",
                  editable: editable,
                  attributes: {
                    className: "form-control",
                  },
                  useCustomLayout: true,
                  customLayout: function (args) {
                    let qtype = this.props.parent.props.data.type;

                    let ftype =
                      args.object.props.parent.props.parent.props.data.type;
                    let isAnonymous =
                      args.object.props.parent.props.parent.props.data
                        .isAnonymous !== "false"
                        ? args.object.props.parent.props.parent.props.data
                            .isAnonymous
                        : false;

                    if (
                      args.object.props.parent.props.multipleIndex < 3 &&
                      (ftype == "public" || ftype == "public-main") &&
                      !isAnonymous &&
                      !UniFun.isLoggedIn()
                    ) {
                      qtype = "userinfo";
                    }

                    switch (qtype) {
                      case "essayType":
                        return (
                          <div>
                            {this.props.parent.props.data.image ? (
                              <img
                                src={
                                  window["baseurl"](
                                    "/agenda-file/data/" +
                                      this.props.parent.props.data.image
                                  ) +
                                  "?type=preview&jwt=" +
                                  localStorage.getItem("jwt")
                                }
                                className="mb-3 mt-2 pointer w-75"
                                // style={{ height: "300px" }}
                                onClick={(e) => {
                                  UniFun.readingsPreviewFile(
                                    {
                                      title: "Question Image",
                                      download: false,
                                      mimetype: "image/jpeg",
                                      fileurl: window["baseurl"](
                                        "/agenda-file/data/" +
                                          this.props.parent.props.data.image +
                                          "?type=preview&jwt=" +
                                          localStorage.getItem("jwt")
                                      ),
                                      filedownloadurl: window["baseurl"](
                                        "/agenda-file/data/" +
                                          this.props.parent.props.data.image +
                                          "?type=download&jwt=" +
                                          localStorage.getItem("jwt")
                                      ),
                                    },
                                    null
                                  );
                                }}
                              ></img>
                            ) : (
                              ""
                            )}

                            {showQuestionEditable ? (
                              <textarea
                                className="form-control mt-2 border"
                                defaultValue={this.props.value}
                                name={this.state.name}
                              ></textarea>
                            ) : (
                              this.props.value
                            )}
                          </div>
                        );
                        break;

                      case "userinfo":
                        return showQuestionEditable ? (
                          <input
                            className="form-control"
                            defaultValue={this.props.value}
                            name={this.state.name}
                          ></input>
                        ) : (
                          this.props.value
                        );

                        break;

                      case "multipleChoice":
                        return (
                          <React.Fragment>
                            {this.props.value}
                            {args.control}
                          </React.Fragment>
                        );
                        break;
                    }

                    return "";
                  },
                },
                image: {
                  alias: "image",
                  control: "text",
                  label: "",
                  placeholder: "",
                  editable: editable,
                  useCustomLayout: true,
                  customLayout: function (args) {
                    if (this.state.defaultValue) {
                      return (
                        <img
                          src={
                            window["baseurl"](
                              "/agenda-file/data/" + this.state.defaultValue
                            ) +
                            "?type=preview&jwt=" +
                            localStorage.getItem("jwt")
                          }
                          className="mb-2 mt-2 pointer w-75"
                          //style={{ height: "300px" }}
                          onClick={(e) => {
                            UniFun.readingsPreviewFile(
                              {
                                title: "Question Image",
                                download: false,
                                mimetype: "image/jpeg",
                                fileurl: window["baseurl"](
                                  "/agenda-file/data/" +
                                    this.state.defaultValue +
                                    "?type=preview&jwt=" +
                                    localStorage.getItem("jwt")
                                ),
                                filedownloadurl: window["baseurl"](
                                  "/agenda-file/data/" +
                                    this.state.defaultValue +
                                    "?type=download&jwt=" +
                                    localStorage.getItem("jwt")
                                ),
                              },
                              null
                            );
                          }}
                        ></img>
                      );
                    }
                    return "";
                  },
                  attributes: {
                    className: "form-control",
                  },
                },
              },
            },
          },
          isRequired: {
            alias: "isRequired",
            control: "hidden",
            label: "",
            editable: editable,
            attributes: {
              className: "form-control",
            },
            useCustomLayout: true,
            customLayout: function (args) {
              return this.props.value ? " *" : "";
            },
          },
        },
      },
    },
    terms: {
      alias: "terms",
      control: "checkbox",
      editable: true,
      config: {
        useTrueFalseValue: true,
      },
      attributes: {
        className: "form-check-input",
      },
      useCustomLayout: true,
      customLayout: function (args) {
        return !UniFun.isLoggedIn() ? (
          <div className="form-check">
            {args.control}
            <label className="form-check-label">
              I confirm I have read and accept the{" "}
              <a href="/challenge/terms.html" className="text-primary">
                terms
              </a>{" "}
              and{" "}
              <a href="/challenge/privacy.html" className="text-primary">
                privacy
              </a>{" "}
              policy
            </label>
          </div>
        ) : (
          ""
        );

        return !UniFun.isLoggedIn() ? (
          <div className="row  mt-4">
            <div className="col-md-12" style={{ maxWidth: "55px" }}>
              <span></span>
            </div>
          </div>
        ) : (
          ""
        );
      },
    },
    createdAt: {
      alias: "createdAt",
      control: "datetime",
      caption: "",
      editable: editable,
      attributes: {
        className: "form-controml ",
      },
    },
    participant: {
      control: "text",
      alias: "participant",
      label: "Name",
      editable: false,
      useCustomLayout: true,
      customLayout: function () {
        if (this.props.value) {
          let person = JSON.parse(this.props.value);
          let output = UniFun.createProfileImageBubble([person]);
          return output;
        }
        return "";
      },
    },
    submit: {
      alias: "submit",
      control: "submit",
      caption: "Submit",
      editable: editable,
      attributes: {
        className: "btn btn-primary ",
      },
    },
  };
}

function UniFormsFormatter(data, isAdd) {
  let formattedData = [];

  if (data) {
    if (localStorage.getItem("role") == "admin" && isAdd) {
      formattedData.push({
        _id: "0",
        title: "",
        color: "fff",
        icon: "/uni/svg/addspace.png",
        onClick: addForm,
      });
    }
    if (data.data) {
      data.data.map((itm, idx) => {
        try {
          let formattedRecord = {};

          formattedRecord["_id"] = itm._id;
          formattedRecord["title"] = itm.title;
          formattedRecord["instructions"] = itm.instructions;
          formattedRecord["color"] = "1C52C6";
          formattedRecord["url"] = "/forms/" + itm._id + "/results";
          formattedRecord["type"] = itm.type;
          formattedRecord["icon"] = "";
          formattedRecord["isAnonymous"] = itm.isAnonymous;
          formattedRecord["questions"] = itm.questions;
          formattedRecord["questionsForUserForm"] = itm.questions;

          formattedRecord["onClick"] = "";

          formattedData.push(formattedRecord);
        } catch (e) {
          console.log(e);
        }
      });
    }
  }

  return formattedData;
}

function participantsProperties() {
  return {
    id: {
      alias: "id",
      control: "hidden",
    },
    participants: {
      alias: "participants",
      label: "Search people and groups or paste multiple email addresses",
      placeholder: "Invite people or groups",
      control: "tags",
      editable: true,
      config: {
        idAttribute: "_id",
        nameAttribute: "name",
        apis: {
          search: {
            url: "/user/autocomplete/{query}?type=all",
            formatter: function (args) {
              let formattedData = [];
              if (args) {
                let data = args.data;
                if (data) {
                  data.map((itm, idx) => {
                    if (itm["_id"]) {
                      let fullName =
                        itm["firstName"] +
                        " " +
                        itm["lastName"] +
                        " - " +
                        itm["email"];

                      if (itm.type == "group") {
                        fullName = itm["firstName"] + " - " + itm["lastName"];
                      }
                      formattedData.push({
                        _id: itm["_id"],
                        name: fullName,
                        group: itm.type,
                      });
                    } else {
                      // if (UniFun.getURLPathValue(1) !== "conference") {
                      formattedData.push({ id: "", name: itm });
                      // }
                    }
                  });
                }
              }
              return formattedData;
            },
            parameters: {
              replace: {
                query: "",
              },
            },
          },
        },
      },
      events: {
        onAddition: function (tag) {
          if (!tag._id) {
            // this.ref.current.input.current.input.current.blur();
            // if (Fun.getURLPathValue(1) !== "conference") {
            if (UniFun.isEmail(tag.name)) {
              tag["_id"] = tag.name.trim();
              tag["name"] = tag.name.trim();
              const tags = [].concat(this.state.tags, tag);
              this.setState({ tags });
            } else {
              let splitParticipants = [];
              let notValidEmails = [];

              tag.name = tag.name.replaceAll(",", " ");
              tag.name = tag.name.replaceAll(";", " ");

              tag.name.split(" ").forEach((itm, idx) => {
                if (itm) {
                  if (UniFun.isEmail(itm.trim())) {
                    splitParticipants.push({
                      id: itm.trim(),
                      name: itm.trim(),
                    });
                  } else {
                    notValidEmails.push(
                      <span className="d-block">{itm.trim()}</span>
                    );
                  }
                }
              });

              if (notValidEmails.length > 0) {
                Fun.createNotification({
                  message: (
                    <span>
                      The following inputs are not valid emails:<br></br>
                      {notValidEmails}
                    </span>
                  ),
                  type: "danger",
                });
              }

              const tags = [].concat(this.state.tags, splitParticipants);
              this.setState({ tags });
            }
            //}
          } else {
            const tags = [].concat(this.state.tags, tag);
            this.setState({ tags });
          }
        },
      },
      attributes: {
        className: "form-control",
      },
    },
  };
}

function UniFormsProperties(input) {
  let editable = input.editable;
  return {
    _id: {
      alias: "_id",
      control: "hidden",
      editable: editable,
      attributes: {},
    },
    url: {
      alias: "url",
      control: "hidden",
    },
    color: {
      alias: "color",
      control: "hidden",
    },
    icon: {
      alias: "icon",
      control: "hidden",
    },
    title: {
      alias: "title",
      control: "text",
      label: "Title",
      placeholder: "Type form title",
      validations: "required",
      editable: editable,
      attributes: {
        className: "form-control",
      },
    },
    instructions: {
      alias: "instructions",
      control: "editor",
      label: "Instructions",
      placeholder: "Type form instructions",
      editable: editable,
      attributes: {
        className: "form-control",
      },
    },
    type: {
      alias: "type",
      control: "select",
      label: "Select who can access the form",
      config: {
        options: function () {
          let control = this;

          Fun.fetch({
            url: {
              url: "/form-types",
              parameters: {
                replace: {},
                headers: {},
              },
            },
            callback: function (args) {
              let output = [];

              let data = args.data;

              data.map((itm, idx) => {
                output.push({
                  label: itm.label,
                  value: itm.value,
                });
              });

              control.setState({ options: output });
            },
          });

          return [];
        },
        // options: [
        //   { value: "public", label: "Public access - form only" },
        //   {
        //     value: "private",
        //     label: "Private access (only for registered users)",
        //   },
        //   { value: "public-main", label: "Public access - for site" },
        // ],
      },
      events: {
        onChange: function (e) {
          switch (e.target.value) {
            case "private":
              document
                .getElementById("isAnonymous")
                .closest(".form-group")
                .classList.add("d-none");
              break;

            default:
              document
                .getElementById("isAnonymous")
                .closest(".form-group")
                .classList.remove("d-none");
              break;
          }
        },
        didMount: function () {
          switch (this.props.value) {
            case "private":
              document
                .getElementById("isAnonymous")
                .closest(".form-group")
                .classList.add("d-none");
              break;
            default:
              document
                .getElementById("isAnonymous")
                .closest(".form-group")
                .classList.remove("d-none");
              break;
          }
        },
      },
      editable: editable,
      attributes: {
        className: "form-control",
      },
    },
    isAnonymous: {
      alias: "isAnonymous",
      label: "Anonymous (does not require name, email)",
      control: "switch",
      editable: editable,
      defaultValue: false,
      config: {
        options: [
          { label: "Yes", value: true },
          { label: "No", value: false },
        ],
      },

      editable: editable,
      attributes: {
        id: "isAnonymous",
      },
    },
    questions: {
      alias: "questions",
      control: "multiple",
      label: "Questions",
      validations: "required",
      customLayout: function (args) {
        let controls = args.control;

        let output = [];

        controls.map((itm, idx) => {
          output.push(
            <div className="pt-2">
              <div className="row">
                <div className="col-md-8">
                  <strong>Question {idx + 1}: </strong>
                  <span>
                    <button
                      className=" pl-2 btn btn-link "
                      type="button"
                      onClick={(e) =>
                        UniFun.deleteTestQuestion({
                          title: "Question " + (idx + 1),
                          idx: idx,
                          object: this,
                        })
                      }
                    >
                      <span className="text-danger">
                        <i className="fas fa-times"></i> Delete
                      </span>
                    </button>
                  </span>
                </div>
                <div className="col-md-2 text-right">
                  <span className="mr-4">
                    {itm[3].props.control.component}
                    <label class="form-check-label">Required</label>
                  </span>
                </div>
              </div>
              <div className="row pt-3">
                <div
                  className="col-1"
                  style={{ maxWidth: "32px", marginRight: "20px" }}
                >
                  <img
                    src="/uni/svg/test_fill.svg"
                    style={{ width: "33px" }}
                    className="filter-light-green"
                  ></img>
                </div>
                <div className="col-9  pr-0">
                  {itm[0].props.control.component}
                  {itm[1].props.control.component}
                  {itm[2].props.control.component}
                </div>
                <div className="col-2">
                  {/* <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">Required</span>
                    </div>
                    {itm[3].props.control.component}
                    
                  </div> */}
                </div>
              </div>
              {itm[1].props.control.component.props.value == "essayType" ? (
                <React.Fragment>
                  <div className="row pt-3">
                    <div
                      className="col-1"
                      style={{ maxWidth: "32px", marginLeft: "20px" }}
                    ></div>
                    <div className="col-9">
                      {itm[5].props.control.component.props.value &&
                      itm[5].props.control.component.props.value != "null" ? (
                        <img
                          src={
                            window["baseurl"](
                              "/agenda-file/data/" +
                                itm[5].props.control.component.props.value
                            ) +
                            "?type=preview&jwt=" +
                            localStorage.getItem("jwt")
                          }
                          className="mb-2 mt-2"
                          style={{ height: "120px" }}
                        ></img>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="col-2"></div>
                    <div
                      className="col-1"
                      style={{ maxWidth: "32px", marginLeft: "20px" }}
                    ></div>
                    <div className="col-9">
                      <div className="form-group">
                        <span>
                          <label className="text-dark">
                            {itm[5].props.control.component.props.field.label}
                          </label>
                        </span>
                        {itm[5].props.control.component}
                      </div>
                    </div>
                    <div className="col-2"></div>
                  </div>
                </React.Fragment>
              ) : (
                itm[4].props.control.component
              )}
            </div>
          );
        });

        let _this = this;

        return (
          <React.Fragment>
            <h3>Questions</h3>
            {output}
            {/* <button
              type="button"
              className="btn btn-link"
              onClick={(e) => this.addNew()}
            >
              +Add new question
            </button> */}
            <div className="dropdown">
              <button
                className="btn btn-link dropdown-toggle"
                type="button"
                data-toggle="dropdown"
              >
                + Add question
              </button>
              <div className="dropdown-menu">
                <button
                  type="button"
                  className="dropdown-item"
                  onClick={(e) => {
                    _this.setState({
                      newDataObject: { type: "multipleChoice" },
                    });
                    setTimeout(_this.addNew, 50);
                  }}
                >
                  Multiple choice
                </button>
                <button
                  type="button"
                  className="dropdown-item"
                  href="#"
                  onClick={(e) => {
                    _this.setState({ newDataObject: { type: "essayType" } });
                    setTimeout(_this.addNew, 50);
                  }}
                >
                  Text
                </button>
              </div>
            </div>
          </React.Fragment>
        );
      },
      useCustomLayout: true,
      config: {
        addNewLabel: "+ Add mulitple choice",
        properties: {
          _id: {
            alias: "_id",
            control: "hidden",
            label: "",
            editable: editable,
          },
          type: {
            alias: "type",
            control: "hidden",
            label: "",
            defaultValue: "multipleChoice",
            editable: editable,
          },
          question: {
            alias: "question",
            control: "editor",
            label: "Question",
            placeholder: "Type a question",
            editable: editable,
            attributes: {
              className: "form-control",
            },
          },
          isRequired: {
            alias: "isRequired",
            control: "checkbox",
            label: "Required",
            placeholder: "",
            editable: editable,

            attributes: {
              className: "form-check-input",
            },
          },
          answers: {
            alias: "answers",
            control: "multiple",
            editable: editable,
            customLayout: function (args) {
              let output = [];

              if (args.control.length <= 1) {
                this.addNew();
                this.addNew();
              }

              args.control.map((itm, idx) => {
                let addNewBtn = "";

                if (idx == args.control.length - 1) {
                  addNewBtn = (
                    <button
                      type="button"
                      className="btn btn-link text-dark"
                      onClick={this.addNew}
                    >
                      <i className="fas fa-plus"></i>
                    </button>
                  );
                }

                output.push(
                  <div className="row pt-3">
                    <div
                      className="col-1"
                      style={{ maxWidth: "32px", marginLeft: "20px" }}
                    >
                      {itm[0].props.control.component}
                      {/* {itm[1].props.control.component} */}
                    </div>
                    <div className="col-9 pr-0">
                      {itm[2].props.control.component}
                    </div>
                    <div className="col-2">
                      <button
                        type="button"
                        className="btn btn-link text-secondary"
                        onClick={(e) => this.deleteControl(idx)}
                      >
                        <i className="fas fa-times"></i>
                      </button>
                      {addNewBtn}
                    </div>
                  </div>
                );
              });

              return (
                <React.Fragment>
                  {output}
                  <div className="pt-5"></div>
                </React.Fragment>
              );
            },
            useCustomLayout: true,
            config: {
              addNewLabel: "+ Add answer",
              properties: {
                _id: {
                  alias: "_id",
                  control: "hidden",
                  label: "",
                  editable: editable,
                },
                isCorrect: {
                  alias: "isCorrect",
                  control: "checkbox",
                  config: {
                    useTrueFalseValue: true,
                  },
                  editable: editable,
                  attributes: {
                    className: "form-check-input form-control mt-0",
                  },
                },
                answer: {
                  alias: "answer",
                  control: "text",
                  placeholder:
                    "Type answer and check the box if it's the correct one",
                  editable: editable,
                  attributes: {
                    className: "form-control",
                  },
                },
              },
            },
          },
          image: {
            alias: "image",
            control: "dropzone",
            // label: "Do you want to include an image with your question?",
            editable: editable,
            customContentLayout: function () {
              let files = [];

              try {
                files = JSON.parse(this.state.defaultValue);
              } catch (e) {}

              if (!files) {
                files = [];
              }

              let content = [];

              Object.keys(files).map((itm, idx) => {
                content.push(
                  UniFun.trainingNodeFileUI(files[itm], this.removeFile, itm)
                );
              });

              this.state.content?.map((itm, idx) => {
                if (typeof files[itm.key] === "undefined") {
                  content.push(itm);
                }
                // if (this.state.pending.indexOf(itm.key) >= 0) {
                //   content.push(itm);
                // }
              });
              return (
                <React.Fragment>
                  {/* {this.state.placeholder()} */}
                  {content.length > 0 ? "" : this.state.placeholder()}
                  <div className="p-3 pb-1">{content}</div>
                  {/* {content.length > 0 ? "" : this.state.placeholder()} */}
                </React.Fragment>
              );
            },

            useCustomLayout: true,
            config: {
              formatDefaultValue: function (value) {
                if (value == "null") {
                  return null;
                }
                if (value) {
                  return {
                    [value]: {
                      _id: value,
                      mimetype: "image/jpeg",
                      title: "Question Image",
                    },
                  };
                }
                return value;
              },
              placeholder: function () {
                return (
                  <div className="p-3 pb-1">
                    <strong>
                      Drag & drop here or{" "}
                      <a
                        href="javascript:void(0);"
                        className="text-primary"
                        onClick={this.onTargetClick}
                      >
                        <strong>browse</strong>
                      </a>{" "}
                      file to attach
                    </strong>
                    <br></br>
                    <span className="text-dark">
                      Maximum size: 50 MB - File type supported: png,jpeg,jpg
                    </span>
                  </div>
                );
              },
            },
            events: {
              removeFile: function (id) {
                let defaultValue = {};

                Object.keys(JSON.parse(this.state.defaultValue)).map(
                  (itm, idx) => {
                    if (itm != id) {
                      defaultValue[itm] = JSON.parse(this.state.defaultValue)[
                        itm
                      ];
                    }
                  }
                );

                let content = [];
                this.state.content?.map((itm, idx) => {
                  if (itm.key != id) {
                    content.push(itm);
                  }
                });

                this.setState({
                  defaultValue: JSON.stringify(defaultValue),
                  content: content,
                });

                return;
              },
              onDrop: function (files) {
                this.setState({
                  defaultValue: JSON.stringify({}),
                  content: [],
                });

                if (files.length > 1) {
                  Fun.createNotification({
                    message:
                      "Only one file is allowed to upload. Only png,jpeg,jpg files are allowed to upload",
                    type: "danger",
                  });
                  return;
                }

                if (
                  files[0].type == "image/jpeg" ||
                  files[0].type == "image/jpg" ||
                  files[0].type == "image/png"
                ) {
                  UniFun.fileProgress({
                    control: this,
                    files: files,
                    url: "/agenda-file",
                    type: "meeting",
                  });
                } else {
                  Fun.createNotification({
                    message: "Only png,jpeg,jpg files are allowed to upload",
                    type: "danger",
                  });
                }
              },
            },
            attributes: {
              className: "border rounded  dropzone-trainings d-none",
            },
          },
        },
      },
    },
  };
}

export default UniForms;
