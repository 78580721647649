import React, { Component } from "react";
import Fun from "../../../lib/functions";
import Builder from "../../../lib/builder";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";

class TopMenuEpub extends Component {
  state = {
    data: [],
    loaded: false,
    output: null,
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.setState({ data: this.props.data, loaded: true });
  }

  render() {
    if (this.state.loaded) {
      let _this = this;
      return (
        <header className=" c-header c-header-light c-header-fixed c-header-with-subheader ">
          <div className="row w-100">
            <div className="col-9">
              <div className="mt-2">
                {this.props.history.length > 1 ? (
                  <img
                    src="/uni/svg/trainings/go_back.svg"
                    className="pr-3 pointer ml-3"
                    style={{ width: "45px", paddingBottom: "5px" }}
                    onClick={(e) => {
                      _this.props.history.goBack();
                    }}
                  ></img>
                ) : (
                  ""
                )}
                <img
                  src="/uni/svg/files/EPUB.svg"
                  className="ml-3"
                  style={{ width: "30px", paddingBottom: "5px" }}
                ></img>{" "}
                <strong>
                  {Fun.getParameterByName("title")
                    ? Fun.getParameterByName("title")
                    : "Epub file"}
                </strong>
              </div>
            </div>
            <div className="col-3 text-right"></div>
          </div>
        </header>
      );
    } else {
      return "loading...";
    }
  }
}

export default withRouter(TopMenuEpub);
