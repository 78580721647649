import React, { Component } from "react";
import ReactTags from "react-tag-autocomplete";
import Fun from "../../lib/functions";

class Tags extends Component {
  state = {
    name: "",
    id: "",
    placeholder: "",
    attributes: "",
    value: "",
    defaultValue: "",
    loaded: false,
    editable: true,
    component: null,
    attributes: "",
    events: {},
    customLayout: null,
    useCustomLayout: true,
    editable: true,
    tags: [],
    suggestions: [],
  };

  constructor(props) {
    super(props);

    let events = {};

    this.editable = this.editable.bind(this);
    this.nonEditable = this.nonEditable.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onInput = this.onInput.bind(this);
    this.onValidate = this.onValidate.bind(this);
    this.onAddition = this.onAddition.bind(this);
    this.onValueChange = this.onValueChange.bind(this);
    this.formatData = this.formatData.bind(this);
    this.unFormatData = this.unFormatData.bind(this);
    this.suggestionsCallback = this.suggestionsCallback.bind(this);

    this.ref = React.createRef();

    if (this.props.field.events) {
      Object.keys(this.props.field.events).map((itm, idx) => {
        this[itm] = this.props.field.events[itm];
        this[itm] = this[itm].bind(this);
        events[itm] = this[itm];
      });
    }

    let config = this.props.field.config
      ? { ...this.props.field.config }
      : null;

    let nonEditableDefaultValue = "";
    if (this.props.field.nonEditableDefaultValue) {
      nonEditableDefaultValue = this.props.field.nonEditableDefaultValue;
    }

    let defaultValue = "";
    if (this.props.field.defaultValue) {
      defaultValue = this.props.field.defaultValue;
    }

    this.state = {
      config: config,
      idAttribute: config.idAttribute ? config.idAttribute : "id",
      nameAttribute: config.nameAttribute ? config.nameAttribute : "name",
      events: events,
      editable:
        this.props.field.editable != null
          ? this.props.field.editable
          : this.state.editable,
      defaultValue: this.props.value ? this.props.value : defaultValue,
      nonEditableDefaultValue: nonEditableDefaultValue,
      tags: this.props.value ? this.props.value : [],
      suggestions: [],
      show: this.props.field.show === false ? false : true,
    };
  }

  componentDidMount() {
    let suggestions = [];

    if (Array.isArray(this.state.config.suggestions)) {
      suggestions = this.state.config.suggestions;
    }

    this.setState({
      attributes: this.props.field.attributes,
      suggestions: this.formatData(suggestions),
      tags: this.formatData(this.state.tags),
      placeholder: this.props.field.placeholder
        ? this.props.field.placeholder
        : "",
      name: this.props.field.alias,
      editable:
        this.props.field.editable != null
          ? this.props.field.editable
          : this.state.editable,
      customLayout: this.props.field.customLayout,
      useCustomLayout:
        typeof this.props.field.useCustomLayout !== "undefined"
          ? this.props.field.useCustomLayout
          : true,
    });

    if (typeof this.state.config.suggestions === "function") {
      this.state.config.suggestions(this);
    }

    if (this.init) {
      this.init();
    }

    try {
      this.ref.current.container.current.addEventListener(
        "mousedown",
        function (e) {
          e.preventDefault();
        }
      );
    } catch (e) {}
  }

  formatData(input) {
    let output = [];

    input.map((itm, idx) => {
      if (typeof itm !== "string") {
        let sugItem = {};
        Object.keys(itm).map((n, i) => {
          if (n == this.state.idAttribute) {
            sugItem["id"] = itm[n];
          } else if (n == this.state.nameAttribute) {
            sugItem["name"] = itm[n];
          } else {
            sugItem[n] = itm[n];
          }
        });
        output.push(sugItem);
      } else {
        output.push(itm);
      }
    });

    return output;
  }

  unFormatData(input) {
    let output = [];

    input.map((itm, idx) => {
      if (typeof itm !== "string") {
        let sugItem = {};
        Object.keys(itm).map((n, i) => {
          if (n == "id") {
            sugItem[this.state.idAttribute] = itm["id"];
          } else if (n == "name") {
            sugItem[this.state.nameAttribute] = itm["name"];
          } else {
            sugItem[n] = itm[n];
          }
        });

        output.push(sugItem);
      } else {
        output.push(itm);
      }
    });

    return output;
  }

  editable() {
    let output = (
      <React.Fragment>
        <ReactTags
          ref={this.ref}
          tags={this.state.tags}
          suggestions={this.state.suggestions}
          onDelete={this.onDelete.bind(this)}
          onAddition={this.onAddition.bind(this)}
          addOnBlur={true}
          onInput={this.onInput.bind(this)}
          allowNew={true}
          maxSuggestionsLength={1000}
          placeholderText={this.state.placeholder}
          suggestionsFilter={function () {
            return true;
          }}
          classNames={{
            root: "react-tags form-control h-100 rounded",
            rootFocused: "is-focused",
            selected: "react-tags__selected",
            selectedTag: "react-tags__selected-tag",
            selectedTagName: "react-tags__selected-tag-name",
            search: "react-tags__search",
            searchInput: "react-tags__search-input",
            suggestions: "react-tags__suggestions",
            suggestionActive: "is-active",
            suggestionDisabled: "is-disabled",
          }}
        />

        <input
          type="hidden"
          defaultValue={this.onValueChange(this.unFormatData(this.state.tags))}
          name={this.props.field.alias}
          {...this.state.attributes}
          tabIndex={-1}
        ></input>
      </React.Fragment>
    );

    if (this.state.customLayout && this.state.useCustomLayout == true) {
      this.state.customLayout = this.state.customLayout.bind(this);
      return this.state.customLayout({ control: output, object: this });
    }

    return output;
  }

  onValueChange(input) {
    return JSON.stringify(input);
  }

  suggestionsCallback(args) {
    if (args) {
      if (Array.isArray(args)) {
        let suggestions = [];
        let currentIds = [];

        this.state.tags.forEach((itm, idx) => {
          currentIds.push(itm["id"]);
        });

        args.forEach((itm, idx) => {
          if (currentIds.indexOf(itm[this.state.idAttribute]) < 0) {
            suggestions.push(itm);
          }
        });

        this.setState({ suggestions: suggestions });
      }
    }
  }

  onInput(input) {
    if (this.state.config.apis) {
      if (this.state.config.apis.search) {
        let parameters = this.state.config.apis.search.parameters;

        if (!parameters.replace) {
          parameters.replace = {};
        }
        parameters.replace["query"] = input;
        // {
        //   replace: {
        //     query: input,
        //   },
        // };
        Fun.fetch({
          url: this.state.config.apis.search,
          parameters: parameters,
          callback: this.suggestionsCallback,
        });
      }
    }
  }

  onValidate() {}

  onDelete(i) {
    const tags = this.state.tags.slice(0);
    tags.splice(i, 1);
    this.setState({ tags });
  }

  onAddition(tag) {
    const tags = [].concat(this.state.tags, tag);
    this.setState({ tags });
  }

  nonEditable() {
    let outputArray = [];

    this.formatData(this.state.tags).map((itm, idx) => {
      outputArray.push(itm["name"]);
    });

    let output = outputArray.join(", ");
    if (output == "") {
      output = this.state.nonEditableDefaultValue
        ? this.state.nonEditableDefaultValue
        : output;
    }

    return output;

    // return (
    //   <React.Fragment>
    //     <ReactTags
    //       tags={this.state.tags}
    //       onDelete={(e) => function () {}}
    //       onAddition={(e) => function () {}}
    //       placeholderText=""
    //       inputAttributes={{ disabled: "disabled" }}
    //       classNames={{
    //         root: "react-tags",
    //         rootFocused: "is-focused",
    //         selected: "react-tags__selected",
    //         selectedTag: "react-tags__selected-tag",
    //         selectedTagName: "react-tags__selected-tag-name",
    //         search: "react-tags__search",
    //         searchInput: "react-tags__search-input",
    //         suggestions: "react-tags__suggestions",
    //         suggestionActive: "is-active",
    //         suggestionDisabled: "is-disabled",
    //       }}
    //     />
    //     <input
    //       type="hidden"
    //       defaultValue={this.onValueChange(this.unFormatData(this.state.tags))}
    //       name={this.props.field.alias}
    //       {...this.state.attributes}
    //     ></input>
    //   </React.Fragment>
    // );
  }

  render() {
    if (!this.state.show) {
      return "";
    }

    let output = this.state.editable ? this.editable() : this.nonEditable();

    return output;
  }
}

export default Tags;
