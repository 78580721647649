import React, { Component } from "react";
import Controls from "../controls";
import Themes from "../../themes/themes";
import Fun from "../../lib/functions";

class Group extends Component {
  state = {
    name: "",
    id: "",
    placeholder: "",
    attributes: "",
    value: "",
    defaultValue: "",
    loaded: false,
    editable: true,
    component: null,
    attributes: "",
    events: {},
    customLayout: null,
    useCustomLayout: true,
    editable: true,
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    let controlField = this.props.field;
    let controlConfig = controlField["config"];
    let controlFields = controlConfig.properties;
    let defaultValue = this.props.value ? this.props.value : [];
    let data = this.props.data;

    this.setState({
      customLayout: this.props.field.customLayout,
      controlFields: controlFields,
      controlField: controlField,
      controlConfig: controlConfig,
      useCustomLayout:
        typeof this.props.field.useCustomLayout !== "undefined"
          ? this.props.field.useCustomLayout
          : true,
      loaded: true,
      defaultValue: defaultValue,
      data: data,
    });
  }

  render() {
    if (this.state.loaded) {
      let namePrefix = this.props.namePrefix ? this.props.namePrefix : "";

      let controls = [];

      Object.keys(this.state.controlFields).map((itm, idx) => {
        let Control = Controls[this.state.controlFields[itm].control];

        let controlValue = this.state.defaultValue[itm];
        // ? this.state.defaultValue[itm]
        // : "";

        // if (this.state.defaultValue[itm] === false) {
        //   controlValue = false;
        // }

        let field = { ...this.state.controlFields[itm] };

        let alias = field["alias"];

        field["alias"] =
          namePrefix +
          "[" +
          this.state.controlField["alias"] +
          "][0][" +
          alias +
          "]";

        const ControlComponent = (
          <Control
            field={field}
            key={Fun.uuid()}
            value={controlValue}
            parent={this}
            data={this.state.data}
          ></Control>
        );

        let ControlTemplate = Themes[window["c_theme"]]["control"];

        if (field["label"]) {
          controls.push(
            <ControlTemplate
              key={Fun.uuid()}
              control={{
                field: field,
                component: ControlComponent,
              }}
            ></ControlTemplate>
          );
        } else {
          controls.push(ControlComponent);
        }
      });

      if (this.state.customLayout && this.state.useCustomLayout) {
        return this.state.customLayout({ control: controls, object: this });
      }

      return controls;
    }
    return "";
  }
}

export default Group;
