import React, { Component } from "react";

class TextArea extends Component {
  state = {
    name: "",
    id: "",
    placeholder: "",
    attributes: "",
    value: "",
    defaultValue: "",
    loaded: false,
    editable: true,
    component: null,
    attributes: "",
    events: {},
    customLayout: null,
    useCustomLayout: true,
    editable: true,
  };

  constructor(props) {
    super(props);

    let events = {};
    if (this.props.field.events) {
      Object.keys(this.props.field.events).map((itm, idx) => {
        this[itm] = this.props.field.events[itm];
        this[itm] = this[itm].bind(this);
        events[itm] = this[itm];
      });
    }

    this.editable = this.editable.bind(this);
    this.nonEditable = this.nonEditable.bind(this);

    let nonEditableDefaultValue = "";
    if (this.props.field.nonEditableDefaultValue) {
      nonEditableDefaultValue = this.props.field.nonEditableDefaultValue;
    }

    this.state = {
      events: events,
      show: this.props.field.show === false ? false : true,
      editable:
        this.props.field.editable != null
          ? this.props.field.editable
          : this.state.editable,
      defaultValue: this.props.value ? this.props.value : "",
      nonEditableDefaultValue: this.props.value
        ? this.props.value
        : nonEditableDefaultValue,
    };
  }

  validator() {}

  componentDidMount() {
    this.setState({
      attributes: this.props.field.attributes,
      name: this.props.field.alias,
      editable:
        this.props.field.editable != null
          ? this.props.field.editable
          : this.state.editable,
      customLayout: this.props.field.customLayout,
      useCustomLayout:
        typeof this.props.field.useCustomLayout !== "undefined"
          ? this.props.field.useCustomLayout
          : true,
    });
  }

  editable() {
    let output = (
      <textarea
        defaultValue={this.state.defaultValue}
        name={this.state.name}
        {...this.state.attributes}
        {...this.state.events}
      ></textarea>
    );
    if (this.state.customLayout && this.state.useCustomLayout == true) {
      this.state.customLayout = this.state.customLayout.bind(this);
      return this.state.customLayout({ control: output, object: this });
    }

    return output;
  }

  nonEditable() {
    let output = this.state.nonEditableDefaultValue
      ? this.state.nonEditableDefaultValue
      : this.state.defaultValue;

    if (this.state.customLayout && this.state.useCustomLayout == true) {
      this.state.customLayout = this.state.customLayout.bind(this);
      return this.state.customLayout({ control: output, object: this });
    }

    return output;
  }

  render() {
    if (!this.state.show) {
      return "";
    }
    let output = this.state.editable ? this.editable() : this.nonEditable();

    return output;
  }
}

export default TextArea;
