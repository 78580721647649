import React, { Component } from "react";
import ReactTooltip from "react-tooltip";

class TopTemplateHeader extends Component {
  state = {
    data: [],
    loaded: false,
    output: null,
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.setState({
      data: this.props.data,
      loaded: true,
      title: this.props.title,
      subtitle: this.props.subtitle,
      customClass: this.props.customClass,
      rightColumn: this.props.rightColumn,
    });
  }

  render() {
    if (this.state.loaded) {
      if (this.state.rightColumn) {
        return (
          <React.Fragment>
            <div
              className={"row headerWhite pl-31px " + this.state.customClass}
            >
              <div className="col-9 pt-5">
                <h1>{this.state.title}</h1>
                <ReactTooltip place={"bottom"} />
                <p>{this.state.subtitle}</p>
              </div>
              <div className="col-3 pt-5">{this.state.rightColumn}</div>
            </div>
          </React.Fragment>
        );
      }

      return (
        <React.Fragment>
          <div className={"row headerWhite pl-31px " + this.state.customClass}>
            <div className="col-12 pt-5">
              <h1>{this.state.title}</h1>
              <ReactTooltip place={"bottom"} />
              <p>{this.state.subtitle}</p>
            </div>
          </div>
        </React.Fragment>
      );
    } else {
      return "";
    }
  }
}

export default TopTemplateHeader;
