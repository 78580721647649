import React, { Component } from "react";
import Fun from "../../lib/functions";

class Ajax extends Component {
  state = {
    name: "",
    id: "",
    placeholder: "",
    attributes: "",
    value: "",
    defaultValue: "",
    loaded: false,
    editable: true,
    component: null,
    attributes: "",
    events: {},
    customLayout: null,
    useCustomLayout: true,
    editable: true,
  };

  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      customLayout: this.props.field.customLayout,
      useCustomLayout:
        typeof this.props.field.useCustomLayout !== "undefined"
          ? this.props.field.useCustomLayout
          : true,
    };
  }

  validator() {}

  componentDidMount() {
    let url = "";

    if (this.props.field.config) {
      if (this.props.field.config.url) {
        url = this.props.field.config.url;
      }
    }

    let object = this;
    if (url) {
      Fun.fetch({
        url: url,
        object: this,
        parentData: this.props.data,
        callback: function (args) {
          object.setState({ defaultValue: args, loaded: true });
        },
      });
    }
  }

  render() {
    let output = "";

    if (this.state.loaded) {
      if (this.state.customLayout && this.state.useCustomLayout == true) {
        this.state.customLayout = this.state.customLayout.bind(this);
        return this.state.customLayout({ control: output, object: this });
      }
    }

    return output;
  }
}

export default Ajax;
