import React, { Component } from "react";
import Control from "./../../control";
import SimpleReactValidator from "simple-react-validator";
import Fun from "../../../../../lib/functions";
import UniFun from "../../../../../modules/uni/functions";
import moment from "moment";
import TopTemplateHeader from "./../../topTemplateHeader";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";

class Speakers extends Component {
  state = {
    data: this.props.data,
    loaded: false,
    colsTemplateCount: 12,
    colsFormCount: 12,
    cardBodyCustomClass: "",
    module: {
      _id: "",
      title: "",
      description: "",
    },
    session: {},
  };

  constructor(props) {
    super(props);
    let builder = this.props.builder.state;
    let templateConfig = builder.module.templateConfig;
    if (templateConfig.events) {
      Object.keys(templateConfig.events).map((itm, idx) => {
        this[itm] = templateConfig.events[itm].bind(this);
      });
    }

    this.form = React.createRef();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.action) {
      return false;
    }
    return true;
  }

  componentDidMount() {
    let builder = this.props.builder.state;
    let templateConfig = builder.module.templateConfig;
    let useCard =
      typeof templateConfig.useCard !== "undefined"
        ? templateConfig.useCard
        : true;

    let templateTop = templateConfig.templateTop
      ? templateConfig.templateTop
      : () => {};
    this["templateTop"] = templateTop.bind(this);
    let templateBottom = templateConfig.templateBottom
      ? templateConfig.templateBottom
      : () => {};
    this["templateBottom"] = templateBottom.bind(this);
    let templateLeft = templateConfig.templateLeft
      ? templateConfig.templateLeft
      : () => {};
    this["templateLeft"] = templateLeft.bind(this);
    let templateRight = templateConfig.templateRight
      ? templateConfig.templateRight
      : () => {};
    this["templateRight"] = templateRight.bind(this);
    let cardTemplateTop = templateConfig.cardTemplateTop
      ? templateConfig.cardTemplateTop
      : () => {};
    this["cardTemplateTop"] = cardTemplateTop.bind(this);
    let cardTemplateBottom = templateConfig.cardTemplateBottom
      ? templateConfig.cardTemplateBottom
      : () => {};
    this["cardTemplateBottom"] = cardTemplateBottom.bind(this);
    let panelTop = templateConfig.panelTop ? templateConfig.panelTop : () => {};
    this["panelTop"] = panelTop.bind(this);
    let panelBottom = templateConfig.panelBottom
      ? templateConfig.panelBottom
      : () => {};
    this["panelBottom"] = panelBottom.bind(this);
    let panelLeft = templateConfig.panelLeft
      ? templateConfig.panelLeft
      : () => {};
    this["panelLeft"] = panelLeft.bind(this);
    let panelRight = templateConfig.panelRight
      ? templateConfig.panelRight
      : () => {};
    this["panelRight"] = panelRight.bind(this);
    let header = templateConfig.header ? templateConfig.header : () => {};
    this["header"] = header.bind(this);

    let colsTemplateCount = templateConfig.colsTemplateCount
      ? templateConfig.colsTemplateCount
      : this.state.colsTemplateCount;
    let colsFormCount = templateConfig.colsFormCount
      ? templateConfig.colsFormCount
      : this.state.colsFormCount;

    let cardBodyCustomClass = templateConfig.cardBodyCustomClass
      ? templateConfig.cardBodyCustomClass
      : this.state.cardBodyCustomClass;

    this.setState({
      loaded: true,
      rawData: this.props.rawData,
      colsTemplateCount: colsTemplateCount,
      colsFormCount: colsFormCount,
      cardBodyCustomClass: cardBodyCustomClass,
      useCard: useCard,
    });

    this.validator = this.props.builder.validator;
    if (this.componentDidMountEvent) {
      this.componentDidMountEvent();
    }
  }

  speakers() {
    let data = this.state.rawData.data[0];

    let speakersIds = [];
    let speakers = [];

    data.days?.forEach((itm, idx) => {
      itm.sessions.forEach((sitm, sidx) => {
        sitm.speakersRaw.forEach((spitm, spidx) => {
          if (speakersIds.indexOf(spitm._id) < 0) {
            speakersIds.push(spitm._id);

            let name = (
              <span style={{ display: "inline-block" }} className="font-18">
                <span className="pt-2">
                  <strong className="text-primary">
                    {spitm.firstName + " " + spitm.lastName}
                  </strong>
                  <br></br>
                  {spitm.jobTitle}
                </span>
              </span>
            );

            speakers.push(
              <div className="col-md-4 mt-4">
                <div
                  className="card-transparent pointer "
                  onClick={(e) => this.speakerInfoModal(spitm)}
                >
                  <div className="card-body p-5 ">
                    {UniFun.createProfileImageBubble(
                      [spitm],
                      "avatar-120",
                      false,
                      name,
                      "13px"
                    )}

                    {/* {UniFun.createProfileImageBubble([spitm])} */}
                  </div>
                </div>
              </div>
            );
          }
        });
      });
    });

    return <div className="row">{speakers}</div>;
  }

  render() {
    if (this.state.loaded) {
      let data = this.state.data[0];

      return (
        <React.Fragment>
          <div className={"uniTopHeader "}>
            <TopTemplateHeader
              customClass="mb-4 uniFixedHeader"
              title={
                <React.Fragment>
                  <span className="pl-3">Speakers</span>
                </React.Fragment>
              }
            ></TopTemplateHeader>
          </div>
          <div className="container-fluid mt-5">{this.speakers()}</div>
        </React.Fragment>
      );
    } else {
      return "loading...";
    }
  }
}

export default withRouter(Speakers);
