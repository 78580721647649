import React, { Component } from "react";
import Fun from "../../lib/functions";
import Builder from "../../lib/builder";
import UniFun from "./functions";
import Control from "../../themes/default/templates/control";
import { Link } from "react-router-dom";
import moment from "moment";

const UniTestResults = {
  auth: "",
  views: {
    traininglist: {
      apis: {
        view: {
          url: "/trainings/with-test-results",
          parameters: {
            headers: { "content-type": "application/json" },
          },
          parameters: {
            replace: {
              start: 0,
              offset: 1000,
            },
          },
          formatter: function (data, builder) {
            return TrainingTestsFormatter(data, builder);
          },
        },
      },
      template: "grid",
      templateConfig: {
        events: {},
        headerTitle: "Trainings",
        headerTitleIcon: "/uni/svg/trainingmain.svg",
        colsTemplateCount: 12,
        includeProperties: ["trainingTitle"],
        gridConfig: {
          showTableHeaders: false,
          cellTemplates: {
            trainingTitle: function (data, rec) {
              return (
                <Link
                  to={"/testresults/training/" + rec._id.value + "/results"}
                >
                  <i
                    className="fas fa-square mr-2"
                    style={{ color: "rgb(55, 117, 210)" }}
                  ></i>
                  {data}
                </Link>
              );
            },
          },
        },
        header: UniFun.commonHeader,
      },
      pk: "id",
      properties: TrainingTests({ editable: false }),
    },
    otherlist: {
      apis: {
        view: {
          url: "/results/other",
          parameters: {
            headers: { "content-type": "application/json" },
          },
          parameters: {
            replace: {
              start: 0,
              offset: 1000,
            },
          },
          formatter: function (data, builder) {
            return TrainingTestsFormatter(data, builder);
          },
        },
      },
      template: "grid",
      templateConfig: {
        events: {},
        headerTitle: "Tests",
        headerTitleIcon: "/uni/svg/trainingmain.svg",
        colsTemplateCount: 12,
        includeProperties: [
          "title",
          "trainingTitle",
          "submitted",
          "status",
          "finalGrade",
        ],
        gridConfig: {
          emptyFolder: function () {
            return (
              <div className="row mt-4 " style={{ height: "500px" }}>
                <div className="col-12 text-center">
                  <h5>
                    <strong>
                      {UniFun.userIsEligible({ forWhat: "isInstructor" })
                        ? "There are not any pending evaluations"
                        : "There are not test results yet"}
                    </strong>
                  </h5>
                  <span>
                    {UniFun.userIsEligible({ forWhat: "isInstructor" })
                      ? "Tests that need to be evaluated will appear here"
                      : ""}
                  </span>
                </div>
              </div>
            );
          },
          headers: {
            title: {
              attributes: {
                className: "w-50",
              },
            },
            status: {
              attributes: function (data) {
                if (data.evaluationMode.value) {
                  return { className: "d-none" };
                }

                return { className: "" };
              },
            },
            finalGrade: {
              attributes: function (data) {
                if (data.evaluationMode.value) {
                  return { className: "d-none" };
                }

                return { className: "" };
              },
            },
          },
          cellTemplates: {
            title: function (data, rec, obj) {
              return (
                <React.Fragment>
                  {rec.status.value !== "Not Evaluated" ||
                  rec.evaluationMode.value ? (
                    <Link to={"/event/test/" + rec._id.value + "/result"}>
                      <span className="pointer">{data}</span>
                    </Link>
                  ) : (
                    <span className="pointer">{data}</span>
                  )}
                  {/* <span className="float-right mr-4 grid-controls mt-2">
                    <Link to={"/event/test/" + rec.examEventId.value}>
                      <i className="fas fa-search mr-4 pointer"></i>
                    </Link>
                  </span> */}
                </React.Fragment>
              );
            },
            trainingTitle: function (data, rec, obj) {
              if (rec.trainingId.value) {
                return (
                  <Link
                    to={"/event/training/" + rec.trainingId.value}
                    className="text-primary"
                  >
                    {data}
                  </Link>
                );
              }
              return data;
            },
            status: function (data, rec, obj) {
              if (rec.evaluationMode.value) {
                return "";
              }

              if (
                parseFloat(rec.passingGrade.value) == 0 ||
                !rec.passingGrade.value
              ) {
                return "---";
              }

              switch (rec.status.value.toLowerCase()) {
                case "pass":
                  return <span className="text-success">Pass</span>;
                  break;
                case "fail":
                  return <span className="text-danger">Fail</span>;
                  break;
                default:
                  return "---";
                  break;
              }
            },
            finalGrade: function (data, rec, obj) {
              if (rec.evaluationMode.value) {
                return "";
              }

              if (
                rec.status.value.toLowerCase() != "fail" &&
                rec.status.value.toLowerCase() != "pass"
              ) {
                return "---";
              }

              //if (parseFloat(rec.passingGrade.value) >= 0) {
              let sumanswers = "";

              if (rec.questions.value) {
                let answerCorrectQuestions = rec.questions.value.length;

                rec.questions.value.map((itm, idx) => {
                  let answerIsCorrect = true;

                  if (itm.type !== "essayType") {
                    itm.answers.map((aitm, aid) => {
                      let isCorrect = aitm.isCorrect
                        ? aitm.isCorrect.toString()
                        : "false";
                      let userAnswer = aitm.userAnswer.toString();

                      if (isCorrect != userAnswer) {
                        answerIsCorrect = false;
                      }
                    });

                    if (!answerIsCorrect) {
                      answerCorrectQuestions--;
                    }
                  }
                });
                // sumanswers =
                //   "(" +
                //   answerCorrectQuestions +
                //   "/" +
                //   rec.questions.value.length +
                //   "ans.)";

                return rec.finalGrade.value + "% " + sumanswers;
              }
              //}

              return "---";
            },
          },
        },
        header: UniFun.commonHeader,
      },
      pk: "id",
      properties: TrainingTests({ editable: false }),
    },
    trainingexams: {
      apis: {
        view: {
          url: "/results/training/{id}",
          parameters: {
            replace: {
              id: function () {
                return Fun.getURLPathValue(3);
              },
            },
          },
          formatter: function (data, builder) {
            return TrainingTestsFormatter(data, builder);
          },
        },
      },
      template: "grid",
      templateConfig: {
        events: {},
        headerTitle: "Test results:",
        headerTitleIcon: "",
        colsTemplateCount: 12,
        includeProperties: ["title", "submitted", "status", "finalGrade"],
        gridConfig: {
          headers: {
            title: {
              attributes: {
                className: "w-50",
              },
            },
          },
          cellTemplates: {
            title: function (data, rec, obj) {
              return (
                <React.Fragment>
                  <Link to={"/event/test/" + rec._id.value + "/result"}>
                    <span className="pointer">{data}</span>
                    <span className="float-right mr-4 grid-controls mt-2">
                      <i className="fas fa-search mr-4 pointer"></i>
                    </span>
                  </Link>
                </React.Fragment>
              );
            },
            status: function (data, rec, obj) {
              if (parseFloat(rec.passingGrade.value) == 0) {
                return "---";
              }

              switch (rec.status.value.toLowerCase()) {
                case "pass":
                  return <span className="text-success">Pass</span>;
                  break;
                case "fail":
                  return <span className="text-danger">Fail</span>;
                  break;
                default:
                  return "---";
                  break;
              }
            },
            finalGrade: function (data, rec, obj) {
              if (
                rec.status.value.toLowerCase() != "fail" &&
                rec.status.value.toLowerCase() != "pass"
              ) {
                return "---";
              }

              if (parseFloat(rec.passingGrade.value) >= 0) {
                let sumanswers = "";

                if (rec.questions.value) {
                  let answerCorrectQuestions = rec.questions.value.length;

                  rec.questions.value.map((itm, idx) => {
                    let answerIsCorrect = true;

                    itm.answers.map((aitm, aid) => {
                      let isCorrect = aitm.isCorrect
                        ? aitm.isCorrect.toString()
                        : "false";
                      let userAnswer = aitm.userAnswer.toString();

                      if (isCorrect != userAnswer) {
                        answerIsCorrect = false;
                      }
                    });

                    if (!answerIsCorrect) {
                      answerCorrectQuestions--;
                    }
                  });
                  // sumanswers =
                  //   "(" +
                  //   answerCorrectQuestions +
                  //   "/" +
                  //   rec.questions.value.length +
                  //   "ans.)";

                  return rec.finalGrade.value + "% " + sumanswers;
                }
              }

              return "---";
            },
          },
        },
        header: UniFun.commonHeader,
      },
      pk: "id",
      properties: TrainingTests({ editable: false }),
    },
    trainingResults: {
      apis: {
        view: {
          url: "{path}",
          parameters: {
            headers: { "content-type": "application/json" },
          },
          parameters: {
            replace: {
              start: 0,
              offset: 1000,
            },
          },
          formatter: function (data, builder) {
            return TrainingTestsFormatter(data, builder);
          },
        },
      },
      template: "grid",
      templateConfig: {
        events: {
          componentDidMountEvent: function () {
            window["trainingoverviewinterval"] = setInterval(
              this.props.builder.rerunComponent,
              30000
            );
          },
          componentWillUnmount: function () {
            clearInterval(window["trainingoverviewinterval"]);
          },
        },
        headerTitle: "Tests",
        headerTitleIcon: "/uni/svg/trainingmain.svg",
        colsTemplateCount: 12,
        includeProperties: [
          "title",
          // "trainingTitle",
          "availability",
          //"submitted",
          "result",
          "status",
          "finalGrade",
        ],
        gridConfig: {
          emptyFolder: function () {
            if (!UniFun.userIsEligible({ forWhat: "isInstructor" })) {
              return (
                <div className="row mt-4 " style={{ height: "500px" }}>
                  <div className="col-12 text-center">
                    <h5>
                      <strong>Tests are empty</strong>
                    </h5>
                    <span>Scheduled tests will appear here</span>
                  </div>
                </div>
              );
            }

            return (
              <div className="row mt-4 " style={{ height: "500px" }}>
                <div className="col-12 text-center">
                  <h5>
                    <strong>
                      {UniFun.userIsEligible({ forWhat: "isInstructor" })
                        ? "There are not any pending evaluations"
                        : "There are not test results yet"}
                    </strong>
                  </h5>
                  <span>
                    {UniFun.userIsEligible({ forWhat: "isInstructor" })
                      ? "Tests that need to be evaluated will appear here"
                      : ""}
                  </span>
                </div>
              </div>
            );
          },
          headers: {
            title: {
              attributes: {
                className: "w-50",
              },
            },

            result: {
              attributes: function (data) {
                if (data.evaluationMode.value) {
                  return { className: "d-none" };
                }

                return { className: "" };
              },
            },
            finalGrade: {
              attributes: function (data) {
                if (data.evaluationMode.value) {
                  return { className: "d-none" };
                }

                return { className: "" };
              },
            },
          },
          cellTemplates: {
            title: function (data, rec, obj) {
              if (moment(rec.from.value) > moment()) {
                return <span>{data}</span>;
              }

              let isMember = true;

              if (!UniFun.userIsEligible({ forWhat: "member" })) {
                isMember = false;
              }
              if (UniFun.userIsEligible({ forWhat: "isInstructor" })) {
                isMember = false;
              }

              if (rec.status.value) {
                if (rec.status.value.toLowerCase() == "not submitted") {
                  if (moment(rec.to.value) < moment()) {
                    return <span>{data}</span>;
                  } else {
                    return (
                      <span
                        className="pointer"
                        onClick={(e) =>
                          UniFun.startExam({ _id: rec._id.value })
                        }
                      >
                        {data}
                        {isMember ? (
                          <span className="float-right mr-4 grid-controls mt-2">
                            <span
                              onClick={(e) =>
                                UniFun.startExam({ _id: rec._id.value })
                              }
                            >
                              Start test{" "}
                              <i className="fas fa-chevron-right ml-2 pointer"></i>
                            </span>
                          </span>
                        ) : (
                          ""
                        )}
                      </span>
                    );
                  }
                }

                if (rec.status.value.toLowerCase() == "submitted") {
                  return (
                    <span>
                      {data}
                      {rec.result.value.toLowerCase() == "fail" ||
                      rec.result.value.toLowerCase() == "pass" ||
                      rec.result.value.toLowerCase() == "no grade" ? (
                        <span className="float-right mr-4 grid-controls pt-2">
                          <Link
                            to={
                              "/event/test/" + rec.userExamId.value + "/result"
                            }
                          >
                            View test{" "}
                            <i className="fas fa-chevron-right ml-2 pointer"></i>
                          </Link>
                        </span>
                      ) : (
                        ""
                      )}
                    </span>
                  );

                  // if (moment(rec.to.value) < moment()) {
                  //   return <span>{data}</span>;
                  // } else {
                  //   return (
                  //     <span className="pointer">
                  //       {data}
                  //       {isMember ? (
                  //         <span className="float-right mr-4 grid-controls mt-2">
                  //           <Link
                  //             to={
                  //               "/event/test/" + rec.userExamId.value + "/result"
                  //             }
                  //           >
                  //             View test{" "}
                  //             <i className="fas fa-chevron-right ml-2 pointer"></i>
                  //           </Link>
                  //         </span>
                  //       ) : (
                  //         ""
                  //       )}
                  //     </span>
                  //   );
                  // }
                }
              }

              if (rec.result.value) {
                if (rec.result.value.toLowerCase() == "not evaluated") {
                  return <span className="">{data}</span>;
                }
              }

              return (
                <React.Fragment>
                  <Link to={"/event/test/" + rec.userExamId.value + "/result"}>
                    <span className="pointer">{data}</span>
                  </Link>
                  {/* <span className="float-right mr-4 grid-controls mt-2">
                    <Link to={"/event/test/" + rec.examEventId.value}>
                      <i className="fas fa-search mr-4 pointer"></i>
                    </Link>
                  </span> */}
                </React.Fragment>
              );
            },
            availability: function (data, rec, obj) {
              return UniFun.fromToDateString(rec.from.value, rec.to.value);
            },
            status: function (data, rec, obj) {
              if (moment(rec.from.value) > moment()) {
                return "Not started";
              }

              if (rec.status.value) {
                if (
                  rec.status.value.toLowerCase() == "fail" ||
                  rec.status.value.toLowerCase() == "pass" ||
                  rec.status.value.toLowerCase() == "submitted" ||
                  rec.status.value.toLowerCase() == "not evaluated" ||
                  rec.status.value.toLowerCase() == "no grade"
                ) {
                  return "Submitted";
                }

                if (rec.status.value.toLowerCase() == "not submitted") {
                  return rec.status.value;
                }
              }

              return "Not started";
            },
            trainingTitle: function (data, rec, obj) {
              if (rec.trainingId.value) {
                return (
                  <Link
                    to={"/event/training/" + rec.trainingId.value}
                    className="text-primary"
                  >
                    {data}
                  </Link>
                );
              }
              return data;
            },
            result: function (data, rec, obj) {
              if (
                rec.evaluationMode.value ||
                UniFun.userIsEligible({ forWhat: "admin" })
              ) {
                return "";
              }

              if (
                parseFloat(rec.passingGrade.value) == 0 ||
                !rec.passingGrade.value
              ) {
                return "---";
              }

              switch (rec.result.value) {
                case "Pass":
                  return <span className="text-success">Pass</span>;
                  break;
                case "Fail":
                  return <span className="text-danger">Fail</span>;
                  break;
                default:
                  return "---";
                  break;
              }
            },
            finalGrade: function (data, rec, obj) {
              if (
                rec.evaluationMode.value ||
                UniFun.userIsEligible({ forWhat: "admin" })
              ) {
                return "";
              }

              if (rec.result.value) {
                if (
                  rec.result.value.toLowerCase() != "fail" &&
                  rec.result.value.toLowerCase() != "pass"
                ) {
                  return "---";
                }
              }

              //if (parseFloat(rec.passingGrade.value) >= 0) {
              let sumanswers = "";

              if (rec.questions.value) {
                let answerCorrectQuestions = rec.questions.value.length;

                rec.questions.value.map((itm, idx) => {
                  let answerIsCorrect = true;

                  if (itm.type !== "essayType") {
                    itm.answers.map((aitm, aid) => {
                      let isCorrect = aitm.isCorrect
                        ? aitm.isCorrect.toString()
                        : "false";
                      let userAnswer = aitm.userAnswer.toString();

                      if (isCorrect != userAnswer) {
                        answerIsCorrect = false;
                      }
                    });

                    if (!answerIsCorrect) {
                      answerCorrectQuestions--;
                    }
                  }
                });
                // sumanswers =
                //   "(" +
                //   answerCorrectQuestions +
                //   "/" +
                //   rec.questions.value.length +
                //   "ans.)";

                return rec.finalGrade.value + "% " + sumanswers;
              }
              //}

              if (rec.finalGrade.value || rec.finalGrade.value == 0) {
                return rec.finalGrade.value + "%";
              }
              return "---";
            },
          },
        },
        // header: UniFun.commonHeader,
      },
      pk: "id",
      properties: TrainingTests({ editable: false }),
    },
    trainingResultsAdmin: {
      apis: {
        view: {
          url: "{path}",
          parameters: {
            headers: { "content-type": "application/json" },
          },
          parameters: {
            replace: {
              start: 0,
              offset: 1000,
            },
          },
          formatter: function (data, builder) {
            return TrainingTestsFormatter(data, builder);
          },
        },
      },
      template: "grid",
      templateConfig: {
        events: {},
        headerTitle: "Tests",
        headerTitleIcon: "/uni/svg/trainingmain.svg",
        colsTemplateCount: 12,
        includeProperties: ["title", "availability", "statusAdmin"],
        gridConfig: {
          emptyFolder: function () {
            if (UniFun.userIsEligible({ forWhat: "module" })) {
              return (
                <div className="row mt-4 " style={{ height: "500px" }}>
                  <div className="col-12 text-center">
                    <h5>
                      <strong>Tests are empty</strong>
                    </h5>
                    <span>Scheduled tests will appear here</span>
                  </div>
                </div>
              );
            }

            return (
              <div className="row mt-4 " style={{ height: "500px" }}>
                <div className="col-12 text-center">
                  <h5>
                    <strong>
                      {UniFun.userIsEligible({ forWhat: "isInstructor" })
                        ? "There are not any pending evaluations"
                        : "There are not test results yet"}
                    </strong>
                  </h5>
                  <span>
                    {UniFun.userIsEligible({ forWhat: "isInstructor" })
                      ? "Tests that need to be evaluated will appear here"
                      : ""}
                  </span>
                </div>
              </div>
            );
          },
          headers: {
            title: {
              attributes: {
                className: "w-50",
              },
            },
          },
          cellTemplates: {
            title: function (data, rec, obj) {
              return (
                <React.Fragment>
                  <Link
                    to={
                      "/event/training/" +
                      Fun.getURLPathValue(3) +
                      "/exam/" +
                      rec._id.value +
                      "/results"
                    }
                  >
                    <span className="pointer">{data}</span>
                  </Link>
                  {/* <span className="float-right mr-4 grid-controls mt-2">
                    <Link to={"/event/test/" + rec.examEventId.value}>
                      <i className="fas fa-search mr-4 pointer"></i>
                    </Link>
                  </span> */}
                </React.Fragment>
              );
            },
            availability: function (data, rec, obj) {
              return UniFun.fromToDateString(rec.from.value, rec.to.value);
            },
          },
        },
        // header: UniFun.commonHeader,
      },
      pk: "id",
      properties: TrainingTests({ editable: false }),
    },
  },
};

function TrainingTestsFormatter(data) {
  let formattedData = [];
  if (data) {
    if (data.data) {
      data.data.map((itm, idx) => {
        let formattedRecord = {};
        formattedRecord["_id"] = itm._id;
        formattedRecord["availability"] = itm.availability;
        formattedRecord["from"] = itm.from;
        formattedRecord["to"] = itm.to;
        formattedRecord["seen"] = itm.seen;

        formattedRecord["title"] = itm.title;
        formattedRecord["examEventId"] = itm.examEventId;
        formattedRecord["trainingId"] = itm.trainingId;
        formattedRecord["trainingTitle"] = itm.trainingTitle
          ? itm.trainingTitle
          : "---";
        formattedRecord["submitted"] = itm.submitted;
        formattedRecord["status"] = itm.status;
        formattedRecord["result"] = itm.result;
        formattedRecord["statusAdmin"] = itm.status;
        formattedRecord["userExamId"] = itm.userExamId;

        formattedRecord["participantsSubmitted"] = itm.participantsSubmitted;
        formattedRecord["allParticipants"] = itm.allParticipants;
        formattedRecord["finalGrade"] = itm.finalGrade;
        formattedRecord["finalGrade"] = itm.grade;
        formattedRecord["passingGrade"] = itm.passingGrade;
        formattedRecord["questions"] = itm.questions;
        formattedRecord["evaluationMode"] = itm.evaluationMode;

        formattedData.push(formattedRecord);
      });
    }
  }

  return formattedData;
}

function TrainingTests(args) {
  let editable = args.editable;
  let show = args.show === false ? false : true;

  return {
    _id: {
      alias: "_id",
      control: "hidden",
      editable: editable,
      label: "",
      attributes: {
        className: "form-control",
      },
    },
    examEventId: {
      alias: "examEventId",
      control: "hidden",
      editable: editable,
      label: "",
      attributes: {
        className: "form-control",
      },
    },
    userExamId: {
      alias: "userExamId",
      control: "hidden",
      editable: editable,
      label: "",
      attributes: {
        className: "form-control",
      },
    },
    title: {
      alias: "title",
      control: "text",
      editable: editable,
      label: "Title",
      useCustomLayout: true,
      customLayout: function () {
        let bgcolor = "#27CCBD";
        //#27CCBD
        let data = this.props.data;
        let type = "Practice";
        let evaluationMode = this.props.data.evaluationMode;

        if (parseFloat(data.passingGrade) > 0) {
          bgcolor = "#D7B835";
          type = "Graded";
        }

        let hasEssay = false;

        this.props.data.questions?.forEach((itm, idx) => {
          if (itm.type == "essayType") {
            hasEssay = true;
          }
        });

        let evaluationStatus = "";

        if (hasEssay) {
          switch (this.props.data.status) {
            case "Not Evaluated":
              evaluationStatus = (
                <span
                  class="p-2  rounded ml-2 text-white"
                  style={{ backgroundColor: "#9D274E" }}
                >
                  Pending evaluation
                </span>
              );
              break;

            case "Pass":
              evaluationStatus = (
                <span
                  class="p-2  rounded ml-2 "
                  style={{ backgroundColor: "#D3EFFF" }}
                >
                  Evaluated
                </span>
              );
              break;

            case "Fail":
              evaluationStatus = (
                <span
                  class="p-2  rounded ml-2"
                  style={{ backgroundColor: "#D3EFFF" }}
                >
                  Evaluated
                </span>
              );
              break;
          }
        }

        if (this.props.data.result == "Not Evaluated") {
          evaluationStatus = (
            <span
              class="p-2  rounded ml-2 text-white"
              style={{ backgroundColor: "#9D274E" }}
            >
              Pending evaluation
            </span>
          );
        }

        return (
          <div style={{ display: "contents" }}>
            <span
              className="avatar-circle avatar-default"
              style={{ backgroundColor: bgcolor }}
            >
              <div className=" h-100 text-center">
                <img
                  src="/uni/svg/tests.svg"
                  style={{ width: "20px" }}
                  className="filter-white"
                ></img>
              </div>
            </span>
            <span className="ml-2 pr-4" style={{ position: "relative" }}>
              {this.state.defaultValue}
              {evaluationMode ? (
                evaluationStatus
              ) : (
                <>
                  <span className="p-2  rounded ml-2 bg-light">{type}</span>
                  {data.seen ? "" : evaluationStatus}
                </>
              )}
            </span>
          </div>
        );
      },
      attributes: {
        className: "form-control",
      },
    },
    trainingTitle: {
      alias: "trainingTitle",
      control: "text",
      editable: editable,
      label: "Training",
    },
    seen: {
      alias: "seen",
      control: "text",
      editable: editable,
      label: "",
    },
    availability: {
      alias: "availability",
      control: "text",
      editable: editable,
      label: "Availability",
    },
    trainingId: {
      alias: "trainingId",
      control: "hidden",
      editable: editable,
      label: "",
    },
    submitted: {
      alias: "submitted",
      control: "datetime",
      editable: editable,
      label: "Submitted on",
      attributes: {
        className: "form-control",
      },
    },
    status: {
      alias: "status",
      control: "text",
      editable: editable,
      label: "Result",
      attributes: {
        className: "form-control",
      },
    },
    result: {
      alias: "result",
      control: "text",
      editable: editable,
      label: "Result",
      attributes: {
        className: "form-control",
      },
    },
    statusAdmin: {
      alias: "statusAdmin",
      control: "text",
      editable: editable,
      label: "Status",
      attributes: {
        className: "form-control",
      },
      useCustomLayout: true,
      customLayout: function () {
        let data = this.props.data;

        if (moment(data.from) > moment()) {
          return "Not started";
        }

        return (
          data.participantsSubmitted +
          "/" +
          data.allParticipants +
          " have submitted the test"
        );
      },
    },
    from: {
      alias: "from",
      control: "hidden",
      editable: editable,
      label: "",
      attributes: {
        className: "form-control",
      },
    },
    to: {
      alias: "to",
      control: "hidden",
      editable: editable,
      label: "",
      attributes: {
        className: "form-control",
      },
    },
    questions: {
      alias: "questions",
      control: "text",
      editable: editable,
      label: "",
      attributes: {
        className: "form-control",
      },
    },
    passingGrade: {
      alias: "passingGrade",
      control: "text",
      editable: editable,
      label: "",
      attributes: {
        className: "form-control",
      },
    },
    finalGrade: {
      alias: "finalGrade",
      control: "text",
      editable: editable,
      label: "Grade",
      attributes: {
        className: "form-control",
      },
    },
    evaluationMode: {
      alias: "evaluationMode",
      control: "text",
      label: "",
      editable: editable,
      attributes: {
        className: "form-control",
      },
    },
  };
}

export default UniTestResults;
