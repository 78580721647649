import React, { Component } from "react";
import Switch from "react-switch";

class ToogleSwitch extends Component {
  state = {
    name: "",
    id: "",
    placeholder: "",
    attributes: "",
    value: "",
    defaultValue: "",
    loaded: false,
    editable: true,
    component: null,
    attributes: "",
    events: {},
    customLayout: null,
    useCustomLayout: true,
    editable: true,
  };

  constructor(props) {
    super(props);

    let events = {};
    if (this.props.field.events) {
      Object.keys(this.props.field.events).map((itm, idx) => {
        this[itm] = this.props.field.events[itm];
        this[itm] = this[itm].bind(this);
        events[itm] = this[itm];
      });
    }

    this.editable = this.editable.bind(this);
    this.nonEditable = this.nonEditable.bind(this);
    this.handleChange = this.handleChange.bind(this);

    let defaultValue = false;
    if (this.props.field.defaultValue) {
      if (typeof this.props.value === "undefined") {
        defaultValue = this.props.field.defaultValue;
      }
    }

    this.state = {
      events: events,
      editable:
        this.props.field.editable != null
          ? this.props.field.editable
          : this.state.editable,
      checked: this.props.value ? this.props.value : defaultValue,
    };
  }

  componentDidMount() {
    this.setState({
      attributes: this.props.field.attributes,
      name: this.props.field.alias,
      caption: this.props.field.caption,
      editable:
        this.props.field.editable != null
          ? this.props.field.editable
          : this.state.editable,
      customLayout: this.props.field.customLayout,
      useCustomLayout:
        typeof this.props.field.useCustomLayout !== "undefined"
          ? this.props.field.useCustomLayout
          : true,
    });

    if (this.didMount) {
      setTimeout(this.didMount, 0);
    }
  }

  handleChange(checked) {
    this.setState({ checked });

    if (this.onChange) {
      this.onChange(checked);
    }
  }

  editable() {
    let output = (
      <div {...this.state.attributes}>
        <Switch
          onChange={this.handleChange}
          checked={this.state.checked}
          className="react-switch"
        />
        <input
          type="hidden"
          defaultValue={this.state.checked}
          name={this.state.name}
        ></input>
      </div>
    );
    if (this.state.customLayout && this.state.useCustomLayout == true) {
      return this.state.customLayout({ control: output, object: this });
    }

    return output;
  }

  nonEditable() {
    let output = (
      <React.Fragment>
        <div>
          <Switch
            onChange={this.handleChange}
            checked={this.state.checked}
            disabled={true}
          />
        </div>
      </React.Fragment>
    );

    if (this.state.customLayout && this.state.useCustomLayout == true) {
      return this.state.customLayout({ control: output, object: this });
    }

    return output;
  }

  render() {
    let output = this.state.editable ? this.editable() : this.nonEditable();

    return output;
  }
}

export default ToogleSwitch;
