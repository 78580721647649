import moment from "moment";
import React, { Component } from "react";
import Fun from "../../../lib/functions";
import UniFun from "../functions";

const UniVideoConference = {
  auth: "",
  views: {
    detail: {
      auth: "",
      apis: {
        view: {
          data: { data: [{}] },
          parameters: {
            headers: {},
            replace: {},
          },
          formatter: function (data) {
            return UniVideoConferenceFormatter(data);
          },
        },
      },
      template: "form",
      templateConfig: {
        events: {
          onSubmit: function (e) {
            e.preventDefault();

            if (
              Fun.validate(this.props.builder.validator, Fun.formDataToJSON(e))
            ) {
              let submittedData = Fun.getSubmitJSON(e);

              window["modal2"].setState({
                show: false,
                data: submittedData,
                overflowY: "auto",
              });

              if (window["modal2"].state.callback) {
                window["modal2"].state.callback(submittedData);
              } else {
                if (window["modal2"].state.targetComponent) {
                  window["modal2"].state.targetComponent.setState({
                    data: submittedData,
                  });
                }
              }
            }
          },
        },
        includeProperties: ["title", "file"],
      },
      pk: "_id",
      properties: UniVideoConferenceProperties({ editable: true }),
    },
    eventDetail: {
      auth: "",
      apis: {
        view: {
          data: { data: [{}] },
          parameters: {
            headers: {},
            replace: {},
          },
          formatter: function (data) {
            return UniVideoConferenceFormatter(data);
          },
        },
      },
      template: "form",
      templateConfig: {
        panelBottom: function () {
          return <p></p>;
        },
        events: {
          onSubmit: function (e) {
            e.preventDefault();
            let submittedData = Fun.getSubmitJSON(e);

            let from = UniFun.fromToDateFormat(
              submittedData.fromToDateTime[0].fromDateTime[0].fromDate,
              submittedData.fromToDateTime[0].fromDateTime[0].fromTime
            );

            let to = UniFun.fromToDateFormat(
              submittedData.fromToDateTime[0].toDateTime[0].toDate,
              submittedData.fromToDateTime[0].toDateTime[0].toTime
            );

            if (
              !Fun.validate(this.props.builder.validator, submittedData) ||
              !UniFun.fromToValidation(from, to)
            ) {
              return;
            }

            if (window["modal2"].state.callback) {
              window["modal2"].state.callback(submittedData);
            } else {
              if (window["modal2"].state.targetComponent) {
                window["modal2"].state.targetComponent.setState({
                  data: submittedData,
                });
              }
            }
            window["modal2"].setState({
              show: false,
              data: submittedData,
              overflowY: "auto",
            });
          },
        },
        includeProperties: ["_id", "title", "fromToDateTime", "reminders"],
      },
      pk: "_id",
      properties: UniVideoConferenceProperties({ editable: true }),
    },
  },
};

export default UniVideoConference;

function UniVideoConferenceProperties(input) {
  let editable = input.editable;

  return {
    _id: {
      alias: "_id",
      control: "hidden",
      editable: editable,
    },
    title: {
      alias: "title",
      control: "text",
      label: "Title",
      placeholder: "Type video conference title",
      validations: "required",
      editable: editable,
      attributes: {
        className: "form-control",
      },
    },
    from: {
      alias: "from",
      control: "datetime",
      config: {
        fromFormat: "YYYY-MM-DDT00:00:00.000Z",
        toFormat: "YYYY-MM-DDT00:00:00.000Z",
        includeTime: false,
        valueEditFormat: { dateStyle: "short" },
        valueDisplayFormat: { dateStyle: "short" },
      },
      label: "Choose date",
      editable: editable,
    },
    // fromToTime: {
    //   alias: "fromToTime",
    //   control: "group",
    //   label: "Choose time",
    //   editable: editable,
    //   customLayout: function (args) {
    //     return (
    //       <React.Fragment>
    //         <label>{args.object.props.field.label}</label>
    //         <div className="row mb-3">
    //           <div className="col-md-2 pr-0">{args.control[0]}</div>
    //           <div className="col-md-1 mt-1 text-center">to</div>
    //           <div className="col-md-2 pl-0">{args.control[1]}</div>
    //           <div className="col-md-7"></div>
    //         </div>
    //       </React.Fragment>
    //     );
    //   },
    //   useCustomLayout: true,
    //   config: {
    //     properties: {
    //       fromTime: {
    //         alias: "fromTime",
    //         control: "select",
    //         config: {
    //           options: UniFun.timeSelect,
    //         },
    //         editable: editable,
    //         attributes: {
    //           className: "form-control",
    //         },
    //       },
    //       toTime: {
    //         alias: "toTime",
    //         control: "select",
    //         config: {
    //           options: UniFun.timeSelect,
    //         },
    //         editable: editable,
    //         attributes: {
    //           className: "form-control",
    //         },
    //       },
    //     },
    //   },
    // },
    fromToDateTime: {
      alias: "fromToDateTime",
      control: "group",
      label: "Availability",
      editable: editable,
      customLayout: function (args) {
        return (
          <React.Fragment>
            <label>{args.object.props.field.label}</label>
            <div className="row mb-3">
              <div className="col-md-5 pr-0 availability-calendar">
                {args.control[0]}
              </div>
              <div className="col-md-1 text-center pr-0 pt-2">to</div>
              <div className="col-md-5 pl-0 availability-calendar">
                {args.control[1]}
              </div>
              <div className="col-md-1"></div>
            </div>
          </React.Fragment>
        );
      },
      useCustomLayout: true,
      config: {
        properties: {
          fromDateTime: {
            alias: "fromDateTime",
            control: "group",
            editable: editable,
            customLayout: function (args) {
              return (
                <React.Fragment>
                  <div className="row mb-3">
                    <div className="col-md-8 pr-0">{args.control[0]}</div>
                    <div className="col-md-4 pr-0">{args.control[1]}</div>
                  </div>
                </React.Fragment>
              );
            },
            useCustomLayout: true,
            config: {
              properties: {
                fromDate: {
                  alias: "fromDate",
                  control: "datetime",
                  config: {
                    fromFormat: "YYYY-MM-DDT00:00:00.000Z",
                    toFormat: "YYYY-MM-DDT00:00:00.000Z",
                    includeTime: false,
                    valueEditFormat: { dateStyle: "short" },
                    valueDisplayFormat: { dateStyle: "short" },
                  },
                  editable: editable,
                },
                fromTime: {
                  alias: "fromTime",
                  control: "select",
                  config: {
                    options: UniFun.timeSelect,
                  },
                  editable: editable,
                  attributes: {
                    className: "form-control",
                  },
                },
              },
            },
          },
          toDateTime: {
            alias: "toDateTime",
            control: "group",
            editable: editable,
            customLayout: function (args) {
              return (
                <React.Fragment>
                  <div className="row mb-3">
                    <div className="col-md-8 pr-0">{args.control[0]}</div>
                    <div className="col-md-4 pr-0">{args.control[1]}</div>
                  </div>
                </React.Fragment>
              );
            },
            useCustomLayout: true,
            config: {
              properties: {
                toDate: {
                  alias: "toDate",
                  control: "datetime",
                  config: {
                    fromFormat: "YYYY-MM-DDT00:00:00.000Z",
                    toFormat: "YYYY-MM-DDT00:00:00.000Z",
                    includeTime: false,
                    valueEditFormat: { dateStyle: "short" },
                    valueDisplayFormat: { dateStyle: "short" },
                  },
                  editable: editable,
                },
                toTime: {
                  alias: "toTime",
                  control: "select",
                  config: {
                    options: UniFun.timeSelect,
                  },
                  editable: editable,
                  attributes: {
                    className: "form-control",
                  },
                },
              },
            },
          },
        },
      },
    },
    reminders: {
      alias: "reminders",
      control: "select",
      config: {
        options: [
          {
            label: "5 minutes before",
            value: JSON.stringify({ type: "minutes", value: 5 }),
          },
          {
            label: "10 minutes before",
            value: JSON.stringify({ type: "minutes", value: 10 }),
          },
          {
            label: "30 minutes before",
            value: JSON.stringify({ type: "minutes", value: 30 }),
          },
          {
            label: "1 hour before",
            value: JSON.stringify({ type: "minutes", value: 60 }),
          },
          {
            label: "1 day before",
            value: JSON.stringify({ type: "days", value: 1 }),
          },
        ],
      },
      label: "Reminder",
      editable: editable,
      attributes: {
        className: "form-control",
      },
    },
    file: {
      alias: "file",
      control: "dropzone",
      label: "Attach file",
      editable: editable,
      config: {
        placeholder: function () {
          return (
            <div>
              <strong>Drag & drop here or browse file to attach</strong>
              <br></br>
              <span className="text-dark">Maximum size:50MB</span>
            </div>
          );
        },
      },
      events: {
        onDrop: function (files) {
          UniFun.fileProgress({ control: this, files: files });
        },
      },
      attributes: {
        className: "border rounded p-3 pb-5",
      },
    },
  };
}

function UniVideoConferenceFormatter(data) {
  if (data) {
    let formattedData = [];
    data.data.map((itm, idx) => {
      let formattedRecord = {};

      const start = moment();
      const halfhour = 30 - (start.minute() % 30);
      const dateFromTime = moment(start)
        .add(halfhour, "minutes")
        .format("HH:mm:00");
      const dateToTime = moment(start)
        .add(halfhour, "minutes")
        .add("3", "hours")
        .format("HH:mm:00");

      let fromTime = dateFromTime;
      if (itm.from) {
        fromTime = moment(itm.from).format("HH:mm:00");
      }

      let toTime = dateToTime;
      if (itm.to) {
        toTime = moment(itm.to).format("HH:mm:00");
      }

      if (!itm.from && !itm.to) {
        if (
          moment(start)
            .add(halfhour, "minutes")
            .isBefore(moment(start).add(halfhour, "minutes").add("3", "hours"))
        ) {
          itm.to = moment(start)
            .add(halfhour, "minutes")
            .add("3", "hours")
            .add("1", "days");
        }
      }

      formattedRecord["fromToDateTime"] = {
        fromDateTime: {
          fromDate: itm.from,
          fromTime: fromTime,
        },
        toDateTime: {
          toDate: itm.to,
          toTime: toTime,
        },
      };

      formattedRecord["from"] = itm.from;
      formattedRecord["to"] = itm.to;
      formattedRecord["reminders"] = itm.reminders
        ? JSON.stringify({
            type: itm.reminders[0].type,
            value: itm.reminders[0].value,
          })
        : {};

      formattedRecord["_id"] = itm._id;
      formattedRecord["title"] = itm.title;
      formattedRecord["file"] = itm.file;
      formattedData.push(formattedRecord);
    });

    return formattedData;
  }
}
