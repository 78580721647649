import React, { Component } from "react";
import Fun from "../../../lib/functions";
import { Link, withRouter } from "react-router-dom";

class SquaresForms extends Component {
  state = {
    data: [],
    loaded: false,
    templateTop: "",
    templateBottom: "",
    templateLeft: "",
    templateRight: "",
    panelTop: "",
    panelBottom: "",
    panelLeft: "",
    panelRight: "",
    header: "",
    colsTemplateCount: 12,
    colsFormCount: 12,
    squaresConfig: {
      titleAttribute: "title",
      colorAttribute: "color",
      iconAttribute: '<i class="fas fa-infinity"></i>',
      dataSections: [],
    },
  };

  constructor(props) {
    super(props);

    // this.createBody = this.createBody.bind(this);
    this.createSqueares = this.createSquares.bind(this);
    //  this.onClick = this.onClick.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.action) {
      return false;
    }
    return true;
  }

  componentDidMount() {
    let builder = this.props.builder.state;
    let templateConfig = builder.module.templateConfig;
    let useCard =
      typeof templateConfig.useCard !== "undefined"
        ? templateConfig.useCard
        : true;
    let templateTop = templateConfig.templateTop
      ? templateConfig.templateTop
      : () => {};

    this["templateTop"] = templateTop.bind(this);
    let templateBottom = templateConfig.templateBottom
      ? templateConfig.templateBottom
      : () => {};
    this["templateBottom"] = templateBottom.bind(this);
    let templateLeft = templateConfig.templateLeft
      ? templateConfig.templateLeft
      : () => {};
    this["templateLeft"] = templateLeft.bind(this);
    let templateRight = templateConfig.templateRight
      ? templateConfig.templateRight
      : () => {};
    this["templateRight"] = templateRight.bind(this);
    let cardTemplateTop = templateConfig.cardTemplateTop
      ? templateConfig.cardTemplateTop
      : () => {};
    this["cardTemplateTop"] = cardTemplateTop.bind(this);
    let cardTemplateBottom = templateConfig.cardTemplateBottom
      ? templateConfig.cardTemplateBottom
      : () => {};
    this["cardTemplateBottom"] = cardTemplateBottom.bind(this);
    let panelTop = templateConfig.panelTop ? templateConfig.panelTop : () => {};
    this["panelTop"] = panelTop.bind(this);
    let panelBottom = templateConfig.panelBottom
      ? templateConfig.panelBottom
      : () => {};
    this["panelBottom"] = panelBottom.bind(this);
    let panelLeft = templateConfig.panelLeft
      ? templateConfig.panelLeft
      : () => {};
    this["panelLeft"] = panelLeft.bind(this);
    let panelRight = templateConfig.panelRight
      ? templateConfig.panelRight
      : () => {};
    this["panelRight"] = panelRight.bind(this);
    let header = templateConfig.header ? templateConfig.header : () => {};
    this["header"] = header.bind(this);

    let colsTemplateCount = templateConfig.colsTemplateCount
      ? templateConfig.colsTemplateCount
      : this.state.colsTemplateCount;
    let colsFormCount = templateConfig.colsFormCount
      ? templateConfig.colsFormCount
      : this.state.colsFormCount;

    let squaresTitleAttribute = this.state.squaresConfig.titleAttribute;
    let squaresColorAttribute = this.state.squaresConfig.colorAttribute;
    let squaresIconAttribute = this.state.squaresConfig.iconAttribute;
    let btnOptions = this.state.squaresConfig.btnOptions;

    if (templateConfig.squaresConfig) {
      if (templateConfig.squaresConfig.titleAttribute) {
        squaresTitleAttribute = templateConfig.squaresConfig.titleAttribute;
      }
      if (templateConfig.squaresConfig.colorAttribute) {
        squaresColorAttribute = templateConfig.squaresConfig.colorAttribute;
      }
      if (templateConfig.squaresConfig.iconAttribute) {
        squaresIconAttribute = templateConfig.squaresConfig.iconAttribute;
      }
      if (templateConfig.squaresConfig.btnOptions) {
        btnOptions = templateConfig.squaresConfig.btnOptions;
      }
    }

    this.setState({
      data: this.props.data,
      loaded: true,
      templateTop: templateTop,
      templateBottom: templateBottom,
      templateLeft: templateLeft,
      templateRight: templateRight,
      panelTop: panelTop,
      panelBottom: panelBottom,
      panelLeft: panelLeft,
      panelRight: panelRight,
      header: header,
      colsTemplateCount: colsTemplateCount,
      colsFormCount: colsFormCount,
      squaresConfig: {
        titleAttribute: squaresTitleAttribute,
        colorAttribute: squaresColorAttribute,
        iconAttribute: squaresIconAttribute,
        btnOptions: btnOptions,
      },
    });
  }

  // onClick(e, itm) {
  //   this.props.builder.setState({ loaded: false });
  //   this.props.builder.rerunComponent();
  // }

  createSquares() {
    let output = [];

    this.state.data.map((itm, idx) => {
      //itm._id.data.onClick
      const card = (
        <div
          className="card rounded bg-events-overview-meetings bg-events-overview-rounded pl-3 pt-3 pointer"
          style={{
            backgroundColor:
              "#" + itm[this.state.squaresConfig.colorAttribute].value,
            backgroundPosition: "center",
            backgroundImage:
              "url(" + itm[this.state.squaresConfig.iconAttribute].value + ")",
          }}
        >
          {itm[this.state.squaresConfig.titleAttribute].value ? (
            <div
              className="card-header border-0 bg-events-overview-meetings bg-events-overview-rounded w-75"
              style={{
                backgroundColor:
                  "#" + itm[this.state.squaresConfig.colorAttribute].value,
                minHeight: "120px",
              }}
            >
              <h4>{itm[this.state.squaresConfig.titleAttribute].value}</h4>
            </div>
          ) : (
            <div
              style={{
                minHeight: "120px",
              }}
            >
              {itm[this.state.squaresConfig.iconAttribute].value}
            </div>
          )}
          <div
            className="card-body border-0 pt-0 text-secondary"
            style={{ minHeight: "70px" }}
          ></div>
          <div
            className="card-footer border-0 bg-events-overview-meetings bg-events-overview-rounded mb-3"
            style={{
              backgroundColor:
                "#" + itm[this.state.squaresConfig.colorAttribute].value,
            }}
          >
            {itm._id.value !== "0" && this.state.squaresConfig.btnOptions ? (
              <div className="btn-group">
                <button
                  type="button"
                  className="btn btn-light bg-white rounded"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="fas fa-ellipsis-h"></i>
                </button>
                <div className="dropdown-menu">
                  {Object.keys(this.state.squaresConfig.btnOptions).map(
                    (opt, oidx) => {
                      return (
                        <span
                          className="dropdown-item"
                          onClick={(e) =>
                            this.state.squaresConfig.btnOptions[opt].onClick(
                              e,
                              itm,
                              this
                            )
                          }
                        >
                          {this.state.squaresConfig.btnOptions[opt].label}
                        </span>
                      );
                    }
                  )}
                </div>
              </div>
            ) : (
              <div
                className="btn-group"
                style={{ paddingBottom: "38px" }}
              ></div>
            )}
          </div>
        </div>
      );

      if (itm._id.data.onClick) {
        output.push(
          <div className="col-md-3">
            <div
              onClick={(e) => {
                itm._id.data.onClick(this.props.builder);
              }}
            >
              {card}
            </div>
          </div>
        );
      } else {
        output.push(
          <div className="col-md-3">
            <Link to={itm["url"].value}>{card}</Link>
          </div>
        );
      }
    });

    return output;
  }

  render() {
    if (this.state.loaded) {
      return (
        <React.Fragment>
          {this.header()}
          {this.panelTop()}
          <div className="container-fluid">
            {this.templateTop()}
            <div className="row">
              {this.panelLeft()}
              <div className={"col-" + this.state.colsTemplateCount}>
                <div className="row">
                  {this.templateLeft()}
                  <div className={"col-" + this.state.colsFormCount}>
                    {this.cardTemplateTop()}
                    <div className="row  w-75">{this.createSquares()}</div>
                    {this.cardTemplateBottom()}
                  </div>
                  {this.templateRight()}
                </div>
              </div>
              {this.panelRight()}
            </div>
            {this.templateBottom()}
          </div>
          {this.panelBottom()}
        </React.Fragment>
      );
    } else {
      return "loading...";
    }
  }
}

export default withRouter(SquaresForms);
