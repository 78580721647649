import React, { Component } from "react";
import Fun from "../../../../../lib/functions";
import Controls from "../../../../../controls/controls";
import SimpleReactValidator from "simple-react-validator";
import { Link } from "react-router-dom";

class InterestForm extends Component {
  state = {
    data: this.props.data,
    loaded: false,
  };

  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator();

    let builder = this.props.builder.state;
    let templateConfig = builder.module.templateConfig;
    if (templateConfig.events) {
      Object.keys(templateConfig.events).map((itm, idx) => {
        this[itm] = templateConfig.events[itm].bind(this);
      });
    }

    this.form = React.createRef();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.action) {
      return false;
    }
    return true;
  }

  componentDidMount() {
    let field = {
      alias: "country",
      label: "Country",
      config: {
        options: function () {
          let control = this;

          Fun.fetch({
            url: {
              url: "/countries",
              parameters: {
                replace: {},
                headers: {},
              },
            },
            callback: function (args) {
              let output = [
                {
                  label: "Please select",
                  value: "",
                },
              ];

              let data = args;

              data.map((itm, idx) => {
                output.push({
                  label: itm,
                  value: itm,
                });
              });

              control.setState({ options: output });
            },
          });

          return [];
        },
      },
      attributes: {
        className: "form-control",
      },
    };

    let Control = Controls["select"];

    this.setState({
      loaded: true,
      country: <Control field={field} key={Fun.uuid()}></Control>,
    });

    //   <<GoogleReCaptchaProvider reCaptchaKey="6LfqMDQeAAAAAKxo_kbK9dVlnwXtRoGWTEOUWn-X">
    //   <GoogleReCaptcha onVerify={this.handleVerify} />
    // </GoogleReCaptchaProvider>>

    if (this.componentDidMountEvent) {
      this.componentDidMountEvent();
    }
  }

  setInputState(name, e) {
    this.setState({ [name]: e.target.value });
  }

  render() {
    return (
      <div className="container-fluid p-5">
        <span className="d-block text-grey pb-4">
          Please fill the form below to reserver a seat and we will contact you
          asap. (*) required fields.
        </span>
        <form onSubmit={this.onSubmit} ref={this.form}>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="firstname">First name *</label>
              <input
                type="text"
                className="form-control"
                name="firstname"
                id="firstname"
                placeholder="Type your first name"
                onChange={(e) => this.setInputState("firstname", e)}
              />

              {this.validator.message(
                "First name",
                this.state.firstname,
                "required",
                { className: "text-danger pt-2" }
              )}
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="lastname">Last name *</label>
              <input
                type="text"
                className="form-control"
                name="lastname"
                id="lastname"
                placeholder="Type your last name"
                onChange={(e) => this.setInputState("lastname", e)}
              />
              {this.validator.message(
                "Last name",
                this.state.lastname,
                "required",
                { className: "text-danger pt-2" }
              )}
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="jobtitle">Job title *</label>
              <input
                type="text"
                className="form-control"
                name="jobtitle"
                id="jobtitle"
                placeholder="Type your job title"
                onChange={(e) => this.setInputState("jobtitle", e)}
              />
              {this.validator.message(
                "Job title",
                this.state.jobtitle,
                "required",
                { className: "text-danger pt-2" }
              )}
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="company">Company *</label>
              <input
                type="text"
                className="form-control"
                name="company"
                id="company"
                placeholder="Type your company's name"
                onChange={(e) => this.setInputState("company", e)}
              />
              {this.validator.message(
                "company's name",
                this.state.company,
                "required",
                { className: "text-danger pt-2" }
              )}
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="emailaddress">Email address *</label>
              <input
                type="text"
                className="form-control"
                name="emailaddress"
                id="emailaddress"
                placeholder="Type your email address"
                onChange={(e) => this.setInputState("emailaddress", e)}
              />
              {this.validator.message(
                "email address",
                this.state.emailaddress,
                "required|email",
                { className: "text-danger pt-2" }
              )}
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="phonenumber">Phone number</label>
              <input
                type="text"
                className="form-control"
                name="phonenumber"
                id="phonenumber"
                placeholder="Type your phone number"
              />
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="country">Country</label>

            {this.state.country}
          </div>
          <div className="form-group">
            <label htmlFor="comments">Anything else?</label>
            <textarea
              className="form-control"
              id="comments"
              rows="3"
              name="comments"
              placeholder="Tell us more about your needs"
            ></textarea>
          </div>
          <div className="form-group">
            <button type="submit" className=" btn btn-primary">
              Send request
            </button>
            <span className="small d-block pt-2 w-50">
              We respect your privacy and wherever your information is processed
              we will handle it with care. We want you to understand how we
              collect, use, and share data about you. Our data collection
              practices are described in our{" "}
              <a
                href={"/events/conferences/privacy"}
                target="_blank"
                className="text-primary"
              >
                Privacy Policy
              </a>
              . This site is protected by reCAPTCHA and the Google{" "}
              <a
                href="https://policies.google.com/privacy?hl=en"
                target="_blank"
                className="text-primary"
              >
                Privacy Policy
              </a>{" "}
              and{" "}
              <a
                href="https://policies.google.com/terms?hl=en"
                target="_blank"
                className="text-primary"
              >
                Term
              </a>{" "}
              apply.{" "}
            </span>
          </div>
        </form>
      </div>
    );
  }
}

export default InterestForm;
