import React, { Component } from "react";
import Fun from "../../lib/functions";

const UniUpload = {
  auth: "",
  views: {
    detail: {
      auth: "",
      apis: {
        view: {
          data: { data: [{}] },
          parameters: {},
          formatter: function (data) {
            return UniUploadFormatter(data);
          },
        },
        upload: {
          url: "/file",
          parameters: {
            headers: {},
            replace: {},
          },
          formatter: function (data) {
            return UniUploadFormatter(data);
          },
        },
      },
      template: "form",
      templateConfig: {
        events: {
          onSubmit: function (e) {
            let submittedData = new FormData(e.target);

            Fun.update(e, {
              url: this.props.builder.state.module.apis.upload,
              method: "POST",
              submittedData: submittedData,
              callback: function (args) {
                window["modal"].setState({ show: false, data: args[0] });

                if (window["modal"].state.callback) {
                  window["modal"].state.callback(args[0]);
                } else {
                  if (window["modal"].state.targetComponent) {
                    window["modal"].state.targetComponent.setState({
                      data: args[0],
                    });
                  }
                }
              },
            });
          },
        },
        colsTemplateCount: 12,
        includeProperties: ["file", "title", "description", "folder"],
      },
      pk: "id",
      properties: UniUploadProperties({ editable: true }),
    },
  },
};

export default UniUpload;

function UniUploadProperties(input) {
  let editable = input.editable;

  return {
    id: {
      alias: "id",
      control: "hidden",
    },
    file: {
      alias: "file",
      control: "upload",
      label: "",
      editable: editable,
      apiPath: "",
      auth: "",
      validations: "",
      attributes: {
        className: "form-control-file",
      },
    },
    title: {
      alias: "title",
      control: "text",
      label: "Title",
      editable: editable,
      apiPath: "",
      auth: "",
      validations: "",
      attributes: {
        className: "form-control",
      },
    },
    description: {
      alias: "description",
      control: "textarea",
      label: "Description",
      editable: editable,
      apiPath: "",
      auth: "",
      validations: "",
      attributes: {
        className: "form-control",
      },
    },
    folder: {
      alias: "folder",
      control: "hidden",
      label: "",
      editable: editable,
      apiPath: "",
      auth: "",
      validations: "",
      attributes: {
        className: "form-control",
      },
    },
    submit: {
      alias: "submit",
      label: "",
      control: "submit",
      caption: "submit",
      apiPath: "",
      auth: "",
      validations: "",
      attributes: {
        className: "btn btn-primary float-right",
      },
    },
  };
}

function UniUploadFormatter(data) {
  if (data) {
    let formattedData = [];

    if (data.data) {
      data = data.data;
    } else {
      if (!Array.isArray(data)) {
        data = [data];
      }
    }

    data.map((itm, idx) => {
      let formattedRecord = {};

      formattedRecord["id"] = itm.id;
      formattedRecord["title"] = itm.title;
      formattedRecord["description"] = itm.description;
      formattedRecord["folder"] = Fun.getParameterByName("_id")
        ? Fun.getParameterByName("_id")
        : "60b65c86566f5c2efac9e9a4"; //itm.folder;

      formattedData.push(formattedRecord);
    });

    return formattedData;
  }
}
