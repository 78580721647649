import React, { Component } from "react";
import Builder from "../../lib/builder";
import Fun from "../../lib/functions";
import TopTemplateHeader from "../../themes/default/templates/topTemplateHeader";
import UniFun from "./functions";
import { Link } from "react-router-dom";

const spaceHeader = function () {
  let data = this.state.data[0];

  return (
    <div className={"uniTopHeader "}>
      <TopTemplateHeader
        customClass="mb-4 uniFixedHeader"
        title={
          <React.Fragment>
            <img
              src="/uni/svg/trainingmain.svg"
              className="pr-3"
              style={{ width: "39px", paddingBottom: "5px" }}
            ></img>{" "}
            {UniFun.substring(data.title.value, 0, 25)}
            <div style={{ display: "inline-block" }}>
              {" "}
              {data.editdropdown.component}
            </div>
          </React.Fragment>
        }
        subtitle={<span className="text-dark pl-5">Structure</span>}
      ></TopTemplateHeader>
    </div>
  );
};

const formatTitle = function (data, itm, rec, parentBuilder) {
  let uid = Fun.uuid();
  let builder = parentBuilder;
  // console.log(parentBuilder.props.parentData._id);
  let spaceId = parentBuilder.props.parentData._id;
  return (
    <div style={{ width: "400px" }}>
      <Link to={"/spaces/" + spaceId + "/groups/" + itm._id + "/participants"}>
        {data}
      </Link>

      <span className=" mr-5  pr-5">
        <i
          className="fas fa-ellipsis-h pointer ml-2"
          data-toggle="collapse"
          data-target={"#" + uid}
        ></i>
        <div
          className=" position-absolute bg-white  mt-1 collapse rounded pt-1 pb-1"
          style={{ zIndex: 80 }}
          id={uid}
        >
          <ul className="list-group p-0" style={{ width: "200px" }}>
            <li className="list-group-item p-2 pl-3 pointer">
              <Link
                to={
                  "/spaces/" + spaceId + "/groups/" + itm._id + "/participants"
                }
                style={{ display: "flex" }}
              >
                Open
              </Link>
            </li>
            <li
              className="list-group-item p-2 pl-3 pointer"
              onClick={(e) => editGroup(builder, itm)}
            >
              Edit group details
            </li>
            <li
              className="list-group-item p-2 pl-3 pointer text-danger"
              onClick={(e) => deleteGroup(builder, itm)}
            >
              Delete
            </li>
          </ul>
        </div>
      </span>
    </div>
  );
};

function createSpaceDetailLink(id, type) {
  let output = "";
  let spaceId = Fun.getURLPathValue(2);
  switch (type) {
    case "participants":
      output = "/spaces/" + spaceId + "/groups/" + id + "/participants";
      break;

    case "files":
      output = "/spaces/" + spaceId + "/groups/" + id + "/files";
      break;
  }
  return output;
}

const createSpace = function (parentBuilder, node, title = "New Space") {
  let builder = parentBuilder;

  let parentId = node?.parentId;
  let rootSpace = node?.rootSpace;
  if (!parentId) {
    parentId = rootSpace;
  }
  window["modal"].setState({
    title: <h3>{title}</h3>,
    size: "lg",
    targetComponent: this,

    callback: function (args) {
      window["modal"].setState({ show: false, overflowY: "auto" });

      builder.rerunComponent();
    },
    footer: UniFun.modalCommonFooter("modal", "Create"),
    show: true,
    body: (
      <Builder
        module="spaces"
        view="add"
        inputData={{
          data: [
            {
              title: "",
              parentId: parentId,
              description: "",
              rootSpace: rootSpace,
            },
          ],
        }}
        key={Fun.uuid()}
        ref={(refComponent) => {
          window["modalbody"] = refComponent;
        }}
      ></Builder>
    ),
  });
};

const showGroudDeatils = function (itm) {
  let body = (
    <div className="container-fluid p-3 pl-5">
      <div className="form-group ">
        <label className="text-dark">Title</label>
        <div>{itm.title.value}</div>
      </div>
      <div className="form-group ">
        <label className="text-dark">Description</label>
        <div>{itm.description.value}</div>
      </div>
    </div>
  );
  window["modal"].setState({
    title: <h3 className="pl-4">Group details</h3>,
    size: "lg",
    footer: [],
    show: true,
    body: body,
  });
};

const editGroup = function (parentBuilder, itm) {
  let builder = parentBuilder;
  let parameters = {
    replace: {
      id: itm._id,
    },
  };

  window["modal"].setState({
    title: <h3>Group details</h3>,
    size: "lg",
    targetComponent: this,
    callback: function () {
      window["modal"].setState({ show: false, overflowY: "auto" });
      Fun.createNotification({
        type: "success",
        message: "Update update was successful!",
      });
      builder.rerunComponent();
    },
    footer: UniFun.modalCommonFooter("modal", "Update"),
    show: true,
    body: (
      <Builder
        module="spaces"
        view="editGroup"
        parameters={parameters}
        // inputData={{
        //   data: [
        //     {
        //       name: itm.name,
        //       description: itm.description,
        //       _id: itm._id,
        //     },
        //   ],
        // }}
        key={Fun.uuid()}
        ref={(refComponent) => {
          window["modalbody"] = refComponent;
        }}
      ></Builder>
    ),
  });
};

const editSpace = function (parentBuilder, itm) {
  let builder = parentBuilder;
  window["modal"].setState({
    title: <h3>Space details</h3>,
    size: "lg",
    targetComponent: this,
    callback: function () {
      window["modal"].setState({ show: false, overflowY: "auto" });
      Fun.createNotification({
        type: "success",
        message: "Update update was successful!",
      });
      builder.rerunComponent();
    },
    footer: UniFun.modalCommonFooter("modal", "Update"),
    show: true,
    body: (
      <Builder
        module="spaces"
        view="editSpace"
        inputData={{
          data: [
            {
              name: itm.name,
              description: itm.description,
              _id: itm._id,
            },
          ],
        }}
        key={Fun.uuid()}
        ref={(refComponent) => {
          window["modalbody"] = refComponent;
        }}
      ></Builder>
    ),
  });
};

const deleteSpace = function (parentBuilder, itm) {
  let builder = parentBuilder;
  window["modal2"].setState({
    title: <h3>Delete Space?</h3>,
    size: "lg",
    targetComponent: this,
    callback: function () {
      window["modal2"].setState({ show: false, overflowY: "auto" });

      Fun.createNotification({
        type: "success",
        message: "Space has been deleted!",
      });
      window.location.replace("/spaces");
    },
    footer: UniFun.modalCommonFooter("modal2", "Delete", "btn-danger"),
    show: true,
    body: (
      <Builder
        module="spaces"
        view="deleteSpace"
        inputData={{
          data: [
            {
              name: itm.name,
              description: itm.description,
              _id: itm._id,
            },
          ],
        }}
        key={Fun.uuid()}
        ref={(refComponent) => {
          window["modal2body"] = refComponent;
        }}
      ></Builder>
    ),
  });
};

const deleteGroup = function (parentBuilder, itm) {
  let builder = parentBuilder;
  window["modal"].setState({
    title: <h3>Delete Group?</h3>,
    size: "lg",
    targetComponent: this,
    callback: function () {
      window["modal"].setState({ show: false, overflowY: "auto" });
      Fun.createNotification({
        type: "success",
        message: "Update update was successful!",
      });

      builder.rerunComponent();
    },
    footer: UniFun.modalCommonFooter("modal", "Delete", "btn-danger"),
    show: true,
    body: (
      <Builder
        module="spaces"
        view="deleteGroup"
        inputData={{
          data: [
            {
              name: itm.name,
              description: itm.description,
              _id: itm._id,
            },
          ],
        }}
        key={Fun.uuid()}
        ref={(refComponent) => {
          window["modalbody"] = refComponent;
        }}
      ></Builder>
    ),
  });
};

const Spaces = {
  auth: "",
  views: {
    list: {
      auth: "",
      apis: {
        view: {
          url: "/spaces/root/{id}?treeview={treeviewtag}",
          parameters: {
            replace: {},
            headers: {},
          },
          formatter: function (data, builder) {
            return SpacesListFormatter(data, builder);
          },
        },
      },
      template: "grid",
      templateConfig: {
        gridConfig: {
          cardBodyClass: "p-0",
          showTableHeaders: false,
          headers: {
            title: {
              attributes: {
                className: "w-100",
              },
            },
          },
          cellTemplates: {
            _id: function (data, rec, obj) {
              let uid = Fun.uuid();
              return (
                <React.Fragment>
                  <Link
                    to={
                      "/spaces/" +
                      rec._id.data.id +
                      "/groups/" +
                      rec._id.data.id +
                      "/participants"
                    }
                  >
                    <span>
                      <img
                        src="/uni/svg/signs/closeddropdown.svg"
                        className="mr-2 ml-4"
                        style={{ width: "14px" }}
                      ></img>
                      {rec.title.value}
                    </span>
                  </Link>
                  <span className="float-right mr-5 grid-controls pr-5">
                    <React.Fragment>
                      <i
                        className="fas fa-ellipsis-h pointer ml-2"
                        data-toggle="collapse"
                        data-target={"#" + uid}
                      ></i>
                      <div
                        className="position-absolute bg-white mt-2 collapse"
                        id={uid}
                        style={{ right: "100px" }}
                      >
                        <ul className="list-group " style={{ width: "200px" }}>
                          <li className="list-group-item p-2 pl-3 pointer">
                            <Link
                              to={
                                "/spaces/" +
                                rec._id.data.id +
                                "/groups/" +
                                rec._id.data.id +
                                "/participants"
                              }
                              style={{ display: "flex" }}
                            >
                              Open
                            </Link>
                          </li>
                          <li
                            className="list-group-item p-2 pl-3 pointer"
                            onClick={(e) => showGroudDeatils(rec)}
                          >
                            View group details
                          </li>
                        </ul>
                      </div>
                    </React.Fragment>
                  </span>
                </React.Fragment>
              );
            },
          },
        },
        colsTemplateCount: 12,
        colsFormCount: 12,
        headerTitle: "Home",
        headerTitleIcon: "",
        templateTop: function () {
          return (
            <p>
              <strong>Groups ({this.state.data.length})</strong>
            </p>
          );
        },
        header: function () {
          let data = this.state.data[0];

          return (
            <div className={"uniTopHeader "}>
              <TopTemplateHeader
                customClass="mb-4 uniFixedHeader"
                title={<React.Fragment>Home</React.Fragment>}
              ></TopTemplateHeader>
            </div>
          );
        },
        events: {
          onSubmit: function (e) {},
        },
        includeProperties: ["_id"],
      },
      pk: "_id",
      properties: SpacesProperties({ editable: false }),
    },
    tree: {
      auth: "",
      apis: {
        view: {
          url: "/spaces/root/{id}?treeview=true",
          parameters: {
            headers: {},
          },
          formatter: function (data, builder) {
            return SpacesTreeFormatter(data, builder);
          },
        },
      },
      template: "treeview",
      templateConfig: {
        colsTemplateCount: 12,
        colsFormCount: 12,
        headerTitle: "Home",
        headerTitleIcon: "",
        // header: function () {
        //   let data = this.state.data[0];

        //   return (
        //     <div className={"uniTopHeader "}>
        //       <TopTemplateHeader
        //         customClass="mb-4 uniFixedHeader"
        //         title={<React.Fragment>Home</React.Fragment>}
        //       ></TopTemplateHeader>
        //     </div>
        //   );
        // },
        treeviewConfig: {
          keyAttribute: "_id",
          titleAttribute: "title",
          treeDataAttribute: "treeData",

          onSelect: function (node, treeNode) {
            if (treeNode.node.createNode == true) {
              createSpace(this.props.builder, treeNode.node, "Add group");
            }
          },
          onSelect1: function (node, props) {
            if (node.createNode == true) {
              createSpace(props.builder, node, "Add group");
            }
          },
          onLoadData: function (treeNode) {
            let _this = this;

            return new Promise((resolve) => {
              Fun.fetch({
                url: {
                  url: "/spaces/root/{spaceId}?treeview=true",
                  parameters: {
                    replace: {
                      spaceId: treeNode["key"],
                    },
                  },
                },
                object: this,
                callback: function (args, object) {
                  if (args) {
                    if (args.data) {
                      let children = [
                        {
                          key: Fun.uuid(),
                          parent: treeNode.key,
                          parentId: treeNode.key,
                          rootSpace: treeNode.rootSpace,
                          title: (
                            <span className="add-group-text">Add group</span>
                          ),
                          isLeaf: true,
                          createNode: true,
                        },
                      ];

                      args.data.map((itm, idx) => {
                        children.push({
                          key: itm["_id"],
                          parentId: treeNode.key,
                          parent: treeNode.key,
                          rootSpace: treeNode.rootSpace,
                          test: "test",
                          description: itm.description,
                          [_this.state.treeviewConfig.titleAttribute]:
                            _this.formatTitle(itm["name"], itm),
                        });
                      });

                      _this.findTreeNode(
                        _this.state.data,
                        treeNode.key,
                        children
                      );

                      let data = _this.state.data;

                      _this.setState({ data: [...data] });
                    }
                  }
                },
              });
              resolve();
            });
          },
        },
        events: {
          onSubmit: function (e) {},
        },
        includeProperties: ["title", "description"],
      },
      pk: "_id",
      properties: SpacesProperties({ editable: false }),
    },
    detail: {
      auth: "",
      apis: {
        view: {
          url: "/spaces/root/{id}?treeview=true",
          parameters: {
            headers: {},
          },
          formatter: function (data) {
            return SpacesFormatter(data);
          },
        },
      },
      template: "squaresoverview",
      templateConfig: {
        colsTemplateCount: 12,
        colsFormCount: 10,
        headerTitle: "Home",
        headerTitleIcon: "/uni/svg/Filesmain.svg",
        squaresConfig: {
          titleAttribute: "title",
          colorAttribute: "color",
          iconAttribute: "icon",
          dataSections: [
            { section: "spaces", label: "Spaces", collapsed: false },
            { section: "tags", label: "Tags", collapsed: true },
          ],
        },
        treeviewConfig: {
          keyAttribute: "_id",
          titleAttribute: "title",
        },
        events: {
          onSubmit: function (e) {},
        },
        includeProperties: ["title", "description"],

        header: UniFun.commonHeader,
      },
      pk: "_id",
      properties: SpacesProperties({ editable: false }),
    },
    squares: {
      auth: "",
      apis: {
        view: {
          url: "/root-spaces",
          parameters: {
            headers: {},
          },
          formatter: function (data) {
            return SpacesFormatter(data);
          },
        },
      },
      template: "squaresoverview",
      templateConfig: {
        colsTemplateCount: 12,
        colsFormCount: 9,
        headerTitle: "Home",
        headerTitleIcon: "/uni/svg/Filesmain.svg",
        squaresConfig: {
          titleAttribute: "title",
          colorAttribute: "color",
          iconAttribute: "icon",
        },
        events: {
          onSubmit: function (e) {},
        },
        colsTemplateCount: 12,
        includeProperties: ["title", "description"],
        header: function () {
          let data = this.state.data;

          return (
            <React.Fragment>
              <div className="row headerWhite pl-5 ">
                <div className="col-12 pt-5">
                  <h1>Home</h1>
                </div>
              </div>
              <p></p>
            </React.Fragment>
          );
        },
      },
      pk: "_id",
      properties: SpacesProperties({ editable: false }),
    },
    detailTree: {
      auth: "",
      apis: {
        view: {
          url: "/space/{id}",
          parameters: {
            headers: {},
          },
          formatter: function (data) {
            return SpacesDetailFormatter(data);
          },
        },
      },
      template: "form",
      templateConfig: {
        useCard: false,
        events: {
          onSubmit: function (e) {},
        },
        colsTemplateCount: 12,
        includeProperties: ["spaceTree"],
        header: spaceHeader,
      },
      pk: "_id",
      properties: SpacesProperties({ editable: false }),
    },
    detailFiles: {
      auth: "",
      apis: {
        view: {
          url: "/space/{id}",
          parameters: {
            headers: {},
          },
          formatter: function (data) {
            return SpacesDetailFormatter(data);
          },
        },
      },
      template: "form",
      templateConfig: {
        useCard: false,
        events: {
          onSubmit: function (e) {},
        },
        colsTemplateCount: 12,
        includeProperties: ["files"],
        header: function () {
          let data = this.state.data[0];

          let link = createSpaceDetailLink(data["_id"].value, "participants");

          return (
            <React.Fragment>
              <div className="row headerWhite pl-5 ">
                <div className="col-12 pt-5">
                  <h1>{data.title.component}</h1>
                  {UniFun.userIsEligible({ forWhat: "module" }) ||
                  UniFun.userIsEligible({ forWhat: "viewer" }) ? (
                    <>
                      <Link to={link} className="mr-4 text-dark">
                        Participants
                      </Link>
                      <span className="text-primary">Files</span>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <p></p>
            </React.Fragment>
          );
        },
      },
      pk: "_id",
      properties: SpacesProperties({ editable: false }),
    },
    detailParticipants: {
      auth: "",
      apis: {
        view: {
          url: "/space/{id}",
          parameters: {
            headers: {},
          },
          formatter: function (data) {
            return SpacesDetailFormatter(data);
          },
        },
      },
      template: "form",
      templateConfig: {
        useCard: false,
        events: {
          onSubmit: function (e) {},
          submitParticipantsFromModal(data) {},
        },
        colsTemplateCount: 12,
        includeProperties: ["participants"],
        header: function () {
          let data = this.state.data[0];

          let link = createSpaceDetailLink(data["_id"].value, "files");

          return (
            <React.Fragment>
              <div className="row headerWhite pl-5 ">
                <div className="col-12 pt-5">
                  <h1>{data.title.component}</h1>
                  {UniFun.userIsEligible({ forWhat: "module" }) ||
                  UniFun.userIsEligible({ forWhat: "viewer" }) ? (
                    <>
                      <span className="mr-4 text-primary">Participants</span>
                      <Link to={link} className="text-dark">
                        Files
                      </Link>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <p></p>
            </React.Fragment>
          );
        },
      },
      pk: "_id",
      properties: SpacesProperties({ editable: false }),
    },
    add: {
      auth: "",
      apis: {
        view: {
          data: { data: [{}] },
          parameters: {
            headers: {},
          },
          formatter: function (args) {
            return SpacesAddFormatter(args);
          },
        },
        save: {
          url: "/space",
          parameters: {
            headers: {},
          },
          formatter: function (data) {
            return SpacesFormatter(data);
          },
        },
      },
      template: "form",
      templateConfig: {
        events: {
          onSubmit: function (e) {
            let submittedData = Fun.getSubmitJSON(e);

            let josnData = {
              name: submittedData["title"],
              description: submittedData["description"],
              // parent: submittedData["parentId"],
              // rootSpace: submittedData["rootSpace"],
            };

            if (submittedData["parentId"]) {
              josnData["parent"] = submittedData["parentId"];
            }
            if (submittedData["rootSpace"]) {
              josnData["rootSpace"] = submittedData["rootSpace"];
            }

            Fun.update(e, {
              url: this.props.builder.state.module.apis.save,
              method: "POST",
              parameters: {
                headers: {
                  "content-type": "application/json",
                },
              },
              callback: function (args, status, responsedata, responsestatus) {
                if (responsestatus == "200") {
                  if (window["modal"].state.callback) {
                    window["modal"].state.callback(args);
                  }
                  Fun.createNotification({
                    message: "Space created successfully",
                    type: "success",
                  });
                  window["leftpanelref"].rerunComponent();
                } else {
                  if (responsedata.data.exceptions) {
                    responsedata.data.exceptions.map((itm, idx) => {
                      Fun.createNotification({
                        message: itm.errorDescription,
                        type: "danger",
                      });
                    });
                  }
                }
              },
              submittedData: JSON.stringify(josnData),
            });
          },
        },
        colsTemplateCount: 12,
        includeProperties: ["title", "description", "parentId", "rootSpace"],
      },
      pk: "_id",
      properties: SpacesProperties({ editable: true }),
    },
    deleteSpace: {
      auth: "",
      apis: {
        view: {
          data: { data: [{}] },
          parameters: {
            headers: {},
          },
          formatter: function (args) {
            return SpacesAddFormatter(args);
          },
        },
        delete: {
          url: "/space/{_id}",
          parameters: {
            headers: {},
            replace: {},
          },
          formatter: function (data) {
            return SpacesAddFormatter(data);
          },
        },
      },
      template: "form",
      templateConfig: {
        useCard: false,
        colsTemplateCount: 12,
        includeProperties: ["_id"],
        excludeProperties: [],
        events: {
          onSubmit: function (e) {
            let submittedData = Fun.getSubmitJSON(e);

            let builder = this.props.builder;

            Fun.update(e, {
              url: builder.state.module.apis.delete,
              method: "DELETE",
              parameters: {
                headers: {
                  "content-type": "application/json",
                },
                replace: {
                  _id: submittedData["_id"],
                },
              },
              callback: function (args) {
                if (window["modal2"].state.callback) {
                  window["modal2"].state.callback(submittedData);
                }
              },
              submittedData: JSON.stringify([]),
            });
          },
        },
        templateTop: function () {
          let data = this.state.data[0];
          return (
            <div className=" p-4">
              <p>
                This action: <br></br>- will delete the Space "
                {data.title.component}"<br></br>- will delete all groups under
                the space "{data.title.component}" along with their users and
                files
              </p>
            </div>
          );
        },
      },
      pk: "_id",
      properties: SpacesProperties({ editable: false }),
    },
    editSpace: {
      auth: "",
      apis: {
        view: {
          data: { data: [{}] },
          parameters: {
            headers: {},
          },
          formatter: function (args) {
            return SpacesAddFormatter(args);
          },
        },
        save: {
          url: "/space",
          parameters: {
            headers: {},
          },
          formatter: function (data) {
            return SpacesAddFormatter(data);
          },
        },
      },
      template: "form",
      templateConfig: {
        events: {
          onSubmit: function (e) {
            let submittedData = Fun.getSubmitJSON(e);

            let jsonData = {
              name: submittedData["title"],
              description: submittedData["description"],
              _id: submittedData["_id"],
            };

            Fun.update(e, {
              url: this.props.builder.state.module.apis.save,
              method: "PATCH",
              parameters: {
                headers: {
                  "content-type": "application/json",
                },
              },
              callback: function (args, object, response, status) {
                window["modal"].setState({
                  show: false,
                  data: args[0],
                  overflowY: "auto",
                });

                if (status == "200") {
                  window["leftpanelref"].rerunComponent();
                  if (window["modal"].state.callback) {
                    window["modal"].state.callback(args[0]);
                  } else {
                    if (window["modal"].state.targetComponent) {
                      window["modal"].state.targetComponent.setState({
                        data: args[0],
                      });
                    }
                  }
                }
              },
              submittedData: JSON.stringify(jsonData),
            });
          },
        },
        colsTemplateCount: 12,
        includeProperties: ["title", "description", "_id"],
      },
      pk: "_id",
      properties: SpacesProperties({ editable: true }),
    },
    editGroup: {
      auth: "",
      apis: {
        view: {
          //data: { data: [{}] },
          url: "/space/{id}",
          parameters: {
            headers: {},
          },
          formatter: function (args) {
            return SpacesAddFormatter(args);
          },
        },
        save: {
          url: "/space",
          parameters: {
            headers: {},
          },
          formatter: function (data) {
            return SpacesAddFormatter(data);
          },
        },
      },
      template: "form",
      templateConfig: {
        events: {
          onSubmit: function (e) {
            let submittedData = Fun.getSubmitJSON(e);

            let josnData = {
              name: submittedData["title"],
              description: submittedData["description"],
              _id: submittedData["_id"],
            };

            Fun.update(e, {
              url: this.props.builder.state.module.apis.save,
              method: "PATCH",
              parameters: {
                headers: {
                  "content-type": "application/json",
                },
              },
              callback: function (args) {
                window["modal"].setState({
                  show: false,
                  data: args[0],
                  overflowY: "auto",
                });

                if (window["modal"].state.callback) {
                  window["modal"].state.callback(args[0]);
                } else {
                  if (window["modal"].state.targetComponent) {
                    window["modal"].state.targetComponent.setState({
                      data: args[0],
                    });
                  }
                }
              },
              submittedData: JSON.stringify(josnData),
            });
          },
        },
        colsTemplateCount: 12,
        includeProperties: ["title", "description", "_id"],
      },
      pk: "_id",
      properties: SpacesProperties({ editable: true }),
    },
    deleteGroup: {
      auth: "",
      apis: {
        view: {
          data: { data: [{}] },
          parameters: {
            headers: {},
          },
          formatter: function (args) {
            return SpacesAddFormatter(args);
          },
        },
        delete: {
          url: "/space/{id}",
          parameters: {
            headers: {},
          },
          formatter: function (data) {
            return SpacesAddFormatter(data);
          },
        },
      },
      template: "form",
      templateConfig: {
        cardTemplateTop: function () {
          let data = this.state.data[0];

          return (
            <div className="p-3">
              <p>
                This action: <br></br>- will delete the group "
                {data.title.component}" along with its users and files <br></br>
                - will delete all groups under the group "{data.title.component}
                " along with their users and files <br></br>
              </p>
            </div>
          );
        },
        events: {
          onSubmit: function (e) {
            let submittedData = Fun.getSubmitJSON(e);

            Fun.update(e, {
              url: this.props.builder.state.module.apis.delete,
              method: "DELETE",
              parameters: {
                headers: {
                  "content-type": "application/json",
                },
                replace: {
                  id: submittedData["_id"],
                },
              },
              callback: function (args) {
                try {
                  if (window["modal"].state.callback) {
                    window["modal"].state.callback(args[0]);
                  } else {
                    if (window["modal"].state.targetComponent) {
                      window["modal"].state.targetComponent.setState({
                        data: args[0],
                      });
                    }
                  }
                } catch (e) {
                  console.log(e);
                }
              },
              submittedData: [],
            });
          },
        },
        colsTemplateCount: 12,
        includeProperties: ["_id"],
      },
      pk: "_id",
      properties: SpacesProperties({ editable: false }),
    },
  },
};

export default Spaces;

function SpacesProperties(input) {
  let editable = input.editable;

  return {
    _id: {
      alias: "_id",
      control: "hidden",
      editable: editable,
    },
    title: {
      alias: "title",
      control: "text",
      label: "Title",
      editable: editable,
      attributes: {
        className: "form-control",
      },
    },
    files: {
      alias: "files",
      control: "module",
      config: {
        module: "unifiles",
        view: "spaceFiles",
        parameters: {
          replace: {
            id: function () {
              return Fun.getParameterByName("_id")
                ? Fun.getParameterByName("_id")
                : "parentdata[spaceFileDirectory]";
            },
          },
        },
      },
    },
    participantsCount: {
      alias: "participantsCount",
      control: "text",
      label: "",
      editable: editable,
      attributes: {
        className: "form-control",
      },
    },
    participants: {
      alias: "participants",
      control: "module",
      placeholder: "Select the participants",
      config: {
        module: "uniparticipantsandfilesevent",
        view: "participantsSpaces",
        moduleParams: { type: "spaces" },
        parameters: {
          replace: {
            path: function () {
              return "/space/" + Fun.getURLPathValue(4) + "/participants";

              return Fun.getURLPathValue(2)
                ? Fun.getParameterByName("_id")
                : "parentdata[spaceFileDirectory]";
            },
          },
        },
      },
    },
    parentId: {
      alias: "parentId",
      control: "hidden",
      label: "",
      editable: editable,
    },
    spaceFileDirectory: {
      alias: "spaceFileDirectory",
      control: "hidden",
      label: "",
      editable: editable,
    },
    rootSpace: {
      alias: "rootSpace",
      control: "hidden",
      label: "",
      editable: editable,
    },
    description: {
      alias: "description",
      control: "textarea",
      label: "Description",
      nonEditableDefaultValue: "No description available",
      editable: editable,
      attributes: {
        className: "form-control",
      },
    },
    url: {
      alias: "url",
      control: "text",
      editable: editable,
    },
    children: {
      alias: "children",
      control: "text",
      editable: false,
    },
    treeData: {
      alias: "treeData",
      control: "text",
      editable: false,
    },
    color: {
      alias: "color",
      control: "text",
      editable: editable,
    },
    icon: {
      alias: "icon",
      control: "text",
      editable: editable,
    },
    spaceTree: {
      alias: "spaceTree",
      control: "module",
      config: {
        module: "spaces",
        view: "tree",
        parameters: {
          replace: {
            id: "parentdata[_id]",
          },
        },
      },
    },
    editdropdown: {
      alias: "editdropdown",
      control: "dropdownbutton",
      caption: (
        <img src="/uni/svg/more-options.svg" style={{ width: "14px" }}></img>
      ),
      editable: true,
      config: {
        removeToggleIcon: true,
        options: {
          edit: {
            label: "Edit",
            events: {
              onClick: function () {
                let builder = this.props.builder;
                let itm = this.props.data;
                itm["name"] = itm.title;

                editSpace(builder, itm);
              },
            },
          },
          delete: {
            label: <span className="text-danger">Delete</span>,
            events: {
              onClick: function () {
                let builder = this.props.builder;
                let itm = this.props.data;
                itm["name"] = itm.title;

                deleteSpace(builder, itm);
              },
            },
          },
        },
      },
      attributes: {
        className: "btn btn-link m-2 ",
      },
    },
  };
}

function SpacesAddFormatter(data) {
  if (data) {
    let formattedData = [];

    data.data.map((itm, idx) => {
      let formattedRecord = {};

      formattedRecord["_id"] = itm._id;
      formattedRecord["title"] = itm.name;

      formattedRecord["parentId"] = itm.parentId;
      formattedRecord["rootSpace"] = itm.rootSpace;
      formattedRecord["description"] = itm.description;
      formattedRecord["children"] = [];
      formattedRecord["color"] = idx % 2 ? "#009F9F" : "#556FCE";
      formattedRecord["url"] = "/spaces/" + itm._id;

      formattedData.push(formattedRecord);
    });

    return formattedData;
  }
}

function SpacesListFormatter(data, builder) {
  let rootSpace = "";
  let formattedData = [];

  if (builder) {
    if (builder.state) {
      if (builder.props.moduleParams) {
        rootSpace = builder.props.moduleParams["rootSpace"];
      }
    }
  }

  function getChildren(children, output) {
    children.map((itm, idx) => {
      output.push({
        key: itm._id,
        id: itm._id,
        title: itm.name,
        description: itm.description,
        rootSpace: rootSpace,
      });
      getChildren(itm.children, output);
    });

    return output;
  }

  if (data) {
    if (!Array.isArray(data.data)) {
      data.data = data.data.spaces;
    }

    data.data.map((itm, idx) => {
      let formattedRecord = {};

      formattedRecord = {
        key: itm._id,
        id: itm._id,
        title: itm.name,
        description: itm.description,
        rootSpace: rootSpace,
      };
      if (itm.children) {
        if (itm.children.length > 0) {
          formattedData = [...formattedData, ...getChildren(itm.children, [])];
        }
      }

      formattedData.push(formattedRecord);
    });
  }

  formattedData.sort((a, b) => a.title.localeCompare(b.title));

  return formattedData;
}

function SpacesTreeFormatter(data, builder) {
  let rootSpace = Fun.getURLPathValue(2);
  let parentBuilder = builder;
  let formattedData = [];
  if (data) {
    let dataChild = [
      {
        _id: 0,
        key: Fun.uuid(),
        name: (
          <strong>
            <img
              src="/uni/svg/spaces/squares.svg"
              style={{ width: "14px", marginRight: "9px" }}
            ></img>
            Create Structures
          </strong>
        ),
        children: [...data.data],
      },
    ];

    // data.data.map((itm, idx) => {
    //   formattedData[0].children.push({
    //     key: itm._id,
    //     title: itm.name,
    //     rootSpace: rootSpace,
    //   });
    // });

    function getChildren(dataChild) {
      try {
        dataChild?.map((itm, idx) => {
          itm.key = itm._id;
          itm.description = itm.description;
          itm.title =
            itm.createNode || itm._id == 0
              ? itm.name
              : formatTitle(itm.name, itm, "", parentBuilder);
          itm.rootSpace = rootSpace;
          if (Array.isArray(itm.children)) {
            itm.children.unshift({
              key: Fun.uuid(),
              title: <span className="add-group-text">Add group</span>,
              name: <span className="add-group-text">Add group</span>,
              createNode: true,
              rootSpace: rootSpace,
              parentId: itm._id,
            });
          }
          getChildren(itm.children);
        });
      } catch (e) {
        console.log(e);
      }
    }

    getChildren(dataChild);

    formattedData.push({ treeData: dataChild });

    return formattedData;
  }
}

function SpacesDetailFormatter(data) {
  if (data) {
    let formattedData = [];

    data.data.map((itm, idx) => {
      let formattedRecord = {};

      formattedRecord["_id"] = itm._id;
      formattedRecord["spaceTree"] = itm._id;
      formattedRecord["editdropdown"] = itm._id;

      formattedRecord["title"] = itm.name;
      formattedRecord["parent"] = itm.parent;
      formattedRecord["rootSpace"] = itm.rootSpace;
      formattedRecord["files"] = itm._id;
      formattedRecord["spaceFileDirectory"] = itm.spaceFileDirectory;
      formattedRecord["description"] = itm.description;

      formattedData.push(formattedRecord);
    });

    return formattedData;
  }
}

function SpacesFormatter(data) {
  let formattedData = [];
  if (data) {
    if (localStorage.getItem("role") == "admin") {
      formattedData.push({
        _id: 0,
        title: "",
        color: "fff",
        icon: "/uni/svg/addspace.png",
        onClick: createSpace,
      });
    }

    let i = 0;
    if (data.data) {
      data.data.map((itm, idx) => {
        let formattedRecord = {};

        if (data.colours) {
          if (!data.colours[i]) {
            i = 0;
          }
        }

        formattedRecord["_id"] = itm._id;
        formattedRecord["spaceTree"] = itm._id;

        formattedRecord["title"] = itm.name;
        formattedRecord["parentId"] = itm.parentId;
        formattedRecord["files"] = itm._id;
        formattedRecord["spaceFileDirectory"] = itm.spaceFileDirectory;
        formattedRecord["description"] = itm.description;
        formattedRecord["children"] = [];
        formattedRecord["color"] = data.colours ? data.colours[i] : "#fff"; //idx % 2 ? "#009F9F" : "#556FCE";
        formattedRecord["url"] = "/spaces/" + itm._id + "/groups";
        formattedRecord["participantsCount"] = itm.participants;
        formattedRecord["icon"] = "";

        i++;

        formattedData.push(formattedRecord);
      });
    }

    return formattedData;
  }
}
