import React, { Component } from "react";
import ReactTooltip from "react-tooltip";
import Fun from "../../../lib/functions";
import ValidationControl from "./validationControl";

class Control extends Component {
  state = {
    data: [],
    control: null,
    loaded: false,
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.setState({
      control: this.props.control,
      loaded: true,
    });
  }

  render() {
    if (this.state.loaded) {
      if (
        this.state.control.field.customLayout ||
        this.state.control.field.control == "hidden" ||
        this.state.control.field.show === false
      ) {
        return this.state.control.component;
      }

      let tooltip = "";

      if (this.state.control.field.tooltip) {
        tooltip = (
          <React.Fragment>
            <i
              className="fas fa-info-circle ml-2 mb-4 pointer"
              data-tip={this.state.control.field.tooltip}
              data-event="click "
              data-iscapture="true"
              style={{ top: "-8px", position: "relative" }}
            ></i>
            <ReactTooltip
              globalEventOff="click"
              place="right"
              type="dark"
              delayHide={2000}
              html={true}
            />
          </React.Fragment>
        );
      }

      let marginBottom = "30px";

      if (this.state.control.field.control == "label") {
        marginBottom = "0px";
      }

      return (
        <div className="form-group " style={{ marginBottom: marginBottom }}>
          {this.state.control.field.label &&
          this.state.control.field.label != "" ? (
            <span>
              <label className="text-dark">
                {this.state.control.field.label}
              </label>
              {tooltip}
            </span>
          ) : (
            ""
          )}
          <div>
            {this.state.control.component}{" "}
            {this.state.control.field.validations ? (
              <ValidationControl
                validations={this.state.control.field.validations}
                label={this.state.control.field.label}
                alias={this.state.control.field.alias}
                ref={(refComponent) => {
                  window["validationcontrol" + this.state.control.field.alias] =
                    refComponent;
                }}
              ></ValidationControl>
            ) : (
              ""
            )}
          </div>
          {this.state.control.field.help}
        </div>
      );
    } else {
      return "";
    }
  }
}

export default Control;
