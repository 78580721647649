import React, { Component } from "react";
import Fun from "../../../lib/functions";
import UniFun from "../functions";
import TopTemplateHeader from "../../../themes/default/templates/topTemplateHeader";
import Builder from "../../../lib/builder";
import moment from "moment";
import { Link } from "react-router-dom";

const deleteStreamEvent = function (eventId, parentBuilder) {
  let parameters = {
    replace: {
      id: eventId,
    },
  };

  let builder = parentBuilder;

  window["modal2"].setState({
    title: <h3>Delete Stream?</h3>,
    size: "lg",
    targetComponent: this,
    show: true,
    callback: function () {
      window["modal2"].setState({ show: false, overflowY: "auto" });
      window.location.replace("/agenda");
    },
    footer: UniFun.modalCommonFooter("modal2", "Delete", "btn-danger"),
    body: (
      <Builder
        module="unistreamevent"
        view="deleteEvent"
        parameters={parameters}
        ref={(refComponent) => {
          window["modal2body"] = refComponent;
        }}
      ></Builder>
    ),
  });
};

const eventStreamHeader = function () {
  let data = this.state.data[0];

  let organizerId = "";

  if (data.organizer) {
    if (data.organizer.value) {
      if (data.organizer.value[0]) {
        organizerId = data.organizer.value[0]._id;
      }
    }
  }
  return (
    <div className={"uniTopHeader "}>
      <TopTemplateHeader
        customClass="mb-4 uniFixedHeader"
        title={
          <React.Fragment>
            <img
              src="/uni/svg/trainingmain.svg"
              className="pr-3 filter-gold"
              style={{ width: "39px", paddingBottom: "5px" }}
            ></img>{" "}
            <span title={data.title.value}>
              {UniFun.substring(data.title.value, 0, 25)}
            </span>
            <div style={{ display: "inline-block" }}>
              {" "}
              {UniFun.userIsEligible({
                forWhat: "stream",
                organizerId: organizerId,
              })
                ? data.editdropdown.component
                : ""}
            </div>
          </React.Fragment>
        }
      ></TopTemplateHeader>
    </div>
  );
};

const UniStreamEvent = {
  auth: "",
  views: {
    previewevent: {
      auth: "",
      apis: {
        view: {
          url: "/stream/{id}",
          parameters: {
            replace: {},
            headers: {},
          },
          formatter: function (args) {
            return UniStreamEventFormatter(args);
          },
        },
      },
      template: "form",
      templateConfig: {
        useCard: false,
        events: {
          onSubmit: function (e) {
            e.preventDefault();
          },
        },
        header: eventStreamHeader,
        templateBottom: function () {
          let data = this.state.data[0];
          return (
            <React.Fragment>
              <div>
                <h5 className="pb-1 text-black">
                  <strong>General information</strong>
                </h5>
                <div className="row ">
                  <div className="col-12">
                    <div className="card " key={Fun.uuid()}>
                      <div className="card-body">
                        <div className=" session-sections">
                          <div className="pt-4 session-section session-row">
                            <div className="mb-3 row">
                              <div className="col-md-10">
                                <img
                                  src="/uni/svg/datetime.svg"
                                  className="mr-2 mb-1 filter-light-grey"
                                  style={{ width: "20px" }}
                                />
                                {UniFun.fromToDateString(
                                  data.from.value,
                                  data.to.value
                                )}
                              </div>
                              <div className="col-md-2 text-center">
                                {UniFun.meetingIsAvailable(
                                  data.from.value,
                                  data.to.value
                                ) ? (
                                  <a
                                    href={"/stream/" + data._id.value}
                                    target="new"
                                    className="btn btn-primary "
                                  >
                                    Join
                                  </a>
                                ) : (
                                  <button
                                    type="button"
                                    className="btn btn-primary disabled action-btn"
                                    data-event-start={moment(
                                      data.from.value
                                    ).valueOf()}
                                    data-event-url={"/stream/" + data._id.value}
                                    disabled
                                  >
                                    Join
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="pt-4 session-section  session-row">
                            <div className="mb-3 row">
                              <div className="col-md-10">
                                <img
                                  src="/uni/svg/meeting_participants.svg"
                                  className="mr-2 mb-1 filter-light-grey"
                                  style={{ width: "20px" }}
                                />
                                {data.participants.value
                                  ? data.participants.value.length
                                  : "0"}{" "}
                                participants
                              </div>
                              <div className="col-md-2 text-center">
                                <Link
                                  to={
                                    "/event/stream/" +
                                    data._id.value +
                                    "/participants"
                                  }
                                  className="btn btn-primary border"
                                >
                                  Show
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <h5 className="pb-1 text-black">
                  <strong>About</strong>
                </h5>
                <div className="row ">
                  <div className="col-12">
                    <div className="card " key={Fun.uuid()}>
                      <div className="card-body">
                        {data.description.component}
                        <h5 className="text-dark mt-4 mb-4">Organizer</h5>
                        {UniFun.createProfileImageBubble(data.organizer.value)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </React.Fragment>
          );
        },
        colsTemplateCount: 12,
        includeProperties: ["_id"],
      },
      pk: "_id",
      properties: UniStreamEventProperties({ editable: false }),
    },
    streamFiles: {
      auth: "",
      apis: {
        view: {
          url: "/stream/{id}",
          parameters: {
            replace: {},
            headers: {},
          },
          formatter: function (args) {
            return UniStreamEventFormatter(args);
          },
        },
      },
      template: "form",
      templateConfig: {
        useCard: false,
        events: {
          onSubmit: function (e) {
            e.preventDefault();
          },
        },
        header: eventStreamHeader,
        colsTemplateCount: 12,
        includeProperties: ["streamFiles"],
      },
      pk: "_id",
      properties: UniStreamEventProperties({ editable: false }),
    },
    streamParticipants: {
      auth: "",
      apis: {
        view: {
          url: "/stream/{id}",
          parameters: {
            replace: {},
            headers: {},
          },
          formatter: function (args) {
            return UniStreamEventFormatter(args);
          },
        },
      },
      template: "form",
      templateConfig: {
        useCard: false,
        events: {
          onSubmit: function (e) {
            e.preventDefault();
          },
        },
        header: eventStreamHeader,
        colsTemplateCount: 12,
        includeProperties: ["streamParticipants"],
      },
      pk: "_id",
      properties: UniStreamEventProperties({ editable: false }),
    },
    editEvent: {
      auth: "",
      apis: {
        view: {
          url: "/stream/{id}",
          parameters: {
            headers: {},
            replace: {},
          },
          formatter: function (data) {
            return UniStreamEventFormatter(data);
          },
        },
        update: {
          url: "/stream",
          parameters: {
            headers: {
              "Content-Type": "application/json",
            },
            replace: {},
          },
          formatter: function (data) {
            return UniStreamEventFormatter(data);
          },
        },
      },
      template: "form",
      templateConfig: {
        events: {
          onSubmit: function (e) {
            e.preventDefault();
            let submittedData = Fun.getSubmitJSON(e);
            let from = UniFun.fromToDateFormat(
              submittedData.fromToDateTime[0].fromDateTime[0].fromDate,
              submittedData.fromToDateTime[0].fromDateTime[0].fromTime
            );

            let to = UniFun.fromToDateFormat(
              submittedData.fromToDateTime[0].toDateTime[0].toDate,
              submittedData.fromToDateTime[0].toDateTime[0].toTime
            );

            let duration = moment
              .duration(moment(to).diff(moment(from)))
              .asMinutes();

            let jsonData = {
              _id: submittedData._id,
              title: submittedData.title,
              streamURL: submittedData["streamURL"],
              isPublic: submittedData["isPublic"],
              from: from,
              to: to,
              files: submittedData["files"]
                ? UniFun.agendaFilesJson(JSON.parse(submittedData["files"]))
                : [],
              description: submittedData.description,
              participants: UniFun.participantsJSONData(
                JSON.parse(submittedData.participants)
              ),
              reminders: [JSON.parse(submittedData.reminders)],
              duration: duration,
            };

            let builder = this.props.builder;

            Fun.update(e, {
              url: builder.state.module.apis.update,
              method: "PATCH",
              callback: function (
                submittedData,
                status,
                responsedata,
                responsestatus
              ) {
                if (responsestatus == "200") {
                  if (window["modal2"].state.callback) {
                    window["modal2"].state.callback(submittedData);
                  } else {
                    if (window["modal2"].state.targetComponent) {
                      window["modal2"].state.targetComponent.setState({
                        data: submittedData,
                      });
                    }
                  }
                  Fun.createNotification({
                    type: "success",
                    message: "Update was successful!",
                  });
                } else {
                  if (responsedata.data.exceptions) {
                    responsedata.data.exceptions.map((itm, idx) => {
                      Fun.createNotification({
                        message: itm.errorDescription,
                        type: "danger",
                      });
                    });
                  }
                }
              },
              parameters: {
                headers: {
                  "content-type": "application/json",
                },
              },
              submittedData: JSON.stringify(jsonData),
            });
          },
        },
        colsTemplateCount: 12,
        includeProperties: [
          "_id",
          "title",
          "streamURL",
          "isPublic",
          "fromToDateTime",
          "participants",
          "description",
          "reminders",
        ],
      },
      pk: "_id",
      properties: UniStreamEventProperties({ editable: true }),
    },
    deleteEvent: {
      auth: "",
      apis: {
        view: {
          url: "/stream/{id}",
          parameters: {
            headers: {},
            replace: {},
          },
          formatter: function (data) {
            return UniStreamEventFormatter(data);
          },
        },
        delete: {
          url: "/stream/{id}",
          parameters: {
            headers: {},
            replace: {},
          },
          formatter: function (data) {
            return UniStreamEventFormatter(data);
          },
        },
      },
      template: "form",
      templateConfig: {
        useCard: false,
        colsTemplateCount: 12,
        includeProperties: ["_id"],
        excludeProperties: [],
        events: {
          onSubmit: function (e) {
            let submittedData = Fun.getSubmitJSON(e);

            let builder = this.props.builder;

            Fun.update(e, {
              url: builder.state.module.apis.delete,
              method: "DELETE",
              parameters: {
                headers: {
                  "content-type": "application/json",
                },
                replace: {
                  id: submittedData["_id"],
                },
              },
              callback: function (args) {
                if (window["modal2"].state.callback) {
                  window["modal2"].state.callback(submittedData);
                }
              },
              submittedData: JSON.stringify([]),
            });
          },
        },
        templateTop: function () {
          let data = this.state.data[0];
          return (
            <div className=" p-4">
              <p>
                Are you sure you want to delete stream "
                <strong>{data.title.value}</strong>"?<br></br>
              </p>
            </div>
          );
        },
      },
      pk: "_id",
      properties: UniStreamEventProperties({ editable: false }),
    },
  },
};

function UniStreamEventProperties(input) {
  let editable = input.editable;

  return {
    _id: {
      alias: "_id",
      control: "hidden",
      label: "",
      editable: editable,
      apiPath: "",
      auth: "",
      validations: "",
      attributes: {},
    },
    title: {
      alias: "title",
      label: "Title",
      control: "text",
      editable: editable,
      apiPath: "",
      auth: "",
      validations: "",
      attributes: {
        className: "form-control",
      },
    },
    streamURL: {
      alias: "streamURL",
      label: "Stream URL",
      control: "text",
      placeholder: "Type the URL of the stream",
      editable: editable,
      apiPath: "",
      auth: "",
      validations: "",
      attributes: {
        className: "form-control",
      },
    },
    isPublic: {
      alias: "isPublic",
      label: "Public available",
      control: "select",
      config: {
        options: [
          { label: "Yes", value: "true" },
          { label: "No", value: "false" },
        ],
      },
      editable: editable,
      apiPath: "",
      auth: "",
      validations: "",
      attributes: {
        className: "form-control",
      },
    },
    fromToDateTime: {
      alias: "fromToDateTime",
      control: "group",
      label: "Availability",
      editable: editable,
      customLayout: function (args) {
        return (
          <React.Fragment>
            <label>{args.object.props.field.label}</label>
            <div className="row mb-3">
              <div className="col-md-5 pr-0 availability-calendar">
                {args.control[0]}
              </div>
              <div className="col-md-1 text-center pr-0 pt-2">to</div>
              <div className="col-md-5 pl-0 availability-calendar">
                {args.control[1]}
              </div>
              <div className="col-md-1"></div>
            </div>
          </React.Fragment>
        );
      },
      useCustomLayout: true,
      config: {
        properties: {
          fromDateTime: {
            alias: "fromDateTime",
            control: "group",
            editable: editable,
            customLayout: function (args) {
              return (
                <React.Fragment>
                  <div className="row mb-3">
                    <div className="col-md-8 pr-0">{args.control[0]}</div>
                    <div className="col-md-4 pr-0">{args.control[1]}</div>
                  </div>
                </React.Fragment>
              );
            },
            useCustomLayout: true,
            config: {
              properties: {
                fromDate: {
                  alias: "fromDate",
                  control: "datetime",
                  config: {
                    fromFormat: "YYYY-MM-DDT00:00:00.000Z",
                    toFormat: "YYYY-MM-DDT00:00:00.000Z",
                    includeTime: false,
                    valueEditFormat: { dateStyle: "short" },
                    valueDisplayFormat: { dateStyle: "short" },
                  },
                  editable: editable,
                },
                fromTime: {
                  alias: "fromTime",
                  control: "select",
                  config: {
                    options: UniFun.timeSelect,
                  },
                  editable: editable,
                  attributes: {
                    className: "form-control",
                  },
                },
              },
            },
          },
          toDateTime: {
            alias: "toDateTime",
            control: "group",
            editable: editable,
            customLayout: function (args) {
              return (
                <React.Fragment>
                  <div className="row mb-3">
                    <div className="col-md-8 pr-0">{args.control[0]}</div>
                    <div className="col-md-4 pr-0">{args.control[1]}</div>
                  </div>
                </React.Fragment>
              );
            },
            useCustomLayout: true,
            config: {
              properties: {
                toDate: {
                  alias: "toDate",
                  control: "datetime",
                  config: {
                    fromFormat: "YYYY-MM-DDT00:00:00.000Z",
                    toFormat: "YYYY-MM-DDT00:00:00.000Z",
                    includeTime: false,
                    valueEditFormat: { dateStyle: "short" },
                    valueDisplayFormat: { dateStyle: "short" },
                  },
                  editable: editable,
                },
                toTime: {
                  alias: "toTime",
                  control: "select",
                  config: {
                    options: UniFun.timeSelect,
                  },
                  editable: editable,
                  attributes: {
                    className: "form-control",
                  },
                },
              },
            },
          },
        },
      },
    },
    instructors: {
      alias: "instructors",
      label: "Instructors",
      control: "tags",
      editable: editable,
      config: {
        idAttribute: "_id",
        nameAttribute: "name",
        apis: {
          search: {
            url: "/user/autocomplete/{query}",
            formatter: function (args) {
              let formattedData = [];
              if (args) {
                let data = args.data;
                data.map((itm, idx) => {
                  let fullName =
                    itm["firstName"] +
                    " " +
                    itm["lastName"] +
                    " - " +
                    itm["email"];
                  formattedData.push({ _id: itm["_id"], name: fullName });
                });
              }
              return formattedData;
            },
            parameters: {
              replace: {
                query: "",
              },
            },
          },
        },
      },
      attributes: {
        className: "form-control",
      },
    },
    participants: {
      alias: "participants",
      label: "Choose participants",
      placeholder: "Select the participants of the stream",
      control: "tags",
      editable: editable,
      config: {
        idAttribute: "_id",
        nameAttribute: "name",
        apis: {
          search: {
            url: "/user/autocomplete/{query}?type=all",
            formatter: function (args) {
              let formattedData = [];
              if (args) {
                let data = args.data;
                data.map((itm, idx) => {
                  let fullName =
                    itm["firstName"] +
                    " " +
                    itm["lastName"] +
                    " - " +
                    itm["email"];
                  formattedData.push({ _id: itm["_id"], name: fullName });
                });
              }
              return formattedData;
            },
            parameters: {
              replace: {
                query: "",
              },
            },
          },
        },
      },
      attributes: {
        className: "form-control",
      },
    },
    description: {
      alias: "description",
      label: "Agenda",
      placeholder: "Write the agenda of the stream",
      nonEditableDefaultValue: "No description available",
      control: "editor",
      editable: editable,
      attributes: {
        className: "form-control",
      },
    },
    createdBy: {
      alias: "createdBy",
      label: "Organizer",
      control: "text",
      editable: editable,
      attributes: {
        className: "form-control",
      },
    },
    organizer: {
      alias: "organizer",
      label: "Organizer",
      control: "text",
      editable: editable,
      attributes: {
        className: "form-control",
      },
    },
    streamFiles: {
      alias: "streamFiles",
      control: "module",
      editable: editable,
      config: {
        module: "uniparticipantsandfilesevent",
        view: "files",
        moduleParams: { type: "streams" },
        parameters: {
          replace: {
            path: function () {
              return "/stream/" + Fun.getURLPathValue(3) + "";
            },
          },
        },
      },
    },
    streamParticipants: {
      alias: "streamParticipants",
      control: "module",
      editable: editable,
      config: {
        module: "uniparticipantsandfilesevent",
        view: "participants",
        moduleParams: { type: "streams" },
        parameters: {
          replace: {
            path: function () {
              return "/stream/" + Fun.getURLPathValue(3) + "/participants";
            },
          },
        },
      },
    },
    files: {
      alias: "files",
      control: "dropzone",
      label: "Attach file",
      editable: editable,
      customContentLayout: function () {
        let files = JSON.parse(this.state.defaultValue);

        let content = [];

        Object.keys(files).map((itm, idx) => {
          content.push(
            UniFun.trainingNodeFileUI(files[itm], this.removeFile, itm)
          );
        });

        this.state.content?.map((itm, idx) => {
          if (typeof files[itm.key] === "undefined") {
            content.push(itm);
          }
        });
        return (
          <React.Fragment>
            {this.state.placeholder()}
            {content}
            {/* {content.length > 0 ? "" : this.state.placeholder()} */}
          </React.Fragment>
        );
      },

      useCustomLayout: true,
      config: {
        placeholder: function () {
          return (
            <div className="p-3">
              <strong>
                Drag & drop here or{" "}
                <a
                  href="javascript:void(0);"
                  className="text-primary"
                  onClick={this.onTargetClick}
                >
                  <strong>browse file</strong>
                </a>{" "}
                to attach
              </strong>
              <br></br>
              <span className="text-dark">Maximum size: 50MB</span>
            </div>
          );
        },
      },
      events: {
        removeFile: function (id) {
          let defaultValue = {};

          Object.keys(JSON.parse(this.state.defaultValue)).map((itm, idx) => {
            if (itm != id) {
              defaultValue[itm] = JSON.parse(this.state.defaultValue)[itm];
            }
          });

          let content = [];
          this.state.content?.map((itm, idx) => {
            if (itm.key != id) {
              content.push(itm);
            }
          });

          this.setState({
            defaultValue: JSON.stringify(defaultValue),
            content: content,
          });

          return;
        },
        onDrop: function (files) {
          this.setState({ defaultValue: JSON.stringify({}) });

          UniFun.fileProgress({
            control: this,
            files: files,
            url: "/agenda-file",
            type: "stream",
          });
        },
      },
      attributes: {
        className: "border rounded  dropzone-trainings",
      },
    },
    reminders: {
      alias: "reminders",
      control: "select",
      config: {
        options: [
          {
            label: "5 minutes before",
            value: JSON.stringify({ type: "minutes", value: 5 }),
          },
          {
            label: "10 minutes before",
            value: JSON.stringify({ type: "minutes", value: 10 }),
          },
          {
            label: "30 minutes before",
            value: JSON.stringify({ type: "minutes", value: 30 }),
          },
          {
            label: "1 hour before",
            value: JSON.stringify({ type: "minutes", value: 60 }),
          },
          {
            label: "1 day before",
            value: JSON.stringify({ type: "days", value: 1 }),
          },
        ],
      },
      label: "Reminder",
      editable: editable,
      attributes: {
        className: "form-control",
      },
    },
    editdropdown: {
      alias: "editdropdown",
      control: "dropdownbutton",
      caption: (
        <img src="/uni/svg/more-options.svg" style={{ width: "14px" }}></img>
      ),
      editable: true,
      config: {
        removeToggleIcon: true,
        options: {
          edit: {
            label: "Edit",
            events: {
              onClick: function () {
                let builder = this.props.builder;

                let params = {
                  replace: {
                    id: this.props.value,
                  },
                };
                window["modal2"].setState({
                  title: <h3>Edit Stream</h3>,
                  size: "xl",
                  targetComponent: this,
                  footer: UniFun.modalCommonFooter("modal2", "Update"),
                  callback: function () {
                    window["modal2"].setState({
                      show: false,
                      overflowY: "auto",
                    });
                    builder.rerunComponent();
                  },
                  show: true,
                  body: (
                    <Builder
                      module="unistreamevent"
                      view="editEvent"
                      parameters={params}
                      ref={(refComponent) => {
                        window["modal2body"] = refComponent;
                      }}
                    ></Builder>
                  ),
                });
              },
            },
          },
          delete: {
            label: <span className="text-danger">Delete</span>,
            events: {
              onClick: function () {
                let builder = this.props.builder;
                deleteStreamEvent(this.props.value, builder);
              },
            },
          },
        },
      },
      attributes: {
        className: "btn btn-link m-2 ",
      },
    },
    from: {
      alias: "from",
      label: "",
      control: "text",
      editable: editable,
      attributes: {
        className: "form-control",
      },
    },
    to: {
      alias: "to",
      label: "",
      control: "text",
      editable: editable,
      attributes: {
        className: "form-control",
      },
    },
  };
}

function UniStreamEventFormatter(data) {
  if (data) {
    let formattedData = [];

    data?.data?.map((itm, idx) => {
      let formattedRecord = {};

      formattedRecord["_id"] = itm._id;
      formattedRecord["editdropdown"] = itm._id;

      formattedRecord["title"] = itm.title;
      formattedRecord["streamURL"] = itm.streamURL;
      formattedRecord["isPublic"] = itm.isPublic ? "true" : "false";

      formattedRecord["from"] = itm.from;
      formattedRecord["to"] = itm.to;
      formattedRecord["participants"] = UniFun.participantsFormatter(
        itm.participants
      );

      const start = moment();
      const halfhour = 30 - (start.minute() % 30);
      const dateFromTime = moment(start)
        .add(halfhour, "minutes")
        .format("HH:mm:00");
      const dateToTime = moment(start)
        .add(halfhour, "minutes")
        .add("3", "hours")
        .format("HH:mm:00");

      let fromTime = dateFromTime;
      if (itm.from) {
        fromTime = moment(itm.from).format("HH:mm:00");
      }
      let toTime = dateToTime;
      if (itm.to) {
        toTime = moment(itm.to).format("HH:mm:00");
      }

      if (!itm.from && !itm.to) {
        if (
          moment(start)
            .add(halfhour, "minutes")
            .isBefore(moment(start).add(halfhour, "minutes").add("3", "hours"))
        ) {
          itm.to = moment(start)
            .add(halfhour, "minutes")
            .add("3", "hours")
            .add("1", "days");
        }
      }

      formattedRecord["fromToDateTime"] = {
        fromDateTime: {
          fromDate: itm.from,
          fromTime: fromTime,
        },
        toDateTime: {
          toDate: itm.to,
          toTime: toTime,
        },
      };

      // "from": "2021-07-17T04:30:00.000Z",
      //      "to": "2021-07-17T04:30:00.000Z",
      formattedRecord["description"] = itm.description;
      formattedRecord["files"] = itm.files;
      formattedRecord["reminders"] = itm.reminders
        ? JSON.stringify({
            type: itm.reminders[0].type,
            value: itm.reminders[0].value,
          })
        : {};
      formattedRecord["createdBy"] = [itm.createdBy];
      formattedRecord["organizer"] = [itm.organizer];

      formattedData.push(formattedRecord);
    });

    return formattedData;
  }
}

export default UniStreamEvent;
