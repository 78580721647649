import React, { Component } from "react";
import Fun from "../../../lib/functions";
import Builder from "../../../lib/builder";
import { Link } from "react-router-dom";

class LeftMenu extends Component {
  state = {
    data: [],
    loaded: false,
    output: null,
  };

  constructor(props) {
    super(props);

    this.firstLevelMenu = this.firstLevelMenu.bind(this);
    this.secondLevelMenu = this.secondLevelMenu.bind(this);
    this.createMenu = this.createMenu.bind(this);
  }

  componentDidMount() {
    this.setState({ data: this.props.data, loaded: true });
  }

  onClick(itm) {
    if (itm["onclick"]) {
      itm["onclick"](itm);
    }
  }

  firstLevelMenu(data, selectedId) {
    let output = [];
    data.map((itm, idx) => {
      let toggleClassName = "";

      if (itm["children"]) {
        if (itm["children"].length > 0) {
          toggleClassName = " c-sidebar-nav-dropdown-toggle ";
        }
      }
      let selectedClass = "";
      if (itm["_id"] == selectedId) {
        selectedClass = "c-sidebar-nav-selected";
      }
      if (itm["selected"] == true) {
        selectedClass = "c-sidebar-nav-selected";
      }

      let permit = true;
      if (typeof itm.auth !== "undefined") {
        if (typeof itm.auth == "function") {
          permit = itm.auth();
        } else {
          permit = itm.auth;
        }
      }

      if (permit) {
        output.push(
          <li
            className={
              "c-sidebar-nav-item c-sidebar-nav-dropdown uniLight " +
              selectedClass +
              " " +
              itm["liclass"]
            }
            key={Fun.uuid()}
            title={itm["name"]}
          >
            <Link
              className={
                "c-sidebar-nav-link  " +
                toggleClassName +
                itm["class"] +
                "  font-size-15"
              }
              to={itm["url"]}
              key={Fun.uuid()}
            >
              {Fun.parse(itm["title"])}
            </Link>

            {this.secondLevelMenu(itm["children"], selectedId)}
          </li>
        );
      }
    });

    return output;
  }

  secondLevelMenu(data, selectedId) {
    let output = [];
    data.map((itm, idx) => {
      let selectedClass = "";

      // if (itm["_id"] == selectedId) {
      //   selectedClass = "c-sidebar-nav-selected";
      // }
      if (itm["selected"] == true) {
        selectedClass = "c-sidebar-nav-selected";
      }

      let link = (
        <div className="c-sidebar-nav-link" key={Fun.uuid()}>
          <span className="c-sidebar-nav-icon"></span> {Fun.parse(itm["title"])}
        </div>
      );

      if (itm["url"]) {
        link = (
          <Link className="c-sidebar-nav-link" to={itm["url"]} key={Fun.uuid()}>
            <span className="c-sidebar-nav-icon"></span>{" "}
            {Fun.parse(itm["title"])}
          </Link>
        );
      }

      let permit = true;
      if (typeof itm.auth !== "undefined") {
        if (typeof itm.auth == "function") {
          permit = itm.auth();
        } else {
          permit = itm.auth;
        }
      }

      if (permit) {
        output.push(
          <li
            className={
              "c-sidebar-nav-item uniLight " +
              selectedClass +
              " " +
              itm["liclass"]
            }
            key={Fun.uuid()}
            title={itm["name"]}
          >
            {link}
          </li>
        );
      }
    });

    if (output.length > 0) {
      output = (
        <ul className="c-sidebar-nav-dropdown-items" key={Fun.uuid()}>
          {output}
        </ul>
      );
    }

    return output;
  }

  createMenu() {
    let data = this.state.data;

    let output = [];

    data.map((itm, idx) => {
      let permit = true;
      if (typeof itm.auth !== "undefined") {
        if (typeof itm.auth == "function") {
          permit = itm.auth();
        } else {
          permit = itm.auth;
        }
      }

      if (permit) {
        output.push(
          <React.Fragment key={Fun.uuid()}>
            <li
              className="c-sidebar-nav-title uniLight"
              key={Fun.uuid()}
              title={itm["name"]["value"]}
            >
              {Fun.parse(itm["title"]["value"])}
            </li>

            {this.firstLevelMenu(
              itm["children"]["value"],
              itm["selectedId"]["value"]
            )}
          </React.Fragment>
        );
      }
    });

    if (output.length > 0) {
      output = (
        <ul className="c-sidebar-nav uniLight mt-30 " key={Fun.uuid()}>
          {output}
        </ul>
      );
    }

    return output;
  }

  render() {
    if (this.state.loaded) {
      return this.createMenu();
    } else {
      return "loading...";
    }
  }
}

export default LeftMenu;
