import React, { Component } from "react";
import { Link } from "react-router-dom";

class LoginForm extends Component {
  constructor(props) {
    super(props);

    let builder = this.props.builder.state;
    let templateConfig = builder.module.templateConfig;
    if (templateConfig.events) {
      Object.keys(templateConfig.events).map((itm, idx) => {
        this[itm] = templateConfig.events[itm].bind(this);
      });
    }

    this.state = {
      loaded: false,
      isLoggedIn: false,
    };
    this.form = React.createRef();
  }

  componentDidMount() {
    this.setState({ loaded: true });
  }

  render() {
    if (this.state.loaded) {
      return (
        <React.Fragment>
          <div className="text-center">
            <img
              src="/uni/images/company-logo.png"
              style={{ height: "100px" }}
            ></img>
            <h1>Login to your account</h1>
            <p className="text-muted">
              Please enter your email address and password to login.
            </p>
          </div>
          <form onSubmit={this.onSubmit} ref={this.form}>
            <div className="form-group ">
              <label className="text-dark">Email address</label>
              <div>
                <input
                  className="form-control"
                  type="text"
                  name="uni_username"
                  placeholder="Type your email address"
                />
              </div>
            </div>
            <div className="form-group ">
              <label className="text-dark">Password</label>

              <div>
                <input
                  className="form-control"
                  type="password"
                  name="uni_password"
                  placeholder="Type your password"
                />
              </div>
            </div>

            <p>
              <Link to="/forgot-password">Forgot password</Link>
            </p>
            <div className="form-group ">
              <button className="btn btn-primary btn-block" type="submit">
                Login
              </button>
            </div>
          </form>
        </React.Fragment>
      );
    }
    return "";
  }
}

export default LoginForm;
