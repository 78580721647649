import React, { Component } from "react";
import Control from "./../../control";
import SimpleReactValidator from "simple-react-validator";
import Fun from "../../../../../lib/functions";
import UniFun from "../../../../../modules/uni/functions";
import moment from "moment";
import TopTemplateHeader from "./../../topTemplateHeader";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";

class Sponsors extends Component {
  state = {
    data: this.props.data,
    loaded: false,
    colsTemplateCount: 12,
    colsFormCount: 12,
    cardBodyCustomClass: "",
    module: {
      _id: "",
      title: "",
      description: "",
    },
    session: {},
  };

  constructor(props) {
    super(props);
    let builder = this.props.builder.state;
    let templateConfig = builder.module.templateConfig;
    if (templateConfig.events) {
      Object.keys(templateConfig.events).map((itm, idx) => {
        this[itm] = templateConfig.events[itm].bind(this);
      });
    }
    this.sponsorCards = this.sponsorCards.bind(this);
    this.form = React.createRef();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.action) {
      return false;
    }
    return true;
  }

  componentDidMount() {
    let builder = this.props.builder.state;
    let templateConfig = builder.module.templateConfig;
    let useCard =
      typeof templateConfig.useCard !== "undefined"
        ? templateConfig.useCard
        : true;

    let templateTop = templateConfig.templateTop
      ? templateConfig.templateTop
      : () => {};
    this["templateTop"] = templateTop.bind(this);
    let templateBottom = templateConfig.templateBottom
      ? templateConfig.templateBottom
      : () => {};
    this["templateBottom"] = templateBottom.bind(this);
    let templateLeft = templateConfig.templateLeft
      ? templateConfig.templateLeft
      : () => {};
    this["templateLeft"] = templateLeft.bind(this);
    let templateRight = templateConfig.templateRight
      ? templateConfig.templateRight
      : () => {};
    this["templateRight"] = templateRight.bind(this);
    let cardTemplateTop = templateConfig.cardTemplateTop
      ? templateConfig.cardTemplateTop
      : () => {};
    this["cardTemplateTop"] = cardTemplateTop.bind(this);
    let cardTemplateBottom = templateConfig.cardTemplateBottom
      ? templateConfig.cardTemplateBottom
      : () => {};
    this["cardTemplateBottom"] = cardTemplateBottom.bind(this);
    let panelTop = templateConfig.panelTop ? templateConfig.panelTop : () => {};
    this["panelTop"] = panelTop.bind(this);
    let panelBottom = templateConfig.panelBottom
      ? templateConfig.panelBottom
      : () => {};
    this["panelBottom"] = panelBottom.bind(this);
    let panelLeft = templateConfig.panelLeft
      ? templateConfig.panelLeft
      : () => {};
    this["panelLeft"] = panelLeft.bind(this);
    let panelRight = templateConfig.panelRight
      ? templateConfig.panelRight
      : () => {};
    this["panelRight"] = panelRight.bind(this);
    let header = templateConfig.header ? templateConfig.header : () => {};
    this["header"] = header.bind(this);

    let colsTemplateCount = templateConfig.colsTemplateCount
      ? templateConfig.colsTemplateCount
      : this.state.colsTemplateCount;
    let colsFormCount = templateConfig.colsFormCount
      ? templateConfig.colsFormCount
      : this.state.colsFormCount;

    let cardBodyCustomClass = templateConfig.cardBodyCustomClass
      ? templateConfig.cardBodyCustomClass
      : this.state.cardBodyCustomClass;

    this.setState({
      loaded: true,
      rawData: this.props.rawData,
      colsTemplateCount: colsTemplateCount,
      colsFormCount: colsFormCount,
      cardBodyCustomClass: cardBodyCustomClass,
      useCard: useCard,
    });

    this.validator = this.props.builder.validator;
    if (this.componentDidMountEvent) {
      this.componentDidMountEvent();
    }
  }

  sponsorCards() {
    let output = [];

    let data = this.state.rawData.data[0];

    let categories = {};
    let bg = [];

    let minHeightInfo = "";
    data.sponsorsRaw?.forEach((itm, idx) => {
      if (!categories[itm.category]) {
        categories[itm.category] = [];
      }

      let banner = itm.logo
        ? window["baseurl"](
            "/agenda-file/data/" +
              itm.logo._id +
              "?type=preview&jwt=" +
              localStorage.getItem("jwt")
          )
        : "";

      categories[itm.category].push(
        <div className="col-md-3 text-center">
          <div className="pointer" onClick={(e) => this.sponsorsInfoModal(itm)}>
            <img
              src={banner}
              className="img-fluid p-3"
              style={{
                maxheight: "80px",
                maxWidth: "250px",
              }}
            />
          </div>
        </div>
      );
    });
    // 'Diamond', 'Platinum', 'Gold', 'Silver', 'Bronze'

    return Object.keys(categories).map((itm, idx) => {
      return (
        <React.Fragment>
          <div class="row mb-3 mt-5 ">
            <div class="col">
              <strong>{itm}</strong>
              <span class="text-dark pl-3"></span>
            </div>
          </div>
          <div className="row">{categories[itm]}</div>
        </React.Fragment>
      );
    });
  }

  render() {
    if (this.state.loaded) {
      let data = this.state.data[0];

      return (
        <React.Fragment>
          <div className={"uniTopHeader "}>
            <TopTemplateHeader
              customClass="mb-4 uniFixedHeader"
              title={
                <React.Fragment>
                  <span className="pl-3">Partners</span>
                </React.Fragment>
              }
            ></TopTemplateHeader>
          </div>
          <div className="container-fluid mt-5">{this.sponsorCards()}</div>
        </React.Fragment>
      );
    } else {
      return "loading...";
    }
  }
}

export default withRouter(Sponsors);
