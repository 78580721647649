import React, { Component } from "react";
import Fun from "../../../lib/functions";
import Builder from "../../../lib/builder";
import UniFun from "../functions";
import { ThemeProvider } from "react-bootstrap";
import TopTemplateHeader from "../../../themes/default/templates/topTemplateHeader";
import { Link } from "react-router-dom";
import DropdownButton from "../../../controls/dropdownButton/dropdownButton";
import { withRouter } from "react-router-dom";

const deleteTrainingEvent = function (eventId, parentBuilder) {
  let parameters = {
    replace: {
      id: eventId,
    },
  };

  let builder = parentBuilder;

  window["modal2"].setState({
    title: <h3>Delete training event?</h3>,
    size: "lg",
    targetComponent: this,
    show: true,
    callback: function () {
      window["modal2"].setState({ show: false, overflowY: "auto" });
      Fun.createNotification({
        type: "success",
        message: "Training has been deleted!",
      });
      window.location.replace("/agenda");
    },
    footer: UniFun.modalCommonFooter("modal2", "Delete", "btn-danger"),
    body: (
      <Builder
        module="unitrainingevents"
        view="deleteEvent"
        parameters={parameters}
        ref={(refComponent) => {
          window["modal2body"] = refComponent;
        }}
      ></Builder>
    ),
  });
};

function showTrainingAbout(description) {
  window["modal2"].setState({
    title: <h3>About</h3>,
    size: "xl",
    targetComponent: this,
    show: true,
    footer: [],
    body: <p className="p-5">{Fun.parse(description)}</p>,
  });
}

const trainingEventHeader = function () {
  let data = this.state.data[0];
  let subtitle = data.modules.value.map((itm, idx) => {
    if (itm._id == Fun.getURLPathValue(5)) {
      return itm.title;
    }
  });
  subtitle = this.props.builder.state.module.templateConfig.headerSubtitle
    ? this.props.builder.state.module.templateConfig.headerSubtitle
    : subtitle;

  return (
    <div className={"uniTopHeader "}>
      <TopTemplateHeader
        customClass="mb-4 uniFixedHeader"
        title={
          <React.Fragment>
            <img
              src="/uni/svg/trainingmain.svg"
              className="pr-3"
              style={{ width: "39px", paddingBottom: "5px" }}
            ></img>{" "}
            <span data-tip={data.title.value}>
              {UniFun.substring(data.title.value, 0, 50)}
            </span>
            <img
              src="/uni/svg/trainings/information.svg"
              className="pl-4 pointer"
              style={{ width: "40px", paddingBottom: "15px" }}
              onClick={(e) => {
                showTrainingAbout(
                  data.description.value
                    ? data.description.value
                    : "No description"
                );
              }}
            ></img>
            <div style={{ display: "inline-block" }}>
              {" "}
              {UniFun.userIsEligible({ forWhat: "default" }) ||
              UniFun.userIsEligible({
                forWhat: "viewer",
              })
                ? data.editdropdown.component
                : ""}
            </div>
          </React.Fragment>
        }
        subtitle={<span className="text-dark pl-5">{subtitle}</span>}
      ></TopTemplateHeader>
    </div>
  );
};

const editTrainingEvent = function (id, parentBuilder) {
  let builder = parentBuilder;
  let parameters = {
    replace: {
      id: id,
    },
  };

  window["modal"].setState({
    title: <h3>Edit training template</h3>,
    size: "xl",
    targetComponent: this,
    callback: function () {
      window["modal"].setState({ show: false, overflowY: "auto" });
      Fun.createNotification({
        type: "success",
        message: "Update update was successful!",
      });
      builder.rerunComponent();
    },
    footer: UniFun.modalCommonFooter("modal", "Update"),
    show: true,
    body: (
      <Builder
        module="unitrainingevents"
        view="detail"
        parameters={parameters}
        key={Fun.uuid()}
        ref={(refComponent) => {
          window["modalbody"] = refComponent;
        }}
      ></Builder>
    ),
  });
};

const UniTrainingEvents = {
  auth: "",
  views: {
    // list: {
    //   auth: "",
    //   apis: {
    //     view: {
    //       url: "/template/training?start={start}&offset={offset}",
    //       parameters: {
    //         headers: {},
    //         replace: {},
    //       },
    //       formatter: function (data) {
    //         return UniTrainingEventsFormatter(data);
    //       },
    //     },
    //   },
    //   template: "grid",
    //   templateConfig: {
    //     headerTitle: "Training Templates",
    //     headerTitleIcon: "/uni/svg/trainingtemp.svg",
    //     colsTemplateCount: 12,
    //     includeProperties: ["title", "createdBy", "modified"],
    //     excludeProperties: [],
    //     gridConfig: {
    //       headers: {
    //         title: {
    //           attributes: {
    //             className: "w-50",
    //           },
    //         },
    //       },
    //       cellTemplates: {
    //         title: function (data, rec, obj) {
    //           let uid = Fun.uuid();

    //           let builder = obj.props.builder;

    //           return (
    //             <React.Fragment>
    //               {data}
    //               <span className="float-right mr-4 grid-controls">
    //                 <i
    //                   className="far fa-edit mr-4 pointer"
    //                   onClick={(e) => editTrainingEvent(rec._id.value, builder)}
    //                 ></i>
    //                 <i
    //                   className="fas fa-ellipsis-h pointer"
    //                   data-toggle="collapse"
    //                   data-target={"#" + uid}
    //                 ></i>
    //                 <div
    //                   className="position-absolute bg-white ml-4 mt-2 collapse"
    //                   id={uid}
    //                 >
    //                   <ul className="list-group " style={{ width: "200px" }}>
    //                     <li
    //                       className="list-group-item p-2 pl-3"
    //                       onClick={(e) =>
    //                         editTrainingEvent(rec._id.value, builder)
    //                       }
    //                     >
    //                       Edit
    //                     </li>
    //                     <li className="list-group-item p-2 pl-3">Duplicate</li>
    //                     <li className="list-group-item p-2  pl-3 text-danger">
    //                       Delete
    //                     </li>
    //                   </ul>
    //                 </div>
    //               </span>
    //             </React.Fragment>
    //           );
    //         },
    //       },
    //     },
    //     cardTemplateTop: function () {
    //       let data = this.state.data;
    //       return (
    //         <div className="row">
    //           <div className="col-6 pt-2 pl-4">
    //             <span className="text-dark ">
    //               There are {data.length} training templates
    //             </span>
    //           </div>
    //           <div className="col-6 text-right p-2">
    //             {data[0].newTemplate.component}
    //           </div>
    //         </div>
    //       );
    //     },
    //     header: UniFun.commonHeader, //trainingTemplatesHeader,
    //   },
    //   pk: "_id",
    //   properties: UniTrainingEventsProperties({ editable: false }),
    // },
    detail: {
      auth: "",
      apis: {
        view: {
          url: "/template/training/{id}",
          parameters: {
            headers: {},
            replace: {},
          },
          formatter: function (data) {
            return UniTrainingEventsFormatter(data);
          },
        },
        update: {
          url: "/event/training",
          parameters: {
            headers: {
              "Content-Type": "application/json",
            },
            replace: {},
          },
          formatter: function (data) {
            return UniTrainingEventsFormatter(data);
          },
        },
      },
      template: "form",
      templateConfig: {
        events: {
          onSubmit: function (e) {
            e.preventDefault();

            if (
              !Fun.validate(this.props.builder.validator, Fun.formDataToJSON(e))
            ) {
              return;
            }

            let jsonData = UniFun.trainingEventJSONData(
              Fun.getSubmitJSON(e),
              false
            );

            let parameters = {
              replace: {
                id: jsonData["_id"],
              },
            };

            jsonData["options"] = jsonData["options"][0];

            Fun.update(e, {
              url: this.props.builder.state.module.apis.update,
              method: "POST",
              parameters: parameters,
              callback: function (
                submittedData,
                status,
                responsedata,
                responsestatus
              ) {
                if (responsestatus == "200") {
                  if (window["modal"].state.callback) {
                    window["modal"].state.callback(submittedData);
                  }
                  Fun.createNotification({
                    type: "success",
                    message: "Update was successful!",
                  });
                } else {
                  if (responsedata.data.exceptions) {
                    responsedata.data.exceptions.map((itm, idx) => {
                      Fun.createNotification({
                        message: itm.errorDescription,
                        type: "danger",
                      });
                    });
                  }
                }
              },
              parameters: {
                headers: {
                  "content-type": "application/json",
                },
              },
              submittedData: JSON.stringify(jsonData),
            });
          },
        },
        colsTemplateCount: 12,
        includeProperties: [
          "_id",
          "title",
          "description",
          "participants",
          "modules",
          "options",
        ],
      },
      pk: "_id",
      properties: UniTrainingEventsProperties({ editable: true }),
    },
    editEvent: {
      auth: "",
      apis: {
        view: {
          url: "/event/training/{id}",
          parameters: {
            headers: {},
            replace: {},
          },
          formatter: function (data) {
            return UniTrainingEventsFormatter(data);
          },
        },
        update: {
          url: "/event/training",
          parameters: {
            headers: {
              "Content-Type": "application/json",
            },
            replace: {},
          },
          formatter: function (data) {
            return UniTrainingEventsFormatter(data);
          },
        },
      },
      template: "form",
      templateConfig: {
        events: {
          onSubmit: function (e) {
            e.preventDefault();
            let jsonData = UniFun.trainingEventJSONData(
              Fun.getSubmitJSON(e),
              false
            );

            if (
              !Fun.validate(this.props.builder.validator, Fun.formDataToJSON(e))
            ) {
              return;
            }

            // console.log(jsonData);
            let parameters = {
              replace: {
                id: jsonData["_id"],
              },
            };

            jsonData["options"] = jsonData["options"][0];

            Fun.update(e, {
              url: this.props.builder.state.module.apis.update,
              method: "PATCH",
              parameters: parameters,
              callback: function (
                submittedData,
                status,
                responsedata,
                responsestatus
              ) {
                if (responsestatus == "200") {
                  if (window["modal"].state.callback) {
                    window["modal"].state.callback(submittedData);
                  }
                  Fun.createNotification({
                    message: "Update was successful",
                    type: "success",
                  });
                } else {
                  if (responsedata.data.exceptions) {
                    responsedata.data.exceptions.map((itm, idx) => {
                      Fun.createNotification({
                        message: itm.errorDescription,
                        type: "danger",
                      });
                    });
                  }
                }
              },
              parameters: parameters,
              submittedData: jsonData,
            });
          },
        },
        colsTemplateCount: 12,
        includeProperties: [
          "_id",
          "title",
          "description",
          "options",
          //"participants",
          "modules",
        ],
      },
      pk: "_id",
      properties: UniTrainingEventsProperties({ editable: true }),
    },
    participants: {
      auth: "",
      apis: {
        view: {
          url: "/event/training/{_id}",
          parameters: {
            headers: {},
            replace: {},
          },
          formatter: function (data) {
            return UniTrainingEventsFormatter(data);
          },
        },
      },
      template: "form",
      templateConfig: {
        useCard: false,
        colsTemplateCount: 12,
        headerTitle: "Training title",
        headerTitleIcon: "/uni/svg/trainingtemp.svg",
        headerSubtitle: "Participants",
        events: {
          onSubmit: function (e) {
            e.preventDefault();
          },
        },
        header: trainingEventHeader,
        includeProperties: ["trainingParticipants"],
      },
      pk: "_id",
      properties: UniTrainingEventsProperties({ editable: true }),
    },
    preview: {
      auth: "",
      apis: {
        view: {
          url: "/event/training/{_id}",
          parameters: {
            headers: {},
            replace: {},
          },
          formatter: function (data) {
            return UniTrainingEventsFormatter(data);
          },
        },
        update: {
          url: "/event/training",
          parameters: {
            headers: {
              "Content-Type": "application/json",
            },
            replace: {},
          },
          formatter: function (data) {
            return UniTrainingEventsFormatter(data);
          },
        },
      },
      template: "trainingoverview",
      templateConfig: {
        headerTitle: "Training title",
        headerTitleIcon: "/uni/svg/trainingtemp.svg",
        headerSubtitle: "Overview",
        events: {
          onSubmit: function (e) {
            e.preventDefault();
          },
          componentDidMountEvent: function () {
            window["trainingoverviewinterval"] = setInterval(
              this.props.builder.rerunComponent,
              60000
            );
          },
          componentWillUnmount: function () {
            clearInterval(window["trainingoverviewinterval"]);
          },
        },
        header: trainingEventHeader,
        panelBottom: function () {
          let data = this.state.data[0];

          let modules = data.modules.value;

          let modulesOut = [];
          let sessions = [];
          modules.map((itm, idx) => {
            let toggleId = Fun.uuid();

            modulesOut.push(
              <div className="card border-0 p-0" key={Fun.uuid()}>
                <div className="card-header border-0 pl-5 pb-0">
                  <div className="mb-0">
                    <div
                      className="preview-training-modules pointer collapsed border-bottom pb-4 "
                      data-toggle="collapse"
                      data-target={"#" + toggleId}
                      aria-expanded="false"
                    >
                      <strong>{itm.title}</strong>
                      <br></br>
                      <span className="text-dark pl-1">
                        {itm.sessions.length} Sessions
                      </span>
                    </div>
                  </div>
                </div>

                <div
                  id={toggleId}
                  className="collapse pb-0 mb-0"
                  data-parent="#accordion"
                >
                  <div className="card-body pt-0 pl-5">
                    {itm.sessions.map((sitm, sidx) => {
                      return (
                        <div className="border-bottom  pointer">
                          <Link
                            to={
                              "/event/training/" +
                              data._id.value +
                              "/module/" +
                              itm._id
                            }
                            className="d-flex"
                          >
                            <span className="pb-3 pt-3">{sitm.title}</span>
                          </Link>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            );
          });

          let instructors = [];
          let instructorsIds = [];

          data.modules.value.forEach((itm, idx) => {
            itm.sessions.forEach((sitm, sidx) => {
              sitm.instructors.forEach((iitm, iidx) => {
                if (instructorsIds.indexOf(iitm._id) < 0) {
                  instructorsIds.push(iitm._id);
                  instructors.push(iitm);
                }
              });
            });
          });

          return (
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="row">
                    <div className="col-12">
                      <div className="mb-3">
                        <h5 className="pb-1 text-black">
                          <strong>About</strong>
                        </h5>
                      </div>
                      <div className="card">
                        <div className="card-body">
                          {data.description.component}
                          {/* {data.instructors.component} */}
                          <div className=" mt-4 session-section">
                            <div className="mb-2 text-dark">
                              Instructors ({instructors.length})
                            </div>
                            <div className="pl-12px p-3">
                              {UniFun.createProfileImageBubble(instructors)}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="row">
                    <div className="col-12">
                      <div className="mb-3">
                        <h5 className="pb-1 text-black">
                          <strong>Modules</strong>
                        </h5>
                      </div>
                      <div className="card">
                        <div className="card-body" id="accordion">
                          {modulesOut}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        },
        colsTemplateCount: 12,
        excludeProperties: ["none"],
        useCard: false,
      },
      pk: "_id",
      properties: UniTrainingEventsProperties({ editable: false }),
    },
    previewsession: {
      auth: "",
      apis: {
        view: {
          //  url: "http://localhost:3000/jsontest.json", //
          url: "/event/training/{_id}",
          parameters: {
            headers: {},
            replace: {},
          },
          formatter: function (data) {
            return UniTrainingEventsFormatter(data);
          },
        },
      },
      template: "trainingsession",
      templateConfig: {
        headerTitle: "Training title",
        headerTitleIcon: "/uni/svg/trainingtemp.svg",
        headerSubtitle: "Overview",
        events: {
          onSubmit: function (e) {
            e.preventDefault();
          },
          componentDidMountEvent: function () {
            window["trainingoverviewinterval"] = setInterval(
              this.props.builder.rerunComponent,
              60000
            );
          },
          componentWillUnmount: function () {
            clearInterval(window["trainingoverviewinterval"]);
          },
        },
        header: trainingEventHeader,
        colsTemplateCount: 12,
        excludeProperties: ["none"],
        useCard: false,
      },
      pk: "_id",
      properties: UniTrainingEventsProperties({ editable: false }),
    },
    previewmodule: {
      auth: "",
      apis: {
        view: {
          url: "/event/training/{_id}",
          parameters: {
            headers: {},
            replace: {},
          },
          formatter: function (data) {
            return UniTrainingEventsFormatter(data);
          },
        },
        update: {
          url: "/event/training",
          parameters: {
            headers: {
              "Content-Type": "application/json",
            },
            replace: {},
          },
          formatter: function (data) {
            return UniTrainingEventsFormatter(data);
          },
        },
      },
      template: "form",
      templateConfig: {
        headerTitle: "Training title",
        headerTitleIcon: "/uni/svg/trainingtemp.svg",
        useCard: false,
        events: {
          onSubmit: function (e) {
            e.preventDefault();
          },
        },
        header: function () {
          let data = this.state.data[0];
          let subtitle = data.modules.value.map((itm, idx) => {
            if (itm._id == Fun.getURLPathValue(5)) {
              return itm.title;
            }
          });

          return (
            <div className={"uniTopHeader "}>
              <TopTemplateHeader
                customClass="mb-4 uniFixedHeader"
                title={
                  <React.Fragment>
                    <img
                      src="/uni/svg/trainingmain.svg"
                      className="pr-3"
                      style={{ width: "39px", paddingBottom: "5px" }}
                    ></img>{" "}
                    {UniFun.substring(data.title.value, 0, 25)}
                    <div style={{ display: "inline-block" }}>
                      {UniFun.userIsEligible({
                        forWhat: "default",
                      }) ||
                      UniFun.userIsEligible({
                        forWhat: "viewer",
                      })
                        ? data.editdropdown.component
                        : ""}
                    </div>
                  </React.Fragment>
                }
                subtitle={<span className="text-dark pl-5">{subtitle}</span>}
              ></TopTemplateHeader>
            </div>
          );
        },

        templateBottom: function () {
          let data = this.state.data[0];
          let modules = data.modules.value;

          let modulesOut = [];
          let sessions = [];
          modules.map((itm, idx) => {
            if (itm._id == Fun.getURLPathValue(5)) {
              sessions.push(
                <div>
                  <h5 className="pb-1 text-black">
                    <strong>About</strong>
                  </h5>
                  <div className="row ">
                    <div className="col-12">
                      <div className="card " key={Fun.uuid()}>
                        <div className="card-body">
                          {itm.description
                            ? Fun.parse(itm.description)
                            : "No description available"}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );

              itm.sessions.map((sitm, sidx) => {
                sessions.push(
                  <div>
                    <h5 className="pb-2 text-black">
                      <strong>{sitm.title}</strong>
                    </h5>
                    <div className="row ">
                      <div className="col-12">
                        <div className="card " key={Fun.uuid()}>
                          <div className="card-body">
                            {UniFun.createSessionPreview(sitm)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              });
            }
          });

          return sessions;
        },
        colsTemplateCount: 12,
        includeProperties: ["id"],
      },
      pk: "_id",
      properties: UniTrainingEventsProperties({ editable: false }),
    },
    results: {
      auth: "",
      apis: {
        view: {
          url: "/event/training/{_id}",
          parameters: {
            headers: {},
            replace: {},
          },
          formatter: function (data) {
            return UniTrainingEventsFormatter(data);
          },
        },
        update: {
          url: "/event/training",
          parameters: {
            headers: {
              "Content-Type": "application/json",
            },
            replace: {},
          },
          formatter: function (data) {
            return UniTrainingEventsFormatter(data);
          },
        },
      },
      template: "form",
      templateConfig: {
        headerTitle: "Training title",
        headerTitleIcon: "/uni/svg/trainingtemp.svg",
        useCard: true,
        gridConfig: {},
        cardTemplateTop: function () {
          let modules = this.state.data[0].modules.value;
          let tests = [];
          let selectSessionTestOptions = {};
          let selectedSessionTestOption = "Select test";

          modules.map((itm, idx) => {
            if (itm._id == Fun.getURLPathValue(5)) {
              itm.sessions.map((sitm, sidx) => {
                if (sitm._id == Fun.getURLPathValue(7)) {
                  sitm.exam.map((eitm, eidx) => {
                    let isGraded = false;
                    if (eitm.passingGrade > 0) {
                      isGraded = true;
                    }

                    let url =
                      "/event/training/" +
                      this.state.data[0]._id.value +
                      "/module/" +
                      itm._id +
                      "/results/" +
                      sitm._id +
                      "/test/" +
                      eitm._id;
                    let selectTestTitle = isGraded ? (
                      <span>
                        <strong>Graded test: </strong>
                        {eitm.title}
                      </span>
                    ) : (
                      <span>
                        <strong>Practice test: </strong>
                        {eitm.title}
                      </span>
                    );

                    if (eitm._id == Fun.getURLPathValue(9)) {
                      selectedSessionTestOption = selectTestTitle;
                    }

                    selectSessionTestOptions[eidx] = {
                      label: selectTestTitle,
                      events: {
                        onClick: function () {
                          this.setState({ caption: selectTestTitle });
                          window.location = url;
                        },
                      },
                    };
                    tests.push({
                      _id: eitm._id,
                      title: eitm.title,
                      isGraded: isGraded,
                    });
                  });
                }
              });
            }
          });

          let SelectSessionTest = (
            <DropdownButton
              field={{
                alias: "selectSessionTest",
                caption: selectedSessionTestOption,
                config: {
                  removeToggleIcon: false,
                  options: selectSessionTestOptions,
                },

                attributes: {
                  className: "btn btn-secondary m-2 dropdown-toggle",
                },
              }}
            ></DropdownButton>
          );

          return SelectSessionTest;
        },
        header: function () {
          let data = this.state.data[0];
          let sessions = [];
          let url = "";
          let subtitle = data.modules.value.map((itm, idx) => {
            if (itm._id == Fun.getURLPathValue(5)) {
              itm.sessions.map((sitm, sidx) => {
                url =
                  "/event/training/" +
                  data._id.value +
                  "/module/" +
                  itm._id +
                  "/results/" +
                  sitm._id;
                sessions.push(
                  <Link to={url} className="mr-4 text-dark">
                    {sitm.title}
                  </Link>
                );
              });

              return itm.title;
            }
          });

          return (
            <div className={"uniTopHeader "}>
              <TopTemplateHeader
                customClass="mb-4 uniFixedHeader"
                title={
                  <React.Fragment>
                    <img
                      src="/uni/svg/trainingmain.svg"
                      className="pr-3"
                      style={{ width: "39px", paddingBottom: "5px" }}
                    ></img>{" "}
                    <span title={subtitle}>
                      {UniFun.substring(subtitle, 0, 25)}
                    </span>
                    <div style={{ display: "inline-block" }}>
                      {" "}
                      {data.editdropdown.component}
                    </div>
                  </React.Fragment>
                }
                subtitle={<span className="text-dark pl-5">{sessions}</span>}
              ></TopTemplateHeader>
            </div>
          );
        },

        colsTemplateCount: 12,
        includeProperties: ["results"],
      },
      pk: "_id",
      properties: UniTrainingEventsProperties({ editable: false }),
    },
    deleteEvent: {
      auth: "",
      apis: {
        view: {
          url: "/event/training/{id}",
          parameters: {
            headers: {},
            replace: {},
          },
          formatter: function (data) {
            return UniTrainingEventsFormatter(data);
          },
        },
        delete: {
          url: "/event/training/{id}",
          parameters: {
            headers: {},
            replace: {},
          },
          formatter: function (data) {
            return UniTrainingEventsFormatter(data);
          },
        },
      },
      template: "form",
      templateConfig: {
        useCard: false,
        colsTemplateCount: 12,
        includeProperties: ["_id"],
        excludeProperties: [],
        events: {
          onSubmit: function (e) {
            let submittedData = Fun.getSubmitJSON(e);

            let builder = this.props.builder;

            Fun.update(e, {
              url: builder.state.module.apis.delete,
              method: "DELETE",
              parameters: {
                headers: {
                  "content-type": "application/json",
                },
                replace: {
                  id: submittedData["_id"],
                },
              },
              callback: function (args) {
                if (window["modal2"].state.callback) {
                  window["modal2"].state.callback(submittedData);
                }
              },
              submittedData: JSON.stringify([]),
            });
          },
        },
        templateTop: function () {
          let data = this.state.data[0];
          return (
            <div className=" p-4">
              <p>
                Are you sure you want to delete training "
                <strong>{data.title.value}</strong>"?<br></br>
                <br></br> This action will delete:
                <br></br>- All training events (tests, video conferences, video
                presentations, readings). <br></br>- All test results if any
              </p>
            </div>
          );
        },
      },
      pk: "_id",
      properties: UniTrainingEventsProperties({ editable: false }),
    },
    trainingFiles: {
      auth: "",
      apis: {
        view: {
          url: "/event/training/{id}",
          parameters: {
            headers: {},
            replace: {},
          },
          formatter: function (data) {
            return UniTrainingEventsFormatter(data);
          },
        },
      },
      template: "form",
      templateConfig: {
        useCard: false,
        colsTemplateCount: 12,
        headerTitle: "Training title",
        headerTitleIcon: "/uni/svg/trainingtemp.svg",
        headerSubtitle: "Files",
        events: {
          onSubmit: function (e) {
            e.preventDefault();
          },
        },
        header: trainingEventHeader,
        // header: function () {
        //   let data = this.state.data[0];
        //   return (
        //     <div className={"uniTopHeader "}>
        //       <TopTemplateHeader
        //         customClass="mb-4 uniFixedHeader"
        //         title={
        //           <React.Fragment>
        //             <img
        //               src="/uni/svg/trainingmain.svg"
        //               className="pr-3"
        //               style={{ width: "39px", paddingBottom: "5px" }}
        //             ></img>{" "}
        //             {UniFun.substring(data.title.value, 0, 25)}
        //             <div style={{ display: "inline-block" }}>
        //               {" "}
        //               {UniFun.userIsEligible({ forWhat: "default" })
        //                 ? data.editdropdown.component
        //                 : ""}
        //             </div>
        //           </React.Fragment>
        //         }
        //         subtitle={<span className="text-dark pl-5">Files</span>}
        //       ></TopTemplateHeader>
        //     </div>
        //   );
        // },
        includeProperties: ["trainingFiles"],
      },
      pk: "_id",
      properties: UniTrainingEventsProperties({ editable: false }),
    },
    trainingModules: {
      auth: "",
      apis: {
        view: {
          url: "/event/training/{id}",
          parameters: {
            headers: {},
            replace: {},
          },
          formatter: function (data) {
            return UniTrainingEventsFormatter(data);
          },
        },
      },
      template: "form",
      templateConfig: {
        useCard: false,
        colsTemplateCount: 12,
        headerTitle: "Training title",
        headerTitleIcon: "/uni/svg/trainingtemp.svg",
        headerSubtitle: "Files",
        events: {
          onSubmit: function (e) {
            e.preventDefault();
          },
        },
        header: trainingEventHeader,
        // header: function () {
        //   let data = this.state.data[0];
        //   return (
        //     <div className={"uniTopHeader "}>
        //       <TopTemplateHeader
        //         customClass="mb-4 uniFixedHeader"
        //         title={
        //           <React.Fragment>
        //             <img
        //               src="/uni/svg/trainingmain.svg"
        //               className="pr-3"
        //               style={{ width: "39px", paddingBottom: "5px" }}
        //             ></img>{" "}
        //             {UniFun.substring(data.title.value, 0, 25)}
        //             <div style={{ display: "inline-block" }}>
        //               {" "}
        //               {UniFun.userIsEligible({ forWhat: "default" })
        //                 ? data.editdropdown.component
        //                 : ""}
        //             </div>
        //           </React.Fragment>
        //         }
        //         subtitle={<span className="text-dark pl-5">Files</span>}
        //       ></TopTemplateHeader>
        //     </div>
        //   );
        // },
        includeProperties: ["trainingModules"],
      },
      pk: "_id",
      properties: UniTrainingEventsProperties({ editable: false }),
    },
    trainingSessions: {
      auth: "",
      apis: {
        view: {
          url: "/event/training/{id}",
          parameters: {
            headers: {},
            replace: {},
          },
          formatter: function (data) {
            return UniTrainingEventsFormatter(data);
          },
        },
      },
      template: "form",
      templateConfig: {
        useCard: false,
        colsTemplateCount: 12,
        headerTitle: "Training title",
        headerTitleIcon: "/uni/svg/trainingtemp.svg",
        headerSubtitle: "Files",
        events: {
          onSubmit: function (e) {
            e.preventDefault();
          },
        },
        header: trainingEventHeader,
        // header: function () {
        //   let data = this.state.data[0];
        //   return (
        //     <div className={"uniTopHeader "}>
        //       <TopTemplateHeader
        //         customClass="mb-4 uniFixedHeader"
        //         title={
        //           <React.Fragment>
        //             <img
        //               src="/uni/svg/trainingmain.svg"
        //               className="pr-3"
        //               style={{ width: "39px", paddingBottom: "5px" }}
        //             ></img>{" "}
        //             {UniFun.substring(data.title.value, 0, 25)}
        //             <div style={{ display: "inline-block" }}>
        //               {" "}
        //               {UniFun.userIsEligible({ forWhat: "default" })
        //                 ? data.editdropdown.component
        //                 : ""}
        //             </div>
        //           </React.Fragment>
        //         }
        //       ></TopTemplateHeader>
        //     </div>
        //   );
        // },
        includeProperties: ["trainingSessions"],
      },
      pk: "_id",
      properties: UniTrainingEventsProperties({ editable: false }),
    },
    trainingResults: {
      auth: "",
      apis: {
        view: {
          url: "/event/training/{_id}",
          parameters: {
            headers: {},
            replace: {},
          },
          formatter: function (data) {
            return UniTrainingEventsFormatter(data);
          },
        },
      },
      template: "form",
      templateConfig: {
        useCard: false,
        colsTemplateCount: 12,
        headerTitle: "Training title",
        headerTitleIcon: "/uni/svg/trainingtemp.svg",
        headerSubtitle: "Tests",
        events: {
          onSubmit: function (e) {
            e.preventDefault();
          },
        },
        header: trainingEventHeader,
        includeProperties: ["trainingResults"],
      },
      pk: "_id",
      properties: UniTrainingEventsProperties({ editable: true }),
    },
    trainingResultsParticipants: {
      auth: "",
      apis: {
        view: {
          //url: "/event/training/{_id}",
          url: "/event/exam/{_id}",
          parameters: {
            headers: {},
            replace: {
              _id: function () {
                return Fun.getURLPathValue(5);
              },
            },
          },
          formatter: function (data) {
            return UniTrainingEventsFormatter(data);
          },
        },
      },
      template: "form",
      templateConfig: {
        useCard: false,
        colsTemplateCount: 12,
        headerTitle: "Training title",
        headerTitleIcon: "/uni/svg/trainingtemp.svg",
        headerSubtitle: "Tests",
        events: {
          onSubmit: function (e) {
            e.preventDefault();
          },
        },
        //header: trainingEventHeader,
        header: function () {
          let data = this.state.data[0];

          return (
            <div className={"uniTopHeader "}>
              <TopTemplateHeader
                customClass="mb-4 uniFixedHeader"
                title={
                  <React.Fragment>
                    <img
                      src="/uni/svg/trainings/go_back.svg"
                      className="pr-3 pointer"
                      style={{ width: "39px", paddingBottom: "5px" }}
                      onClick={(e) => {
                        window.location.replace(
                          "/event/training/" +
                            Fun.getURLPathValue(3) +
                            "/results"
                        );
                      }}
                    ></img>{" "}
                    <span data-tip={data.title.value}>
                      {UniFun.substring(data.title.value, 0, 50)}
                    </span>
                    <div style={{ display: "inline-block" }}> </div>
                  </React.Fragment>
                }
                subtitle={
                  <span className="text-dark pl-5">
                    {UniFun.testAvailability(data.from.value, data.to.value)}
                  </span>
                }
              ></TopTemplateHeader>
            </div>
          );

          return "Dd";
        },
        includeProperties: ["trainingResultsParticipants"],
      },
      pk: "_id",
      properties: UniTrainingEventsProperties({ editable: true }),
    },
  },
};

export default UniTrainingEvents;

function UniTrainingEventsProperties(input) {
  let editable = input.editable;

  return {
    selectTemplate: {
      alias: "selectTemplate",
      control: "checkbox",
      label: "",
      editable: true,
      attributes: {
        className: "form-check-input  select-template-checkbox",
      },
      events: {
        onChange: function () {
          this.props.builder.builderTemplate.current.setState({
            action: this.props.data["_id"],
          });
        },
      },
    },
    _id: {
      alias: "_id",
      control: "hidden",
      label: "",
      editable: editable,
      attributes: {},
    },
    nextEvent: {
      alias: "nextEvent",
      control: "text",
    },
    title: {
      alias: "title",
      control: "text",
      label: "Name",
      placeholder: "Type training title",
      validations: "required",
      editable: editable,
      attributes: {
        className: "form-control",
      },
    },
    sessions: {
      alias: "sessions",
      control: "text",
      label: "Sessions",
      editable: false,
      attributes: {
        className: "form-control",
      },
    },
    description: {
      alias: "description",
      control: "editor",
      label: "Description",
      placeholder: "Write about the training",
      nonEditableDefaultValue: "No description available",
      editable: editable,
      attributes: {
        className: "form-control",
      },
    },

    trainingParticipants: {
      alias: "trainingParticipants",
      control: "module",
      editable: editable,
      placeholder: "Select the participants of the training",
      config: {
        module: "uniparticipantsandfilesevent",
        view: "participants",
        moduleParams: { type: "trainings" },
        parameters: {
          replace: {
            path: function () {
              return (
                "/event/training/" + Fun.getURLPathValue(3) + "/participants"
              );
            },
          },
        },
      },
    },
    trainingResults: {
      alias: "trainingResults",
      control: "module",
      editable: editable,
      config: {
        module: "unitestresults",
        view: function () {
          if (
            UniFun.userIsEligible({ forWhat: "admin" }) ||
            UniFun.userIsEligible({ forWhat: "viewer" })
          ) {
            return "trainingResultsAdmin";
          }
          return "trainingResults";
        },
        moduleParams: { type: "trainings" },
        parameters: {
          replace: {
            path: function () {
              return "/training/" + Fun.getURLPathValue(3) + "/tests";
            },
          },
        },
      },
    },
    adminExamHeader: {
      alias: "adminExamHeader",
      control: "ajax",
      config: {},
      customLayout: function () {
        return "Asd";
      },
      useCustomLayout: true,
    },

    trainingResultsParticipants: {
      alias: "trainingResultsParticipants",
      control: "module",
      editable: editable,
      config: {
        module: "unitaketest",
        view: "resultsTrainingParticipants",
        moduleParams: { type: "trainings" },
        parameters: {
          replace: {
            id: function () {
              return Fun.getURLPathValue(5);
            },
          },
        },
      },
    },
    participants: {
      alias: "participants",
      label: "Participants",
      placeholder: "Select the participants of the training",
      control: "tags",
      editable: editable,
      config: {
        idAttribute: "_id",
        nameAttribute: "fullName",
        apis: {
          search: {
            url: "/user/autocomplete/{query}?type=participants",
            formatter: function (args) {
              let formattedData = [];
              if (args) {
                let data = args.data;
                if (data) {
                  data.map((itm, idx) => {
                    if (itm["_id"]) {
                      let fullName =
                        itm["firstName"] +
                        " " +
                        itm["lastName"] +
                        " - " +
                        itm["email"];

                      if (itm.type == "group") {
                        fullName = itm["firstName"] + " - " + itm["lastName"];
                      }
                      formattedData.push({
                        _id: itm["_id"],
                        name: fullName,
                        group: itm.type,
                      });
                    } else {
                      if (UniFun.getURLPathValue(1) !== "conference") {
                        formattedData.push({ id: "", name: itm });
                      }
                    }
                  });
                }
              }
              return formattedData;
            },
            parameters: {
              replace: {
                query: "",
              },
            },
          },
        },
      },
      events: {
        onAddition: function (tag) {
          if (!tag._id) {
            // this.ref.current.input.current.input.current.blur();
            if (Fun.getURLPathValue(1) !== "conference") {
              if (UniFun.isEmail(tag.name)) {
                tag["_id"] = tag.name.trim();
                tag["name"] = tag.name.trim();
                const tags = [].concat(this.state.tags, tag);
                this.setState({ tags });
              } else {
                let splitParticipants = [];
                let notValidEmails = [];

                tag.name = tag.name.replaceAll(",", " ");
                tag.name = tag.name.replaceAll(";", " ");

                tag.name.split(" ").forEach((itm, idx) => {
                  if (itm) {
                    if (UniFun.isEmail(itm.trim())) {
                      splitParticipants.push({
                        id: itm.trim(),
                        name: itm.trim(),
                      });
                    } else {
                      notValidEmails.push(
                        <span className="d-block">{itm.trim()}</span>
                      );
                    }
                  }
                });

                if (notValidEmails.length > 0) {
                  Fun.createNotification({
                    message: (
                      <span>
                        The following inputs are not valid emails:<br></br>
                        {notValidEmails}
                      </span>
                    ),
                    type: "danger",
                  });
                }

                const tags = [].concat(this.state.tags, splitParticipants);
                this.setState({ tags });
              }
            }
          } else {
            const tags = [].concat(this.state.tags, tag);
            this.setState({ tags });
          }
        },
      },
      attributes: {
        className: "form-control",
      },
    },
    options: {
      alias: "options",
      label: <span className="d-block mb-2 text-strong">Options</span>,
      control: "group",
      config: {
        properties: {
          allowCameraForParticipants: {
            alias: "allowCameraForParticipants",
            label: "Allow camera for participants",
            control: "switch",
            defaultValue: true,
            editable: editable,
            config: {
              options: [
                { label: "Yes", value: true },
                { label: "No", value: false },
              ],
            },
            attributes: {
              className: "form-control",
            },
          },
          recording: {
            alias: "recording",
            label: "Record meeting",
            defaultValue: true,
            control: "switch",
            editable: editable,
            config: {
              options: [
                { label: "Yes", value: true },
                { label: "No", value: false },
              ],
            },
            useCustomLayout: true,
            customLayout: function (args) {
              if (localStorage.getItem("trainingRecording") == "true") {
                return (
                  <div className="form-group" style={{ marginBottom: "30px" }}>
                    <span>
                      <label className="text-dark">
                        {args.object.props.field.label}
                      </label>
                    </span>
                    {args.control}
                  </div>
                );
              }

              return "";
            },
            events: {
              onChange: function (checked) {
                if (checked) {
                  document
                    .getElementById("recordingMethod")
                    .classList.remove("d-none");
                } else {
                  document
                    .getElementById("recordingMethod")
                    .classList.add("d-none");
                }
              },
            },
            attributes: {
              className: "form-control",
            },
          },
          recordingMethod: {
            alias: "recordingMethod",
            label: "Select recording method",
            control: "select",
            editable: editable,
            useCustomLayout: true,
            customLayout: function (args) {
              if (localStorage.getItem("trainingRecording") == "true") {
                let classHidden = "";

                if (!args.object.props.data.options?.recording) {
                  classHidden = "d-none";
                }
                if (!args.object.props.data.options) {
                  classHidden = "";
                }
                return (
                  <div
                    className={"form-group " + classHidden}
                    style={{ marginBottom: "30px" }}
                    id="recordingMethod"
                  >
                    <span>
                      <label className="text-dark">
                        {args.object.props.field.label}
                      </label>
                    </span>
                    {args.control}
                  </div>
                );
              }

              return "";
            },
            config: {
              options: function () {
                let control = this;

                Fun.fetch({
                  url: {
                    url: "/recordingMethods",
                    parameters: {
                      replace: {},
                      headers: {},
                    },
                  },
                  callback: function (args) {
                    let output = [];

                    let data = args.data;

                    data.map((itm, idx) => {
                      output.push({
                        label: itm.label,
                        value: itm.value,
                      });
                    });

                    control.setState({ options: output });
                  },
                });

                return [];
              },
            },
            attributes: {
              className: "form-control",
            },
          },
        },
      },
    },
    modules: {
      alias: "modules",
      control: "tabs",
      label: <h3 className="text-black mt-4">Modules</h3>,
      tooltip:
        "A training is divided into modules. You can add as many modules as you need for the training.",
      events: {
        closeTab: function (idx) {
          let object = this;
          let tabIdx = idx;
          window["modal3"].setState({
            show: true,
            title: <h3>Delete Module?</h3>,
            body: (
              <div className="p-4">
                <p style={{ paddingLeft: "10px" }}>
                  Are you sure you want to delete module {idx + 1}? This action
                  will delete all sessions added to this module!
                </p>
              </div>
            ),
            footer: [
              <button
                type="button"
                className="btn btn-link border"
                onClick={() => {
                  window["modal3"].setState({ show: false, overflowY: "auto" });
                }}
              >
                Cancel
              </button>,
              <button
                type="button"
                className="btn btn-danger border"
                onClick={() => {
                  let tabs = [...object.state.tabs];
                  tabs.splice(tabIdx, 1);
                  object.setState({ tabs: tabs });
                  window["modal3"].setState({ show: false, overflowY: "auto" });
                }}
              >
                Delete
              </button>,
            ],
          });
        },
      },
      config: {
        canClose: true,
        tabs: [
          {
            labelNew: (
              <span>
                <img
                  src="/uni/svg/add.svg"
                  className="filter-link mr-2"
                  style={{ width: "14px", paddingBottom: "4px" }}
                ></img>{" "}
                Add new module
              </span>
            ),
            label: "Module {index}",
            includeProperties: ["_id", "title", "description", "sessions"],
            isNewTab: true,
          },
        ],
        properties: {
          _id: {
            alias: "_id",
            control: "hidden",
            label: "",
            editable: editable,
          },
          title: {
            alias: "title",
            control: "text",
            label: "Title",
            placeholder: "Type module title",
            validations: "required",
            editable: editable,
            attributes: {
              className: "form-control",
            },
          },
          description: {
            alias: "description",
            control: "editor",
            label: "Description",
            placeholder: "Write module introduction...",
            nonEditableDefaultValue: "No description available",
            editable: editable,
            attributes: {
              className: "form-control",
            },
          },

          sessions: {
            alias: "sessions",
            control: "tabs",
            label: <h3 className="text-black">Sessions</h3>,
            placeholder: "Type session title",
            tooltip:
              "Each module is divided into sessions. You can add as many sessions as you need for each module.",
            events: {
              closeTab: function (idx) {
                let object = this;
                let tabIdx = idx;
                window["modal3"].setState({
                  show: true,
                  title: <h3>Delete Session?</h3>,
                  body: (
                    <div className="p-4">
                      <p style={{ paddingLeft: "10px" }}>
                        Are you sure you want to delete session {idx + 1}? This
                        action will delete all learning material added to this
                        session!
                      </p>
                    </div>
                  ),
                  footer: [
                    <button
                      type="button"
                      className="btn btn-link border"
                      onClick={() => {
                        window["modal3"].setState({
                          show: false,
                          overflowY: "auto",
                        });
                      }}
                    >
                      Cancel
                    </button>,
                    <button
                      type="button"
                      className="btn btn-danger border"
                      onClick={() => {
                        let tabs = [...object.state.tabs];
                        tabs.splice(tabIdx, 1);
                        object.setState({ tabs: tabs });
                        window["modal3"].setState({
                          show: false,
                          overflowY: "auto",
                        });
                      }}
                    >
                      Delete
                    </button>,
                  ],
                });
              },
            },
            config: {
              canClose: true,
              tabs: [
                {
                  labelNew: (
                    <span>
                      <img
                        src="/uni/svg/add.svg"
                        className="filter-link mr-2"
                        style={{ width: "14px", paddingBottom: "4px" }}
                      ></img>{" "}
                      Add new session
                    </span>
                  ),
                  label: "Session {index}",
                  includeProperties: [
                    "_id",
                    "title",
                    "instructors",
                    "label",
                    "videoConference",
                    "videoPresentation",
                    "reading",
                    "exam",
                  ],
                  isNewTab: true,
                },
              ],
              properties: {
                _id: {
                  alias: "_id",
                  control: "hidden",
                  label: "",
                  editable: editable,
                },
                title: {
                  alias: "title",
                  control: "text",
                  label: "Title",
                  placeholder: "Type session title",
                  validations: "required",
                  editable: editable,
                  attributes: {
                    className: "form-control",
                  },
                },

                instructors: {
                  alias: "instructors",
                  label: "Instructors",
                  control: "tags",
                  editable: editable,
                  placeholder: "Select the instructors of the training",
                  config: {
                    idAttribute: "_id",
                    nameAttribute: "fullName",
                    apis: {
                      search: {
                        url: "/user/autocomplete/{query}?type=instructors",
                        formatter: function (args) {
                          let formattedData = [];
                          if (args) {
                            let data = args.data;

                            data?.map((itm, idx) => {
                              let fullName =
                                itm["firstName"] +
                                " " +
                                itm["lastName"] +
                                " - " +
                                itm["email"];
                              if (itm.type == "group") {
                                fullName =
                                  itm["firstName"] + " - " + itm["lastName"];
                              }
                              formattedData.push({
                                _id: itm["_id"],
                                name: fullName,
                                group: itm.type,
                              });
                            });
                          }
                          return formattedData;
                        },
                        parameters: {
                          replace: {
                            query: "",
                          },
                        },
                      },
                    },
                  },
                  attributes: {
                    className: "form-control",
                  },
                },
                label: {
                  alias: "label",
                  control: "label",
                  label: (
                    <h6 className="text-black pt-3 pb-3">
                      <strong>Learning Material</strong>
                    </h6>
                  ),
                  tooltip:
                    "In each session you can add the learning material that are suitable for you.",
                },
                videoConference: {
                  alias: "videoConference",
                  control: "multiple",
                  label: "Video Conference",
                  config: {
                    addNewLabel: (
                      <span>
                        <img
                          src="/uni/svg/add.svg"
                          className="filter-link mr-2"
                          style={{ width: "11px", paddingBottom: "5px" }}
                        ></img>{" "}
                        Add Video Conference
                      </span>
                    ),
                    addNewFunction: function () {
                      UniFun.trainingNodes({
                        module: "univideoconference",
                        callback: function (data) {
                          let currentData =
                            window["modal2"].state.targetComponent.state.data;

                          let from = UniFun.fromToDateFormat(
                            data.fromToDateTime[0].fromDateTime[0].fromDate,
                            data.fromToDateTime[0].fromDateTime[0].fromTime
                          );

                          let to = UniFun.fromToDateFormat(
                            data.fromToDateTime[0].toDateTime[0].toDate,
                            data.fromToDateTime[0].toDateTime[0].toTime
                          );

                          let jsonData = {
                            title: data.title,
                            from: from,
                            to: to,
                            reminders: [JSON.parse(data.reminders)],
                          };

                          currentData.push(jsonData);
                          window["modal2"].state.targetComponent.setState({
                            data: currentData,
                          });
                        },
                        view: "eventDetail",
                        control: this,
                        modalTitle: "Video Conference",
                        mimetype: "videoconf",
                        inputData: { data: [{}] },
                      });
                    },
                    properties: {
                      videoConference: {
                        alias: "",
                        control: "hidden",
                        modalModule: "univideoconference",
                        viewModule: "eventDetail",
                        showSchedule: true,
                        modalTitle: "Video Conference",
                        mimetype: "videoconf",
                        callback: function (data) {
                          let from = UniFun.fromToDateFormat(
                            data.fromToDateTime[0].fromDateTime[0].fromDate,
                            data.fromToDateTime[0].fromDateTime[0].fromTime
                          );

                          let to = UniFun.fromToDateFormat(
                            data.fromToDateTime[0].toDateTime[0].toDate,
                            data.fromToDateTime[0].toDateTime[0].toTime
                          );

                          let jsonData = {
                            title: data.title,
                            from: from,
                            to: to,
                            _id: data._id,
                            reminders: [JSON.parse(data.reminders)],
                          };

                          window["modal2"].state.targetComponent.setState({
                            defaultValue: jsonData,
                          });
                        },
                        customLayout: UniFun.trainingNodesCustomLayout,
                        useCustomLayout: true,
                      },
                    },
                  },
                },
                videoPresentation: {
                  alias: "videoPresentation",
                  control: "multiple",
                  label: "Video Presentation",
                  config: {
                    addNewLabel: (
                      <span>
                        <img
                          src="/uni/svg/add.svg"
                          className="filter-link mr-2"
                          style={{ width: "11px", paddingBottom: "5px" }}
                        ></img>{" "}
                        Add Video Presentation
                      </span>
                    ),
                    addNewFunction: function () {
                      UniFun.trainingNodes({
                        module: "univideopresentation",
                        view: "detail",
                        control: this,
                        modalTitle: "Video Presentation",
                        inputData: { data: [{}] },
                      });
                    },
                    properties: {
                      videoPresentation: {
                        alias: "",
                        modalModule: "univideopresentation",
                        viewModule: "detail",
                        control: "hidden",
                        modalTitle: "Video Presentation",
                        customLayout: UniFun.trainingNodesCustomLayout,
                        useCustomLayout: true,
                      },
                    },
                  },
                },
                reading: {
                  alias: "reading",
                  control: "multiple",
                  label: "Reading",
                  config: {
                    addNewLabel: (
                      <span>
                        <img
                          src="/uni/svg/add.svg"
                          className="filter-link mr-2"
                          style={{ width: "11px", paddingBottom: "5px" }}
                        ></img>{" "}
                        Add Reading
                      </span>
                    ),
                    addNewFunction: function () {
                      UniFun.trainingNodes({
                        module: "unireadings",
                        view: "detail",
                        control: this,
                        modalTitle: "Reading",
                        inputData: { data: [{}] },
                      });
                    },
                    properties: {
                      reading: {
                        alias: "",
                        modalModule: "unireadings",
                        viewModule: "detail",
                        control: "hidden",
                        modalTitle: "Reading",
                        customLayout: UniFun.trainingNodesCustomLayout,
                        useCustomLayout: true,
                      },
                    },
                  },
                },
                exam: {
                  alias: "exam",
                  control: "multiple",
                  label: "Test",
                  config: {
                    addNewLabel: (
                      <span>
                        <img
                          src="/uni/svg/add.svg"
                          className="filter-link mr-2"
                          style={{ width: "11px", paddingBottom: "5px" }}
                        ></img>{" "}
                        Add Test
                      </span>
                    ),
                    addNewFunction: function () {
                      window["modal2"].setState({
                        title: <h3>Schedule test</h3>,
                        size: "lg",
                        targetComponent: this,
                        footer: UniFun.modalCommonFooter("modal2", "Save"),
                        callback: function (data) {
                          let exam = JSON.parse(data["exam"]);
                          let currentData =
                            window["modal2"].state.targetComponent.state.data;

                          let jsonData = {
                            _id: exam.id,
                            title: exam.title,
                            evaluators: JSON.parse(data.evaluators),
                            from: UniFun.fromToDateFormat(
                              data.fromToDateTime[0].fromDateTime[0].fromDate,
                              data.fromToDateTime[0].fromDateTime[0].fromTime
                            ),
                            to: UniFun.fromToDateFormat(
                              data.fromToDateTime[0].toDateTime[0].toDate,
                              data.fromToDateTime[0].toDateTime[0].toTime
                            ),
                            examDuration: data.examDuration[0],
                            participants: data.participants,
                            reminders: [JSON.parse(data.reminders)],
                          };

                          currentData.push(jsonData);
                          window["modal2"].state.targetComponent.setState({
                            data: currentData,
                          });
                        },
                        show: true,
                        control: this,
                        body: (
                          <Builder
                            module="unitrainingtests"
                            view="trainingEventDetail"
                            ref={(refComponent) => {
                              window["modal2body"] = refComponent;
                            }}
                          ></Builder>
                        ),
                      });
                    },
                    properties: {
                      exam: {
                        alias: "",
                        control: "hidden",
                        showSchedule: true,
                        modalModule: function (inputData) {
                          let questions = inputData.data[0].questions;
                          if (questions) {
                            return "unitestevent";
                          }
                          return "unitrainingtests";
                        },
                        viewModule: function (inputData) {
                          let questions = inputData.data[0].questions;
                          if (questions) {
                            return "editTrainingTest";
                          }
                          return "trainingEventDetail";
                        },
                        // modalModule: "unitestevent",
                        // viewModule: "editTrainingTest",
                        mimetype: "testfill",
                        modalTitle: "Test",
                        mimeTypeClass: "filter-somon",
                        callback: function (data) {
                          // let exam = JSON.parse(data["exam"]);

                          let exam = data;

                          if (data["exam"]) {
                            exam = JSON.parse(data["exam"]);
                            exam._id = exam.id;
                          }

                          // let currentData =
                          //   window["modal2"].state.targetComponent.state.data;

                          try {
                            let jsonData = {
                              _id: exam._id,
                              title: exam.title,
                              from: UniFun.fromToDateFormat(
                                data.fromToDateTime[0].fromDateTime[0].fromDate,
                                data.fromToDateTime[0].fromDateTime[0].fromTime
                              ),
                              to: UniFun.fromToDateFormat(
                                data.fromToDateTime[0].toDateTime[0].toDate,
                                data.fromToDateTime[0].toDateTime[0].toTime
                              ),
                              examDuration: Array.isArray(data.examDuration)
                                ? data.examDuration[0]
                                : data.examDuration,
                              participants: [],
                              evaluators: JSON.parse(data.evaluators),
                              reminders: [JSON.parse(data.reminders)],
                            };

                            if (exam.questions) {
                              if (exam.questions.length > 0) {
                                jsonData["instructions"] = exam.instructions;
                                jsonData["passingGrade"] = exam.passingGrade;
                                jsonData["questions"] = exam.questions;
                              }
                            }
                            // let jsonData = {
                            //   _id: exam.id,
                            //   title: exam.title,
                            //   from: UniFun.fromToDateFormat(
                            //     data.fromToDateTime[0].fromDateTime[0].fromDate,
                            //     data.fromToDateTime[0].fromDateTime[0].fromTime
                            //   ),
                            //   to: UniFun.fromToDateFormat(
                            //     data.fromToDateTime[0].toDateTime[0].toDate,
                            //     data.fromToDateTime[0].toDateTime[0].toTime
                            //   ),
                            //   examDuration: data.examDuration[0],
                            //   participants: data.participants,
                            //   reminders: [JSON.parse(data.reminders)],
                            // };

                            window["modal2"].state.targetComponent.setState({
                              defaultValue: jsonData,
                            });
                          } catch (e) {
                            console.log(e);
                          }
                        },
                        customLayout: UniFun.trainingNodesCustomLayout,
                        useCustomLayout: true,
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
    createdBy: {
      alias: "createdBy",
      control: "text",
      label: "Created by",
      editable: editable,
      attributes: {
        className: "form-control",
      },
    },
    modified: {
      alias: "modified",
      control: "datetime",
      label: "Last modified",
      editable: editable,
      attributes: {
        className: "form-control",
      },
    },
    edit: {
      alias: "edit",
      control: "button",
      caption: "Edit",
      apiPath: "",
      auth: "",
      validations: "",
      events: {
        onClick: function () {
          let params = {
            replace: {
              id: this.props.data._id,
            },
          };

          window["viewport"].setState({
            loaded: true,
            main: (
              <Builder
                module="meetings"
                view="detail"
                parameters={params}
                key={Fun.uuid()}
              />
            ),
          });
        },
      },
      attributes: {
        className: "btn btn-primary",
      },
    },
    newTemplate: {
      alias: "newTemplate",
      control: "button",
      caption: "New Template",
      events: {
        onClick: function () {
          let builder = this.props.builder;

          window["modal"].setState({
            title: <h3>New training template</h3>,
            size: "xl",
            targetComponent: this,
            callback: function () {
              window["modal"].setState({ show: false, overflowY: "auto" });
              Fun.createNotification({
                type: "success",
                message: "Update update was successful!",
              });
              builder.rerunComponent();
            },
            footer: UniFun.modalCommonFooter("modal", "Create"),
            show: true,
            body: (
              <Builder
                module="UniTrainingEvents"
                view="add"
                key={Fun.uuid()}
                ref={(refComponent) => {
                  window["modalbody"] = refComponent;
                }}
              ></Builder>
            ),
          });
        },
      },
      attributes: {
        className: "btn btn-primary",
      },
    },
    results: {
      alias: "results",
      control: "module",
      config: {
        module: "unitaketest",
        view: "trainingresults",
        parameters: {
          replace: {
            id: function () {
              return Fun.getURLPathValue(9);
            },
          },
        },
      },
    },
    from: {
      alias: "from",
      control: "hidden",
    },
    to: {
      alias: "to",
      control: "hidden",
    },
    submit: {
      alias: "submit",
      control: "submit",
      caption: "submit",
      apiPath: "",
      auth: "",
      validations: "",
      events: {
        onChange: function () {},
      },
      attributes: {
        className: "btn btn-primary float-right",
      },
    },
    trainingFiles: {
      alias: "trainingFiles",
      control: "module",
      editable: editable,
      config: {
        module: "uniparticipantsandfilesevent",
        view: "files",
        moduleParams: { type: "training" },
        parameters: {
          replace: {
            path: function () {
              return "/training/" + Fun.getURLPathValue(3) + "/modules/";
            },
          },
        },
      },
    },
    trainingModules: {
      alias: "trainingModules",
      control: "module",
      editable: editable,
      config: {
        module: "uniparticipantsandfilesevent",
        view: "trainingModules",
        moduleParams: { type: "training" },
        parameters: {
          replace: {
            id: function () {
              return Fun.getURLPathValue(3);
            },
          },
        },
      },
    },
    trainingSessions: {
      alias: "trainingSessions",
      control: "module",
      editable: editable,
      config: {
        module: "uniparticipantsandfilesevent",
        view: "trainingSessions",
        moduleParams: { type: "training" },
        parameters: {
          replace: {
            id: function () {
              return Fun.getURLPathValue(3);
            },
            moduleid: function () {
              return Fun.getURLPathValue(6);
            },
          },
        },
      },
    },
    trainingFiles: {
      alias: "trainingFiles",
      control: "module",
      editable: editable,
      config: {
        module: "unifiles",
        view: "trainingFiles",
        moduleParams: { type: "training" },
        parameters: {
          replace: {
            id: function () {
              return Fun.getURLPathValue(8);
            },
          },
        },
      },
    },
    editdropdown: {
      alias: "editdropdown",
      control: "dropdownbutton",
      caption: (
        <img src="/uni/svg/more-options.svg" style={{ width: "14px" }}></img>
      ),
      editable: true,
      config: {
        removeToggleIcon: true,
        options: {
          edit: {
            label: "Edit",
            auth: function () {
              return (
                UniFun.userIsEligible({ forWhat: "default" }) ||
                UniFun.userIsEligible({
                  forWhat: "viewer",
                })
              );
            },
            events: {
              onClick: function () {
                let builder = this.props.builder;

                let params = {
                  replace: {
                    id: this.props.value,
                  },
                };
                window["modal"].setState({
                  title: <h3>Edit training</h3>,
                  size: "xl",
                  targetComponent: this,
                  footer: UniFun.modalCommonFooter("modal", "Update"),
                  callback: function () {
                    window["modal"].setState({
                      show: false,
                      overflowY: "auto",
                    });
                    builder.rerunComponent();
                  },
                  show: true,
                  body: (
                    <Builder
                      module="unitrainingevents"
                      view="editEvent"
                      parameters={params}
                      ref={(refComponent) => {
                        window["modalbody"] = refComponent;
                      }}
                    ></Builder>
                  ),
                });
              },
            },
          },
          delete: {
            label: <span className="text-danger">Delete</span>,
            auth: function () {
              return (
                UniFun.userIsEligible({ forWhat: "default" }) ||
                UniFun.userIsEligible({
                  forWhat: "viewer",
                })
              );
            },
            events: {
              onClick: function () {
                let builder = this.props.builder;
                deleteTrainingEvent(this.props.value, builder);
              },
            },
          },
        },
      },
      attributes: {
        className: "btn btn-link m-2 ",
      },
    },
  };
}

function UniTrainingEventsFormatter(data) {
  let formattedData = [];
  if (data) {
    if (data.data) {
      data.data.map((itm, idx) => {
        let formattedRecord = {};
        try {
          formattedRecord["_id"] = itm._id;
          formattedRecord["results"] = itm._id;
          formattedRecord["selectTemplate"] = itm._id;
          formattedRecord["options"] = {};
          try {
            formattedRecord["options"] = itm.options;
          } catch (e) {}

          formattedRecord["title"] = itm.title;
          formattedRecord["participants"] = UniFun.participantsFormatter(
            itm.participants
          );
          formattedRecord["editdropdown"] = itm._id;
          formattedRecord["from"] = itm.from;
          formattedRecord["to"] = itm.to;

          formattedRecord["createdBy"] = itm.createdBy;
          formattedRecord["modified"] = itm.updatedAt;
          formattedRecord["description"] = itm.description;
          formattedRecord["nextEvent"] = itm.nextEvent ? itm.nextEvent : "";

          formattedRecord["modules"] = itm.modules ? itm.modules : [];
        } catch (e) {
          console.log(e);
        }
        formattedData.push(formattedRecord);
      });
    }
  }
  return formattedData;
}
