import React, { Component } from "react";
import Fun from "../../lib/functions";
import { withRouter } from "react-router-dom";

class Button extends Component {
  state = {
    name: "",
    id: "",
    placeholder: "",
    attributes: "",
    value: "",
    defaultValue: "",
    loaded: false,
    editable: true,
    component: null,
    attributes: "",
    events: "",
    caption: "",
  };

  constructor(props) {
    super(props);

    let events = {};

    this.init = function () {};

    if (this.props.field.events) {
      Object.keys(this.props.field.events).map((itm, idx) => {
        this[itm] = this.props.field.events[itm];
        this[itm] = this[itm].bind(this);
        events[itm] = this[itm];
      });
    }
    this.state = {
      events: events,
    };
  }

  validator() {}

  componentDidMount() {
    this.setState({
      defaultValue: this.props.value,
      attributes: this.props.field.attributes,
      caption: this.props.field.caption,
      customLayout: this.props.field.customLayout,
      useCustomLayout:
        typeof this.props.field.useCustomLayout !== "undefined"
          ? this.props.field.useCustomLayout
          : true,
    });

    this.init();
  }

  editable() {}

  render() {
    let output = (
      <button type="button" {...this.state.attributes} {...this.state.events}>
        {this.state.caption}
      </button>
    );

    if (this.state.customLayout && this.state.useCustomLayout == true) {
      this.state.customLayout = this.state.customLayout.bind(this);
      return this.state.customLayout({ control: output, object: this });
    }

    return output;
  }
}

export default withRouter(Button);
