import React, { Component } from "react";
import Controls from "../controls";
import Themes from "../../themes/themes";
import Fun from "../../lib/functions";

class Tabs extends Component {
  state = {
    name: "",
    id: "",
    placeholder: "",
    attributes: "",
    value: "",
    defaultValue: "",
    loaded: false,
    editable: true,
    component: null,
    attributes: "",
    events: "",
    customLayout: null,
    useCustomLayout: true,
    tabs: [],
  };

  constructor(props) {
    super(props);

    this.closeTab = this.closeTab.bind(this);

    let events = {};
    if (this.props.field.events) {
      Object.keys(this.props.field.events).map((itm, idx) => {
        this[itm] = this.props.field.events[itm];
        this[itm] = this[itm].bind(this);
      });
    }
    this.state = {
      events: events,
      tabs: [],
    };

    this.addTab = this.addTab.bind(this);
  }

  componentDidMount() {
    let controlConfig = this.props.field.config;
    let dataTabs = controlConfig.tabs;
    let defaultValue = this.props.value ? this.props.value : [{}];
    let data = this.props.data;
    let controlName = this.props.field.alias;
    let simpleTabs = controlConfig.simpleTabs
      ? controlConfig.simpleTabs
      : false;

    if (!simpleTabs) {
      defaultValue.push({});
    }

    let controlFields = controlConfig.properties;
    let tabs = [];

    let namePrefix = this.props.namePrefix ? this.props.namePrefix : "";

    defaultValue.map((rec) => {
      dataTabs.map((tab, idx) => {
        let itm = { ...tab };

        let controls = [];
        if (itm.includeProperties) {
          itm.includeProperties.map((prp, iprp) => {
            if (itm.includeProperties.indexOf(prp) !== false) {
              let Control = Controls[controlFields[prp].control];

              let controlValue = rec[prp] ? rec[prp] : "";

              if (controlFields[prp].control == "tabs") {
                if (!rec[prp]) {
                  controlValue = [];
                }
              }

              let field = { ...controlFields[prp] };
              let alias = field["alias"];

              if (!simpleTabs) {
                field["alias"] = controlName + "[" + idx + "][" + alias + "]";
                namePrefix = controlName + "[" + idx + "]";
              }

              field["validator"] = this.props.field.validator;

              const ControlComponent = (
                <Control
                  field={field}
                  key={Fun.uuid()}
                  value={controlValue}
                  namePrefix={namePrefix}
                  data={data}
                  builder={this.props.builder}
                ></Control>
              );
              let ControlTemplate = Themes[window["c_theme"]]["control"];

              controls.push({
                alias: alias,
                control: (
                  <ControlTemplate
                    key={Fun.uuid()}
                    control={{
                      field: field,
                      component: ControlComponent,
                    }}
                  ></ControlTemplate>
                ),
              });
            }
          });
        }
        itm["controls"] = [];
        itm["controls"] = controls;
        tabs.push(itm);
      });
    });

    this.setState({
      tabs: tabs,
      customLayout: this.props.field.customLayout,
      canAdd: this.props.field.config.canAdd ? true : false,
      canClose: this.props.field.config.canClose ? true : false,
      simpleTabs: simpleTabs,
      data: data,
      newTab: this.props.field.config.newTab,
      controlName: controlName,
      useCustomLayout:
        typeof this.props.field.useCustomLayout !== "undefined"
          ? this.props.field.useCustomLayout
          : true,
    });
  }

  addTab(hrefid) {
    let newTab = {};

    let tabs = [];

    this.state.tabs.map((itm, idx) => {
      if (itm.isNewTab) {
        newTab = { ...itm };
      }
      itm.isNewTab = false;
      tabs.push(itm);
    });

    newTab["isNewTab"] = true;

    this.setState({ tabs: [...tabs, newTab] });
  }

  closeTab(idx) {
    let tabs = [...this.state.tabs];
    tabs.splice(idx, 1);
    this.setState({ tabs: tabs });
  }

  render() {
    let uuids = [];

    let tabs = this.state.tabs.map((itm, idx) => {
      let active = idx == 0 ? "active" : "";

      uuids.push(Fun.uuid());

      return (
        <li className="nav-item  " key={"likey-" + idx}>
          {itm.isNewTab && idx >= this.state.tabs.length - 1 ? (
            <button
              className={" btn-link nav-link bg-white "}
              onClick={(e) => {
                this.addTab("contenthref-" + uuids[idx]);
              }}
              style={{ color: "#321fdb!important" }}
            >
              {itm.labelNew}
            </button>
          ) : (
            <React.Fragment>
              <a
                className={" text-black nav-link text-strong " + active}
                data-toggle="tab"
                href={"#content-" + uuids[idx]}
                role="tab"
                aria-selected="true"
                key={"ahrefkey-" + idx}
              >
                {itm.label.replace("{index}", idx + 1)}{" "}
                {this.state.canClose ? (
                  <span onClick={(e) => this.closeTab(idx)} className="ml-5">
                    {/* <i className="bi bi-x"></i> */}
                    <img
                      src="/uni/svg/close.svg"
                      style={{ width: "11px", paddingBottom: "3px" }}
                    />
                  </span>
                ) : (
                  ""
                )}
              </a>
            </React.Fragment>
          )}
        </li>
      );
    });

    let content = this.state.tabs.map((itm, idx) => {
      let active = idx == 0 ? "show active" : "";

      if (itm.isNewTab && idx >= this.state.tabs.length - 1) {
        return;
      }
      return (
        <div
          className={"tab-pane fade p-3 " + active}
          id={"content-" + uuids[idx]}
          role="tabpanel"
          key={"contentkey-" + idx}
        >
          {itm.controls.map((itmCtrl, idxCtrl) => {
            if (itmCtrl) {
              if (!this.state.simpleTabs) {
                itmCtrl.control.props.control.field.alias =
                  this.state.controlName +
                  "[" +
                  idx +
                  "][" +
                  itmCtrl.alias +
                  "]";
              }

              return itmCtrl.control;
            }
          })}
        </div>
      );
    });

    return (
      <React.Fragment>
        <ul className="nav nav-tabs mt-3" role="tablist">
          {tabs}
        </ul>
        <div className="tab-content border border-top-0 mt-3">{content}</div>
      </React.Fragment>
    );
  }
}

export default Tabs;
