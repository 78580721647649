import React, { Component } from "react";
import Control from "./control";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
moment.locale("en", {
  week: {
    dow: 1,
    doy: 1,
  },
});
const localizer = momentLocalizer(moment);

class Agenda extends Component {
  state = {
    data: [],
    loaded: false,
    filters: ["exams", "trainings", "meetings", "streams"],
    colsTemplateCount: 12,
    colsFormCount: 12,
    agendaConfig: {
      localizer: localizer,
      popup: true,
      titleAccessor: "titleAgendaEvent",
      view: "month",
      eventPropGetter: function (event, start, end, isSelected) {
        var style = {
          backgroundColor: event.backgroundColor,
          borderRadius: "0px",

          color: "black",
          border: "0px",
          display: "block",
        };
        return {
          style: style,
        };
      },
      views: {
        month: true,
        week: true,
        day: true,
      },

      date: new Date(),
      events: this.props.data[0] ? [...this.props.data[0].events.value] : [],
    },
  };

  constructor(props) {
    super(props);
    let builder = this.props.builder.state;
    let templateConfig = builder.module.templateConfig;
    let agendaConfig = templateConfig.agendaConfig;
    this.onNavigate = function () {};
    this.onSelectEvent = function () {};
    if (templateConfig.events) {
      Object.keys(templateConfig.events).map((itm, idx) => {
        this[itm] = templateConfig.events[itm];
        this[itm] = this[itm].bind(this);
      });
    }

    if (agendaConfig) {
      if (agendaConfig.events) {
        Object.keys(agendaConfig.events).map((itm, idx) => {
          this[itm] = agendaConfig.events[itm];
          this[itm] = this[itm].bind(this);
        });
      }
    }

    this.agendaWidget = React.createRef();
  }

  componentDidMount() {
    let builder = this.props.builder.state;
    let templateConfig = builder.module.templateConfig;
    let templateTop = templateConfig.templateTop
      ? templateConfig.templateTop
      : () => {};
    this["templateTop"] = templateTop.bind(this);
    let templateBottom = templateConfig.templateBottom
      ? templateConfig.templateBottom
      : () => {};
    this["templateBottom"] = templateBottom.bind(this);
    let templateLeft = templateConfig.templateLeft
      ? templateConfig.templateLeft
      : () => {};
    this["templateLeft"] = templateLeft.bind(this);
    let templateRight = templateConfig.templateRight
      ? templateConfig.templateRight
      : () => {};
    this["templateRight"] = templateRight.bind(this);
    let panelTop = templateConfig.panelTop ? templateConfig.panelTop : () => {};
    this["panelTop"] = panelTop.bind(this);
    let panelBottom = templateConfig.panelBottom
      ? templateConfig.panelBottom
      : () => {};
    this["panelBottom"] = panelBottom.bind(this);
    let panelLeft = templateConfig.panelLeft
      ? templateConfig.panelLeft
      : () => {};
    this["panelLeft"] = panelLeft.bind(this);
    let panelRight = templateConfig.panelRight
      ? templateConfig.panelRight
      : () => {};
    this["panelRight"] = panelRight.bind(this);
    let header = templateConfig.header ? templateConfig.header : () => {};
    this["header"] = header.bind(this);

    let colsTemplateCount = templateConfig.colsTemplateCount
      ? templateConfig.colsTemplateCount
      : this.state.colsTemplateCount;
    let colsFormCount = templateConfig.colsFormCount
      ? templateConfig.colsFormCount
      : this.state.colsFormCount;

    this.setState({
      data: this.props.data,
      loaded: true,
      colsTemplateCount: colsTemplateCount,
      colsFormCount: colsFormCount,
    });
  }

  output() {
    let out = [];

    this.state.data.map((itm, idx) => {
      Object.keys(itm).map((objItm, objIdx) => {
        if (itm[objItm]["showPropertyOnTemplate"]) {
          let ControlUI = (
            <Control control={itm[objItm]} key={idx + "-" + objIdx}></Control>
          );
          out.push(ControlUI);
        }
      });
    });

    return out;
  }

  render() {
    if (this.state.loaded) {
      return (
        <React.Fragment>
          {this.header()}
          {this.panelTop()}
          <div className="container-fluid h-100 p-0 pr-3 pl-3 ">
            <div className="row vh-100 p-0">
              {this.panelLeft()}
              <div className={"p-0 col-" + this.state.colsTemplateCount}>
                {this.templateTop()}
                <div className="card h-100 border-0 m-0">
                  <Calendar
                    {...this.state.agendaConfig}
                    onNavigate={this.onNavigate}
                    onSelectEvent={this.onSelectEvent}
                    // view={this.state.view}
                    // popup={false}
                    messages={{
                      showMore: (target) => (
                        <span
                          className="ml-2"
                          role="presentation"
                          onClick={(e) => {
                            e.preventDefault();
                            this.setState({
                              calendarOverlay: true,
                              currentTitleData: {},
                            });
                          }}
                        >
                          {" "}
                          ...{target}
                        </span>
                      ),
                    }}
                    ref={this.agendaWidget}
                  />
                </div>
                {this.templateBottom()}
              </div>
              {this.panelRight()}
            </div>
          </div>
          {this.panelBottom()}
        </React.Fragment>
      );
    } else {
      return "loading...";
    }
  }
}

export default Agenda;
