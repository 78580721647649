import React, { Component } from "react";

class Hidden extends Component {
  state = {
    name: "",
    id: "",
    placeholder: "",
    attributes: "",
    value: "",
    defaultValue: "",
    loaded: false,
    editable: true,
    component: null,
    attributes: "",
    events: "",
    customLayout: null,
    useCustomLayout: true,
    editable: true,
  };

  constructor(props) {
    super(props);

    let events = {};
    if (this.props.field.events) {
      Object.keys(this.props.field.events).map((itm, idx) => {
        this[itm] = this.props.field.events[itm];
        this[itm] = this[itm].bind(this);
        events[itm] = this[itm];
      });
    }

    let defaultValue = "";
    if (this.props.field.defaultValue) {
      defaultValue = this.props.field.defaultValue;
    }

    this.state = {
      events: events,
      defaultValue: this.props.value ? this.props.value : defaultValue,
    };
  }

  validator() {}

  componentDidMount() {
    this.setState({
      attributes: this.props.field.attributes,
      name: this.props.field.alias,
      editable:
        this.props.field.editable != null
          ? this.props.field.editable
          : this.state.editable,
      customLayout: this.props.field.customLayout,
      useCustomLayout:
        typeof this.props.field.useCustomLayout !== "undefined"
          ? this.props.field.useCustomLayout
          : true,
    });
  }

  render() {
    let defaultValue = this.state.defaultValue;

    if (typeof defaultValue === "object") {
      defaultValue = JSON.stringify(this.state.defaultValue);
    }

    let output = (
      <input
        type="hidden"
        defaultValue={defaultValue}
        name={this.state.name}
        {...this.state.attributes}
        {...this.state.events}
      ></input>
    );
    if (this.state.customLayout && this.state.useCustomLayout == true) {
      this.customLayout = this.state.customLayout.bind(this);
      return this.customLayout({ control: output, object: this });
    }

    return output;
  }
}

export default Hidden;
