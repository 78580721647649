import React, { Component } from "react";
import Fun from "../../lib/functions";

class DropdownButton extends Component {
  state = {
    name: "",
    id: "",
    placeholder: "",
    attributes: "",
    value: "",
    defaultValue: "",
    loaded: false,
    editable: true,
    component: null,
    attributes: "",
    events: "",
    caption: "",
  };

  constructor(props) {
    super(props);

    let events = {};
    if (this.props.field.events) {
      Object.keys(this.props.field.events).map((itm, idx) => {
        this[itm] = this.props.field.events[itm];
        this[itm] = this[itm].bind(this);
        events[itm] = this[itm];
      });
    }
    this.state = {
      events: events,
    };

    this.options = this.options.bind(this);
  }

  componentDidMount() {
    this.setState({
      defaultValue: this.props.value,
      attributes: this.props.field.attributes,
      caption: this.props.field.caption,
    });
  }

  options() {
    let output = [];
    if (this.props.field.config) {
      if (this.props.field.config.options) {
        Object.keys(this.props.field.config.options).map((itm, idx) => {
          let onClickFun =
            this.props.field.config.options[itm].events["onClick"];
          let isEligible = true;
          if (typeof this.props.field.config.options[itm].auth === "function") {
            isEligible = this.props.field.config.options[itm].auth();
          }
          if (isEligible) {
            if (this.props.field.config.options[itm].checkbox) {
              output.push(
                <div className="input-group pl-3 dropdown-item">
                  <input
                    type="checkbox"
                    style={{ marginTop: "5px" }}
                    onClick={onClickFun.bind(this)}
                    defaultChecked={
                      this.props.field.config.options[itm].checked
                    }
                  />
                  <label className="ml-2">
                    {this.props.field.config.options[itm].label}
                  </label>
                </div>
              );
            } else {
              output.push(
                <button
                  type="button"
                  className="dropdown-item"
                  onClick={onClickFun.bind(this)}
                >
                  {this.props.field.config.options[itm].label}
                </button>
              );
            }
          }
        });
      }
    }
    return output;
  }

  render() {
    let attributes = { ...this.state.attributes };

    attributes["className"] = attributes["className"]
      ? attributes["className"]
      : "dropdown-toggle";

    return (
      <div className="dropdown">
        <button
          type="button"
          {...attributes}
          {...this.state.events}
          data-toggle="dropdown"
        >
          {this.state.caption}
        </button>
        <div className="dropdown-menu">{this.options()}</div>
      </div>
    );
  }
}

export default DropdownButton;
