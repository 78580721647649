import React, { Component } from "react";
import Controls from "../controls/controls";
import ReactDOM from "react-dom";
import { v4 as uuidv4 } from "uuid";
import parse from "html-react-parser";
import { AlertList } from "react-bs-notifier";
import axios from "axios";
import UniFun from "../modules/uni/functions";
import SimpleReactValidator from "simple-react-validator";

Array.prototype.remove = function () {
  var what,
    a = arguments,
    L = a.length,
    ax;
  while (L && this.length) {
    what = a[--L];
    while ((ax = this.indexOf(what)) !== -1) {
      this.splice(ax, 1);
    }
  }
  return this;
};

const sleep = (ms) => {
  const startPoint = new Date().getTime();
  while (new Date().getTime() - startPoint <= ms) {
    /* wait */
  }
};

const Fun = {
  notifications: [],
  createNotificationFromResponse(data, onlyfail = false) {
    if (data.exceptions) {
      data.exceptions.map((itm, idx) => {
        Fun.createNotification({
          message: itm.errorDescription,
          type: "danger",
        });
      });
    }
    if (!onlyfail) {
      if (data.message) {
        Fun.createNotification({ message: data.message, type: "success" });
      }
    }
  },
  createNotification(args) {
    if (args.notification) {
      const idx = Fun.notifications.indexOf(args.notification);

      if (idx >= 0) {
        Fun.notifications = [
          ...Fun.notifications.slice(0, idx),
          ...Fun.notifications.slice(idx + 1),
        ];
      }
    } else {
      Fun.notifications.push({
        id: new Date().getTime(),
        type: args.type,
        message: <span className="pr-2">{args.message}</span>,
      });
    }
    ReactDOM.render(
      <AlertList
        alerts={Fun.notifications}
        timeout={5000}
        onDismiss={(item) => {
          Fun.createNotification({ notification: item });
        }}
      />,
      document.getElementById("notification")
    );
  },
  parse(value) {
    if (value) {
      value = value.replaceAll("<p><br></p>", "");
      return parse(value);
    }
    return value;
  },
  fetch(args) {
    let urlObj = Fun.clone({ object: args.url, parentData: args.parentData });
    let data = urlObj.data;
    let object = args.object;
    let formatter = args.url.formatter ? args.url.formatter : null;
    let callback = args.callback ? args.callback : null;
    let responseType = args.responseType ? args.responseType : "json";
    let cancelToken = args.cancelToken ? args.cancelToken : null;
    let parameters = urlObj.parameters ? urlObj.parameters : null;
    parameters = args.parameters ? args.parameters : parameters;

    let onUploadProgress = args.onUploadProgress ? args.onUploadProgress : null;

    let APIUrl = Fun.replaceFromObject(
      urlObj.url,
      parameters["replace"],
      args.data,
      args.parentData
    );

    if (window["baseurl"]) {
      APIUrl = window["baseurl"](APIUrl);
    }
    let headers = parameters["headers"] ? parameters["headers"] : {};

    headers["userid"] = localStorage.getItem("userid");

    if (!headers["Authorization"]) {
      headers["Authorization"] = "Bearer".concat(
        " " + localStorage.getItem("jwt")
      );
    }

    let method = args.method ? args.method : "GET";
    let submittedData = args.submittedData ? args.submittedData : null;

    if (data) {
      let out = [];
      if (formatter) {
        out = formatter(data, object);
      }
      if (callback) {
        setTimeout(function () {
          callback(out);
        }, 0);
      }
    } else {
      let ax = new axios(
        {
          method: method,
          url: APIUrl,
          data: submittedData,
          headers: headers ? headers : {},
          onUploadProgress: onUploadProgress,
          cancelToken: cancelToken,
          responseType: responseType,
        },
        { cancelToken: cancelToken, responseType: responseType }
      )
        .then((response) => {
          let out = response.data;

          if (formatter) {
            out = formatter(response.data, object);
          }

          if (callback) {
            callback(out, args, response.data, response.status);
          }
        })
        .catch((error) => {
          console.log(error);
          try {
            if (error.response) {
              if (error.response.status == 401) {
                UniFun.logout(window.location.href);
              }
            }
          } catch (e) {
            console.log(e);
          }
          if (callback) {
            if (error) {
              if (error.response) {
                if (error.response.data) {
                  let out = error.response.data;

                  if (formatter) {
                    out = formatter([], object);
                  }

                  if (callback) {
                    callback([], error.response.status, error.response);
                  }
                }
              }
            }
          }
        });
    }
  },
  replaceFromObject(input, replaceParams, data, parentData) {
    let out = input;

    if (replaceParams) {
      Object.keys(replaceParams).map((itm, idx) => {
        try {
          out = out.replace("{" + itm + "}", replaceParams[itm]);
        } catch (e) {}
      });
    }

    if (data) {
      Object.keys(data).map((dataItem, dataItemKey) => {
        try {
          out = out.replace("data[" + dataItem + "]", data[dataItem]);
        } catch (e) {}
      });
    }

    if (parentData) {
      Object.keys(parentData).map((dataItem, dataItemKey) => {
        try {
          out = out.replace(
            "parentdata[" + dataItem + "]",
            parentData[dataItem]
          );
        } catch (e) {}
      });
    }

    return out;
  },
  getComp(obj, name, pk) {
    return pk
      ? obj.props.builder[name + "-" + pk]
      : obj.props.builder[name + "-" + obj.props.pk];
  },
  addClass(className, obj) {
    let attributes = { ...obj.state.attributes };
    attributes["className"] =
      obj.state.attributes["className"] + " " + className;

    obj.setState({ attributes: attributes });
  },
  removeClass(className, obj) {
    let attributes = { ...obj.state.attributes };
    attributes["className"] = obj.state.attributes["className"].replace(
      className,
      ""
    );
    obj.setState({ attributes: attributes });
  },
  addAttr(attr, value, obj) {
    let attributes = { ...obj.state.attributes };
    attributes[attr] = value;
    obj.setState({ attributes: attributes });
  },
  removeAttr(attr, obj) {
    let attributes = { ...obj.state.attributes };
    delete attributes[attr];
    obj.setState({ attributes: attributes });
  },
  enrichData(args) {
    let module = args.module;
    let pk = module.pk;
    let moduleFields = module.properties;

    let includeProperties = module.templateConfig.includeProperties
      ? module.templateConfig.includeProperties
      : [];
    if (typeof includeProperties === "function") {
      includeProperties = includeProperties();
    }
    let excludeProperties = module.templateConfig.excludeProperties
      ? module.templateConfig.excludeProperties
      : [];
    let data = args.data;
    let builder = args.builder;
    let out = [];
    if (data) {
      if (data.length > 0) {
        data.map((record, idxRec) => {
          let formattedRecord = {};
          Object.keys(moduleFields).map((itm, idx) => {
            let field = { ...moduleFields[itm] };
            field["validator"] = builder.validator;
            let Control = Controls[field["control"]];
            let showPropertyOnTemplate = true;

            if (excludeProperties.length > 0) {
              showPropertyOnTemplate =
                excludeProperties.indexOf(itm) >= 0 ? false : true;
            }

            if (includeProperties.length > 0) {
              showPropertyOnTemplate =
                includeProperties.indexOf(itm) >= 0 ? true : false;
            }

            //todo check auth for each control

            let refPk = field["alias"] + "-" + record[pk];

            let dataRecord = { ...record };

            let editable = field["editable"];

            if (typeof editable !== "undefined") {
              if (typeof editable === "function") {
                field["editable"] = field["editable"]({
                  data: dataRecord,
                  field: field,
                  builder: builder,
                });
              }
            }

            let show = field["show"];

            if (typeof show !== "undefined") {
              if (typeof show === "function") {
                field["show"] = field["show"]({
                  data: dataRecord,
                  field: field,
                  builder: builder,
                });
              }
            }

            let value = record[field["alias"]];

            let alias = field["alias"];

            formattedRecord[alias] = {
              value: value,
              showPropertyOnTemplate: showPropertyOnTemplate,
              component: (
                <Control
                  value={value}
                  data={dataRecord}
                  key={Fun.uuid()}
                  field={field}
                  pk={record[pk]}
                  ref={(input) => {
                    builder[refPk] = input;
                  }}
                  builder={builder}
                ></Control>
              ),
              data: dataRecord,
              field: field,
            };
          });
          out.push(formattedRecord);
        });
      }
    }
    return out;
  },
  uuid() {
    return "a" + uuidv4();
  },
  clone(args) {
    let object = args.object;
    let data = args.parentData;
    let dataParent = args.parentData;
    let out = "";

    let originalToJSON = Function.prototype.toJSON;

    Function.prototype.toJSON = function () {
      return this();
    };

    let stringifyObject = JSON.stringify(object);

    if (dataParent) {
      Object.keys(dataParent).map((dataItem, dataItemKey) => {
        stringifyObject = stringifyObject.replace(
          "parentdata[" + dataItem + "]",
          dataParent[dataItem]
        );
      });
    }
    if (data) {
      Object.keys(data).map((dataItem, dataItemKey) => {
        stringifyObject = stringifyObject.replace(
          "data[" + dataItem + "]",
          data[dataItem]
        );
      });
    }
    let stringOut = stringifyObject;

    out = JSON.parse(stringOut);

    //this is ugly
    Function.prototype.toJSON = originalToJSON;

    return out;
  },
  validate(validator, data) {
    Object.keys(data).map((itm, idx) => {
      if (window["validationcontrol" + itm]) {
        window["validationcontrol" + itm].setState({
          validation: "",
        });
        validator.message(
          "{" + itm + "}",
          data[itm],
          window["validationcontrol" + itm].state.validations
        );
      }
    });

    if (validator.allValid()) {
      return true;
    } else {
      Object.keys(data).map((itm, idx) => {
        if (window["validationcontrol" + itm]) {
          window["validationcontrol" + itm].setState({
            validation: validator.getErrorMessages()["{" + itm + "}"],
          });
        }
      });
      return false;
    }

    return true;
  },
  getValidatorValue(name) {
    if (name != "description") {
      if (document.getElementsByName(name)[0]) {
        return document.getElementsByName(name)[0].value;
      } else {
        return "";
      }
    }
    return "";
  },
  getJSONPath(obj, path) {
    return path.split(".").reduce(function (o, k) {
      return o && o[k];
    }, obj);
  },
  setJSONPath(obj, path, value) {
    var i;
    path = path.split(".");
    for (i = 0; i < path.length - 1; i++) {
      obj = obj[path[i]];
    }
    obj[path[i]] = value;
  },
  formDataToJSON(e) {
    e.preventDefault();
    let data = new FormData(e.target);
    var object = {};
    data.forEach(function (value, key) {
      object[key] = value;
    });
    return object;
  },
  getSubmitJSON(e) {
    e.preventDefault();
    let data = new FormData(e.target);
    var object = {};
    data.forEach(function (value, key) {
      if (key.indexOf("[") >= 0) {
        let keySplit = [];
        keySplit = key
          .replace(/\[/g, "]")
          .split("]")
          .filter(function (el) {
            return el != null && el != "";
          });
        let path = [];
        keySplit.map((it, ix) => {
          path.push(it);
          let pathValue = isNaN(it) ? [] : {};
          pathValue = ix == keySplit.length - 1 ? value : pathValue;
          if (typeof Fun.getJSONPath(object, path.join(".")) === "undefined") {
            Fun.setJSONPath(object, path.join("."), pathValue);
          }
        });
      } else {
        object[key] = value;
      }
    });
    return object;
  },
  update(e, args) {
    let submittedData = JSON.stringify(Fun.getSubmitJSON(e));

    if (args.submittedData) {
      submittedData = args.submittedData;
    }

    Fun.fetch({
      url: args.url,
      parameters: args.parameters ? args.parameters : null,
      object: this,
      method: args.method,
      submittedData: submittedData,
      callback: args.callback ? args.callback : null,
    });
  },
  addScript(url, nocache) {
    const script = document.createElement("script");
    let cacheBreak = new Date().valueOf();
    if (nocache) {
      script.src = url;
    } else {
      script.src = url + "?" + cacheBreak;
    }
    script.async = false;
    document.body.appendChild(script);
  },
  addCSS(url, nocache) {
    const css = document.createElement("link");
    css.type = "text/css";
    css.rel = "stylesheet";
    let cacheBreak = new Date().valueOf();
    if (nocache) {
      css.href = url;
    } else {
      css.href = url + "?" + cacheBreak;
    }
    document.getElementsByTagName("head")[0].appendChild(css);
  },
  submitFromModal(modalBodyRef, action) {
    if (window[modalBodyRef]) {
      if (action) {
        window[modalBodyRef].builderTemplate.current.setState({
          action: action,
        });
      }

      setTimeout(function () {
        window[modalBodyRef].builderTemplate.current.form.current.dispatchEvent(
          new Event("submit", {
            cancelable: true,
            bubbles: true,
          })
        );
      }, 0);
    }
  },

  getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  },
  getURLPathValue(index, url = window.location.pathname) {
    let splitUrl = url.split("/");

    return splitUrl[index];
  },
};

export default Fun;
