import React, { Component } from "react";
import Fun from "../../lib/functions";
import Builder from "../../lib/builder";
import UniFun from "./functions";
import TopTemplateHeader from "../../themes/default/templates/topTemplateHeader";
import { Link } from "react-router-dom";
import moment from "moment";

const testEventHeader = function () {
  let data = this.state.data[0];
  let subtitle = data.modules?.value.map((itm, idx) => {
    if (itm._id == Fun.getURLPathValue(5)) {
      return itm.title;
    }
  });

  return (
    <div className={"uniTopHeader "}>
      <TopTemplateHeader
        customClass="mb-4 uniFixedHeader"
        title={
          <React.Fragment>
            <img
              src="/uni/svg/trainingmain.svg"
              className="pr-3"
              style={{ width: "39px", paddingBottom: "5px" }}
            ></img>{" "}
            {UniFun.substring(data.title.value, 0, 50)}
            <div style={{ display: "inline-block" }}>
              {UniFun.userIsEligible({
                forWhat: "default",
              })
                ? data.editdropdown.component
                : ""}
            </div>
          </React.Fragment>
        }
        subtitle={<span className="text-dark pl-5">{subtitle}</span>}
      ></TopTemplateHeader>
    </div>
  );
};

const deleteExam = function (examId, parentBuilder) {
  let builder = parentBuilder;
  window["modal2"].setState({
    title: <h3>Delete exam?</h3>,
    targetComponent: null,
    show: true,
    overflowY: "none",
    footer: UniFun.modalCommonFooter("modal2", "Delete", "btn-danger"),
    callback: function (args) {
      window["modal2"].setState({ show: false, overflowY: "auto" });
      window.location = "/agenda";
    },
    body: (
      <Builder
        module="unitaketest"
        view="deleteExam"
        key={Fun.uuid()}
        parameters={{
          replace: {
            id: examId,
          },
        }}
        ref={(refComponent) => {
          window["modal2body"] = refComponent;
        }}
      ></Builder>
    ),
  });
};

const UniTakeTest = {
  auth: "",
  views: {
    detail: {
      auth: "",
      apis: {
        view: {
          url: "/exam/event/user-exam/{_id}",
          parameters: {
            replace: {},
            headers: {},
          },
          formatter: function (args) {
            return UniTakeTestFormatter(args);
          },
        },
        autosave: {
          url: "/exam/user-exam/",
          parameters: {
            headers: {
              "Content-Type": "application/json",
            },
            replace: {},
          },
          formatter: function (data) {
            return UniTakeTestFormatter(data);
          },
        },
        finalize: {
          url: "/exam/user-exam/finalize/{id}",
          parameters: {
            headers: {
              "Content-Type": "application/json",
            },
            replace: {},
          },
          formatter: function (data) {
            return UniTakeTestFormatter(data);
          },
        },
        finalizeAuto: {
          url: "/exam/user-exam/status/{id}",
          parameters: {
            headers: {
              "Content-Type": "application/json",
            },
            replace: {},
          },
          formatter: function (data) {
            return UniTakeTestFormatter(data);
          },
        },
      },
      template: "form",
      templateConfig: {
        events: {
          onSubmit: function (e) {
            e.preventDefault();
            let submitData = Fun.getSubmitJSON(e);

            let hasEssay = false;
            let _this = this;

            let examEvent = this.state.data[0].examEvent.value;

            submitData.questions.forEach((itm, idx) => {
              if (itm.type == "essayType") {
                hasEssay = true;
              }
            });

            switch (this.state.action) {
              case "autosave":
                Fun.fetch({
                  url: this.props.builder.state.module.apis.autosave,
                  method: "PATCH",
                  parameters: {
                    headers: {
                      "content-type": "application/json",
                    },
                  },
                  callback: function (args) {
                    let data = args[0];
                    if (data) {
                      let status = data.status;
                      let eventId = data.examEvent;

                      if (status !== "Pending") {
                        if (
                          examEvent.moduleId &&
                          examEvent.sessionId &&
                          examEvent.training
                        ) {
                          setTimeout(function () {
                            window.location =
                              "/event/training/" +
                              examEvent.training +
                              "/results";
                          }, 0);
                        } else {
                          setTimeout(function () {
                            window.location =
                              "/event/test/" + Fun.getURLPathValue(2);
                          }, 0);
                        }
                      }
                    }
                  },
                  submittedData: JSON.stringify(submitData),
                });
                break;

              case "finalize":
                window["modal2"].setState({
                  title: <h3>Submit test?</h3>,
                  targetComponent: this,
                  footer: [
                    <button
                      type="button"
                      className="btn btn-link border"
                      onClick={(e) => {
                        window["modal2"].setState({
                          show: false,
                          overflowY: "auto",
                        });
                      }}
                    >
                      Cancel
                    </button>,
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={(e) => {
                        Fun.fetch({
                          url: _this.props.builder.state.module.apis.finalize,
                          method: "PATCH",
                          callback: function (args) {
                            let data = args[0];
                            let eventId = data.examEvent;

                            if (
                              examEvent.moduleId &&
                              examEvent.sessionId &&
                              examEvent.training
                            ) {
                              setTimeout(function () {
                                window.location =
                                  "/event/training/" +
                                  examEvent.training +
                                  "/results";
                              }, 0);
                            } else {
                              setTimeout(function () {
                                window.location = "/event/test/" + eventId;
                              }, 0);
                            }
                            // if (hasEssay) {
                            //   setTimeout(function () {
                            //     window.location = "/event/test/" + eventId;
                            //   }, 0);
                            // } else {
                            //   window["modal2"].setState({
                            //     title: <h3>You have submitted the test!</h3>,
                            //     targetComponent: this,
                            //     onClose: function () {
                            //       window["modal2"].setState({
                            //         show: false,
                            //         showClose: true,
                            //         onClose: null,
                            //       });
                            //       setTimeout(function () {
                            //         window.location = "/event/test/" + eventId;
                            //       }, 0);
                            //     },
                            //     footer: [
                            //       <button
                            //         type="button"
                            //         className="btn btn-primary"
                            //         onClick={(e) => {
                            //           window["modal2"].setState({
                            //             show: false,
                            //             showClose: true,
                            //             onClose: null,
                            //           });
                            //           setTimeout(function () {
                            //             window.location =
                            //               "/event/test/" + eventId;
                            //           }, 0);
                            //         }}
                            //       >
                            //         {hasEssay ? "Continue" : "View results"}
                            //       </button>,
                            //     ],
                            //     show: true,
                            //     body: (
                            //       <div className=" p-4">
                            //         <p>
                            //           Thank you for submitting the test.{" "}
                            //           {hasEssay
                            //             ? "You will receive an email notification when the test is evaluated."
                            //             : "Click below to view your result."}
                            //         </p>
                            //       </div>
                            //     ),
                            //   });
                            // }
                          },
                          parameters: {
                            headers: {
                              "content-type": "application/json",
                            },
                            replace: {
                              id: submitData["_id"],
                            },
                          },
                          submittedData: JSON.stringify(submitData),
                        });
                      }}
                    >
                      Submit test
                    </button>,
                  ],
                  show: true,
                  body: (
                    <div className=" p-4">
                      <p>
                        {hasEssay
                          ? "You are about to submit your test. If you want to review your answers, click cancel or click submit test to submit it. This action cannot be undone! You will receive an email notification when the test is evaluated."
                          : "You are about to submit your test. If you want to review your answers, click cancel or click submit test to submit it. This action cannot be undone!"}
                      </p>
                    </div>
                  ),
                });

                break;

              case "finalizeauto":
                setTimeout(function () {
                  Fun.fetch({
                    url: _this.props.builder.state.module.apis.finalizeAuto,
                    parameters: {
                      headers: {
                        "content-type": "application/json",
                      },
                      replace: {
                        id: submitData["_id"],
                      },
                    },
                    callback: function (args) {
                      window["modal2"].setState({
                        title: <h3>Test duration has ended</h3>,
                        showClose: false,
                        targetComponent: _this,
                        onClose: function () {
                          window["modal2"].setState({
                            show: false,
                            showClose: true,
                            onClose: null,
                            overflowY: "auto",
                          });

                          if (
                            examEvent.moduleId &&
                            examEvent.sessionId &&
                            examEvent.training
                          ) {
                            setTimeout(function () {
                              window.location =
                                "/event/training/" +
                                examEvent.training +
                                "/results";
                            }, 0);
                          } else {
                            setTimeout(function () {
                              window.location =
                                "/event/test/" + Fun.getURLPathValue(2);
                            }, 0);
                          }
                        },
                        footer: [
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={(e) => {
                              window["modal2"].setState({
                                show: false,
                                showClose: true,
                                onClose: null,
                                overflowY: "auto",
                              });

                              if (
                                examEvent.moduleId &&
                                examEvent.sessionId &&
                                examEvent.training
                              ) {
                                setTimeout(function () {
                                  window.location =
                                    "/event/training/" +
                                    examEvent.training +
                                    "/results";
                                }, 0);
                              } else {
                                setTimeout(function () {
                                  window.location =
                                    "/event/test/" + Fun.getURLPathValue(2);
                                }, 0);
                              }
                            }}
                          >
                            {hasEssay ? "Continue" : "View results"}
                          </button>,
                        ],
                        show: true,
                        body: (
                          <div className=" p-4">
                            <p>
                              You haven't submitted the test within its time
                              limit. Don't worry, your progress has been
                              submitted automatically.{" "}
                              {hasEssay
                                ? "You will receive an email notification when the test is evaluated."
                                : "Click below to view your result."}
                            </p>
                          </div>
                        ),
                      });
                    },
                  });
                }, 500);

                break;
            }

            // if (
            //   this.state.action == "finalize" ||
            //   this.state.action == "finalizeauto"
            // ) {
            //   let action = this.state.action;

            //   Fun.fetch({
            //     url: this.props.builder.state.module.apis.finalize,
            //     method: "PATCH",
            //     callback: function (args) {
            //       let data = args[0];
            //       let eventId = data.examEvent;

            //       let title = "You have submitted the test!";
            //       let body =
            //         "Thank you for submitting the test. Click below to view your result.";

            //       if (hasEssay) {
            //         body =
            //           "Thank you for submitting the test. You will receive an email notification when the test is evaluated.";
            //       }

            //       if (action == "finalizeauto") {
            //         title = "Test duration has ended";
            //         body =
            //           "You haven't submitted the test within its time limit. Don't worry, your progress has been submitted automatically. Click below to view your results.";

            //         if (hasEssay) {
            //           body =
            //             "You haven't submitted the test within its time limit. Don't worry, your progress has been submitted automatically. You will receive an email notification when the test is evaluated.";
            //         }
            //       }

            //       let footerBtn = (
            //         <button
            //           className="btn btn-primary"
            //           onClick={(e) => {
            //             window["modal2"].setState({ show: false });
            //             setTimeout(function () {
            //               window.location = "/event/test/" + eventId;
            //             }, 0);
            //           }}
            //         >
            //           View results
            //         </button>
            //       );

            //       if (hasEssay) {
            //         footerBtn = (
            //           <button
            //             className="btn btn-primary"
            //             onClick={(e) => {
            //               window["modal2"].setState({ show: false });
            //               setTimeout(function () {
            //                 window.location = "/event/test/" + eventId;
            //               }, 0);
            //             }}
            //           >
            //             Done
            //           </button>
            //         );
            //       }

            //       window["modal2"].setState({
            //         title: <h3>{title}</h3>,
            //         targetComponent: this,
            //         footer: [footerBtn],
            //         show: true,
            //         body: (
            //           <div className=" p-4">
            //             <p>{body}</p>
            //           </div>
            //         ),
            //       });
            //     },
            //     parameters: {
            //       headers: {
            //         "content-type": "application/json",
            //       },
            //       replace: {
            //         id: submitData["_id"],
            //       },
            //     },
            //     submittedData: JSON.stringify(submitData),
            //   });
            // }
          },
        },
        colsTemplateCount: 8,
        panelLeft: function () {
          return <div className="col-2"></div>;
        },
        panelRight: function () {
          return <div className="col-2"></div>;
        },
        panelTop: function () {
          return <div className="mt-5"></div>;
        },
        cardTemplateBottom: function () {
          let data = this.state.data;
          return (
            <div className="row ">
              <div className="col-12 text-center">
                {data[0].submit.component}
              </div>
            </div>
          );
        },
        includeProperties: [
          "_id",
          "title",
          "instructions",
          "passingGrade",
          "questions",
        ],
      },
      pk: "_id",
      properties: UniTakeTestProperties({ editable: false }),
    },
    resultPreview: {
      auth: "",
      apis: {
        view: {
          url: "/exam/user-exam/{id}",
          //url: "/event/exam/{id}",
          parameters: {
            replace: {},
            headers: {},
          },
          formatter: function (args) {
            return UniTakeTestFormatter(args);
          },
        },
        evaluate: {
          url: "/exam/user-exam/evaluate/{id}",
          //url: "/event/exam/{id}",
          parameters: {
            replace: {},
            headers: {},
          },
          formatter: function (args) {
            return UniTakeTestFormatter(args);
          },
        },
      },
      template: "form",
      templateConfig: {
        events: {
          onSubmit: function (e) {
            e.preventDefault();

            let data = this.state.data[0];

            if (data.evaluationMode.value) {
              if (this.state.action == "submitevaluation") {
                let submitData = Fun.getSubmitJSON(e);

                let _this = this;
                let hasErrors = false;
                submitData.questions.forEach((itm, idx) => {
                  if (!isNaN(itm.mark)) {
                    if (parseFloat(itm.mark) < 0 || parseFloat(itm.mark) > 10) {
                      hasErrors = true;
                    }
                  } else {
                    hasErrors = true;
                  }
                });

                if (hasErrors) {
                  Fun.createNotification({
                    type: "danger",
                    message: "All questions must have mark between 0 to 10",
                  });
                } else {
                  window["modal2"].setState({
                    title: <h3>Submit evaluation?</h3>,
                    targetComponent: this,
                    footer: [
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={(e) => {
                          Fun.fetch({
                            url: _this.props.builder.state.module.apis.evaluate,
                            method: "PATCH",
                            submittedData: submitData,
                            parameters: {
                              replace: {
                                id: submitData["_id"],
                              },
                            },
                            callback: function (
                              submittedData,
                              status,
                              responsedata,
                              responsestatus
                            ) {
                              if (responsestatus == "200") {
                                window.location.replace("/testresults");
                              } else {
                                if (responsedata.data.exceptions) {
                                  responsedata.data.exceptions.map(
                                    (itm, idx) => {
                                      Fun.createNotification({
                                        message: itm.errorDescription,
                                        type: "danger",
                                      });
                                    }
                                  );
                                }
                              }
                            },
                          });
                        }}
                      >
                        Submit
                      </button>,
                    ],
                    show: true,
                    body: (
                      <div className=" p-4">
                        <p>
                          You are about to submit your evaluation. Before
                          submitting be sure that you have added the correct
                          grades. This action cannot be undone! An email will be
                          sent to the participant to inform him about the final
                          grade.
                        </p>
                      </div>
                    ),
                  });
                }
              }
            }
          },
        },
        colsTemplateCount: 8,
        cardBodyCustomClass: "p-5",
        panelLeft: function () {
          return <div className="col-2"></div>;
        },
        panelRight: function () {
          return <div className="col-2"></div>;
        },
        panelTop: function () {
          return <div className="mt-5"></div>;
        },
        cardTemplateBottom: function () {
          let data = this.state.data[0];

          if (data.evaluationMode.value) {
            return (
              <div className="row">
                <div className="col-12 text-center">
                  {data.submitEvaluation.component}
                </div>
              </div>
            );
          }

          if (!data.status.value) {
            return "";
          }

          if (
            data.status.value.toLowerCase() != "pass" &&
            data.status.value.toLowerCase() != "fail" &&
            data.status.value.toLowerCase() != "no grade"
          ) {
            return "";
          }

          let answerCorrectQuestions = data.questions.value.length;

          let hasEssay = false;

          data.questions.value.map((itm, idx) => {
            let answerIsCorrect = true;
            if (itm.type !== "essayType") {
              itm.answers.map((aitm, aid) => {
                let isCorrect = aitm.isCorrect
                  ? aitm.isCorrect.toString()
                  : "false";
                let userAnswer = aitm.userAnswer.toString();

                if (isCorrect != userAnswer) {
                  answerIsCorrect = false;
                }
              });

              if (!answerIsCorrect) {
                answerCorrectQuestions--;
              }
            } else {
              hasEssay = true;
            }
          });

          if (data.status.value.toLowerCase() == "not evaluated") {
            return (
              <div className="ml-3">
                <span>
                  Pending evaluation: You will receive an email notification
                  when the test is evaluated.
                </span>
              </div>
            );
          }

          if (data.passingGrade.value > 0) {
            return (
              <React.Fragment>
                <div className="ml-3">
                  <span
                    className={
                      data.status.value.toLowerCase() == "fail"
                        ? "text-danger"
                        : "text-success"
                    }
                  >
                    {data.status.value}{" "}
                  </span>
                  :
                  <span>
                    {"Your final grade is " + data.finalGrade.value + "%"}
                    {/* {hasEssay
                      ? " Your final grade is " + data.finalGrade.value + "%"
                      : " You have answered " +
                        answerCorrectQuestions +
                        "/" +
                        data.questions.value.length +
                        " of the questions correctly and your score was " +
                        data.finalGrade.value +
                        "%"} */}
                  </span>
                </div>
              </React.Fragment>
            );
          } else {
            return (
              <React.Fragment>
                <div className="ml-3">
                  <span>
                    {"Your final grade is " + data.finalGrade.value + "%"}
                    {/* {hasEssay
                      ? " Your final grade is " + data.finalGrade.value + "%"
                      : " You have answered " +
                        answerCorrectQuestions +
                        "/" +
                        data.questions.value.length +
                        " of the questions correctly and your score was " +
                        data.finalGrade.value +
                        "%"} */}
                  </span>
                </div>
              </React.Fragment>
            );
          }
        },

        includeProperties: [
          "_id",
          "title",
          "instructions",
          "passingGrade",
          "questions",
        ],
      },
      pk: "_id",
      properties: UniTakeTestProperties({
        editable: false,
        showResultCheckboxes: true,
      }),
    },
    results: {
      auth: "",
      apis: {
        view: {
          url: "/event/exam/{id}",
          parameters: {
            replace: {},
            headers: {},
          },
          formatter: function (args) {
            return UniTakeTestFormatter(args);
          },
        },
      },
      template: "form",
      templateConfig: {
        header: testEventHeader,
        gridConfig: {
          headers: {
            participant: {
              attributes: {
                className: "w-50",
              },
            },
          },
          cellTemplates: {
            participant: function (data, rec, obj) {
              let uid = Fun.uuid();

              return (
                <React.Fragment>
                  {UniFun.createProfileImageBubble([data.props.value])}
                  <span className="float-right mr-4 grid-controls pt-2">
                    <Link to={"/event/test/" + rec.examEvent.value + "/result"}>
                      <i className="fas fa-search mr-4 pointer"></i>
                    </Link>
                  </span>
                </React.Fragment>
              );
            },
          },
        },
        colsTemplateCount: 12,
        includeProperties: ["resultsParticipants"],
      },
      pk: "_id",
      properties: UniTakeTestProperties({
        editable: false,
        useCustomLayout: false,
      }),
    },
    resultsParticipants: {
      auth: "",
      apis: {
        view: {
          url: "/exam/{id}/user-exam",
          parameters: {
            replace: {
              id: function () {
                return Fun.getURLPathValue(3);
              },
            },
            headers: {},
          },
          formatter: function (args, object) {
            if (object) {
              let parentData = object.state.parentData;

              args.data.map((itm, idx) => {
                itm["from"] = parentData.from;
                itm["to"] = parentData.to;
              });
            }

            return UniTakeTestFormatter(args);
          },
        },
      },
      template: "grid",
      templateConfig: {
        useCard: false,
        gridConfig: {
          emptyFolder: function () {
            return (
              <div className="row mt-4 " style={{ height: "500px" }}>
                <div className="col-12 text-center">
                  <h5>
                    <strong>There are no results</strong>
                  </h5>
                  <span>The test has not started yet</span>
                </div>
              </div>
            );
          },
          headers: {
            participant: {
              attributes: {
                className: "w-50",
              },
            },
          },
          cellTemplates: {
            participant: function (data, rec, obj) {
              let uid = Fun.uuid();

              if (rec.examEvent.value) {
                return (
                  <React.Fragment>
                    {UniFun.createProfileImageBubble([data.props.value])}
                    {rec.status.value !== "Not Evaluated" ? (
                      <span className="float-right mr-4 grid-controls pt-2">
                        <Link to={"/event/test/" + rec._id.value + "/result"}>
                          <i className="fas fa-search mr-4 pointer"></i>
                        </Link>
                      </span>
                    ) : (
                      ""
                    )}
                  </React.Fragment>
                );
              }
              return UniFun.createProfileImageBubble([data.props.value]);
            },
            status: function (data, rec, obj) {
              //return rec.status.value;
              if (rec.status.value.toLowerCase() == "not evaluated") {
                return "Pending evaluation";
              }
              if (rec.status.value.toLowerCase() == "pending") {
                return "Not submitted";
              }
              if (
                rec.status.value.toLowerCase() == "fail" ||
                rec.status.value.toLowerCase() == "pass" ||
                rec.status.value.toLowerCase() == "no grade"
              ) {
                return "Submitted";
              }
              if (moment().isBefore(rec.from.value)) {
                return "Not started";
              }
              if (rec.status.value.toLowerCase() != "pending") {
                return "Not submitted";
              }
              return "Submitted";
            },
            result: function (data, rec, obj) {
              if (parseFloat(rec.passingGrade.value) == 0) {
                return "---";
              }

              switch (rec.status.value.toLowerCase()) {
                case "pass":
                  return <span className="text-success">Pass</span>;
                  break;
                case "fail":
                  return <span className="text-danger">Fail</span>;
                  break;
                default:
                  return "---";
                  break;
              }
            },
            finalGrade: function (data, rec, obj) {
              if (
                rec.status.value.toLowerCase() != "fail" &&
                rec.status.value.toLowerCase() != "pass" &&
                rec.status.value.toLowerCase() != "no grade"
              ) {
                return "---";
              }

              if (parseFloat(rec.passingGrade.value) >= 0) {
                //  if (parseFloat(rec.finalGrade.value) >= 0) {
                let sumanswers = "";

                if (rec.questions.value) {
                  let answerCorrectQuestions = rec.questions.value.length;

                  rec.questions.value.map((itm, idx) => {
                    let answerIsCorrect = true;
                    if (itm.type !== "essayType") {
                      itm.answers.map((aitm, aid) => {
                        let isCorrect = aitm.isCorrect
                          ? aitm.isCorrect.toString()
                          : "false";
                        let userAnswer = aitm.userAnswer.toString();

                        if (isCorrect != userAnswer) {
                          answerIsCorrect = false;
                        }
                      });

                      if (!answerIsCorrect) {
                        answerCorrectQuestions--;
                      }
                    }
                  });
                  // sumanswers =
                  //   "(" +
                  //   answerCorrectQuestions +
                  //   "/" +
                  //   rec.questions.value.length +
                  //   "ans.)";
                }

                return rec.finalGrade.value + "% " + sumanswers;
                //  }
              }

              return "---";
            },
          },
        },
        colsTemplateCount: 12,
        includeProperties: ["participant", "status", "result", "finalGrade"],
      },
      pk: "_id",
      properties: UniTakeTestProperties({
        editable: false,
        useCustomLayout: false,
      }),
    },
    resultsTrainingParticipants: {
      auth: "",
      apis: {
        view: {
          url: "/exam/{id}/user-exam",
          parameters: {
            replace: {
              id: function () {
                return Fun.getURLPathValue(3);
              },
            },
            headers: {},
          },
          formatter: function (args, object) {
            if (object) {
              let parentData = object.state.parentData;

              args.data.map((itm, idx) => {
                itm["from"] = parentData.from;
                itm["to"] = parentData.to;
              });
            }

            return UniTakeTestFormatter(args);
          },
        },
      },
      template: "grid",
      templateConfig: {
        useCard: true,
        gridConfig: {
          emptyFolder: function () {
            return (
              <div className="row mt-4 " style={{ height: "500px" }}>
                <div className="col-12 text-center">
                  <h5>
                    <strong>There are no results</strong>
                  </h5>
                  <span>The test has not started yet</span>
                </div>
              </div>
            );
          },
          headers: {
            participant: {
              attributes: {
                className: "w-50",
              },
            },
          },
          cellTemplates: {
            participant: function (data, rec, obj) {
              let uid = Fun.uuid();

              if (rec.examEvent.value) {
                return (
                  <React.Fragment>
                    <span>
                      {UniFun.createProfileImageBubble([data.props.value])}
                    </span>
                    {rec.status.value.toLowerCase() == "not evaluated" ? (
                      <span
                        class="p-2  rounded  text-white"
                        style={{ backgroundColor: "#9D274E" }}
                      >
                        Pending evaluation
                      </span>
                    ) : (
                      ""
                    )}
                    {rec.status.value.toLowerCase() == "fail" ||
                    rec.status.value.toLowerCase() == "pass" ||
                    rec.status.value.toLowerCase() == "no grade" ? (
                      <span className="float-right mr-4 grid-controls pt-2">
                        <Link to={"/event/test/" + rec._id.value + "/result"}>
                          View test{" "}
                          <i className="fas fa-chevron-right ml-2 pointer"></i>
                        </Link>
                      </span>
                    ) : (
                      ""
                    )}
                  </React.Fragment>
                );
              }
              return UniFun.createProfileImageBubble([data.props.value]);
            },
            status: function (data, rec, obj) {
              //return rec.status.value;
              // if (rec.status.value.toLowerCase() == "not evaluated") {
              //   return "Pending evaluation";
              // }
              if (rec.status.value.toLowerCase() == "pending") {
                return "Not submitted";
              }
              if (
                rec.status.value.toLowerCase() == "fail" ||
                rec.status.value.toLowerCase() == "pass" ||
                rec.status.value.toLowerCase() == "not evaluated" ||
                rec.status.value.toLowerCase() == "no grade"
              ) {
                return "Submitted";
              }
              if (moment().isBefore(rec.from.value)) {
                return "Not started";
              }
              if (rec.status.value.toLowerCase() != "pending") {
                return "Not submitted";
              }
              return "Submitted";
            },
            result: function (data, rec, obj) {
              if (parseFloat(rec.passingGrade.value) == 0) {
                return "---";
              }

              switch (rec.status.value.toLowerCase()) {
                case "pass":
                  return <span className="text-success">Pass</span>;
                  break;
                case "fail":
                  return <span className="text-danger">Fail</span>;
                  break;
                default:
                  return "---";
                  break;
              }
            },
            finalGrade: function (data, rec, obj) {
              if (rec.status.value.toLowerCase() == "not evaluated") {
                return "---";
              }
              if (
                rec.status.value.toLowerCase() !== "pending" &&
                !moment().isBefore(rec.from.value)
              ) {
                if (rec.finalGrade) {
                  if (rec.finalGrade.value) {
                    return rec.finalGrade.value + "% ";
                  }
                }
              }
              if (
                rec.status.value.toLowerCase() != "fail" &&
                rec.status.value.toLowerCase() != "pass"
              ) {
                return "---";
              }

              if (parseFloat(rec.passingGrade.value) >= 0) {
                //  if (parseFloat(rec.finalGrade.value) >= 0) {
                let sumanswers = "";

                if (rec.questions.value) {
                  let answerCorrectQuestions = rec.questions.value.length;

                  rec.questions.value.map((itm, idx) => {
                    let answerIsCorrect = true;
                    if (itm.type !== "essayType") {
                      itm.answers.map((aitm, aid) => {
                        let isCorrect = aitm.isCorrect
                          ? aitm.isCorrect.toString()
                          : "false";
                        let userAnswer = aitm.userAnswer.toString();

                        if (isCorrect != userAnswer) {
                          answerIsCorrect = false;
                        }
                      });

                      if (!answerIsCorrect) {
                        answerCorrectQuestions--;
                      }
                    }
                  });
                  // sumanswers =
                  //   "(" +
                  //   answerCorrectQuestions +
                  //   "/" +
                  //   rec.questions.value.length +
                  //   "ans.)";
                }

                return rec.finalGrade.value + "% " + sumanswers;
                //  }
              }

              return "---";
            },
          },
        },
        colsTemplateCount: 12,
        includeProperties: ["participant", "status", "result", "finalGrade"],
      },
      pk: "_id",
      properties: UniTakeTestProperties({
        editable: false,
        useCustomLayout: false,
      }),
    },
    trainingresults: {
      auth: "",
      apis: {
        view: {
          url: "/exam/{id}/user-exam",
          parameters: {
            replace: {},
            headers: {},
          },
          formatter: function (args) {
            return UniTakeTestFormatter(args);
          },
        },
      },
      template: "grid",
      templateConfig: {
        useCard: false,
        gridConfig: {
          headers: {
            participant: {
              attributes: {
                className: "w-50",
              },
            },
          },
          cellTemplates: {
            participant: function (data, rec, obj) {
              let uid = Fun.uuid();
              return (
                <React.Fragment>
                  {UniFun.createProfileImageBubble([data.props.value])}
                  <span className="float-right mr-4 grid-controls pt-2">
                    <i className="fas fa-search mr-4 pointer"></i>
                  </span>
                </React.Fragment>
              );
            },
          },
        },
        colsTemplateCount: 12,
        includeProperties: ["participant", "status", "result", "finalGrade"],
      },
      pk: "_id",
      properties: UniTakeTestProperties({
        editable: false,
        useCustomLayout: false,
      }),
    },
    participants: {
      auth: "",
      apis: {
        view: {
          url: "/event/exam/{id}",
          parameters: {
            headers: {},
            replace: {},
          },
          formatter: function (data) {
            return UniTakeTestFormatter(data);
          },
        },
      },
      template: "form",
      templateConfig: {
        useCard: false,
        colsTemplateCount: 12,
        events: {
          onSubmit: function (e) {
            e.preventDefault();
          },
        },
        header: testEventHeader,
        includeProperties: ["testParticipants"],
      },
      pk: "_id",
      properties: UniTakeTestProperties({ editable: true }),
    },
    deleteExam: {
      auth: "",
      apis: {
        view: {
          url: "/event/exam/{id}",
          parameters: {
            headers: {},
            replace: {},
          },
          formatter: function (data, builder) {
            let output = [];
            if (data) {
              if (data.data) {
                data.data.map((itm, idx) => {
                  output.push(itm);
                });
              }
            }

            return output;
          },
        },
        deleteExam: {
          url: "/event/exam/{id}",
          parameters: {
            headers: { "content-type": "application/json" },
          },
          formatter: function (data, builder) {
            return data;
          },
        },
      },
      template: "form",
      templateConfig: {
        useCard: false,
        colsTemplateCount: 12,
        includeProperties: ["_id"],
        events: {
          onSubmit: function (e) {
            let submittedData = Fun.getSubmitJSON(e);

            let jsonData = {};

            let url = "";
            let method = "DELETE";

            Fun.update(e, {
              url: this.props.builder.state.module.apis.deleteExam,
              method: method,
              parameters: {
                headers: {
                  "content-type": "application/json",
                },
                replace: {
                  id: submittedData["_id"],
                },
              },
              callback: function (args) {
                if (window["modal2"].state.callback) {
                  window["modal2"].state.callback(args);
                } else {
                  if (window["modal2"].state.targetComponent) {
                    window["modal2"].state.targetComponent.setState({
                      data: args,
                    });
                  }
                }
              },
              submittedData: JSON.stringify(jsonData),
            });
          },
        },
        templateTop: function () {
          let data = this.state.data[0];
          return (
            <div className=" p-4">
              <p>
                Are you sure you want to delete "
                <strong>{data.title.value}</strong>" test?
              </p>
            </div>
          );
        },
      },
      pk: "_id",
      properties: UniTakeTestProperties({ editable: false }),
    },
    preview: {
      auth: "",
      apis: {
        view: {
          url: "/event/exam/{id}",
          parameters: {
            replace: {},
            headers: {},
          },
          formatter: function (args) {
            return UniTakeTestFormatter(args);
          },
        },
      },
      template: "form",
      templateConfig: {
        cardBodyCustomClass: "p-5",
        header: testEventHeader,
        useCard: false,
        events: {
          onSubmit: function (e) {
            e.preventDefault();
            let jsonData = UniFun.examJSONData(Fun.getSubmitJSON(e));

            Fun.update(e, {
              url: this.props.builder.state.module.apis.update,
              method: "PATCH",
              parameters: {
                headers: {
                  "content-type": "application/json",
                },
              },
              submittedData: JSON.stringify(jsonData),
            });
          },
        },
        colsTemplateCount: 12,
        cardTemplateTop: function () {
          let data = this.state.data[0];

          let examDurationOut = UniFun.examDuration(data.examDuration.value);

          // let examDurationOut = "";
          // if (examDuration.hours > 0) {
          //   examDurationOut = examDuration.hours + " hours";
          //   if (examDuration.minutes > 0) {
          //     examDurationOut =
          //       examDurationOut + " and " + examDuration.minutes + " minutes";
          //   }
          //   examDurationOut = examDurationOut + " duration";
          // } else {
          //   if (examDuration.minutes > 0) {
          //     examDurationOut = examDuration.minutes + " minutes duration";
          //   }
          // }

          let hasEssay = false;
          let numberOfEssays = 0;
          let numberOfMulitple = 0;
          let totalQuestions = 0;
          let aboutText = "";

          data.questions.value.forEach((itm, idx) => {
            if (itm.type == "essayType") {
              numberOfEssays++;
              hasEssay = true;
            } else {
              numberOfMulitple++;
            }
            totalQuestions++;
          });

          if (numberOfMulitple == 0) {
            aboutText = numberOfEssays + " essay questions";
          } else if (numberOfEssays == 0) {
            aboutText = numberOfMulitple + " multiple choice questions";
          } else {
            aboutText =
              "Total " +
              totalQuestions +
              " questions: " +
              numberOfMulitple +
              " multiple choice and " +
              numberOfEssays +
              " essay questions";
          }

          return (
            <React.Fragment>
              <div className="mb-3 mt-4">
                <h5>
                  <strong>Availability & results</strong>
                </h5>
              </div>
              <div className="card ">
                <div className="card-body pl-3 pt-0 pb-0">
                  <div className="row border-bottom pt-4 pb-2 pl-3 rowhover pointer ">
                    <div className="col-10 ">
                      <p style={{ float: "left" }}>
                        <img
                          src="/uni/svg/datetime.svg"
                          className="pr-2"
                          style={{ height: "20px", float: "left" }}
                        ></img>
                      </p>
                      <p>
                        {UniFun.testAvailability(
                          data.from.value,
                          data.to.value
                        )}
                      </p>
                    </div>
                    <div className="col-2 text-center">
                      <span className="grid-controls">
                        {data.startTest.component}
                      </span>
                    </div>
                  </div>
                  <div className="row  pt-4 pb-2 pl-3 rowhover pointer">
                    <div className="col-10 ">
                      <p style={{ float: "left" }}>
                        <img
                          src="/uni/svg/star.svg"
                          className="pr-2"
                          style={{ height: "20px", float: "left" }}
                        ></img>
                      </p>
                      {/* <p>{UniFun.examStatus(data)}</p> */}
                      <p>{data.resultStatus.component}</p>
                    </div>
                    <div className="col-2 text-center  ">
                      <span className="grid-controls">
                        {data.viewResults.component}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mb-3">
                <h5>
                  <strong>About</strong>
                </h5>
              </div>
              <div className="card">
                <div className="card-body pl-3 pt-0 pb-0">
                  <div className="row border-bottom pt-4 pb-2 pl-3">
                    <div className="col-12 ">
                      <p style={{ float: "left" }}>
                        <img
                          src="/uni/svg/graded_practicetest.svg"
                          className="pr-2"
                          style={{ height: "20px", float: "left" }}
                        ></img>
                      </p>
                      <p>{UniFun.gradeExam(data.passingGrade.value)}</p>
                    </div>
                  </div>
                  <div className="row border-bottom pt-4 pb-2 pl-3">
                    <div className="col-12 ">
                      <p style={{ float: "left" }}>
                        <img
                          src="/uni/svg/check-mark.svg"
                          className="pr-2"
                          style={{ height: "20px", float: "left" }}
                        ></img>
                      </p>
                      <p>
                        {/* {data.questions.value.length} Multiple choice questions{" "}
                        {hasEssay ? "and essay questions" : ""} */}
                        {aboutText}
                      </p>
                    </div>
                  </div>
                  <div className="row  pt-4 pb-2 pl-3">
                    <div className="col-12 ">
                      <p style={{ float: "left" }}>
                        <img
                          src="/uni/svg/testdutation.svg"
                          className="pr-2"
                          style={{ height: "20px", float: "left" }}
                        ></img>
                      </p>
                      <p>{examDurationOut ? examDurationOut : "---"}</p>
                    </div>
                  </div>
                </div>
              </div>
            </React.Fragment>
          );
        },
        includeProperties: ["_id"],
      },
      pk: "_id",
      properties: UniTakeTestProperties({ editable: false }),
    },
  },
};

function UniTakeTestFormatter(data) {
  let formattedData = [];

  if (data) {
    if (data.data) {
      data.data.map((itm, idx) => {
        let formattedRecord = {};
        formattedRecord["_id"] = itm._id;
        formattedRecord["availability"] = itm._id;
        formattedRecord["editdropdown"] = itm._id;
        formattedRecord["resultsParticipants"] = itm._id;
        formattedRecord["resultStatus"] = itm._id;
        formattedRecord["from"] = itm.from;
        formattedRecord["to"] = itm.to;
        formattedRecord["evaluationMode"] = itm.evaluationMode ? true : false;
        if (itm.email) {
          formattedRecord["status"] = "Not started";
          formattedRecord["result"] = "---";
          formattedRecord["participant"] = itm;
          formattedRecord["passingGrade"] = "";
        } else {
          formattedRecord["title"] = itm.title;
          formattedRecord["participant"] = itm.participant;

          formattedRecord["instructions"] = itm.instructions;
          formattedRecord["status"] = itm.status;
          formattedRecord["result"] = itm.status;
          formattedRecord["passingGrade"] = itm.passingGrade
            ? itm.passingGrade
            : 0;
          formattedRecord["finalGrade"] = itm.finalGrade;
          formattedRecord["examDuration"] = itm.examDuration;
          formattedRecord["questions"] = itm.questions;
          formattedRecord["modified"] = itm.updatedAt;
          formattedRecord["userExamInfo"] = itm.userExamInfo;
          if (itm.userExamInfo) {
            if (itm.userExamInfo.status) {
              formattedRecord["status"] = itm.userExamInfo.status;
            }
          }
          if (itm.start) {
            formattedRecord["from"] = itm.start;
          }
          if (itm.end) {
            formattedRecord["to"] = itm.end;
          }
          formattedRecord["examEvent"] = itm.examEvent;
        }

        formattedData.push(formattedRecord);
      });
    }
  }

  return formattedData;
}

function UniTakeTestProperties(input) {
  let editable = input.editable;
  let useCustomLayout =
    typeof input.useCustomLayout !== "undefined" ? input.useCustomLayout : true;
  let showResultCheckboxes = input.showResultCheckboxes;

  return {
    _id: {
      alias: "_id",
      control: "hidden",
      editable: editable,
      attributes: {},
    },
    participant: {
      alias: "participant",
      placeholder: "Select the participants of the test",
      control: "text",
      editable: false,
      attributes: {},
    },

    title: {
      alias: "title",
      control: "text",
      label: "Name",
      editable: editable,
      placeholder: "Type the name of the test",
      customLayout: function (args) {
        return (
          <React.Fragment>
            <h2> {args.control}</h2>
            <br></br>
          </React.Fragment>
        );
      },
      useCustomLayout: useCustomLayout,
      attributes: {
        className: "form-control",
      },
    },
    availability: {
      alias: "availability",
      control: "text",
      label: "Availability",
      editable: false,
      useCustomLayout: true,
      customLayout: function () {
        let data = this.props.data;
        return UniFun.testAvailability(data.from.value, data.to.value);
      },
    },
    status: {
      alias: "status",
      control: "text",
      label: "Status",
      editable: false,
      useCustomLayout: true,
      customLayout: function () {
        let value = this.props.value.toLowerCase();

        if (
          value.toLowerCase() == "fail" ||
          value.toLowerCase() == "pass" ||
          value.toLowerCase() == "no grade"
        ) {
          return "Submitted";
        } else if (value.toLowerCase() == "pending") {
          return "Pending";
        }
        return "Not submitted";
      },
    },
    result: {
      alias: "result",
      control: "text",
      label: "Result",
      editable: false,
    },
    finalGrade: {
      alias: "finalGrade",
      control: "text",
      label: "Grade",
      editable: false,
      useCustomLayout: true,
      customLayout: function () {
        let value = this.props.value;
        let data = this.props.data;

        let passingGrade = parseFloat(data.passingGrade);
        if (passingGrade >= 0) {
          return value;
        }
        return "---";
      },
    },
    instructions: {
      alias: "instructions",
      control: "editor",
      label: "Instructions",
      placeholder: "Write the instructions of the test",
      editable: editable,
      customLayout: function (args) {
        if (this.props.data.evaluationMode) {
          return (
            <span>
              <strong>Instructions:</strong>{" "}
              <span>
                Below you will find the essay type questions included in the
                test. Please evaluate them by grading the question by adding a
                number between 0 and 10 (where 10 is the best) in the box under
                each question.
              </span>
              <br></br>
            </span>
          );
        }

        return (
          <span>
            <strong>Instructions:</strong> {args.control}
            <br></br>
          </span>
        );
      },
      useCustomLayout: true,
      attributes: {
        className: "form-control",
      },
    },
    passingGrade: {
      alias: "passingGrade",
      control: "text",
      label: "Passing Grade",
      editable: editable,
      customLayout: function (args) {
        return (
          <React.Fragment>
            <br></br>
            {this.props.data.evaluationMode ? (
              ""
            ) : (
              <>
                <span className="bg-light rounded p-2 text-dark">
                  {/* {args.control}% or higher to pass */}
                  {UniFun.gradeExam(this.props.value)}
                </span>
                <br></br>
                <br></br>
              </>
            )}
            <hr></hr>
            <br></br>
          </React.Fragment>
        );
      },
      useCustomLayout: true,
      attributes: {
        className: "form-control w-10",
      },
    },
    questions: {
      alias: "questions",
      control: "multiple",
      label: "Questions",
      customLayout: function (args) {
        let controls = args.control;

        let output = [];
        let evaluationMode = this.props.data.evaluationMode;

        controls.map((itm, idx) => {
          output.push(
            <div className="row pt-2 pb-5">
              <div className="col-10 pl-4">
                <span>
                  {" "}
                  <strong className="mb-2 d-block">{idx + 1} Question: </strong>
                  {itm[2].props.control.component}
                </span>
              </div>
              <div className="col-2">
                {evaluationMode !== true ? (
                  <span className=" rounded p-2 pl-3 pr-3 text-dark border d-block ">
                    {itm[3].props.control.component} points
                  </span>
                ) : (
                  ""
                )}
              </div>
              <div className="col-12">
                {itm[0].props.control.component}
                {itm[1].props.control.component}
                {itm[4].props.control.component}
                {itm[5].props.control.component}
              </div>
            </div>
          );
        });

        return <React.Fragment>{output}</React.Fragment>;
      },
      useCustomLayout: true,
      config: {
        properties: {
          _id: {
            alias: "_id",
            control: "hidden",
            label: "",
            editable: editable,
          },
          type: {
            alias: "type",
            control: "hidden",
            label: "",
            defaultValue: "multipleChoice",
            editable: editable,
            attributes: {
              className: "form-control",
            },
          },
          question: {
            alias: "question",
            control: "hidden",
            label: "Question",
            placeholder: "Type a question",
            editable: editable,
            useCustomLayout: true,
            customLayout: function (args) {
              return (
                <React.Fragment>
                  {Fun.parse(this.props.value)}
                  {args.control}
                </React.Fragment>
              );
            },
            attributes: {
              className: "form-control",
            },
          },
          weight: {
            alias: "weight",
            control: "text",
            label: "Points",
            placeholder: "0",
            editable: editable,
            defaultValue: "0",
            attributes: {
              className: "form-control",
            },
            useCustomLayout: true,
            customLayout: function (args) {
              if (this.props.field.editable) {
                return args.control;
              } else {
                return this.state.defaultValue ? this.state.defaultValue : "0";
              }
            },
          },
          answers: {
            alias: "answers",
            control: "multiple",
            editable: editable,
            customLayout: function (args) {
              let output = [];

              let qtype = this.props.data.type;

              switch (qtype) {
                case "essayType":
                  args.control.map((itm, idx) => {
                    output.push(
                      <React.Fragment>
                        <div className="row pt-4 mt-0 pl-3">
                          <div className="col-9 pr-0">
                            {itm[2].props.control.component}
                          </div>
                          <div className="col-3"></div>
                        </div>
                      </React.Fragment>
                    );
                  });

                  break;

                default:
                  args.control.map((itm, idx) => {
                    output.push(
                      <div className="row pt-4 mt-0 pl-3">
                        <div
                          className="col-1"
                          style={{
                            maxWidth: "32px",
                            marginLeft: "20px",
                          }}
                        >
                          {itm[1].props.control.component}
                        </div>
                        <div className="col-9 pr-0">
                          {itm[2].props.control.component}
                        </div>
                        <div className="col-2"></div>
                      </div>
                    );
                  });
                  break;
              }

              return <React.Fragment>{output}</React.Fragment>;
            },
            useCustomLayout: true,
            config: {
              formatDefaultValue: function (data) {
                if (data.length <= 0) {
                  return [{}];
                }
                return data;
              },
              properties: {
                _id: {
                  alias: "_id",
                  control: "hidden",
                  label: "",
                  editable: editable,
                },
                userAnswer: {
                  alias: "userAnswer",
                  control: "checkbox",
                  config: {
                    useHiddenField: true,
                    useTrueFalseValue: true,
                  },
                  editable: true,
                  attributes: {
                    className:
                      "form-check-input form-control mt-0 test-answers-checkbox",
                  },
                  useCustomLayout: true,
                  customLayout: function (args) {
                    if (showResultCheckboxes == true) {
                      let data = args.object.props.data;
                      let img = "/uni/svg/testcheck/box.svg";
                      if (data) {
                        if (data.hasOwnProperty("isCorrect")) {
                          let isCorrect = data.isCorrect
                            ? data.isCorrect.toString()
                            : "false";
                          let userAnswer = data.userAnswer.toString();

                          if (isCorrect == "true" && userAnswer == "true") {
                            img = "/uni/svg/testcheck/correct.svg";
                          } else if (
                            isCorrect == "true" &&
                            userAnswer == "false"
                          ) {
                            img = "/uni/svg/testcheck/boxgreen.svg";
                          } else if (
                            isCorrect == "false" &&
                            userAnswer == "true"
                          ) {
                            img = "/uni/svg/testcheck/wrong.svg";
                          }
                        }
                        return (
                          <img
                            src={img}
                            style={{ width: "25px" }}
                            key={Fun.uuid()}
                          ></img>
                        );
                      }
                    }

                    return args.control;
                  },
                },
                answer: {
                  alias: "answer",
                  control: "hidden",
                  placeholder:
                    "Type answer and check the box if it's the correct one",
                  editable: editable,
                  attributes: {
                    className: "form-control",
                  },
                  useCustomLayout: true,
                  customLayout: function (args) {
                    if (showResultCheckboxes == true) {
                      let data = args.object.props.data;
                      let textClassName = "";
                      if (data) {
                        if (data.hasOwnProperty("isCorrect")) {
                          let isCorrect = data.isCorrect
                            ? data.isCorrect.toString()
                            : "false";
                          let userAnswer = data.userAnswer.toString();
                          if (isCorrect == "true" && userAnswer == "true") {
                            textClassName = "text-success";
                          } else if (
                            isCorrect == "true" &&
                            userAnswer == "false"
                          ) {
                            textClassName = "text-success";
                          } else if (
                            isCorrect == "false" &&
                            userAnswer == "true"
                          ) {
                            textClassName = "text-danger";
                          }
                        }

                        return (
                          <span className={textClassName}>
                            {this.props.parent.props.data.image ? (
                              <img
                                src={
                                  window["baseurl"](
                                    "/agenda-file/data/" +
                                      this.props.parent.props.data.image
                                  ) +
                                  "?type=preview&jwt=" +
                                  localStorage.getItem("jwt")
                                }
                                className="mb-3 mt-2 d-block pointer w-75"
                                //style={{ height: "300px" }}
                                onClick={(e) => {
                                  UniFun.readingsPreviewFile(
                                    {
                                      title: "Question Image",
                                      download: false,
                                      mimetype: "image/jpeg",
                                      fileurl: window["baseurl"](
                                        "/agenda-file/data/" +
                                          this.props.parent.props.data.image +
                                          "?type=preview&jwt=" +
                                          localStorage.getItem("jwt")
                                      ),
                                      filedownloadurl: window["baseurl"](
                                        "/agenda-file/data/" +
                                          this.props.parent.props.data.image +
                                          "?type=download&jwt=" +
                                          localStorage.getItem("jwt")
                                      ),
                                    },
                                    null
                                  );
                                }}
                              ></img>
                            ) : (
                              ""
                            )}
                            {this.props.value}
                            {/* {this.props.parent.props.parent.props.data
                              .evaluationMode &&
                            this.props.parent.props.data.type == "essayType" ? (
                              <input
                                type="hidden"
                                defaultValue={this.props.value}
                                name={this.state.name}
                              ></input>
                            ) : (
                              ""
                            )} */}
                          </span>
                        );
                      }
                    }

                    let qtype = this.props.parent.props.data.type;

                    switch (qtype) {
                      case "essayType":
                        return (
                          <div>
                            {this.props.parent.props.data.image ? (
                              <img
                                src={
                                  window["baseurl"](
                                    "/agenda-file/data/" +
                                      this.props.parent.props.data.image
                                  ) +
                                  "?type=preview&jwt=" +
                                  localStorage.getItem("jwt")
                                }
                                className="mb-3 mt-2 pointer w-75"
                                // style={{ height: "300px" }}
                                onClick={(e) => {
                                  UniFun.readingsPreviewFile(
                                    {
                                      title: "Question Image",
                                      download: false,
                                      mimetype: "image/jpeg",
                                      fileurl: window["baseurl"](
                                        "/agenda-file/data/" +
                                          this.props.parent.props.data.image +
                                          "?type=preview&jwt=" +
                                          localStorage.getItem("jwt")
                                      ),
                                      filedownloadurl: window["baseurl"](
                                        "/agenda-file/data/" +
                                          this.props.parent.props.data.image +
                                          "?type=download&jwt=" +
                                          localStorage.getItem("jwt")
                                      ),
                                    },
                                    null
                                  );
                                }}
                              ></img>
                            ) : (
                              ""
                            )}
                            <textarea
                              className="form-control mt-2"
                              defaultValue={this.props.value}
                              name={this.state.name}
                            ></textarea>
                          </div>
                        );
                        break;

                      case "multipleChoice":
                        return (
                          <React.Fragment>
                            {this.props.value}
                            {args.control}
                          </React.Fragment>
                        );
                        break;
                    }
                  },
                },
                image: {
                  alias: "image",
                  control: "text",
                  label: "",
                  placeholder: "",
                  editable: editable,
                  useCustomLayout: true,
                  customLayout: function (args) {
                    if (this.state.defaultValue) {
                      return (
                        <img
                          src={
                            window["baseurl"](
                              "/agenda-file/data/" + this.state.defaultValue
                            ) +
                            "?type=preview&jwt=" +
                            localStorage.getItem("jwt")
                          }
                          className="mb-2 mt-2 pointer w-75"
                          //style={{ height: "300px" }}
                          onClick={(e) => {
                            UniFun.readingsPreviewFile(
                              {
                                title: "Question Image",
                                download: false,
                                mimetype: "image/jpeg",
                                fileurl: window["baseurl"](
                                  "/agenda-file/data/" +
                                    this.state.defaultValue +
                                    "?type=preview&jwt=" +
                                    localStorage.getItem("jwt")
                                ),
                                filedownloadurl: window["baseurl"](
                                  "/agenda-file/data/" +
                                    this.state.defaultValue +
                                    "?type=download&jwt=" +
                                    localStorage.getItem("jwt")
                                ),
                              },
                              null
                            );
                          }}
                        ></img>
                      );
                    }
                    return "";
                  },
                  attributes: {
                    className: "form-control",
                  },
                },
              },
            },
          },
          mark: {
            alias: "mark",
            control: "text",
            label: "",
            placeholder: "0",
            editable: true,
            useCustomLayout: true,
            customLayout: function (args) {
              if (
                (this.props.parent.props.data.evaluationMode ||
                  this.props.parent.props.data.status == "Fail" ||
                  this.props.parent.props.data.status == "Pass") &&
                this.props.data.type == "essayType"
              ) {
                return (
                  <div className="row pt-4 mt-0 pl-3">
                    <div className="col-3">
                      <div className="input-group mb-2 mr-sm-2">
                        <div className="input-group-prepend">
                          <div className="input-group-text">
                            {this.props.parent.props.data.evaluationMode
                              ? "Grade question (0-10)"
                              : "Instructor's Grade"}
                          </div>
                        </div>
                        {this.props.parent.props.data.evaluationMode ? (
                          args.control
                        ) : (
                          <span className="form-control text-dark text-center">
                            {this.state.defaultValue
                              ? this.state.defaultValue + "/10"
                              : "0/10"}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-8"></div>
                  </div>
                );
              }
              return "";
            },
            attributes: {
              className: "form-control",
            },
          },
        },
      },
    },
    createdBy: {
      alias: "createdBy",
      control: "text",
      label: "Created by",
      editable: editable,
      attributes: {
        className: "form-control",
      },
    },
    modified: {
      alias: "modified",
      control: "datetime",
      label: "Last modified",
      editable: editable,
      attributes: {
        className: "form-control",
      },
    },
    from: {
      alias: "from",
      control: "datetime",
      label: "From",
      editable: editable,
      attributes: {
        className: "form-control",
      },
    },
    to: {
      alias: "to",
      control: "datetime",
      label: "To",
      editable: editable,
      attributes: {
        className: "form-control",
      },
    },
    examDuration: {
      alias: "examDuration",
      control: "text",
      label: "Duration",
      editable: editable,
      attributes: {
        className: "form-control",
      },
    },
    evaluationMode: {
      alias: "evaluationMode",
      control: "text",
      label: "",
      editable: editable,
      attributes: {
        className: "form-control",
      },
    },
    startTest: {
      alias: "startTest",
      control: "text",
      caption: "Start Exam",
      editable: false,
      useCustomLayout: true,
      customLayout: function () {
        let event = this.props.data;
        return UniFun.createStartExamBtn(event);
      },
    },
    viewResults: {
      alias: "viewResults",
      control: "button",
      caption: "View",
      editable: false,
      useCustomLayout: true,
      customLayout: function (args) {
        if (this.props.data.userExamInfo) {
          if (this.props.data.userExamInfo.status) {
            if (
              this.props.data.userExamInfo.status.toLowerCase() == "fail" ||
              this.props.data.userExamInfo.status.toLowerCase() == "pass" ||
              this.props.data.userExamInfo.status.toLowerCase() == "no grade"
            ) {
              return <span>{args.control}</span>;
            }
          }
        }
        return "";
      },
      events: {
        onClick: function () {
          if (this.props.data.userExamInfo) {
            this.props.history.push(
              "/event/test/" + this.props.data.userExamInfo._id + "/result"
            );
          } else {
            this.props.history.push(
              "/event/test/" + this.props.data._id + "/results"
            );
          }
        },
      },
      attributes: {
        className: "btn btn-primary",
      },
    },
    resultStatus: {
      alias: "resultStatus",
      control: "ajax",
      label: "",
      config: {
        url: {
          url: "/exam/{id}/user-exam",
          parameters: {
            replace: {
              id: "data[_id]",
            },
          },
        },
      },
      useCustomLayout: true,
      customLayout: function (args) {
        let event = this.props.data;
        let data = this.state.defaultValue.data;

        if (moment() < moment(event.from)) {
          return "Test has not started yet";
        }

        if (
          UniFun.userIsEligible({ forWhat: "default" }) ||
          UniFun.userIsEligible({ forWhat: "viewer" })
        ) {
          let submitted = data.filter(function (itm) {
            if (itm.hasOwnProperty("examEvent")) {
              if (
                itm.status.toLowerCase() == "fail" ||
                itm.status.toLowerCase() == "pass" ||
                itm.status.toLowerCase() == "not evaluated" ||
                itm.status.toLowerCase() == "no grade"
              ) {
                return true;
              }
            }
            return false;
          });

          return (
            submitted.length +
            "/" +
            data.length +
            " attendees have submitted the test"
          );
        } else {
          data = data[0] ? data[0] : [];

          if (!data.status) {
            return "You haven't started the test yet";
          }

          if (data.status.toLowerCase() == "pending") {
            return "You haven't submitted the test yet";
          }

          if (UniFun.isBetweenDates(event.from, event.to) && !data.examEvent) {
            return "You haven't submitted the test yet";
          }

          let answerCorrectQuestions = data.questions.length;
          let hasEssay = false;

          data.questions.map((itm, idx) => {
            if (itm.type !== "essayType") {
              let answerIsCorrect = true;

              itm.answers.map((aitm, aid) => {
                let isCorrect = aitm.isCorrect
                  ? aitm.isCorrect.toString()
                  : "false";
                let userAnswer = aitm.userAnswer.toString();

                if (isCorrect != userAnswer) {
                  answerIsCorrect = false;
                }
              });

              if (!answerIsCorrect) {
                answerCorrectQuestions--;
              }
            } else {
              hasEssay = true;
            }
          });
          if (data.status.toLowerCase() == "not evaluated") {
            return (
              <div className="ml-3">
                <span>
                  Pending evaluation: You will receive an email notification
                  when the test is evaluated.
                </span>
              </div>
            );
          }

          if (data.passingGrade > 0) {
            return (
              <React.Fragment>
                <div className="ml-3">
                  <span
                    className={
                      data.status.toLowerCase() == "fail"
                        ? "text-danger"
                        : "text-success"
                    }
                  >
                    {data.status}{" "}
                  </span>
                  :
                  <span>
                    {"Your final grade is " + data.finalGrade + "%"}
                    {/* {hasEssay
                      ? " Your final grade is " + data.finalGrade + "%"
                      : " You have answered " +
                        answerCorrectQuestions +
                        "/" +
                        data.questions.length +
                        " of the questions correctly and your score was " +
                        data.finalGrade +
                        "%"} */}
                  </span>
                </div>
              </React.Fragment>
            );
          } else {
            return (
              <React.Fragment>
                <div className="ml-3">
                  <span>
                    {"Your final grade is " + data.finalGrade + "%"}
                    {/* {hasEssay
                      ? " Your final grade is " + data.finalGrade + "%"
                      : " You have answered " +
                        answerCorrectQuestions +
                        "/" +
                        data.questions.length +
                        " of the questions correctly and your score was " +
                        data.finalGrade +
                        "%"} */}
                  </span>
                </div>
              </React.Fragment>
            );
          }
        }
      },
      attributes: {
        className: "form-control",
      },
    },
    resultsParticipants: {
      alias: "resultsParticipants",
      control: "module",
      editable: editable,
      config: {
        module: "unitaketest",
        view: "resultsParticipants",
      },
    },
    testParticipants: {
      alias: "testParticipants",
      control: "module",
      editable: editable,
      config: {
        module: "uniparticipantsandfilesevent",
        view: "participants",
        moduleParams: { type: "tests" },
        parameters: {
          replace: {
            path: function () {
              return "/event/exam/" + Fun.getURLPathValue(3) + "/participants";
            },
          },
        },
      },
    },
    editdropdown: {
      alias: "editdropdown",
      control: "dropdownbutton",
      caption: (
        <img src="/uni/svg/more-options.svg" style={{ width: "14px" }}></img>
      ),
      editable: true,
      config: {
        removeToggleIcon: true,
        options: {
          edit: {
            label: "Edit",
            events: {
              onClick: function () {
                let builder = this.props.builder;

                let params = {
                  replace: {
                    id: this.props.value,
                  },
                };
                window["modal"].setState({
                  title: <h3>Edit test</h3>,
                  size: "xl",
                  targetComponent: this,
                  footer: UniFun.modalCommonFooter("modal", "Update"),
                  callback: function () {
                    window["modal"].setState({
                      show: false,
                      overflowY: "auto",
                    });
                    builder.rerunComponent();
                  },
                  show: true,
                  body: (
                    <Builder
                      module="unitestevent"
                      view="editEvent"
                      parameters={params}
                      ref={(refComponent) => {
                        window["modalbody"] = refComponent;
                      }}
                    ></Builder>
                  ),
                });
              },
            },
          },
          delete: {
            label: <span className="text-danger">Delete</span>,
            events: {
              onClick: function () {
                let builder = this.props.builder;
                let examId = this.props.value;

                deleteExam(examId, builder);
              },
            },
          },
        },
      },
      attributes: {
        className: "btn btn-link m-2 ",
      },
    },
    userExamInfo: {
      alias: "userExamInfo",
      control: "text",
      editable: editable,
    },
    examEvent: {
      alias: "examEvent",
      control: "text",
      editable: editable,
    },
    submitEvaluation: {
      alias: "submitEvaluation",
      control: "submit",
      action: "submitevaluation",
      caption: "Submit evaluation",
      editable: editable,
      attributes: {
        className: "btn btn-primary ",
      },
      events: {
        onClick: function () {},
      },
    },
    submit: {
      alias: "submit",
      control: "submit",
      caption: "Submit",
      action: "finalize",
      editable: editable,
      attributes: {
        className: "btn btn-primary ",
      },
    },
  };
}

export default UniTakeTest;
