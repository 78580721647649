import React, { Component } from "react";

class ConfirmPassword extends Component {
  constructor(props) {
    super(props);

    let builder = this.props.builder.state;
    let templateConfig = builder.module.templateConfig;
    if (templateConfig.events) {
      Object.keys(templateConfig.events).map((itm, idx) => {
        this[itm] = templateConfig.events[itm].bind(this);
      });
    }

    this.state = {
      loaded: false,
    };

    this.form = React.createRef();
  }

  componentDidMount() {
    this.setState({ loaded: true });
  }

  render() {
    if (this.state.loaded) {
      return (
        <React.Fragment>
          <div className="text-center">
            <img
              src="/uni/images/company-logo.png"
              style={{ height: "100px" }}
            ></img>
            <h1>Create your password</h1>
            <p className="text-muted">
              The password must contain at least 8 characters, 1 letter and 1
              number.
            </p>
          </div>
          <form onSubmit={this.onSubmit} ref={this.form}>
            <div className="form-group ">
              <label className="text-dark">New password</label>
              <div>
                <input
                  className="form-control"
                  type="password"
                  placeholder="Type password"
                  name="password"
                />
              </div>
            </div>
            <div className="form-group ">
              <label className="text-dark">Confirm new password</label>

              <div>
                <input
                  className="form-control"
                  type="password"
                  placeholder="Retype password"
                  name="confirmPassword"
                />
              </div>
            </div>

            <div className="form-group ">
              <button className="btn btn-primary btn-block" type="submit">
                Change password
              </button>
            </div>
          </form>
        </React.Fragment>
      );
    }
    return "";
  }
}

export default ConfirmPassword;
