import React, { Component } from "react";
import Fun from "../../../lib/functions";

class SelectBoxes extends Component {
  state = {
    data: [],
    loaded: false,
    colsTemplateCount: 12,
    colsFormCount: 12,
  };

  constructor(props) {
    super(props);
    let builder = this.props.builder.state;
    let templateConfig = builder.module.templateConfig;
    if (templateConfig.events) {
      Object.keys(templateConfig.events).map((itm, idx) => {
        this[itm] = templateConfig.events[itm].bind(this);
      });
    }

    this.form = React.createRef();

    this.output = this.output.bind(this);
  }

  componentDidMount() {
    let builder = this.props.builder.state;
    let templateConfig = builder.module.templateConfig;
    let templateTop = templateConfig.templateTop
      ? templateConfig.templateTop
      : () => {};
    this["templateTop"] = templateTop.bind(this);
    let templateBottom = templateConfig.templateBottom
      ? templateConfig.templateBottom
      : () => {};
    this["templateBottom"] = templateBottom.bind(this);
    let templateLeft = templateConfig.templateLeft
      ? templateConfig.templateLeft
      : () => {};
    this["templateLeft"] = templateLeft.bind(this);
    let templateRight = templateConfig.templateRight
      ? templateConfig.templateRight
      : () => {};
    this["templateRight"] = templateRight.bind(this);
    let panelTop = templateConfig.panelTop ? templateConfig.panelTop : () => {};
    this["panelTop"] = panelTop.bind(this);
    let panelBottom = templateConfig.panelBottom
      ? templateConfig.panelBottom
      : () => {};
    this["panelBottom"] = panelBottom.bind(this);
    let panelLeft = templateConfig.panelLeft
      ? templateConfig.panelLeft
      : () => {};
    this["panelLeft"] = panelLeft.bind(this);
    let panelRight = templateConfig.panelRight
      ? templateConfig.panelRight
      : () => {};
    this["panelRight"] = panelRight.bind(this);
    let header = templateConfig.header ? templateConfig.header : () => {};
    this["header"] = header.bind(this);

    let colsTemplateCount = templateConfig.colsTemplateCount
      ? templateConfig.colsTemplateCount
      : this.state.colsTemplateCount;
    let colsFormCount = templateConfig.colsFormCount
      ? templateConfig.colsFormCount
      : this.state.colsFormCount;

    this.setState({
      data: this.props.data,
      loaded: true,
      colsTemplateCount: colsTemplateCount,
      colsFormCount: colsFormCount,
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.action) {
      return false;
    }
    return true;
  }

  output() {
    let rows = [];

    let data = this.state.data;

    let colCount = 0;

    data.map((itm, idx) => {
      colCount = 0;
      Object.keys(itm).map((citm, cidx) => {
        if (itm[citm].showPropertyOnTemplate) {
          if (itm[citm].showPropertyOnTemplate == true) {
            colCount++;
          }
        }
      });
    });

    data.map((itm, idx) => {
      let cells = [];
      Object.keys(itm).map((citm, cidx) => {
        if (itm[citm].showPropertyOnTemplate) {
          if (itm[citm].showPropertyOnTemplate) {
            let itmComponent = itm[citm].component;

            let colNum = 12 / colCount;
            cells.push(itmComponent);
          }
        }
      });
      rows.push(
        <div className="row border p-4 m-2 bg-light rounded" key={Fun.uuid()}>
          <div className="col-12">
            <div className="input-group">{cells}</div>
          </div>
        </div>
      );
    });

    return rows;
  }

  render() {
    if (this.state.loaded) {
      return (
        <React.Fragment>
          {this.header()}
          {this.panelTop()}
          <div className="container-fluid">
            <div className="row">
              {this.panelLeft()}
              <div className={"col-" + this.state.colsTemplateCount}>
                <form onSubmit={this.onSubmit} ref={this.form}>
                  {this.templateLeft()}
                  {this.templateTop()}
                  {this.output()}
                  {this.templateBottom()}
                  {this.templateRight()}
                </form>
              </div>
              {this.panelRight()}
            </div>
          </div>
          {this.panelBottom()}
        </React.Fragment>
      );
    } else {
      return "loading...";
    }
  }
}

export default SelectBoxes;
