import React, { Component } from "react";
import Controls from "../../../../../controls/controls";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import Fun from "../../../../../lib/functions";

class Footer extends Component {
  state = {
    data: this.props.data,
    loaded: false,
  };

  constructor(props) {
    super(props);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.action) {
      return false;
    }
    return true;
  }

  componentDidMount() {
    let field = {
      alias: "companyInfo",
      control: "ajax",
      label: "",
      config: {
        url: {
          url: "/getCompanySettings",
          parameters: {
            replace: {},
          },
        },
      },
      useCustomLayout: true,
      customLayout: function (args) {
        if (this.state.defaultValue) {
          if (this.state.defaultValue.data) {
            if (this.state.defaultValue.data[0]) {
              if (this.state.defaultValue.data[0].name) {
                return (
                  <span className="text-white text-strong">
                    @{this.state.defaultValue.data[0].name}
                  </span>
                );
              }
            }
          }
        }
        return "";
      },
    };

    let Control = Controls["ajax"];

    this.setState({
      loaded: true,
      company: <Control field={field} key={Fun.uuid()}></Control>,
    });

    if (this.componentDidMountEvent) {
      this.componentDidMountEvent();
    }
  }

  render() {
    return (
      <footer className="mt-auto" style={{ minHeight: "100px" }}>
        <div className="container-fluid mt-4 pt-5">
          <div className="container">
            <div className="row">
              <div className="col-md-8 text-white">{this.state.company}</div>
              <div className="col-md-4 text-right">
                <Link
                  className="mr-2 pt-4 pl-4 text-white"
                  to={"/events/conferences/terms"}
                >
                  <strong>Terms</strong>
                </Link>
                <Link
                  className=" pt-4 pl-4  text-white"
                  to={"/events/conferences/privacy"}
                >
                  <strong>Privacy</strong>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default withRouter(Footer);
