import React, { Component } from "react";
import Control from "../../control";
import SimpleReactValidator from "simple-react-validator";
import Fun from "../../../../../lib/functions";
import UniFun from "../../../../../modules/uni/functions";
import moment from "moment";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import Menu from "./menu";

class Header extends Component {
  state = {
    data: this.props.data,
    rawData: this.props.rawData,
    loaded: false,
    activeTab: this.props.activeTab ? this.props.activeTab : 0,
    showMenu: this.props.showMenu === "false" ? this.props.showMenu : true,
  };

  constructor(props) {
    super(props);

    this.form = React.createRef();
    this.interestFormModal = this.props.interestFormModal;
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.action) {
      return false;
    }
    return true;
  }

  componentDidMount() {
    this.setState({
      loaded: true,
    });

    if (this.componentDidMountEvent) {
      this.componentDidMountEvent();
    }
  }

  render() {
    return (
      <React.Fragment>
        <nav className="navbar navbar-default public-header">
          <div className=" container-fluid p-0">
            <div className="row w-100">
              <div className="col-md-6 ">
                <div className="container">
                  <div className="navbar-header logo-div">
                    <Link to="/events/conferences" className="navbar-brand">
                      <img
                        src="/uni/images/company-logo.png"
                        style={{ height: "75px" }}
                      ></img>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-md-6 ">
                {this.state.rawData ? (
                  <div className="row">
                    <div className="col-md-8 p-2  ">
                      <div className=" text-strong event-title text-right text-sm-conf-center pt-2">
                        {UniFun.fromToOnlyMonthString(
                          this.state.rawData.data[0].startDate,
                          this.state.rawData.data[0].endDate
                        )}
                      </div>
                    </div>
                    <div className="col-md-4 p-2 text-sm-conf-center">
                      <div className="btn-group ">
                        <button
                          type="button"
                          className="btn btn-primary radius25 bg-darkblue text-white  mr-2"
                          onClick={(e) => {
                            window.location.replace(
                              "/login?redirect=" + window.location.href
                            );
                          }}
                        >
                          Join event
                        </button>

                        <button
                          type="button"
                          className="btn btn-link radius25 border text-darkblue border-darkblue "
                          onClick={this.interestFormModal}
                        >
                          Reserve seat
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </nav>
        <div className="container-fluid public-top-header text-center text-white">
          {/* <h2 className="text-dark  pb-2">{this.state.data[0].title.value}</h2> */}
          {this.state.data ? (
            <Menu
              data={this.state.data}
              activeTab={this.state.activeTab}
            ></Menu>
          ) : (
            ""
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(Header);
