import React, { Component } from "react";
import Fun from "../../lib/functions";
import Builder from "../../lib/builder";
import UniFun from "./functions";
import Control from "../../themes/default/templates/control";

const resendInvitation = function (userId, parentBuilder) {
  let builder = parentBuilder;

  let parameters = {
    replace: {
      id: userId,
    },
  };
  window["modal2"].setState({
    title: (
      <React.Fragment>
        <h3 className="pl-3">Resend Invitation?</h3>
      </React.Fragment>
    ),
    size: "lg",
    targetComponent: this,
    callback: function () {
      window["modal2"].setState({ show: false, overflowY: "auto" });
      Fun.createNotification({
        type: "success",
        message: "Resend invitation was successful!",
      });
      builder.rerunComponent();
    },
    footer: UniFun.modalCommonFooter("modal2", "Resend"),
    show: true,
    body: (
      <Builder
        module="uniusers"
        view="resend"
        parameters={parameters}
        key={Fun.uuid()}
        ref={(refComponent) => {
          window["modal2body"] = refComponent;
        }}
      ></Builder>
    ),
  });
};

const deActivateUser = function (userId, parentBuilder) {
  let builder = parentBuilder;

  let parameters = {
    replace: {
      id: userId,
    },
  };
  window["modal2"].setState({
    title: (
      <React.Fragment>
        <h3 className="pl-3">Deactivate user?</h3>
      </React.Fragment>
    ),
    size: "lg",
    targetComponent: this,
    callback: function () {
      window["modal2"].setState({ show: false, overflowY: "auto" });
      Fun.createNotification({
        type: "success",
        message: "Deactivate was successful!",
      });
      builder.rerunComponent();
    },
    footer: UniFun.modalCommonFooter("modal2", "Deactivate", "btn-danger"),
    show: true,
    body: (
      <Builder
        module="uniusers"
        view="deactivate"
        parameters={parameters}
        key={Fun.uuid()}
        ref={(refComponent) => {
          window["modal2body"] = refComponent;
        }}
      ></Builder>
    ),
  });
};

const activateUser = function (userId, parentBuilder) {
  let builder = parentBuilder;

  let parameters = {
    replace: {
      id: userId,
    },
  };
  window["modal2"].setState({
    title: (
      <React.Fragment>
        <h3 className="pl-3">Activate user?</h3>
      </React.Fragment>
    ),
    size: "lg",
    targetComponent: this,
    callback: function () {
      window["modal2"].setState({ show: false, overflowY: "auto" });
      Fun.createNotification({
        type: "success",
        message: "Activating user was successful!",
      });
      builder.rerunComponent();
    },
    footer: UniFun.modalCommonFooter("modal2", "Activate"),
    show: true,
    body: (
      <Builder
        module="uniusers"
        view="activate"
        parameters={parameters}
        key={Fun.uuid()}
        ref={(refComponent) => {
          window["modal2body"] = refComponent;
        }}
      ></Builder>
    ),
  });
};

const revokeInvitation = function (userId, parentBuilder) {
  let builder = parentBuilder;

  let parameters = {
    replace: {
      id: userId,
    },
  };
  window["modal2"].setState({
    title: (
      <React.Fragment>
        <h3 className="pl-3">Revoke Invitation?</h3>
      </React.Fragment>
    ),
    size: "lg",
    targetComponent: this,
    callback: function () {
      window["modal2"].setState({ show: false, overflowY: "auto" });
      Fun.createNotification({
        type: "success",
        message: "Revoke was successful!",
      });
      builder.rerunComponent();
    },
    footer: UniFun.modalCommonFooter("modal2", "Revoke", "btn-danger"),
    show: true,
    body: (
      <Builder
        module="uniusers"
        view="revoke"
        parameters={parameters}
        key={Fun.uuid()}
        ref={(refComponent) => {
          window["modal2body"] = refComponent;
        }}
      ></Builder>
    ),
  });
};

const deleteUser = function (userId, parentBuilder) {
  let builder = parentBuilder;

  let parameters = {
    replace: {
      id: userId,
    },
  };
  window["modal2"].setState({
    title: <h3>Delete User?</h3>,
    size: "lg",
    targetComponent: this,
    callback: function () {
      window["modal2"].setState({ show: false, overflowY: "auto" });

      Fun.createNotification({
        type: "success",
        message: "User has been deleted!",
      });
      builder.rerunComponent();
    },
    footer: UniFun.modalCommonFooter("modal2", "Delete", "btn-danger"),
    show: true,
    body: (
      <Builder
        module="uniusers"
        view="deleteUser"
        key={Fun.uuid()}
        parameters={parameters}
        ref={(refComponent) => {
          window["modal2body"] = refComponent;
        }}
      ></Builder>
    ),
  });
};

const discardChanges = function () {
  if (
    Object.keys(
      JSON.parse(window["modalbody"]["fileupload-undefined"].state.defaultValue)
    ).length < 1
  ) {
    window["modal"].setState({ show: false, onClose: null, overflowY: "auto" });
  } else {
    window["modal2"].setState({
      title: <h3>Discard unsaved changes?</h3>,
      size: "def",
      targetComponent: this,
      footer: [
        <button
          type="button"
          className="btn btn-link border"
          onClick={() => {
            window["modal2"].setState({ show: false, overflowY: "auto" });
          }}
        >
          Cancel
        </button>,
        <button
          type="button"
          className="btn btn-primary border "
          onClick={() => {
            window["modal2"].setState({ show: false, overflowY: "auto" });
            window["modal"].setState({
              show: false,
              onClose: null,
              overflowY: "auto",
            });
          }}
        >
          Discard
        </button>,
      ],
      show: true,
      body: (
        <p className="pl-4 pt-4 pr-4 mt-3 ml-2">
          Click cancel to continue editing or discard to exit without saving
        </p>
      ),
    });
  }
};

const selectAdd = function (parentBuilder) {
  let builder = parentBuilder;
  window["modal"].setState({
    title: (
      <React.Fragment>
        <h3 className="pl-3">Invite new users</h3>
      </React.Fragment>
    ),
    size: "lg",
    targetComponent: this,
    parentBuilder: builder,
    footer: [
      <button
        type="button"
        className="btn btn-link border"
        onClick={() => {
          window["modal"].setState({ show: false, overflowY: "auto" });
        }}
      >
        Cancel
      </button>,
      <button
        type="button"
        className="btn btn-primary border"
        onClick={() => {
          Fun.submitFromModal("modalbody");
        }}
      >
        Next
      </button>,
    ],
    show: true,
    body: (
      <Builder
        module="uniusers"
        view="selectNewOrUpload"
        //parameters={parameters}
        key={Fun.uuid()}
        ref={(refComponent) => {
          window["modalbody"] = refComponent;
        }}
      ></Builder>
    ),
  });
};
const registerDirectly = function (parentBuilder) {
  let builder = parentBuilder;
  window["modal"].setState({
    title: (
      <React.Fragment>
        <h3 className="pl-3">New users</h3>
      </React.Fragment>
    ),
    size: "lg",
    targetComponent: this,
    parentBuilder: builder,
    onClose: function () {
      discardChanges();
    },
    footer: [
      <button
        type="button"
        className="btn btn-link border"
        onClick={() => {
          discardChanges();
        }}
      >
        Cancel
      </button>,
      <button
        type="button"
        className="btn btn-primary border  sendBulkInvite"
        // disabled
        onClick={() => {
          Fun.submitFromModal("modalbody");
        }}
      >
        <span className="sendBulkInvite-loader mr-2"></span>Register users
      </button>,
    ],
    show: true,
    body: (
      <div className="p-3">
        <Builder
          module="uniusers"
          view="registerDirectly"
          //parameters={parameters}
          key={Fun.uuid()}
          ref={(refComponent) => {
            window["modalbody"] = refComponent;
          }}
        ></Builder>
      </div>
    ),
  });
};

const importUsers = function (parentBuilder) {
  let builder = parentBuilder;
  window["modal"].setState({
    title: (
      <React.Fragment>
        <h3 className="pl-3">Invite new users</h3>
      </React.Fragment>
    ),
    size: "lg",
    targetComponent: this,
    parentBuilder: builder,
    onClose: function () {
      discardChanges();
    },
    footer: [
      <button
        type="button"
        className="btn btn-link border"
        onClick={() => {
          discardChanges();
        }}
      >
        Cancel
      </button>,
      <button
        type="button"
        className="btn btn-primary border  sendBulkInvite"
        // disabled
        onClick={() => {
          Fun.submitFromModal("modalbody");
        }}
      >
        <span className="sendBulkInvite-loader mr-2"></span>Send bulk invite
      </button>,
    ],
    show: true,
    body: (
      <div className="p-3">
        <Builder
          module="uniusers"
          view="importUsers"
          //parameters={parameters}
          key={Fun.uuid()}
          ref={(refComponent) => {
            window["modalbody"] = refComponent;
          }}
        ></Builder>
      </div>
    ),
  });
};

const addUser = function (parentBuilder) {
  let builder = parentBuilder;
  window["modal"].setState({
    title: (
      <React.Fragment>
        <h3 className="pl-3">Invite new users</h3>
      </React.Fragment>
    ),
    size: "lg",
    targetComponent: this,
    parentBuilder: builder,
    footer: UniFun.modalCommonFooter("modal", "Send invitation"),
    show: true,
    body: (
      <Builder
        module="uniusers"
        view="add"
        //parameters={parameters}
        key={Fun.uuid()}
        ref={(refComponent) => {
          window["modalbody"] = refComponent;
        }}
      ></Builder>
    ),
  });
};

const showUserBIO = function (id, parentBuilder) {
  let builder = parentBuilder;
  let parameters = {
    replace: {
      id: id,
    },
  };

  let footerBtns = [
    <button
      type="button"
      className="btn btn-link border"
      onClick={() => {
        window["modal2"].setState({ show: false, overflowY: "auto" });
      }}
    >
      Cancel
    </button>,
  ];

  if (
    UniFun.userIsEligible({ forWhat: "default" }) ||
    localStorage.getItem("userid") == id
  ) {
    footerBtns.push(
      <button
        type="button"
        className="btn btn-primary border"
        onClick={() => {
          editUser(id, parentBuilder);
        }}
      >
        Edit
      </button>
    );
  }

  window["modal2"].setState({
    title: "",
    size: "xl",
    targetComponent: this,
    callback: function () {},
    footer: footerBtns,
    show: true,
    body: (
      <Builder
        module="uniusers"
        view="userBio"
        parameters={parameters}
        key={Fun.uuid()}
        ref={(refComponent) => {
          window["modal2body"] = refComponent;
        }}
      ></Builder>
    ),
  });
};

const editUser = function (id, parentBuilder) {
  let builder = parentBuilder;
  let parameters = {
    replace: {
      id: id,
    },
  };

  let footerBtns = UniFun.modalCommonFooter("modal", "Save");

  if (!UniFun.userIsEligible({ forWhat: "module" })) {
    footerBtns = [
      <button
        type="button"
        className="btn btn-link border"
        onClick={() => {
          window["modal"].setState({ show: false, overflowY: "auto" });
        }}
      >
        Cancel
      </button>,
    ];
  }

  window["modal"].setState({
    title: (
      <React.Fragment>
        <h3 className="pl-3">Profile settings</h3>
      </React.Fragment>
    ),
    size: "lg",
    targetComponent: this,
    callback: function () {
      window["modal"].setState({ show: false, overflowY: "auto" });
      window["modal2"].setState({ show: false, overflowY: "auto" });
      Fun.createNotification({
        type: "success",
        message: "Update was successful!",
      });
      builder.rerunComponent();
    },
    footer: footerBtns,

    show: true,
    body: (
      <Builder
        module="uniusers"
        view="detail"
        parameters={parameters}
        key={Fun.uuid()}
        ref={(refComponent) => {
          window["modalbody"] = refComponent;
        }}
      ></Builder>
    ),
  });
};

const UniUsers = {
  auth: "",
  views: {
    list: {
      // auth: function () {
      //   return UniFun.userIsEligible({ forWhat: "module" });
      // },
      apis: {
        view: {
          url: "/users?start={start}&offset={offset}",
          parameters: {
            replace: {
              start: 0,
              offset: 25,
            },
          },
          formatter: function (data, builder) {
            return UniUsersFormatter(data, builder);
          },
        },
      },
      template: "grid",
      templateConfig: {
        events: {},
        headerTitle: "Organization",
        headerTitleIcon: "/uni/svg/usersmain.svg",
        colsTemplateCount: 12,
        // includeProperties: [
        //   "fullName",
        //   "email",
        //   "roles",
        //   "instructor",
        //   "status",
        // ],
        includeProperties: function () {
          if (UniFun.userIsEligible({ forWhat: "default" })) {
            return ["fullName", "email", "roles", "instructor", "status"];
          }

          return ["fullName", "email"];
        },
        gridConfig: {
          headers: {
            fullName: {
              attributes: {
                className: "w-50",
              },
            },
            email: {
              attributes: {
                className: "d-none d-md-table-cell ",
              },
            },
            roles: {
              attributes: {
                className: "d-none d-md-table-cell",
              },
            },
            instructor: {
              attributes: {
                className: "d-none d-md-table-cell",
              },
            },
            status: {
              attributes: {
                className: "d-none d-md-table-cell",
              },
            },
          },
          cell: {
            email: {
              attributes: {
                className: "d-none d-md-table-cell",
              },
            },
            roles: {
              attributes: {
                className: "d-none d-md-table-cell",
              },
            },
            instructor: {
              attributes: {
                className: "d-none d-md-table-cell",
              },
            },
            status: {
              attributes: {
                className: "d-none d-md-table-cell",
              },
            },
          },
          cellTemplates: {
            email: function (data, rec, obj) {
              let opacity = "";

              if (rec.deleted.value) {
                opacity = "opacity-50 deactivated-bg";
              }
              return <span className={opacity}>{data}</span>;
            },
            roles: function (data, rec, obj) {
              let opacity = "";

              if (rec.deleted.value) {
                opacity = "opacity-50 deactivated-bg";
              }
              return <span className={opacity}>{data}</span>;
            },
            instructor: function (data, rec, obj) {
              let opacity = "";

              if (rec.deleted.value) {
                opacity = "opacity-50 deactivated-bg";
              }
              return (
                <span className={opacity}>
                  {rec.instructor.value ? data : "Not specified"}
                </span>
              );
            },
            status: function (data, rec, obj) {
              let opacity = "";

              if (rec.deleted.value) {
                opacity = "opacity-50 deactivated-bg";
              }
              return <span className={opacity}>{data}</span>;
            },
            fullName: function (data, rec, obj) {
              let uid = Fun.uuid();

              let builder = obj.props.builder;
              let opacity = "";

              let deactivated = (
                <li
                  className="list-group-item p-2 pl-3"
                  key={Fun.uuid()}
                  onClick={(e) => deActivateUser(rec._id.value, builder)}
                >
                  Deactivate user
                </li>
              );

              if (rec.deleted.value) {
                opacity = "opacity-50 deactivated-bg";
                deactivated = (
                  <li
                    className="list-group-item p-2 pl-3"
                    key={Fun.uuid()}
                    onClick={(e) => activateUser(rec._id.value, builder)}
                  >
                    Activate user
                  </li>
                );
              }

              let options = [
                deactivated,
                <li
                  className="list-group-item p-2 pl-3 text-danger"
                  key={Fun.uuid()}
                  onClick={(e) => deleteUser(rec._id.value, builder)}
                >
                  Delete user
                </li>,
              ];

              if (
                rec.hasPrivateInfo.value != true ||
                UniFun.userIsEligible({ forWhat: "default" }) ||
                UniFun.userIsEligible({ forWhat: "viewer" })
              ) {
                options.unshift(
                  <li
                    className="list-group-item p-2 pl-3"
                    onClick={(e) => showUserBIO(rec._id.value, builder)}
                    key={Fun.uuid()}
                  >
                    View profile
                  </li>
                );
              }

              if (rec.status.value == "registration") {
                options = [
                  <li
                    className="list-group-item p-2 pl-3"
                    key={Fun.uuid()}
                    onClick={(e) => resendInvitation(rec._id.value, builder)}
                  >
                    Resend invitation
                  </li>,
                  <li
                    className="list-group-item p-2 pl-3 text-danger"
                    key={Fun.uuid()}
                    onClick={(e) => revokeInvitation(rec._id.value, builder)}
                  >
                    Revoke invitation
                  </li>,
                ];
              }
              if (!UniFun.userIsEligible({ forWhat: "default" })) {
                options = [
                  <li
                    className="list-group-item p-2 pl-3"
                    onClick={(e) => showUserBIO(rec._id.value, builder)}
                    key={Fun.uuid()}
                  >
                    View profile
                  </li>,
                ];
              }

              return (
                <React.Fragment>
                  {rec.hasPrivateInfo.value != true ||
                  UniFun.userIsEligible({ forWhat: "default" }) ||
                  UniFun.userIsEligible({ forWhat: "viewer" }) ? (
                    <a
                      href="javascript:void(0);"
                      onClick={(e) => showUserBIO(rec._id.value, builder)}
                      className={opacity}
                    >
                      {rec.profileImageList.component}
                    </a>
                  ) : (
                    <span>{rec.profileImageList.component}</span>
                  )}
                  {rec.hasPrivateInfo.value != true ||
                  UniFun.userIsEligible({ forWhat: "default" }) ||
                  UniFun.userIsEligible({ forWhat: "viewer" }) ? (
                    <span className="float-right mr-4 grid-controls mt-2">
                      <i
                        className="fas fa-ellipsis-h pointer"
                        data-toggle="collapse"
                        data-target={"#" + uid}
                      ></i>
                      <div
                        className="position-absolute bg-white  mt-2 collapse"
                        id={uid}
                        style={{ zIndex: 10 }}
                      >
                        <ul className="list-group " style={{ width: "200px" }}>
                          {options}
                        </ul>
                      </div>
                    </span>
                  ) : (
                    ""
                  )}
                </React.Fragment>
              );
            },
          },
        },
        cardTemplateTop: function () {
          let data = this.state.data;
          let parentBuilder = this.props.builder;
          let pagination = this.state.rawData.pagination;
          return (
            <div className="row mb-3">
              <div className="col-6 pt-2 pl-4">
                <span className="text-dark ">
                  There are {pagination.total} users
                </span>
              </div>
              <div className="col-6 text-right p-2 pr-4">
                {UniFun.userIsEligible({ forWhat: "default" }) ? (
                  <>
                    <button
                      type="button"
                      className="btn btn-secondary mr-3 text-black"
                      // onClick={(e) => addUser(parentBuilder)}
                      onClick={UniFun.downloadContacts}
                    >
                      Download
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary bg-navy-blue"
                      // onClick={(e) => addUser(parentBuilder)}
                      onClick={(e) => selectAdd(parentBuilder)}
                    >
                      New Users
                    </button>
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          );
        },
        cardTemplateBottom: function () {
          let data = this.state.data;
          let pagination = this.state.rawData.pagination;
          let parentBuilder = this.props.builder;

          return (
            <div className="mt-4">
              {UniFun.pagination(
                pagination.currentPage,
                0,
                25,
                pagination.total,
                parentBuilder,
                "offset"
              )}
            </div>
          );
        },
        header: UniFun.commonHeader,
      },
      pk: "id",
      properties: UniUsersProperties({ editable: false }),
    },
    detail: {
      auth: "",
      apis: {
        view: {
          //data: { data: [{ firstName: "Simon", lastName: "Kamilieris" }] },
          url: "/user/{id}",
          parameters: {
            headers: { "content-type": "application/json" },
          },
          formatter: function (data, builder) {
            return UniUsersFormatter(data, builder);
          },
        },
        update: {
          //data: { data: [{ firstName: "Simon", lastName: "Kamilieris" }] },
          url: "/user",
          parameters: {
            headers: { "content-type": "application/json" },
          },
          formatter: function (data, builder) {
            return UniUsersFormatter(data, builder);
          },
        },
      },
      template: "form",
      templateConfig: {
        events: {
          onSubmit: function (e) {
            e.preventDefault();

            let submittedData = Fun.getSubmitJSON(e);

            let object = this;

            // submittedData["roles"] = [submittedData["roles"]];

            if (submittedData["_id"] == localStorage.getItem("userId")) {
              localStorage.setItem(
                "profileImage",
                submittedData["profileImage"]
              );
            }

            if (
              submittedData["profileImage"] == "" ||
              submittedData["profileImage"] == "[]"
            ) {
              delete submittedData["profileImage"];
            }

            switch (submittedData["instructor"]) {
              case "instructor":
                submittedData["instructor"] = true;
                submittedData["host"] = false;
                break;

              case "host":
                submittedData["instructor"] = false;
                submittedData["host"] = true;
                break;

              default:
                submittedData["instructor"] = false;
                submittedData["host"] = false;
                break;
            }

            delete submittedData["email"];
            //TBF

            let roles = [];

            if (submittedData["roles"]) {
              try {
                JSON.parse(submittedData["roles"]).map((itm, idx) => {
                  roles.push(itm["_id"]);
                });
              } catch (e) {}
            }
            submittedData["roles"] = roles;

            Fun.update(e, {
              url: this.props.builder.state.module.apis.update,
              method: "PATCH",
              callback: function (submittedData) {
                if (window["modal"].state.callback) {
                  window["modal"].state.callback(submittedData);
                }
              },
              parameters: {
                headers: {
                  "content-type": "application/json",
                },
              },
              submittedData: JSON.stringify(submittedData),
            });
          },
        },
        colsTemplateCount: 12,
        includeProperties: [
          "_id",
          //   "email",
          //   "roles",
          //   "status",
          //   "company",
          //   "department",
          //   "phone",
        ],

        cardTemplateTop: function () {
          let data = this.state.data[0];

          return (
            <div>
              <div className="mt-2 mb-2">
                <span>{data.profileImage.component} </span>
              </div>
              <p></p>
              <Control control={data.firstName} key={Fun.uuid()}></Control>
              <Control control={data.lastName} key={Fun.uuid()}></Control>
              {UniFun.userIsEligible({ forWhat: "module" }) ? (
                <Control
                  control={data.isPublicProfileImage}
                  key={Fun.uuid()}
                ></Control>
              ) : (
                ""
              )}
              {/* <Control control={data.department} key={Fun.uuid()}></Control> */}
              <Control control={data.email} key={Fun.uuid()}></Control>
              <Control control={data.instructor} key={Fun.uuid()}></Control>
              <Control control={data.roles} key={Fun.uuid()}></Control>
              <Control control={data.jobTitle} key={Fun.uuid()}></Control>
              <Control control={data.biography} key={Fun.uuid()}></Control>

              {/* <Control control={data.phoneNumber} key={Fun.uuid()}></Control> */}
              <Control control={data.extraFields} key={Fun.uuid()}></Control>
            </div>
          );
        },
      },
      pk: "id",
      properties: new UniUsersProperties({
        editable: function (args) {
          let userId = args.data._id;

          return UniFun.userIsEligible({
            forWhat: "edit-account",
            userId: userId,
          });
        },
        show: UniFun.userIsEligible({ forWhat: "default" }),
      }),
    },
    userBio: {
      auth: "",
      apis: {
        view: {
          url: "/user/{id}",
          parameters: {
            headers: { "content-type": "application/json" },
          },
          formatter: function (data, builder) {
            return UniUsersFormatter(data, builder);
          },
        },
      },
      template: "userprofile",
      templateConfig: {
        colsTemplateCount: 12,
        includeProperties: [],
      },
      pk: "id",
      properties: UniUsersProperties({
        editable: false,
      }),
    },
    add: {
      auth: "",
      apis: {
        view: {
          data: { data: [{}] },
          parameters: {
            headers: { "content-type": "application/json" },
          },
          formatter: function (data, builder) {
            return UniUsersFormatter(data, builder);
          },
        },
        addusers: {
          url: "/auth/sendInvitation/manually",
          parameters: {
            headers: { "content-type": "application/json" },
          },
          formatter: function (data, builder) {
            return UniUsersFormatter(data, builder);
          },
        },
      },
      template: "form",
      templateConfig: {
        events: {
          onSubmit: function (e) {
            e.preventDefault();
            let submittedData = Fun.getSubmitJSON(e);

            let jsonData = [];

            if (!Fun.validate(this.props.builder.validator, submittedData)) {
              return;
            }

            submittedData["newUser"].map((itm, idx) => {
              if (itm["fullName"][0]["email"]) {
                switch (itm["fullName"][0]["instructor"]) {
                  case "instructor":
                    itm["fullName"][0]["instructor"] = true;
                    itm["fullName"][0]["host"] = false;
                    itm["fullName"][0]["manager"] = false;
                    break;
                  case "viewer":
                    itm["fullName"][0]["host"] = false;
                    itm["fullName"][0]["instructor"] = false;
                    itm["fullName"][0]["manager"] = true;
                    break;

                  case "host":
                    itm["fullName"][0]["instructor"] = false;
                    itm["fullName"][0]["host"] = true;
                    itm["fullName"][0]["manager"] = false;
                    break;

                  default:
                    itm["fullName"][0]["instructor"] = false;
                    itm["fullName"][0]["host"] = false;
                    itm["fullName"][0]["manager"] = false;
                    break;
                }
                jsonData.push(itm["fullName"][0]);
              }
            });

            Fun.update(e, {
              url: this.props.builder.state.module.apis.addusers,
              method: "POST",
              // callback: function (submittedData) {
              //   Fun.createNotificationFromResponse(submittedData);
              //   if (window["modal"].state.callback) {
              //     window["modal"].state.callback(submittedData);
              //   }
              // },
              callback: function (
                submittedData,
                status,
                responsedata,
                responsestatus
              ) {
                if (responsestatus == "200") {
                  window["modal"].state.parentBuilder.rerunComponent();
                  window["modal"].setState({ show: false, overflowY: "auto" });
                  Fun.createNotification({
                    type: "success",
                    message: "User was successfully added!",
                  });
                } else {
                  if (responsedata.data.exceptions) {
                    responsedata.data.exceptions.map((itm, idx) => {
                      Fun.createNotification({
                        message: itm.errorDescription,
                        type: "danger",
                      });
                    });
                  }
                }
              },

              parameters: {
                headers: {
                  "content-type": "application/json",
                },
              },
              submittedData: JSON.stringify({ users: jsonData }),
            });
          },
        },
        colsTemplateCount: 12,
        includeProperties: ["newUser"],
      },
      pk: "id",
      properties: UniUsersProperties({ editable: true }),
    },
    resend: {
      auth: "",
      apis: {
        view: {
          url: "/user/{id}",
          parameters: {
            headers: {},
          },
          formatter: function (args) {
            return UniUsersFormatter(args);
          },
        },
        delete: {
          url: "/auth/resendInvitation",
          parameters: {
            headers: {},
            replace: {},
          },
          formatter: function (data) {
            return UniUsersFormatter(data);
          },
        },
      },
      template: "form",
      templateConfig: {
        useCard: false,
        colsTemplateCount: 12,
        includeProperties: ["_id"],
        excludeProperties: [],
        events: {
          onSubmit: function (e) {
            let submittedData = Fun.getSubmitJSON(e);

            let builder = this.props.builder;

            let email = this.state.data[0].email.value;

            Fun.update(e, {
              url: builder.state.module.apis.delete,
              method: "POST",
              parameters: {
                headers: {
                  "content-type": "application/json",
                },
              },
              callback: function (
                submittedData,
                status,
                responsedata,
                responsestatus
              ) {
                if (responsestatus == 200) {
                  if (window["modal2"].state.callback) {
                    window["modal2"].state.callback(submittedData);
                  }
                } else {
                  window["modal2"].setState({ show: false, overflowY: "auto" });
                  let message = responsedata.data.message;

                  if (responsedata.data.exceptions) {
                    if (responsedata.data.exceptions[0]) {
                      message =
                        responsedata.data.exceptions[0].errorDescription;
                    }
                  }

                  Fun.createNotification({
                    message: message,
                    type: "danger",
                  });
                }
              },
              submittedData: JSON.stringify({ email: email }),
            });
          },
        },
        templateTop: function () {
          let data = this.state.data[0];
          return (
            <div className=" p-4">
              <p>
                Do you want to resend invitation for user"
                <strong>{data.fullName.value}</strong>"?<br></br>
              </p>
            </div>
          );
        },
      },
      pk: "_id",
      properties: UniUsersProperties({ editable: false }),
    },
    activate: {
      auth: "",
      apis: {
        view: {
          url: "/user/{id}",
          parameters: {
            headers: {},
          },
          formatter: function (args) {
            return UniUsersFormatter(args);
          },
        },
        activate: {
          url: "/user/{id}/activate",
          parameters: {
            headers: {},
            replace: {},
          },
          formatter: function (data) {
            return UniUsersFormatter(data);
          },
        },
      },
      template: "form",
      templateConfig: {
        useCard: false,
        colsTemplateCount: 12,
        includeProperties: ["_id"],
        excludeProperties: [],
        events: {
          onSubmit: function (e) {
            let submittedData = Fun.getSubmitJSON(e);

            let builder = this.props.builder;

            let email = this.state.data[0].email.value;

            Fun.update(e, {
              url: builder.state.module.apis.activate,
              method: "PATCH",
              parameters: {
                headers: {
                  "content-type": "application/json",
                },
                replace: {
                  id: submittedData["_id"],
                },
              },
              callback: function (
                submittedData,
                status,
                responsedata,
                responsestatus
              ) {
                if (responsestatus == 200) {
                  if (window["modal2"].state.callback) {
                    window["modal2"].state.callback(submittedData);
                  }
                } else {
                  window["modal2"].setState({ show: false, overflowY: "auto" });
                  let message = responsedata.data.message;

                  if (responsedata.data.exceptions) {
                    if (responsedata.data.exceptions[0]) {
                      message =
                        responsedata.data.exceptions[0].errorDescription;
                    }
                  }

                  Fun.createNotification({
                    message: message,
                    type: "danger",
                  });
                }
              },
              submittedData: JSON.stringify({ email: email }),
            });
          },
        },
        templateTop: function () {
          let data = this.state.data[0];
          return (
            <div className=" p-4">
              <p>
                Do you want activate user"
                <strong>{data.fullName.value}</strong>"?<br></br>
              </p>
            </div>
          );
        },
      },
      pk: "_id",
      properties: UniUsersProperties({ editable: false }),
    },
    deactivate: {
      auth: "",
      apis: {
        view: {
          url: "/user/{id}",
          parameters: {
            headers: {},
          },
          formatter: function (args) {
            return UniUsersFormatter(args);
          },
        },
        deactivate: {
          url: "/user/{id}/deactivate",
          parameters: {
            headers: {},
            replace: {},
          },
          formatter: function (data) {
            return UniUsersFormatter(data);
          },
        },
      },
      template: "form",
      templateConfig: {
        useCard: false,
        colsTemplateCount: 12,
        includeProperties: ["_id"],
        excludeProperties: [],
        events: {
          onSubmit: function (e) {
            let submittedData = Fun.getSubmitJSON(e);

            let builder = this.props.builder;

            let email = this.state.data[0].email.value;

            Fun.update(e, {
              url: builder.state.module.apis.deactivate,
              method: "PATCH",
              parameters: {
                headers: {
                  "content-type": "application/json",
                },
                replace: {
                  id: submittedData["_id"],
                },
              },
              callback: function (
                submittedData,
                status,
                responsedata,
                responsestatus
              ) {
                if (responsestatus == 200) {
                  if (window["modal2"].state.callback) {
                    window["modal2"].state.callback(submittedData);
                  }
                } else {
                  window["modal2"].setState({ show: false, overflowY: "auto" });
                  let message = responsedata.data.message;

                  if (responsedata.data.exceptions) {
                    if (responsedata.data.exceptions[0]) {
                      message =
                        responsedata.data.exceptions[0].errorDescription;
                    }
                  }

                  Fun.createNotification({
                    message: message,
                    type: "danger",
                  });
                }
              },
              submittedData: JSON.stringify({ email: email }),
            });
          },
        },
        templateTop: function () {
          let data = this.state.data[0];
          return (
            <div className=" p-4">
              <p>
                Do you want deactivate user"
                <strong>{data.fullName.value}</strong>"?<br></br>
              </p>
            </div>
          );
        },
      },
      pk: "_id",
      properties: UniUsersProperties({ editable: false }),
    },
    revoke: {
      auth: "",
      apis: {
        view: {
          url: "/user/{id}",
          parameters: {
            headers: {},
          },
          formatter: function (args) {
            return UniUsersFormatter(args);
          },
        },
        delete: {
          url: "/auth/revokeInvitation",
          parameters: {
            headers: {},
            replace: {},
          },
          formatter: function (data) {
            return UniUsersFormatter(data);
          },
        },
      },
      template: "form",
      templateConfig: {
        useCard: false,
        colsTemplateCount: 12,
        includeProperties: ["_id"],
        excludeProperties: [],
        events: {
          onSubmit: function (e) {
            let submittedData = Fun.getSubmitJSON(e);

            let builder = this.props.builder;

            let email = this.state.data[0].email.value;

            Fun.update(e, {
              url: builder.state.module.apis.delete,
              method: "POST",
              parameters: {
                headers: {
                  "content-type": "application/json",
                },
              },
              callback: function (
                submittedData,
                status,
                responsedata,
                responsestatus
              ) {
                if (responsestatus == 200) {
                  if (window["modal2"].state.callback) {
                    window["modal2"].state.callback(submittedData);
                  }
                } else {
                  window["modal2"].setState({ show: false, overflowY: "auto" });
                  let message = responsedata.data.message;

                  if (responsedata.data.exceptions) {
                    if (responsedata.data.exceptions[0]) {
                      message =
                        responsedata.data.exceptions[0].errorDescription;
                    }
                  }

                  Fun.createNotification({
                    message: message,
                    type: "danger",
                  });
                }
              },
              submittedData: JSON.stringify({ email: email }),
            });
          },
        },
        templateTop: function () {
          let data = this.state.data[0];
          return (
            <div className=" p-4">
              <p>
                Do you want to revoke invitation for user"
                <strong>{data.fullName.value}</strong>"?<br></br>
              </p>
            </div>
          );
        },
      },
      pk: "_id",
      properties: UniUsersProperties({ editable: false }),
    },
    deleteUser: {
      auth: "",
      apis: {
        view: {
          url: "/user/{id}",
          parameters: {
            headers: {},
          },
          formatter: function (args) {
            return UniUsersFormatter(args);
          },
        },
        delete: {
          url: "/user/{id}",
          parameters: {
            headers: {},
            replace: {},
          },
          formatter: function (data) {
            return UniUsersFormatter(data);
          },
        },
      },
      template: "form",
      templateConfig: {
        useCard: false,
        colsTemplateCount: 12,
        includeProperties: ["_id"],
        excludeProperties: [],
        events: {
          onSubmit: function (e) {
            let submittedData = Fun.getSubmitJSON(e);

            let builder = this.props.builder;

            Fun.update(e, {
              url: builder.state.module.apis.delete,
              method: "DELETE",
              parameters: {
                headers: {
                  "content-type": "application/json",
                },
                replace: {
                  id: submittedData["_id"],
                },
              },
              callback: function (args) {
                if (window["modal2"].state.callback) {
                  window["modal2"].state.callback(submittedData);
                }
              },
              submittedData: JSON.stringify([]),
            });
          },
        },
        templateTop: function () {
          let data = this.state.data[0];
          return (
            <div className=" p-4">
              <p>
                Are you sure you want to delete user{" "}
                <strong>"{data.fullName.value}"</strong>
              </p>
            </div>
          );
        },
      },
      pk: "_id",
      properties: UniUsersProperties({ editable: false }),
    },
    selectNewOrUpload: {
      auth: "",
      apis: {
        view: {
          data: {
            data: [
              {
                _id: "modalAddNew",
                title: "Add users manually",
              },
              {
                _id: "modalUploadFile",
                title: "Import multiple users",
              },
            ],
          },
          parameters: {
            headers: {},
            replace: {},
          },
          formatter: function (data) {
            if (data) {
              let formattedData = [];

              if (data.data) {
                if (
                  window.location.hostname.toLocaleLowerCase() ==
                    "heal.hapeiron.com" ||
                  window.location.hostname.toLocaleLowerCase() ==
                    "staging-v2.interfima.org" ||
                  window.location.hostname.toLocaleLowerCase() ==
                    "hapeiron.com" ||
                  window.location.hostname.toLocaleLowerCase() == "localhost"
                ) {
                  data.data.push({
                    _id: "modalRegisterDirectly",
                    title: "Register users directly",
                  });
                }

                data.data.map((itm, idx) => {
                  let formattedRecord = {};

                  formattedRecord["_id"] = itm._id;
                  formattedRecord["selectTemplate"] = itm._id;
                  formattedRecord["title"] = itm.title;
                  formattedData.push(formattedRecord);
                });
              }

              return formattedData;
            }
          },
        },
      },
      template: "selectboxes",
      templateConfig: {
        events: {
          onSubmit: function (e) {
            e.preventDefault();
            let jsonData = Fun.getSubmitJSON(e);
            let builder = this.props.builder;

            if (jsonData["selectTemplate"] == "modalAddNew") {
              addUser(window["modal"].state.parentBuilder);
            } else if (jsonData["selectTemplate"] == "modalUploadFile") {
              importUsers(window["modal"].state.parentBuilder);
            } else if (jsonData["selectTemplate"] == "modalRegisterDirectly") {
              registerDirectly(window["modal"].state.parentBuilder);
            }
          },
        },
        colsTemplateCount: 12,
        templateTop: function () {
          return (
            <React.Fragment>
              <p className="pl-2 mt-4">
                <h5>I want to: </h5>
              </p>
            </React.Fragment>
          );
        },
        includeProperties: ["title", "selectTemplate"],
      },
      pk: "_id",
      properties: selectNewUserProperties({ editable: false }),
    },
    importUsers: {
      auth: "",
      apis: {
        view: {
          data: { data: [{}] },
          parameters: {
            headers: {},
          },
          formatter: function (args) {
            return UniUsersFormatter(args);
          },
        },
        upload: {
          url: "/auth/importInvitation/excel",
          parameters: {
            headers: {},
            replace: {},
          },
          formatter: function (data) {
            return UniUsersFormatter(data);
          },
        },
      },
      template: "form",
      templateConfig: {
        useCard: false,
        colsTemplateCount: 12,
        includeProperties: ["fileupload"],
        excludeProperties: [],
        cardTemplateTop: function () {
          return <p className="mt-4"></p>;
        },
        cardTemplateBottom: function () {
          return (
            <p className="mt-2 text-dark">
              {/* Excel column format: The first three columns titles must be: 1)
              firstname, 2) lastname, 3) email */}
              Download excel template to add your contacts:{" "}
              <button
                className="btn btn-link mb-1 p-0"
                onClick={(e) => UniFun.downloadContactsTemplate(false)}
              >
                Download
              </button>
            </p>
          );
        },
        events: {
          onSubmit: function (e) {
            e.preventDefault();
            let submittedData = Fun.getSubmitJSON(e);

            let fileupload = JSON.parse(submittedData["fileupload"]);

            if (fileupload[Object.keys(fileupload)[0]]) {
              let file = fileupload[Object.keys(fileupload)[0]];

              let postData = { fileId: file._id, fileKey: file.fileKey };

              let sendBulkInvite =
                document.getElementsByClassName("sendBulkInvite");

              sendBulkInvite[0].classList.add("disabled");
              sendBulkInvite[0].setAttribute("disabled", "disabled");

              let sendBulkInviteLoader = document.getElementsByClassName(
                "sendBulkInvite-loader"
              );
              sendBulkInviteLoader[0].classList.add(
                "spinner-border",
                "spinner-border-sm",
                "text-light"
              );

              Fun.update(e, {
                url: this.props.builder.state.module.apis.upload,
                method: "POST",
                callback: function (
                  submittedData,
                  status,
                  responsedata,
                  responsestatus
                ) {
                  sendBulkInvite[0].classList.remove("disabled");
                  sendBulkInvite[0].removeAttribute("disabled");
                  sendBulkInviteLoader[0].classList.remove(
                    "spinner-border",
                    "spinner-border-sm",
                    "text-light"
                  );
                  if (responsestatus == "200") {
                    window["modal"].state.parentBuilder.rerunComponent();
                    window["modal"].setState({
                      show: false,
                      onClose: null,
                      overflowY: "auto",
                    });

                    Fun.createNotification({
                      type: "success",
                      message: "Import users succeeded",
                    });
                  } else {
                    if (responsedata.data.exceptions) {
                      responsedata.data.exceptions.map((itm, idx) => {
                        Fun.createNotification({
                          message: itm.errorDescription,
                          type: "danger",
                        });
                      });
                    }
                  }
                },
                parameters: {
                  headers: {
                    "content-type": "application/json",
                  },
                },
                submittedData: JSON.stringify(postData),
              });
            }
          },
        },
      },
      pk: "_id",
      properties: newUserUploadProperties({ editable: true }),
    },
    registerDirectly: {
      auth: "",
      apis: {
        view: {
          data: { data: [{}] },
          parameters: {
            headers: {},
          },
          formatter: function (args) {
            return UniUsersFormatter(args);
          },
        },
        upload: {
          url: "/auth/importUsers/excel",
          parameters: {
            headers: {},
            replace: {},
          },
          formatter: function (data) {
            return UniUsersFormatter(data);
          },
        },
      },
      template: "form",
      templateConfig: {
        useCard: false,
        colsTemplateCount: 12,
        includeProperties: ["fileupload"],
        excludeProperties: [],
        cardTemplateTop: function () {
          return <p className="mt-4"></p>;
        },
        cardTemplateBottom: function () {
          return (
            <p className="mt-2 text-dark">
              {/* Excel column format: The first four columns titles must be: 1)
              firstname, 2) lastname, 3) email, 4) password */}
              Download excel template to add your contacts:{" "}
              <button
                className="btn btn-link mb-1 p-0"
                onClick={(e) => UniFun.downloadContactsTemplate(true)}
              >
                Download
              </button>
            </p>
          );
        },
        events: {
          onSubmit: function (e) {
            e.preventDefault();
            let submittedData = Fun.getSubmitJSON(e);

            let fileupload = JSON.parse(submittedData["fileupload"]);

            if (fileupload[Object.keys(fileupload)[0]]) {
              let file = fileupload[Object.keys(fileupload)[0]];

              let postData = { fileId: file._id, fileKey: file.fileKey };

              let sendBulkInvite =
                document.getElementsByClassName("sendBulkInvite");

              sendBulkInvite[0].classList.add("disabled");
              sendBulkInvite[0].setAttribute("disabled", "disabled");

              let sendBulkInviteLoader = document.getElementsByClassName(
                "sendBulkInvite-loader"
              );
              sendBulkInviteLoader[0].classList.add(
                "spinner-border",
                "spinner-border-sm",
                "text-light"
              );

              Fun.update(e, {
                url: this.props.builder.state.module.apis.upload,
                method: "POST",
                callback: function (
                  submittedData,
                  status,
                  responsedata,
                  responsestatus
                ) {
                  sendBulkInvite[0].classList.remove("disabled");
                  sendBulkInvite[0].removeAttribute("disabled");
                  sendBulkInviteLoader[0].classList.remove(
                    "spinner-border",
                    "spinner-border-sm",
                    "text-light"
                  );

                  if (responsestatus == "200") {
                    window["modal"].state.parentBuilder.rerunComponent();
                    window["modal"].setState({
                      show: false,
                      onClose: null,
                      overflowY: "auto",
                    });

                    Fun.createNotification({
                      type: "success",
                      message: "Import users succeeded",
                    });
                  } else {
                    if (responsedata.data.exceptions) {
                      responsedata.data.exceptions.map((itm, idx) => {
                        Fun.createNotification({
                          message: itm.errorDescription,
                          type: "danger",
                        });
                      });
                    }
                  }

                  sendBulkInvite[0].classList.remove("disabled");
                  sendBulkInvite[0].removeAttribute("disabled");
                },
                parameters: {
                  headers: {
                    "content-type": "application/json",
                  },
                },
                submittedData: JSON.stringify(postData),
              });
            }
          },
        },
      },
      pk: "_id",
      properties: newUserUploadProperties({ editable: true }),
    },
  },
};

function newUserUploadProperties(args) {
  let editable = args.editable;

  return {
    _id: {
      alias: "_id",
      control: "hidden",
      editable: editable,
      label: "",
      attributes: {
        className: "form-control",
      },
    },
    fileupload: {
      alias: "fileupload",
      control: "dropzone",
      label: "Import excel file",
      editable: editable,
      customContentLayout: function () {
        let files = JSON.parse(this.state.defaultValue);

        let sendBulkInvite = document.getElementsByClassName("sendBulkInvite");

        sendBulkInvite[0].classList.add("disabled");
        sendBulkInvite[0].setAttribute("disabled", "disabled");

        let content = [];

        Object.keys(files).map((itm, idx) => {
          content.push(
            UniFun.trainingNodeFileUI(files[itm], this.removeFile, itm)
          );
          sendBulkInvite[0].classList.remove("disabled");
          sendBulkInvite[0].removeAttribute("disabled");
        });

        this.state.content?.map((itm, idx) => {
          if (typeof files[itm.key] === "undefined") {
            content.push(itm);
          }
          // if (this.state.pending.indexOf(itm.key) >= 0) {
          //   content.push(itm);
          // }
        });
        return (
          <React.Fragment>
            {/* {this.state.placeholder()} */}
            {content.length > 0 ? "" : this.state.placeholder()}
            <div className="p-3 pb-1">{content}</div>
            {/* {content.length > 0 ? "" : this.state.placeholder()} */}
          </React.Fragment>
        );
      },

      useCustomLayout: true,
      config: {
        placeholder: function () {
          return (
            <div className="p-3 pb-1">
              <strong>
                Drag & drop here or{" "}
                <a
                  href="javascript:void(0);"
                  className="text-primary"
                  onClick={this.onTargetClick}
                >
                  <strong>browse</strong>
                </a>{" "}
                file to attach
              </strong>
              <br></br>
              <span className="text-dark">
                Maximum size: 50 MB - File type supported: xlsx
              </span>
            </div>
          );
        },
      },
      events: {
        removeFile: function (id) {
          let defaultValue = {};

          Object.keys(JSON.parse(this.state.defaultValue)).map((itm, idx) => {
            if (itm != id) {
              defaultValue[itm] = JSON.parse(this.state.defaultValue)[itm];
            }
          });

          let content = [];
          this.state.content?.map((itm, idx) => {
            if (itm.key != id) {
              content.push(itm);
            }
          });

          this.setState({
            defaultValue: JSON.stringify(defaultValue),
            content: content,
          });

          return;
        },
        onDrop: function (files) {
          this.setState({ defaultValue: JSON.stringify({}), content: [] });

          if (files.length > 1) {
            Fun.createNotification({
              message:
                "Only one file is allowed to upload. Only XLSX files are allowed to upload",
              type: "danger",
            });
            return;
          }

          if (
            files[0].type ==
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          ) {
            UniFun.fileProgress({
              control: this,
              files: files,
              url: "/auth/uploadInvitation/excel",
              type: "usersimport",
              filesName: "csvFile",
            });
          } else {
            Fun.createNotification({
              message: "Only  XLSX files are allowed to upload",
              type: "danger",
            });
          }
        },
      },
      attributes: {
        className: "border rounded  dropzone-trainings",
      },
    },
  };
}

function selectNewUserProperties(args) {
  let editable = args.editable;

  return {
    _id: {
      alias: "_id",
      control: "hidden",
      editable: editable,
      label: "",
      attributes: {
        className: "form-control",
      },
    },
    title: {
      alias: "title",
      control: "text",
      editable: editable,
      label: "",
      attributes: {
        className: "form-control",
      },
    },
    selectTemplate: {
      alias: "selectTemplate",
      control: "checkbox",
      label: "",
      editable: true,
      attributes: {
        className: "form-check-input  select-template-checkbox",
      },
      events: {
        onChange: function () {
          let value = this.props.value;

          Object.keys(this.props.builder).map((itm, idx) => {
            if (
              itm.startsWith("selectTemplate") &&
              itm != "selectTemplate-" + value
            ) {
              this.props.builder[itm].setState({
                checked: false,
              });
            }
          });

          this.props.builder.builderTemplate.current.setState({
            action: this.props.data["_id"],
          });
        },
      },
    },
  };
}

function UniUsersFormatter(data) {
  let formattedData = [];
  if (data) {
    if (data.data) {
      data.data.map((itm, idx) => {
        let formattedRecord = {};

        let initialsName = "";

        try {
          initialsName =
            itm.firstName.charAt(0).toUpperCase() +
            itm.lastName.charAt(0).toUpperCase();
        } catch (e) {}

        // let roles = itm.roles?.map((ritm,ridx)=>{
        //   return r
        // })
        formattedRecord["_id"] = itm._id;
        formattedRecord["firstName"] = itm.firstName;
        formattedRecord["lastName"] = itm.lastName;
        formattedRecord["fullName"] = itm.firstName + " " + itm.lastName;
        formattedRecord["roles"] = itm.roles;
        formattedRecord["deleted"] = itm.deleted;
        formattedRecord["jobTitle"] = itm.jobTitle;
        formattedRecord["biography"] = itm.biography;
        formattedRecord["hasPrivateInfo"] = itm.hasPrivateInfo
          ? itm.hasPrivateInfo
          : "false";

        formattedRecord["instructor"] = "";

        if (itm.instructor) {
          formattedRecord["instructor"] = "instructor";
        }
        if (itm.host) {
          formattedRecord["instructor"] = "host";
        }
        // formattedRecord["profileImage"] = (
        //   <span className="p-2 mr-2 rounded-circle bg-secondary">
        //     {initialsName}
        //   </span>
        // );

        formattedRecord["profileImage"] = itm.profileImage;
        formattedRecord["profileImageList"] = itm.profileImage;

        formattedRecord["email"] = itm.email;
        formattedRecord["company"] = itm.company;
        formattedRecord["department"] = itm.department;
        formattedRecord["phoneNumber"] = itm.phoneNumber;
        formattedRecord["extraFields"] = itm.extraFields;
        formattedRecord["groups"] = itm.groups;
        formattedRecord["events"] = itm.events;

        if (itm.extraFields) {
          if (Array.isArray(itm.extraFields)) {
            itm.extraFields.forEach((fitm, idx) => {
              formattedRecord[fitm.label] = itm[fitm.label];
            });
          }
        }

        formattedRecord["createdAt"] = itm.createdAt;
        formattedRecord["updatedAt"] = itm.updatedAt;
        formattedRecord["status"] = itm.status;
        formattedRecord["newUser"] = "";

        formattedData.push(formattedRecord);
      });
    }
  }
  return formattedData;
}

function UniUsersProperties(args) {
  let editable = args.editable;
  let show = args.show === false ? false : true;

  return {
    _id: {
      alias: "_id",
      control: "hidden",
      editable: editable,
      label: "",
      attributes: {
        className: "form-control",
      },
    },

    firstName: {
      alias: "firstName",
      control: "text",
      editable: editable,
      label: "First name",
      nonEditableDefaultValue: "No description",
      attributes: {
        className: "form-control",
      },
    },
    lastName: {
      alias: "lastName",
      control: "text",
      editable: editable,
      label: "Last name",
      nonEditableDefaultValue: "No description",
      attributes: {
        className: "form-control",
      },
    },
    fullName: {
      alias: "fullName",
      control: "text",
      editable: editable,
      nonEditableDefaultValue: "No description",
      label: "Name",
      attributes: {
        className: "form-control",
      },
    },
    email: {
      alias: "email",
      control: "text",
      editable: editable,
      label: "Email",
      attributes: {
        className: "form-control",
        readOnly: "readOnly",
      },
    },
    instructor: {
      alias: "instructor",
      control: "select",
      label: "Tag",
      show: show,
      editable: editable,
      config: {
        options: [
          { label: "Not specified", value: "" },
          { label: "Instructor", value: "instructor" },
          { label: "Conference host", value: "host" },
        ],
      },
      attributes: {
        className: "form-control",
        readOnly: "readOnly",
        disabled: "disabled",
      },
    },
    roles: {
      alias: "roles",
      control: "tags",
      show: show,
      config: {
        idAttribute: "_id",
        nameAttribute: "name",
        apis: {
          search: {
            url: "/roles",
            formatter: function (args) {
              let output = [];
              if (args) {
                if (args.data) {
                  output = args.data;
                }
              }

              return output;
            },
            parameters: {
              replace: {
                query: "",
              },
            },
          },
        },
      },
      editable: false,
      label: "Role",
      attributes: {
        className: "form-control",
      },
    },
    jobTitle: {
      alias: "jobTitle",
      control: "text",
      label: "Job title",
      editable: editable,
      nonEditableDefaultValue: "No description",
      attributes: {
        className: "form-control",
      },
    },
    biography: {
      alias: "biography",
      control: "editor",
      label: "Biography",
      nonEditableDefaultValue: "No description",
      editable: editable,
      attributes: {
        className: "form-control",
      },
    },
    hasPrivateInfo: {
      alias: "hasPrivateInfo",
      control: "hidden",
      label: "",
      editable: false,
    },
    status: {
      alias: "status",
      control: "select",
      show: show,
      editable: editable,
      config: {
        options: [
          { label: "Registered", value: "active" },
          { label: "Invited", value: "registration" },
          { label: "Deactivated", value: "deactivated" },
        ],
      },
      label: "Status",
      attributes: {
        className: "form-control",
      },
    },
    profileImageList: {
      alias: "profileImageList",
      control: "text",
      editable: true,
      label: "",
      attributes: {
        className: "form-control",
      },
      useCustomLayout: true,
      customLayout: function (args) {
        let data = { ...this.props.data };

        if (this.state.defaultValue) {
          data["profileImage"] = this.state.defaultValue;
        } else {
          delete data["profileImage"];
        }

        return (
          <React.Fragment>
            {UniFun.createProfileImageBubble([data], "avatar-default", true)}
          </React.Fragment>
        );
      },
    },
    profileImage: {
      alias: "profileImage",
      control: "dropzone",
      editable: true,
      label: "",
      attributes: {
        className: "form-control",
      },
      events: {
        onDrop: function (files) {
          let submittedData = new FormData();

          submittedData.set("type", "profile");

          Array.from(files).map((file, fidx) => {
            submittedData.set("file", file);
          });

          let object = this;

          let argsUpload = {
            url: {
              url: "/agenda-file",
              parameters: {
                headers: {
                  "content-type": "application/json",
                },
                replace: {},
              },
            },
            callback: function (args) {
              let data = args.data;

              object.setState({
                defaultValue: data[0]._id,
              });
            },
            method: "POST",
            submittedData: submittedData,
          };

          this.uploadFiles(argsUpload);
        },
      },
      useCustomLayout: true,
      customLayout: function (args) {
        let data = this.props.data;

        let controls = [];
        // ?type=preview&jwt=" +localStorage.getItem("jwt"),

        if (
          UniFun.userIsEligible({ forWhat: "edit-account", userId: data._id })
        ) {
          controls.push(
            <a
              href="javascript:void(0);"
              className="text-primary"
              onClick={this.onTargetClick}
            >
              Upload new photo
            </a>
          );
          let value = this.state.defaultValue;
          if (value.length > 0 && value != "[]" && value != "") {
            data["profileImage"] = this.state.defaultValue;

            controls.push(" • ");
            controls.push(
              <a
                href="javascript:void(0);"
                className="text-primary"
                onClick={(e) => this.setState({ defaultValue: "" })}
              >
                Remove photo
              </a>
            );
          } else {
            data["profileImage"] = "";
          }
        }

        return (
          <React.Fragment>
            {UniFun.createProfileImageBubble(
              [data],
              "avatar-default",
              false,
              controls
            )}
            <div className="d-none">{args.control}</div>
          </React.Fragment>
        );
      },
    },
    isPublicProfileImage: {
      alias: "isPublicProfileImage",
      control: "select",
      show: show,
      editable: editable,
      config: {
        options: [
          { label: "No", value: true },
          { label: "Yes", value: false },
        ],
      },
      label: "Make profile image public available",
      attributes: {
        className: "form-control",
      },
    },
    company: {
      alias: "company",
      control: "text",
      editable: editable,
      show: show,
      label: "Company",
      nonEditableDefaultValue: "No description",
      attributes: {
        className: "form-control",
      },
    },
    department: {
      alias: "department",
      control: "text",
      editable: editable,
      nonEditableDefaultValue: "No description",
      show: show,
      label: "Department",
      attributes: {
        className: "form-control",
      },
    },
    phoneNumber: {
      alias: "phoneNumber",
      control: "text",
      editable: editable,
      nonEditableDefaultValue: "No description",
      show: show,
      label: "Phone",
      attributes: {
        className: "form-control",
      },
    },
    extraFields: {
      alias: "extraFields",
      control: "extrafields",
      editable: function () {
        return UniFun.userIsEligible({ forWhat: "module" }) ? true : false;
      },
      nonEditableDefaultValue: "No description",
      show: show,
      label: "",
      attributes: {
        className: "form-control",
      },
    },
    createdAt: {
      alias: "createdAt",
      control: "text",
      editable: editable,
      label: "Created ",
      attributes: {
        className: "form-control",
      },
    },
    deleted: {
      alias: "deleted",
      control: "hidden",
      editable: editable,
      label: " ",
      attributes: {
        className: "form-control",
      },
    },
    events: {
      alias: "events",
      control: "hidden",
      editable: editable,
      label: " ",
      attributes: {
        className: "form-control",
      },
    },
    groups: {
      alias: "groups",
      control: "hidden",
      editable: editable,
      label: " ",
      attributes: {
        className: "form-control",
      },
    },
    updatedAt: {
      alias: "updatedAt",
      control: "text",
      editable: editable,
      label: "Last modified",
      attributes: {
        className: "form-control",
      },
    },
    newUser: {
      control: "multiple",
      alias: "newUser",
      editable: editable,
      customLayout: function (args) {
        let output = "";

        if (args.control.length <= 0) {
          this.addNew();
        }
        return (
          <React.Fragment>
            {args.control}
            <button
              type="button"
              className="btn btn-link mt-3"
              onClick={this.addNew}
            >
              + Add user
            </button>
          </React.Fragment>
        );
      },
      userCustomLayout: true,
      config: {
        addNewLabel: "+ Add User",
        properties: {
          fullName: {
            control: "group",
            alias: "fullName",
            editable: editable,
            customLayout: function (args) {
              let index = args.object.props.multipleIndex + 1;
              let output = (
                <div className="mt-4">
                  <h5>User #{index}</h5>
                  <div className="row ">
                    <div className="col-md-6 pr-1">{args.control[0]}</div>
                    <div className="col-md-6 pl-1 ">{args.control[1]}</div>
                    <div className="col-md-6 pr-1 pt-2">{args.control[2]}</div>
                    <div className="col-md-6 pl-1 pt-2">{args.control[3]}</div>
                  </div>
                </div>
              );
              return output;
            },
            userCustomLayout: true,
            config: {
              properties: {
                firstName: {
                  alias: "firstName",
                  control: "text",
                  validations: "required",
                  label: "",
                  placeholder: "Type first name",
                  editable: editable,
                  attributes: {
                    className: "form-control",
                  },
                },
                lastName: {
                  alias: "lastName",
                  control: "text",
                  label: "",
                  validations: "required",
                  placeholder: "Type last name",
                  editable: editable,
                  attributes: {
                    className: "form-control",
                  },
                },
                email: {
                  alias: "email",
                  control: "text",
                  label: "",
                  placeholder: "Type email address",
                  editable: editable,
                  validations: "required",
                  attributes: {
                    className: "form-control",
                  },
                },
                instructor: {
                  alias: "instructor",
                  control: "select",
                  placeholder: "Select tag",
                  show: show,
                  editable: editable,
                  config: {
                    options: [
                      { label: "Select tag", value: "" },
                      { label: "Not specified", value: "" },
                      { label: "Instructor", value: "instructor" },
                      { label: "Conference host", value: "host" },
                      { label: "Viewer", value: "viewer" },
                    ],
                  },
                  attributes: {
                    className: "form-control",
                  },
                },
              },
            },
          },
        },
      },
    },
  };
}

export default UniUsers;
