import React, { Component } from "react";
import Control from "./../../control";
import SimpleReactValidator from "simple-react-validator";
import Fun from "../../../../../lib/functions";
import UniFun from "../../../../../modules/uni/functions";
import moment from "moment";
import TopTemplateHeader from "./../../topTemplateHeader";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import Header from "./header";
import Menu from "./menu";
import Footer from "./footer";

class PublicSpeakers extends Component {
  state = {
    data: this.props.data,
    loaded: false,
    colsTemplateCount: 12,
    colsFormCount: 12,
    cardBodyCustomClass: "",
    module: {
      _id: "",
      title: "",
      description: "",
    },
    session: {},
  };

  constructor(props) {
    super(props);
    let builder = this.props.builder.state;
    let templateConfig = builder.module.templateConfig;
    if (templateConfig.events) {
      Object.keys(templateConfig.events).map((itm, idx) => {
        this[itm] = templateConfig.events[itm].bind(this);
      });
    }

    this.form = React.createRef();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.action) {
      return false;
    }
    return true;
  }

  componentDidMount() {
    let builder = this.props.builder.state;
    let templateConfig = builder.module.templateConfig;
    let useCard =
      typeof templateConfig.useCard !== "undefined"
        ? templateConfig.useCard
        : true;

    let templateTop = templateConfig.templateTop
      ? templateConfig.templateTop
      : () => {};
    this["templateTop"] = templateTop.bind(this);
    let templateBottom = templateConfig.templateBottom
      ? templateConfig.templateBottom
      : () => {};
    this["templateBottom"] = templateBottom.bind(this);
    let templateLeft = templateConfig.templateLeft
      ? templateConfig.templateLeft
      : () => {};
    this["templateLeft"] = templateLeft.bind(this);
    let templateRight = templateConfig.templateRight
      ? templateConfig.templateRight
      : () => {};
    this["templateRight"] = templateRight.bind(this);
    let cardTemplateTop = templateConfig.cardTemplateTop
      ? templateConfig.cardTemplateTop
      : () => {};
    this["cardTemplateTop"] = cardTemplateTop.bind(this);
    let cardTemplateBottom = templateConfig.cardTemplateBottom
      ? templateConfig.cardTemplateBottom
      : () => {};
    this["cardTemplateBottom"] = cardTemplateBottom.bind(this);
    let panelTop = templateConfig.panelTop ? templateConfig.panelTop : () => {};
    this["panelTop"] = panelTop.bind(this);
    let panelBottom = templateConfig.panelBottom
      ? templateConfig.panelBottom
      : () => {};
    this["panelBottom"] = panelBottom.bind(this);
    let panelLeft = templateConfig.panelLeft
      ? templateConfig.panelLeft
      : () => {};
    this["panelLeft"] = panelLeft.bind(this);
    let panelRight = templateConfig.panelRight
      ? templateConfig.panelRight
      : () => {};
    this["panelRight"] = panelRight.bind(this);
    let header = templateConfig.header ? templateConfig.header : () => {};
    this["header"] = header.bind(this);

    let colsTemplateCount = templateConfig.colsTemplateCount
      ? templateConfig.colsTemplateCount
      : this.state.colsTemplateCount;
    let colsFormCount = templateConfig.colsFormCount
      ? templateConfig.colsFormCount
      : this.state.colsFormCount;

    let cardBodyCustomClass = templateConfig.cardBodyCustomClass
      ? templateConfig.cardBodyCustomClass
      : this.state.cardBodyCustomClass;

    this.setState({
      loaded: true,
      rawData: this.props.rawData,
      colsTemplateCount: colsTemplateCount,
      colsFormCount: colsFormCount,
      cardBodyCustomClass: cardBodyCustomClass,
      useCard: useCard,
    });

    this.validator = this.props.builder.validator;
    if (this.componentDidMountEvent) {
      this.componentDidMountEvent();
    }
  }

  speakers() {
    let data = this.state.rawData.data[0];

    let speakersIds = [];
    let speakers = [];

    data.days?.forEach((itm, idx) => {
      itm.sessions.forEach((sitm, sidx) => {
        sitm.speakersRaw.forEach((spitm, spidx) => {
          if (speakersIds.indexOf(spitm._id) < 0) {
            speakersIds.push(spitm._id);

            let name = (
              <span style={{ display: "inline-block" }} className="font-18">
                <span className="pt-2">
                  <strong className="text-primary">
                    {spitm.firstName + " " + spitm.lastName}
                  </strong>
                  <br></br>
                  {spitm.jobTitle}
                </span>
              </span>
            );

            speakers.push(
              <div className="col-md-4 mt-4">
                <article
                  className="pointer p-3 pt-4 pb-4 bg-white rounded"
                  onClick={(e) => this.speakerInfoModal(spitm)}
                >
                  {UniFun.createProfileImageBubble(
                    [spitm],
                    "avatar-120",
                    false,
                    name,
                    "13px"
                  )}

                  {/* {UniFun.createProfileImageBubble([spitm], "avatar-120")} */}
                </article>
              </div>
            );
          }
        });
      });
    });

    return <div className="row">{speakers}</div>;
  }

  render() {
    if (this.state.loaded) {
      let data = this.state.data[0];

      let banner = data.banner.value[0]
        ? window["baseurl"](
            "/agenda-file/data/" +
              data.banner.value[0]._id +
              "?type=preview&jwt=" +
              localStorage.getItem("jwt")
          )
        : "/uni/svg/conferencePlaceholder.jpg";

      return (
        <React.Fragment>
          <Header
            activeTab="2"
            data={this.state.data}
            interestFormModal={this.interestFormModal}
            rawData={this.state.rawData}
          ></Header>

          <div
            className="pt-5"
            style={{
              backgroundImage: "url(" + banner + ")",
              backgroundSize: "cover",
              boxShadow: "inset 0 0 0 2000px rgba(0, 0, 0, 0.7)",
              minHeight: "100vh",
            }}
          >
            <div style={{ minHeight: "calc(100vh - 150px)" }}>
              <div className="container" style={{ minHeight: "100%" }}>
                <div className="row">
                  <div className="col-md-12 pl-0">
                    <h1
                      className="text-white"
                      style={{ padding: "0.8rem 1rem" }}
                    >
                      <strong>Speakers</strong>
                    </h1>
                  </div>
                </div>
                <div style={{ minHeight: "600px" }}>{this.speakers()}</div>
              </div>
            </div>
            <Footer></Footer>
          </div>

          {/* <div className="container" style={{ minHeight: "100%" }}>
            <div className="row">
              <div className="col-md-12 pl-0">
                <h1 className="header-title" style={{ padding: "0.8rem 1rem" }}>
                  <strong>Speakers</strong>
                </h1>
              </div>
            </div>
            {this.speakers()}
          </div> */}
        </React.Fragment>
      );
    } else {
      return "loading...";
    }
  }
}

export default withRouter(PublicSpeakers);
