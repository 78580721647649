import React, { Component } from "react";
import Control from "../../control";
import SimpleReactValidator from "simple-react-validator";
import Fun from "../../../../../lib/functions";
import UniFun from "../../../../../modules/uni/functions";
import moment from "moment";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";

class Menu extends Component {
  state = {
    data: this.props.data,
    loaded: false,
    activeTab: this.props.activeTab ? this.props.activeTab : 0,
  };

  constructor(props) {
    super(props);

    this.form = React.createRef();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.action) {
      return false;
    }
    return true;
  }

  componentDidMount() {
    this.setState({
      loaded: true,
    });

    if (this.componentDidMountEvent) {
      this.componentDidMountEvent();
    }
  }

  render() {
    let conferenceId = Fun.getURLPathValue(2);

    let title = "";

    if (this.state.data) {
      title = this.state.data[0].title.value;
    }

    return (
      // <div className="container mb-2">
      //   <div className="row">
      //     {/* <div className="col-md-6 pl-0">
      //       <h3 className="header-title" style={{ padding: "0.8rem 1rem" }}>
      //         <strong>{title}</strong>
      //       </h3>
      //     </div> */}
      //     <div className="col-md-6 pr-0">
      // <nav class="navbar navbar-expand">
      <ul className="c-header-nav  navbar-menu  ml-auto mr-auto">
        <li
          className={
            this.state.activeTab == 0
              ? " nav-item p-3  pt-4"
              : "nav-item p-3  pt-4"
          }
        >
          <Link className="nav-link" to={"/conference/" + conferenceId}>
            <h3 className="conferences-subheader-title">
              {this.state.data[0].title.value}
            </h3>
          </Link>
        </li>
        <li
          className={
            this.state.activeTab == 1
              ? " nav-item active p-3  pt-4"
              : "nav-item p-3  pt-4"
          }
        >
          <Link
            className="nav-link "
            to={"/conference/" + conferenceId + "/sessions"}
          >
            <h3>Sessions</h3>
          </Link>
        </li>
        <li
          className={
            this.state.activeTab == 2
              ? " nav-item active p-3  pt-4"
              : "nav-item p-3  pt-4"
          }
        >
          <Link
            className="nav-link"
            to={"/conference/" + conferenceId + "/speakers"}
          >
            <h3>Speakers</h3>
          </Link>
        </li>
        <li
          className={
            this.state.activeTab == 3
              ? " nav-item active p-3  pt-4"
              : "nav-item p-3  pt-4"
          }
        >
          <Link
            className="nav-link"
            to={"/conference/" + conferenceId + "/sponsors"}
          >
            <h3>Partners</h3>
          </Link>
        </li>
      </ul>
      // {/* </nav> */}
      //     </div>
      //   </div>
      // </div>
    );
  }
}

export default withRouter(Menu);
