import React, { Component } from "react";
import Fun from "./functions";
import Modules from "../modules/modules";
import Themes from "../themes/themes";
import SimpleReactValidator from "simple-react-validator";
import { withRouter } from "react-router-dom";

class Builder extends Component {
  state = {
    loaded: false,
  };
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator();
    this.loadTemplate = this.loadTemplate.bind(this);
    this.loadDataCallback = this.loadDataCallback.bind(this);
    this.rerunComponent = this.rerunComponent.bind(this);
    let moduleName = this.props.module;
    let view = this.props.view;
    if (typeof view === "function") {
      view = view();
    }
    let parameters = this.props.parameters;
    let parentData = this.props.parentData;
    let inputData = this.props.inputData;

    this.builderTemplate = React.createRef();
    this.state = {
      moduleName: moduleName,
      view: view,
      parameters: parameters,
      parentData: parentData,
      inputData: inputData,
      theme: window["c_theme"],
    };
  }

  componentDidMount() {
    let module = Modules[this.state.moduleName]["views"][this.state.view];

    let moduleParams = this.props.moduleParams;

    if (this.checkAuth(module)) {
      if (this.state.inputData) {
        if (module) {
          module.apis.view["data"] = this.state.inputData;
        }
      }

      if (this.state.inputData == false) {
        if (module) {
          delete module.apis.view["data"];
        }
      }

      Fun.fetch({
        url: module.apis.view,
        parameters: this.state.parameters,
        method: module.apis.view.method ? module.apis.view.method : "GET",
        object: this,
        module: module,
        parentData: this.state.parentData,
        callback: this.loadDataCallback,
      });
    }

    this.setState({
      module: module,
      template:
        typeof module["template"] === "function"
          ? module["template"]()
          : module["template"],
      loaded: false,
      moduleParams: moduleParams,
    });
  }

  checkAuth(module) {
    if (module.auth) {
      if (typeof module.auth === "function") {
        return module.auth();
      }
    }

    return true;
  }

  rerunComponent(args) {
    let module = Modules[this.state.moduleName]["views"][this.state.view];
    let parameters = this.state.parameters;
    let api = module.apis.view;

    if (this.checkAuth(module)) {
      if (this.state.inputData) {
        if (module) {
          module.apis.view["data"] = this.state.inputData;
        }
      }

      if (this.state.inputData == false) {
        if (module) {
          delete module.apis.view["data"];
        }
      }

      if (args) {
        if (args.parameter) {
          parameters = args.parameter;
        }
        if (args.api) {
          api = args.api;
        }
        if (args.inputData) {
          module.apis.view["data"] = args.inputData;
        }

        if (args.inputData == false) {
          if (module) {
            delete module.apis.view["data"];
          }
        }
      }

      Fun.fetch({
        url: api,
        parameters: parameters,
        object: this,
        module: module,
        parentData: this.state.parentData,
        callback: this.loadDataCallback,
      });
    }

    this.setState({
      module: module,
      parameters: parameters,
      template:
        typeof module["template"] === "function"
          ? module["template"]()
          : module["template"],
      loaded: true,
    });
  }

  loadDataCallback(data, args, rawData) {
    if (this.state.module) {
      let enrichedData = Fun.enrichData({
        module: this.state.module,
        properties: this.state.module.properties,
        data: data,
        builder: this,
      });

      this.setState({ data: enrichedData, loaded: true, rawData: rawData });
    }
  }

  loadTemplate() {
    let Template = Themes[this.state.theme][this.state.template];

    if (this.checkAuth(this.state.module)) {
      return (
        <Template
          data={this.state.data}
          rawData={this.state.rawData}
          builder={this}
          ref={this.builderTemplate}
          key={Fun.uuid()}
          // history={this.props.history}
        ></Template>
      );
    }
    return "";
  }

  render() {
    if (this.state.loaded) {
      return this.loadTemplate();
    }

    return "";
  }
}

export default Builder;
