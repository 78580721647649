import React, { Component } from "react";
import Control from "./control";
import SimpleReactValidator from "simple-react-validator";
import Fun from "../../../lib/functions";
import UniFun from "../../../modules/uni/functions";
import moment from "moment";
import TopTemplateHeader from "./topTemplateHeader";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";

class SpacesFiles extends Component {
  state = {
    data: this.props.data,
    loaded: false,
    colsTemplateCount: 12,
    colsFormCount: 12,
    cardBodyCustomClass: "",
    squaresConfig: {
      titleAttribute: "title",
      colorAttribute: "color",
      iconAttribute: '<i class="fas fa-infinity"></i>',
      dataSections: [],
    },
    session: {},
  };

  constructor(props) {
    super(props);
    let builder = this.props.builder.state;
    let templateConfig = builder.module.templateConfig;
    if (templateConfig.events) {
      Object.keys(templateConfig.events).map((itm, idx) => {
        this[itm] = templateConfig.events[itm].bind(this);
      });
    }

    this.allSpaces = this.allSpaces.bind(this);
    this.form = React.createRef();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.action) {
      return false;
    }
    return true;
  }

  componentDidMount() {
    let builder = this.props.builder.state;
    let templateConfig = builder.module.templateConfig;
    let useCard =
      typeof templateConfig.useCard !== "undefined"
        ? templateConfig.useCard
        : true;

    let templateTop = templateConfig.templateTop
      ? templateConfig.templateTop
      : () => {};
    this["templateTop"] = templateTop.bind(this);
    let templateBottom = templateConfig.templateBottom
      ? templateConfig.templateBottom
      : () => {};
    this["templateBottom"] = templateBottom.bind(this);
    let templateLeft = templateConfig.templateLeft
      ? templateConfig.templateLeft
      : () => {};
    this["templateLeft"] = templateLeft.bind(this);
    let templateRight = templateConfig.templateRight
      ? templateConfig.templateRight
      : () => {};
    this["templateRight"] = templateRight.bind(this);
    let cardTemplateTop = templateConfig.cardTemplateTop
      ? templateConfig.cardTemplateTop
      : () => {};
    this["cardTemplateTop"] = cardTemplateTop.bind(this);
    let cardTemplateBottom = templateConfig.cardTemplateBottom
      ? templateConfig.cardTemplateBottom
      : () => {};
    this["cardTemplateBottom"] = cardTemplateBottom.bind(this);
    let panelTop = templateConfig.panelTop ? templateConfig.panelTop : () => {};
    this["panelTop"] = panelTop.bind(this);
    let panelBottom = templateConfig.panelBottom
      ? templateConfig.panelBottom
      : () => {};
    this["panelBottom"] = panelBottom.bind(this);
    let panelLeft = templateConfig.panelLeft
      ? templateConfig.panelLeft
      : () => {};
    this["panelLeft"] = panelLeft.bind(this);
    let panelRight = templateConfig.panelRight
      ? templateConfig.panelRight
      : () => {};
    this["panelRight"] = panelRight.bind(this);
    let header = templateConfig.header ? templateConfig.header : () => {};
    this["header"] = header.bind(this);

    let colsTemplateCount = templateConfig.colsTemplateCount
      ? templateConfig.colsTemplateCount
      : this.state.colsTemplateCount;
    let colsFormCount = templateConfig.colsFormCount
      ? templateConfig.colsFormCount
      : this.state.colsFormCount;

    let cardBodyCustomClass = templateConfig.cardBodyCustomClass
      ? templateConfig.cardBodyCustomClass
      : this.state.cardBodyCustomClass;

    let squaresTitleAttribute = this.state.squaresConfig.titleAttribute;
    let squaresColorAttribute = this.state.squaresConfig.colorAttribute;
    let squaresIconAttribute = this.state.squaresConfig.iconAttribute;
    let squaresDataSections = this.state.squaresConfig.dataSections;

    if (templateConfig.squaresConfig) {
      if (templateConfig.squaresConfig.titleAttribute) {
        squaresTitleAttribute = templateConfig.squaresConfig.titleAttribute;
      }
      if (templateConfig.squaresConfig.colorAttribute) {
        squaresColorAttribute = templateConfig.squaresConfig.colorAttribute;
      }
      if (templateConfig.squaresConfig.iconAttribute) {
        squaresIconAttribute = templateConfig.squaresConfig.iconAttribute;
      }
      if (templateConfig.squaresConfig.dataSections) {
        squaresDataSections = templateConfig.squaresConfig.dataSections;
      }
    }

    this.setState({
      loaded: true,
      rawData: this.props.rawData,
      colsTemplateCount: colsTemplateCount,
      colsFormCount: colsFormCount,
      cardBodyCustomClass: cardBodyCustomClass,
      useCard: useCard,
      squaresConfig: {
        titleAttribute: squaresTitleAttribute,
        colorAttribute: squaresColorAttribute,
        iconAttribute: squaresIconAttribute,
        dataSections: squaresDataSections,
      },
    });

    this.validator = this.props.builder.validator;
    if (this.componentDidMountEvent) {
      this.componentDidMountEvent();
    }
  }

  allSpaces() {
    let output = [];

    this.state.data.map((itm, idx) => {
      //itm._id.data.onClick
      const card = (
        <div
          className="card rounded bg-events-overview-meetings bg-events-overview-rounded pl-3 pt-3 pointer"
          style={{
            backgroundColor:
              "#" + itm[this.state.squaresConfig.colorAttribute].value,
            backgroundPosition: "center",
            backgroundImage:
              "url(" + itm[this.state.squaresConfig.iconAttribute].value + ")",
          }}
        >
          {itm[this.state.squaresConfig.titleAttribute].value ? (
            <div
              className="card-header border-0 bg-events-overview-meetings bg-events-overview-rounded w-75"
              style={{
                backgroundColor:
                  "#" + itm[this.state.squaresConfig.colorAttribute].value,
                minHeight: "120px",
              }}
            >
              <h4>{itm[this.state.squaresConfig.titleAttribute].value}</h4>
            </div>
          ) : (
            <div
              style={{
                minHeight: "120px",
              }}
            >
              {itm[this.state.squaresConfig.iconAttribute].value}
            </div>
          )}
          <div
            className="card-body border-0 pt-0 text-secondary"
            style={{ minHeight: "70px" }}
          ></div>
          <div
            className="card-footer border-0 bg-events-overview-meetings bg-events-overview-rounded mb-3"
            style={{
              backgroundColor:
                "#" + itm[this.state.squaresConfig.colorAttribute].value,
            }}
          >
            {itm["participantsCount"]
              ? itm["participantsCount"].value
                ? itm["participantsCount"].value + " people"
                : ""
              : ""}
            {itm["filesCount"]
              ? itm["filesCount"].value
                ? itm["filesCount"].value + " files"
                : ""
              : ""}
          </div>
        </div>
      );

      if (itm._id.data.onClick) {
        output.push(
          <div className="col-md-3">
            <div
              onClick={(e) => {
                itm._id.data.onClick(this.props.builder);
              }}
            >
              {card}
            </div>
          </div>
        );
      } else {
        output.push(
          <div className="col-md-3">
            <Link to={itm["url"].value}>{card}</Link>
          </div>
        );
      }
    });

    return output;
  }

  render() {
    let hasParentFolder = false;
    if (this.state.data[0]) {
      if (this.state.data[0].isParentFolder) {
        if (this.state.data[0].isParentFolder.value === true) {
          hasParentFolder = true;
        }
      }
    }

    let realDataCount = hasParentFolder
      ? this.state.data.length - 1
      : this.state.data.length;

    if (this.state.loaded) {
      return (
        <React.Fragment>
          {this.header()}
          {this.panelTop()}
          <div className="container-fluid">
            <div className="row">
              {this.panelLeft()}
              <div className={"col-" + this.state.colsTemplateCount}>
                <div className="row">
                  {this.templateLeft()}
                  <div className={"col-" + this.state.colsFormCount}>
                    {this.templateTop()}

                    {this.cardTemplateTop()}
                    <div className="row  w-75 mt-4">{this.allSpaces()}</div>
                    {this.cardTemplateBottom()}

                    {this.templateBottom()}
                  </div>
                  {this.templateRight()}
                </div>
              </div>
              {this.panelRight()}
            </div>
          </div>
          {this.panelBottom()}
        </React.Fragment>
      );
    } else {
      return "loading...";
    }
  }
}

export default withRouter(SpacesFiles);
