import React, { Component } from "react";
import Builder from "../../lib/builder";
import Templates from "./templates";
import Fun from "../../lib/functions";
import ModalWidget from "./templates/modal";
import { Link } from "react-router-dom";
import "@coreui/coreui/dist/css/coreui.min.css";
import "@coreui/coreui/dist/js/coreui.bundle";
import Login from "./templates/login";
import Fileupload from "./templates/fileupload";
import UniFun from "../../modules/uni/functions";
import Footer from "./templates/conferences/public/footer";
import Cookies from "./templates/conferences/public/cookies";
import NewLogin from "./templates/newlogin";

window["baseurl"] = function (url) {
  if (url) {
    switch (window.location.hostname.toLocaleLowerCase()) {
      case "heal.hapeiron.com":
        url = "https://api-heal.hapeiron.com".concat(url);
        break;
      case "hapeiron.com":
        url = "https://api.hapeiron.com".concat(url);
        break;
      case "interfima.hapeiron.com":
        url = "https://api.hapeiron.com".concat(url);
        break;
      case "efpalux.hapeiron.com":
        url = "https://api-efpalux.hapeiron.com".concat(url);
        break;
      case "euromedica.hapeiron.com":
        url = "https://api-euromedica.hapeiron.com".concat(url);
        break;
      case "pacc.hapeiron.com":
        url = "https://api-pacc.hapeiron.com".concat(url);
        break;
      case "eia.hapeiron.com":
        url = "https://api-eia.hapeiron.com".concat(url);
        break;
      case "avcon.hapeiron.com":
        url = "https://api-avcon.hapeiron.com".concat(url);
        break;
      case "brightwealthbanking.hapeiron.com":
        url = "https://api-brightwealthbanking.hapeiron.com".concat(url);
        break;
      case "mcfalumni.hapeiron.com":
        url = "https://api-mcfalumni.hapeiron.com".concat(url);
        break;
      case "pella.hapeiron.com":
        url = "https://api-pella.hapeiron.com".concat(url);
        break;
      case "occwealth.hapeiron.com":
        url = "https://api-occwealth.hapeiron.com".concat(url);
        break;
      case "mavrommatis.hapeiron.com":
        url = "https://api-mavrommatis.hapeiron.com".concat(url);
        break;
      default:
        if (!url.startsWith("http")) {
          url = "https://business-v2-api.interfima.org".concat(url);
        }
        break;
    }
  }
  return url;
};

class Index extends Component {
  constructor(props) {
    super(props);

    let Header = Templates["header"];

    let isLoggedIn = false;

    this.state = {
      main: [],
      topmenu: [<Builder module="unitopmenu" view="list" />],
      leftpanel: [],
      header: <Header></Header>,
      loaded: false,
      isLoggedIn: isLoggedIn,
    };
  }

  componentDidMount() {
    Fun.addCSS("/uni/css/style.css");

    if (!localStorage.getItem("isLoggedIn")) {
      let validNotLoggedInPaths = [
        "/login",
        "/forgot-password",
        "/confirm-password",
        "/account-verify",
        "/change-password",
        "/register",
      ];

      if (window.location.pathname.indexOf("conference") > 0) {
        const script = document.createElement("script");
        script.src =
          "https://www.google.com/recaptcha/api.js?render=6LfqMDQeAAAAAKxo_kbK9dVlnwXtRoGWTEOUWn-X";
        script.async = false;
        document.body.appendChild(script);
      }

      if (
        !window.location.pathname.startsWith("/meeting/m-") &&
        !window.location.pathname.startsWith("/form/m-") &&
        !window.location.pathname.startsWith("/stream/") &&
        !window.location.pathname.indexOf("conference") &&
        !window.location.pathname.indexOf("/challenge-form")
      ) {
        if (!validNotLoggedInPaths.includes(window.location.pathname)) {
          UniFun.logout(window.location.href);
        }
      }
    } else {
      Fun.addScript("/uni/js/script.js");
    }

    this.setState({
      loaded: true,
      isLoggedIn: localStorage.getItem("isLoggedIn"),
    });
  }

  render() {
    if (this.state.loaded) {
      if (
        this.state.isLoggedIn ||
        window.location.pathname.startsWith("/meeting/m-") ||
        window.location.pathname.startsWith("/stream/") ||
        window.location.pathname.startsWith("/form/m-") ||
        window.location.pathname.startsWith("/challenge-form/")
      ) {
        if (window.location.pathname.startsWith("/conference/live")) {
          return this.state.main;
        }
        return (
          <React.Fragment>
            {this.state.leftpanel ? (
              <div
                className="c-sidebar c-sidebar-dark c-sidebar-fixed c-sidebar-lg-show uniLight"
                id="sidebar"
              >
                <div className="c-sidebar-brand d-lg-down-none uniDark">
                  <div className="pl-4">
                    {localStorage.getItem("companyName")}
                  </div>
                </div>
                {this.state.leftpanel}
              </div>
            ) : (
              <div id="sidebar"></div>
            )}
            <div className="c-wrapper c-fixed-components">
              {this.state.topmenu}

              <div className="c-body">
                <main className="c-main">{this.state.main}</main>
                {/* <footer className="c-footer">
                  <div></div>
                  <div className="ml-auto"></div>
                </footer> */}
              </div>
            </div>
            <Fileupload
              ref={(refComponent) => {
                window["fileupload"] = refComponent;
              }}
            ></Fileupload>
            <ModalWidget
              ref={(refComponent) => {
                window["modal"] = refComponent;
              }}
            ></ModalWidget>
            <ModalWidget
              ref={(refComponent) => {
                window["modal2"] = refComponent;
              }}
            ></ModalWidget>
            <ModalWidget
              ref={(refComponent) => {
                window["modal3"] = refComponent;
              }}
            ></ModalWidget>
          </React.Fragment>
        );
      } else {
        // return <Login key={Fun.uuid()} content={this.state.main}></Login>;

        if (window.location.pathname.indexOf("/conference") >= 0) {
          return (
            <React.Fragment>
              {this.state.main}
              {this.state.isLoggedIn ? (
                ""
              ) : (
                <>
                  <Cookies></Cookies>
                  {/* <Footer></Footer> */}
                </>
              )}
              <ModalWidget
                ref={(refComponent) => {
                  window["modal"] = refComponent;
                }}
              ></ModalWidget>
            </React.Fragment>
          );
        } else {
          if (
            window.location.hostname.toLocaleLowerCase() ==
              "pella.hapeiron.com" ||
            window.location.hostname.toLocaleLowerCase() == "localhost" ||
            window.location.hostname.toLocaleLowerCase() ==
              "staging-v2.interfima.org"
          ) {
            if (
              window.location.pathname.indexOf("/login") == 0 ||
              window.location.pathname == "/"
            ) {
              return (
                <NewLogin key={Fun.uuid()} content={this.state.main}></NewLogin>
              );
            } else {
              return <Login key={Fun.uuid()} content={this.state.main}></Login>;
            }
          } else {
            return <Login key={Fun.uuid()} content={this.state.main}></Login>;
          }
        }
      }
    }
    return "";
  }
}

export default Index;
