import React, { Component } from "react";
import Fun from "../../../lib/functions";
import ModalWidget from "./modal";

class Fileupload extends Component {
  state = {
    data: [],
    loaded: false,
    output: null,
    content: [],
    defaultValue: null,
    uploadedAll: false,
  };

  constructor(props) {
    super(props);
    this.openModal = this.openModal.bind(this);
    this.uploadFiles = this.uploadFiles.bind(this);
    this.clearAll = this.clearAll.bind(this);
  }

  componentDidMount() {
    this.setState({
      data: this.props.data ? this.props.data : [],
      loaded: true,
      showProgress: false,
      content: [],
    });
  }

  openModal() {
    this.setState({ showProgress: this.state.showProgress ? false : true });
    // window["modalfileupload"].setState({
    //   title: <h3>File upload status</h3>,
    //   size: "lg",
    //   show: true,
    //   targetComponent: this,
    //   show: this.state.modalShowState,
    //   body: <p key={Fun.uuid()}>{this.state.content}</p>,
    // });
  }

  uploadFiles(args) {
    Fun.fetch({
      url: args.url,
      method: args.method,
      submittedData: args.submittedData,
      object: this,
      callback: args.callback ? args.callback : null,
      cancelToken: args.cancelToken ? args.cancelToken : null,
      onUploadProgress: args.onUploadProgress ? args.onUploadProgress : null,
    });
  }

  clearAll() {
    this.setState({ defaultValue: null, content: [] });
  }

  render() {
    let parseValue = this.state.defaultValue
      ? JSON.parse(this.state.defaultValue)
      : null;

    let uploaded = parseValue ? Object.keys(parseValue).length : 0;
    let total = this.state.content.length;

    if (this.state.loaded && this.state.content.length > 0) {
      return (
        <React.Fragment>
          <div className="fileupload-section border shadow p-3 ">
            <div className="row">
              <div className="col-md-8">
                <img
                  src="/uni/svg/files_uploading.svg"
                  className="mr-2"
                  style={{ width: "20px" }}
                ></img>
                <span className="pt-1" style={{ lineHeight: "33px" }}>
                  {/* {this.state.content.length} files uploading */}
                  {uploaded}/{total} uploaded
                </span>
              </div>
              <div className="col-md-4 text-right">
                {uploaded == total ? (
                  <button className="btn btn-link" onClick={this.clearAll}>
                    Done
                  </button>
                ) : (
                  <button className="btn btn-link" onClick={this.openModal}>
                    {this.state.showProgress ? "Hide" : "View Status"}
                  </button>
                )}
              </div>
            </div>
            <div className={this.state.showProgress ? "mt-3" : "d-none"}>
              <div style={{ maxHeight: "200px", overflowY: "auto" }}>
                {this.state.content}
              </div>
              {/* <div className="text-right pt-2">
                {uploaded == total ? (
                  <button
                    className="btn btn-danger pull-right"
                    onClick={this.clearAll}
                  >
                    Done
                  </button>
                ) : (
                  ""
                )}
              </div> */}
            </div>
          </div>
          {/* <ModalWidget
            ref={(refComponent) => {
              window["modalfileupload"] = refComponent;
            }}
            // key={Fun.uuid()}
          ></ModalWidget> */}
        </React.Fragment>
      );
    } else {
      return "";
    }
  }
}

export default Fileupload;
