import React, { Component } from "react";

class Select extends Component {
  state = {
    name: "",
    id: "",
    placeholder: "",
    attributes: "",
    value: "",
    defaultValue: "",
    loaded: false,
    editable: true,
    component: null,
    attributes: "",
    events: {},
    customLayout: null,
    useCustomLayout: true,
    editable: true,
    options: [],
  };

  constructor(props) {
    super(props);

    let events = {};
    if (this.props.field.events) {
      Object.keys(this.props.field.events).map((itm, idx) => {
        this[itm] = this.props.field.events[itm];
        this[itm] = this[itm].bind(this);
        events[itm] = this[itm];
      });
    }

    this.editable = this.editable.bind(this);
    this.nonEditable = this.nonEditable.bind(this);

    this.options = this.props.field.config.options;

    if (typeof this.options === "function") {
      this.options = this.props.field.config.options;
      this.options = this.options.bind(this);
      this.options = this.options();
    }

    let value = this.props.value
      ? this.props.value
      : this.props.placeholder
      ? this.props.placeholder
      : "";

    if (this.props.value == false) {
      value = false;
    }

    if (this.props.field.config) {
      if (this.props.field.config.formatDefaultValue) {
        value = this.props.field.config.formatDefaultValue(value);
      }
    }

    this.state = {
      events: events,
      editable:
        this.props.field.editable != null
          ? this.props.field.editable
          : this.state.editable,
      defaultValue: value,
      options: this.options,
      show: this.props.field.show === false ? false : true,
    };
  }

  validator() {}

  componentDidMount() {
    this.setState({
      attributes: this.props.field.attributes,
      name: this.props.field.alias,
      placeholder: this.props.field.placeholder
        ? this.props.field.placeholder
        : "No data found",
      editable:
        this.props.field.editable != null
          ? this.props.field.editable
          : this.state.editable,
      customLayout: this.props.field.customLayout,
      // options: this.props.field.config.options
      //   ? this.props.field.config.options
      //   : this.state.options,
      useCustomLayout:
        typeof this.props.field.useCustomLayout !== "undefined"
          ? this.props.field.useCustomLayout
          : true,
    });

    if (this.didMount) {
      setTimeout(this.didMount, 0);
    }
  }

  editable() {
    let nodata = "";

    if (this.state.options.length < 1) {
      nodata = (
        <option value="" disabled selected>
          {this.state.placeholder}
        </option>
      );
    }

    let output = (
      <select
        defaultValue={this.state.defaultValue}
        name={this.state.name}
        {...this.state.attributes}
        {...this.state.events}
      >
        {nodata}
        {this.state.options.map((item, key) => {
          let selected = "";
          if (
            item.value.toString().toLowerCase() ===
            this.state.defaultValue.toString().toLowerCase()
          ) {
            selected = "selected";
          }

          //   if (selected === false) {
          //     if (item.selected === true) {
          //       selected = true;
          //     }
          //   }

          return (
            <option
              key={key}
              value={item.value}
              item={JSON.stringify(item)}
              selected={this.state.defaultValue === item.value}
            >
              {item.label}
            </option>
          );
        })}
      </select>
    );
    if (this.state.customLayout && this.state.useCustomLayout == true) {
      return this.state.customLayout({ control: output, object: this });
    }

    return output;
  }

  nonEditable() {
    let output = this.state.options.map((item, key) => {
      if (
        item.value.toString().toLowerCase() ===
        this.state.defaultValue.toString().toLowerCase()
      ) {
        return item.label;
      }

      return "";
    });

    if (this.state.customLayout && this.state.useCustomLayout == true) {
      return this.state.customLayout({ control: output, object: this });
    }

    return output;
  }

  render() {
    if (!this.state.show) {
      return "";
    }

    let output = this.state.editable ? this.editable() : this.nonEditable();

    return output;
  }
}

export default Select;
