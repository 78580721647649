import React, { Component } from "react";

import Fun from "../../../lib/functions";
import { ReactReader } from "react-reader";
import UniFun from "../../../modules/uni/functions";

class EpubViewerTemplate extends Component {
  state = {
    data: [],
    loaded: false,
    output: null,
    location: null,
  };

  constructor(props) {
    super(props);
    let builder = this.props.builder.state;
    let templateConfig = builder.module.templateConfig;
    this.locationChanged = this.locationChanged.bind(this);
    if (templateConfig.events) {
      Object.keys(templateConfig.events).map((itm, idx) => {
        this[itm] = templateConfig.events[itm].bind(this);
      });
    }
  }

  componentDidMount() {
    if (!localStorage.getItem("isLoggedIn")) {
      UniFun.logout(window.location.href);
    } else {
      this.setState({ data: this.props.data, loaded: true });
    }
    if (this.componentDidMountEvent) {
      setTimeout(this.componentDidMountEvent, 0);
    }
  }

  locationChanged(epubcifi) {
    this.setState({ location: epubcifi });
  }

  render() {
    if (this.state.loaded) {
      return (
        <div style={{ borderTop: "1px solid #d8dbe0" }}>
          <div
            style={{
              height: "100vh",
            }}
            className="myReader"
          >
            <ReactReader
              url={window["baseurl"](
                "/agenda-file/data/" +
                  Fun.getURLPathValue(3) +
                  "?type=preview&jwt=" +
                  localStorage.getItem("jwt") +
                  "&.epub"
              )}
              location={this.state.location}
              locationChanged={this.locationChanged}
              epubOptions={{
                allowScriptedContent: true,
              }}
            />
          </div>
        </div>
      );
    } else {
      return "loading...";
    }
  }
}

export default EpubViewerTemplate;
