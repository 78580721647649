import React, { Component } from "react";

class Header extends Component {
  state = {
    data: [],
    loaded: false,
    output: null,
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.setState({ data: this.props.data, loaded: true });
  }

  render() {
    if (this.state.loaded) {
      return (
        <header className="c-header c-header-light c-header-fixed c-header-with-subheader ">
          <div className="c-subheader px-3 uniDark"></div>
        </header>
      );
    } else {
      return "loading...";
    }
  }
}

export default Header;
