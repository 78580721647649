import React, { Component } from "react";
import Fun from "../../lib/functions";
import Builder from "../../lib/builder";
import UniFun from "./functions";
import moment from "moment";

const UniMeetings = {
  auth: "",
  views: {
    detail: {
      auth: "",
      apis: {
        view: {
          url: "/meeting/{id}",
          parameters: {
            headers: {},
            replace: {},
          },
          formatter: function (data) {
            return UniMeetingsFormatter(data);
          },
        },
        update: {
          url: "/meeting",
          parameters: {
            headers: {
              "Content-Type": "application/json",
            },
            replace: {},
          },
          formatter: function (data) {
            return UniMeetingsFormatter(data);
          },
        },
      },
      template: "form",
      templateConfig: {
        events: {
          onSubmit: function (e) {
            e.preventDefault();
            let submittedData = Fun.getSubmitJSON(e);

            let from = UniFun.fromToDateFormat(
              submittedData.fromToDateTime[0].fromDateTime[0].fromDate,
              submittedData.fromToDateTime[0].fromDateTime[0].fromTime
            );

            let to = UniFun.fromToDateFormat(
              submittedData.fromToDateTime[0].toDateTime[0].toDate,
              submittedData.fromToDateTime[0].toDateTime[0].toTime
            );

            let duration = moment
              .duration(moment(to).diff(moment(from)))
              .asMinutes();

            let jsonData = {
              title: submittedData.title,
              from: from,
              to: to,
              options: submittedData.options[0],
              files: submittedData["files"]
                ? UniFun.agendaFilesJson(JSON.parse(submittedData["files"]))
                : [],
              description: submittedData.description,
              participants: UniFun.participantsJSONData(
                JSON.parse(submittedData.participants)
              ),
              reminders: JSON.parse(submittedData.reminders),
              duration: duration,
            };
          },
        },
        colsTemplateCount: 10,
        includeProperties: [
          "_id",
          "title",
          "fromToDateTime",
          "organizers",
          "participants",
          "description",
          "files",
          "reminders",
          "options",
        ],
        excludeProperties: [],
      },
      pk: "_id",
      properties: UniMeetingsProperties({ editable: true }),
    },
    meeting: {
      auth: "",
      apis: {
        view: {
          url: "{url}",
          parameters: {
            headers: {},
            replace: {
              url: function () {
                if (Fun.getURLPathValue(2).startsWith("v-")) {
                  let id = Fun.getURLPathValue(2).replace("v-", "");
                  return "/trainingConferenceInfo/" + id;
                } else {
                  let id = Fun.getURLPathValue(2).replace("m-", "");
                  return "/meetingInfo/" + id;
                }
              },
            },
          },
          formatter: function (data) {
            if (data) {
              if (data.data) {
                if (!Array.isArray(data.data)) {
                  data.data = [data.data];
                }
                if (Fun.getURLPathValue(2).startsWith("v-")) {
                  data.data[0].type = "conference";
                } else {
                  data.data[0].type = "meeting";
                }
              }
            }

            return UniMeetingsFormatter(data);
          },
        },
      },
      template: "meeting",
      templateConfig: {
        events: {
          onSubmit: function (e) {},
        },
        colsTemplateCount: 10,
        includeProperties: [],
        excludeProperties: [],
      },
      pk: "_id",
      properties: UniMeetingsProperties({ editable: true }),
    },
    add: {
      auth: "",
      apis: {
        view: {
          data: { data: [{}] },
          parameters: {
            headers: {
              "Content-Type": "application/json",
            },
            replace: {},
          },
          formatter: function (data) {
            return UniMeetingsFormatter(data);
          },
        },
        update: {
          url: "/meeting/",
          parameters: {
            headers: {
              "Content-Type": "application/json",
            },
            replace: {},
          },
          formatter: function (data) {
            return UniMeetingsFormatter(data);
          },
        },
      },
      template: "form",
      templateConfig: {
        events: {
          onSubmit: function (e) {
            e.preventDefault();
            let submittedData = Fun.getSubmitJSON(e);
            let from = UniFun.fromToDateFormat(
              submittedData.fromToDateTime[0].fromDateTime[0].fromDate,
              submittedData.fromToDateTime[0].fromDateTime[0].fromTime
            );

            let to = UniFun.fromToDateFormat(
              submittedData.fromToDateTime[0].toDateTime[0].toDate,
              submittedData.fromToDateTime[0].toDateTime[0].toTime
            );
            if (
              Fun.validate(
                this.props.builder.validator,
                Fun.formDataToJSON(e)
              ) &&
              UniFun.fromToValidation(from, to)
            ) {
              let duration = moment
                .duration(moment(to).diff(moment(from)))
                .asMinutes();

              let organizers = [];
              try {
                JSON.parse(submittedData.organizers)?.forEach((itm, idx) => {
                  organizers.push(itm._id);
                });
              } catch (e) {}
              submittedData["organizers"] = organizers;

              let jsonData = {
                title: submittedData.title,
                from: from,
                organizers: organizers,
                options: submittedData.options[0],
                to: to,
                files: submittedData["files"]
                  ? UniFun.agendaFilesJson(JSON.parse(submittedData["files"]))
                  : [],
                description: submittedData.description,
                participants: UniFun.participantsJSONData(
                  JSON.parse(submittedData.participants)
                ),
                reminders: [JSON.parse(submittedData.reminders)],
                duration: duration,
              };

              Fun.update(e, {
                url: this.props.builder.state.module.apis.update,
                method: "POST",
                callback: function (
                  submittedData,
                  status,
                  responsedata,
                  responsestatus
                ) {
                  if (responsestatus == "200") {
                    if (window["modal2"].state.callback) {
                      window["modal2"].state.callback(submittedData);
                    } else {
                      if (window["modal2"].state.targetComponent) {
                        window["modal2"].state.targetComponent.setState({
                          data: submittedData,
                        });
                      }
                    }
                    Fun.createNotification({
                      type: "success",
                      message: "The meeting was created",
                    });
                  } else {
                    if (responsedata.data.exceptions) {
                      responsedata.data.exceptions.map((itm, idx) => {
                        Fun.createNotification({
                          message: itm.errorDescription,
                          type: "danger",
                        });
                      });
                    }
                  }
                },
                parameters: {
                  headers: {
                    "content-type": "application/json",
                  },
                },
                submittedData: JSON.stringify(jsonData),
              });
            }
          },
        },
        colsTemplateCount: 12,
        includeProperties: [
          "title",
          "fromToDateTime",
          "organizers",
          "participants",
          "description",
          "files",
          "reminders",
          "options",
        ],
      },
      pk: "_id",
      properties: UniMeetingsProperties({ editable: true }),
    },
  },
};

export default UniMeetings;

function UniMeetingsProperties(input) {
  let editable = input.editable;

  return {
    _id: {
      alias: "_id",
      control: "hidden",
      label: "",
      editable: editable,
      apiPath: "",
      auth: "",
      validations: "",
      attributes: {},
    },
    title: {
      alias: "title",
      label: "Title",
      control: "text",
      placeholder: "Type the title of the meeting",
      editable: editable,
      apiPath: "",
      auth: "",
      validations: "required",
      attributes: {
        className: "form-control",
      },
    },
    moderatorId: {
      alias: "moderatorId",
      label: "",
      control: "hidden",
      editable: false,
    },
    moderators: {
      alias: "moderators",
      label: "",
      control: "hidden",
      editable: false,
    },
    fromToDateTime: {
      alias: "fromToDateTime",
      control: "group",
      label: "Availability",
      editable: editable,
      customLayout: function (args) {
        return (
          <React.Fragment>
            <label>{args.object.props.field.label}</label>
            <div className="row mb-3">
              <div className="col-md-5 pr-0 availability-calendar">
                {args.control[0]}
              </div>
              <div className="col-md-1 text-center pr-0 pt-2 ">to</div>
              <div className="col-md-5 pl-0 availability-calendar">
                {args.control[1]}
              </div>
              <div className="col-md-1"></div>
            </div>
          </React.Fragment>
        );
      },
      useCustomLayout: true,
      config: {
        properties: {
          fromDateTime: {
            alias: "fromDateTime",
            control: "group",
            editable: editable,
            customLayout: function (args) {
              return (
                <React.Fragment>
                  <div className="row mb-3">
                    <div className="col-md-8 pr-0">{args.control[0]}</div>
                    <div className="col-md-4 pr-0">{args.control[1]}</div>
                  </div>
                </React.Fragment>
              );
            },
            useCustomLayout: true,
            config: {
              properties: {
                fromDate: {
                  alias: "fromDate",
                  control: "datetime",
                  config: {
                    fromFormat: "YYYY-MM-DDT00:00:00.000Z",
                    toFormat: "YYYY-MM-DDT00:00:00.000Z",
                    includeTime: false,
                    valueEditFormat: { dateStyle: "short" },
                    valueDisplayFormat: { dateStyle: "short" },
                  },
                  editable: editable,
                },
                fromTime: {
                  alias: "fromTime",
                  control: "select",
                  config: {
                    options: UniFun.timeSelect,
                  },
                  editable: editable,
                  attributes: {
                    className: "form-control",
                  },
                },
              },
            },
          },
          toDateTime: {
            alias: "toDateTime",
            control: "group",
            editable: editable,
            customLayout: function (args) {
              return (
                <React.Fragment>
                  <div className="row mb-3">
                    <div className="col-md-8 pr-0">{args.control[0]}</div>
                    <div className="col-md-4 pr-0">{args.control[1]}</div>
                  </div>
                </React.Fragment>
              );
            },
            useCustomLayout: true,
            config: {
              properties: {
                toDate: {
                  alias: "toDate",
                  control: "datetime",
                  config: {
                    fromFormat: "YYYY-MM-DDT00:00:00.000Z",
                    toFormat: "YYYY-MM-DDT00:00:00.000Z",
                    includeTime: false,
                    valueEditFormat: { dateStyle: "short" },
                    valueDisplayFormat: { dateStyle: "short" },
                  },
                  editable: editable,
                },
                toTime: {
                  alias: "toTime",
                  control: "select",
                  config: {
                    options: UniFun.timeSelect,
                  },
                  editable: editable,
                  attributes: {
                    className: "form-control",
                  },
                },
              },
            },
          },
        },
      },
    },
    organizers: {
      alias: "organizers",
      label: "Add organizers", //"Select additional hosts (you are already a host)",
      placeholder: "Type email or name...",
      control: "tags",
      editable: editable,
      config: {
        idAttribute: "_id",
        nameAttribute: "name",
        apis: {
          search: {
            url: "/user/autocomplete/{query}?type=all",
            formatter: function (args) {
              let formattedData = [];
              if (args) {
                let data = args.data;
                if (data) {
                  data.map((itm, idx) => {
                    if (itm.type != "group") {
                      let fullName =
                        itm["firstName"] +
                        " " +
                        itm["lastName"] +
                        " - " +
                        itm["email"];

                      // fullName = itm["firstName"] + " - " + itm["lastName"];

                      formattedData.push({
                        _id: itm["_id"],
                        name: fullName,
                        group: itm.type,
                      });
                    }
                  });
                }
              }
              return formattedData;
            },
            parameters: {
              replace: {
                query: "",
              },
            },
          },
        },
      },
      attributes: {
        className: "form-control",
      },
    },
    participants: {
      alias: "participants",
      label: "Choose participants",
      control: "tags",
      placeholder: "Select the participants of the meeting",
      editable: editable,
      config: {
        idAttribute: "_id",
        nameAttribute: "name",
        apis: {
          search: {
            url: "/user/autocomplete/{query}?type=all",
            formatter: function (args) {
              let formattedData = [];
              if (args) {
                let data = args.data;
                if (data) {
                  data.map((itm, idx) => {
                    if (itm["_id"]) {
                      let fullName =
                        itm["firstName"] +
                        " " +
                        itm["lastName"] +
                        " - " +
                        itm["email"];

                      if (itm.type == "group") {
                        fullName = itm["firstName"] + " - " + itm["lastName"];
                      }
                      formattedData.push({
                        _id: itm["_id"],
                        name: fullName,
                        group: itm.type,
                      });
                    } else {
                      if (UniFun.getURLPathValue(1) !== "conference") {
                        formattedData.push({ id: "", name: itm });
                      }
                    }
                  });
                }
              }
              return formattedData;
            },
            parameters: {
              replace: {
                query: "",
              },
            },
          },
        },
      },
      attributes: {
        className: "form-control",
      },
      events: {
        onAddition: function (tag) {
          if (!tag._id) {
            // this.ref.current.input.current.input.current.blur();
            if (Fun.getURLPathValue(1) !== "conference") {
              if (UniFun.isEmail(tag.name)) {
                tag["_id"] = tag.name.trim();
                tag["name"] = tag.name.trim();
                const tags = [].concat(this.state.tags, tag);
                this.setState({ tags });
              } else {
                let splitParticipants = [];
                let notValidEmails = [];

                tag.name = tag.name.replaceAll(",", " ");
                tag.name = tag.name.replaceAll(";", " ");

                tag.name.split(" ").forEach((itm, idx) => {
                  if (itm) {
                    if (UniFun.isEmail(itm.trim())) {
                      splitParticipants.push({
                        id: itm.trim(),
                        name: itm.trim(),
                      });
                    } else {
                      notValidEmails.push(
                        <span className="d-block">{itm.trim()}</span>
                      );
                    }
                  }
                });

                if (notValidEmails.length > 0) {
                  Fun.createNotification({
                    message: (
                      <span>
                        The following inputs are not valid emails:<br></br>
                        {notValidEmails}
                      </span>
                    ),
                    type: "danger",
                  });
                }

                const tags = [].concat(this.state.tags, splitParticipants);
                this.setState({ tags });
              }
            }
          } else {
            const tags = [].concat(this.state.tags, tag);
            this.setState({ tags });
          }
        },
      },
    },
    description: {
      alias: "description",
      label: "Description",
      control: "editor",
      placeholder: "Write a description of the meeting",
      nonEditableDefaultValue: "No description available",
      editable: editable,
      attributes: {
        className: "form-control",
      },
    },
    moderatorHasJoined: {
      alias: "moderatorHasJoined",
      label: "",
      control: "hidden",
      editable: editable,
      attributes: {},
    },
    type: {
      alias: "type",
      label: "",
      control: "hidden",
      editable: editable,
      attributes: {},
    },
    files: {
      alias: "files",
      control: "dropzone",
      label: "Attach files",
      editable: editable,
      customContentLayout: function () {
        let files = JSON.parse(this.state.defaultValue);

        let content = [];

        Object.keys(files).map((itm, idx) => {
          content.push(
            UniFun.trainingNodeFileUI(files[itm], this.removeFile, itm)
          );
        });

        this.state.content?.map((itm, idx) => {
          // if (typeof files[itm.key] === "undefined") {
          //   content.push(itm);
          // }
          if (this.state.pending.indexOf(itm.key) >= 0) {
            content.push(itm);
          }
        });
        return (
          <React.Fragment>
            {this.state.placeholder()}
            {content}
            {/* {content.length > 0 ? "" : this.state.placeholder()} */}
          </React.Fragment>
        );
      },

      useCustomLayout: true,
      config: {
        placeholder: function () {
          return (
            <div className="p-3 pb-1">
              <strong>
                Drag & drop here or{" "}
                <a
                  href="javascript:void(0);"
                  className="text-primary"
                  onClick={this.onTargetClick}
                >
                  <strong>browse file</strong>
                </a>{" "}
                to attach
              </strong>
              <br></br>
              <span className="text-dark">Maximum size: 50MB</span>
            </div>
          );
        },
      },
      events: {
        removeFile: function (id) {
          let defaultValue = {};

          Object.keys(JSON.parse(this.state.defaultValue)).map((itm, idx) => {
            if (itm != id) {
              defaultValue[itm] = JSON.parse(this.state.defaultValue)[itm];
            }
          });

          let content = [];
          this.state.content?.map((itm, idx) => {
            if (itm.key != id) {
              content.push(itm);
            }
          });

          this.setState({
            defaultValue: JSON.stringify(defaultValue),
            content: content,
          });

          return;
        },
        onDrop: function (files) {
          //this.setState({ defaultValue: JSON.stringify({}) });

          UniFun.fileProgress({
            control: this,
            files: files,
            url: "/agenda-file",
            type: "meeting",
          });
        },
      },
      attributes: {
        className: "border rounded  dropzone-trainings",
      },
    },
    reminders: {
      alias: "reminders",
      control: "select",
      config: {
        options: [
          {
            label: "5 minutes before",
            value: JSON.stringify({ type: "minutes", value: 5 }),
          },
          {
            label: "10 minutes before",
            value: JSON.stringify({ type: "minutes", value: 10 }),
          },
          {
            label: "30 minutes before",
            value: JSON.stringify({ type: "minutes", value: 30 }),
          },
          {
            label: "1 hour before",
            value: JSON.stringify({ type: "minutes", value: 60 }),
          },
          {
            label: "1 day before",
            value: JSON.stringify({ type: "days", value: 1 }),
          },
        ],
      },
      label: "Reminder",
      editable: editable,
      attributes: {
        className: "form-control",
      },
    },
    options: {
      alias: "options",
      label: <span className="d-block mb-2 text-strong">Options</span>,
      control: "group",
      config: {
        properties: {
          allowCameraForParticipants: {
            alias: "allowCameraForParticipants",
            label: "Allow camera for participants",
            control: "switch",
            editable: editable,
            defaultValue: true,
            config: {
              options: [
                { label: "Yes", value: true },
                { label: "No", value: false },
              ],
            },
            attributes: {
              className: "form-control",
            },
          },
          recording: {
            alias: "recording",
            label: "Record meeting",
            control: "switch",
            editable: editable,
            defaultValue: false,
            config: {
              options: [
                { label: "Yes", value: true },
                { label: "No", value: false },
              ],
            },
            useCustomLayout: true,
            customLayout: function (args) {
              if (localStorage.getItem("meetingRecording") == "true") {
                return (
                  <div className="form-group" style={{ marginBottom: "30px" }}>
                    <span>
                      <label className="text-dark">
                        {args.object.props.field.label}
                      </label>
                    </span>
                    {args.control}
                  </div>
                );
              }

              return "";
            },
            events: {
              onChange: function (checked) {
                if (checked) {
                  document
                    .getElementById("recordingMethod")
                    .classList.remove("d-none");
                } else {
                  document
                    .getElementById("recordingMethod")
                    .classList.add("d-none");
                }
              },
            },
            attributes: {
              className: "form-control",
            },
          },
          recordingMethod: {
            alias: "recordingMethod",
            label: "Select recording method",
            control: "select",
            editable: editable,
            useCustomLayout: true,
            customLayout: function (args) {
              if (localStorage.getItem("meetingRecording")) {
                let classHidden = "";

                if (!args.object.props.data.options?.recording) {
                  classHidden = "d-none";
                }
                return (
                  <div
                    className={"form-group " + classHidden}
                    style={{ marginBottom: "30px" }}
                    id="recordingMethod"
                  >
                    <span>
                      <label className="text-dark">
                        {args.object.props.field.label}
                      </label>
                    </span>
                    {args.control}
                  </div>
                );
              }

              return "";
            },
            config: {
              options: function () {
                let control = this;

                Fun.fetch({
                  url: {
                    url: "/recordingMethods",
                    parameters: {
                      replace: {},
                      headers: {},
                    },
                  },
                  callback: function (args) {
                    let output = [];

                    let data = args.data;

                    data.map((itm, idx) => {
                      output.push({
                        label: itm.label,
                        value: itm.value,
                      });
                    });

                    control.setState({ options: output });
                  },
                });

                return [];
              },
            },
            attributes: {
              className: "form-control",
            },
          },
        },
      },
    },
  };
}

function UniMeetingsFormatter(data) {
  if (data) {
    let formattedData = [];

    data.data.map((itm, idx) => {
      let formattedRecord = {};

      formattedRecord["_id"] = itm._id;
      formattedRecord["title"] = itm.title;
      formattedRecord["from"] = itm.from;
      formattedRecord["to"] = itm.to;
      formattedRecord["type"] = itm.type;
      formattedRecord["options"] = {};
      try {
        formattedRecord["options"] = itm.options;
      } catch (e) {}

      formattedRecord["moderatorHasJoined"] = itm.moderatorHasJoined;
      formattedRecord["moderators"] = itm.moderators
        ? itm.moderators
        : [itm.moderator];

      const start = moment();
      const halfhour = 30 - (start.minute() % 30);
      const dateFromTime = moment(start)
        .add(halfhour, "minutes")
        .format("HH:mm:00");
      const dateToTime = moment(start)
        .add(halfhour, "minutes")
        .add("3", "hours")
        .format("HH:mm:00");

      let fromTime = dateFromTime;
      if (itm.from) {
        fromTime = moment(itm.from).format("HH:mm:00");
      }
      let toTime = dateToTime;
      if (itm.to) {
        toTime = moment(itm.to).format("HH:mm:00");
      }

      if (!itm.from && !itm.to) {
        if (
          moment(start)
            .add(halfhour, "minutes")
            .isBefore(moment(start).add(halfhour, "minutes").add("3", "hours"))
        ) {
          itm.to = moment(start)
            .add(halfhour, "minutes")
            .add("3", "hours")
            .add("1", "days");
        }
      }

      formattedRecord["fromToDateTime"] = {
        fromDateTime: {
          fromDate: itm.from,
          fromTime: fromTime,
        },
        toDateTime: {
          toDate: itm.to,
          toTime: toTime,
        },
      };

      formattedRecord["description"] = itm.description;
      formattedRecord["moderatorId"] = itm.createdBy;

      formattedRecord["files"] = itm.files;
      formattedRecord["reminders"] = itm.reminders
        ? JSON.stringify({
            type: itm.reminders[0].type,
            value: itm.reminders[0].value,
          })
        : {};

      formattedData.push(formattedRecord);
    });

    return formattedData;
  }
}
