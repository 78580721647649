import React, { Component } from "react";
import Fun from "../../lib/functions";

const UniLogin = {
  auth: "",
  views: {
    login: {
      auth: "",
      apis: {
        view: {
          data: { data: [{}] },
          parameters: {
            headers: {},
          },
          formatter: function (data) {
            return UniLoginFormatter(data);
          },
        },
        login: {
          url: "/auth/login",
          parameters: {
            headers: {},
          },
        },
      },
      template: function () {
        if (
          window.location.hostname.toLocaleLowerCase() ==
            "pella.hapeiron.com" ||
          window.location.hostname.toLocaleLowerCase() == "localhost" ||
          window.location.hostname.toLocaleLowerCase() ==
            "staging-v2.interfima.org"
        ) {
          return "newloginform";
        }
        return "loginform";
      },
      templateConfig: {
        events: {
          onSubmit: function (e) {
            e.preventDefault();

            let jsonData = Fun.getSubmitJSON(e);

            jsonData = {
              email: jsonData["uni_username"],
              password: jsonData["uni_password"],
            };

            Fun.update(e, {
              url: this.props.builder.state.module.apis.login,
              method: "POST",
              callback: function (
                submittedData,
                status,
                responsedata,
                responsestatus
              ) {
                if (submittedData.jwt) {
                  localStorage.setItem("isLoggedIn", true);
                  localStorage.setItem("jwt", submittedData.jwt);
                  localStorage.setItem("userid", submittedData.extra._id);
                  localStorage.setItem("role", submittedData.extra.role);
                  localStorage.setItem(
                    "profileImage",
                    submittedData.extra.profileImg
                  );

                  localStorage.setItem(
                    "firstName",
                    submittedData.extra.firstName
                  );
                  localStorage.setItem(
                    "lastName",
                    submittedData.extra.lastName
                  );

                  localStorage.setItem(
                    "instructor",
                    submittedData.extra.instructor
                  );
                  localStorage.setItem("host", submittedData.extra.host);

                  localStorage.setItem("manager", submittedData.extra.manager);

                  localStorage.setItem(
                    "intervalChecker",
                    submittedData.extra.intervalChecker
                  );

                  localStorage.setItem(
                    "conferenceLiveInfoInterval",
                    submittedData.extra.conferenceLiveInfoInterval
                  );
                  localStorage.setItem("library", submittedData.extra.library);
                  localStorage.setItem(
                    "companyId",
                    submittedData.extra.companyId
                  );
                  localStorage.setItem(
                    "companyName",
                    submittedData.extra.companyName
                  );

                  localStorage.setItem(
                    "personalFolderDirectory",
                    submittedData.extra.personalFolderDirectory
                  );

                  localStorage.setItem(
                    "trainingRecording",
                    submittedData.extra.trainingRecording
                  );

                  localStorage.setItem(
                    "meetingRecording",
                    submittedData.extra.meetingRecording
                  );

                  if (window.localStorage) {
                    if (!localStorage.getItem("firstLoad")) {
                      localStorage["firstLoad"] = true;
                      if (Fun.getParameterByName("redirect")) {
                        window.location.replace(
                          Fun.getParameterByName("redirect")
                        );
                      } else {
                        window.location.replace("/");
                      }
                    } else localStorage.removeItem("firstLoad");
                  }
                } else {
                  let message = responsedata.data.message;

                  if (responsedata.data.exceptions) {
                    if (responsedata.data.exceptions[0]) {
                      message =
                        responsedata.data.exceptions[0].errorDescription;
                    }
                  }

                  Fun.createNotification({
                    message: message,
                    type: "danger",
                  });
                }
              },
              parameters: {
                headers: {
                  "content-type": "application/json",
                },
              },
              submittedData: JSON.stringify(jsonData),
            });
          },
        },
        colsTemplateCount: 10,
      },
      pk: "id",
      properties: UniLoginProperties({ editable: true }),
    },
    forgotpassword: {
      auth: "",
      apis: {
        view: {
          data: { data: [] },
          parameters: {
            headers: {},
          },
          formatter: function (data) {
            return UniLoginFormatter(data);
          },
        },
        forgot: {
          url: "/auth/changePassword",
          parameters: {
            headers: {},
          },
          formatter: function (data) {
            return UniLoginFormatter(data);
          },
        },
      },
      template: "forgotpassword",
      templateConfig: {
        events: {
          onSubmit: function (e) {
            e.preventDefault();

            let jsonData = Fun.getSubmitJSON(e);

            jsonData = {
              email: jsonData["email"],
            };

            Fun.update(e, {
              url: this.props.builder.state.module.apis.forgot,
              method: "POST",
              callback: function (
                submittedData,
                status,
                responsedata,
                responsestatus
              ) {
                if (responsestatus == "200") {
                  Fun.createNotification({
                    message: "A link has been sent in your email",
                    type: "success",
                  });
                } else {
                  let message = responsedata.data.message;

                  if (responsedata.data.exceptions) {
                    if (responsedata.data.exceptions[0]) {
                      message =
                        responsedata.data.exceptions[0].errorDescription;
                    }
                  }

                  Fun.createNotification({
                    message: message,
                    type: "danger",
                  });
                }
              },
              parameters: {
                headers: {
                  "content-type": "application/json",
                },
              },
              submittedData: JSON.stringify(jsonData),
            });
          },
        },
        colsTemplateCount: 10,
      },
      pk: "id",
      properties: UniLoginProperties({ editable: true }),
    },
    confirmpassword: {
      auth: "",
      apis: {
        view: {
          data: { data: [] },
          parameters: {
            headers: {},
          },
          formatter: function (data) {
            return UniLoginFormatter(data);
          },
        },
        register: {
          url: "/auth/register",
          parameters: {
            headers: {},
          },
          formatter: function (data) {
            return UniLoginFormatter(data);
          },
        },
      },
      template: "confirmpassword",
      templateConfig: {
        events: {
          onSubmit: function (e) {
            e.preventDefault();

            let jsonData = Fun.getSubmitJSON(e);

            jsonData = {
              password: jsonData["password"],
              confirmPassword: jsonData["confirmPassword"],
            };

            let authorization = "Bearer " + Fun.getParameterByName("i");

            Fun.update(e, {
              url: this.props.builder.state.module.apis.register,
              method: "POST",
              callback: function (
                submittedData,
                status,
                responsedata,
                responsestatus
              ) {
                if (responsestatus == "200") {
                  Fun.createNotification({
                    message: "Please login with your credentials!",
                    type: "success",
                  });
                  setTimeout(function () {
                    window.location.replace("/login");
                  }, 500);
                } else {
                  let message = responsedata.data.message;

                  if (responsedata.data.exceptions) {
                    if (responsedata.data.exceptions[0]) {
                      message =
                        responsedata.data.exceptions[0].errorDescription;
                    }
                  }

                  Fun.createNotification({
                    message: message,
                    type: "danger",
                  });
                }
              },
              parameters: {
                headers: {
                  "content-type": "application/json",
                  Authorization: authorization,
                },
              },
              submittedData: JSON.stringify(jsonData),
            });
          },
        },
        colsTemplateCount: 10,
      },
      pk: "id",
      properties: UniLoginProperties({ editable: true }),
    },
    changepassword: {
      auth: "",
      apis: {
        view: {
          data: { data: [] },
          parameters: {
            headers: {},
          },
          formatter: function (data) {
            return UniLoginFormatter(data);
          },
        },
        register: {
          url: "/auth/saveNewPassword",
          parameters: {
            headers: {},
          },
          formatter: function (data) {
            return UniLoginFormatter(data);
          },
        },
      },
      template: "confirmpassword",
      templateConfig: {
        events: {
          onSubmit: function (e) {
            e.preventDefault();

            let jsonData = Fun.getSubmitJSON(e);

            jsonData = {
              password: jsonData["password"],
              confirmPassword: jsonData["confirmPassword"],
            };

            let authorization = "Bearer " + Fun.getParameterByName("i");

            Fun.update(e, {
              url: this.props.builder.state.module.apis.register,
              method: "POST",
              callback: function (
                submittedData,
                status,
                responsedata,
                responsestatus
              ) {
                if (responsestatus == "200") {
                  setTimeout(function () {
                    window.location.replace("/login");
                  }, 500);
                } else {
                  let message = responsedata.data.message;

                  if (responsedata.data.exceptions) {
                    if (responsedata.data.exceptions[0]) {
                      message =
                        responsedata.data.exceptions[0].errorDescription;
                    }
                  }

                  Fun.createNotification({
                    message: message,
                    type: "danger",
                  });
                }
              },
              parameters: {
                headers: {
                  "content-type": "application/json",
                  Authorization: authorization,
                },
              },
              submittedData: JSON.stringify(jsonData),
            });
          },
        },
        colsTemplateCount: 10,
      },
      pk: "id",
      properties: UniLoginProperties({ editable: true }),
    },
    otp: {
      auth: "",
      apis: {
        view: {
          url: "/auth/sendVerificationKey",
          method: "POST",
          parameters: {
            headers: {
              Authorization: function () {
                return "Bearer " + Fun.getParameterByName("i");
              },
            },
          },
          formatter: UniLoginOTPFormatter,
        },
        otp: {
          url: "/auth/verify",
          formatter: UniLoginOTPFormatter,
        },
      },
      template: "otp",
      templateConfig: {
        includeProperties: ["hash"],
        events: {
          onSubmit: function (e) {
            e.preventDefault();

            let jsonData = Fun.getSubmitJSON(e);

            jsonData = {
              hash: jsonData["hash"],
              key: jsonData["key"],
            };

            let authorization = "Bearer " + Fun.getParameterByName("i");

            let builder = this.props.builder;

            Fun.update(e, {
              url: this.props.builder.state.module.apis.otp,
              method: "POST",
              callback: function (
                submittedData,
                status,
                responsedata,
                responsestatus
              ) {
                if (responsestatus == "200") {
                  let url =
                    "/confirm-password?i=" + Fun.getParameterByName("i");
                  window.location.replace(url);
                } else {
                  let message = responsedata.data.message;

                  if (responsedata.data.exceptions) {
                    if (responsedata.data.exceptions[0]) {
                      message =
                        responsedata.data.exceptions[0].errorDescription;
                    }
                  }

                  Fun.createNotification({
                    message: message,
                    type: "danger",
                  });
                }
              },
              parameters: {
                headers: {
                  "content-type": "application/json",
                  Authorization: authorization,
                },
              },
              submittedData: JSON.stringify(jsonData),
            });
          },
          onSubmitResend: function (e) {
            e.preventDefault();

            let jsonData = Fun.getSubmitJSON(e);

            let object = this;

            jsonData = {
              resend: true,
            };

            let authorization = "Bearer " + Fun.getParameterByName("i");

            Fun.update(e, {
              url: this.props.builder.state.module.apis.view,
              method: "POST",
              callback: function (
                submittedData,
                status,
                responsedata,
                responsestatus
              ) {
                try {
                  if (responsestatus == "200") {
                    Fun.getComp(object, "hash", "0").setState({
                      defaultValue: responsedata.fullHash,
                    });
                    Fun.createNotification({
                      message: responsedata.message,
                      type: "success",
                    });
                  } else {
                    let message = responsedata.data.message;

                    if (responsedata.data.exceptions) {
                      if (responsedata.data.exceptions[0]) {
                        message =
                          responsedata.data.exceptions[0].errorDescription;
                      }
                    }

                    Fun.createNotification({
                      message: message,
                      type: "danger",
                    });
                  }
                } catch (e) {
                  console.log(e);
                }
              },
              parameters: {
                headers: {
                  "content-type": "application/json",
                  Authorization: authorization,
                },
              },
              submittedData: JSON.stringify(jsonData),
            });
          },
        },
        colsTemplateCount: 10,
      },
      pk: "id",
      properties: {
        hash: {
          alias: "hash",
          control: "hidden",
          editable: true,
        },
      },
    },
  },
};

export default UniLogin;

function UniLoginProperties(input) {
  let editable = input.editable;

  return {
    id: {
      alias: "id",
      control: "hidden",
      apiPath: "",
      auth: "",
      validations: "",
    },
  };
}

function UniLoginOTPFormatter(data) {
  let formattedData = [];

  if (data) {
    if (data.fullHash) {
      formattedData.push({ hash: data.fullHash, id: "0" });
    }
  }

  return formattedData;
}

function UniLoginFormatter(data) {
  let formattedData = [];

  if (data) {
    if (data.data) {
      data.data.map((itm, idx) => {
        let formattedRecord = {};

        formattedRecord["id"] = idx;

        formattedData.push(formattedRecord);
      });
    }
  }
  return formattedData;
}
