import React, { Component } from "react";

import Fun from "../../../lib/functions";
import { ReactReader } from "react-reader";
import UniFun from "../../../modules/uni/functions";
//import PDFViewer from "mgr-pdf-viewer-react";
import { Document, Page, pdfjs } from "react-pdf";
//import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

class PDFViewerTemplate extends Component {
  state = {
    data: [],
    loaded: false,
    output: null,
    location: null,
    pageNumber: 1,
    zoom: 1.5,
    width: 600,
    pages: "...",
  };

  constructor(props) {
    super(props);
    let builder = this.props.builder.state;
    let templateConfig = builder.module.templateConfig;
    if (templateConfig.events) {
      Object.keys(templateConfig.events).map((itm, idx) => {
        this[itm] = templateConfig.events[itm].bind(this);
      });
    }
    this.changePage = this.changePage.bind(this);
    this.onDocumentLoadSuccess = this.onDocumentLoadSuccess.bind(this);
    this.goToPage = this.goToPage.bind(this);
  }

  componentDidMount() {
    if (!localStorage.getItem("isLoggedIn")) {
      UniFun.logout(window.location.href);
    } else {
      this.setState({ data: this.props.data, loaded: true });
    }

    if (this.componentDidMountEvent) {
      setTimeout(this.componentDidMountEvent, 0);
    }
  }

  onDocumentLoadSuccess({ numPages }) {
    this.setState({ pages: numPages });
  }

  changePage(page) {
    this.setState({ pageNumber: page });
  }

  goToPage(e) {
    if (Number.isInteger(parseFloat(e.target.value))) {
      if (
        parseFloat(e.target.value) > 0 &&
        parseFloat(e.target.value) <= this.state.pages
      ) {
        this.setState({ pageNumber: parseFloat(e.target.value) });
      }
    }
  }

  zoom(zoom) {
    this.setState({ zoom: zoom });
  }

  render() {
    let pagination = [];

    if (this.state.loaded) {
      return (
        <div style={{ borderTop: "1px solid #d8dbe0" }}>
          <div
            // style={{
            //   height: "100vh",
            // }}
            className="myReader"
          >
            {/* <PDFViewer
              document={{
                url: window["baseurl"](
                  "/agenda-file/data/" +
                    Fun.getURLPathValue(3) +
                    "?type=preview&jwt=" +
                    localStorage.getItem("jwt")
                ),
              }}
            /> */}

            <div className="pdf-toolbar mb-3 p-2 unselectable">
              <span className="mr-3 ml-3">
                Current page {this.state.pageNumber} of {this.state.pages}
              </span>
              {this.state.pages > 1 ? (
                <span className="mr-3 ml-3">
                  Go to page
                  <input
                    type="text"
                    className="ml-2"
                    value={this.state.pageNumber}
                    style={{ width: "45px", textAlign: "center" }}
                    onChange={this.goToPage}
                  />
                </span>
              ) : (
                ""
              )}
              {this.state.pageNumber > 1 ? (
                <span
                  className="pointer mr-3"
                  onClick={(e) => this.changePage(this.state.pageNumber - 1)}
                >
                  <i className="fas fa-chevron-left mr-2"></i> Previous
                </span>
              ) : (
                <span className="pointer text-secondary  mr-3">
                  <i className="fas fa-chevron-left mr-2"></i> Previous
                </span>
              )}

              {this.state.pageNumber < this.state.pages ? (
                <span
                  className="pointer"
                  onClick={(e) => this.changePage(this.state.pageNumber + 1)}
                >
                  Next<i className="fas fa-chevron-right ml-2"></i>
                </span>
              ) : (
                <span className="pointer text-secondary">
                  Next<i className="fas fa-chevron-right ml-2"></i>
                </span>
              )}
              {this.state.zoom < 2.5 ? (
                <span
                  className="pointer ml-4"
                  onClick={(e) => this.zoom(this.state.zoom + 0.5)}
                >
                  <i className="fas fa-search-plus mr-2"></i>Zoom in
                </span>
              ) : (
                <span className="pointer ml-4 text-secondary">
                  <i className="fas fa-search-plus mr-2"></i>Zoom in
                </span>
              )}
              {this.state.zoom > 1 ? (
                <span
                  className="pointer ml-2"
                  onClick={(e) => this.zoom(this.state.zoom - 0.5)}
                >
                  <i className="fas fa-search-minus mr-2"></i>Zoom out
                </span>
              ) : (
                <span className="pointer ml-2 text-secondary">
                  <i className="fas fa-search-minus mr-2"></i>Zoom out
                </span>
              )}
            </div>

            <div className="mt-5 pt-4 ">
              <Document
                file={window["baseurl"](
                  "/agenda-file/data/" +
                    Fun.getURLPathValue(3) +
                    "?type=preview&jwt=" +
                    localStorage.getItem("jwt")
                )}
                onLoadSuccess={this.onDocumentLoadSuccess}
                className="pdf-viewer-document "
              >
                <Page
                  pageNumber={this.state.pageNumber}
                  renderTextLayer={false}
                  scale={this.state.zoom}
                />
              </Document>
            </div>
          </div>
        </div>
      );
    } else {
      return "loading...";
    }
  }
}

export default PDFViewerTemplate;
