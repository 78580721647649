import React, { Component } from "react";
import Builder from "../../lib/builder";
import Fun from "../../lib/functions";

class Module extends Component {
  state = {
    module: "",
    view: "",
    theme: "",
    loaded: false,
  };

  constructor(props) {
    super(props);
    let module = this.props.field.config.module;
    let view = this.props.field.config.view;
    let parameters = this.props.field.config.parameters;
    let moduleParams = this.props.field.config.moduleParams;

    let parentData = this.props.data;
    let theme = this.props.field.config.theme
      ? this.props.field.config.theme
      : window["c_theme"];

    this.state = {
      module: module,
      view: view,
      theme: theme,
      parameters: parameters,
      moduleParams: moduleParams,
      parentData: parentData,
      loaded: true,
    };
  }

  componentDidMount() {
    this.setState({ loaded: true });
  }

  render() {
    if (this.state.loaded) {
      return (
        <Builder
          module={this.state.module}
          view={this.state.view}
          theme={this.state.theme}
          parentData={this.state.parentData}
          parameters={this.state.parameters}
          moduleParams={this.state.moduleParams}
          key={Fun.uuid()}
        />
      );
    }
    return "";
  }
}

export default Module;
