import React, { Component } from "react";
import {
  Chart,
  ChartConfiguration,
  LineController,
  BarController,
  BarElement,
  LineElement,
  PieController,
  ArcElement,
  PointElement,
  LinearScale,
  Title,
  Legend,
  CategoryScale,
  Tooltip,
} from "chart.js";

class Charts extends Component {
  state = {
    name: "",
    id: "",
    placeholder: "",
    attributes: "",
    value: "",
    defaultValue: "",
    loaded: false,
    editable: true,
    component: null,
    attributes: "",
    events: {},
    customLayout: null,
    useCustomLayout: true,
    editable: true,
    theChart: null,
    chartConfig: {},
  };

  constructor(props) {
    super(props);

    let events = {};
    if (this.props.field.events) {
      Object.keys(this.props.field.events).map((itm, idx) => {
        this[itm] = this.props.field.events[itm];
        this[itm] = this[itm].bind(this);
        events[itm] = this[itm];
      });
    }

    this.updateChart = this.updateChart.bind(this);

    this.state = {
      events: events,
      chartRef: React.createRef(),
      chartConfig: {},
      theChart: null,
      editable:
        this.props.field.editable != null
          ? this.props.field.editable
          : this.state.editable,
      defaultValue: this.props.value ? this.props.value : "",
    };
  }

  componentDidMount() {
    this.setState({
      attributes: this.props.field.attributes,
      name: this.props.field.alias,
      theChart: {},
      editable:
        this.props.field.editable != null
          ? this.props.field.editable
          : this.state.editable,
      customLayout: this.props.field.customLayout,
      useCustomLayout:
        typeof this.props.field.useCustomLayout !== "undefined"
          ? this.props.field.useCustomLayout
          : true,
    });

    Chart.register(
      LineController,
      BarController,
      BarElement,
      PieController,
      LineElement,
      ArcElement,
      PointElement,
      LinearScale,
      Tooltip,
      CategoryScale,
      Title,
      Legend
    );

    if (this.init) {
      this.init();
    }
  }

  updateChart(chartConfig) {
    const chartRef = this.state.chartRef.current.getContext("2d");

    new Chart(chartRef, chartConfig);
  }

  render() {
    return <canvas ref={this.state.chartRef} />;
  }
}

export default Charts;
