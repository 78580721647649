import React, { Component } from "react";
import Fun from "../../lib/functions";
import Builder from "../../lib/builder";
import UniFun from "./functions";
import TopTemplateHeader from "../../themes/default/templates/topTemplateHeader";

const duplicateTemplate = function (templateId, parentBuilder) {
  let builder = parentBuilder;
  let parameters = {
    replace: {
      id: templateId,
    },
  };
  window["modal2"].setState({
    title: <h3>Duplicate Template?</h3>,
    size: "lg",
    targetComponent: this,
    callback: function () {
      window["modal2"].setState({ show: false, overflowY: "auto" });
      Fun.createNotification({
        type: "success",
        message: "Copy of template was successful!",
      });

      builder.rerunComponent();
    },
    footer: UniFun.modalCommonFooter("modal2", "Copy"),
    show: true,
    body: (
      <Builder
        module="unitesttemplates"
        view="duplicateTemplate"
        parameters={parameters}
        key={Fun.uuid()}
        ref={(refComponent) => {
          window["modal2body"] = refComponent;
        }}
      ></Builder>
    ),
  });
};

const newTemplate = function (parentBuilder) {
  let builder = parentBuilder;

  window["modal2"].setState({
    title: <h3>New test template</h3>,
    size: "xl",
    targetComponent: this,
    callback: function () {
      window["modal2"].setState({ show: false, overflowY: "auto" });
      builder.rerunComponent();
    },
    footer: UniFun.modalCommonFooter("modal2", "Create"),
    show: true,
    body: (
      <Builder
        module="unitesttemplates"
        view="add"
        key={Fun.uuid()}
        ref={(refComponent) => {
          window["modal2body"] = refComponent;
        }}
      ></Builder>
    ),
  });
};

const deleteTemplate = function (templateId, parentBuilder) {
  let builder = parentBuilder;
  let parameters = {
    replace: {
      id: templateId,
    },
  };
  window["modal2"].setState({
    title: <h3>Delete Template?</h3>,
    size: "lg",
    targetComponent: this,
    callback: function () {
      window["modal2"].setState({ show: false, overflowY: "auto" });
      Fun.createNotification({
        type: "success",
        message: "delete was successful!",
      });

      builder.rerunComponent();
    },
    footer: UniFun.modalCommonFooter("modal2", "Delete", "btn-danger"),
    show: true,
    body: (
      <Builder
        module="unitesttemplates"
        view="deleteTemplate"
        parameters={parameters}
        key={Fun.uuid()}
        ref={(refComponent) => {
          window["modal2body"] = refComponent;
        }}
      ></Builder>
    ),
  });
};

const editTestTemplate = function (id, parentBuilder) {
  let builder = parentBuilder;
  let parameters = {
    replace: {
      id: id,
    },
  };

  window["modal"].setState({
    title: <h3>Edit test template</h3>,
    size: "xl",
    targetComponent: this,
    callback: function () {
      window["modal"].setState({ show: false, overflowY: "auto" });
      Fun.createNotification({
        type: "success",
        message: "Update was successful!",
      });
      builder.rerunComponent();
    },
    footer: UniFun.modalCommonFooter("modal", "Update"),
    show: true,
    body: (
      <Builder
        module="unitesttemplates"
        view="detail"
        key={Fun.uuid()}
        parameters={parameters}
        ref={(refComponent) => {
          window["modalbody"] = refComponent;
        }}
      ></Builder>
    ),
  });
};

const UniTestTemplates = {
  auth: "",
  views: {
    list: {
      auth: "",
      apis: {
        view: {
          url: "/template/exam?start={start}&offset={offset}",
          parameters: {
            replace: {
              start: 0,
              offset: 25,
            },
            headers: {},
          },
          formatter: function (args) {
            return UniTestFormatter(args);
          },
        },
      },
      template: "grid",
      templateConfig: {
        headerTitle: "Test Templates",
        headerTitleIcon: "/uni/svg/trainings/go_back.svg",
        colsTemplateCount: 12,
        headerIconUrl: "/events/overview",
        includeProperties: ["title", "createdBy", "modified"],
        gridConfig: {
          emptyFolder: function () {
            return (
              <div className="row mt-4 " style={{ height: "500px" }}>
                <div className="col-12 text-center">
                  <h5>
                    <strong>There are not any templates created</strong>
                  </h5>

                  <p>
                    {UniFun.userIsEligible({ forWhat: "default" }) ? (
                      <a
                        href="javascript:void(0);"
                        className="text-primary"
                        onClick={(e) => newTemplate(this.props.builder)}
                      >
                        New Template
                      </a>
                    ) : (
                      ""
                    )}
                  </p>
                </div>
              </div>
            );
          },
          headers: {
            title: {
              attributes: {
                className: "w-50",
              },
            },
          },
          cellTemplates: {
            title: function (data, rec, obj) {
              let uid = Fun.uuid();

              let builder = obj.props.builder;

              return (
                <React.Fragment>
                  {data}
                  <span className="float-right mr-4 grid-controls">
                    <i
                      className="far fa-edit mr-4 pointer"
                      onClick={(e) => editTestTemplate(rec._id.value, builder)}
                    ></i>
                    <i
                      className="fas fa-ellipsis-h pointer"
                      data-toggle="collapse"
                      data-target={"#" + uid}
                    ></i>
                    <div
                      className="position-absolute bg-white ml-4 mt-2 collapse"
                      id={uid}
                    >
                      <ul className="list-group " style={{ width: "200px" }}>
                        <li
                          className="list-group-item p-2 pl-3"
                          onClick={(e) =>
                            editTestTemplate(rec._id.value, builder)
                          }
                        >
                          Edit
                        </li>
                        <li
                          className="list-group-item p-2 pl-3"
                          onClick={(e) =>
                            duplicateTemplate(rec._id.value, builder)
                          }
                        >
                          Duplicate
                        </li>
                        <li
                          className="list-group-item p-2  pl-3 text-danger"
                          onClick={(e) =>
                            deleteTemplate(rec._id.value, builder)
                          }
                        >
                          Delete
                        </li>
                      </ul>
                    </div>
                  </span>
                </React.Fragment>
              );
            },
          },
        },
        cardTemplateTop: function () {
          let data = this.state.data;
          return (
            <div className="row">
              <div className="col-6 pt-2 pl-4">
                <span className="text-dark ">
                  There are {data.length} test templates
                </span>
              </div>
              <div className="col-6 text-right p-2 pr-4">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={(e) => newTemplate(this.props.builder)}
                >
                  New Template
                </button>
              </div>
            </div>
          );
        },
        header: UniFun.commonHeader,
      },
      pk: "_id",
      properties: UniTestProperties({ editable: false }),
    },
    detail: {
      auth: "",
      apis: {
        view: {
          url: "/template/exam/{id}",
          parameters: {
            replace: {},
            headers: {},
          },
          formatter: function (args) {
            return UniTestFormatter(args);
          },
        },
        update: {
          url: "/template/exam",
          parameters: {
            headers: {
              "Content-Type": "application/json",
            },
            replace: {},
          },
          formatter: function (data) {
            return UniTestFormatter(data);
          },
        },
      },
      template: "form",
      templateConfig: {
        events: {
          onSubmit: function (e) {
            e.preventDefault();
            if (
              !Fun.validate(this.props.builder.validator, Fun.formDataToJSON(e))
            ) {
              return;
            }
            let submittedJson = Fun.getSubmitJSON(e);
            let examId = submittedJson["_id"];
            let jsonData = UniFun.examJSONData(submittedJson);

            jsonData["_id"] = examId;
            Fun.update(e, {
              url: this.props.builder.state.module.apis.update,
              method: "PATCH",
              callback: function (
                submittedData,
                status,
                responsedata,
                responsestatus
              ) {
                if (responsestatus == "200") {
                  if (window["modal"].state.callback) {
                    window["modal"].state.callback(submittedData);
                  }
                  Fun.createNotification({
                    type: "success",
                    message: "Update was successful!",
                  });
                } else {
                  if (responsedata.data.exceptions) {
                    responsedata.data.exceptions.map((itm, idx) => {
                      Fun.createNotification({
                        message: itm.errorDescription,
                        type: "danger",
                      });
                    });
                  }
                }
              },
              parameters: {
                headers: {
                  "content-type": "application/json",
                },
              },
              submittedData: JSON.stringify(jsonData),
            });
          },
        },
        colsTemplateCount: 12,
        includeProperties: [
          "_id",
          "title",
          "instructions",
          "passingGrade",
          "labelQuestions",
          "questions",
        ],
      },
      pk: "_id",
      properties: UniTestProperties({ editable: true }),
    },
    duplicateTemplate: {
      auth: "",
      apis: {
        view: {
          url: "/template/exam/{id}",
          parameters: {
            headers: {},
          },
          formatter: function (args) {
            return UniTestFormatter(args);
          },
        },
        duplicate: {
          url: "/template/exam/",
          parameters: {
            headers: {},
            replace: {},
          },
          formatter: function (data) {
            return UniTestFormatter(data);
          },
        },
      },
      template: "form",
      templateConfig: {
        useCard: false,
        colsTemplateCount: 12,
        includeProperties: ["duplicateditm"],
        excludeProperties: [],
        events: {
          onSubmit: function (e) {
            e.preventDefault();

            let data = new FormData(e.target);

            let duplicateditm = JSON.parse(data.get("duplicateditm"));

            let jsonData = duplicateditm;

            delete jsonData["_id"];
            delete jsonData["createdAt"];
            delete jsonData["updatedAt"];
            delete jsonData["deleted"];
            delete jsonData["owner"];
            jsonData["title"] = "Copy of " + jsonData["title"];
            if (jsonData) {
              jsonData.questions.map((itm, idx) => {
                delete itm["_id"];

                itm.answers.map((sitm, sidx) => {
                  delete sitm["_id"];

                  if (sitm["isCorrect"]) {
                    sitm["isCorrect"] = true;
                  } else {
                    sitm["isCorrect"] = false;
                  }
                });
              });
            }

            Fun.update(e, {
              url: this.props.builder.state.module.apis.duplicate,
              method: "POST",
              callback: function (
                submittedData,
                status,
                responsedata,
                responsestatus
              ) {
                if (responsestatus == "200") {
                  if (window["modal2"].state.callback) {
                    window["modal2"].state.callback(submittedData);
                  }
                } else {
                  if (responsedata.data.exceptions) {
                    responsedata.data.exceptions.map((itm, idx) => {
                      Fun.createNotification({
                        message: itm.errorDescription,
                        type: "danger",
                      });
                    });
                  }
                }
              },
              parameters: {
                headers: {
                  "content-type": "application/json",
                },
              },
              submittedData: JSON.stringify(jsonData),
            });
          },
        },
        templateTop: function () {
          let data = this.state.data[0];
          return (
            <div className=" p-4">
              <p>
                Are you sure you want to create a copy of the test template{" "}
                <strong>"{data.title.component}"</strong>?<br></br>
              </p>
            </div>
          );
        },
      },
      pk: "_id",
      properties: UniTestProperties({ editable: false }),
    },
    selectNewOrFromTemplate: {
      auth: "",
      apis: {
        view: {
          data: {
            data: [
              {
                _id: "modalSelectTemplate",
                title: "Choose from existing templates",
              },
              {
                _id: "modalNewTemplate",
                title: "Schedule new exam",
              },
            ],
          },
          parameters: {
            headers: {},
            replace: {},
          },
          formatter: function (data) {
            return UniTestFormatter(data);
          },
        },
      },
      template: "selectboxes",
      templateConfig: {
        events: {
          onSubmit: function (e) {
            e.preventDefault();
            let jsonData = Fun.getSubmitJSON(e);
            let builder = this.props.builder;
            if (jsonData["selectTemplate"] == "modalSelectTemplate") {
              window["modal2"].setState({
                title: <h3>Schedule test</h3>,
                size: "lg",
                targetComponent: null,
                show: true,
                callback: function (args) {
                  window["modal2"].setState({ show: false, overflowY: "auto" });
                  this.builder.rerunComponent();
                },
                builder: window["modal2"].state.builder,
                footer: UniFun.modalCommonFooter(
                  "modal2",
                  "Save",
                  "btn btn-primary save-test-template d-none"
                ),
                body: (
                  <Builder
                    module="unitesttemplates"
                    view="selectTest"
                    key={Fun.uuid()}
                    ref={(refComponent) => {
                      window["modal2body"] = refComponent;
                    }}
                  ></Builder>
                ),
              });
            } else if (jsonData["selectTemplate"] == "modalNewTemplate") {
              window["modal2"].setState({ show: false, overflowY: "auto" });

              window["modal"].setState({
                title: <h3>Schedule test</h3>,
                size: "xl",
                targetComponent: this,
                footer: UniFun.modalCommonFooter("modal", "Schedule"),
                builder: window["modal2"].state.builder,
                show: true,
                callback: function (args) {
                  window["modal"].setState({ show: false, overflowY: "auto" });
                  this.builder.rerunComponent();
                },
                body: (
                  <Builder
                    module="unitestevent"
                    view="detail"
                    inputData={{ data: [{}] }}
                    key={Fun.uuid()}
                    ref={(refComponent) => {
                      window["modalbody"] = refComponent;
                    }}
                  ></Builder>
                ),
              });
            }
          },
        },
        colsTemplateCount: 12,
        templateTop: function () {
          if (this.state.data.length > 0) {
            if (document.getElementsByClassName("save-test-template")) {
              if (document.getElementsByClassName("save-test-template")[0]) {
                document
                  .getElementsByClassName("save-test-template")[0]
                  .classList.remove("d-none");
              }
            }
          }

          return (
            <React.Fragment>
              <p className="pl-2 mt-4">
                <h5>I want to: </h5>
                {this.state.data.length == 0 ? (
                  <span className="text-dark pt-2">No template created</span>
                ) : (
                  ""
                )}
              </p>
            </React.Fragment>
          );
        },
        includeProperties: ["selectTemplate", "title"],
      },
      pk: "_id",
      properties: UniTestProperties({ editable: false }),
    },
    selectTest: {
      auth: "",
      apis: {
        view: {
          url: "/template/exam?start={start}&offset={offset}",
          parameters: {
            headers: {},
            replace: {
              start: 0,
              offset: 100,
            },
          },
          formatter: function (data) {
            return UniTestFormatter(data);
          },
        },
      },
      template: "selectboxes",
      templateConfig: {
        events: {
          onSubmit: function (e) {
            e.preventDefault();
            let jsonData = Fun.getSubmitJSON(e);

            let params = {
              replace: {
                id: jsonData.selectTemplate,
              },
            };

            window["modal2"].setState({ show: false, overflowY: "auto" });

            window["modal"].setState({
              title: <h3>Schedule test</h3>,
              size: "xl",
              targetComponent: this,
              footer: UniFun.modalCommonFooter("modal", "Schedule"),
              builder: window["modal2"].state.builder,
              callback: function () {
                window["modal"].setState({ show: false, overflowY: "auto" });
                this.builder.rerunComponent();
              },
              show: true,
              body: (
                <Builder
                  module="unitestevent"
                  view="detail"
                  parameters={params}
                  inputData={false}
                  key={Fun.uuid()}
                  ref={(refComponent) => {
                    window["modalbody"] = refComponent;
                  }}
                ></Builder>
              ),
            });
          },
        },
        colsTemplateCount: 12,
        templateTop: function () {
          if (this.state.data.length > 0) {
            if (document.getElementsByClassName("save-test-template")) {
              if (document.getElementsByClassName("save-test-template")[0]) {
                document
                  .getElementsByClassName("save-test-template")[0]
                  .classList.remove("d-none");
              }
            }
          }

          return (
            <React.Fragment>
              <p className="pl-2 mt-4">
                <h5>Choose test template: </h5>
                {this.state.data.length == 0 ? (
                  <span className="text-dark pt-2">No template created</span>
                ) : (
                  ""
                )}
              </p>
            </React.Fragment>
          );
        },
        includeProperties: ["title", "selectTemplate"],
      },
      pk: "_id",
      properties: UniTestProperties({ editable: false }),
    },
    add: {
      auth: "",
      apis: {
        view: {
          data: { data: [{}] },
          parameters: {
            replace: {},
            headers: {},
          },
          formatter: function (args) {
            return UniTestFormatter(args);
          },
        },
        save: {
          url: "/template/exam",
          parameters: {
            headers: {
              "Content-Type": "application/json",
            },
            replace: {},
          },
          formatter: function (data) {
            return UniTestFormatter(data);
          },
        },
      },
      template: "form",
      templateConfig: {
        events: {
          onSubmit: function (e) {
            e.preventDefault();
            let jsonData = UniFun.examJSONData(Fun.getSubmitJSON(e));

            Fun.update(e, {
              url: this.props.builder.state.module.apis.save,
              method: "POST",
              callback: function (
                submittedData,
                status,
                responsedata,
                responsestatus
              ) {
                if (responsestatus == "200") {
                  if (window["modal2"].state.callback) {
                    window["modal2"].state.callback(submittedData);
                  } else {
                    if (window["modal2"].state.targetComponent) {
                      window["modal2"].state.targetComponent.setState({
                        data: submittedData,
                      });
                    }
                  }
                  Fun.createNotification({
                    message: "Template has been created",
                    type: "success",
                  });
                } else {
                  if (responsedata.data.exceptions) {
                    responsedata.data.exceptions.map((itm, idx) => {
                      Fun.createNotification({
                        message: itm.errorDescription,
                        type: "danger",
                      });
                    });
                  }
                }
              },
              parameters: {
                headers: {
                  "content-type": "application/json",
                },
              },
              submittedData: JSON.stringify(jsonData),
            });
          },
        },
        colsTemplateCount: 12,
        includeProperties: [
          "_id",
          "title",
          "instructions",
          "passingGrade",
          "labelQuestions",
          "questions",
        ],
      },
      pk: "_id",
      properties: UniTestProperties({ editable: true }),
    },
    deleteTemplate: {
      auth: "",
      apis: {
        view: {
          url: "/template/exam/{id}",
          parameters: {
            headers: {},
          },
          formatter: function (args) {
            return UniTestFormatter(args);
          },
        },
        delete: {
          url: "/template/exam/{id}",
          parameters: {
            headers: {},
            replace: {},
          },
          formatter: function (data) {
            return UniTestFormatter(data);
          },
        },
      },
      template: "form",
      templateConfig: {
        useCard: false,
        colsTemplateCount: 12,
        includeProperties: ["_id"],
        excludeProperties: [],
        events: {
          onSubmit: function (e) {
            let submittedData = Fun.getSubmitJSON(e);

            let builder = this.props.builder;

            Fun.update(e, {
              url: builder.state.module.apis.delete,
              method: "DELETE",
              parameters: {
                headers: {
                  "content-type": "application/json",
                },
                replace: {
                  id: submittedData["_id"],
                },
              },
              callback: function (args) {
                if (window["modal2"].state.callback) {
                  window["modal2"].state.callback(submittedData);
                }
              },
              submittedData: JSON.stringify([]),
            });
          },
        },
        templateTop: function () {
          let data = this.state.data[0];
          return (
            <div className=" p-4">
              <p>
                Are you sure you want to delete test template{" "}
                <strong>"{data.title.component}"</strong>?<br></br>
              </p>
            </div>
          );
        },
      },
      pk: "_id",
      properties: UniTestProperties({ editable: false }),
    },
  },
};

function UniTestFormatter(data) {
  let formattedData = [];
  if (data) {
    if (data.data) {
      data.data.map((itm, idx) => {
        let formattedRecord = {};

        formattedRecord["_id"] = itm._id;
        formattedRecord["duplicateditm"] = JSON.stringify(itm);
        formattedRecord["selectTemplate"] = itm._id;
        formattedRecord["title"] = itm.title;
        formattedRecord["instructions"] = itm.instructions;
        formattedRecord["passingGrade"] = itm.passingGrade;
        formattedRecord["questions"] = itm.questions;
        formattedRecord["createdBy"] = itm.owner ? itm.owner.fullName : "---";
        formattedRecord["modified"] = itm.updatedAt;

        formattedData.push(formattedRecord);
      });
    }
  }
  return formattedData;
}

function UniTestProperties(input) {
  let editable = input.editable;
  return {
    selectTemplate: {
      alias: "selectTemplate",
      control: "checkbox",
      label: "",
      editable: true,
      attributes: {
        className: "form-check-input  select-template-checkbox",
      },
      events: {
        onChange: function () {
          let value = this.props.value;

          Object.keys(this.props.builder).map((itm, idx) => {
            if (
              itm.startsWith("selectTemplate") &&
              itm != "selectTemplate-" + value
            ) {
              this.props.builder[itm].setState({
                checked: false,
              });
            }
          });

          this.props.builder.builderTemplate.current.setState({
            action: this.props.data["_id"],
          });
        },
      },
    },
    _id: {
      alias: "_id",
      control: "hidden",
      editable: editable,
      attributes: {},
    },
    title: {
      alias: "title",
      control: "text",
      label: "Name",
      placeholder: "Type test title",
      editable: editable,
      attributes: {
        className: "form-control",
      },
    },
    duplicateditm: {
      alias: "duplicateditm",
      control: "hidden",
      label: "",
      editable: editable,
      attributes: {
        className: "form-control",
      },
    },
    instructions: {
      alias: "instructions",
      control: "editor",
      label: "Instructions",
      placeholder: "Write test instructions...",
      editable: editable,
      attributes: {
        className: "form-control",
      },
    },
    passingGrade: {
      alias: "passingGrade",
      control: "text",
      label: "Passing Grade",
      placeholder: "0",
      attributes: {
        className: "form-control  text-center",
        style: { width: "80px", flex: "initial" },
      },
      customLayout: function (args) {
        let examType = "Practice Test";

        if (this.state.defaultValue) {
          if (parseFloat(this.state.defaultValue) > 0) {
            examType = "Graded Test";
          }
        }

        return (
          <div style={{ width: "400px" }}>
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <label className="input-group-text">Passing Grade (%)</label>
              </div>
              {args.control}
              <div class="input-group-append">
                <span class="input-group-text bg-white border-0  text-dark">
                  {examType}
                </span>
              </div>
            </div>
          </div>
        );
      },
      useCustomLayout: true,
      events: {
        onChange: function (event) {
          this.setState({ defaultValue: event.target.value });
        },
      },
    },
    labelQuestions: {
      alias: "labelQuestions",
      control: "label",
      label: (
        <h4 className="text-black">
          <strong>Questions</strong>
        </h4>
      ),
      // tooltip: "- You can add as many multiple choice questions as you need.</br>-In each question you can choose one or more correct answers.</br>-If you want you can add points to each question",
    },
    questions: {
      alias: "questions",
      control: "multiple",
      label: "Questions",
      customLayout: function (args) {
        let controls = args.control;

        let output = [];

        controls.map((itm, idx) => {
          output.push(
            <div className="pt-2">
              <strong>Question {idx + 1}: </strong>
              <span>
                <button
                  className=" pl-2 btn btn-link "
                  type="button"
                  onClick={(e) =>
                    UniFun.deleteTestQuestion({
                      title: "Question " + (idx + 1),
                      idx: idx,
                      object: this,
                    })
                  }
                >
                  <span className="text-danger">
                    <i className="fas fa-times"></i> Delete
                  </span>
                </button>
              </span>
              <div className="row pt-3">
                <div
                  className="col-1"
                  style={{ maxWidth: "32px", marginRight: "20px" }}
                >
                  <img
                    src="/uni/svg/test_fill.svg"
                    style={{ width: "33px" }}
                    className="filter-light-green"
                  ></img>
                </div>
                <div className="col-9  pr-0">
                  {itm[0].props.control.component}
                  {itm[1].props.control.component}
                  {itm[2].props.control.component}
                </div>
                <div className="col-2">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">Points</span>
                    </div>
                    {itm[3].props.control.component}
                  </div>
                </div>
              </div>
              {itm[1].props.control.component.props.value == "essayType" ? (
                <React.Fragment>
                  <div className="row pt-3">
                    <div
                      className="col-1"
                      style={{ maxWidth: "32px", marginLeft: "20px" }}
                    ></div>
                    <div className="col-9">
                      {itm[5].props.control.component.props.value &&
                      itm[5].props.control.component.props.value != "null" ? (
                        <img
                          src={
                            window["baseurl"](
                              "/agenda-file/data/" +
                                itm[5].props.control.component.props.value
                            ) +
                            "?type=preview&jwt=" +
                            localStorage.getItem("jwt")
                          }
                          className="mb-2 mt-2"
                          style={{ height: "120px" }}
                        ></img>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="col-2"></div>
                    <div
                      className="col-1"
                      style={{ maxWidth: "32px", marginLeft: "20px" }}
                    ></div>
                    <div className="col-9">
                      <div className="form-group">
                        <span>
                          <label className="text-dark">
                            {itm[5].props.control.component.props.field.label}
                          </label>
                        </span>
                        {itm[5].props.control.component}
                      </div>
                    </div>
                    <div className="col-2"></div>
                  </div>
                </React.Fragment>
              ) : (
                itm[4].props.control.component
              )}
            </div>
          );
        });

        let _this = this;

        return (
          <React.Fragment>
            {output}
            {/* <button
              type="button"
              className="btn btn-link"
              onClick={this.addNew}
            >
              <span>
                <img
                  src="/uni/svg/add.svg"
                  className="filter-link mr-2"
                  style={{ width: "11px", paddingBottom: "5px" }}
                ></img>{" "}
                Add new question
              </span>
            </button> */}

            <div className="dropdown">
              <button
                className="btn btn-link dropdown-toggle"
                type="button"
                data-toggle="dropdown"
              >
                + Add new question
              </button>
              <div className="dropdown-menu">
                <button
                  type="button"
                  className="dropdown-item"
                  onClick={(e) => {
                    _this.setState({
                      newDataObject: { type: "multipleChoice" },
                    });
                    setTimeout(_this.addNew, 50);
                  }}
                >
                  Add multiple choice
                </button>
                <button
                  type="button"
                  className="dropdown-item"
                  href="#"
                  onClick={(e) => {
                    _this.setState({ newDataObject: { type: "essayType" } });
                    setTimeout(_this.addNew, 50);
                  }}
                >
                  Add essay type
                </button>
              </div>
            </div>
          </React.Fragment>
        );
      },
      useCustomLayout: true,
      config: {
        addNewLabel: (
          <span>
            <img
              src="/uni/svg/add.svg"
              className="filter-link mr-2"
              style={{ width: "11px", paddingBottom: "5px" }}
            ></img>{" "}
            Add mulitple choice
          </span>
        ),
        properties: {
          _id: {
            alias: "_id",
            control: "hidden",
            label: "",
            editable: editable,
          },
          type: {
            alias: "type",
            control: "hidden",
            label: "",
            defaultValue: "multipleChoice",
            editable: editable,
            attributes: {
              className: "form-control",
            },
          },
          question: {
            alias: "question",
            control: "editor",
            label: "Question",
            placeholder: "Type a question",
            editable: editable,
            placeholder: "Type question title",
            validations: "required",
            attributes: {
              className: "form-control",
            },
          },
          weight: {
            alias: "weight",
            control: "text",
            label: "Points",
            placeholder: "",
            editable: editable,
            validations: "required",
            attributes: {
              className: "form-control",
            },
          },
          answers: {
            alias: "answers",
            control: "multiple",
            editable: editable,
            customLayout: function (args) {
              let output = [];

              if (args.control.length <= 1) {
                this.addNew();
                this.addNew();
              }

              args.control.map((itm, idx) => {
                let addNewBtn = "";

                if (idx == args.control.length - 1) {
                  addNewBtn = (
                    <button
                      type="button"
                      className="btn btn-link text-dark"
                      onClick={this.addNew}
                    >
                      <i className="fas fa-plus"></i>
                    </button>
                  );
                }

                output.push(
                  <div className="row pt-3">
                    <div
                      className="col-1"
                      style={{ maxWidth: "32px", marginLeft: "20px" }}
                    >
                      {itm[0].props.control.component}
                      {itm[1].props.control.component}
                    </div>
                    <div className="col-9 pr-0">
                      {itm[2].props.control.component}
                    </div>
                    <div className="col-2">
                      <button
                        type="button"
                        className="btn btn-link text-secondary"
                        onClick={(e) => this.deleteControl(idx)}
                      >
                        <i className="fas fa-times"></i>
                      </button>
                      {addNewBtn}
                    </div>
                  </div>
                );
              });

              return (
                <React.Fragment>
                  {output}
                  <div className="pt-5"></div>
                </React.Fragment>
              );
            },
            useCustomLayout: true,
            config: {
              addNewLabel: "+ Add answer",
              properties: {
                _id: {
                  alias: "_id",
                  control: "hidden",
                  label: "",
                  editable: editable,
                },
                isCorrect: {
                  alias: "isCorrect",
                  control: "checkbox",
                  editable: editable,
                  config: {
                    useTrueFalseValue: true,
                  },
                  attributes: {
                    className: "form-check-input form-control mt-0",
                  },
                },
                answer: {
                  alias: "answer",
                  control: "text",
                  validations: "required",
                  placeholder:
                    "Type answer and check the box if it's the correct one",
                  editable: editable,
                  attributes: {
                    className: "form-control",
                  },
                },
              },
            },
          },
          image: {
            alias: "image",
            control: "dropzone",
            label: "Do you want to include an image with your question?",
            editable: editable,
            customContentLayout: function () {
              let files = [];

              try {
                files = JSON.parse(this.state.defaultValue);
              } catch (e) {}

              if (!files) {
                files = [];
              }

              let content = [];

              Object.keys(files).map((itm, idx) => {
                content.push(
                  UniFun.trainingNodeFileUI(files[itm], this.removeFile, itm)
                );
              });

              this.state.content?.map((itm, idx) => {
                if (typeof files[itm.key] === "undefined") {
                  content.push(itm);
                }
                // if (this.state.pending.indexOf(itm.key) >= 0) {
                //   content.push(itm);
                // }
              });
              return (
                <React.Fragment>
                  {/* {this.state.placeholder()} */}
                  {content.length > 0 ? "" : this.state.placeholder()}
                  <div className="p-3 pb-1">{content}</div>
                  {/* {content.length > 0 ? "" : this.state.placeholder()} */}
                </React.Fragment>
              );
            },

            useCustomLayout: true,
            config: {
              formatDefaultValue: function (value) {
                if (value) {
                  return {
                    [value]: {
                      _id: value,
                      mimetype: "image/jpeg",
                      title: "Question Image",
                    },
                  };
                }
                return value;
              },
              placeholder: function () {
                return (
                  <div className="p-3 pb-1">
                    <strong>
                      Drag & drop here or{" "}
                      <a
                        href="javascript:void(0);"
                        className="text-primary"
                        onClick={this.onTargetClick}
                      >
                        <strong>browse</strong>
                      </a>{" "}
                      file to attach
                    </strong>
                    <br></br>
                    <span className="text-dark">
                      Maximum size: 50 MB - File type supported: png,jpeg,jpg
                    </span>
                  </div>
                );
              },
            },
            events: {
              removeFile: function (id) {
                let defaultValue = {};

                Object.keys(JSON.parse(this.state.defaultValue)).map(
                  (itm, idx) => {
                    if (itm != id) {
                      defaultValue[itm] = JSON.parse(this.state.defaultValue)[
                        itm
                      ];
                    }
                  }
                );

                let content = [];
                this.state.content?.map((itm, idx) => {
                  if (itm.key != id) {
                    content.push(itm);
                  }
                });

                this.setState({
                  defaultValue: JSON.stringify(defaultValue),
                  content: content,
                });

                return;
              },
              onDrop: function (files) {
                this.setState({
                  defaultValue: JSON.stringify({}),
                  content: [],
                });

                if (files.length > 1) {
                  Fun.createNotification({
                    message:
                      "Only one file is allowed to upload. Only png,jpeg,jpg files are allowed to upload",
                    type: "danger",
                  });
                  return;
                }

                if (
                  files[0].type == "image/jpeg" ||
                  files[0].type == "image/jpg" ||
                  files[0].type == "image/png"
                ) {
                  UniFun.fileProgress({
                    control: this,
                    files: files,
                    url: "/agenda-file",
                    type: "meeting",
                  });
                } else {
                  Fun.createNotification({
                    message: "Only png,jpeg,jpg files are allowed to upload",
                    type: "danger",
                  });
                }
              },
            },
            attributes: {
              className: "border rounded  dropzone-trainings",
            },
          },
        },
      },
    },
    createdBy: {
      alias: "createdBy",
      control: "text",
      label: "Created by",
      editable: editable,
      attributes: {
        className: "form-control",
      },
    },
    modified: {
      alias: "modified",
      control: "datetime",
      label: "Last modified",
      editable: editable,
      attributes: {
        className: "form-control",
      },
    },
    newTemplate: {
      alias: "newTemplate",
      control: "button",
      caption: "New Template",
      events: {
        onClick: function () {},
      },
      attributes: {
        className: "btn btn-primary",
      },
    },
  };
}

export default UniTestTemplates;
