import React, { Component } from "react";
import Fun from "../../../lib/functions";
import Builder from "../../../lib/builder";
import UniFun from "../functions";
import { Link } from "react-router-dom";
import moment from "moment";
import TopTemplateHeader from "../../../themes/default/templates/topTemplateHeader";

const selectAdd = function (parentBuilder) {
  let builder = parentBuilder;
  window["modal"].setState({
    title: (
      <React.Fragment>
        <h3 className="pl-3">Schedule conference</h3>
      </React.Fragment>
    ),
    size: "xl",
    targetComponent: this,
    parentBuilder: builder,
    callback: function () {
      window["modal"].setState({ show: false, overflowY: "auto" });
      builder.rerunComponent();
    },
    footer: [
      <button
        type="button"
        className="btn btn-link border"
        onClick={() => {
          window["modal"].setState({ show: false, overflowY: "auto" });
        }}
      >
        Cancel
      </button>,
      <button
        type="button"
        className="btn btn-primary border"
        onClick={() => {
          Fun.submitFromModal("modalbody");
        }}
      >
        Save
      </button>,
    ],
    show: true,
    body: (
      <Builder
        module="uniconferences"
        view="privateAdd"
        //parameters={parameters}
        key={Fun.uuid()}
        ref={(refComponent) => {
          window["modalbody"] = refComponent;
        }}
      ></Builder>
    ),
  });
};

const openPrivacyTerms = function (title, parentBuilder) {
  let builder = parentBuilder;
  window["modal"].setState({
    title: (
      <React.Fragment>
        <h3 className="pl-3">Schedule conference</h3>
      </React.Fragment>
    ),
    size: "xl",
    targetComponent: this,
    parentBuilder: builder,
    callback: function () {},
    footer: [
      <button
        type="button"
        className="btn btn-link border"
        onClick={() => {
          window["modal"].setState({ show: false, overflowY: "auto" });
        }}
      >
        Close
      </button>,
    ],
    show: true,
    body: (
      <Builder
        module="uniconferences"
        view="privacynterms"
        //parameters={parameters}
        key={Fun.uuid()}
        ref={(refComponent) => {
          window["modalbody"] = refComponent;
        }}
      ></Builder>
    ),
  });
};

function readMore(description) {
  window["modal"].setState({
    title: "About",
    size: "xl",
    show: true,
    footer: [
      <button
        className="btn btn-link border"
        type="button"
        onClick={(e) => {
          window["modal"].setState({ show: false, overflowY: "auto" });
        }}
      >
        Close
      </button>,
    ],
    body: <div className="container p-3">{description}</div>,
  });
}

function speakerInfoModal(speaker) {
  let parameters = {
    replace: {
      id: speaker._id,
    },
  };

  let builder = this.props.builder;

  let data = this.state.rawData.data[0];

  let speakerSessions = [];

  data.days?.forEach((itm, idx) => {
    itm.sessions.forEach((sitm, sidx) => {
      let isSpeaker = false;

      sitm.speakers.forEach((spitm, spidx) => {
        if (spitm._id == speaker._id) {
          isSpeaker = true;
        }
      });

      if (isSpeaker) {
        speakerSessions.push(
          <Link to={"/conference/" + data._id + "/session/" + sitm._id}>
            <div className="border-top mt-3 pt-4 pb-3 ">
              <span className="text-dark-blue text-strong d-block  ">
                {sitm.title}
              </span>
              <span className="d-block mt-2 text-dark ">
                {moment(itm.date).format("ddd DD MMM YYYY")}
                {" | "}
                {moment(sitm.fromRaw).format("HH:mm")}
                {" - "}
                {moment(sitm.toRaw).format("HH:mm")}
              </span>
            </div>
          </Link>
        );
      }
    });
  });

  let profileImage = "";

  profileImage = speaker.profileImage
    ? window["baseurl"]("/agenda-file/data/" + speaker.profileImage) +
      "?type=preview&jwt=" +
      localStorage.getItem("jwt")
    : "";

  window["modal"].setState({
    title: "",
    size: "xl",
    parentBuilder: builder,
    show: true,
    footer: [
      <button
        className="btn btn-link border"
        type="button"
        onClick={(e) => {
          window["modal"].setState({ show: false, overflowY: "auto" });
        }}
      >
        Close
      </button>,
    ],
    body: (
      <div className="container p-5">
        <div className="row">
          <div className="col-md-4">
            <h3 className="text-dark-blue text-strong mb-3">
              {speaker.firstName} {speaker.lastName}
            </h3>
            <span className="d-block">{speaker.jobTitle}</span>
            {speakerSessions}
          </div>
          <div className="col-md-8">
            {profileImage ? (
              <img
                src={profileImage}
                className="mr-3 rounded"
                style={{ width: "170px", float: "left" }}
              ></img>
            ) : (
              ""
            )}
            {speaker.biography ? Fun.parse(speaker.biography) : ""}
          </div>
        </div>
      </div>
    ),
  });
}

function sponsorsInfoModal(sponsor) {
  let sponsorImage = "";

  sponsorImage = sponsor.logo
    ? window["baseurl"]("/agenda-file/data/" + sponsor.logo._id) +
      "?type=preview&jwt=" +
      localStorage.getItem("jwt")
    : "";

  window["modal"].setState({
    title: "",
    size: "xl",
    show: true,
    footer: [
      <button
        className="btn btn-link border"
        type="button"
        onClick={(e) => {
          window["modal"].setState({ show: false, overflowY: "auto" });
        }}
      >
        Close
      </button>,
    ],
    body: (
      <div className="container p-5">
        <div className="row">
          <div className="col-md-3">
            {sponsorImage ? (
              <img
                src={sponsorImage}
                className="mr-3 rounded"
                style={{ width: "170px", float: "left" }}
              ></img>
            ) : (
              ""
            )}
          </div>
          <div className="col-md-9">
            <h3 className="text-dark-blue text-strong mb-3">{sponsor.title}</h3>
            {Fun.parse(sponsor.description)}
            {sponsor.link ? (
              <span className="d-block">
                <a
                  href={sponsor.link}
                  className="text-primary text-strong"
                  target="new"
                >
                  {sponsor.link}
                </a>
              </span>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    ),
  });
}
function Mapper(data) {
  let formattedData = [];

  if (data) {
    if (data.data) {
      data.data.forEach((itm, idx) => {
        let formattedRecord = {};
        itm.sponsorsRaw = itm.sponsors;
        try {
          if (itm.days) {
            itm.days?.forEach((ditm, didx) => {
              ditm.sessions?.forEach((sitm, sidx) => {
                if (sitm.speakers) {
                  sitm.speakersRaw = sitm.speakers;
                  sitm.speakers = UniFun.participantsFormatter(sitm.speakers);
                }
                if (sitm.from) {
                  sitm.fromRaw = sitm.from;
                  sitm.from = moment(sitm.from).format("HH:mm:ss");
                }
                if (sitm.to) {
                  sitm.toRaw = sitm.to;
                  sitm.to = moment(sitm.to).format("HH:mm:ss");
                }
              });
            });
          }
        } catch (e) {
          console.log(e);
        }

        try {
          formattedRecord["_id"] = itm._id;
          formattedRecord["participants"] = itm.participants
            ? UniFun.participantsFormatter(itm.participants)
            : [];
          formattedRecord["hosts"] = itm.hosts
            ? UniFun.participantsFormatter(itm.hosts)
            : [];

          let sponsors = [];

          if (Array.isArray(itm.sponsors)) {
            itm.sponsors?.forEach((itm, idx) => {
              sponsors.push({ _id: itm._id, name: itm.title });
            });
          }

          formattedRecord["sponsors"] = sponsors;

          formattedRecord["title"] = itm.title;
          formattedRecord["description"] = itm.description;
          formattedRecord["eventType"] = itm.eventType;
          formattedRecord["isPublic"] = itm.isPublic;

          formattedRecord["banner"] = [itm.banner];

          formattedRecord["event"] = {
            from: itm.startDate,
            to: itm.endDate,
            moreLink: "/conference/" + itm._id,
            eventType: "conference",
          };

          formattedRecord["days"] = itm.days ? itm.days : [];
          formattedRecord["startsOn"] = itm.startDate;
          formattedRecord["createdBy"] = itm.createdBy;
          formattedRecord["createdAt"] = itm.createdAt;
          formattedRecord["updatedAt"] = itm.updatedAt;
        } catch (e) {
          console.log(e);
        }
        formattedData.push(formattedRecord);
      });
    }
  }

  return formattedData;
}

function Properties(args) {
  let editable = args.editable;

  return {
    _id: {
      alias: "_id",
      control: "hidden",
      editable: editable,
      label: "",
      attributes: {
        className: "form-control",
      },
    },
    title: {
      alias: "title",
      control: "text",
      editable: editable,
      placeholder: "Type conference title",
      label: "Title",
      attributes: {
        className: "form-control",
      },
    },
    event: {
      alias: "event",
      control: "hidden",
      editable: editable,
      label: "",
      attributes: {
        className: "form-control",
      },
    },
    hosts: {
      alias: "hosts",
      label: "Add organizers", //"Select additional hosts (you are already a host)",
      placeholder: "Type email or name...",
      control: "tags",
      editable: editable,
      config: {
        idAttribute: "_id",
        nameAttribute: "name",
        apis: {
          search: {
            url: "/user/autocomplete/{query}?type=hosts",
            formatter: function (args) {
              let formattedData = [];
              if (args) {
                let data = args.data;
                if (data) {
                  data.map((itm, idx) => {
                    let fullName =
                      itm["firstName"] +
                      " " +
                      itm["lastName"] +
                      " - " +
                      itm["email"];
                    if (itm.type == "group") {
                      fullName = itm["firstName"] + " - " + itm["lastName"];
                    }
                    formattedData.push({
                      _id: itm["_id"],
                      name: fullName,
                      group: itm.type,
                    });
                  });
                }
              }
              return formattedData;
            },
            parameters: {
              replace: {
                query: "",
              },
            },
          },
        },
      },
      attributes: {
        className: "form-control",
      },
    },
    isPublic: {
      alias: "isPublic",
      control: "select",
      editable: editable,
      config: {
        options: [
          { label: "Private", value: "Private" },
          { label: "Public", value: "Public" },
        ],
      },
      label: "Event Type",
      attributes: {
        className: "form-control",
      },
    },
    eventType: {
      alias: "eventType",
      control: "select",
      editable: editable,
      config: {
        options: [
          { label: "Private", value: "Private" },
          { label: "Public", value: "Public" },
        ],
      },
      label: "Event Type",
      attributes: {
        className: "form-control",
      },
    },
    conferenceParticipants: {
      alias: "conferenceParticipants",
      control: "module",
      editable: editable,
      config: {
        module: "uniparticipantsandfilesevent",
        view: "participants",
        moduleParams: { type: "streams" },
        parameters: {
          replace: {
            path: function () {
              return "/stream/" + Fun.getURLPathValue(2) + "/participants";
            },
          },
        },
      },
    },
    participants: {
      alias: "participants",
      label: "Select participants",
      placeholder: "Type email or name",
      control: "tags",
      editable: editable,
      config: {
        idAttribute: "_id",
        nameAttribute: "name",
        apis: {
          search: {
            url: "/user/autocomplete/{query}?type=participants",
            formatter: function (args) {
              let formattedData = [];
              if (args) {
                let data = args.data;
                if (data) {
                  data.map((itm, idx) => {
                    if (itm["_id"]) {
                      let fullName =
                        itm["firstName"] +
                        " " +
                        itm["lastName"] +
                        " - " +
                        itm["email"];

                      if (itm.type == "group") {
                        fullName = itm["firstName"] + " - " + itm["lastName"];
                      }
                      formattedData.push({
                        _id: itm["_id"],
                        name: fullName,
                        group: itm.type,
                      });
                    } else {
                      // if (UniFun.getURLPathValue(1) !== "conference") {
                      formattedData.push({ id: "", name: itm });
                      //}
                    }
                  });
                }
              }
              return formattedData;
            },
            parameters: {
              replace: {
                query: "",
              },
            },
          },
        },
      },
      events: {
        onAddition: function (tag) {
          if (!tag._id) {
            // this.ref.current.input.current.input.current.blur();
            //  if (Fun.getURLPathValue(1) !== "conference") {
            if (UniFun.isEmail(tag.name)) {
              tag["_id"] = tag.name.trim();
              tag["name"] = tag.name.trim();
              const tags = [].concat(this.state.tags, tag);
              this.setState({ tags });
            } else {
              let splitParticipants = [];
              let notValidEmails = [];

              tag.name = tag.name.replaceAll(",", " ");
              tag.name = tag.name.replaceAll(";", " ");

              tag.name.split(" ").forEach((itm, idx) => {
                if (itm) {
                  if (UniFun.isEmail(itm.trim())) {
                    splitParticipants.push({
                      id: itm.trim(),
                      name: itm.trim(),
                    });
                  } else {
                    notValidEmails.push(
                      <span className="d-block">{itm.trim()}</span>
                    );
                  }
                }
              });

              if (notValidEmails.length > 0) {
                Fun.createNotification({
                  message: (
                    <span>
                      The following inputs are not valid emails:<br></br>
                      {notValidEmails}
                    </span>
                  ),
                  type: "danger",
                });
              }

              const tags = [].concat(this.state.tags, splitParticipants);
              this.setState({ tags });
            }
            //}
          } else {
            const tags = [].concat(this.state.tags, tag);
            this.setState({ tags });
          }
        },
      },
      attributes: {
        className: "form-control",
      },
    },
    description: {
      alias: "description",
      control: "editor",
      editable: editable,
      label: "About (shown in public page)",
      placeholder: "Write about the conference",
      attributes: {
        className: "form-control",
      },
    },
    banner: {
      alias: "banner",
      control: "dropzone",
      label: "Banner",
      editable: editable,
      customContentLayout: function () {
        let files = JSON.parse(this.state.defaultValue);

        let content = [];

        Object.keys(files).map((itm, idx) => {
          content.push(
            UniFun.trainingNodeFileUI(files[itm], this.removeFile, itm)
          );
        });

        this.state.content?.map((itm, idx) => {
          // if (typeof files[itm.key] === "undefined" && itm.ref != null) {
          //   content.push(itm);
          // }
          if (this.state.pending.indexOf(itm.key) >= 0) {
            content.push(itm);
          }
        });

        return (
          <React.Fragment>
            {this.state.placeholder()}
            {content}
            {/* {content.length > 0 ? "" : this.state.placeholder()} */}
          </React.Fragment>
        );
      },

      useCustomLayout: true,
      config: {
        placeholder: function () {
          return (
            <div className="p-3">
              <strong>
                Drag & drop here or{" "}
                <a
                  href="javascript:void(0);"
                  className="text-primary"
                  onClick={this.onTargetClick}
                >
                  <strong>browse file</strong>
                </a>{" "}
                to attach
              </strong>
              <br></br>
              <span className="text-dark">
                Maximum size: 50MB - File type supported png,jpeg,jpg
              </span>
            </div>
          );
        },
      },
      events: {
        removeFile: function (id) {
          let defaultValue = {};

          Object.keys(JSON.parse(this.state.defaultValue)).map((itm, idx) => {
            if (itm != id) {
              defaultValue[itm] = JSON.parse(this.state.defaultValue)[itm];
            }
          });

          let content = [];
          this.state.content?.map((itm, idx) => {
            if (itm.key != id) {
              content.push(itm);
            }
          });

          this.setState({
            defaultValue: JSON.stringify(defaultValue),
            content: content,
          });

          return;
        },
        onDrop: function (files) {
          // this.setState({ defaultValue: JSON.stringify({}) });

          if (
            files[0].type == "image/jpeg" ||
            files[0].type == "image/jpg" ||
            files[0].type == "image/png"
          ) {
            UniFun.fileProgress({
              control: this,
              files: files,
              url: "/agenda-file",
              type: "meeting",
            });
          } else {
            Fun.createNotification({
              message: "Only png,jpeg,jpg files are allowed to upload",
              type: "danger",
            });
          }
        },
      },
      attributes: {
        className: "border rounded  dropzone-trainings",
      },
    },
    sponsors: {
      alias: "sponsors",
      label: "Partners",
      placeholder: "Type partner name...",
      control: "tags",
      editable: editable,
      config: {
        idAttribute: "_id",
        nameAttribute: "name",
        apis: {
          search: {
            url: "/sponsors/autocomplete/{query}",
            formatter: function (args) {
              let formattedData = [];
              if (args) {
                let data = args.data;
                if (data) {
                  data.map((itm, idx) => {
                    formattedData.push({ _id: itm["_id"], name: itm["title"] });
                  });
                }
              }
              return formattedData;
            },
            parameters: {
              replace: {
                query: "",
              },
            },
          },
        },
      },
      attributes: {
        className: "form-control",
      },
    },

    startsOn: {
      alias: "startsOn",
      control: "datetime",
      editable: editable,
      label: "Starts on",
      config: {
        fromFormat: "YYYY-MM-DDT00:00:00.000Z",
        toFormat: "ddd DD MMM YYYY",
        includeTime: false,
        valueEditFormat: { dateStyle: "short" },
        valueDisplayFormat: { dateStyle: "short" },
      },
      attributes: {
        className: "form-control",
      },
    },

    days: {
      alias: "days",
      control: "tabs",
      editable: editable,
      label: <h3 className="text-black mt-4">Add days</h3>,
      attributes: {
        className: "form-control",
      },
      config: {
        canClose: true,
        tabs: [
          {
            labelNew: (
              <span>
                <img
                  src="/uni/svg/add.svg"
                  className="filter-link mr-2"
                  style={{ width: "14px", paddingBottom: "4px" }}
                ></img>{" "}
                Add day
              </span>
            ),
            label: "Day {index}",
            includeProperties: ["_id", "date", "sessions"],
            isNewTab: true,
          },
        ],
        properties: {
          _id: {
            alias: "_id",
            control: "hidden",
            label: "",
            editable: editable,
          },
          date: {
            alias: "date",
            control: "datetime",
            label: "Select date",
            config: {
              fromFormat: "YYYY-MM-DDT00:00:00.000Z",
              toFormat: "YYYY-MM-DDT00:00:00.000Z",
              includeTime: false,
              valueEditFormat: { dateStyle: "short" },
              valueDisplayFormat: { dateStyle: "short" },
            },
            editable: editable,
            attributes: {
              className: "w-25",
            },
          },
          sessions: {
            alias: "sessions",
            control: "tabs",
            label: <h3 className="text-black">Sessions</h3>,
            config: {
              canClose: true,
              tabs: [
                {
                  labelNew: (
                    <span>
                      <img
                        src="/uni/svg/add.svg"
                        className="filter-link mr-2"
                        style={{ width: "14px", paddingBottom: "4px" }}
                      ></img>{" "}
                      Add session
                    </span>
                  ),
                  label: "Session {index}",
                  includeProperties: [
                    "_id",
                    "title",
                    "speakers",
                    "from",
                    "to",
                    "description",
                  ],
                  isNewTab: true,
                },
              ],
              properties: {
                _id: {
                  alias: "_id",
                  control: "hidden",
                  editable: editable,
                  attributes: {
                    className: "form-control",
                  },
                },
                title: {
                  alias: "title",
                  control: "text",
                  editable: editable,
                  placeholder: "Type session title",
                  label: "Title",
                  attributes: {
                    className: "form-control",
                  },
                },
                speakers: {
                  alias: "speakers",
                  label: "Select speakers",
                  placeholder: "Type email or name...",
                  control: "tags",
                  editable: editable,
                  config: {
                    idAttribute: "_id",
                    nameAttribute: "name",
                    apis: {
                      search: {
                        url: "/user/autocomplete/{query}?type=instructors",
                        formatter: function (args) {
                          let formattedData = [];
                          if (args) {
                            let data = args.data;
                            if (data) {
                              data.map((itm, idx) => {
                                let fullName =
                                  itm["firstName"] +
                                  " " +
                                  itm["lastName"] +
                                  " - " +
                                  itm["email"];
                                if (itm.type == "group") {
                                  fullName =
                                    itm["firstName"] + " - " + itm["lastName"];
                                }
                                formattedData.push({
                                  _id: itm["_id"],
                                  name: fullName,
                                  group: itm.type,
                                });
                              });
                            }
                          }
                          return formattedData;
                        },
                        parameters: {
                          replace: {
                            query: "",
                          },
                        },
                      },
                    },
                  },
                  attributes: {
                    className: "form-control",
                  },
                },

                from: {
                  alias: "from",
                  control: "select",
                  label: "Start time",
                  config: {
                    options: UniFun.timeSelect,
                  },
                  editable: editable,
                  attributes: {
                    className: "form-control w-25",
                  },
                },
                to: {
                  alias: "to",
                  control: "select",
                  label: "End time",
                  config: {
                    options: UniFun.timeSelect,
                  },
                  editable: editable,
                  attributes: {
                    className: "form-control  w-25",
                  },
                },
                description: {
                  alias: "description",
                  control: "editor",
                  editable: editable,
                  label: "About the session",
                  placeholder: "Write about the session",
                  attributes: {
                    className: "form-control",
                  },
                },
              },
            },
          },
        },
      },
    },
    createdBy: {
      alias: "createdBy",
      control: "text",
      editable: editable,
      label: "",
      attributes: {
        className: "form-control",
      },
    },
    createdAt: {
      alias: "createdAt",
      control: "text",
      editable: editable,
      label: "Created on",
      attributes: {
        className: "form-control",
      },
    },
    updatedAt: {
      alias: "updatedAt",
      control: "text",
      editable: editable,
      label: "Updated on",
      attributes: {
        className: "form-control",
      },
    },
  };
}

const UniConferences = {
  auth: "",
  views: {
    privateList: {
      apis: {
        view: {
          url: "/streams?start={start}&offset={offset}",
          parameters: {
            headers: { "content-type": "application/json" },
            replace: {
              start: 0,
              offset: 25,
            },
          },
          formatter: function (data, builder) {
            return Mapper(data, builder);
          },
        },
      },
      template: "grid",
      templateConfig: {
        headerTitle: "Conferences",
        headerTitleIcon: "/uni/svg/trainings/go_back.svg",
        colsTemplateCount: 12,
        headerIconUrl: "/events/overview/",
        headerSubtitle: function () {
          if (!UniFun.userIsEligible({ forWhat: "isHostOrAdmin" })) {
            return "";
          }

          return (
            <ul className="nav" style={{ paddingLeft: "38px" }}>
              <li className="nav-link">
                <Link
                  to={"/events/conferences"}
                  className="text-black text-underline"
                >
                  List
                </Link>
              </li>
              <li className="nav-link">
                <Link to={"/events/conferences/sponsors"}>Partners</Link>
              </li>
            </ul>
          );
        },
        includeProperties: ["title", "startsOn"],
        header: UniFun.commonHeader,
        gridConfig: {
          headers: {
            title: {
              attributes: {
                className: "w-50",
              },
            },
          },
          cellTemplates: {
            title: function (data, rec, obj) {
              let canEdit = UniFun.userIsEligible({ forWhat: "isHostOrAdmin" })
                ? true
                : false;
              let uid = Fun.uuid();
              return (
                <React.Fragment>
                  <Link to={"/conference/" + rec._id.value}>
                    {UniFun.substring(rec.title.value, 0, 70)}
                  </Link>
                  <span className="float-right mr-4 grid-controls">
                    <Link to={"/conference/" + rec._id.value} className="mr-4">
                      View details{" "}
                      <i className="fas fa-chevron-right ml-2 pointer"></i>
                    </Link>
                    <a
                      href="javascript:void(0);"
                      onClick={(e) => {
                        UniFun.copyToClipboard(
                          UniFun.createURL("/conference/" + rec._id.value)
                        );
                      }}
                    >
                      <i className="fas fa-link mr-4 pointer"></i>
                    </a>
                    <i
                      class="fas fa-ellipsis-h pointer"
                      data-toggle="collapse"
                      data-target={"#" + uid}
                    ></i>
                    <div
                      className="position-absolute bg-white ml-4 mt-2 collapse"
                      id={uid}
                    >
                      <ul className="list-group " style={{ width: "200px" }}>
                        <Link
                          className="list-group-item p-2 pl-3"
                          to={"/conference/" + rec._id.value}
                        >
                          Open
                        </Link>
                        {canEdit ? (
                          <li
                            className="list-group-item p-2 pl-3"
                            onClick={(e) =>
                              UniFun.createEventModal(
                                obj.props.builder,
                                rec._id.value,
                                "Edit conference",
                                "uniconferences",
                                "privateEdit",
                                "modal",
                                "xl",
                                "Update"
                              )
                            }
                          >
                            Edit
                          </li>
                        ) : (
                          ""
                        )}
                        <li
                          className="list-group-item p-2 pl-3"
                          onClick={(e) => {
                            UniFun.copyToClipboard(
                              UniFun.createURL("/conference/" + rec._id.value)
                            );
                          }}
                        >
                          Copy link
                        </li>
                        {/* <li
                          className="list-group-item p-2 pl-3"
                          onClick={(e) => UniFun.createICS(rec.event.value)}
                        >
                          Download ics
                        </li> */}
                        {canEdit ? (
                          <li
                            className="list-group-item p-2 pl-3 text-danger"
                            onClick={(e) =>
                              UniFun.createEventModal(
                                obj.props.builder,
                                rec._id.value,
                                "Delete conference",
                                "uniconferences",
                                "delete",
                                "modal",
                                "default",
                                "Delete",
                                "btn-danger"
                              )
                            }
                          >
                            Delete
                          </li>
                        ) : (
                          ""
                        )}
                      </ul>
                    </div>
                  </span>
                </React.Fragment>
              );
            },
          },
          emptyFolder: function () {
            return (
              <div className="row mt-4 " style={{ height: "500px" }}>
                <div className="col-12 text-center">
                  <h5>
                    <strong>Conferences are empty</strong>
                  </h5>
                  <p>Your conferences will appear here</p>
                </div>
              </div>
            );
          },
        },
        cardTemplateTop: function () {
          let data = this.state.data;
          let parentBuilder = this.props.builder;
          let pagination = this.state.rawData.pagination;
          return (
            <div className="row">
              <div className="col-6 pt-2 pl-4">
                <span className="text-dark ">
                  There are {pagination.total} conferences
                </span>
              </div>
              <div className="col-6 text-right p-2 pr-4">
                {UniFun.userIsEligible({ forWhat: "isHostOrAdmin" }) ? (
                  <button
                    type="button"
                    className="btn btn-primary bg-navy-blue"
                    // onClick={(e) => addUser(parentBuilder)}
                    onClick={(e) => selectAdd(parentBuilder)}
                  >
                    Schedule
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>
          );
        },
        cardTemplateBottom: function () {
          let data = this.state.data;
          let pagination = this.state.rawData.pagination;
          let parentBuilder = this.props.builder;

          return (
            <div className="mt-4">
              {UniFun.pagination(
                pagination.currentPage,
                0,
                25,
                pagination.total,
                parentBuilder,
                "offset"
              )}
            </div>
          );
        },
      },
      properties: Properties({ editable: false }),
    },
    privateEdit: {
      apis: {
        view: {
          url: "/stream/{_id}",
          parameters: {
            headers: { "content-type": "application/json" },
          },
          formatter: function (data, builder) {
            return Mapper(data, builder);
          },
        },
        update: {
          url: "/stream/",
          parameters: {
            headers: { "content-type": "application/json" },
          },
          formatter: function (data, builder) {
            return Mapper(data, builder);
          },
        },
      },
      template: "form",
      templateConfig: {
        events: {
          onSubmit: function (e) {
            e.preventDefault();
            let submittedData = Fun.getSubmitJSON(e);

            let host = [];
            try {
              JSON.parse(submittedData.hosts)?.forEach((itm, idx) => {
                host.push(itm._id);
              });
            } catch (e) {}
            submittedData["hosts"] = host;

            let sponsors = [];

            try {
              JSON.parse(submittedData.sponsors)?.forEach((itm, idx) => {
                sponsors.push(itm._id);
              });
            } catch (e) {}

            submittedData["sponsors"] = sponsors;
            submittedData["pollEvents"] = [];

            submittedData["banner"] = submittedData["banner"]
              ? UniFun.agendaFilesJson(JSON.parse(submittedData["banner"]))[0]
              : null;

            let participants = [];
            if (submittedData.participants) {
              try {
                JSON.parse(submittedData.participants)?.forEach((itm, idx) => {
                  participants.push(itm._id);
                });
              } catch (e) {}
              submittedData["participants"] = participants;
            }

            submittedData["days"]?.forEach((itm, idx) => {
              if (itm._id == "") {
                delete itm._id;
              }
              itm["sessions"].forEach((sitm, sidx) => {
                if (sitm._id == "") {
                  delete sitm._id;
                }
                let from = sitm.from.split(":");
                let dateFrom = moment(itm.date)
                  .set("hour", from[0])
                  .set("minute", from[1])
                  .set("seconds", 0)
                  .set("millisecond", 0);

                sitm.from = dateFrom.utc().format();
                let to = sitm.to.split(":");
                let dateTo = moment(itm.date)
                  .set("hour", to[0])
                  .set("minute", to[1])
                  .set("seconds", 0)
                  .set("millisecond", 0);
                sitm.to = dateTo.utc().format();
                let speakers = [];
                try {
                  JSON.parse(sitm.speakers)?.forEach((itm, idx) => {
                    speakers.push(itm._id);
                  });
                } catch (e) {}
                sitm["speakers"] = speakers;
              });
            });

            Fun.update(e, {
              url: this.props.builder.state.module.apis.update,
              method: "PUT",
              callback: function (
                submittedData,
                status,
                responsedata,
                responsestatus
              ) {
                if (responsestatus == "200") {
                  Fun.createNotification({
                    message: "Update confernce was successful",
                    type: "success",
                  });
                  if (window["modal"].state.callback) {
                    window["modal"].state.callback(submittedData);
                  } else {
                    if (window["modal"].state.targetComponent) {
                      window["modal"].state.targetComponent.setState({
                        data: submittedData,
                      });
                    }
                  }
                } else {
                  if (responsedata.data.exceptions) {
                    responsedata.data.exceptions.map((itm, idx) => {
                      Fun.createNotification({
                        message: itm.errorDescription,
                        type: "danger",
                      });
                    });
                  }
                }
              },
              parameters: {
                headers: {
                  "content-type": "application/json",
                },
              },
              submittedData: JSON.stringify(submittedData),
            });
          },
        },
        includeProperties: [
          "_id",
          "title",
          "hosts",
          "eventType",
          // "participants",
          "description",
          "banner",
          "sponsors",
          "days",
        ],
      },
      properties: Properties({ editable: true }),
    },
    privateAdd: {
      apis: {
        view: {
          data: { data: [{}] },
          parameters: {
            headers: { "content-type": "application/json" },
          },
          formatter: function (data, builder) {
            return Mapper(data, builder);
          },
        },
        update: {
          url: "/stream/",
          parameters: {
            headers: { "content-type": "application/json" },
          },
          formatter: function (data, builder) {
            return Mapper(data, builder);
          },
        },
      },
      template: "form",
      templateConfig: {
        events: {
          onSubmit: function (e) {
            e.preventDefault();
            let submittedData = Fun.getSubmitJSON(e);

            let host = [];
            try {
              JSON.parse(submittedData.hosts)?.forEach((itm, idx) => {
                host.push(itm._id);
              });
            } catch (e) {}
            submittedData["hosts"] = host;
            let sponsors = [];
            submittedData["pollEvents"] = [];

            try {
              JSON.parse(submittedData.sponsors)?.forEach((itm, idx) => {
                sponsors.push(itm._id);
              });
            } catch (e) {}

            submittedData["sponsors"] = sponsors;

            submittedData["banner"] = submittedData["banner"]
              ? UniFun.agendaFilesJson(JSON.parse(submittedData["banner"]))[0]
              : null;

            let participants = [];
            try {
              participants = UniFun.participantsJSONData(
                JSON.parse(submittedData.participants)
              );
              // JSON.parse(submittedData.participants)?.forEach((itm, idx) => {
              //   participants.push(itm._id);
              // });
            } catch (e) {}
            submittedData["participants"] = participants;

            delete submittedData["_id"];

            submittedData["days"]?.forEach((itm, idx) => {
              if (itm._id == "") {
                delete itm._id;
              }
              itm["sessions"].forEach((sitm, sidx) => {
                if (sitm._id == "") {
                  delete sitm._id;
                }
                let from = sitm.from.split(":");
                let dateFrom = moment(itm.date)
                  .set("hour", from[0])
                  .set("minute", from[1])
                  .set("seconds", 0)
                  .set("millisecond", 0);

                sitm.from = dateFrom.utc().format();
                let to = sitm.to.split(":");
                let dateTo = moment(itm.date)
                  .set("hour", to[0])
                  .set("minute", to[1])
                  .set("seconds", 0)
                  .set("millisecond", 0);
                sitm.to = dateTo.utc().format();
                let speakers = [];
                try {
                  JSON.parse(sitm.speakers)?.forEach((itm, idx) => {
                    speakers.push(itm._id);
                  });
                } catch (e) {}
                sitm["speakers"] = speakers;
              });
            });

            Fun.update(e, {
              url: this.props.builder.state.module.apis.update,
              method: "POST",
              callback: function (
                submittedData,
                status,
                responsedata,
                responsestatus
              ) {
                if (responsestatus == "200") {
                  Fun.createNotification({
                    message: "Update confernce was successful",
                    type: "success",
                  });
                  if (window["modal"].state.callback) {
                    window["modal"].state.callback(submittedData);
                  } else {
                    if (window["modal"].state.targetComponent) {
                      window["modal"].state.targetComponent.setState({
                        data: submittedData,
                      });
                    }
                  }
                } else {
                  if (responsedata.data.exceptions) {
                    responsedata.data.exceptions.map((itm, idx) => {
                      Fun.createNotification({
                        message: itm.errorDescription,
                        type: "danger",
                      });
                    });
                  }
                }
              },
              parameters: {
                headers: {
                  "content-type": "application/json",
                },
              },
              submittedData: JSON.stringify(submittedData),
            });
          },
        },
        includeProperties: [
          "_id",
          "title",
          "hosts",
          "eventType",
          "participants",
          "description",
          "banner",
          "sponsors",
          "days",
        ],
      },
      properties: Properties({ editable: true }),
    },
    privateViewReception: {
      apis: {
        view: {
          url: "/stream/{_id}",
          parameters: {
            headers: { "content-type": "application/json" },
            replace: {
              _id: function () {
                return Fun.getURLPathValue(2);
              },
            },
          },

          formatter: function (data, builder) {
            return Mapper(data, builder);
          },
        },
        update: {
          url: "/stream/",
          parameters: {
            headers: { "content-type": "application/json" },
          },
          formatter: function (data, builder) {
            return Mapper(data, builder);
          },
        },
      },
      template: function () {
        if (Fun.getURLPathValue(3) == "session") {
          return "session";
        }
        if (Fun.getURLPathValue(3) == "sessions") {
          return "sessions";
        }
        if (Fun.getURLPathValue(3) == "speakers") {
          return "speakers";
        }
        if (Fun.getURLPathValue(3) == "sponsors") {
          return "sponsors";
        }
        return "reception";
      },
      templateConfig: {
        events: {
          speakerInfoModal: speakerInfoModal,
          sponsorsInfoModal: sponsorsInfoModal,
          readMore: readMore,
        },
        includeProperties: [
          "_id",
          "title",
          "hosts",
          "isPublic",
          "participants",
          "description",
          "banner",
          "sponsors",
          "days",
        ],
      },
      properties: Properties({ editable: false }),
    },
    publicList: {
      apis: {
        view: {
          url: "/streams?start={start}&offset={offset}",
          parameters: {
            headers: { "content-type": "application/json" },
            replace: {
              start: 0,
              offset: 25,
            },
          },
          formatter: function (data, builder) {
            return Mapper(data, builder);
          },
        },
      },
      template: "publichomepage",
      templateConfig: {
        includeProperties: ["title", "startsOn"],
      },
      properties: Properties({ editable: false }),
    },
    publicView: {
      apis: {
        view: {
          url: "/stream/{_id}",
          parameters: {
            headers: {
              "content-type": "application/json",
              Authorization: "Bearer public",
            },
            replace: {
              _id: function () {
                return Fun.getURLPathValue(2);
              },
            },
          },

          formatter: function (data, builder) {
            return Mapper(data, builder);
          },
        },
        update: {
          url: "/stream/",
          parameters: {
            headers: { "content-type": "application/json" },
          },
          formatter: function (data, builder) {
            return Mapper(data, builder);
          },
        },
      },
      template: function () {
        if (Fun.getURLPathValue(3) == "session") {
          return "publicsession";
        }
        if (Fun.getURLPathValue(3) == "sessions") {
          return "publicsessions";
        }
        if (Fun.getURLPathValue(3) == "speakers") {
          return "publicspeakers";
        }
        if (Fun.getURLPathValue(3) == "sponsors") {
          return "publicsponsors";
        }
        return "publicreception";
      },
      templateConfig: {
        events: {
          componentDidMountEvent: function () {
            Fun.addCSS("/uni/css/conferences.css");
          },
          speakerInfoModal: speakerInfoModal,
          sponsorsInfoModal: sponsorsInfoModal,

          interestFormModal: function () {
            window["modal"].setState({
              title: "Thank you for your interest!",
              size: "lg",
              show: true,

              body: (
                <Builder module="uniconferences" view="interestForm"></Builder>
              ),
            });
          },
        },
        includeProperties: [
          "_id",
          "title",
          "hosts",
          "isPublic",
          "participants",
          "description",
          "banner",
          "sponsors",
          "days",
        ],
      },
      properties: Properties({ editable: true }),
    },
    interestForm: {
      apis: {
        view: {
          data: { data: [{}] },
          parameters: {
            headers: {
              "content-type": "application/json",
            },
            replace: {},
          },

          formatter: function (data, builder) {
            return Mapper(data, builder);
          },
        },
      },
      template: function () {
        return "interestform";
      },
      templateConfig: {
        events: {
          onSubmit: function (e) {
            e.preventDefault();

            if (this.validator.allValid()) {
              window.grecaptcha.ready(function () {
                window.grecaptcha
                  .execute("6LfqMDQeAAAAAKxo_kbK9dVlnwXtRoGWTEOUWn-X", {
                    action: "sendEmail",
                  })
                  .then(function (token) {
                    let data = Fun.getSubmitJSON(e);
                    data["token"] = token;
                    data["streamId"] = Fun.getURLPathValue(2);

                    Fun.update(e, {
                      url: {
                        url: "/auth/recaptcha",
                        formatter: function () {
                          return [];
                        },
                      },
                      method: "POST",
                      parameters: {
                        headers: {
                          "content-type": "application/json",
                        },
                      },
                      submittedData: data,
                      callback: function (
                        args,
                        status,
                        responsedata,
                        responsestatus
                      ) {
                        if (responsestatus == "200") {
                          if (responsedata) {
                            if (responsedata.exceptions) {
                              responsedata.exceptions.map((itm, idx) => {
                                Fun.createNotification({
                                  message: itm.errorDescription,
                                  type: "danger",
                                });
                              });
                            } else {
                              Fun.createNotification({
                                message: responsedata.message,
                                type: "success",
                              });
                              window["modal"].setState({ show: false });
                            }
                          } else {
                            Fun.createNotification({
                              message: args.message,
                              type: "success",
                            });
                            window["modal"].setState({ show: false });
                          }
                        } else {
                          if (responsedata.data.exceptions) {
                            responsedata.data.exceptions.map((itm, idx) => {
                              Fun.createNotification({
                                message: itm.errorDescription,
                                type: "danger",
                              });
                            });
                          }
                        }
                      },
                    });
                  });
              });
            } else {
              this.validator.showMessages();
              this.forceUpdate();
            }
          },
        },
        excludeProperties: ["none"],
      },
      properties: Properties({ editable: false }),
    },
    participants: {
      apis: {
        view: {
          url: "/stream/{_id}",
          parameters: {
            headers: {
              "content-type": "application/json",
            },
            replace: {
              _id: function () {
                return Fun.getURLPathValue(2);
              },
            },
          },

          formatter: function (data, builder) {
            return Mapper(data, builder);
          },
        },
      },
      template: "form",
      templateConfig: {
        header: function () {
          return (
            <div className={"uniTopHeader "}>
              <TopTemplateHeader
                customClass="mb-4 uniFixedHeader"
                title={
                  <React.Fragment>
                    <span className="pl-3">Participants</span>
                  </React.Fragment>
                }
              ></TopTemplateHeader>
            </div>
          );
        },
        useCard: false,
        includeProperties: ["conferenceParticipants"],
      },
      properties: Properties({ editable: false }),
    },
    settings: {
      apis: {
        view: {
          url: "/stream/{_id}/settings",
          parameters: {
            headers: {
              "content-type": "application/json",
            },
            replace: {
              _id: function () {
                return Fun.getURLPathValue(2);
              },
            },
          },

          formatter: function (data, builder) {
            return Mapper(data, builder);
          },
        },
      },
      template: function () {
        return "conferencesettings";
      },
      templateConfig: {
        excludeProperties: ["none"],
      },
      properties: Properties({ editable: false }),
    },
    delete: {
      auth: "",
      apis: {
        view: {
          url: "/stream/{id}",
          parameters: {
            headers: {},
            replace: {},
          },
          formatter: function (data) {
            return Mapper(data);
          },
        },
        delete: {
          url: "/stream/{id}",
          parameters: {
            headers: {},
            replace: {},
          },
          formatter: function (data) {
            return Mapper(data);
          },
        },
      },
      template: "form",
      templateConfig: {
        useCard: false,
        colsTemplateCount: 12,
        includeProperties: ["_id"],
        excludeProperties: [],
        events: {
          onSubmit: function (e) {
            let submittedData = Fun.getSubmitJSON(e);

            let builder = this.props.builder;

            Fun.update(e, {
              url: builder.state.module.apis.delete,
              method: "DELETE",
              parameters: {
                headers: {
                  "content-type": "application/json",
                },
                replace: {
                  id: submittedData["_id"],
                },
              },
              callback: function (args) {
                if (window["modal"].state.callback) {
                  window["modal"].state.callback(submittedData);
                }
              },
              submittedData: JSON.stringify([]),
            });
          },
        },
        templateTop: function () {
          let data = this.state.data[0];
          return (
            <div className=" p-4">
              <p>
                Are you sure you want to delete conference "
                <strong>{data.title.value}</strong>"?
              </p>
            </div>
          );
        },
      },
      pk: "_id",
      properties: Properties({ editable: false }),
    },
    live: {
      apis: {
        view: {
          url: "/stream/{_id}",
          parameters: {
            headers: {
              "content-type": "application/json",
            },
            replace: {
              _id: function () {
                return Fun.getURLPathValue(3);
              },
            },
          },

          formatter: function (data, builder) {
            return Mapper(data, builder);
          },
        },
        liveInfo: {
          url: "/stream/{streamId}/day/{dayId}/session/{sessionId}/liveInfo",
          parameters: {
            headers: {
              "content-type": "application/json",
            },
            replace: {
              streamId: function () {
                return Fun.getURLPathValue(3);
              },
              dayId: function () {
                return Fun.getURLPathValue(4);
              },
              sessionId: function () {
                return Fun.getURLPathValue(5);
              },
            },
          },
          formatter: function (data, builder) {
            if (data) {
              if (data.data) {
                return data.data;
              }
            }
            return [];
          },
        },
      },
      template: function () {
        return "conferencelive";
      },
      templateConfig: {
        events: {
          speakerInfoModal: speakerInfoModal,
          sponsorsInfoModal: sponsorsInfoModal,
          componentDidMountEvent: function () {
            Fun.addCSS("/uni/css/liveconference.css");
            let _this = this;

            function liveInfo() {
              Fun.fetch({
                url: _this.props.builder.state.module.apis.liveInfo,
                callback: function (data) {
                  if (data[0]) {
                    _this.setState({
                      isLive: data[0].currentSession.isLive,
                      todaysSessions: data[0].otherSessions
                        ? data[0].otherSessions
                        : [],
                      // viewers: data[0].currentSession.viewerCount,
                    });

                    if (data[0].currentSession.isLive) {
                      if (data[0].currentSession.viewerCount > 0) {
                      }
                      _this.setState({
                        viewers: data[0].currentSession.viewerCount,
                      });
                    }
                  }
                },
              });
            }

            liveInfo();
            let intervalTime = 15000;
            if (localStorage.getItem("conferenceLiveInfoInterval")) {
              intervalTime = parseInt(
                localStorage.getItem("conferenceLiveInfoInterval")
              );
            }

            window["liveconferenceinterval"] = setInterval(
              liveInfo,
              intervalTime
            );
          },
          componentWillUnmount: function () {
            clearInterval(window["liveconferenceinterval"]);
          },
        },

        excludeProperties: ["none"],
      },
      properties: Properties({ editable: false }),
    },
    publicTerms: {
      apis: {
        view: {
          url: "/getCompanySettings",
          //data: { data: [{ content: "test" }] },
          parameters: {
            headers: {
              "content-type": "application/json",
            },
            replace: {},
          },

          formatter: MapperTerms,
        },
      },
      template: "privacy",
      templateConfig: {
        useCard: false,
        includeProperties: ["content"],
      },
      properties: PropertiesTerms(),
    },
    publicPrivacy: {
      apis: {
        view: {
          url: "/getCompanySettings",
          // data: { data: [{ content: "test" }] },
          parameters: {
            headers: {
              "content-type": "application/json",
            },
            replace: {},
          },

          formatter: MapperPrivacy,
        },
      },
      template: "privacy",
      templateConfig: {
        useCard: false,
        includeProperties: ["content"],
      },
      properties: PropertiesTerms(),
    },
  },
};

function MapperTerms(data, builder) {
  let formattedData = [];
  if (data) {
    if (data.data) {
      data.data.forEach((itm, idx) => {
        let formattedRecord = {};
        formattedRecord.content = itm.termsConditions;
        formattedData.push(formattedRecord);
      });
    }
  }

  return formattedData;
}

function MapperPrivacy(data, builder) {
  let formattedData = [];
  if (data) {
    if (data.data) {
      data.data.forEach((itm, idx) => {
        let formattedRecord = {};
        formattedRecord.content = itm.privacyPolicy;
        formattedData.push(formattedRecord);
      });
    }
  }

  return formattedData;
}

function PropertiesTerms() {
  return {
    content: {
      alias: "content",
      control: "text",
      editable: true,
      label: "",
      useCustomLayout: true,
      customLayout: function () {
        return Fun.parse(this.state.defaultValue);
      },
      attributes: {
        className: "form-control",
      },
    },
  };
}
export default UniConferences;
