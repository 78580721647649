import React, { Component } from "react";
import Control from "./../../control";
import SimpleReactValidator from "simple-react-validator";
import Fun from "../../../../../lib/functions";
import UniFun from "../../../../../modules/uni/functions";
import moment from "moment";
import TopTemplateHeader from "./../../topTemplateHeader";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import Header from "./header";
import Footer from "./footer";

class PublicHomepage extends Component {
  state = {
    data: this.props.data,
    loaded: false,
    colsTemplateCount: 12,
    colsFormCount: 12,
    cardBodyCustomClass: "",
    module: {
      _id: "",
      title: "",
      description: "",
    },
    session: {},
  };

  constructor(props) {
    super(props);
    let builder = this.props.builder.state;
    let templateConfig = builder.module.templateConfig;
    if (templateConfig.events) {
      Object.keys(templateConfig.events).map((itm, idx) => {
        this[itm] = templateConfig.events[itm].bind(this);
      });
    }

    this.form = React.createRef();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.action) {
      return false;
    }
    return true;
  }

  componentDidMount() {
    let builder = this.props.builder.state;
    let templateConfig = builder.module.templateConfig;
    let useCard =
      typeof templateConfig.useCard !== "undefined"
        ? templateConfig.useCard
        : true;

    let templateTop = templateConfig.templateTop
      ? templateConfig.templateTop
      : () => {};
    this["templateTop"] = templateTop.bind(this);
    let templateBottom = templateConfig.templateBottom
      ? templateConfig.templateBottom
      : () => {};
    this["templateBottom"] = templateBottom.bind(this);
    let templateLeft = templateConfig.templateLeft
      ? templateConfig.templateLeft
      : () => {};
    this["templateLeft"] = templateLeft.bind(this);
    let templateRight = templateConfig.templateRight
      ? templateConfig.templateRight
      : () => {};
    this["templateRight"] = templateRight.bind(this);
    let cardTemplateTop = templateConfig.cardTemplateTop
      ? templateConfig.cardTemplateTop
      : () => {};
    this["cardTemplateTop"] = cardTemplateTop.bind(this);
    let cardTemplateBottom = templateConfig.cardTemplateBottom
      ? templateConfig.cardTemplateBottom
      : () => {};
    this["cardTemplateBottom"] = cardTemplateBottom.bind(this);
    let panelTop = templateConfig.panelTop ? templateConfig.panelTop : () => {};
    this["panelTop"] = panelTop.bind(this);
    let panelBottom = templateConfig.panelBottom
      ? templateConfig.panelBottom
      : () => {};
    this["panelBottom"] = panelBottom.bind(this);
    let panelLeft = templateConfig.panelLeft
      ? templateConfig.panelLeft
      : () => {};
    this["panelLeft"] = panelLeft.bind(this);
    let panelRight = templateConfig.panelRight
      ? templateConfig.panelRight
      : () => {};
    this["panelRight"] = panelRight.bind(this);
    let header = templateConfig.header ? templateConfig.header : () => {};
    this["header"] = header.bind(this);

    let colsTemplateCount = templateConfig.colsTemplateCount
      ? templateConfig.colsTemplateCount
      : this.state.colsTemplateCount;
    let colsFormCount = templateConfig.colsFormCount
      ? templateConfig.colsFormCount
      : this.state.colsFormCount;

    let cardBodyCustomClass = templateConfig.cardBodyCustomClass
      ? templateConfig.cardBodyCustomClass
      : this.state.cardBodyCustomClass;

    this.setState({
      loaded: true,
      rawData: this.props.rawData,
      colsTemplateCount: colsTemplateCount,
      colsFormCount: colsFormCount,
      cardBodyCustomClass: cardBodyCustomClass,
      useCard: useCard,
    });

    this.validator = this.props.builder.validator;
    if (this.componentDidMountEvent) {
      this.componentDidMountEvent();
    }

    Fun.addCSS("/uni/css/conferences.css");
  }

  render() {
    if (this.state.loaded) {
      let data = this.state.data;

      return (
        <React.Fragment>
          <Header showMenu={false}></Header>
          <div className="container" style={{ minHeight: "100%" }}>
            <div>
              <h3 className="header-title pt-3">
                <strong>Events</strong>
              </h3>
            </div>
            <div className="row">
              {this.state.data.map((itm, idx) => {
                let banner = itm.banner.value[0]
                  ? window["baseurl"](
                      "/agenda-file/data/" +
                        itm.banner.value[0] +
                        "?type=preview"
                    )
                  : "/uni/svg/conferencePlaceholder.jpg";

                return (
                  <div className="col-md-4 mt-4">
                    <Link to={"/conference/" + itm._id.value}>
                      <article className="w-100 pointer h-100">
                        <div
                          className="rounded"
                          style={{
                            height: "200px",
                            backgroundImage: "url(" + banner + ")",
                            backgroundSize: "cover",
                          }}
                        ></div>
                        <div className="p-3">
                          <span className="event-type-title mt-3 d-block mb-3">
                            <strong>CONFERENCE</strong>
                          </span>
                          <h4 className="event-title mt-2 d-block mb-2">
                            {itm.title.value}
                          </h4>
                          <span className="event-description mt-2 d-block mb-2">
                            {UniFun.substring(
                              UniFun.stripTags(itm.description.value),
                              0,
                              150
                            )}
                          </span>
                        </div>
                      </article>
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
        </React.Fragment>
      );
    } else {
      return "loading...";
    }
  }
}

export default withRouter(PublicHomepage);
