import React, { Component } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";

class ProgressBarControl extends Component {
  state = {
    name: "",
    id: "",
    placeholder: "",
    attributes: "",
    value: "",
    defaultValue: "",
    loaded: false,
    editable: true,
    component: null,
    attributes: "",
    events: {},
    customLayout: null,
    useCustomLayout: true,
    editable: true,
    show: true,
  };

  constructor(props) {
    super(props);

    let events = {};
    if (this.props.field?.events) {
      Object.keys(this.props.field.events).map((itm, idx) => {
        this[itm] = this.props.field.events[itm];
        this[itm] = this[itm].bind(this);
        events[itm] = this[itm];
      });
    }

    this.state = {
      min: 0,
      max: 100,
      now: this.props.value,
      events: events,
      defaultValue: this.props.value ? this.props.value : "",
    };
  }

  componentDidMount() {
    let min = this.state.min;
    let max = this.state.max;
    let now = this.state.now;

    if (this.props.field.config) {
      if (this.props.field.config.min) {
        min = this.props.field.config.min;
      }
      if (this.props.field.config.max) {
        max = this.props.field.config.max;
      }
      if (this.props.field.config.now) {
        now = this.props.field.config.now;
      }
    }

    this.setState({
      min: min,
      max: max,
      now: now,
      attributes: this.props.field?.attributes,
      name: this.props.field?.alias,
      customLayout: this.props.field?.customLayout,
      useCustomLayout:
        typeof this.props.field.useCustomLayout !== "undefined"
          ? this.props.field.useCustomLayout
          : true,
    });
  }

  render() {
    let output = (
      <ProgressBar
        variant="info"
        now={this.state.now}
        min={this.state.min}
        max={this.state.max}
      ></ProgressBar>
    );

    if (this.state.show == false) {
      output = "";
    }

    return output;
  }
}

export default ProgressBarControl;
