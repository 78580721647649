import React, { Component } from "react";
import Fun from "../../../../lib/functions";
import UniFun from "../../../../modules/uni/functions";
import moment from "moment";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import ModalWidget from "../modal";

class ConferenceLive extends Component {
  state = {
    data: this.props.data,
    loaded: false,
    colsTemplateCount: 12,
    colsFormCount: 12,
    cardBodyCustomClass: "",
    module: {
      _id: "",
      title: "",
      description: "",
    },
    session: {},
  };

  constructor(props) {
    super(props);
    let builder = this.props.builder.state;
    let templateConfig = builder.module.templateConfig;
    if (templateConfig.events) {
      Object.keys(templateConfig.events).map((itm, idx) => {
        this[itm] = templateConfig.events[itm].bind(this);
      });
    }

    this.todaysSessions = this.todaysSessions.bind(this);

    this.form = React.createRef();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.action) {
      return false;
    }
    return true;
  }

  componentDidMount() {
    let builder = this.props.builder.state;
    let templateConfig = builder.module.templateConfig;
    let useCard =
      typeof templateConfig.useCard !== "undefined"
        ? templateConfig.useCard
        : true;

    let templateTop = templateConfig.templateTop
      ? templateConfig.templateTop
      : () => {};
    this["templateTop"] = templateTop.bind(this);
    let templateBottom = templateConfig.templateBottom
      ? templateConfig.templateBottom
      : () => {};
    this["templateBottom"] = templateBottom.bind(this);
    let templateLeft = templateConfig.templateLeft
      ? templateConfig.templateLeft
      : () => {};
    this["templateLeft"] = templateLeft.bind(this);
    let templateRight = templateConfig.templateRight
      ? templateConfig.templateRight
      : () => {};
    this["templateRight"] = templateRight.bind(this);
    let cardTemplateTop = templateConfig.cardTemplateTop
      ? templateConfig.cardTemplateTop
      : () => {};
    this["cardTemplateTop"] = cardTemplateTop.bind(this);
    let cardTemplateBottom = templateConfig.cardTemplateBottom
      ? templateConfig.cardTemplateBottom
      : () => {};
    this["cardTemplateBottom"] = cardTemplateBottom.bind(this);
    let panelTop = templateConfig.panelTop ? templateConfig.panelTop : () => {};
    this["panelTop"] = panelTop.bind(this);
    let panelBottom = templateConfig.panelBottom
      ? templateConfig.panelBottom
      : () => {};
    this["panelBottom"] = panelBottom.bind(this);
    let panelLeft = templateConfig.panelLeft
      ? templateConfig.panelLeft
      : () => {};
    this["panelLeft"] = panelLeft.bind(this);
    let panelRight = templateConfig.panelRight
      ? templateConfig.panelRight
      : () => {};
    this["panelRight"] = panelRight.bind(this);
    let header = templateConfig.header ? templateConfig.header : () => {};
    this["header"] = header.bind(this);

    let colsTemplateCount = templateConfig.colsTemplateCount
      ? templateConfig.colsTemplateCount
      : this.state.colsTemplateCount;
    let colsFormCount = templateConfig.colsFormCount
      ? templateConfig.colsFormCount
      : this.state.colsFormCount;

    let cardBodyCustomClass = templateConfig.cardBodyCustomClass
      ? templateConfig.cardBodyCustomClass
      : this.state.cardBodyCustomClass;

    let data = this.props.rawData.data[0];
    let sessions = [];
    let session = {};
    let speakersIds = [];
    let speakers = [];
    let streamURL = "";

    data.days?.forEach((itm, idx) => {
      itm.sessions?.forEach((sitm, sidx) => {
        sitm["conferenceId"] = data._id;
        sitm["dayId"] = itm._id;
        sessions.push(sitm);
        if (sitm._id == Fun.getURLPathValue(5)) {
          session = sitm;

          sitm.speakersRaw.forEach((spitm, spidx) => {
            if (speakersIds.indexOf(spitm._id) < 0) {
              speakersIds.push(spitm._id);

              let name = (
                <span style={{ display: "inline-block" }}>
                  <span className="pt-2">
                    <strong className="text-primary">
                      {spitm.firstName + " " + spitm.lastName}
                    </strong>
                    <br></br>
                    {spitm.jobTitle}
                  </span>
                </span>
              );

              speakers.push(
                <div
                  className="mt-2 pointer"
                  onClick={(e) => this.speakerInfoModal(spitm)}
                >
                  {UniFun.createProfileImageBubble(
                    [spitm],
                    "avatar-default",
                    false,
                    name,
                    "13px"
                  )}
                </div>
              );
            }
          });
        }
      });
    });

    window["streamURL"] = session.playbackUrl;

    this.setState({
      loaded: true,
      rawData: this.props.rawData,
      colsTemplateCount: colsTemplateCount,
      colsFormCount: colsFormCount,
      cardBodyCustomClass: cardBodyCustomClass,
      useCard: useCard,
      session: session,
      speakers: speakers,
      allSessions: sessions,
      todaysSessions: [],
    });

    this.validator = this.props.builder.validator;
    if (this.componentDidMountEvent) {
      this.componentDidMountEvent();
    }

    Fun.addCSS(
      "https://cdnjs.cloudflare.com/ajax/libs/video.js/7.11.4/video-js.css",
      true
    );
    Fun.addScript(
      "https://cdnjs.cloudflare.com/ajax/libs/video.js/7.11.4/video.min.js",
      true
    );
    Fun.addScript(
      "https://player.live-video.net/1.3.1/amazon-ivs-videojs-tech.min.js",
      true
    );
    Fun.addScript(
      "https://player.live-video.net/1.3.1/amazon-ivs-quality-plugin.min.js",
      true
    );
    Fun.addScript("/uni/js/stream.js");
  }

  todaysSessions() {
    let output = [];

    if (this.state.todaysSessions.length > 0) {
      let sessionIds = [];
      let liveSessionsIds = [];
      this.state.todaysSessions.forEach((itm, idx) => {
        sessionIds.push(itm.sessionId);

        if (itm.isLive) {
          liveSessionsIds.push(itm.sessionId);
        }
      });

      this.state.allSessions.forEach((itm, idx) => {
        if (sessionIds.indexOf(itm._id) >= 0) {
          let url =
            "/conference/" + itm["conferenceId"] + "/session/" + itm._id;
          let classURL = "";
          let isLive = false;

          if (liveSessionsIds.indexOf(itm._id) >= 0) {
            isLive = true;
            classURL = "text-primary";
            url =
              "/conference/live/" +
              itm["conferenceId"] +
              "/" +
              itm.dayId +
              "/" +
              itm._id;
          }

          output.push(
            <Link to={url} target="new">
              <div className="row border-bottom">
                <div className="mt-3 p-2 col-10">
                  <span className="d-block text-primary">
                    <strong>{itm.title}</strong>
                  </span>
                  <span className="d-block pt-1 ">
                    {moment(itm.fromRaw).format("ddd DD MMM YYYY")}
                    {" | "}
                    {moment(itm.fromRaw).format("HH:mm")}
                    {" - "}
                    {moment(itm.toRaw).format("HH:mm")}
                  </span>
                </div>
                <div className="col-2 mt-3 p-2 ">
                  {isLive ? (
                    <img
                      src="/uni/svg/live.svg"
                      style={{ height: "40px" }}
                      className="mr-3"
                    ></img>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </Link>
          );
        }
      });
    }

    return output;
  }

  render() {
    if (this.state.loaded) {
      let data = this.state.rawData.data[0];

      let _this = this;
      return (
        <React.Fragment>
          <header className=" mb-3 w-100 bg-white border-bottom">
            <div className="row">
              <div className="col-12">
                <div className="mt-2 p-2">
                  <Link to="/events/conferences">
                    <img
                      src="/uni/images/company-logo.png"
                      style={{ height: "70px" }}
                    ></img>
                  </Link>
                  <img
                    src="/uni/svg/trainings/go_back.svg"
                    className="pr-3 pointer ml-3"
                    style={{ width: "45px", paddingBottom: "5px" }}
                    onClick={(e) => {
                      _this.props.history.push(
                        "/conference/" +
                          Fun.getURLPathValue(3) +
                          "/session/" +
                          Fun.getURLPathValue(5)
                      );
                    }}
                  ></img>

                  <h5 style={{ display: "inline-block" }}>
                    <strong>{this.state.session.title}</strong>
                  </h5>
                </div>
              </div>
            </div>
          </header>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-9  ">
                <video
                  id="amazon-ivs-videojs"
                  className="video-js vjs-4-3 vjs-big-play-centered rounded"
                  controls
                  autoPlay
                  playsInline
                  style={{ paddingTop: "54%" }}
                ></video>
              </div>
              <div className="col-md-3">
                <div className="card h-100">
                  <div className="card-body ">
                    <ul
                      className="nav nav-pills nav-tabs nav-justified"
                      role="tablist"
                    >
                      <li className="nav-item">
                        <a
                          className="nav-link active"
                          id="speakers-tab"
                          data-toggle="tab"
                          href="#speakers"
                          aria-controls="speakers"
                          aria-selected="true"
                        >
                          Speakers
                        </a>
                      </li>
                      <li className="nav-item pl-0 pr-0">
                        <a
                          className="nav-link pl-0 pr-0"
                          id="today-tab"
                          data-toggle="tab"
                          href="#today"
                          aria-controls="today"
                          aria-selected="false"
                        >
                          Today's sessions
                        </a>
                      </li>
                      {/* <li className="nav-item">
                        <a
                          className="nav-link"
                          id="polls-tab"
                          data-toggle="tab"
                          href="#polls"
                          aria-controls="polls"
                          aria-selected="false"
                        >
                          Polls
                        </a>
                      </li> */}
                    </ul>
                    <div class="tab-content pt-4">
                      <div
                        class="tab-pane active"
                        id="speakers"
                        role="tabpanel"
                        aria-labelledby="speakers-tab"
                      >
                        {this.state.speakers}
                      </div>
                      <div
                        class="tab-pane"
                        id="today"
                        role="tabpanel"
                        aria-labelledby="today-tab"
                      >
                        {this.todaysSessions()}
                      </div>
                      {/* <div
                        class="tab-pane"
                        id="polls"
                        role="tabpanel"
                        aria-labelledby="polls-tab"
                      >
                        g
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                {this.state.isLive ? (
                  <div>
                    <span>
                      <img
                        src="/uni/svg/live.svg"
                        style={{ height: "55px" }}
                        className="mr-3"
                      ></img>
                    </span>
                    <span>{this.state.viewers} are watching</span>
                  </div>
                ) : (
                  <div>
                    <span>
                      <img
                        src="/uni/svg/conferences/waiting.svg"
                        style={{ height: "37px" }}
                        className="mr-3"
                      ></img>
                    </span>
                    <span>Session starts soon</span>
                  </div>
                )}
              </div>
            </div>
          </div>
          <ModalWidget
            ref={(refComponent) => {
              window["modal"] = refComponent;
            }}
          ></ModalWidget>
        </React.Fragment>
      );
    } else {
      return "loading...";
    }
  }
}

export default withRouter(ConferenceLive);
