import React, { Component, ReactDOM } from "react";

import Fun from "../../../lib/functions";
import UniFun from "../functions";
import Builder from "../../../lib/builder";
import { Link } from "react-router-dom";

const filesEmptyFolder = function () {
  let builder = this.props.builder;
  let organizerId = "";
  let organizers = [];
  if (this.state.rawData.data) {
    if (this.state.rawData.data[0]) {
      if (this.state.rawData.data[0].organizer) {
        if (this.state.rawData.data[0].organizer._id) {
          organizerId = this.state.rawData.data[0].organizer._id;
          organizers = this.state.rawData.data[0].organizers;
        }
      }
    }
  }

  return (
    <div className="row mt-4 " style={{ height: "500px" }}>
      <div className="col-12 text-center">
        <h5>
          <strong>There not any files yet </strong>
        </h5>
        <p>
          {UniFun.userIsEligible({
            forWhat: "meeting",
            organizerId: organizerId,
            organizers: organizers,
          })
            ? "Drag n drop files into this window to upload."
            : ""}
        </p>
      </div>
    </div>
  );

  return [];
};

const fullNameCell = function (data, rec, obj) {
  let uid = Fun.uuid();

  let builder = obj.props.builder;

  let options = [];

  if (
    rec.participants.value !== "guest" &&
    (rec.hasPrivateInfo.value != true ||
      UniFun.userIsEligible({ forWhat: "default" }) ||
      UniFun.userIsEligible({ forWhat: "viewer" }))
  ) {
    options.push(
      <li
        className="list-group-item p-2 pl-3"
        onClick={(e) => showUserBIO(rec._id.value)}
      >
        View profile
      </li>
    );
  }

  let forWhat = "";
  if (builder.props.moduleParams) {
    if (builder.props.moduleParams.type) {
      forWhat = builder.props.moduleParams.type;
    }
  }

  let organizerId = "";
  if (builder.state.parentData) {
    if (builder.state.parentData.organizer) {
      organizerId = builder.state.parentData.organizer[0]._id;
    }
  }

  if (Fun.getURLPathValue(1) == "conference") {
    forWhat = "isHostOrAdmin";
  }

  if (UniFun.userIsEligible({ forWhat: forWhat, organizerId: organizerId })) {
    options.push(
      <li
        className="list-group-item p-2 pl-3 text-danger"
        onClick={(e) => removeUser(rec._id.value, builder, rec.fullName.value)}
      >
        Remove user
      </li>
    );
  }

  return (
    <React.Fragment>
      <span>{rec.profileImage.component}</span>
      {options.length > 0 ? (
        <span className="float-right mr-4 grid-controls mt-2">
          <i
            className="fas fa-ellipsis-h pointer"
            data-toggle="collapse"
            data-target={"#" + uid}
          ></i>

          <div className="position-absolute bg-white mt-2 collapse" id={uid}>
            <ul className="list-group " style={{ width: "200px" }}>
              {options}
            </ul>
          </div>
        </span>
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

const removeUser = function (data, parentBuilder, fullName) {
  let inputData = null;
  if (parentBuilder.props.moduleParams) {
    if (parentBuilder.props.moduleParams.type) {
      switch (parentBuilder.props.moduleParams.type) {
        case "spaces":
          inputData = {
            data: [
              {
                _id: parentBuilder.props.parentData._id,
                eventType: "spaces",
                participantId: data,
                fullName: fullName,
              },
            ],
          };
          break;
        case "meetings":
          inputData = {
            data: [
              {
                _id: parentBuilder.props.parentData._id,
                eventType: "meetings",
                participantId: data,
                fullName: fullName,
              },
            ],
          };
          break;

        case "streams":
          inputData = {
            data: [
              {
                _id: parentBuilder.props.parentData._id,
                eventType: "streams",
                participantId: data,
                fullName: fullName,
              },
            ],
          };
          break;

        case "tests":
          inputData = {
            data: [
              {
                _id: parentBuilder.props.parentData._id,
                eventType: "tests",
                participantId: data,
                fullName: fullName,
              },
            ],
          };
          break;

        case "trainings":
          inputData = {
            data: [
              {
                _id: parentBuilder.props.parentData._id,
                eventType: "trainings",
                participantId: data,
                fullName: fullName,
              },
            ],
          };
          break;
      }
    }
  }

  window["modal2"].setState({
    title: <h3>Remove Participant</h3>,
    size: "none",
    targetComponent: null,
    show: true,
    overflowY: "none",
    footer: UniFun.modalCommonFooter("modal2", "Remove", "btn-danger"),
    callback: function (args) {
      window["modal2"].setState({ show: false, overflowY: "auto" });
      parentBuilder.rerunComponent();
    },
    body: (
      <Builder
        module="uniparticipantsandfilesevent"
        view="removeUser"
        key={Fun.uuid()}
        inputData={inputData}
        ref={(refComponent) => {
          window["modal2body"] = refComponent;
        }}
      ></Builder>
    ),
  });
};

const showUserBIO = function (id, parentBuilder) {
  let builder = parentBuilder;
  let parameters = {
    replace: {
      id: id,
    },
  };

  let footerBtns = [
    <button
      type="button"
      className="btn btn-link border"
      onClick={() => {
        window["modal2"].setState({ show: false, overflowY: "auto" });
      }}
    >
      Cancel
    </button>,
  ];

  if (
    UniFun.userIsEligible({ forWhat: "default" }) ||
    localStorage.getItem("userid") == id
  ) {
    footerBtns.push(
      <button
        type="button"
        className="btn btn-primary border"
        onClick={() => {
          editUser(id, parentBuilder);
        }}
      >
        Edit
      </button>
    );
  }

  window["modal2"].setState({
    title: "",
    size: "xl",
    targetComponent: this,
    callback: function () {},
    footer: footerBtns,
    show: true,
    body: (
      <Builder
        module="uniusers"
        view="userBio"
        parameters={parameters}
        key={Fun.uuid()}
        ref={(refComponent) => {
          window["modal2body"] = refComponent;
        }}
      ></Builder>
    ),
  });
};

const editUser = function (id, parentBuilder) {
  let builder = parentBuilder;
  let parameters = {
    replace: {
      id: id,
    },
  };

  window["modal"].setState({
    title: (
      <React.Fragment>
        <h3 className="pl-3">Profile settings</h3>
      </React.Fragment>
    ),
    size: "lg",
    targetComponent: this,
    callback: function () {
      window["modal"].setState({ show: false, overflowY: "auto" });
      Fun.createNotification({
        type: "success",
        message: "Update update was successful!",
      });
      builder.rerunComponent();
    },
    footer: UniFun.modalCommonFooter(
      "modal",
      "Save",
      "",
      UniFun.userIsEligible({ forWhat: "edit-account", userId: id })
    ),
    show: true,
    body: (
      <Builder
        module="uniusers"
        view="detail"
        parameters={parameters}
        key={Fun.uuid()}
        ref={(refComponent) => {
          window["modalbody"] = refComponent;
        }}
      ></Builder>
    ),
  });
};

const addParticipant = function (data, parentBuilder, type) {
  let inputData = null;
  let view = "add";
  let modalTitle = "Add people and groups";
  if (parentBuilder.props.moduleParams) {
    if (parentBuilder.props.moduleParams.type) {
      switch (parentBuilder.props.moduleParams.type) {
        case "spaces":
          view = "addSpaceParticipants";
          inputData = {
            data: [
              { _id: parentBuilder.props.parentData._id, eventType: "spaces" },
            ],
          };
          break;
        case "meetings":
          view = "addAllParticipants";
          inputData = {
            data: [
              {
                _id: parentBuilder.props.parentData._id,
                eventType: "meetings",
              },
            ],
          };
          break;

        case "streams":
          inputData = {
            data: [
              {
                _id: parentBuilder.props.parentData._id,
                eventType: "streams",
              },
            ],
          };
          break;

        case "tests":
          view = "addAllParticipants";
          inputData = {
            data: [
              {
                _id: parentBuilder.props.parentData._id,
                eventType: "tests",
              },
            ],
          };
          break;

        case "trainings":
          inputData = {
            data: [
              {
                _id: parentBuilder.props.parentData._id,
                eventType: "trainings",
              },
            ],
          };
          break;
      }
    }
  }

  window["modal2"].setState({
    title: <h3>{modalTitle}</h3>,
    size: "lg",
    targetComponent: null,
    show: true,
    overflowY: "unset",
    footer: UniFun.modalCommonFooter("modal2", "Add people"),
    callback: function (args) {
      window["modal2"].setState({ show: false, overflowY: "auto" });
      parentBuilder.rerunComponent();
    },
    body: (
      <Builder
        module="uniparticipantsandfilesevent"
        view={view}
        key={Fun.uuid()}
        inputData={inputData}
        ref={(refComponent) => {
          window["modal2body"] = refComponent;
        }}
      ></Builder>
    ),
  });
};

const filesCardTemplateTop = function () {
  let data = this.state.data;
  let builder = this.props.builder;
  let addFileButton = [];
  let organizerId = "";
  let participants = [];
  let organizers = [];

  if (this.state.rawData.data) {
    if (this.state.rawData.data[0]) {
      if (this.state.rawData.data[0].organizer) {
        if (this.state.rawData.data[0].organizer._id) {
          organizerId = this.state.rawData.data[0].organizer._id;
          organizers = this.state.rawData.data[0].organizers;
        }
      }
      if (this.state.rawData.data[0].participants) {
        participants = this.state.rawData.data[0].participants;
      }
    }
  }

  if (builder.state.moduleParams) {
    if (builder.state.moduleParams.type) {
      if (builder.state.moduleParams.type == "meetings") {
        if (
          UniFun.userIsEligible({
            forWhat: "meeting.uploadfiles",
            organizerId: organizerId,
            participants: participants,
            organizers: organizers,
          })
        ) {
          addFileButton = (
            <button
              type="button"
              className="btn btn-primary ml-3 bg-purple"
              onClick={this.onTargetClick}
            >
              Upload File
            </button>
          );
        }
      }
    }
  }
  return (
    <React.Fragment>
      <div className="row">
        <div className="col-6 pt-2 pl-4">
          <div></div>
          <span className="text-dark ">There are {data.length} files</span>
        </div>
        <div className="col-6 text-right p-2">{addFileButton}</div>
      </div>
    </React.Fragment>
  );
};

const filesDeleteFile = function (inputData, parentBuilder) {
  let builder = parentBuilder;

  window["modal2"].setState({
    title: <h3>Delete file?</h3>,
    size: "lg",
    targetComponent: this,
    show: true,
    callback: function () {
      builder.rerunComponent();
      window["modal2"].setState({ show: false, overflowY: "auto" });
    },
    footer: UniFun.modalCommonFooter("modal2", "Delete", "btn-danger"),
    body: (
      <Builder
        module="uniparticipantsandfilesevent"
        view="filedelete"
        inputData={{
          data: [
            {
              _id: inputData.parentId.value,
              files: [
                {
                  _id: inputData._id.value,
                  title: inputData.title.value,
                },
              ],
            },
          ],
        }}
        ref={(refComponent) => {
          window["modal2body"] = refComponent;
        }}
      ></Builder>
    ),
  });
};

const cellTemplatesTitle = function (data, rec, obj) {
  let uid = Fun.uuid();

  let builder = obj.props.builder;

  let icon = [];

  let mimetype = rec.mime.value;

  icon = UniFun.mimeTypeIcon(mimetype);
  let isAudioRecording = false;
  let isVideoRecording = false;

  if (rec.recording.value) {
    if (mimetype.startsWith("audio")) {
      icon = UniFun.mimeTypeIcon("audiorecording");
      isAudioRecording = true;
    } else {
      icon = UniFun.mimeTypeIcon("recording");
      isVideoRecording = true;
    }
  }

  let organizerId = "";
  let organizers = [];
  if (obj.props.builder.state.parentData) {
    if (obj.props.builder.state.parentData.organizer) {
      organizerId = obj.props.builder.state.parentData.organizer[0]._id;

      organizers = obj.props.builder.state.parentData.organizersRaw;
    }
  }

  let fileChoices = [
    <li
      className="list-group-item p-2 pl-3 pointer"
      onClick={(e) =>
        UniFun.readingsPreviewFile(
          {
            title: rec.title.value,
            mimetype: rec.mime.value,
            isAudioRecording: isAudioRecording,
            isVideoRecording: isVideoRecording,
            fileurl: window["baseurl"](
              "/agenda-file/data/" +
                rec._id.value +
                "?type=preview&jwt=" +
                localStorage.getItem("jwt")
            ),
            filedownloadurl: window["baseurl"](
              "/agenda-file/data/" +
                rec._id.value +
                "?type=download&jwt=" +
                localStorage.getItem("jwt")
            ),
          },
          null
        )
      }
    >
      Preview
    </li>,
    <li className="list-group-item p-2 pl-3 pointer">
      <a
        href={window["baseurl"](
          "/agenda-file/data/" +
            rec._id.value +
            "?type=download&jwt=" +
            localStorage.getItem("jwt")
        )}
        target="new"
      >
        Download
      </a>
    </li>,
  ];

  if (
    UniFun.userIsEligible({
      forWhat: "meeting.files",
      organizerId: organizerId,
      createdBy: rec.createdBy.value,
      organizers: organizers,
    })
  ) {
    fileChoices.push(
      <li
        className="list-group-item p-2  pl-3 text-danger  pointer"
        onClick={(e) => filesDeleteFile(rec, builder)}
      >
        Delete
      </li>
    );
  }

  let linkTitle = (
    <span
      className="pointer"
      title={rec.title.value}
      onClick={(e) =>
        UniFun.readingsPreviewFile(
          {
            title: rec.title.value,
            mimetype: rec.mime.value,
            isAudioRecording: isAudioRecording,
            isVideoRecording: isVideoRecording,
            fileurl: window["baseurl"](
              "/agenda-file/data/" +
                rec._id.value +
                "?type=preview&jwt=" +
                localStorage.getItem("jwt")
            ),
            filedownloadurl: window["baseurl"](
              "/agenda-file/data/" +
                rec._id.value +
                "?type=download&jwt=" +
                localStorage.getItem("jwt")
            ),
          },
          null
        )
      }
    >
      <img
        src={icon}
        className="mr-2"
        style={{ width: "21px", paddingBottom: "2px" }}
      ></img>{" "}
      {UniFun.substring(rec.title.value, 0, 55)}
    </span>
  );

  return (
    <React.Fragment>
      {linkTitle}
      <span className="float-right mr-4 grid-controls">
        <React.Fragment>
          {/* {favicon} */}
          <a
            href={window["baseurl"](
              "/agenda-file/data/" +
                rec._id.value +
                "?type=download&jwt=" +
                localStorage.getItem("jwt")
            )}
            target="new"
            title="Download"
          >
            <img
              src="/uni/svg/download.svg"
              style={{ width: "14px" }}
              className="ml-2 "
            ></img>
          </a>

          <i
            className="fas fa-ellipsis-h pointer ml-2"
            data-toggle="collapse"
            data-target={"#" + uid}
          ></i>
          <div
            className="position-absolute bg-white ml-4 mt-2 collapse"
            id={uid}
          >
            <ul className="list-group " style={{ width: "200px" }}>
              {fileChoices}
            </ul>
          </div>
        </React.Fragment>
      </span>
    </React.Fragment>
  );
};

const UniParticipantsAndFilesEvent = {
  auth: "",
  views: {
    files: {
      auth: "",
      apis: {
        view: {
          url: "{path}",
          parameters: {
            replace: {},
            headers: {},
          },
          formatter: function (args) {
            return UniParticipantsAndFilesEventFormatter(args);
          },
        },
      },
      template: "foldergrid",
      templateConfig: {
        colsTemplateCount: 12,
        includeProperties: ["title", "updatedAt", "size"],
        excludeProperties: [],
        events: {
          onDrop: function (files) {
            let parent = this.state.rawData.data[0]._id;

            UniFun.fileProgress({
              control: window["fileupload"],
              files: files,
              filesName: "files",
              // parent: parent,
              showModalOnDelete: false,
              builder: this.props.builder,
              rerunComponent: true,
              url: "/agenda-files/meeting/" + parent,
              // url: "/meeting/" + parent + "/files",
              type: "meeting",
            });
          },
        },
        gridConfig: {
          emptyFolder: filesEmptyFolder,
          headers: {
            title: {
              attributes: {
                className: "w-50",
              },
            },
            updatedAt: {
              attributes: {
                className: "d-none d-md-table-cell",
              },
            },
            size: {
              attributes: {
                className: "d-none d-md-table-cell",
              },
            },
          },
          cell: {
            updatedAt: {
              attributes: {
                className: "d-none d-md-table-cell",
              },
            },
            size: {
              attributes: {
                className: "d-none d-md-table-cell",
              },
            },
          },
          cellTemplates: {
            title: cellTemplatesTitle,
          },
        },

        cardTemplateTop: filesCardTemplateTop,
      },
      pk: "_id",
      properties: UniParticipantsAndFilesEventProperties({ editable: false }),
    },
    trainingModules: {
      auth: "",
      apis: {
        view: {
          url: "/training/{id}/modules",
          parameters: {
            replace: {
              id: function () {
                return Fun.getURLPathValue(3);
              },
            },
            headers: {},
          },
          formatter: function (args) {
            return UniTrainingEventsModulesFormatter(args);
          },
        },
      },
      template: "grid",
      templateConfig: {
        colsTemplateCount: 12,
        includeProperties: ["title", "sessions"],
        excludeProperties: [],
        headerTitle: "Training title",
        headerTitleIcon: "/uni/svg/trainingtemp.svg",

        events: {
          onSubmit: function (e) {
            e.preventDefault();
          },
        },
        cardTemplateTop: function () {
          let data = this.state.data;

          return (
            <div className="row mb-4">
              <div className="col pt-2 pl-4">
                <span className="text-dark ">
                  <strong>Modules ({data.length})</strong>
                </span>
              </div>
            </div>
          );
        },
        gridConfig: {
          headers: {
            title: {
              attributes: {
                className: "w-75",
              },
            },
            updatedAt: {
              attributes: {
                className: "d-none d-md-table-cell",
              },
            },
            sessions: {
              attributes: {
                className: "d-none d-md-table-cell",
              },
            },
          },
          cell: {
            updatedAt: {
              attributes: {
                className: "d-none d-md-table-cell",
              },
            },
            sessions: {
              attributes: {
                className: "d-none d-md-table-cell",
              },
            },
          },
          cellTemplates: {
            title: function (data, rec) {
              const icon = UniFun.mimeTypeIcon("folder");
              const url =
                "/event/training/" +
                Fun.getURLPathValue(3) +
                "/files/modules/" +
                rec._id.value;
              return (
                <Link className="pointer" to={url} title={rec.title.value}>
                  <img
                    src={icon}
                    className="mr-2"
                    style={{ width: "21px", paddingBottom: "2px" }}
                  ></img>{" "}
                  {UniFun.substring(rec.title.value, 0, 55)}
                </Link>
              );
            },
            sessions: function (data, rec) {
              return rec.sessions.value + " sessions";
            },
          },
        },
      },
      pk: "_id",
      properties: trainingModulesProperties({ editable: false }),
    },
    trainingSessions: {
      auth: "",
      apis: {
        view: {
          url: "/module/{moduleid}/sessions ",
          parameters: {
            replace: {
              moduleid: function () {
                return Fun.getURLPathValue(6);
              },
            },
            headers: {},
          },
          formatter: function (args) {
            return UniTrainingEventsModulesFormatter(args);
          },
        },
      },
      template: "grid",
      templateConfig: {
        colsTemplateCount: 12,
        includeProperties: ["title", "files"],
        excludeProperties: [],
        headerTitle: "Training title",
        headerTitleIcon: "/uni/svg/trainingtemp.svg",

        events: {
          onSubmit: function (e) {
            e.preventDefault();
          },
        },
        cardTemplateTop: function () {
          let data = this.state.data;

          return (
            <div className="row mb-4">
              <div className="col pt-2 pl-4">
                {data[0].breadCrumb.component}
              </div>
            </div>
          );
        },
        gridConfig: {
          headers: {
            title: {
              attributes: {
                className: "w-75",
              },
            },
            updatedAt: {
              attributes: {
                className: "d-none d-md-table-cell",
              },
            },
            files: {
              attributes: {
                className: "d-none d-md-table-cell",
              },
            },
          },
          cell: {
            updatedAt: {
              attributes: {
                className: "d-none d-md-table-cell",
              },
            },
            files: {
              attributes: {
                className: "d-none d-md-table-cell",
              },
            },
          },
          cellTemplates: {
            title: function (data, rec) {
              const icon = UniFun.mimeTypeIcon("folder");
              const url =
                "/event/training/" +
                Fun.getURLPathValue(3) +
                "/files/modules/" +
                Fun.getURLPathValue(6) +
                "/sessions/" +
                rec._id.value;
              return (
                <Link className="pointer" to={url} title={rec.title.value}>
                  <img
                    src={icon}
                    className="mr-2"
                    style={{ width: "21px", paddingBottom: "2px" }}
                  ></img>{" "}
                  {UniFun.substring(rec.title.value, 0, 55)}
                </Link>
              );
            },
            files: function (data, rec) {
              return rec.files.value + " files";
            },
          },
        },
      },
      pk: "_id",
      properties: trainingModulesProperties({ editable: false }),
    },
    filedelete: {
      auth: "",
      apis: {
        view: {
          url: "/file/{_id}",
          parameters: {
            headers: {},
            replace: {},
          },
          formatter: function (data) {
            return UniParticipantsAndFilesEventFormatter(data);
          },
        },
        delete: {
          url: "/agenda-file/{_id}/meeting",
          parameters: {
            headers: {},
            replace: {},
          },
          formatter: function (data) {
            return UniParticipantsAndFilesEventFormatter(data);
          },
        },
      },
      template: "form",
      templateConfig: {
        useCard: false,
        colsTemplateCount: 12,
        includeProperties: ["_id", "parentId"],
        excludeProperties: [],
        events: {
          onSubmit: function (e) {
            let submittedData = Fun.getSubmitJSON(e);

            let fileTitle = this.state.data[0].title.value;
            let builder = this.props.builder;

            Fun.update(e, {
              url: builder.state.module.apis.delete,
              method: "DELETE",
              parameters: {
                headers: {
                  "content-type": "application/json",
                },
                replace: {
                  _id: submittedData["_id"],
                },
              },
              callback: function (
                submittedData,
                status,
                responsedata,
                responsestatus
              ) {
                if (responsestatus == "200") {
                  if (window["modal2"].state.callback) {
                    window["modal2"].state.callback(submittedData);
                  }
                  Fun.createNotification({
                    message: "'" + fileTitle + "' has been deleted",
                    type: "success",
                  });
                }
              },
              submittedData: JSON.stringify({
                meetingId: submittedData["parentId"],
              }),
            });
          },
        },
        templateTop: function () {
          let data = this.state.data[0];
          return (
            <div className=" p-4">
              <p>
                Are you sure you want to delete file "
                <strong>{data.title.value}</strong>"?<br></br>
                <br></br> This action will delete the file permanently.
              </p>
            </div>
          );
        },
      },
      pk: "_id",
      properties: UniParticipantsAndFilesEventProperties({ editable: false }),
    },
    participantsSpaces: {
      auth: "",
      apis: {
        view: {
          url: "{path}",
          parameters: {
            headers: { "content-type": "application/json" },
          },
          formatter: function (data, builder) {
            return UniParticipantsSpaceFormatter(data, builder);
          },
        },
      },
      template: "grid",
      templateConfig: {
        events: {},
        colsTemplateCount: 12,
        includeProperties: ["fullName", "email"],
        gridConfig: {
          emptyFolder: function () {
            let parentBuilder = this.props.builder;
            let data = this.state.data;
            return (
              <div className="row mt-4 " style={{ height: "500px" }}>
                <div className="col-12 text-center">
                  <h5>
                    <strong>There are no participants </strong>
                  </h5>

                  <p>
                    {UniFun.userIsEligible({ forWhat: "default" }) ? (
                      <button
                        type="button"
                        className="btn btn-link bg-navy-blue"
                        onClick={(e) => addParticipant(data, parentBuilder)}
                      >
                        <strong>Add participants</strong>
                      </button>
                    ) : (
                      ""
                    )}
                  </p>
                </div>
              </div>
            );
          },
          headers: {
            fullName: {
              attributes: {
                className: "w-50",
              },
            },
            email: {
              attributes: {
                className: "d-none d-md-table-cell",
              },
            },
          },
          cell: {
            email: {
              attributes: {
                className: "d-none d-md-table-cell",
              },
            },
          },
          cellTemplates: {
            fullName: fullNameCell,
          },
        },
        cardTemplateTop: function () {
          let data = this.state.data;
          let parentBuilder = this.props.builder;

          let forWhat = "meeting";

          if (parentBuilder.state.view == "participantsSpaces") {
            forWhat = "default";
          }

          return (
            <div className="row">
              <div className="col-6 pt-2 ">
                <span className="text-dark ">
                  There are {data.length} participants
                </span>
              </div>
              <div className="col-6 text-right p-2">
                {UniFun.userIsEligible({ forWhat: forWhat }) ? (
                  <button
                    type="button"
                    className="btn btn-primary bg-navy-blue"
                    onClick={(e) => addParticipant(data, parentBuilder)}
                  >
                    Add participants
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>
          );
        },
      },
      pk: "id",
      properties: UniParticipantsProperties({
        editable: false,
      }),
    },
    participants: {
      auth: "",
      apis: {
        view: {
          url: "{path}",
          parameters: {
            headers: { "content-type": "application/json" },
          },
          formatter: function (data, builder) {
            return UniParticipantsFormatter(data, builder);
          },
        },
      },
      template: "grid",
      templateConfig: {
        events: {},
        headerTitle: "Organization",
        headerTitleIcon: "/uni/svg/usersmain.svg",
        colsTemplateCount: 12,
        includeProperties: ["fullName", "email"],
        gridConfig: {
          emptyFolder: function () {
            let parentBuilder = this.props.builder;
            let data = this.state.data;

            let eligibleForWhat = "default";
            let organizerId = "";
            let organizers = [];
            if (parentBuilder.state.parentData) {
              if (parentBuilder.state.parentData.organizer) {
                organizerId = parentBuilder.state.parentData.organizer[0]._id;
                organizers = parentBuilder.state.parentData.organizersRaw;
              }
            }
            if (parentBuilder.state.moduleParams) {
              if (parentBuilder.state.moduleParams.type) {
                eligibleForWhat = parentBuilder.state.moduleParams.type;
              }
            }

            if (Fun.getURLPathValue(1) == "conference") {
              eligibleForWhat = "isHostOrAdmin";
            }

            return (
              <div className="row mt-4 " style={{ height: "500px" }}>
                <div className="col-12 text-center">
                  <h5>
                    <strong>There are no participants </strong>
                  </h5>

                  <p>
                    {UniFun.userIsEligible({
                      forWhat: eligibleForWhat,
                      organizerId: organizerId,
                      organizers: organizers,
                    }) ? (
                      <button
                        type="button"
                        className="btn btn-link bg-navy-blue"
                        onClick={(e) => addParticipant(data, parentBuilder)}
                      >
                        <strong>Add participants</strong>
                      </button>
                    ) : (
                      ""
                    )}
                  </p>
                </div>
              </div>
            );
          },
          headers: {
            fullName: {
              attributes: {
                className: "w-50",
              },
            },
            email: {
              attributes: {
                className: "d-none d-md-table-cell",
              },
            },
          },
          cell: {
            email: {
              attributes: {
                className: "d-none d-md-table-cell",
              },
            },
          },
          cellTemplates: {
            fullName: fullNameCell,
          },
        },
        cardTemplateTop: function () {
          let data = this.state.data;
          let parentBuilder = this.props.builder;

          let eligibleForWhat = "default";
          let organizerId = "";
          let organizers = [];
          if (parentBuilder.state.parentData) {
            if (parentBuilder.state.parentData.organizer) {
              organizerId = parentBuilder.state.parentData.organizer[0]._id;
              organizers = parentBuilder.state.parentData.organizersRaw;
            }
          }
          if (parentBuilder.state.moduleParams) {
            if (parentBuilder.state.moduleParams.type) {
              eligibleForWhat = parentBuilder.state.moduleParams.type;
            }
          }

          if (Fun.getURLPathValue(1) == "conference") {
            eligibleForWhat = "isHostOrAdmin";
          }

          return (
            <div className="row">
              <div className="col-6 pt-2 pl-4">
                <span className="text-dark ">
                  There are {data.length} participants
                </span>
              </div>
              <div className="col-6 text-right p-2">
                {UniFun.userIsEligible({
                  forWhat: eligibleForWhat,
                  organizerId: organizerId,
                  organizers: organizers,
                }) ? (
                  <button
                    type="button"
                    className="btn btn-primary bg-navy-blue"
                    onClick={(e) => addParticipant(data, parentBuilder)}
                  >
                    Add participants
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>
          );
        },
      },
      pk: "id",
      properties: UniParticipantsProperties({ editable: false }),
    },
    removeUser: {
      auth: "",
      apis: {
        view: {
          url: "{path}",
          parameters: {
            headers: {},
            replace: {},
          },
          formatter: function (data, builder) {
            let output = [];
            if (data) {
              if (data.data) {
                data.data.map((itm, idx) => {
                  output.push(itm);
                });
              }
            }

            return output;
          },
        },
        removeSpaces: {
          url: "/space/{id}/participants",
          parameters: {
            headers: { "content-type": "application/json" },
          },
          formatter: function (data, builder) {
            return data;
          },
        },
        removeMeetings: {
          url: "/meeting/{id}/participants",
          parameters: {
            headers: { "content-type": "application/json" },
          },
          formatter: function (data, builder) {
            return data;
          },
        },
        removeStreams: {
          url: "/stream/{id}/participants",
          parameters: {
            headers: { "content-type": "application/json" },
          },
          formatter: function (data, builder) {
            return data;
          },
        },
        removeTests: {
          url: "/event/exam/{id}/participants",
          parameters: {
            headers: { "content-type": "application/json" },
          },
          formatter: function (data, builder) {
            return data;
          },
        },
        removeTraining: {
          url: "/event/training/{id}/participants",
          parameters: {
            headers: { "content-type": "application/json" },
          },
          formatter: function (data, builder) {
            return data;
          },
        },
      },
      template: "form",
      templateConfig: {
        useCard: false,
        colsTemplateCount: 12,
        includeProperties: ["_id", "participantId", "eventType"],
        events: {
          onSubmit: function (e) {
            let submittedData = Fun.getSubmitJSON(e);

            if (submittedData["eventType"]) {
              let jsonData = {};

              let url = "";
              let method = "DELETE";
              try {
                switch (submittedData["eventType"]) {
                  case "spaces":
                    url = this.props.builder.state.module.apis.removeSpaces;
                    jsonData["participants"] = [submittedData["participantId"]];

                    break;

                  case "meetings":
                    url = this.props.builder.state.module.apis.removeMeetings;

                    jsonData["meetingParticipants"] = [
                      submittedData["participantId"],
                    ];
                    delete jsonData["participants"];
                    break;

                  case "streams":
                    url = this.props.builder.state.module.apis.removeStreams;

                    jsonData["streamParticipants"] = [
                      submittedData["participantId"],
                    ];
                    delete jsonData["participants"];
                    break;

                  case "trainings":
                    url = this.props.builder.state.module.apis.removeTraining;

                    jsonData["trainingParticipants"] = [
                      submittedData["participantId"],
                    ];
                    delete jsonData["participants"];
                    break;

                  case "tests":
                    url = this.props.builder.state.module.apis.removeTests;

                    jsonData["examParticipants"] = [
                      submittedData["participantId"],
                    ];
                    delete jsonData["participants"];
                    break;
                }

                delete jsonData["eventType"];
              } catch (e) {
                console.log(e);
              }

              Fun.update(e, {
                url: url,
                method: method,
                parameters: {
                  headers: {
                    "content-type": "application/json",
                  },
                  replace: {
                    id: submittedData["_id"],
                  },
                },
                callback: function (args) {
                  if (window["modal2"].state.callback) {
                    window["modal2"].state.callback(args);
                  } else {
                    if (window["modal2"].state.targetComponent) {
                      window["modal2"].state.targetComponent.setState({
                        data: args,
                      });
                    }
                  }
                },
                submittedData: JSON.stringify(jsonData),
              });
            }
          },
        },
        templateTop: function () {
          let data = this.state.data[0];
          return (
            <div className=" p-4">
              <p>
                Are you sure you want to remove "
                <strong>{data.fullName.component}</strong>" from participants?
              </p>
            </div>
          );
        },
      },
      pk: "_id",
      properties: UniParticipantsProperties({ editable: false }),
    },
    add: {
      auth: "",
      apis: {
        view: {
          data: { data: [{}] },
          parameters: {
            headers: { "content-type": "application/json" },
          },
          formatter: function (data, builder) {
            let output = [];
            if (data) {
              if (data.data) {
                data.data.map((itm, idx) => {
                  output.push(itm);
                });
              }
            }

            return output;
          },
        },
        saveSpaces: {
          url: "/space/{id}/participants",
          parameters: {
            headers: { "content-type": "application/json" },
          },
          formatter: function (data, builder) {
            return data;
          },
        },
        saveMeetings: {
          url: "/meeting/{id}/participants",
          parameters: {
            headers: { "content-type": "application/json" },
          },
          formatter: function (data, builder) {
            return data;
          },
        },
        saveStreams: {
          url: "/stream/{id}/participants",
          parameters: {
            headers: { "content-type": "application/json" },
          },
          formatter: function (data, builder) {
            return data;
          },
        },
        saveTests: {
          url: "/event/exam/{id}/participants",
          parameters: {
            headers: { "content-type": "application/json" },
          },
          formatter: function (data, builder) {
            return data;
          },
        },
        saveTraining: {
          url: "/event/training/{id}/participants",
          parameters: {
            headers: { "content-type": "application/json" },
          },
          formatter: function (data, builder) {
            return data;
          },
        },
      },
      template: "form",
      templateConfig: {
        events: {
          onSubmit: function (e) {
            let submittedData = Fun.getSubmitJSON(e);

            if (submittedData["eventType"]) {
              let jsonData = {
                participants: UniFun.participantsJSONData(
                  JSON.parse(submittedData["participants"])
                ),
              };

              let url = "";
              let method = "POST";

              switch (submittedData["eventType"]) {
                case "spaces":
                  url = this.props.builder.state.module.apis.saveSpaces;
                  break;

                case "meetings":
                  url = this.props.builder.state.module.apis.saveMeetings;

                  jsonData["meetingParticipants"] = jsonData["participants"];
                  delete jsonData["participants"];
                  break;

                case "streams":
                  url = this.props.builder.state.module.apis.saveStreams;

                  jsonData["streamParticipants"] = jsonData["participants"];
                  delete jsonData["participants"];
                  break;

                case "trainings":
                  url = this.props.builder.state.module.apis.saveTraining;

                  jsonData["trainingParticipants"] = jsonData["participants"];
                  delete jsonData["participants"];
                  break;

                case "tests":
                  url = this.props.builder.state.module.apis.saveTests;

                  jsonData["examParticipants"] = jsonData["participants"];
                  delete jsonData["participants"];
                  break;
              }

              delete jsonData["eventType"];

              Fun.update(e, {
                url: url,
                method: method,
                parameters: {
                  headers: {
                    "content-type": "application/json",
                  },
                  replace: {
                    id: submittedData["_id"],
                  },
                },
                callback: function (
                  args,
                  status,
                  responsedata,
                  responsestatus
                ) {
                  if (responsestatus == "200") {
                    if (window["modal2"].state.callback) {
                      window["modal2"].state.callback(args);
                    } else {
                      if (window["modal2"].state.targetComponent) {
                        window["modal2"].state.targetComponent.setState({
                          data: args,
                        });
                      }
                    }
                    Fun.createNotification({
                      message: args.message,
                      type: "success",
                    });
                  } else {
                    if (responsedata.data.exceptions) {
                      responsedata.data.exceptions.map((itm, idx) => {
                        Fun.createNotification({
                          message: itm.errorDescription,
                          type: "danger",
                        });
                      });
                    }
                  }
                },
                submittedData: JSON.stringify(jsonData),
              });
            }
          },
        },
        colsTemplateCount: 12,
        includeProperties: ["_id", "participants", "eventType"],
      },
      pk: "_id",
      properties: UniParticipantsProperties({ editable: true }),
    },
    addAllParticipants: {
      auth: "",
      apis: {
        view: {
          data: { data: [{}] },
          parameters: {
            headers: { "content-type": "application/json" },
          },
          formatter: function (data, builder) {
            let output = [];
            if (data) {
              if (data.data) {
                data.data.map((itm, idx) => {
                  output.push(itm);
                });
              }
            }

            return output;
          },
        },
        saveSpaces: {
          url: "/space/{id}/participants",
          parameters: {
            headers: { "content-type": "application/json" },
          },
          formatter: function (data, builder) {
            return data;
          },
        },
        saveMeetings: {
          url: "/meeting/{id}/participants",
          parameters: {
            headers: { "content-type": "application/json" },
          },
          formatter: function (data, builder) {
            return data;
          },
        },
        saveStreams: {
          url: "/stream/{id}/participants",
          parameters: {
            headers: { "content-type": "application/json" },
          },
          formatter: function (data, builder) {
            return data;
          },
        },
        saveTests: {
          url: "/event/exam/{id}/participants",
          parameters: {
            headers: { "content-type": "application/json" },
          },
          formatter: function (data, builder) {
            return data;
          },
        },
        saveTraining: {
          url: "/event/training/{id}/participants",
          parameters: {
            headers: { "content-type": "application/json" },
          },
          formatter: function (data, builder) {
            return data;
          },
        },
      },
      template: "form",
      templateConfig: {
        events: {
          onSubmit: function (e) {
            let submittedData = Fun.getSubmitJSON(e);

            if (submittedData["eventType"]) {
              let jsonData = {
                participants: UniFun.participantsJSONData(
                  JSON.parse(submittedData["participants"])
                ),
              };

              let url = "";
              let method = "POST";

              switch (submittedData["eventType"]) {
                case "spaces":
                  url = this.props.builder.state.module.apis.saveSpaces;
                  break;

                case "meetings":
                  url = this.props.builder.state.module.apis.saveMeetings;

                  jsonData["meetingParticipants"] = jsonData["participants"];
                  delete jsonData["participants"];
                  break;

                case "streams":
                  url = this.props.builder.state.module.apis.saveStreams;

                  jsonData["streamParticipants"] = jsonData["participants"];
                  delete jsonData["participants"];
                  break;

                case "trainings":
                  url = this.props.builder.state.module.apis.saveTraining;

                  jsonData["trainingParticipants"] = jsonData["participants"];
                  delete jsonData["participants"];
                  break;

                case "tests":
                  url = this.props.builder.state.module.apis.saveTests;

                  jsonData["examParticipants"] = jsonData["participants"];
                  delete jsonData["participants"];
                  break;
              }

              delete jsonData["eventType"];

              Fun.update(e, {
                url: url,
                method: method,
                parameters: {
                  headers: {
                    "content-type": "application/json",
                  },
                  replace: {
                    id: submittedData["_id"],
                  },
                },
                callback: function (
                  args,
                  status,
                  responsedata,
                  responsestatus
                ) {
                  if (responsestatus == "200") {
                    if (window["modal2"].state.callback) {
                      window["modal2"].state.callback(args);
                    } else {
                      if (window["modal2"].state.targetComponent) {
                        window["modal2"].state.targetComponent.setState({
                          data: args,
                        });
                      }
                    }
                    Fun.createNotification({
                      message: args.message,
                      type: "success",
                    });
                  } else {
                    if (responsedata.data.exceptions) {
                      responsedata.data.exceptions.map((itm, idx) => {
                        Fun.createNotification({
                          message: itm.errorDescription,
                          type: "danger",
                        });
                      });
                    }
                  }
                },
                submittedData: JSON.stringify(jsonData),
              });
            }
          },
        },
        colsTemplateCount: 12,
        includeProperties: ["_id", "participants", "eventType"],
      },
      pk: "_id",
      properties: UniParticipantsProperties({
        editable: true,
        participantsType: "all",
      }),
    },
    addSpaceParticipants: {
      auth: "",
      apis: {
        view: {
          data: { data: [{}] },
          parameters: {
            headers: { "content-type": "application/json" },
          },
          formatter: function (data, builder) {
            let output = [];
            if (data) {
              if (data.data) {
                data.data.map((itm, idx) => {
                  output.push(itm);
                });
              }
            }

            return output;
          },
        },
        saveSpaces: {
          url: "/space/{id}/participants",
          parameters: {
            headers: { "content-type": "application/json" },
          },
          formatter: function (data, builder) {
            return data;
          },
        },
      },
      template: "form",
      templateConfig: {
        cardTemplateBottom: function () {
          return (
            <span className="text-dark">
              To add colleagues who are not on Hapeiron, you'll need to invite
              them first.
            </span>
          );
        },
        events: {
          onSubmit: function (e) {
            let submittedData = Fun.getSubmitJSON(e);

            if (submittedData["eventType"]) {
              let jsonData = {
                participants: UniFun.participantsJSONData(
                  JSON.parse(submittedData["participants"])
                ),
              };

              let url = "";
              let method = "POST";

              switch (submittedData["eventType"]) {
                case "spaces":
                  url = this.props.builder.state.module.apis.saveSpaces;
                  break;
              }

              delete jsonData["eventType"];

              Fun.update(e, {
                url: url,
                method: method,
                parameters: {
                  headers: {
                    "content-type": "application/json",
                  },
                  replace: {
                    id: submittedData["_id"],
                  },
                },
                callback: function (
                  args,
                  status,
                  responsedata,
                  responsestatus
                ) {
                  if (responsestatus == "200") {
                    if (window["modal2"].state.callback) {
                      window["modal2"].state.callback(args);
                    } else {
                      if (window["modal2"].state.targetComponent) {
                        window["modal2"].state.targetComponent.setState({
                          data: args,
                        });
                      }
                    }
                    Fun.createNotification({
                      message: args.message,
                      type: "success",
                    });
                  } else {
                    if (responsedata.data.exceptions) {
                      responsedata.data.exceptions.map((itm, idx) => {
                        Fun.createNotification({
                          message: itm.errorDescription,
                          type: "danger",
                        });
                      });
                    }
                  }
                },
                submittedData: JSON.stringify(jsonData),
              });
            }
          },
        },
        colsTemplateCount: 12,
        includeProperties: ["_id", "participants", "eventType"],
      },
      pk: "_id",
      properties: UniParticipantsProperties({
        editable: true,
        participantsType: "all",
        type: "space",
      }),
    },
  },
};

function UniTrainingEventsModulesFormatter(data) {
  let formattedData = [];
  if (data) {
    if (data.data) {
      data.data.map((itm, idx) => {
        let formattedRecord = {};
        try {
          formattedRecord["_id"] = itm.moduleId;
          if (itm.sessionId) {
            formattedRecord["_id"] = itm.sessionId;
          }
          formattedRecord["title"] = itm.title;
          formattedRecord["modified"] = itm.updatedAt;
          formattedRecord["sessions"] = itm.sessions;
          formattedRecord["files"] = itm.files;
          formattedRecord["breadCrumb"] = JSON.stringify(itm.breadCrumb);
        } catch (e) {
          console.log(e);
        }
        formattedData.push(formattedRecord);
      });
    }
  }

  return formattedData;
}

function UniParticipantsSpaceFormatter(data) {
  let formattedData = [];
  if (data) {
    if (data.data) {
      data.data.map((itm, idx) => {
        let formattedRecord = {};

        formattedRecord["_id"] = itm._id;
        formattedRecord["firstName"] = itm.firstName;
        formattedRecord["lastName"] = itm.lastName;
        formattedRecord["fullName"] = itm.firstName + " " + itm.lastName;
        formattedRecord["profileImage"] = itm.profileImage;
        formattedRecord["email"] = itm.email;
        formattedRecord["hasPrivateInfo"] = itm.hasPrivateInfo
          ? itm.hasPrivateInfo
          : "false";
        formattedData.push(formattedRecord);
      });
    }
  }
  return formattedData;
}

function UniParticipantsFormatter(data) {
  let formattedData = [];
  if (data) {
    if (data.data) {
      data.data.map((ditm, idx) => {
        let participants = ditm["participants"];

        if (ditm["guestUsers"]) {
          participants = ditm["participants"].concat(ditm["guestUsers"]);
        }

        participants?.map((itm, fileidx) => {
          let formattedRecord = {};

          let initialsName = "";

          try {
            initialsName =
              itm.firstName.charAt(0).toUpperCase() +
              itm.lastName.charAt(0).toUpperCase();
          } catch (e) {}
          if (itm._id) {
            formattedRecord["_id"] = itm._id;
            formattedRecord["participants"] = itm._id;
            formattedRecord["participantId"] = itm.participantId
              ? itm.participantId
              : itm._id;
            formattedRecord["firstName"] = itm.firstName;
            formattedRecord["lastName"] = itm.lastName;
            formattedRecord["eventType"] = itm.eventType;
            formattedRecord["fullName"] = itm.firstName + " " + itm.lastName;
            formattedRecord["roles"] = itm.roles;
            formattedRecord["profileImage"] = itm.profileImage;
            formattedRecord["email"] = itm.email;
            formattedRecord["hasPrivateInfo"] = itm.hasPrivateInfo
              ? itm.hasPrivateInfo
              : "false";
          } else {
            formattedRecord["_id"] = itm;
            formattedRecord["participants"] = "guest";
            formattedRecord["eventType"] = itm.eventType;
            formattedRecord["fullName"] = itm + " - Guest";
            formattedRecord["roles"] = null;
            formattedRecord["profileImage"] = "";
            formattedRecord["email"] = itm;
            formattedRecord["hasPrivateInfo"] = "true";
          }
          formattedData.push(formattedRecord);
        });
      });
    }
  }
  return formattedData;
}

function trainingModulesProperties(args) {
  let editable = false;
  return {
    _id: {
      alias: "_id",
      control: "hidden",
      editable: editable,
      label: "",
      attributes: {
        className: "form-control",
      },
    },
    title: {
      alias: "title",
      control: "text",
      editable: editable,
      label: "Name",
      attributes: {
        className: "form-control",
      },
    },

    updatedAt: {
      alias: "updatedAt",
      control: "datetime",
      editable: editable,
      label: "Last modified",
      attributes: {
        className: "form-control",
      },
    },
    sessions: {
      alias: "sessions",
      control: "text",
      editable: editable,
      label: "Sessions included",
      attributes: {
        className: "form-control",
      },
    },
    breadCrumb: {
      alias: "breadCrumb",
      control: "text",
      editable: editable,
      label: "",
      useCustomLayout: true,
      customLayout: function () {
        let moduleTitle = JSON.parse(this.state.defaultValue)[1].name;
        let modulesId = JSON.parse(this.state.defaultValue)[0]._id;

        return (
          <React.Fragment>
            <Link
              className=" font-16  text-dark hover-underline"
              to={"/event/training/" + Fun.getURLPathValue(3) + "/files"}
            >
              Modules
            </Link>{" "}
            <i className="fas fa-chevron-right ml-2 mr-2"></i>
            <span className=" font-16  text-dark">
              <strong>
                {moduleTitle} ({this.props.builder.state.data.length})
              </strong>
            </span>
          </React.Fragment>
        );
      },
      attributes: {
        className: "form-control",
      },
    },
    files: {
      alias: "files",
      control: "text",
      editable: editable,
      label: "Files included",
      attributes: {
        className: "form-control",
      },
    },
  };
}

function UniParticipantsProperties(args) {
  let editable = args.editable;
  let participantsType = args.participantsType
    ? args.participantsType
    : "participants";

  let participantLabel =
    "Search people and groups or paste multiple email addresses"; //"Add participants";
  let participantPlaceholder = "Add people and groups..."; //"Search people by email or name or add people by entering multiple email addresses comma (,) seperated";

  if (args.type == "space") {
    participantLabel =
      "Search people and groups or paste multiple email addresses";
    participantPlaceholder = "Add people and groups...";
  }

  return {
    _id: {
      alias: "_id",
      control: "hidden",
      editable: editable,
      label: "",
      attributes: {
        className: "form-control",
      },
    },
    participantId: {
      alias: "participantId",
      control: "hidden",
      editable: editable,
      label: "",
      attributes: {
        className: "form-control",
      },
    },
    hasPrivateInfo: {
      alias: "hasPrivateInfo",
      control: "hidden",
      editable: false,
    },
    profileImage: {
      alias: "profileImage",
      control: "text",
      editable: editable,
      label: "",
      attributes: {
        className: "form-control",
      },
      useCustomLayout: true,
      customLayout: function (args) {
        if (
          this.props.data.hasPrivateInfo == true &&
          !UniFun.userIsEligible({ forWhat: "default" }) &&
          !UniFun.userIsEligible({ forWhat: "viewer" })
        ) {
          return (
            <span>{UniFun.createProfileImageBubble([this.props.data])}</span>
          );
        }

        return (
          <a
            href="javascript:void(0);"
            onClick={(e) =>
              showUserBIO(this.props.data._id, this.props.builder)
            }
          >
            {UniFun.createProfileImageBubble([this.props.data])}
          </a>
        );
      },
    },
    firstName: {
      alias: "firstName",
      control: "text",
      editable: editable,
      label: "Name",
      attributes: {
        className: "form-control",
      },
    },
    lastName: {
      alias: "lastName",
      control: "text",
      editable: editable,
      label: "Lastname",
      attributes: {
        className: "form-control",
      },
    },
    fullName: {
      alias: "fullName",
      control: "text",
      editable: editable,
      label: "Name",
      attributes: {
        className: "form-control",
      },
    },
    email: {
      alias: "email",
      control: "text",
      editable: editable,
      label: "Email",
      attributes: {
        className: "form-control",
        readOnly: "readOnly",
      },
    },
    roles: {
      alias: "roles",
      control: "tags",
      config: {
        idAttribute: "_id",
        nameAttribute: "name",
        apis: {
          search: {
            url: "/roles",
            formatter: function (args) {
              let output = [];
              if (args) {
                if (args.data) {
                  output = args.data;
                }
              }

              return output;
            },
            parameters: {
              replace: {
                query: "",
              },
            },
          },
        },
      },
      editable: editable,
      label: "Role",
      attributes: {
        className: "form-control",
      },
    },
    eventType: {
      alias: "eventType",
      control: "hidden",
      editable: true,
      label: "",
      attributes: {
        className: "form-control",
      },
    },
    participants: {
      alias: "participants",
      label: participantLabel,
      placeholder: participantPlaceholder,
      control: "tags",
      editable: editable,
      config: {
        idAttribute: "_id",
        nameAttribute: "name",
        apis: {
          search: {
            url: "/user/autocomplete/{query}?type={participantsType}",
            formatter: function (args) {
              let formattedData = [];
              if (args) {
                let data = args.data;
                if (data) {
                  data.map((itm, idx) => {
                    if (itm["_id"]) {
                      let fullName =
                        itm["firstName"] +
                        " " +
                        itm["lastName"] +
                        " - " +
                        itm["email"];

                      if (itm.type == "group") {
                        fullName = itm["firstName"] + " - " + itm["lastName"];
                      }
                      formattedData.push({
                        _id: itm["_id"],
                        name: fullName,
                        group: itm.type,
                      });
                    } else {
                      // if (UniFun.getURLPathValue(1) !== "conference") {
                      formattedData.push({ id: "", name: itm });
                      // }
                    }
                  });
                }
              }
              return formattedData;
            },
            parameters: {
              replace: {
                query: "",
                participantsType: participantsType,
              },
            },
          },
        },
      },
      events: {
        onAddition: function (tag) {
          if (!tag._id) {
            // this.ref.current.input.current.input.current.blur();
            // if (Fun.getURLPathValue(1) !== "conference") {
            if (UniFun.isEmail(tag.name)) {
              tag["_id"] = tag.name.trim();
              tag["name"] = tag.name.trim();
              const tags = [].concat(this.state.tags, tag);
              this.setState({ tags });
            } else {
              let splitParticipants = [];
              let notValidEmails = [];

              tag.name = tag.name.replaceAll(",", " ");
              tag.name = tag.name.replaceAll(";", " ");

              tag.name.split(" ").forEach((itm, idx) => {
                if (itm) {
                  if (UniFun.isEmail(itm.trim())) {
                    splitParticipants.push({
                      id: itm.trim(),
                      name: itm.trim(),
                    });
                  } else {
                    notValidEmails.push(
                      <span className="d-block">{itm.trim()}</span>
                    );
                  }
                }
              });

              if (notValidEmails.length > 0) {
                Fun.createNotification({
                  message: (
                    <span>
                      The following inputs are not valid emails:<br></br>
                      {notValidEmails}
                    </span>
                  ),
                  type: "danger",
                });
              }

              const tags = [].concat(this.state.tags, splitParticipants);
              this.setState({ tags });
            }
            //}
          } else {
            const tags = [].concat(this.state.tags, tag);
            this.setState({ tags });
          }
        },
      },
      attributes: {
        className: "form-control",
      },
    },
  };
}

function UniParticipantsAndFilesEventProperties(input) {
  let editable = input.editable;

  return {
    _id: {
      alias: "_id",
      control: "hidden",
      label: "",
      editable: editable,
      apiPath: "",
      auth: "",
      validations: "",
      attributes: {},
    },

    title: {
      alias: "title",
      control: "text",
      label: "Name",
      editable: editable,
      attributes: {
        className: "form-control",
      },
    },
    parentId: {
      alias: "parentId",
      control: "hidden",
      label: "",
      editable: editable,
    },
    description: {
      alias: "description",
      control: "textarea",
      label: "Description",
      nonEditableDefaultValue: "No description available",
      editable: editable,
      attributes: {
        className: "form-control",
      },
    },
    mime: {
      alias: "mime",
      control: "text",
      label: "Mime",
      editable: false,
      attributes: {
        className: "form-control",
      },
    },
    recording: {
      alias: "recording",
      control: "hidden",
      label: "",
      editable: false,
      attributes: {
        className: "form-control",
      },
    },
    createdAt: {
      alias: "createdAt",
      control: "datetime",
      label: "Created On",
      editable: false,
      attributes: {
        className: "form-control",
      },
    },
    createdBy: {
      alias: "createdBy",
      control: "text",
      label: "Created By",
      editable: false,
      attributes: {
        className: "form-control",
      },
    },
    updatedAt: {
      alias: "updatedAt",
      control: "datetime",
      label: "Last modified",
      editable: false,
      attributes: {
        className: "form-control",
      },
    },
    fileurl: {
      alias: "fileurl",
      control: "iframe",
      label: "",
      editable: false,
      attributes: {
        className: "form-control vh-100",
      },
    },
    size: {
      alias: "size",
      control: "text",
      label: "Size",
      editable: false,
      attributes: {
        className: "form-control",
      },
    },
  };
}

function UniParticipantsAndFilesEventFormatter(data) {
  let formattedData = [];
  if (data) {
    if (data.data) {
      data.data.map((itm, idx) => {
        itm["files"]?.map((file, fileidx) => {
          let formattedRecord = {};
          formattedRecord["_id"] = file._id;
          formattedRecord["title"] = file.title;
          formattedRecord["description"] = file.description;
          formattedRecord["createdAt"] = file.createdAt;
          formattedRecord["createdBy"] = file.createdBy;
          formattedRecord["parentId"] = itm._id;

          formattedRecord["updatedAt"] = file.updatedAt;
          formattedRecord["size"] = file.size;
          formattedRecord["path"] = file.path;
          formattedRecord["mime"] = file.mimetype;
          formattedRecord["recording"] = file.recording;
          formattedRecord["isFolder"] = false;
          formattedRecord["isFavorite"] = file.isFavorite;
          formattedRecord["owner"] = file.owner ? file.owner.fullName : "---";
          formattedData.push(formattedRecord);
        });
      });
    }
  }
  return formattedData;
}

export default UniParticipantsAndFilesEvent;
