import React, { Component } from "react";
import Builder from "../../../lib/builder";
import Fun from "../../../lib/functions";

class LeftPanel extends Component {
  state = {
    data: null,
    loaded: false,
    output: null,
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.setState({
      data: [
        <Builder module="unileftmenu" view="spaces" key={Fun.uuid()}></Builder>,
      ],
      loaded: true,
    });
  }

  render() {
    if (this.state.loaded) {
      return (
        // <div
        //   className="c-sidebar c-sidebar-dark c-sidebar-fixed c-sidebar-lg-show uniLight"
        //   id="sidebar"
        // >
        <React.Fragment>
          <div className="c-sidebar-brand d-lg-down-none uniDark">
            <div className="pl-4">{localStorage.getItem("companyName")}</div>
          </div>
          {this.state.data}
        </React.Fragment>
        // </div>
      );
    } else {
      return "loading...";
    }
  }
}

export default LeftPanel;
