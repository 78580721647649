import React, { Component } from "react";
import Fun from "../../lib/functions";
import Builder from "../../lib/builder";
import moment from "moment";
import UniFun from "./functions";
import { Link } from "react-router-dom";
import Button from "../../controls/button/button";
import DropdownButton from "../../controls/dropdownButton/dropdownButton";

function scheduleFromList(title, module, view, builder) {
  window["modal2"].setState({
    title: title,
    size: "lg",
    targetComponent: null,
    show: true,
    builder: builder,
    callback: function (args) {
      window["modal2"].setState({ show: false, overflowY: "auto" });
      builder.rerunComponent();
    },
    footer: [
      <button
        type="button"
        className="btn btn-link border"
        onClick={() => {
          window["modal2"].setState({ show: false, overflowY: "auto" });
        }}
      >
        Cancel
      </button>,
      <button
        type="button"
        className="btn btn-primary border"
        onClick={() => {
          Fun.submitFromModal("modal2body");
        }}
      >
        Schedule
      </button>,
    ],
    body: (
      <Builder
        module={module}
        view={view}
        key={Fun.uuid()}
        ref={(refComponent) => {
          window["modal2body"] = refComponent;
        }}
      ></Builder>
    ),
  });
}

function UniAgendaGetEvents(from, to, object) {
  let api = object.props.builder.state.module.apis.view;

  let agenda = object.props.builder.builderTemplate.current;

  let agendaConfig = { ...agenda.state.agendaConfig };

  // agendaConfig.events = [];
  // agenda.setState({ agendaConfig: agendaConfig });

  let filters = [
    { value: agenda.state.filters.join(",") },
    {
      key: "from",
      operator: "gt",
      value: moment(from).startOf("month").subtract(7, "days").toISOString(),
    },
    {
      key: "to",
      operator: "lt",
      value: moment(to).endOf("month").add(7, "days").toISOString(),
    },
  ];

  Fun.fetch({
    url: api,
    parameters: {
      replace: {
        filters: JSON.stringify(filters),
      },
    },
    callback: function (args) {
      if (args) {
        if (args[0]) {
          if (args[0].events) {
            agendaConfig.events = args[0].events;
            agenda.setState({ agendaConfig: agendaConfig });
          }
        }
      }
    },
  });
}

function UniAgendaApplyFilters(e, object, type) {
  let agenda = object.props.builder.builderTemplate.current;
  let filters = agenda.state.filters;

  let from = moment(agenda.state.agendaConfig.date)
    .startOf("month")
    .toISOString();

  let to = moment(agenda.state.agendaConfig.date).endOf("month").toISOString();

  if (e.target.checked) {
    filters.push(type);
  } else {
    filters.remove(type);
  }

  agenda.setState({ filters: filters });

  setTimeout(function () {
    UniAgendaGetEvents(from, to, object);
  }, 0);
}

function UniAgendaSelecView(agenda, view) {
  let agendaConfig = { ...agenda.state.agendaConfig };
  agendaConfig.view = view;
  agenda.setState({
    agendaConfig: agendaConfig,
  });
}

const UniAgenda = {
  auth: "",
  views: {
    agenda: {
      auth: "",
      apis: {
        view: {
          url: "/event/all?filters={filters}",
          parameters: {
            headers: {},
            replace: {
              filters: function () {
                let filter = [
                  { value: "exams,trainings,meetings,streams" },
                  {
                    key: "from",
                    operator: "gt",
                    value: moment()
                      .startOf("month")
                      .subtract(7, "days")
                      .toISOString(),
                  },
                  {
                    key: "to",
                    operator: "lt",
                    value: moment().endOf("month").add(7, "days").toISOString(),
                  },
                ];

                return JSON.stringify(filter);
              },
            },
          },
          formatter: function (data) {
            return UniAgendaFormatter(data);
          },
        },
      },
      template: "agenda",
      templateConfig: {
        colsTemplateCount: 12,
        colsFormCount: 10,
        headerClass: "agendaTopHeader",
        header: UniFun.commonHeader,
        headerTitle: "Calendar",
        headerTitleIcon: "/uni/svg/agendaicon.svg",
        agendaConfig: {
          events: {
            onNavigate: function (newdate) {
              let agendaConfig = { ...this.state.agendaConfig };
              agendaConfig.date = newdate;
              this.setState({ agendaConfig: agendaConfig });
            },
            onSelectEvent: UniFun.UniAgendaSelectEvent,
          },
        },
        events: {
          onSubmit: function (e) {},
        },
        includeProperties: ["titleAgendaEvent", "description"],
        templateTop: function () {
          let data = this.state.data;

          let currentAgendaConfig = this.state.agendaConfig;

          let label = "";
          let newDate = null;

          switch (currentAgendaConfig.view) {
            case "month":
              label = moment(currentAgendaConfig.date).format("MMMM YYYY");
              break;
            case "week":
              let startOfWeek = moment(currentAgendaConfig.date)
                .startOf("isoWeek")
                .format("DD/MM/YY");
              let endOfWeek = moment(currentAgendaConfig.date)
                .endOf("isoWeek")
                .format("DD/MM/YY");

              label = "Week " + startOfWeek + " - " + endOfWeek;
              break;
            case "day":
              label = moment(currentAgendaConfig.date).format(
                "dddd DD MMMM YYYY"
              );
              break;
          }

          function navMonth(obj, dir) {
            let agendaConfig = { ...obj.state.agendaConfig };

            let currentDate = agendaConfig.date;

            let month = null;
            let year = null;
            let from = null;
            let to = null;

            switch (obj.state.agendaConfig.view) {
              case "month":
                newDate = moment(currentDate)
                  .add(dir == "prev" ? -1 : 1, "months")
                  .toDate();

                label = moment(newDate).format("MMMM YYYY");

                break;

              case "week":
                let weeknumber = moment(currentDate).add(
                  dir == "prev" ? -1 : 1,
                  "weeks"
                );

                newDate = weeknumber.startOf("isoWeek").toDate();

                let startOfWeek = moment(newDate).format("DD/MM/YY");

                let endOfWeek = moment(newDate)
                  .endOf("isoWeek")
                  .format("DD/MM/YY");

                label = "Week " + startOfWeek + " - " + endOfWeek;

                break;

              case "day":
                newDate = moment(currentDate)
                  .add(dir == "prev" ? -1 : 1, "days")
                  .toDate();

                label = moment(newDate).format("dddd DD MMMM YYYY");
                break;
            }

            agendaConfig.date = newDate;
            obj.setState({
              agendaConfig: agendaConfig,
              month: month,
              year: year,
              date: newDate,
              label: label,
            });

            from = moment(newDate).startOf("month").toISOString();
            to = moment(newDate).endOf("month").toISOString();

            setTimeout(function () {
              UniAgendaGetEvents(from, to, obj);
            }, 0);
          }
          return (
            <div className="row bg-white p-3 border-bottom">
              <div className="col-lg-6  col-md-12">
                <h2 className="m-2 pl-3 unselectable">
                  <i
                    className="fas fa-chevron-left pointer mr-2"
                    onClick={(e) => navMonth(this, "prev")}
                  ></i>
                  <i
                    className="fas fa-chevron-right pointer mr-3"
                    onClick={(e) => navMonth(this, "next")}
                  ></i>
                  {label}
                </h2>
              </div>
              <div className="col-lg-6 col-md-12 text-right">
                <DropdownButton
                  field={UniAgendaProperties({ editable: false }).scheduleBtn}
                  builder={this.props.builder}
                ></DropdownButton>
                <DropdownButton
                  field={UniAgendaProperties({ editable: false }).filters}
                  builder={this.props.builder}
                ></DropdownButton>
                <DropdownButton
                  field={UniAgendaProperties({ editable: false }).monthBtn}
                  builder={this.props.builder}
                ></DropdownButton>
                <Button
                  field={UniAgendaProperties({ editable: false }).todayBtn}
                  builder={this.props.builder}
                ></Button>
                {/* {data[0].scheduleBtn.component} */}
                {/* {data[0].filters.component}
                {data[0].monthBtn.component}
                {data[0].todayBtn.component} */}
              </div>
            </div>
          );
        },
      },
      pk: "_id",
      properties: UniAgendaProperties({ editable: false }),
    },
    list: {
      auth: "",
      apis: {
        view: {
          // url: "/event/all?filters={filters}",
          url: "{url}?start={start}&offset={offset}",
          parameters: {
            replace: {
              start: 0,
              offset: 25,
              url: function () {
                let url = "trainings";
                let urlValue = Fun.getURLPathValue("3");

                if (!Fun.getURLPathValue("3")) {
                  urlValue = Fun.getURLPathValue("2");
                }
                switch (urlValue.toLowerCase()) {
                  case "tests":
                    url = "exam";
                    break;
                  case "trainings":
                    // url = "training/list/all";
                    url = "training";
                    break;
                  case "meetings":
                    url = "meeting";
                    break;
                  case "streams":
                    url = "stream";
                    break;
                }

                return "/event/" + url;
              },
              filters: function () {
                let filterValue = "trainings";

                let urlValue = Fun.getURLPathValue("3");

                if (!Fun.getURLPathValue("3")) {
                  urlValue = Fun.getURLPathValue("2");
                }
                switch (urlValue.toLowerCase()) {
                  case "tests":
                    filterValue = "exams";
                    break;
                  case "trainings":
                    filterValue = "trainings";
                    break;
                  case "meetings":
                    filterValue = "meetings";
                    break;
                  case "streams":
                    filterValue = "streams";
                    break;
                }

                let filter = [
                  { value: filterValue },
                  {
                    key: "from",
                    operator: "gt",
                    value: moment().toISOString(),
                  },
                  {
                    key: "to",
                    operator: "lt",
                    value: moment().endOf("month").toISOString(),
                  },
                ];

                return JSON.stringify(filter);
              },
            },
          },
          formatter: function (data) {
            if (data) {
              if (data.data) {
                let urlValue = Fun.getURLPathValue("3");
                let type = "";
                if (!Fun.getURLPathValue("3")) {
                  urlValue = Fun.getURLPathValue("2");
                }
                switch (urlValue.toLowerCase()) {
                  case "tests":
                    type = "exam";
                    break;
                  case "trainings":
                    // url = "training/list/all";
                    type = "training";
                    break;
                  case "meetings":
                    type = "meeting";
                    break;
                  case "streams":
                    type = "stream";
                    break;
                }

                data.data.forEach((itm, idx) => {
                  itm.type = type;
                });
              }
            }

            return UniAgendaListFormatter(data);
          },
        },
      },
      template: "grid",
      templateConfig: {
        colsTemplateCount: 12,
        colsFormCount: 12,
        gridConfig: {
          emptyFolder: function () {
            let parentBuilder = this.props.builder;
            let data = this.state.data;

            let scheduleBtn = "";
            let urlValue = Fun.getURLPathValue("3");

            if (!Fun.getURLPathValue("3")) {
              urlValue = Fun.getURLPathValue("2");
            }
            switch (urlValue.toLowerCase()) {
              case "tests":
                if (UniFun.userIsEligible({ forWhat: "default" })) {
                  scheduleBtn = (
                    <button
                      type="button"
                      className="btn btn-link text-primary font-size-17"
                      onClick={(e) =>
                        scheduleFromList(
                          "Schedule exam",
                          "unitesttemplates",
                          "selectNewOrFromTemplate",
                          parentBuilder
                        )
                      }
                    >
                      Schedule new event
                    </button>
                  );
                }
                break;
              default:
                if (UniFun.userIsEligible({ forWhat: "default" })) {
                  scheduleBtn = (
                    <button
                      type="button"
                      className="btn btn-link text-primary font-size-17"
                      onClick={(e) =>
                        scheduleFromList(
                          "Schedule training",
                          "unitrainingtemplates",
                          "selectNewOrFromTemplate",
                          parentBuilder
                        )
                      }
                    >
                      Schedule new event
                    </button>
                  );
                }
                break;
              case "meetings":
                scheduleBtn = (
                  <button
                    type="button"
                    className="btn btn-link text-primary font-size-17"
                    onClick={(e) =>
                      scheduleFromList(
                        "Schedule meeting",
                        "unimeetings",
                        "add",
                        parentBuilder
                      )
                    }
                  >
                    Schedule new event
                  </button>
                );
                break;
              case "streams":
                if (UniFun.userIsEligible({ forWhat: "default" })) {
                  scheduleBtn = (
                    <button
                      type="button"
                      className="btn btn-link text-primary font-size-17"
                      onClick={(e) =>
                        scheduleFromList(
                          "Schedule stream",
                          "unistreams",
                          "add",
                          parentBuilder
                        )
                      }
                    >
                      Schedule new event
                    </button>
                  );
                }
                break;
            }

            return (
              <div className="card border-0">
                <div className="card-body">
                  <div className="row mt-4 " style={{ height: "500px" }}>
                    <div className="col-12 text-center">
                      <h5>
                        <strong>There are not any scheduled events </strong>
                      </h5>

                      <p>
                        {scheduleBtn}
                        {/* {UniFun.userIsEligible({ forWhat: "default" }) ? (
                          <DropdownButton
                            field={scheduleBtn}
                            builder={this.props.builder}
                          ></DropdownButton>
                        ) : (
                          ""
                        )} */}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            );
          },
          headers: {
            title: {
              attributes: {
                className: "w-50",
              },
            },
          },
          cellTemplates: {
            title: function (data, rec, obj) {
              let uid = Fun.uuid();

              let moreLink = "";
              let module = "";
              let view = "";
              let modalTitle = "";
              let modalDeleteTitle = "";
              let modal = "modal2";
              let deleteView = "deleteEvent";
              let deleteModule = "";
              let canEdit = false;

              let dataEvent = rec.event.data;
              let type = dataEvent.eventType;

              if (type) {
                type = type.toLowerCase();
              }

              switch (type) {
                case "test":
                  moreLink = dataEvent.data.training
                    ? "/event/training/" + dataEvent.data.training + "/results"
                    : "/event/test/" + dataEvent.data._id;
                  modalTitle = "Edit test";
                  modalDeleteTitle = "Delete test event";
                  module = "unitestevent";
                  view = "editEvent";
                  deleteView = "deleteExam";
                  deleteModule = "unitaketest";
                  break;

                case "training":
                  moreLink = "/event/training/" + dataEvent.data._id;
                  modalTitle = "Edit training";
                  modalDeleteTitle = "Delete training";
                  module = "unitrainingevents";
                  view = "editEvent";
                  break;

                case "conference":
                  moreLink = dataEvent.data.training._id
                    ? "/event/training/" +
                      dataEvent.data.training._id +
                      "/module/" +
                      dataEvent.data.module._id
                    : "";
                  modalDeleteTitle = "Delete conference event";
                  modalTitle = "Edit training";
                  module = "unitrainingevents";
                  view = "editEvent";
                  break;

                case "meeting":
                  moreLink = "/event/meeting/" + dataEvent.id;
                  modalDeleteTitle = "Delete meeting event";
                  modalTitle = "Edit meeting";
                  module = "unimeetingevent";
                  view = "editEvent";
                  break;

                case "stream":
                  moreLink = "/conference/" + dataEvent.id;
                  modalTitle = "Edit conference";
                  modalDeleteTitle = "Delete conference";
                  module = "uniconferences";
                  view = "privateEdit";

                  break;
              }

              // if (dataEvent.data.training) {
              //   modalTitle = "Edit training";
              //   module = "unitrainingevents";
              //   modalDeleteTitle = "Delete training";
              //   view = "editEvent";
              //   modal = "modal";
              // }

              canEdit = UniFun.userIsEligible({ forWhat: "default" })
                ? true
                : false;

              let bgcolor = "#27CCBD";

              let typeExam = "Practice";

              if (parseFloat(dataEvent.data.passingGrade) > 0) {
                bgcolor = "#D7B835";
                typeExam = "Graded";
              }

              let mt = "";
              let eventTitle = UniFun.substring(rec.title.value, 0, 70);
              if (dataEvent.data.questions) {
                mt = "mt-2";
                eventTitle = (
                  <div style={{ display: "contents" }}>
                    <span
                      className="avatar-circle avatar-default"
                      style={{ backgroundColor: bgcolor }}
                    >
                      <div className=" h-100 text-center">
                        <img
                          src="/uni/svg/tests.svg"
                          style={{ width: "20px" }}
                          className="filter-white"
                        ></img>
                      </div>
                    </span>
                    <span
                      className="ml-2 pr-4"
                      style={{ position: "relative" }}
                    >
                      {rec.title.value}
                      <span className="p-2  rounded ml-2 bg-light">
                        {typeExam}
                      </span>
                    </span>
                  </div>
                );
              }
              return (
                <React.Fragment>
                  {/* <a
                    href="javascript:;"
                    onClick={(e) =>
                      UniFun.UniAgendaSelectEvent(rec.event.value)
                    }
                  > */}
                  <Link to={moreLink}>
                    {/* <img
                      src={rec.icon.value}
                      style={{ height: "20px" }}
                      className="filter-grey mr-2"
                    /> */}
                    {eventTitle}
                  </Link>
                  <span className={"float-right mr-4 grid-controls " + mt}>
                    <Link to={moreLink} className="mr-4">
                      View details{" "}
                      <i className="fas fa-chevron-right ml-2 pointer"></i>
                    </Link>
                    <a
                      href="javascript:void(0);"
                      onClick={(e) =>
                        UniFun.copyToClipboard(UniFun.createURL(moreLink))
                      }
                    >
                      <i className="fas fa-link mr-4 pointer"></i>
                    </a>
                    <i
                      class="fas fa-ellipsis-h pointer"
                      data-toggle="collapse"
                      data-target={"#" + uid}
                    ></i>
                    <div
                      className="position-absolute bg-white ml-4 mt-2 collapse"
                      id={uid}
                    >
                      <ul className="list-group " style={{ width: "200px" }}>
                        <Link
                          className="list-group-item p-2 pl-3"
                          to={moreLink}
                        >
                          Open
                        </Link>
                        {canEdit ? (
                          <li
                            className="list-group-item p-2 pl-3"
                            onClick={(e) =>
                              UniFun.createEventModal(
                                obj.props.builder,
                                // dataEvent.data.training
                                //   ? dataEvent.data.training._id
                                //   : rec.event.value.id,
                                dataEvent.data._id,
                                modalTitle,
                                module,
                                view,
                                "modal",
                                "xl",
                                "Update"
                              )
                            }
                          >
                            Edit
                          </li>
                        ) : (
                          ""
                        )}
                        <li
                          className="list-group-item p-2 pl-3"
                          onClick={(e) => {
                            UniFun.copyToClipboard(UniFun.createURL(moreLink));
                          }}
                        >
                          Copy link
                        </li>
                        {type !== "training" ? (
                          <li
                            className="list-group-item p-2 pl-3"
                            onClick={(e) => UniFun.createICS(rec.event.value)}
                          >
                            Download ics
                          </li>
                        ) : (
                          ""
                        )}
                        {canEdit ? (
                          <li
                            className="list-group-item p-2 pl-3 text-danger"
                            onClick={(e) =>
                              UniFun.createEventModal(
                                obj.props.builder,
                                dataEvent.data.training
                                  ? dataEvent.data.training._id
                                  : rec.event.value.id,
                                modalDeleteTitle,
                                deleteModule ? deleteModule : module,
                                deleteView,
                                modal,
                                "default",
                                "Delete",
                                "btn-danger"
                              )
                            }
                          >
                            Delete
                          </li>
                        ) : (
                          ""
                        )}
                      </ul>
                    </div>
                  </span>
                </React.Fragment>
              );
            },
            organizer: function (data, rec, obj) {
              return UniFun.createProfileImageBubble(rec.organizer.value);
            },
          },
        },
        header: UniFun.commonHeader,
        headerTitle: function () {
          let urlValue = Fun.getURLPathValue("3");

          if (!Fun.getURLPathValue("3")) {
            urlValue = Fun.getURLPathValue("2");
          }
          switch (urlValue.toLowerCase()) {
            case "tests":
              return "Tests";
              break;
            default:
              return "Trainings";
              break;
            case "meetings":
              return "Meetings";
              break;
            case "streams":
              return "Streams";
              break;
          }
        },
        headerTitleIcon: "/uni/svg/trainings/go_back.svg",
        headerIconUrl: "/events/overview",
        events: {
          onSubmit: function (e) {},
        },
        includeProperties: function () {
          let urlValue = Fun.getURLPathValue("3");
          let type = "";
          if (!Fun.getURLPathValue("3")) {
            urlValue = Fun.getURLPathValue("2");
          }
          switch (urlValue.toLowerCase()) {
            case "trainings":
              return ["title", "createdAt", "when"];
              break;
            case "meetings":
              return ["title", "organizer", "when"];
              break;
          }

          return ["title", "when"];
        },
        cardTemplateTop: function () {
          let data = this.state.data;
          let parentBuilder = this.props.builder;
          let pagination = this.state.rawData.pagination;

          let scheduleBtn = "";
          let urlValue = Fun.getURLPathValue("3");

          if (!Fun.getURLPathValue("3")) {
            urlValue = Fun.getURLPathValue("2");
          }
          switch (urlValue.toLowerCase()) {
            case "tests":
              if (UniFun.userIsEligible({ forWhat: "default" })) {
                scheduleBtn = (
                  <button
                    type="button"
                    className="btn btn-primary bg-purple  m-2  float-right"
                    onClick={(e) =>
                      scheduleFromList(
                        "Schedule exam",
                        "unitesttemplates",
                        "selectNewOrFromTemplate",
                        parentBuilder
                      )
                    }
                  >
                    Schedule
                  </button>
                );
              }
              break;
            default:
              if (UniFun.userIsEligible({ forWhat: "default" })) {
                scheduleBtn = (
                  <button
                    type="button"
                    className="btn btn-primary bg-purple  m-2  float-right"
                    onClick={(e) =>
                      scheduleFromList(
                        "Schedule training",
                        "unitrainingtemplates",
                        "selectNewOrFromTemplate",
                        parentBuilder
                      )
                    }
                  >
                    Schedule
                  </button>
                );
              }
              break;
            case "meetings":
              scheduleBtn = (
                <button
                  type="button"
                  className="btn btn-primary bg-purple  m-2  float-right"
                  onClick={(e) =>
                    scheduleFromList(
                      "Schedule meeting",
                      "unimeetings",
                      "add",
                      parentBuilder
                    )
                  }
                >
                  Schedule
                </button>
              );
              break;
            case "streams":
              if (UniFun.userIsEligible({ forWhat: "default" })) {
                scheduleBtn = (
                  <button
                    type="button"
                    className="btn btn-primary bg-purple  m-2  float-right"
                    onClick={(e) =>
                      scheduleFromList(
                        "Schedule stream",
                        "unistreams",
                        "add",
                        parentBuilder
                      )
                    }
                  >
                    Schedule
                  </button>
                );
              }
              break;
          }

          return (
            <div className="row ">
              <div className="col-6 pt-2 pl-4 ">
                <span className="text-dark ">
                  There are {pagination?.total} events
                </span>
              </div>
              <div className="col-6 text-right p-2 pr-4">
                {/* <DropdownButton
                  field={UniAgendaProperties({ editable: false }).scheduleBtn}
                  builder={this.props.builder}
                ></DropdownButton> */}
                {scheduleBtn}

                {/* {data[0].scheduleBtn.component}
                {data[0].filters.component}
                {data[0].monthBtn.component}
                {data[0].todayBtn.component} */}
              </div>
            </div>
          );
        },
        cardTemplateBottom: function () {
          let data = this.state.data;
          let pagination = this.state.rawData.pagination;
          let parentBuilder = this.props.builder;

          if (pagination) {
            return (
              <div className="mt-4">
                {UniFun.pagination(
                  pagination.currentPage,
                  0,
                  25,
                  pagination.total,
                  parentBuilder,
                  "offset"
                )}
              </div>
            );
          }
          return "";
        },
      },
      pk: "_id",
      properties: UniAgendaProperties({ editable: false }),
    },
    detail: {
      auth: "",
      apis: {
        view: {
          data: { data: [{}] },
          parameters: {
            headers: {},
            replace: {},
          },
          formatter: function (data) {
            return data;
          },
        },
      },
      template: "form",
      templateConfig: {
        useCard: false,
        colsTemplateCount: 12,
        colsFormCount: 12,
        includeProperties: ["_id"],
        templateTop: function () {
          let data = this.props.builder.props.inputData[0];
          let type = data.data.type;

          let title = (
            <div className="mt-40px">
              <img
                src="/uni/svg/title.svg"
                style={{ height: "30px" }}
                className="filter-grey"
              />
              <span className="ml-3 text-black">{data.title}</span>
            </div>
          );

          let additionalmaterial = (
            <div className="mt-40px">
              <img
                src="/uni/svg/additionalmaterial.svg"
                style={{ height: "30px" }}
                className="filter-grey"
              />
              <span className="ml-3  text-black">
                <a href="javascript:;">{data.data.files} files included</a>
              </span>
            </div>
          );

          let actionTitle = "";
          let actionIcon = "";
          let actionLink = "";
          let examDuration = "";
          let gradeOut = "";
          let moreLink = "";
          let actionBtn = null;
          let moreText = "More details";
          let moreLinkClass = "btn btn-link border";

          let disabled = UniFun.isBetweenDates(data.data.from, data.data.to)
            ? ""
            : "disabled";

          switch (type) {
            case "exam":
              if (data.data.userExamInfo) {
                if (data.data.userExamInfo._id) {
                  actionLink = "/test/" + data.id;
                }
              }
              actionTitle = "Take test";
              actionIcon = "/uni/svg/test.svg";
              actionBtn = UniFun.createStartExamBtn(data.data);
              additionalmaterial = "";
              moreLink = data.data.training
                ? "/event/training/" +
                  data.data.training._id +
                  "/module/" +
                  data.data.module._id
                : "/event/test/" + data.id;
              examDuration = (
                <div className="mt-40px">
                  <img
                    src="/uni/svg/testdutation.svg"
                    style={{ height: "30px" }}
                    className="filter-grey"
                  />
                  <span className="ml-3  text-black">
                    {UniFun.examDuration(data.data.duration)}
                  </span>
                </div>
              );

              gradeOut = (
                <div className="mt-40px">
                  <img
                    src="/uni/svg/graded_practicetest.svg"
                    style={{ height: "30px" }}
                    className="filter-grey"
                  />
                  <span className="ml-3 text-black">
                    {UniFun.gradeExam(data.data.gradePass)}
                  </span>
                </div>
              );
              break;

            case "conference":
              disabled = UniFun.meetingIsAvailable(data.data.from, data.data.to)
                ? "disabled"
                : "";

              actionTitle = "Join conference";
              actionLink = "/meeting/v-" + data.id;

              if (!UniFun.meetingIsAvailable(data.data.from, data.data.to)) {
                actionBtn = (
                  <button
                    type="button"
                    className="btn btn-primary disabled action-btn"
                    data-event-start={moment(data.data.from).valueOf()}
                    data-event-url={actionLink}
                    disabled
                  >
                    {actionTitle}
                  </button>
                );
              }

              actionIcon = "/uni/svg/joinvideocall.svg";
              moreLink = data.data.training._id
                ? "/event/training/" +
                  data.data.training._id +
                  "/module/" +
                  data.data.module._id
                : "";
              break;

            case "meeting":
              disabled = UniFun.meetingIsAvailable(data.data.from, data.data.to)
                ? "disabled"
                : "";

              actionTitle = "Join meeting";
              actionLink = "/meeting/m-" + data.id;

              if (!UniFun.meetingIsAvailable(data.data.from, data.data.to)) {
                actionBtn = (
                  <button
                    type="button"
                    className="btn btn-primary disabled action-btn"
                    data-event-start={moment(data.data.from).valueOf()}
                    data-event-url={actionLink}
                    disabled
                  >
                    {actionTitle}
                  </button>
                );
              }

              actionIcon = "/uni/svg/joinvideocall.svg";

              moreLink = data.data.training
                ? "/event/training/" +
                  data.data.training._id +
                  "/module/" +
                  data.data.module._id
                : "/event/meeting/" + data.id;
              break;

            case "stream":
              disabled = UniFun.meetingIsAvailable(data.data.from, data.data.to)
                ? "disabled"
                : "";
              moreLinkClass = "btn btn-primary ";
              actionTitle = "Join conference";
              actionLink = "/conference/live/" + data.id;
              actionBtn = "";
              // if (!UniFun.meetingIsAvailable(data.data.from, data.data.to)) {
              //   actionBtn = (
              //     <button
              //       type="button"
              //       className="btn btn-primary ml-3 disabled action-btn"
              //       data-event-start={moment(data.data.from).valueOf()}
              //       data-event-url={actionLink}
              //       disabled
              //     >
              //       {actionTitle}
              //     </button>
              //   );
              // }

              title = "";
              additionalmaterial = (
                <div className="mt-40px">
                  <img
                    src="/uni/svg/additionalmaterial.svg"
                    style={{ height: "30px" }}
                    className="filter-grey"
                  />
                  <span className="ml-3  text-black">
                    <a href="javascript:;">
                      {data.data.numberOfSessions} sessions included in current
                      day
                    </a>
                  </span>
                </div>
              );
              actionIcon = "/uni/svg/stream_overiew_dashboard.svg";
              moreText = "View sessions";
              moreLink = "/conference/" + data.id + "/sessions";
              break;
          }

          let refActionBtn = React.createRef();

          function closeModal() {
            window["modal"].setState({ show: false, overflowY: "auto" });
          }

          let action = (
            <div className="mt-40px">
              <img
                src={actionIcon}
                className="filter-grey"
                style={{ height: "25px" }}
              />
              <span className="text-black">
                {actionBtn != null ? (
                  actionBtn ? (
                    <span className="ml-3">{actionBtn}</span>
                  ) : (
                    <span className="ml-2"></span>
                  )
                ) : (
                  <a
                    href={actionLink}
                    id="actionBtn"
                    className="btn btn-primary mr-2 ml-3 action-btn"
                    data-event-start={moment(data.data.from).valueOf()}
                    disabled={disabled}
                    target="new"
                    ref={refActionBtn}
                    onClick={closeModal}
                  >
                    {actionTitle}
                  </a>
                )}
                <Link
                  className={moreLinkClass + " ml-2"}
                  to={moreLink}
                  onClick={closeModal}
                >
                  {moreText}
                </Link>
              </span>
            </div>
          );

          let from = moment(data.start);
          let to = moment(data.end);

          let when = "";

          // if (from.isSame(to)) {
          //   when = (
          //     <span className="ml-3 text-black">
          //       {from.format("ddd DD MMM YY, HH:mm")}
          //     </span>
          //   );
          // } else {
          //   when = (
          //     <span className="ml-3  text-black">
          //       {from.format("ddd DD MMM YY, HH:mm")} to{" "}
          //       {to.format("ddd DD MMM YY, HH:mm")}
          //     </span>
          //   );
          // }

          when = (
            <span className="ml-3  text-black">
              {UniFun.fromToDateString(data.start, data.end)}
            </span>
          );

          let datetime = (
            <div className="mt-40px">
              <img
                src="/uni/svg/datetime.svg"
                className="filter-grey"
                style={{ height: "30px" }}
              />
              {when}
            </div>
          );

          return (
            <div className="container mt-5">
              {title}
              {datetime}
              {examDuration}
              {gradeOut}
              {additionalmaterial}
              {action}
            </div>
          );
        },
      },
      pk: "_id",
      properties: UniAgendaProperties({ editable: false }),
    },
    overview: {
      auth: "",
      apis: {
        view: {
          url: "/event/all?{filters}",

          parameters: {
            replace: {
              filters: function () {
                let filter = [
                  {
                    value: "exams,trainings,meetings,streams",
                  },
                  {
                    key: "to",
                    operator: "gt",
                    value: moment().startOf("day").toISOString(),
                  },
                  {
                    key: "to",
                    operator: "lt",
                    value: moment().add(6, "months").toISOString(),
                  },
                ];

                return "filters=" + JSON.stringify(filter);
              },
            },
          },
          formatter: function (data) {
            return UniAgendaListFormatter(data);
          },
        },
      },
      template: "agendaoverview",
      templateConfig: {
        gridConfig: {
          emptyFolder: function () {
            let parentBuilder = this.props.builder;
            let data = this.state.data;

            let scheduleBtn = {
              ...UniAgendaProperties({ editable: false }).scheduleBtn,
            };
            scheduleBtn.attributes.className = "btn btn-link";
            scheduleBtn.caption = <strong>Schedule new event</strong>;
            return (
              // <div className="card">
              //   <div className="card-body">
              <div className="row mt-4 " style={{ height: "500px" }}>
                <div className="col-12 text-center">
                  <h5>
                    <strong>There are not any scheduled events </strong>
                  </h5>

                  <p>
                    {UniFun.userIsEligible({ forWhat: "default" }) ? (
                      <DropdownButton
                        field={scheduleBtn}
                        builder={this.props.builder}
                      ></DropdownButton>
                    ) : (
                      "Your events will appear here"
                    )}
                  </p>
                </div>
              </div>
              //   </div>
              // </div>
            );
          },
        },
        colsTemplateCount: 12,
        colsFormCount: 12,
        header: UniFun.commonHeader,
        headerTitle: "Overview",
        headerTitleIcon: "/uni/svg/agendaicon.svg",
        events: {
          onSubmit: function (e) {},
        },
        includeProperties: ["title", "when", "category"],
      },
      pk: "_id",
      properties: UniAgendaProperties({ editable: false }),
    },
  },
};

export default UniAgenda;

function UniAgendaProperties(input) {
  let editable = input.editable;

  return {
    _id: {
      alias: "_id",
      control: "hidden",

      editable: editable,
    },
    title: {
      alias: "title",
      control: "text",
      label: "Title",
      editable: editable,
    },
    titleAgendaEvent: {
      alias: "titleAgendaEvent",
      control: "text",
      label: "",
      editable: editable,
    },
    organizer: {
      alias: "organizer",
      label: "Organizer",
      control: "text",
      editable: editable,
      attributes: {
        className: "form-control",
      },
    },
    createdAt: {
      alias: "createdAt",
      control: "datetime",
      editable: editable,
      label: "Date created",
      config: {
        fromFormat: "YYYY-MM-DDT00:00:00.000Z",
        toFormat: "ddd DD MMM YYYY",
        includeTime: false,
        valueEditFormat: { dateStyle: "short" },
        valueDisplayFormat: { dateStyle: "short" },
      },
      attributes: {
        className: "form-control",
      },
    },
    when: {
      alias: "when",
      control: "text",
      label: "Date",
      editable: editable,
    },
    icon: {
      alias: "icon",
      control: "text",
      label: "",
      editable: editable,
    },
    category: {
      alias: "category",
      control: "select",
      config: {
        options: [
          { label: "Test", value: "exam" },
          { label: "Meeting", value: "meeting" },
          { label: "Training / Conference", value: "trainingconference" },
          { label: "Training / Test", value: "trainingexam" },
        ],
      },
      label: "Category",
      editable: editable,
    },
    events: {
      alias: "events",
      control: "text",
      editable: editable,
    },
    event: {
      alias: "event",
      control: "text",
      editable: editable,
    },
    from: {
      alias: "from",
      control: "datetime",
      label: "Start date",
      editable: editable,
    },
    startDate: {
      alias: "startDate",
      control: "datetime",
      label: "Start date",
      editable: editable,
      useCustomLayout: true,
      customLayout: function (args) {
        if (!this.props.value) {
          return "Self paced";
        }
        return args.control;
      },
    },
    to: {
      alias: "to",
      control: "text",
      editable: editable,
    },
    joinBtn: {
      alias: "joinBtn",
      control: "text",
      editable: editable,
    },
    editBtn: {
      alias: "editBtn",
      control: "text",
      editable: editable,
    },
    subtitle: {
      alias: "subtitle",
      control: "text",
      editable: editable,
    },
    duration: {
      alias: "duration",
      control: "text",
      editable: editable,
    },
    description: {
      alias: "description",
      control: "text",
      editable: editable,
    },
    color: {
      alias: "color",
      control: "text",
      editable: editable,
    },
    icon: {
      alias: "icon",
      control: "text",
      editable: editable,
    },
    eventType: {
      alias: "eventType",
      control: "text",
      editable: false,
    },
    syncBtn: {
      alias: "syncBtn",
      control: "button",
      caption: "Sync",
      attributes: {
        className: "btn btn-light m-2 float-right",
      },
    },

    todayBtn: {
      alias: "todayBtn",
      control: "button",
      caption: "Today",
      attributes: {
        className: "btn btn-light m-2 float-right",
      },
      events: {
        onClick: function () {
          let agendaCompo = this.props.builder.builderTemplate.current;
          let agendaConfig = { ...agendaCompo.state.agendaConfig };
          let newDate = new Date();
          agendaConfig.date = newDate;
          let month = newDate.toLocaleString("default", {
            month: "long",
          });
          let year = newDate.getFullYear();
          agendaCompo.setState({
            agendaConfig: agendaConfig,
            month: month,
            year: year,
          });

          let from = moment().startOf("month").toISOString();
          let to = moment().endOf("month").toISOString();

          setTimeout(function () {
            UniAgendaGetEvents(from, to, agendaCompo);
          }, 0);
        },
      },
    },
    monthBtn: {
      alias: "monthBtn",
      control: "dropdownbutton",
      caption: "Month",
      attributes: {
        className: "btn btn-light m-2 float-right",
      },
      config: {
        options: {
          month: {
            label: "Month",
            events: {
              onClick: function () {
                let agendaCompo = this.props.builder.builderTemplate.current;
                UniAgendaSelecView(agendaCompo, "month");
                this.setState({ caption: "Month" });
              },
            },
          },
          week: {
            label: "Week",
            events: {
              onClick: function () {
                let agendaCompo = this.props.builder.builderTemplate.current;
                UniAgendaSelecView(agendaCompo, "week");
                this.setState({ caption: "Week" });
              },
            },
          },
          day: {
            label: "Day",
            events: {
              onClick: function () {
                let agendaCompo = this.props.builder.builderTemplate.current;
                UniAgendaSelecView(agendaCompo, "day");
                this.setState({ caption: "Day" });
              },
            },
          },
        },
      },
    },
    filters: {
      alias: "filters",
      control: "dropdownbutton",
      caption: "Filters",
      attributes: {
        className: "btn btn-light m-2 float-right",
      },
      config: {
        options: {
          meeting: {
            label: "Meetings",
            checkbox: true,
            checked: true,
            events: {
              onClick: function (e) {
                UniAgendaApplyFilters(e, this, "meetings");
              },
            },
          },
          stream: {
            label: "Conferences",
            checkbox: true,
            checked: true,
            events: {
              onClick: function (e) {
                UniAgendaApplyFilters(e, this, "streams");
              },
            },
          },
          training: {
            label: "Trainings",
            checkbox: true,
            checked: true,
            events: {
              onClick: function (e) {
                UniAgendaApplyFilters(e, this, "trainings");
              },
            },
          },
          exam: {
            label: "Tests",
            checkbox: true,
            checked: true,
            events: {
              onClick: function (e) {
                UniAgendaApplyFilters(e, this, "exams");
              },
            },
          },
        },
      },
    },
    scheduleBtn: {
      alias: "scheduleBtn",
      control: "dropdownbutton",
      caption: "Schedule",
      config: {
        options: {
          meeting: {
            label: "Meeting",
            // auth: function () {
            //   return UniFun.userIsEligible({ forWhat: "schedule.meeting" });
            // },
            events: {
              onClick: function () {
                let builder = this.props.builder;

                window["modal2"].setState({
                  title: "Schedule meeting",
                  size: "lg",
                  targetComponent: null,
                  show: true,
                  callback: function (args) {
                    window["modal2"].setState({
                      show: false,
                      overflowY: "auto",
                    });
                    builder.rerunComponent();
                  },
                  footer: [
                    <button
                      type="button"
                      className="btn btn-link border"
                      onClick={() => {
                        window["modal2"].setState({
                          show: false,
                          overflowY: "auto",
                        });
                      }}
                    >
                      Cancel
                    </button>,
                    <button
                      type="button"
                      className="btn btn-primary border"
                      onClick={() => {
                        Fun.submitFromModal("modal2body");
                      }}
                    >
                      Schedule
                    </button>,
                  ],
                  body: (
                    <Builder
                      module="unimeetings"
                      view="add"
                      key={Fun.uuid()}
                      ref={(refComponent) => {
                        window["modal2body"] = refComponent;
                      }}
                    ></Builder>
                  ),
                });
              },
            },
          },
          stream: {
            label: "Conference",
            auth: function () {
              return UniFun.userIsEligible({ forWhat: "isHost" });
            },
            events: {
              onClick: function () {
                let builder = this.props.builder;

                window["modal2"].setState({
                  title: "Schedule conference",
                  size: "lg",
                  targetComponent: null,
                  show: true,
                  callback: function (args) {
                    window["modal2"].setState({
                      show: false,
                      overflowY: "auto",
                    });
                    builder.rerunComponent();
                  },
                  footer: [
                    <button
                      type="button"
                      className="btn btn-link border"
                      onClick={() => {
                        window["modal2"].setState({
                          show: false,
                          overflowY: "auto",
                        });
                      }}
                    >
                      Cancel
                    </button>,
                    <button
                      type="button"
                      className="btn btn-primary border"
                      onClick={() => {
                        Fun.submitFromModal("modal2body");
                      }}
                    >
                      Schedule
                    </button>,
                  ],
                  body: (
                    <Builder
                      module="uniconferences"
                      view="privateAdd"
                      key={Fun.uuid()}
                      ref={(refComponent) => {
                        window["modal2body"] = refComponent;
                      }}
                    ></Builder>
                  ),
                });
              },
            },
          },
          training: {
            label: "Training",
            auth: function () {
              return UniFun.userIsEligible({ forWhat: "schedule.training" });
            },
            events: {
              onClick: function () {
                let builder = this.props.builder;
                window["modal2"].setState({
                  title: "Schedule training",
                  size: "lg",
                  targetComponent: null,
                  show: true,
                  builder: builder,
                  callback: function (args) {
                    window["modal2"].setState({
                      show: false,
                      overflowY: "auto",
                    });
                    builder.rerunComponent();
                  },
                  footer: [
                    <button
                      type="button"
                      className="btn btn-link border"
                      onClick={() => {
                        window["modal2"].setState({
                          show: false,
                          overflowY: "auto",
                        });
                      }}
                    >
                      Cancel
                    </button>,
                    <button
                      type="button"
                      className="btn btn-primary border"
                      onClick={() => {
                        Fun.submitFromModal("modal2body");
                      }}
                    >
                      Next
                    </button>,
                  ],
                  body: (
                    <Builder
                      module="unitrainingtemplates"
                      view="selectNewOrFromTemplate"
                      key={Fun.uuid()}
                      ref={(refComponent) => {
                        window["modal2body"] = refComponent;
                      }}
                    ></Builder>
                  ),
                });
              },
            },
          },
          test: {
            label: "Test",
            auth: function () {
              return UniFun.userIsEligible({ forWhat: "schedule.test" });
            },
            events: {
              onClick: function () {
                let builder = this.props.builder;
                window["modal2"].setState({
                  title: "Schedule exam",
                  size: "lg",
                  targetComponent: null,
                  show: true,
                  callback: function (args) {
                    window["modal2"].setState({
                      show: false,
                      overflowY: "auto",
                    });
                  },
                  builder: builder,
                  footer: [
                    <button
                      type="button"
                      className="btn btn-link border"
                      onClick={() => {
                        window["modal2"].setState({
                          show: false,
                          overflowY: "auto",
                        });
                      }}
                    >
                      Cancel
                    </button>,
                    <button
                      type="button"
                      className="btn btn-primary border"
                      onClick={() => {
                        Fun.submitFromModal("modal2body");
                      }}
                    >
                      Schedule
                    </button>,
                  ],
                  body: (
                    <Builder
                      module="unitesttemplates"
                      view="selectNewOrFromTemplate"
                      key={Fun.uuid()}
                      ref={(refComponent) => {
                        window["modal2body"] = refComponent;
                      }}
                    ></Builder>
                  ),
                });
              },
            },
          },
        },
      },
      attributes: {
        className: "btn btn-primary bg-purple  m-2  float-right",
      },
    },
  };
}

function UniAgendaListFormatter(data) {
  let formattedData = [];

  if (data) {
    if (data.data) {
      data.data.map((itm, idx) => {
        let backgroundColor = "";
        let hex = "";
        let type = itm["type"];
        let icon = "";

        if (itm["training"]) {
          if (itm["training"]["_id"]) {
            type = "training" + type;
          }
        }

        if (itm["modules"]) {
          type = "training";
        }

        if (itm["examDuration"]) {
          type = "exam";
        }

        let joinBtn = [];
        let editBtn = [];
        let moreLink = "";
        let eventType = "";

        let colors = {
          conference: "#5B2E7D",
          exam: "#124C59",
          meeting: "#311259",
          training: "#2B358A",
          stream: "#311259",
        };

        if (data.colours) {
          colors = {
            conference: data.colours.conference
              ? data.colours.conference
              : "#5B2E7D",
            exam: data.colours.exam ? data.colours.exam : "#124C59",
            meeting: data.colours.meeting ? data.colours.meeting : "#311259",
            training: data.colours.training ? data.colours.training : "#2B358A",
            stream: data.colours.stream ? data.colours.stream : "#311259",
          };
        }

        switch (type) {
          case "exam":
            backgroundColor = colors.exam; //"rgb(79, 147, 162, .2)"; // "#4F93A2";
            hex = colors.exam; //"#4f93a2";
            icon = "/uni/svg/test.svg";

            moreLink = itm["training"]
              ? "/event/training/" + itm["training"]["_id"] + "/results"
              : "/event/test/" + itm["_id"];
            joinBtn = (
              <Link to={moreLink} className="btn btn-secondary border ">
                Open
              </Link>
            );

            // <Link to={moreLink} className="btn btn-secondary border ml-2 ">
            //     <i className="fas fa-ellipsis-h"></i>
            //   </Link>

            editBtn = (
              <div className="btn-group">
                <button
                  type="button"
                  className="btn btn-secondary border ml-2"
                  data-toggle="dropdown"
                >
                  <i className="fas fa-ellipsis-h"></i>
                </button>
                <div className="dropdown-menu">
                  <a className="dropdown-item" href="#">
                    Edit Test
                  </a>
                  <div className="dropdown-divider"></div>
                  <a className="dropdown-item" href="#">
                    Delete
                  </a>
                </div>
              </div>
            );
            eventType = "Test";
            break;

          case "meeting":
            backgroundColor = colors.meeting; //"rgb(116, 79, 162, .2)"; //"#744FA2";
            hex = colors.meeting; //"#744fa2";
            icon = "/uni/svg/joinvideocall.svg";
            moreLink = itm["training"]
              ? "/event/training/" +
                itm["training"]["_id"] +
                "/module/" +
                itm["module"]._id
              : "/event/meeting/" + itm["_id"];
            eventType = "Meeting";
            joinBtn = (
              <Link to={moreLink} className="btn btn-secondary border">
                Open
              </Link>
            );

            break;

          case "trainingconference":
            eventType = "Training";
            backgroundColor = colors.training; // "rgb(55, 117, 210, .2)"; //"#3775D2";
            hex = colors.training; // "#3775d2";
            icon = "/uni/svg/joinvideocall.svg";
            moreLink =
              "/event/training/" +
              itm["training"]["_id"] +
              "/module/" +
              itm["module"]._id;
            joinBtn = (
              <Link
                to={
                  "/event/training/" +
                  itm["training"]["_id"] +
                  "/module/" +
                  itm["module"]._id
                }
                className="btn btn-secondary border"
              >
                Open
              </Link>
            );
            break;

          case "training":
            eventType = "Training";
            moreLink = "/event/training/" + itm._id;
            break;

          default:
            eventType = "Test";

            break;

          case "stream":
            eventType = "Stream";
            backgroundColor = colors.conference; //"rgb(162, 149, 79, .2)"; //"#3775D2";
            hex = colors.conference; //"#A2954F";
            icon = "/uni/svg/stream_overiew_dashboard.svg";
            moreLink = "/conference/" + itm["_id"];

            joinBtn = (
              <Link
                to={"/conference/" + itm["_id"]}
                className="btn btn-secondary border"
              >
                Open
              </Link>
            );
            break;

          case "trainingexam":
            eventType = "Training";
            // backgroundColor = "rgb(55, 117, 210, .2)"; //"#3775D2";
            // hex = "#3775d2";
            backgroundColor = colors.training; //"rgb(55, 117, 210, .2)"; //"#3775D2";
            hex = colors.training; //"#3775d2";
            //  icon = "/uni/svg/tests.svg";
            icon = "/uni/svg/test.svg";
            moreLink =
              "/event/training/" +
              itm["training"]["_id"] +
              "/module/" +
              itm["module"]._id;
            joinBtn = (
              <Link
                to={
                  "/event/training/" +
                  itm["training"]["_id"] +
                  "/module/" +
                  itm["module"]._id
                }
                className="btn btn-secondary border"
              >
                Open
              </Link>
            );
            break;
        }

        let selectEvent = { ...itm };
        selectEvent["data"] = { ...itm };
        joinBtn = (
          <a
            href="javascript:void(0);"
            className="btn btn-secondary border"
            onClick={(e) => UniFun.UniAgendaSelectEvent(selectEvent)}
          >
            Open
          </a>
        );

        let from = moment(itm["from"]);
        let to = moment(itm["to"]);

        let when = "";

        // if (from.isSame(to)) {
        //   when = (
        //     <React.Fragment>
        //       {from.format("ddd DD MMM YY, HH:mm")}
        //     </React.Fragment>
        //   );
        // } else {
        //   when = (
        //     <React.Fragment>
        //       {from.format("ddd DD MMM YY, HH:mm")} to{" "}
        //       {to.format("ddd DD MMM YY, HH:mm")}
        //     </React.Fragment>
        //   );
        // }

        when = UniFun.fromToDateString(itm["from"], itm["to"]);

        if (type == "training") {
          if (itm["startDate"]) {
            when = UniFun.fromToDateString(itm["startDate"], itm["endDate"]);
          } else {
            when = "Self paced";
          }
        }

        let formattedRecord = {
          id: itm["_id"],
          title: itm["title"],
          start: moment(itm["from"]).toDate(),
          end: moment(itm["to"]).toDate(),
          allDay: false,
          backgroundColor: backgroundColor,
          data: itm,
          hex: hex,
          when: when,
          createdAt: itm.createdAt,
          startDate: itm["startDate"],
          organizer: [itm.organizer],
          eventType: eventType,
          category: type,
          type: itm["type"],
          joinBtn: joinBtn,
          editBtn: editBtn,
          from: itm["from"],
          moreLink: moreLink,
          to: itm["to"],
          subtitle: UniFun.fromToDateString(itm["from"], itm["to"]),
          icon: icon,
          event: {
            id: itm["_id"],
            title: itm["title"],
            moreLink: moreLink,
            start: moment(itm["from"]).toDate(),
            end: moment(itm["to"]).toDate(),
            from: itm["from"],
            to: itm["to"],
            eventType: itm["type"],
            allDay: false,
            startDate: itm["startDate"],
            training: itm["training"],
            backgroundColor: backgroundColor,
            data: itm,
            hex: hex,
            duration: itm["duration"],
          },
        };

        formattedData.push(formattedRecord);
      });
    }
  }
  return formattedData;
}

function UniAgendaFormatter(data) {
  let formattedData = [];
  let formattedEvents = [];
  if (data) {
    if (data.data) {
      data.data.map((itm, idx) => {
        let backgroundColor = "";
        let hex = "";
        let type = itm["type"];

        if (itm["training"]) {
          if (itm["training"]["_id"]) {
            type = "training" + type;
          }
        }

        let colors = {
          conference: "#5B2E7D",
          exam: "#124C59",
          meeting: "#311259",
          training: "#2B358A",
          stream: "#311259",
        };

        if (data.colours) {
          colors = {
            conference: data.colours.conference
              ? data.colours.conference
              : "#5B2E7D",
            exam: data.colours.exam ? data.colours.exam : "#124C59",
            meeting: data.colours.meeting ? data.colours.meeting : "#311259",
            training: data.colours.training ? data.colours.training : "#2B358A",
            stream: data.colours.stream ? data.colours.stream : "#311259",
          };
        }

        let icon = "";
        let eventType = "";
        let moreLink = "";
        switch (type) {
          case "exam":
            backgroundColor = colors.exam; //"rgb(79, 147, 162, .2)"; // "#4F93A2";
            hex = colors.exam; //"#4F93A2";
            icon = "/uni/svg/tests.svg";
            eventType = "Test";
            moreLink = itm["training"]
              ? "/event/training/" +
                itm["training"]["_id"] +
                "/module/" +
                itm["module"]._id
              : "/event/test/" + itm["_id"];
            break;

          case "meeting":
            backgroundColor = colors.meeting; //"rgb(116, 79, 162, .2)"; //"#744FA2";
            hex = colors.meeting; //"#744FA2";
            icon = "/uni/svg/videoconferences.svg";
            eventType = "Meeting";
            moreLink = itm["training"]
              ? "/event/training/" +
                itm["training"]["_id"] +
                "/module/" +
                itm["module"]._id
              : "/event/meeting/" + itm["_id"];
            break;

          case "stream":
            backgroundColor = colors.conference; //"rgb(162, 149, 79, .2)"; //"#3775D2";
            hex = colors.conference; //"#A2954F";
            icon = "/uni/svg/stream_calendar.svg";
            eventType = "Stream";
            moreLink = "/conference/" + itm["_id"];
            break;

          case "trainingconference":
            backgroundColor = colors.training; //"rgb(55, 117, 210, .2)"; //"#3775D2";
            hex = colors.training; //"#3775D2";
            icon = "/uni/svg/videoconferences.svg";
            eventType = "Training";
            moreLink =
              "/event/training/" +
              itm["training"]["_id"] +
              "/module/" +
              itm["module"]._id;
            break;

          case "trainingexam":
            backgroundColor = colors.training; //"rgb(55, 117, 210, .2)"; //"#3775D2";
            hex = colors.training; //"#3775D2";
            icon = "/uni/svg/tests.svg";
            eventType = "Training";
            moreLink =
              "/event/training/" +
              itm["training"]["_id"] +
              "/module/" +
              itm["module"]._id;
            break;
        }

        let event = {
          id: itm["_id"],
          title: itm["title"],
          eventType: eventType,
          moreLink: moreLink,
          titleAgendaEvent: (
            <span title={itm["title"]} className="text-white">
              <img
                src={icon}
                style={{ height: "16px" }}
                className="mr-2 pb-1 filter-white"
              ></img>
              <span>{itm["title"]}</span>{" "}
              <span className="ml-2 ">
                {moment(itm["from"]).format("HH:mm")}
                {/* {" - "}
                {moment(itm["to"]).format("HH:mm")} */}
              </span>
            </span>
          ),
          duration: itm["duration"],
          start: moment(itm["from"]).toDate(),
          end: moment(itm["to"]).toDate(),
          from: itm["from"],
          to: itm["to"],
          subtitle: UniFun.fromToDateString(itm["from"], itm["to"]),
          allDay: false,
          icon: "",
          backgroundColor: backgroundColor,
          data: itm,
          hex: hex,
        };
        formattedEvents.push(event);
      });
    }
  }
  formattedData.push({ events: formattedEvents });

  return formattedData;
}
