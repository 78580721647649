import React, { Component } from "react";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";

import MarkerClusterer from "node-js-marker-clusterer";

class Maps extends Component {
  marker = null;

  state = {
    stateLayers: [],
    inputPacId: React.createRef(),
    drawingModes: [],
    markerClusterer: MarkerClusterer,
    address: "",
    x: "37.9550852",
    y: "23.8500882",
    style: {
      width: "100%",
      height: "400px",
      position: "relative",
    },
    map: null,
    mapProps: null,
    value: "",
    zoom: 14,
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    let height = "400px";
    let zoom = 14;

    if (this.props.field.config) {
      if (this.props.field.config.height) {
        height = this.props.field.config.height;
      }
      if (this.props.field.config.zoom) {
        zoom = this.props.field.config.zoom;
      }
    }

    this.setState({
      style: { height: height, width: "100%", position: "relative" },
      zoom: zoom,
    });
  }

  addLayer(layers) {
    let stateLayers = [];

    this.setState({ stateLayers: stateLayers });
  }

  setMarker() {
    this.setState({ marker: null });

    this.updateMarker({
      lat: this.state.map.getCenter().lat(),
      lng: this.state.map.getCenter().lng(),
      map: this.state.map,
      mapProps: this.state.mapProps,
      draggable: true,
    });

    this.geocodePosition({
      mapProps: this.state.mapProps,
      pos: this.state.map.getCenter(),
    });
  }

  geocodePosition(args) {
    const { google } = args.mapProps;
    let geocoder = new google.maps.Geocoder();
    geocoder.geocode(
      {
        latLng: args.pos,
      },
      function (results, status) {
        if (status == google.maps.GeocoderStatus.OK) {
          let place = results[0];
          if (place) {
            let addressObject = {
              address: place.address_components,
              location: {
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng(),
              },
            };
            args.mapProps.object.setState({ value: addressObject });

            args.mapProps.object.props.field.config.savePlaces(
              args.mapProps,
              args.map,
              args.mapProps.object.state.value
            );
          }
        }
      }
    );
  }

  updateMarker(args) {
    const { google } = args.mapProps;

    args.mapProps.object.setState({ marker: null });

    if (args.mapProps.object.marker) {
      args.mapProps.object.marker.setMap(null);
    }
    args.mapProps.object.marker = new google.maps.Marker({
      position: {
        lat: args.lat,
        lng: args.lng,
      },
      draggable: args.draggable,
      map: args.map,
    });

    google.maps.event.addListener(
      args.mapProps.object.marker,
      "dragend",
      function () {
        // mapProps.object.setState({ value: addressObject });
        args.mapProps.object.geocodePosition({
          mapProps: args.mapProps,
          pos: args.mapProps.object.marker.getPosition(),
        });
      }
    );
  }

  searchPlaces(mapProps, map) {
    const { google } = mapProps;
    let input = document.getElementById(
      "pac-inputsearch-" + this.state.inputPacId
    );
    let markerButton = document.getElementById(
      "pac-marker-" + this.state.inputPacId
    );
    let saveSearch = mapProps.object.props.field.config.savePlaces
      ? true
      : false;

    var autocomplete = new google.maps.places.Autocomplete(input, {
      componentRestrictions: { country: "gr" },
      language: "el",
    });

    // var searchBox = new google.maps.places.SearchBox(input);

    map.controls[google.maps.ControlPosition.TOP_CENTER].push(input);
    map.controls[google.maps.ControlPosition.TOP_CENTER].push(markerButton);
    autocomplete.addListener("place_changed", function () {
      var places = [autocomplete.getPlace()];

      if (places.length == 0) {
        return;
      }
      var bounds = new google.maps.LatLngBounds();
      places.forEach(function (place) {
        if (!place.geometry) {
          return;
        }
        if (saveSearch) {
          let addressObject = {
            address: place.address_components,
            location: {
              lat: place.geometry.location.lat(),
              lng: place.geometry.location.lng(),
            },
          };

          mapProps.object.updateMarker({
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
            map: map,
            mapProps: mapProps,
            draggable: true,
          });

          mapProps.object.setState({ value: addressObject });

          mapProps.object.props.field.config.savePlaces(
            mapProps,
            map,
            mapProps.object.state.value
          );
        }

        if (place.geometry.viewport) {
          bounds.union(place.geometry.viewport);
        } else {
          bounds.extend(place.geometry.location);
        }
      });
      map.fitBounds(bounds);
    });
  }

  initMap(mapProps, map) {
    mapProps.object.setState({ mapProps: mapProps, map: map });
    if (mapProps.object.props.field.config) {
      if (mapProps.object.props.field.config.layers) {
        let layers = mapProps.object.props.field.config.layers(
          mapProps.object.props.data
        );
        mapProps.object.addLayer(layers);
      }

      if (mapProps.object.props.field.config.searchPlaces) {
        mapProps.object.searchPlaces(mapProps, map);
      }

      if (mapProps.object.props.field.config) {
        if (mapProps.object.props.field.config.initMap) {
          mapProps.object.props.field.config.initMap(mapProps, map);
        }
      }
    }
  }

  render() {
    var attributes = {};

    if (this.props.field.attributes) {
      attributes = this.props.field.attributes;
    }
    return (
      <div style={this.state.style} {...attributes}>
        {this.props.field.config.searchPlaces ? (
          <React.Fragment>
            <input
              id={"pac-inputsearch-" + this.state.inputPacId}
              className="form-control"
              style={{ width: "50%" }}
              type="text"
              placeholder="Αναζήτηση"
            ></input>
            <button
              id={"pac-marker-" + this.state.inputPacId}
              className="btn btn-primary"
              type="button"
              onClick={this.setMarker.bind(this)}
            >
              <i className="fa fa-map-marker" aria-hidden="true"></i> Ορισμός
              σημείου
            </button>
          </React.Fragment>
        ) : (
          ""
        )}
        <Map
          google={this.props.google}
          object={this}
          zoom={this.state.zoom}
          onReady={this.initMap}
          initialCenter={{
            lat: this.state.x,
            lng: this.state.y,
          }}
        ></Map>
        <input
          type="hidden"
          defaultValue={JSON.stringify(this.state.value)}
          name={this.props.field.alias}
          {...attributes}
        ></input>
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyBUteu35Jd0jEjAZ_bHvdvK8CHWYhr1epE",
  libraries: ["places", "drawing"],
  language: "el&region=GR",
  region: "EL",
})(Maps);
