import React, { Component } from "react";

class Checkbox extends Component {
  state = {
    name: "",
    id: "",
    placeholder: "",
    attributes: "",
    value: "",
    defaultValue: "",
    loaded: false,
    editable: true,
    component: null,
    attributes: "",
    events: {},
    customLayout: null,
    useCustomLayout: true,
    editable: true,
  };

  constructor(props) {
    super(props);

    let events = {};
    if (this.props.field.events) {
      Object.keys(this.props.field.events).map((itm, idx) => {
        let itmName = itm;

        if (itm == "onChange") {
          itmName = "onChangeFromEvents";
        }

        this[itmName] = this.props.field.events[itm];
        this[itmName] = this[itmName].bind(this);
        events[itmName] = this[itmName];
      });
    }

    this.editable = this.editable.bind(this);
    this.nonEditable = this.nonEditable.bind(this);

    this.handleInputChange = this.handleInputChange.bind(this);

    let useTrueFalseValue = false;
    let useHiddenField = false;

    if (this.props.field.config) {
      if (this.props.field.config.useTrueFalseValue) {
        useTrueFalseValue = this.props.field.config.useTrueFalseValue;
      }
      if (this.props.field.config.useHiddenField) {
        useHiddenField = this.props.field.config.useHiddenField;
      }
    }

    this.state = {
      events: events,
      useTrueFalseValue: useTrueFalseValue,
      useHiddenField: useHiddenField,
      editable:
        this.props.field.editable != null
          ? this.props.field.editable
          : this.state.editable,
      defaultValue: this.props.value ? this.props.value : "",
    };
  }

  validator() {}

  componentDidMount() {
    this.setState({
      attributes: this.props.field.attributes,
      name: this.props.field.alias,
      checked:
        this.state.defaultValue == "true" || this.state.defaultValue == true
          ? "checked"
          : "",
      editable:
        this.props.field.editable != null
          ? this.props.field.editable
          : this.state.editable,
      customLayout: this.props.field.customLayout,
      useCustomLayout:
        typeof this.props.field.useCustomLayout !== "undefined"
          ? this.props.field.useCustomLayout
          : true,
    });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.checked ? "checked" : "";

    if (this.state.useTrueFalseValue) {
      this.setState({
        checked: value,
        defaultValue: value ? true : false,
      });
    } else {
      this.setState({
        checked: value,
        // defaultValue: value ? true : false,
      });
    }

    if (this["onChangeFromEvents"]) {
      this["onChangeFromEvents"]();
    }
  }

  editable() {
    let output = (
      <React.Fragment>
        <input
          type="checkbox"
          checked={this.state.checked}
          name={this.state.name}
          defaultValue={this.state.defaultValue}
          onChange={this.handleInputChange}
          {...this.state.attributes}
          {...this.state.events}
        ></input>
        {/* <input
          type="hidden"
          name={this.state.name}
          defaultValue={this.state.defaultValue ? true : false}
        ></input> */}
      </React.Fragment>
    );

    if (this.state.useHiddenField) {
      let defaultValue = this.state.defaultValue;
      if (defaultValue == "false" || defaultValue == false) {
        defaultValue = "false";
      } else {
        defaultValue = "true";
      }
      output = (
        <React.Fragment>
          <input
            type="checkbox"
            checked={this.state.checked}
            defaultValue={this.state.defaultValue}
            onChange={this.handleInputChange}
            {...this.state.attributes}
            {...this.state.events}
          ></input>
          <input
            type="hidden"
            name={this.state.name}
            defaultValue={defaultValue}
          ></input>
        </React.Fragment>
      );
    }

    if (this.state.customLayout && this.state.useCustomLayout == true) {
      this.state.customLayout = this.state.customLayout.bind(this);
      return this.state.customLayout({ control: output, object: this });
    }

    return output;
  }

  nonEditable() {
    let output = (
      <input
        type="checkbox"
        checked={this.state.checked}
        disabled="disabled"
        name={this.state.name}
        {...this.state.attributes}
        {...this.state.events}
      ></input>
    );
    if (this.state.customLayout && this.state.useCustomLayout == true) {
      this.state.customLayout = this.state.customLayout.bind(this);
      return this.state.customLayout({ control: output, object: this });
    }

    return output;
  }

  render() {
    let output = this.state.editable ? this.editable() : this.nonEditable();

    return output;
  }
}

export default Checkbox;
