import React, { Component } from "react";
import Fun from "../../../lib/functions";
import Builder from "../../../lib/builder";
import UniFun from "../functions";
import { Link } from "react-router-dom";
import moment from "moment";

function Mapper(data) {
  let formattedData = [];

  if (data) {
    if (data.data) {
      formattedData.push({ data: data.data });
    }
  }

  return formattedData;
}

function Properties(args) {
  let editable = args.editable;

  return {
    data: {
      alias: "data",
      control: "text",
      editable: editable,
      label: "",
      attributes: {
        className: "form-control",
      },
    },
  };
}

const UniEvents = {
  auth: "",
  views: {
    list: {
      apis: {
        view: {
          url: "/event/info",
          parameters: {
            headers: { "content-type": "application/json" },
          },
          formatter: function (data, builder) {
            return Mapper(data, builder);
          },
        },
      },
      template: "eventsoverview",
      templateConfig: {
        headerTitle: "Overview",
        colsTemplateCount: 12,
        includeProperties: ["data"],
        header: UniFun.commonHeader,
      },
      properties: Properties({ editable: false }),
    },
  },
};

export default UniEvents;
