import React, { Component } from "react";
import Control from "./control";
import SimpleReactValidator from "simple-react-validator";
import Fun from "../../../lib/functions";
import UniFun from "../../../modules/uni/functions";
import moment from "moment";
import TopTemplateHeader from "./topTemplateHeader";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";

function msToTime(duration) {
  var milliseconds = Math.floor((duration % 1000) / 100),
    seconds = Math.floor((duration / 1000) % 60),
    minutes = Math.floor((duration / (1000 * 60)) % 60),
    hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

  hours = hours < 10 ? hours : hours;
  minutes = minutes < 10 ? minutes : minutes;

  if (hours == 0 && minutes == 0) {
    return "";
  }

  if (hours == 0) {
    return minutes + " min remaining to complete";
  }

  return hours + " hr " + minutes + " min remaining to complete";
}

class TrainingSession extends Component {
  state = {
    data: this.props.data,
    loaded: false,
    colsTemplateCount: 12,
    colsFormCount: 12,
    cardBodyCustomClass: "",
    module: {
      _id: "",
      title: "",
      description: "",
    },
    session: {},
  };

  constructor(props) {
    super(props);
    let builder = this.props.builder.state;
    let templateConfig = builder.module.templateConfig;
    if (templateConfig.events) {
      Object.keys(templateConfig.events).map((itm, idx) => {
        this[itm] = templateConfig.events[itm].bind(this);
      });
    }

    this.sessionsMenu = this.sessionsMenu.bind(this);
    // this.validator = new SimpleReactValidator({
    //   autoForceUpdate: this,
    //   locale: "en",
    //   validators: {
    //     phone: {
    //       message: "Phone number is incorrect",
    //       rule: (val, params, validator) => {
    //         return (
    //           validator.helpers.testRegex(val, /^(2|6)[0-9\ \(\)\+]{9}$/i) &&
    //           params.indexOf(val) === -1
    //         );
    //       },
    //       messageReplace: (message, params) =>
    //         message.replace(":values", this.helpers.toSentence(params)),
    //     },
    //   },
    // });

    this.form = React.createRef();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.action) {
      return false;
    }
    return true;
  }

  componentDidMount() {
    let builder = this.props.builder.state;
    let templateConfig = builder.module.templateConfig;
    let useCard =
      typeof templateConfig.useCard !== "undefined"
        ? templateConfig.useCard
        : true;

    let templateTop = templateConfig.templateTop
      ? templateConfig.templateTop
      : () => {};
    this["templateTop"] = templateTop.bind(this);
    let templateBottom = templateConfig.templateBottom
      ? templateConfig.templateBottom
      : () => {};
    this["templateBottom"] = templateBottom.bind(this);
    let templateLeft = templateConfig.templateLeft
      ? templateConfig.templateLeft
      : () => {};
    this["templateLeft"] = templateLeft.bind(this);
    let templateRight = templateConfig.templateRight
      ? templateConfig.templateRight
      : () => {};
    this["templateRight"] = templateRight.bind(this);
    let cardTemplateTop = templateConfig.cardTemplateTop
      ? templateConfig.cardTemplateTop
      : () => {};
    this["cardTemplateTop"] = cardTemplateTop.bind(this);
    let cardTemplateBottom = templateConfig.cardTemplateBottom
      ? templateConfig.cardTemplateBottom
      : () => {};
    this["cardTemplateBottom"] = cardTemplateBottom.bind(this);
    let panelTop = templateConfig.panelTop ? templateConfig.panelTop : () => {};
    this["panelTop"] = panelTop.bind(this);
    let panelBottom = templateConfig.panelBottom
      ? templateConfig.panelBottom
      : () => {};
    this["panelBottom"] = panelBottom.bind(this);
    let panelLeft = templateConfig.panelLeft
      ? templateConfig.panelLeft
      : () => {};
    this["panelLeft"] = panelLeft.bind(this);
    let panelRight = templateConfig.panelRight
      ? templateConfig.panelRight
      : () => {};
    this["panelRight"] = panelRight.bind(this);
    let header = templateConfig.header ? templateConfig.header : () => {};
    this["header"] = header.bind(this);

    let colsTemplateCount = templateConfig.colsTemplateCount
      ? templateConfig.colsTemplateCount
      : this.state.colsTemplateCount;
    let colsFormCount = templateConfig.colsFormCount
      ? templateConfig.colsFormCount
      : this.state.colsFormCount;

    let cardBodyCustomClass = templateConfig.cardBodyCustomClass
      ? templateConfig.cardBodyCustomClass
      : this.state.cardBodyCustomClass;

    let currentModule = this.state.module;
    let currentSession = this.state.session;

    this.state.data.forEach((itm, idx) => {
      itm.modules.value.forEach((module, midx) => {
        if (module._id == Fun.getURLPathValue(5)) {
          currentModule = module;
          currentModule["trainingId"] = itm._id.value;
          currentSession = module.sessions[0];
        }
      });
    });

    currentModule.sessions.forEach((session, sidx) => {
      if (session._id == Fun.getURLPathValue(7)) {
        currentSession = session;
      }
    });

    let instructors = [];
    let instructorsIds = [];
    currentSession.instructors.forEach((iitm, iidx) => {
      if (instructorsIds.indexOf(iitm._id) < 0) {
        instructorsIds.push(iitm._id);
        instructors.push(iitm);
      }
    });

    this.setState({
      loaded: true,
      rawData: this.props.rawData,
      colsTemplateCount: colsTemplateCount,
      colsFormCount: colsFormCount,
      cardBodyCustomClass: cardBodyCustomClass,
      useCard: useCard,
      module: currentModule,
      session: currentSession,
      instructors: instructors,
    });

    this.validator = this.props.builder.validator;
    if (this.componentDidMountEvent) {
      this.componentDidMountEvent();
    }
  }

  output() {
    let out = [];

    let events = [];

    let isMember = true;

    if (!UniFun.userIsEligible({ forWhat: "member" })) {
      isMember = false;
    }
    if (UniFun.userIsEligible({ forWhat: "isInstructor" })) {
      isMember = false;
    }
    if (UniFun.userIsEligible({ forWhat: "viewer" })) {
      isMember = false;
    }
    this.state.session.videoConference.map((vitm, vidx) => {
      events.push(
        <div
          className="row pointer action-btn rowhover"
          data-event-start={moment(vitm.from).valueOf()}
          data-event-end={moment(vitm.to).valueOf()}
          data-event-url={"/meeting/v-" + vitm._id}
        >
          <div className="col p-3">
            <div>
              {vitm.done && isMember ? (
                <img
                  src="/uni/svg/trainings/finished.svg"
                  style={{ width: "20px" }}
                  className="mr-2"
                />
              ) : (
                <img
                  src="/uni/svg/videocall.svg"
                  className="mr-2 mb-1 filter-light-grey"
                  style={{ width: "20px" }}
                />
              )}
              <strong>Video conference: </strong>
              {vitm.title}
              <span className="ml-2">
                <img
                  src="/uni/svg/trainings/event_locked.svg"
                  style={{ width: "20px" }}
                  className="mr-2 action-btn event-icon mb-2"
                  data-tip="Event locked"
                  data-event-start={moment(vitm.from).valueOf()}
                  data-event-end={moment(vitm.to).valueOf()}
                />
              </span>
            </div>
            <div className="text-grey " style={{ paddingLeft: "30px" }}>
              {/* // {moment(vitm.from).format("ddd DD MMM YYYY, HH:mm")} */}
              {UniFun.fromToDateString(vitm.from, vitm.to)}
            </div>
          </div>
        </div>
      );
    });

    this.state.session.videoPresentation.map((vitm, vidx) => {
      if (vitm.mimetype.startsWith("video/mp4")) {
        events.push(
          <Link
            className="row pointer rowhover"
            to={"/video/viewer/" + vitm._id + "?title=" + vitm.title}
          >
            <div className="col p-3">
              <div>
                {vitm.done && isMember ? (
                  <img
                    src="/uni/svg/trainings/finished.svg"
                    style={{ width: "20px" }}
                    className="mr-2"
                  />
                ) : (
                  <img
                    src="/uni/svg/video.svg"
                    className="mr-2 mb-1 filter-light-grey"
                    style={{ width: "20px" }}
                  />
                )}
                <strong>Video: </strong>
                {vitm.title}
              </div>
              <div className="text-grey " style={{ paddingLeft: "30px" }}>
                Self paced
              </div>
            </div>
          </Link>
        );
      } else {
        events.push(
          <div
            className="row pointer rowhover"
            onClick={(e) => {
              UniFun.readingsPreviewFile(
                {
                  title: vitm.title,
                  mimetype: "video/mp4",
                  fileurl: window["baseurl"](
                    "/agenda-file/data/" +
                      vitm._id +
                      "?type=preview&jwt=" +
                      localStorage.getItem("jwt")
                  ),
                  filedownloadurl: window["baseurl"](
                    "/agenda-file/data/" +
                      vitm._id +
                      "?type=download&jwt=" +
                      localStorage.getItem("jwt")
                  ),
                },
                null
              );
            }}
          >
            <div className="col p-3">
              <div>
                {vitm.done && isMember ? (
                  <img
                    src="/uni/svg/trainings/finished.svg"
                    style={{ width: "20px" }}
                    className="mr-2"
                  />
                ) : (
                  <img
                    src="/uni/svg/video.svg"
                    className="mr-2 mb-1 filter-light-grey"
                    style={{ width: "20px" }}
                  />
                )}
                <strong>Video: </strong>
                {vitm.title}
              </div>
              <div className="text-grey " style={{ paddingLeft: "30px" }}>
                Self paced
              </div>
            </div>
          </div>
        );
      }
    });
    this.state.session.reading.map((vitm, vidx) => {
      let duration = vitm.duration;
      let remainingMiliseconds = vitm.remainingMiliseconds;

      let durationLabel = "";

      if (duration) {
        if (duration.hours && duration.hours != "0") {
          durationLabel = duration.hours + " hr ";
        }

        if (duration.minutes) {
          durationLabel = durationLabel + duration.minutes + " min reading";
        }

        if (!vitm.done) {
          if (
            UniFun.userIsEligible({ forWhat: "member" }) &&
            !UniFun.userIsEligible({ forWhat: "viewer" })
          ) {
            durationLabel += " remaining to complete";
          }
        }
        if (duration.hours == "0" && duration.minutes == "0") {
          durationLabel = "";
        }
      }

      if (!vitm.done) {
        if (remainingMiliseconds) {
          durationLabel = msToTime(remainingMiliseconds);
        }
      }

      let reading = (
        <div className="col p-3">
          <div>
            {vitm.done && isMember ? (
              <img
                src="/uni/svg/trainings/finished.svg"
                style={{ width: "20px" }}
                className="mr-2 "
              />
            ) : (
              <img
                src="/uni/svg/reading.svg"
                className="mr-2 mb-1 filter-light-grey"
                style={{ width: "20px" }}
              />
            )}
            <strong>Reading: </strong>
            {vitm.title}
          </div>
          <div className="text-grey " style={{ paddingLeft: "30px" }}>
            Self paced {durationLabel ? " | " + durationLabel : ""}
          </div>
        </div>
      );

      if (vitm.mimetype.startsWith("application/epub")) {
        events.push(
          <Link
            className="row pointer rowhover"
            to={"/epub/viewer/" + vitm._id + "?title=" + vitm.title}
          >
            {reading}
          </Link>
        );
      } else if (vitm.mimetype.startsWith("application/pdf")) {
        events.push(
          <Link
            className="row pointer rowhover"
            to={"/pdf/viewer/" + vitm._id + "?title=" + vitm.title}
          >
            {reading}
          </Link>
        );
      } else {
        events.push(
          <div
            className="row pointer rowhover"
            onClick={(e) => {
              UniFun.readingsPreviewFile(
                {
                  title: vitm.title,
                  mimetype: vitm.mimetype,
                  fileurl: window["baseurl"](
                    "/agenda-file/data/" +
                      vitm._id +
                      "?type=preview&jwt=" +
                      localStorage.getItem("jwt")
                  ),
                  filedownloadurl: window["baseurl"](
                    "/agenda-file/data/" +
                      vitm._id +
                      "?type=download&jwt=" +
                      localStorage.getItem("jwt")
                  ),
                },
                null
              );
            }}
          >
            {reading}
          </div>
        );
      }
    });
    this.state.session.exam.map((vitm, vidx) => {
      events.push(
        <div
          className="row pointer rowhover"
          onClick={(e) => {
            if (
              UniFun.userIsEligible({ forWhat: "member" }) &&
              !UniFun.userIsEligible({ forWhat: "isInstructor" }) &&
              !UniFun.userIsEligible({ forWhat: "viewer" })
            ) {
              if (
                moment(vitm.from) <= moment() &&
                moment(vitm.to) >= moment()
              ) {
                if (!vitm.done) {
                  UniFun.startExam(vitm);
                }
              }
            }
          }}
        >
          <div className="col p-3">
            <div>
              {vitm.done && isMember ? (
                <img
                  src="/uni/svg/trainings/finished.svg"
                  style={{ width: "20px" }}
                  className="mr-2 "
                />
              ) : (
                <img
                  src="/uni/svg/taketest.svg"
                  className="mr-2 mb-1 filter-light-grey"
                  style={{ width: "20px" }}
                />
              )}
              <strong>
                {vitm.passingGrade > 0 ? "Graded test" : "Practice test"}:{" "}
              </strong>
              {vitm.title}
              <span className="ml-2 ">
                {vitm.passingGrade && vitm.passingGrade > 0 ? (
                  <React.Fragment>
                    <img
                      src="/uni/svg/trainings/grade.svg"
                      style={{ width: "20px" }}
                      className="mr-2 mb-2"
                      data-tip={vitm.passingGrade + "% or higher to pass"}
                    />
                  </React.Fragment>
                ) : (
                  ""
                )}
                {vitm.done && isMember ? (
                  <img
                    src="/uni/svg/trainings/event_locked.svg"
                    style={{ width: "20px" }}
                    className="mr-2 action-btn  mb-2"
                    data-tip="Event locked"
                  />
                ) : (
                  <img
                    src="/uni/svg/trainings/event_locked.svg"
                    style={{ width: "20px" }}
                    className="mr-2 action-btn event-icon mb-2"
                    data-tip="Event locked"
                    data-event-start={moment(vitm.from).valueOf()}
                    data-event-end={moment(vitm.to).valueOf()}
                    data-event-dif="0"
                  />
                )}
              </span>
            </div>
            <div className="text-grey " style={{ paddingLeft: "30px" }}>
              {UniFun.fromToDateString(vitm.from, vitm.to)}
            </div>
          </div>
        </div>
      );
    });

    let sessionProgress = "";

    let full = this.state.session.full;
    let progress = this.state.session.progress;

    if (progress == 0) {
      sessionProgress = <span>{"Not started 0/" + full}</span>;
    } else if (progress < full) {
      sessionProgress = (
        <span className="text-primary">
          {"In progress " + progress + "/" + full}
        </span>
      );
    } else if (progress == full) {
      sessionProgress = (
        <span className="text-success">
          {"Completed " + progress + "/" + full}
        </span>
      );
    }

    if (UniFun.userIsEligible({ forWhat: "module" })) {
      sessionProgress = "";
    }

    if (UniFun.userIsEligible({ forWhat: "isInstructor" })) {
      sessionProgress = "";
    }

    if (UniFun.userIsEligible({ forWhat: "viewer" })) {
      sessionProgress = "";
    }

    return (
      <React.Fragment>
        <div className="row mb-3">
          <div className="col">
            <strong>{this.state.session.title}</strong>
            <span className="text-dark pl-3">{sessionProgress}</span>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="card">
              <div className="card-body">
                <div className="row p-3">
                  <div className="col border-bottom pb-3 pl-0">
                    {this.state.instructors.length > 0
                      ? UniFun.createProfileImageBubble(this.state.instructors)
                      : "No instructors available"}
                  </div>
                </div>
                {events}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  showTrainingAbout(title, description) {
    window["modal2"].setState({
      title: <h3 className="pr-5">{title}</h3>,
      size: "xl",
      targetComponent: this,
      show: true,
      footer: [],
      body: <p className="p-5">{Fun.parse(description)}</p>,
    });
  }

  sessionsMenu() {
    let output = [];

    this.state.module.sessions.forEach((itm, idx) => {
      let colorClass = "text-dark";
      if (itm._id == Fun.getURLPathValue(7)) {
        colorClass = "text-black text-underline";
      }

      if (!Fun.getURLPathValue(7) && idx == 0) {
        colorClass = "text-black text-underline";
      }

      output.push(
        <li className="nav-link">
          <Link
            to={
              "/event/training/" +
              this.state.module.trainingId +
              "/module/" +
              this.state.module._id +
              "/session/" +
              itm._id
            }
            className={colorClass}
          >
            Session {idx + 1}
          </Link>
        </li>
      );
    });

    return (
      <ul className="nav" style={{ paddingLeft: "38px" }}>
        {output}
      </ul>
    );
  }

  render() {
    if (this.state.loaded) {
      let _this = this;

      let sessionsMenu = this.sessionsMenu();
      return (
        <React.Fragment>
          <div className={"uniTopHeader "}>
            <TopTemplateHeader
              customClass="mb-4 uniFixedHeader"
              title={
                <React.Fragment>
                  <Link to={"/event/training/" + this.state.module.trainingId}>
                    <img
                      src="/uni/svg/trainings/go_back.svg"
                      className="pr-3 pointer"
                      style={{ width: "45px", paddingBottom: "5px" }}
                      onClick={(e) => {
                        _this.props.history.goBack();
                      }}
                    ></img>
                  </Link>{" "}
                  <span
                    data-tip={
                      this.state.module.title.length >= 50
                        ? this.state.module.title
                        : ""
                    }
                  >
                    {UniFun.substring(this.state.module.title, 0, 50)}
                  </span>
                  <img
                    src="/uni/svg/trainings/information.svg"
                    className="pl-4 pointer"
                    style={{ width: "40px", paddingBottom: "15px" }}
                    onClick={(e) => {
                      _this.showTrainingAbout(
                        this.state.module.title,
                        this.state.module.description
                          ? this.state.module.description
                          : "No description"
                      );
                    }}
                  ></img>
                </React.Fragment>
              }
              subtitle={sessionsMenu}
            ></TopTemplateHeader>
          </div>
          <div className="container-fluid">{this.output()}</div>
        </React.Fragment>
      );
    } else {
      return "loading...";
    }
  }
}

export default withRouter(TrainingSession);
