import React, { Component } from "react";
import Builder from "../../lib/builder";
import Fun from "../../lib/functions";
import UniFun from "./functions";
import { Link } from "react-router-dom";

const add = function (parentBuilder) {
  let builder = parentBuilder;
  window["modal"].setState({
    title: (
      <React.Fragment>
        <h3 className="pl-3">Add partner</h3>
      </React.Fragment>
    ),
    size: "lg",
    targetComponent: this,
    parentBuilder: builder,
    callback: function () {
      window["modal"].setState({ show: false, overflowY: "auto" });
      builder.rerunComponent();
    },
    footer: [
      <button
        type="button"
        className="btn btn-link border"
        onClick={() => {
          window["modal"].setState({ show: false, overflowY: "auto" });
        }}
      >
        Cancel
      </button>,
      <button
        type="button"
        className="btn btn-primary border"
        onClick={() => {
          Fun.submitFromModal("modalbody");
        }}
      >
        Save
      </button>,
    ],
    show: true,
    body: (
      <Builder
        module="unisponsors"
        view="add"
        key={Fun.uuid()}
        ref={(refComponent) => {
          window["modalbody"] = refComponent;
        }}
      ></Builder>
    ),
  });
};

const edit = function (id, parentBuilder) {
  let builder = parentBuilder;
  let parameters = {
    replace: {
      id: id,
    },
  };

  window["modal"].setState({
    title: (
      <React.Fragment>
        <h3 className="pl-3">Edit partner</h3>
      </React.Fragment>
    ),
    size: "lg",
    targetComponent: this,
    callback: function () {
      window["modal"].setState({ show: false, overflowY: "auto" });
      Fun.createNotification({
        type: "success",
        message: "Update was successful!",
      });
      builder.rerunComponent();
    },
    footer: UniFun.modalCommonFooter("modal", "Save"),
    show: true,
    body: (
      <Builder
        module="unisponsors"
        view="detail"
        parameters={parameters}
        key={Fun.uuid()}
        ref={(refComponent) => {
          window["modalbody"] = refComponent;
        }}
      ></Builder>
    ),
  });
};

const UniSponsors = {
  auth: "",
  views: {
    list: {
      auth: "",
      apis: {
        view: {
          url: "/sponsors?start={start}&offset={offset}",
          parameters: {
            replace: {
              start: 0,
              offset: 25,
            },
          },
          formatter: function (data) {
            return Formatter(data);
          },
        },
      },
      template: "grid",
      templateConfig: {
        headerTitle: "Conferences",
        headerTitleIcon: "/uni/svg/trainings/go_back.svg",
        colsTemplateCount: 12,
        header: UniFun.commonHeader,
        headerSubtitle: (
          <ul className="nav" style={{ paddingLeft: "38px" }}>
            <li className="nav-link">
              <Link to={"/events/conferences"}>List</Link>
            </li>
            <li className="nav-link">
              <Link
                to={"/events/conferences/sponsors"}
                className="text-black text-underline"
              >
                Partners
              </Link>
            </li>
          </ul>
        ),
        gridConfig: {
          headers: {
            title: {
              attributes: {
                className: "w-50",
              },
            },
          },
          cellTemplates: {
            title: function (data, rec, obj) {
              let canEdit = UniFun.userIsEligible({ forWhat: "default" })
                ? true
                : false;
              let uid = Fun.uuid();
              return (
                <React.Fragment>
                  {UniFun.substring(rec.title.value, 0, 70)}
                  <span className="float-right mr-4 grid-controls">
                    <i
                      class="fas fa-ellipsis-h pointer"
                      data-toggle="collapse"
                      data-target={"#" + uid}
                    ></i>
                    <div
                      className="position-absolute bg-white ml-4 mt-2 collapse"
                      id={uid}
                    >
                      <ul className="list-group " style={{ width: "200px" }}>
                        <li
                          className="list-group-item p-2 pl-3"
                          onClick={(e) =>
                            UniFun.createEventModal(
                              obj.props.builder,
                              rec._id.value,
                              "Edit partner",
                              "unisponsors",
                              "detail",
                              "modal",
                              "lg",
                              "Update"
                            )
                          }
                        >
                          Edit
                        </li>
                        <li
                          className="list-group-item p-2 pl-3 text-danger"
                          onClick={(e) =>
                            UniFun.createEventModal(
                              obj.props.builder,
                              rec._id.value,
                              "Delete partner",
                              "unisponsors",
                              "delete",
                              "modal",
                              "default",
                              "Delete",
                              "btn-danger"
                            )
                          }
                        >
                          Delete
                        </li>
                      </ul>
                    </div>
                  </span>
                </React.Fragment>
              );
            },
          },

          emptyFolder: function () {
            return (
              <div className="row mt-4 " style={{ height: "500px" }}>
                <div className="col-12 text-center">
                  <h5>
                    <strong>Partners are empty</strong>
                  </h5>
                  <p>Added partners will appear here</p>
                </div>
              </div>
            );
          },
        },
        includeProperties: ["title", "category"],

        cardTemplateTop: function () {
          let data = this.state.data;
          let parentBuilder = this.props.builder;
          let pagination = this.state.rawData.pagination;
          return (
            <div className="row">
              <div className="col-6 pt-2 pl-4">
                <span className="text-dark ">
                  There are {data.length} partners
                </span>
              </div>
              <div className="col-6 text-right p-2 pr-4">
                <button
                  type="button"
                  className="btn btn-primary bg-navy-blue"
                  onClick={(e) => add(parentBuilder)}
                >
                  Add partner
                </button>
              </div>
            </div>
          );
        },
        cardTemplateBottom: function () {
          //   let data = this.state.data;
          //   let pagination = this.state.rawData.pagination;
          //   let parentBuilder = this.props.builder;
          //   return (
          //     <div className="mt-4">
          //       {UniFun.pagination(
          //         pagination.currentPage,
          //         0,
          //         25,
          //         pagination.total,
          //         parentBuilder,
          //         "offset"
          //       )}
          //     </div>
          //   );
        },
      },
      pk: "id",
      properties: Properties({ editable: false }),
    },

    detail: {
      auth: "",
      apis: {
        view: {
          url: "/sponsors/{id}",
          parameters: {
            replace: {},
          },
          formatter: function (data) {
            return Formatter(data);
          },
        },
        update: {
          url: "/sponsor",
          parameters: {
            replace: {},
          },
          formatter: function (data) {
            return Formatter(data);
          },
        },
      },
      template: "form",
      templateConfig: {
        excludeProperties: ["none"],
        events: {
          onSubmit: function (e) {
            let submittedData = Fun.getSubmitJSON(e);
            submittedData["logo"] = submittedData["logo"]
              ? UniFun.agendaFilesJson(JSON.parse(submittedData["logo"]))[0]
              : null;

            let builder = this.props.builder;

            Fun.update(e, {
              url: builder.state.module.apis.update,
              method: "PUT",
              parameters: {
                headers: {
                  "content-type": "application/json",
                },
              },
              callback: function (
                submittedData,
                status,
                responsedata,
                responsestatus
              ) {
                if (responsestatus == 200) {
                  if (window["modal"].state.callback) {
                    window["modal"].state.callback(submittedData);
                  }
                } else {
                  window["modal"].setState({ show: false });
                  let message = responsedata.data.message;

                  if (responsedata.data.exceptions) {
                    if (responsedata.data.exceptions[0]) {
                      message =
                        responsedata.data.exceptions[0].errorDescription;
                    }
                  }

                  Fun.createNotification({
                    message: message,
                    type: "danger",
                  });
                }
              },
              submittedData: JSON.stringify(submittedData),
            });
          },
        },
      },
      pk: "id",
      properties: Properties({ editable: true }),
    },
    delete: {
      auth: "",
      apis: {
        view: {
          url: "/sponsors/{id}",
          parameters: {
            replace: {},
          },
          formatter: function (data) {
            return Formatter(data);
          },
        },
        delete: {
          url: "/sponsors/{id}",
          parameters: {
            replace: {},
          },
          formatter: function (data) {
            return Formatter(data);
          },
        },
      },
      template: "form",
      templateConfig: {
        includeProperties: ["_id"],
        useCard: false,
        events: {
          onSubmit: function (e) {
            let submittedData = Fun.getSubmitJSON(e);

            let builder = this.props.builder;

            Fun.update(e, {
              url: builder.state.module.apis.delete,
              method: "DELETE",
              parameters: {
                replace: {
                  id: submittedData["_id"],
                },
                headers: {
                  "content-type": "application/json",
                },
              },
              callback: function (
                submittedData,
                status,
                responsedata,
                responsestatus
              ) {
                if (responsestatus == 200) {
                  if (window["modal"].state.callback) {
                    window["modal"].state.callback(submittedData);
                  }
                } else {
                  window["modal"].setState({ show: false, overflowY: "auto" });
                  let message = responsedata.data.message;

                  if (responsedata.data.exceptions) {
                    if (responsedata.data.exceptions[0]) {
                      message =
                        responsedata.data.exceptions[0].errorDescription;
                    }
                  }

                  Fun.createNotification({
                    message: message,
                    type: "danger",
                  });
                }
              },
              submittedData: submittedData,
            });
          },
        },
        templateTop: function () {
          let data = this.state.data[0];
          return (
            <div className=" p-4">
              <p>
                Are you sure you want to delete sponsor{" "}
                <strong>"{data.title.value}"</strong>
              </p>
            </div>
          );
        },
        colsTemplateCount: 10,
      },
      pk: "id",
      properties: Properties({ editable: false }),
    },
    add: {
      auth: "",
      apis: {
        view: {
          data: { data: [{}] },
          parameters: {
            replace: {},
          },
          formatter: function (data) {
            return Formatter(data);
          },
        },
        save: {
          url: "/sponsor",
          parameters: {
            replace: {},
          },
          formatter: function (data) {
            return Formatter(data);
          },
        },
      },
      template: "form",
      templateConfig: {
        excludeProperties: ["_id"],
        events: {
          onSubmit: function (e) {
            let submittedData = Fun.getSubmitJSON(e);

            submittedData["logo"] = submittedData["logo"]
              ? UniFun.agendaFilesJson(JSON.parse(submittedData["logo"]))[0]
              : null;

            let builder = this.props.builder;

            Fun.update(e, {
              url: builder.state.module.apis.save,
              method: "POST",
              parameters: {
                headers: {
                  "content-type": "application/json",
                },
              },
              callback: function (
                submittedData,
                status,
                responsedata,
                responsestatus
              ) {
                if (responsestatus == 200) {
                  if (window["modal"].state.callback) {
                    window["modal"].state.callback(submittedData);
                  }
                } else {
                  window["modal"].setState({ show: false, overflowY: "auto" });
                  let message = responsedata.data.message;

                  if (responsedata.data.exceptions) {
                    if (responsedata.data.exceptions[0]) {
                      message =
                        responsedata.data.exceptions[0].errorDescription;
                    }
                  }

                  Fun.createNotification({
                    message: message,
                    type: "danger",
                  });
                }
              },
              submittedData: submittedData,
            });
          },
        },
      },
      pk: "id",
      properties: Properties({ editable: true }),
    },
  },
};

export default UniSponsors;

// {
//     "deleted": false,
//     "_id": "61cc55b9676aad2bc31fbf03",
//     "title": "test1234",
//     "category": "Gold",
//     "logo": "61cc51fa4ff87f28ce1b5021",
//     "createdBy": "60d230307514f52ebf88693f",
//     "createdAt": "2021-12-29T12:34:01.724Z",
//     "updatedAt": "2021-12-29T12:34:01.724Z"
// }

function Properties(args) {
  let editable = args.editable;

  return {
    _id: {
      alias: "_id",
      control: "hidden",
      editable: editable,
      label: "",
      attributes: {
        className: "form-control",
      },
    },
    title: {
      alias: "title",
      control: "text",
      editable: editable,
      label: "Name",
      attributes: {
        className: "form-control",
      },
    },
    logo: {
      alias: "logo",
      control: "dropzone",
      label: "Partner logo",
      editable: editable,
      customContentLayout: function () {
        let files = JSON.parse(this.state.defaultValue);

        let content = [];

        Object.keys(files).map((itm, idx) => {
          content.push(
            UniFun.trainingNodeFileUI(files[itm], this.removeFile, itm)
          );
        });

        this.state.content?.map((itm, idx) => {
          // if (typeof files[itm.key] === "undefined" && itm.ref != null) {
          //   content.push(itm);
          // }
          if (this.state.pending.indexOf(itm.key) >= 0) {
            content.push(itm);
          }
        });

        return (
          <React.Fragment>
            {this.state.placeholder()}
            {content}
            {/* {content.length > 0 ? "" : this.state.placeholder()} */}
          </React.Fragment>
        );
      },

      useCustomLayout: true,
      config: {
        placeholder: function () {
          return (
            <div className="p-3">
              <strong>
                Drag & drop here or{" "}
                <a
                  href="javascript:void(0);"
                  className="text-primary"
                  onClick={this.onTargetClick}
                >
                  <strong>browse file</strong>
                </a>{" "}
                to attach
              </strong>
              <br></br>
              <span className="text-dark">
                Maximum size: 50MB - File type supported png,jpeg,jpg
              </span>
            </div>
          );
        },
      },
      events: {
        removeFile: function (id) {
          let defaultValue = {};

          Object.keys(JSON.parse(this.state.defaultValue)).map((itm, idx) => {
            if (itm != id) {
              defaultValue[itm] = JSON.parse(this.state.defaultValue)[itm];
            }
          });

          let content = [];
          this.state.content?.map((itm, idx) => {
            if (itm.key != id) {
              content.push(itm);
            }
          });

          this.setState({
            defaultValue: JSON.stringify(defaultValue),
            content: content,
          });

          return;
        },
        onDrop: function (files) {
          // this.setState({ defaultValue: JSON.stringify({}) });

          if (
            files[0].type == "image/jpeg" ||
            files[0].type == "image/jpg" ||
            files[0].type == "image/png"
          ) {
            UniFun.fileProgress({
              control: this,
              files: files,
              url: "/agenda-file",
              type: "meeting",
            });
          } else {
            Fun.createNotification({
              message: "Only png,jpeg,jpg files are allowed to upload",
              type: "danger",
            });
          }
        },
      },
      attributes: {
        className: "border rounded  dropzone-trainings",
      },
    },
    description: {
      alias: "description",
      control: "editor",
      editable: editable,
      label: "Description",
      attributes: {
        className: "form-control",
      },
    },
    link: {
      alias: "link",
      control: "text",
      editable: editable,
      label: "Link",
      attributes: {
        className: "form-control",
      },
    },
    category: {
      alias: "category",
      control: "select",
      label: "Category",
      editable: editable,
      config: {
        options: function () {
          let control = this;

          Fun.fetch({
            url: {
              url: "/sponsorCategories",
              parameters: {
                replace: {},
                headers: {},
              },
            },
            callback: function (args) {
              let output = [];

              let data = args.data;

              data.map((itm, idx) => {
                output.push({
                  label: itm,
                  value: itm,
                });
              });

              control.setState({ options: output });
            },
          });

          return [];
        },
        // options: [
        //   { label: "Diamond", value: "Diamond" },
        //   { label: "Platinum", value: "Platinum" },
        //   { label: "Gold", value: "Gold" },
        //   { label: "Silver", value: "Silver" },
        //   { label: "Bronze", value: "Bronze" },
        // ],
      },
      attributes: {
        className: "form-control",
      },
    },
  };
}

function Formatter(data) {
  if (data) {
    let formattedData = [];
    data.data.map((itm, idx) => {
      let formattedRecord = {};
      formattedRecord["_id"] = itm._id;
      formattedRecord["category"] = itm.category;
      formattedRecord["logo"] = [itm.logo];
      formattedRecord["title"] = itm.title;
      formattedRecord["link"] = itm.link;
      formattedRecord["description"] = itm.description;

      formattedData.push(formattedRecord);
    });

    return formattedData;
  }
}
