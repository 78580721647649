import React, { Component } from "react";
import Fun from "../../../lib/functions";
import UniFun from "../functions";
import moment from "moment";

const UniTrainingTests = {
  auth: "",
  views: {
    detail: {
      auth: "",
      apis: {
        view: {
          data: { data: [{}] },
          parameters: {
            headers: {},
            replace: {},
          },
          formatter: function (data) {
            return UniTrainingTestsFormatter(data);
          },
        },
      },
      template: "form",
      templateConfig: {
        events: {
          onSubmit: function (e) {
            e.preventDefault();
            let submittedData = Fun.getSubmitJSON(e);

            if (submittedData["exam"]) {
              window["modal2"].setState({
                show: false,
                data: submittedData,
                overflowY: "auto",
              });

              if (window["modal2"].state.callback) {
                window["modal2"].state.callback(submittedData);
              } else {
                if (window["modal2"].state.targetComponent) {
                  window["modal2"].state.targetComponent.setState({
                    data: submittedData,
                  });
                }
              }
            }
          },
        },
        includeProperties: ["exam"],
      },
      pk: "_id",
      properties: UniTrainingTestsProperties({ editable: true }),
    },
    trainingEventDetail: {
      auth: "",
      apis: {
        view: {
          data: { data: [{}] },
          parameters: {
            headers: {},
            replace: {},
          },
          formatter: function (data) {
            return UniTrainingEditTestsFormatter(data);
          },
        },
      },
      template: "form",
      templateConfig: {
        panelBottom: function () {
          return <p></p>;
        },
        events: {
          onSubmit: function (e) {
            e.preventDefault();
            let submittedData = Fun.getSubmitJSON(e);

            if (
              !UniFun.validateTrainingTest(
                this.props.builder.validator,
                Fun.getSubmitJSON(e)
              )
            ) {
              return;
            }

            if (window["modal2"].state.callback) {
              window["modal2"].state.callback(submittedData);
              window["modal2"].setState({ show: false, overflowY: "auto" });
            } else {
              if (window["modal2"].state.targetComponent) {
                window["modal2"].state.targetComponent.setState({
                  data: submittedData,
                });
              }
              window["modal2"].setState({
                show: false,
                data: submittedData,
                overflowY: "auto",
              });
            }
          },
        },
        includeProperties: [
          "exam",
          "from",
          "fromToDateTime",
          "evaluators",
          "examDuration",
          "reminders",
        ],
      },
      pk: "_id",
      properties: UniTrainingTestsProperties({ editable: true }),
    },
    eventDetail: {
      auth: "",
      apis: {
        view: {
          data: { data: [{}] },
          parameters: {
            headers: {},
            replace: {},
          },
          formatter: function (data) {
            return UniTrainingTestsFormatter(data);
          },
        },
      },
      template: "form",
      templateConfig: {
        panelBottom: function () {
          return <p></p>;
        },
        events: {
          onSubmit: function (e) {
            e.preventDefault();
            let submittedData = Fun.getSubmitJSON(e);

            if (window["modal2"].state.callback) {
              window["modal2"].state.callback(submittedData);
            } else {
              if (window["modal2"].state.targetComponent) {
                window["modal2"].state.targetComponent.setState({
                  data: submittedData,
                });
              }
            }
            window["modal2"].setState({
              show: false,
              data: submittedData,
              overflowY: "auto",
            });
          },
        },
        includeProperties: [
          "exam",
          "from",
          "fromToDateTime",
          "examDuration",
          "reminders",
          //  "participants",
        ],
      },
      pk: "_id",
      properties: UniTrainingTestsProperties({ editable: true }),
    },
    add: {
      auth: "",
      apis: {
        view: {
          data: { data: [{}] },
          parameters: {
            headers: {},
            replace: {},
          },
          formatter: function (data) {
            return UniTrainingTestsFormatter(data);
          },
        },
        save: {
          url: "/event/exam",
          parameters: {
            headers: {},
            replace: {},
          },
          formatter: function (data) {
            return UniTrainingTestsFormatter(data);
          },
        },
      },
      template: "form",
      templateConfig: {
        events: {
          onSubmit: function (e) {
            e.preventDefault();
            let submittedData = Fun.getSubmitJSON(e);

            if (window["modal2"].state.callback) {
              window["modal2"].state.callback(submittedData);
            } else {
              if (window["modal2"].state.targetComponent) {
                window["modal2"].state.targetComponent.setState({
                  data: submittedData,
                });
              }
            }
            window["modal2"].setState({
              show: false,
              data: submittedData,
              overflowY: "auto",
            });
          },
        },
        includeProperties: [
          "exam",
          "from",
          "fromToDateTime",
          "examDuration",
          "reminders",
          "participants",
        ],
      },
      pk: "_id",
      properties: UniTrainingTestsProperties({ editable: true }),
    },
  },
};

export default UniTrainingTests;

function UniTrainingTestsProperties(input) {
  let editable = input.editable;

  return {
    _id: {
      alias: "_id",
      control: "hidden",
      editable: editable,
    },
    exam: {
      alias: "exam",
      control: "select",
      label: "Select",
      editable: editable,
      placeholder: "No test created",
      config: {
        options: function () {
          let control = this;

          Fun.fetch({
            url: {
              url: "/template/exam?start={start}&offset={offset}",
              parameters: {
                replace: {
                  start: 0,
                  offset: 25,
                },
                headers: {},
              },
            },
            callback: function (args) {
              let output = [];

              let data = args.data;

              data.map((itm, idx) => {
                output.push({
                  label: itm.title,
                  value: JSON.stringify({ id: itm._id, title: itm.title }),
                });
              });

              control.setState({ options: output });
            },
          });

          return [];
        },
      },
      attributes: {
        className: "form-control",
      },
    },
    fromToDateTime: {
      alias: "fromToDateTime",
      control: "group",
      label: "Availability",
      editable: editable,
      customLayout: function (args) {
        return (
          <React.Fragment>
            <label>{args.object.props.field.label}</label>
            <div className="row mb-3">
              <div className="col-md-5 pr-0 availability-calendar">
                {args.control[0]}
              </div>
              <div className="col-md-1 text-center pr-0 pt-2 pl-0">to</div>
              <div className="col-md-5 pl-0 availability-calendar">
                {args.control[1]}
              </div>
              <div className="col-md-1"></div>
            </div>
          </React.Fragment>
        );
      },
      useCustomLayout: true,
      config: {
        properties: {
          fromDateTime: {
            alias: "fromDateTime",
            control: "group",
            editable: editable,
            customLayout: function (args) {
              return (
                <React.Fragment>
                  <div className="row mb-3">
                    <div className="col-md-8 pr-0">{args.control[0]}</div>
                    <div className="col-md-4 ">{args.control[1]}</div>
                  </div>
                </React.Fragment>
              );
            },
            useCustomLayout: true,
            config: {
              properties: {
                fromDate: {
                  alias: "fromDate",
                  control: "datetime",
                  config: {
                    fromFormat: "YYYY-MM-DDT00:00:00.000Z",
                    toFormat: "YYYY-MM-DDT00:00:00.000Z",
                    includeTime: false,
                    valueEditFormat: { dateStyle: "short" },
                    valueDisplayFormat: { dateStyle: "short" },
                  },
                  editable: editable,
                },
                fromTime: {
                  alias: "fromTime",
                  control: "select",
                  config: {
                    options: UniFun.timeSelect,
                  },
                  editable: editable,
                  attributes: {
                    className: "form-control",
                  },
                },
              },
            },
          },
          toDateTime: {
            alias: "toDateTime",
            control: "group",
            editable: editable,
            customLayout: function (args) {
              return (
                <React.Fragment>
                  <div className="row mb-3">
                    <div className="col-md-8 pr-0">{args.control[0]}</div>
                    <div className="col-md-4 ">{args.control[1]}</div>
                  </div>
                </React.Fragment>
              );
            },
            useCustomLayout: true,
            config: {
              properties: {
                toDate: {
                  alias: "toDate",
                  control: "datetime",
                  config: {
                    fromFormat: "YYYY-MM-DDT00:00:00.000Z",
                    toFormat: "YYYY-MM-DDT00:00:00.000Z",
                    includeTime: false,
                    valueEditFormat: { dateStyle: "short" },
                    valueDisplayFormat: { dateStyle: "short" },
                  },
                  editable: editable,
                },
                toTime: {
                  alias: "toTime",
                  control: "select",
                  config: {
                    options: UniFun.timeSelect,
                  },
                  editable: editable,
                  attributes: {
                    className: "form-control",
                  },
                },
              },
            },
          },
        },
      },
    },
    examDuration: {
      alias: "examDuration",
      control: "group",
      label: "Duration",
      editable: editable,
      customLayout: function (args) {
        return (
          <React.Fragment>
            <label>{args.object.props.field.label}</label>
            <div className="row mb-3">
              <div className="col-md-3 pr-0">
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <label className="input-group-text">Hours</label>
                  </div>
                  {args.control[0]}
                </div>
              </div>
              <div className="col-md-3 ">
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <label className="input-group-text">Minutes</label>
                  </div>
                  {args.control[1]}
                </div>
              </div>
              <div className="col-md-6"></div>
            </div>
          </React.Fragment>
        );
      },
      useCustomLayout: true,
      config: {
        properties: {
          hours: {
            alias: "hours",
            control: "number",
            editable: editable,
            attributes: {
              className: "form-control",
            },
          },
          minutes: {
            alias: "minutes",
            control: "number",
            editable: editable,
            attributes: {
              className: "form-control",
            },
          },
        },
      },
    },
    evaluators: {
      alias: "evaluators",
      label: "Choose evaluators",
      placeholder: "Select the evaluators of the test",
      control: "tags",
      editable: editable,
      config: {
        idAttribute: "_id",
        nameAttribute: "name",
        apis: {
          search: {
            url: "/user/autocomplete/{query}?type=instructors",
            formatter: function (args) {
              let formattedData = [];
              if (args) {
                let data = args.data;
                if (data) {
                  data.map((itm, idx) => {
                    let fullName =
                      itm["firstName"] +
                      " " +
                      itm["lastName"] +
                      " - " +
                      itm["email"];
                    if (itm.type == "group") {
                      fullName = itm["firstName"] + " - " + itm["lastName"];
                    }
                    formattedData.push({ _id: itm["_id"], name: fullName });
                  });
                }
              }
              return formattedData;
            },
            parameters: {
              replace: {
                query: "",
              },
            },
          },
        },
      },
      attributes: {
        className: "form-control",
      },
    },
    reminders: {
      alias: "reminders",
      control: "select",
      config: {
        options: [
          {
            label: "5 minutes before",
            value: JSON.stringify({ type: "minutes", value: 5 }),
          },
          {
            label: "10 minutes before",
            value: JSON.stringify({ type: "minutes", value: 10 }),
          },
          {
            label: "30 minutes before",
            value: JSON.stringify({ type: "minutes", value: 30 }),
          },
          {
            label: "1 hour before",
            value: JSON.stringify({ type: "minutes", value: 60 }),
          },
          {
            label: "1 day before",
            value: JSON.stringify({ type: "days", value: 1 }),
          },
        ],
      },
      label: "Reminder",
      editable: editable,
      attributes: {
        className: "form-control",
      },
    },
    participants: {
      alias: "participants",
      control: "textarea",
      placeholder: "Select the participants of the test",
      label: "Choose participants",
      editable: editable,
      attributes: {
        className: "form-control",
      },
    },
  };
}

function UniTrainingTestsFormatter(data) {
  let formattedData = [];

  if (data) {
    data.data.map((itm, idx) => {
      let formattedRecord = {};

      formattedRecord["_id"] = itm._id;
      formattedRecord["select"] = itm.title;
      formattedData.push(formattedRecord);
    });

    return formattedData;
  }
}

function UniTrainingEditTestsFormatter(data) {
  if (data) {
    let formattedData = [];
    data.data.map((itm, idx) => {
      let formattedRecord = {};

      formattedRecord["_id"] = itm._id;
      formattedRecord["title"] = itm.title;
      formattedRecord["exam"] = JSON.stringify({
        id: itm._id,
        title: itm.title,
      });

      formattedRecord["examDuration"] = itm.examDuration;
      formattedRecord["evaluators"] = itm.evaluators;
      const start = moment();
      const halfhour = 30 - (start.minute() % 30);
      const dateFromTime = moment(start)
        .add(halfhour, "minutes")
        .format("HH:mm:00");
      const dateToTime = moment(start)
        .add(halfhour, "minutes")
        .add("3", "hours")
        .format("HH:mm:00");

      let fromTime = dateFromTime;
      if (itm.from) {
        fromTime = moment(itm.from).format("HH:mm:00");
      }
      let toTime = dateToTime;
      if (itm.to) {
        toTime = moment(itm.to).format("HH:mm:00");
      }

      if (!itm.from && !itm.to) {
        if (
          moment(start)
            .add(halfhour, "minutes")
            .isBefore(moment(start).add(halfhour, "minutes").add("3", "hours"))
        ) {
          itm.to = moment(start)
            .add(halfhour, "minutes")
            .add("3", "hours")
            .add("1", "days");
        }
      }

      formattedRecord["reminders"] = itm.reminders
        ? JSON.stringify({
            type: itm.reminders[0].type,
            value: itm.reminders[0].value,
          })
        : {};

      formattedRecord["fromToDateTime"] = {
        fromDateTime: {
          fromDate: itm.from,
          fromTime: fromTime,
        },
        toDateTime: {
          toDate: itm.to,
          toTime: toTime,
        },
      };

      formattedData.push(formattedRecord);
    });

    return formattedData;
  }
}
