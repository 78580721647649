import React, { Component } from "react";
import Control from "./../../control";
import SimpleReactValidator from "simple-react-validator";
import Fun from "../../../../../lib/functions";
import UniFun from "../../../../../modules/uni/functions";
import moment from "moment";
import TopTemplateHeader from "./../../topTemplateHeader";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import Header from "./header";
import Menu from "./menu";
import Footer from "./footer";

class Session extends Component {
  state = {
    data: this.props.data,
    loaded: false,
    colsTemplateCount: 12,
    colsFormCount: 12,
    cardBodyCustomClass: "",
    module: {
      _id: "",
      title: "",
      description: "",
    },
    session: {},
  };

  constructor(props) {
    super(props);
    let builder = this.props.builder.state;
    let templateConfig = builder.module.templateConfig;
    if (templateConfig.events) {
      Object.keys(templateConfig.events).map((itm, idx) => {
        this[itm] = templateConfig.events[itm].bind(this);
      });
    }
    this.sessionsMenu = this.sessionsMenu.bind(this);
    this.sessionsCards = this.sessionsCards.bind(this);
    this.form = React.createRef();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.action) {
      return false;
    }
    return true;
  }

  componentDidMount() {
    let builder = this.props.builder.state;
    let templateConfig = builder.module.templateConfig;
    let useCard =
      typeof templateConfig.useCard !== "undefined"
        ? templateConfig.useCard
        : true;

    let templateTop = templateConfig.templateTop
      ? templateConfig.templateTop
      : () => {};
    this["templateTop"] = templateTop.bind(this);
    let templateBottom = templateConfig.templateBottom
      ? templateConfig.templateBottom
      : () => {};
    this["templateBottom"] = templateBottom.bind(this);
    let templateLeft = templateConfig.templateLeft
      ? templateConfig.templateLeft
      : () => {};
    this["templateLeft"] = templateLeft.bind(this);
    let templateRight = templateConfig.templateRight
      ? templateConfig.templateRight
      : () => {};
    this["templateRight"] = templateRight.bind(this);
    let cardTemplateTop = templateConfig.cardTemplateTop
      ? templateConfig.cardTemplateTop
      : () => {};
    this["cardTemplateTop"] = cardTemplateTop.bind(this);
    let cardTemplateBottom = templateConfig.cardTemplateBottom
      ? templateConfig.cardTemplateBottom
      : () => {};
    this["cardTemplateBottom"] = cardTemplateBottom.bind(this);
    let panelTop = templateConfig.panelTop ? templateConfig.panelTop : () => {};
    this["panelTop"] = panelTop.bind(this);
    let panelBottom = templateConfig.panelBottom
      ? templateConfig.panelBottom
      : () => {};
    this["panelBottom"] = panelBottom.bind(this);
    let panelLeft = templateConfig.panelLeft
      ? templateConfig.panelLeft
      : () => {};
    this["panelLeft"] = panelLeft.bind(this);
    let panelRight = templateConfig.panelRight
      ? templateConfig.panelRight
      : () => {};
    this["panelRight"] = panelRight.bind(this);
    let header = templateConfig.header ? templateConfig.header : () => {};
    this["header"] = header.bind(this);

    let colsTemplateCount = templateConfig.colsTemplateCount
      ? templateConfig.colsTemplateCount
      : this.state.colsTemplateCount;
    let colsFormCount = templateConfig.colsFormCount
      ? templateConfig.colsFormCount
      : this.state.colsFormCount;

    let cardBodyCustomClass = templateConfig.cardBodyCustomClass
      ? templateConfig.cardBodyCustomClass
      : this.state.cardBodyCustomClass;

    this.setState({
      loaded: true,
      rawData: this.props.rawData,
      colsTemplateCount: colsTemplateCount,
      colsFormCount: colsFormCount,
      cardBodyCustomClass: cardBodyCustomClass,
      useCard: useCard,
    });

    this.validator = this.props.builder.validator;
    if (this.componentDidMountEvent) {
      this.componentDidMountEvent();
    }
  }

  sessionsCards() {
    let output = [];

    let data = this.state.rawData.data[0];

    let speakersIds = [];
    let speakers = [];
    let about = "";
    data.days?.forEach((itm, idx) => {
      //if (itm._id == Fun.getURLPathValue(2)) {
      itm.sessions.forEach((sitm, sidx) => {
        if (sitm._id == Fun.getURLPathValue(4)) {
          about = sitm.description;
          sitm.speakersRaw.forEach((spitm, spidx) => {
            if (speakersIds.indexOf(spitm._id) < 0) {
              speakersIds.push(spitm._id);

              let name = (
                <span style={{ display: "inline-block" }}>
                  <span className="pt-2">
                    <strong className="text-primary">
                      {spitm.firstName + " " + spitm.lastName}
                    </strong>
                    <br></br>
                    {spitm.jobTitle}
                  </span>
                </span>
              );
              speakers.push(
                <div
                  className="mt-3 pointer"
                  onClick={(e) => this.speakerInfoModal(spitm)}
                >
                  {UniFun.createProfileImageBubble(
                    [spitm],
                    "avatar-default",
                    false,
                    name,
                    "0px"
                  )}
                </div>
              );
            }
          });
        }
      });
    });

    return (
      <div className="row">
        <div className="col-md-12">
          <div className="card shadow">
            <div className="card-body">
              <div className="row mt-3">
                <div className="col-md-4">
                  <h5>
                    <strong>Speakers</strong>
                  </h5>
                  <div className="pl-3">{speakers}</div>
                </div>
                <div className="col-md-8">
                  <h5>
                    <strong>About</strong>
                  </h5>

                  <div className="pt-2">{Fun.parse(about)}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  sessionsMenu() {
    let output = [];

    let data = this.state.data[0];

    data.days.value?.forEach((itm, idx) => {
      let colorClass = "text-dark";

      itm.sessions.forEach((sitm, sidx) => {
        if (sitm._id == Fun.getURLPathValue(4)) {
          colorClass = "active-day";
        }
      });

      output.push(
        <li className="nav-link pl-0">
          <span
            className={
              "rounded pl-3  pr-3 pt-2 pb-2 conference-day " + colorClass
            }
          >
            <Link to={"/conference/" + data._id.value + "/sessions/" + itm._id}>
              Day {idx + 1}
            </Link>
          </span>
        </li>
      );
    });

    return (
      <div className="container  pb-5 pt-3">
        <ul className="nav">{output}</ul>
      </div>
    );
  }

  render() {
    if (this.state.loaded) {
      let data = this.state.data[0];

      let banner = data.banner.value[0]
        ? window["baseurl"](
            "/agenda-file/data/" +
              data.banner.value[0]._id +
              "?type=preview&jwt=" +
              localStorage.getItem("jwt")
          )
        : "/uni/svg/conferencePlaceholder.jpg";

      let sessionsMenu = this.sessionsMenu();

      let sessionTitle = "";
      let sessionSubtitle = "";

      this.state.rawData.data[0].days?.forEach((itm, idx) => {
        if (itm.sessions) {
          itm.sessions.forEach((sitm, sidx) => {
            if (sitm._id == Fun.getURLPathValue(4)) {
              sessionTitle = sitm.title;
              sessionSubtitle =
                moment(itm.date).format("ddd DD MMM YYYY") +
                " | " +
                moment(sitm.fromRaw).format("HH:mm") +
                " - " +
                moment(sitm.toRaw).format("HH:mm");
            }
          });
        }
      });

      return (
        <React.Fragment>
          <Header
            activeTab="1"
            data={this.state.data}
            interestFormModal={this.interestFormModal}
            rawData={this.state.rawData}
          ></Header>
          <div
            className="pt-5"
            style={{
              backgroundImage: "url(" + banner + ")",
              backgroundSize: "cover",
              boxShadow: "inset 0 0 0 2000px rgba(0, 0, 0, 0.7)",
              minHeight: "100vh",
            }}
          >
            <div style={{ minHeight: "calc(100vh - 150px)" }}>
              <div className="container pt-5">
                <div className={"row pb-4"}>
                  <div className="col-12 ">
                    <h3>
                      <Link
                        to={
                          "/conference/" + Fun.getURLPathValue(2) + "/sessions"
                        }
                      >
                        <img
                          src="/uni/svg/trainings/go_back.svg"
                          className="pr-3"
                          style={{ width: "49px", paddingBottom: "7px" }}
                        ></img>{" "}
                      </Link>
                      <strong className="font-20 text-white">
                        {sessionTitle}
                      </strong>
                    </h3>
                    <p className="pl-56  text-white">{sessionSubtitle}</p>
                  </div>
                </div>

                <div className="container" style={{ minHeight: "600px" }}>
                  {this.sessionsCards()}
                </div>
              </div>
            </div>
            <Footer></Footer>
          </div>

          {/* <div className="container pt-5">
            <React.Fragment>
              <div className={"row pb-4"}>
                <div className="col-12 ">
                  <h3>
                    <Link
                      to={"/conference/" + Fun.getURLPathValue(2) + "/sessions"}
                    >
                      <img
                        src="/uni/svg/trainings/go_back.svg"
                        className="pr-3"
                        style={{ width: "49px", paddingBottom: "7px" }}
                      ></img>{" "}
                    </Link>
                    <strong className="font-20">{sessionTitle}</strong>
                  </h3>
                  <p className="pl-56 ">{sessionSubtitle}</p>
                </div>
              </div>
            </React.Fragment>
          </div>
          <div className="container h-100">{this.sessionsCards()}</div> */}
        </React.Fragment>
      );
    } else {
      return "loading...";
    }
  }
}

export default withRouter(Session);
