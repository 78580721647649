import React, { Component } from "react";
import Fun from "../../../lib/functions";
import OTP from "./login/otp";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      username: "admin",
      password: "admin1234!",
      isLoggedIn: true,
    };
  }

  componentDidMount() {
    Fun.addCSS("/uni/css/loginstyle.css");

    this.setState({ loaded: true, content: this.props.content });
  }

  render() {
    if (this.state.loaded) {
      return (
        <div
          className="container h-100 w-50 w-md-75"
          style={{
            paddingTop: "10%",
          }}
        >
          <div className="row justify-content-center">
            <div className="col-md-8">
              <div className="card-group">
                <div className="card p-4">
                  <div className="card-body">
                    {this.state.content ? this.state.content : ""}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return "";
  }
}

export default Login;
