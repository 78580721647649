import axios from "axios";
import React, { Component, useRef } from "react";
import ProgressBarControl from "../../controls/progressbar/progressbar";
import Builder from "../../lib/builder";
import Fun from "../../lib/functions";
import TopTemplateHeader from "../../themes/default/templates/topTemplateHeader";
import Moment from "moment";
import moment from "moment";
import Pagination from "../../controls/pagination/pagination";
import { Link } from "react-router-dom";
import { Fade, InputGroup } from "react-bootstrap";

const UniFun = {
  isEmail: function validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  },
  isNumber: function isNumber(value) {
    return typeof value === "number" && isFinite(value);
  },
  pagination: function (current, start, offset, total, builder, replace) {
    return (
      <Pagination
        totalRecords={total}
        current={current}
        start={start}
        offset={offset}
        builder={builder}
        replace={replace}
      ></Pagination>
    );
  },
  isLoggedIn() {
    return localStorage.getItem("jwt") && localStorage.getItem("isLoggedIn");
  },
  timeSelect: function (args) {
    let timeArray = [
      "07:15",
      "07:30",
      "07:45",
      "08:00",
      "08:15",
      "08:30",
      "08:45",
      "09:00",
      "09:15",
      "09:30",
      "09:45",
      "10:00",
      "10:15",
      "10:30",
      "10:45",
      "11:00",
      "11:15",
      "11:30",
      "11:45",
      "12:00",
      "12:15",
      "12:30",
      "12:45",
      "13:00",
      "13:15",
      "13:30",
      "13:45",
      "14:00",
      "14:15",
      "14:30",
      "14:45",
      "15:00",
      "15:15",
      "15:30",
      "15:45",
      "16:00",
      "16:15",
      "16:30",
      "16:45",
      "17:00",
      "17:15",
      "17:30",
      "17:45",
      "18:00",
      "18:15",
      "18:30",
      "18:45",
      "19:00",
      "19:15",
      "19:30",
      "19:45",
      "20:00",
      "20:15",
      "20:30",
      "20:45",
      "21:00",
      "21:15",
      "21:30",
      "21:45",
      "22:00",
      "22:15",
      "22:30",
      "22:45",
      "23:00",
      "23:15",
      "23:30",
      "23:45",
      "00:00",
      "00:15",
      "00:30",
      "00:45",
      "01:00",
      "01:15",
      "01:30",
      "01:45",
      "02:00",
      "02:15",
      "02:30",
      "02:45",
      "03:00",
      "03:15",
      "03:30",
      "03:45",
      "04:00",
      "04:15",
      "04:30",
      "04:45",
      "05:00",
      "05:15",
      "05:30",
      "05:45",
      "06:00",
      "06:15",
      "06:30",
      "06:45",
      "07:00",
    ];

    let output = [];
    timeArray.map((itm, idx) => {
      output.push({ label: itm, value: itm + ":00" });
    });
    return output;
  },
  createEventModal(
    parentBuilder,
    id,
    title,
    module,
    view,
    modal,
    size,
    submitCaption,
    submitClass,
    parameters
  ) {
    let builder = parentBuilder;

    let params = {
      replace: {
        _id: id,
        id: id,
      },
    };

    if (parameters) {
      params = parameters;
    }

    window[modal].setState({
      title: <h3>{title}</h3>,
      size: size,
      targetComponent: this,
      footer: UniFun.modalCommonFooter(modal, submitCaption, submitClass),
      callback: function () {
        window[modal].setState({ show: false, overflowY: "auto" });
        builder.rerunComponent();
      },
      show: true,
      body: (
        <Builder
          module={module}
          view={view}
          parameters={params}
          ref={(refComponent) => {
            window[modal + "body"] = refComponent;
          }}
        ></Builder>
      ),
    });
  },
  createURL(url) {
    return window.location.protocol + "//" + window.location.hostname + url;
  },
  copyToClipboard(text) {
    var dummy = document.createElement("textarea");
    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);

    Fun.createNotification({
      type: "success",
      message: "Link copied!",
    });
  },
  createICS(event) {
    let ics = require("ics");

    let start = moment(event.from)
      .format("YYYY-M-D-H-m")
      .split("-")
      .map(function (x) {
        return parseInt(x, 10);
      });

    let end = moment(event.to)
      .format("YYYY-M-D-H-m")
      .split("-")
      .map(function (x) {
        return parseInt(x, 10);
      });

    let domain = window.location.hostname;
    let scheme = window.location.protocol;
    let link = scheme + "//" + domain + event.moreLink;

    let description =
      "You are invited to join " +
      event.eventType +
      "\n\n Date:" +
      moment(event.from).format("ddd DD MMM YYYY") +
      "\n Time:" +
      moment(event.from).format("hh:mm A") +
      "\n\n To access your " +
      event.eventType +
      " you need first to login (" +
      scheme +
      "//" +
      domain +
      ") \n Join " +
      event.eventType +
      " \n\n" +
      link;

    const eventD = {
      start: start,
      end: end,
      title: event.title,
      location: event.eventType,
      url: link,
      description: description,
    };

    ics.createEvent(eventD, (error, value) => {
      if (error) {
        console.log(error);
        return;
      }
      window.open("data:text/calendar;charset=utf8," + value);
    });
  },
  downloadContactsTemplate(withPassword) {
    Fun.fetch({
      url: {
        url: "/auth/downloadUserTemplate/{withPassword}",
      },
      responseType: "arraybuffer",
      parameters: {
        replace: {
          withPassword: function () {
            if (withPassword) {
              return "true";
            }

            return false;
          },
        },
        headers: {},
      },
      callback: function (response) {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "contactsTemplate.xls");
        link.click();
      },
    });
  },
  downloadContacts() {
    Fun.fetch({
      url: {
        url: "/users/download/excel",
        replace: {},
        headers: {},
      },
      responseType: "arraybuffer",
      parameters: {
        replace: {},
        headers: {},
      },
      callback: function (response) {
        // const data = Uint8Array.from(response);
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "contacts.xls");
        link.click();
      },
    });
  },
  allowDownloadPdfForTraining() {
    switch (window.location.hostname.toLocaleLowerCase()) {
      case "efpalux.hapeiron.com":
        return true;
        break;

      case "staging-v2.interfima.org":
        return true;
        break;
    }
    return false;
  },
  fromToValidation(from, to) {
    let fromDate = moment(from);
    let toDate = moment(to);

    if (toDate.isAfter(fromDate)) {
      return true;
    }

    Fun.createNotification({
      type: "danger",
      message: "The end date time cannot precede the start date time",
    });

    return false;
  },
  userIsEligible(args) {
    switch (args.forWhat) {
      case "users.accounts.change":
        break;

      case "my-account.change":
        break;

      case "files":
        if (localStorage.getItem("userid") == args.ownerId) {
          return true;
        }
        return false;
        break;

      case "files.library":
        if (localStorage.getItem("role")) {
          if (localStorage.getItem("role").includes("admin")) {
            return true;
          }
        }
        return false;
        break;

      case "schedule.test":
        if (localStorage.getItem("role")) {
          if (localStorage.getItem("role").includes("admin")) {
            return true;
          }
        }
        return false;
        break;

      case "schedule.meeting":
        if (localStorage.getItem("role")) {
          if (localStorage.getItem("role").includes("admin")) {
            return true;
          }
        }
        return false;
        break;

      case "schedule.training":
        if (localStorage.getItem("role")) {
          if (localStorage.getItem("role").includes("admin")) {
            return true;
          }
        }
        return false;
        break;

      case "meeting.uploadfiles":
        if (localStorage.getItem("userid") == args.organizerId) {
          return true;
        }

        let canUpload = false;

        if (args.participants) {
          args.participants.map((itm, idx) => {
            if (localStorage.getItem("userid") == itm._id) {
              canUpload = true;
            }
          });
        }

        if (args.organizers) {
          args.organizers.map((itm, idx) => {
            if (localStorage.getItem("userid") == itm._id) {
              canUpload = true;
            }
          });
        }
        return canUpload;
        break;

      case "meeting.files":
        let canUploadMeetingFiles = false;
        if (localStorage.getItem("userid") == args.organizerId) {
          return true;
        } else {
          if (localStorage.getItem("userid") == args.createdBy) {
            return true;
          }

          if (args.organizers) {
            args.organizers.map((itm, idx) => {
              if (localStorage.getItem("userid") == itm._id) {
                canUploadMeetingFiles = true;
              }
            });
          }
        }
        return canUploadMeetingFiles;
        break;

      case "schedule.stream":
        return localStorage.getItem("host") === "true" ? true : false;
        // if (localStorage.getItem("role")) {
        //   if (localStorage.getItem("role").includes("admin")) {
        //     return true;
        //   }
        // }
        // return false;
        break;

      case "meeting":
        if (localStorage.getItem("userid") == args.organizerId) {
          return true;
        }

        let canUploadMeeting = false;
        if (args.organizers) {
          args.organizers.map((itm, idx) => {
            if (localStorage.getItem("userid") == itm._id) {
              canUploadMeeting = true;
            }
          });
        }
        return canUploadMeeting;

        break;

      case "meetings":
        if (localStorage.getItem("userid") == args.organizerId) {
          return true;
        }
        let canUploadMeetings = false;
        if (args.organizers) {
          args.organizers.map((itm, idx) => {
            if (localStorage.getItem("userid") == itm._id) {
              canUploadMeetings = true;
            }
          });
        }
        return canUploadMeetings;
        break;

      case "module":
        if (localStorage.getItem("role")) {
          if (localStorage.getItem("role").includes("admin")) {
            return true;
          }
        }
        return false;
        break;

      case "isInstructor":
        return localStorage.getItem("instructor") === "true" ? true : false;
        break;

      case "isHostOrAdmin":
        if (localStorage.getItem("role")) {
          if (localStorage.getItem("role").includes("admin")) {
            return true;
          }
        }

        return localStorage.getItem("host") === "true" ? true : false;
        break;

      case "isHost":
        return localStorage.getItem("host") === "true" ? true : false;
        break;

      case "edit-account":
        if (localStorage.getItem("role")) {
          if (localStorage.getItem("role").includes("admin")) {
            return true;
          } else {
            if (localStorage.getItem("userid") == args.userId) {
              return true;
            }
          }
        }
        return false;

      case "member":
        if (localStorage.getItem("role")) {
          if (!localStorage.getItem("role").includes("admin")) {
            return true;
          }
        }
        return false;
        break;

      case "viewer":
        if (localStorage.getItem("manager") === "true") {
          return true;
        }
        return false;
        break;

      default:
        if (localStorage.getItem("role")) {
          if (localStorage.getItem("role").includes("admin")) {
            return true;
          }
        }
        return false;
        break;
    }

    return;
  },
  cardRows: function (args) {
    return (
      <div className="row">
        <div className="col-10">{args.main}</div>
        <div className="col-2">{args.right}</div>
      </div>
    );
  },
  testIsAvailable: function (from, to) {
    return UniFun.isBetweenDates(from, to);
  },
  meetingIsAvailable: function (from, to) {
    let start = moment(from).subtract(10, "minutes").toDate();
    let end = moment(to).add(30, "minutes").toDate();

    return UniFun.isBetweenDates(start, end);
  },
  testAvailability: function (from, to) {
    let fromValue = moment(from).format("ddd DD MMM YY, HH:mm");
    let toValue = moment(to).format("ddd DD MMM YY, HH:mm");

    let fromDate = moment(from);
    let toDate = moment(to);
    let isSame = fromDate.isSame(toDate, "date");

    if (isSame) {
      return (
        moment(from).format("ddd DD MMM YY") +
        ", " +
        moment(from).format("HH:mm") +
        " - " +
        moment(to).format("HH:mm")
      );
    }

    return fromValue + " to " + toValue;
  },
  fromToDateString: function (from, to) {
    let fromValue = moment(from).format("ddd DD MMM YY, HH:mm");
    let toValue = moment(to).format("ddd DD MMM YY, HH:mm");

    let fromDate = moment(from);
    let toDate = moment(to);
    let isSame = fromDate.isSame(toDate, "date");

    if (isSame) {
      return (
        moment(from).format("ddd DD MMM YY") +
        ", " +
        moment(from).format("HH:mm") +
        " - " +
        moment(to).format("HH:mm")
      );
    }

    return fromValue + " to " + toValue;
  },
  fromToOnlyDateString: function (from, to) {
    let fromValue = moment(from).format("ddd DD MMM YY");
    let toValue = moment(to).format("ddd DD MMM YY");

    let fromDate = moment(from);
    let toDate = moment(to);
    let isSame = fromDate.isSame(toDate, "date");

    if (isSame) {
      return moment(from).format("ddd DD MMM YY");
    }

    return fromValue + " to " + toValue;
  },
  fromToOnlyMonthString: function (from, to) {
    let fromValue = moment(from).format("ddd DD MMM YY");
    let toValue = moment(to).format("ddd DD MMM YY");

    let fromDate = moment(from);
    let toDate = moment(to);
    let isSameDay = fromDate.isSame(toDate, "date");

    if (isSameDay) {
      return moment(from).format("ddd DD MMM YY");
    }

    let isSameMonth = fromDate.isSame(toDate, "month");

    if (isSameMonth) {
      return (
        moment(from).format("MMMM") +
        " " +
        moment(from).format("DD") +
        " - " +
        moment(to).format("DD") +
        ", " +
        moment(from).format("YYYY")
      );
    }

    return (
      moment(from).format("MMMM") +
      " " +
      moment(from).format("DD") +
      " - " +
      moment(to).format("MMMM") +
      " " +
      moment(to).format("DD") +
      ", " +
      moment(from).format("YYYY")
    );
  },
  mimeTypeIcon: function (mimetype) {
    let icon = "";
    switch (mimetype?.toLowerCase()) {
      case "folder":
        icon = "/uni/svg/folder.svg";

        break;

      case "recording":
        icon = "/uni/svg/files/rec.svg";

        break;

      case "audiorecording":
        icon = "/uni/svg/files/audio.svg";

        break;
      case "parentfolder":
        icon = "/uni/svg/backarrow.svg";

        break;
      case "test":
        icon = "/uni/svg/test.svg";

        break;
      case "testfill":
        icon = "/uni/svg/test_fill.svg";

        break;
      case "application/pdf":
        icon = "/uni/svg/files/pdf.svg";

        break;

      case "application/epub+zip":
        icon = "/uni/svg/files/EPUB.svg";

        break;

      case "video/mp4":
        icon = "/uni/svg/files/video.svg";
        break;

      case "videoconf":
        icon = "/uni/svg/video_conf.svg";
        break;

      case "application/xlsx":
        icon = "/uni/svg/files/excel.svg";

        break;
      case "application/vnd.ms-excel":
        icon = "/uni/svg/files/excel.svg";

        break;
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        icon = "/uni/svg/files/excel.svg";

        break;
      case "application/vnd.ms-powerpoint":
        icon = "/uni/svg/files/powerpoint.svg";

        break;
      case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
        icon = "/uni/svg/files/powerpoint.svg";

        break;
      case "application/doc":
        icon = "/uni/svg/files/word.svg";

        break;
      case "application/docx":
        icon = "/uni/svg/files/word.svg";

        break;
      case "application/msword":
        icon = "/uni/svg/files/word.svg";

        break;
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        icon = "/uni/svg/files/word.svg";

        break;

      case "text/plain":
        icon = "/uni/svg/files/textfile.svg";

        break;

      case "image/jpeg":
        icon = "/uni/svg/files/image.svg";

        break;
      case "image/jpg":
        icon = "/uni/svg/files/image.svg";

        break;
      case "image/png":
        icon = "/uni/svg/files/image.svg";
        break;
      default:
        icon = "/uni/svg/files/unknownfile.svg";
        break;
    }
    return icon;
  },
  filesFileDetail: function (fileid, parentBuilder) {
    let parameters = {
      replace: {
        _id: fileid,
      },
    };

    let builder = parentBuilder;

    window["modal2"].setState({
      title: <h3>File details</h3>,
      size: "lg",
      //targetComponent: this,
      show: true,
      callback: function () {
        window["modal2"].setState({ show: false, overflowY: "auto" });
        builder.rerunComponent();
      },
      footer: [
        <button
          type="button"
          className="btn btn-link border"
          onClick={() => {
            window["modal2"].setState({ show: false, overflowY: "auto" });
          }}
        >
          Cancel
        </button>,
        <button
          type="button"
          className="btn btn-primary border d-none filedetails-save"
          onClick={() => {
            //builder.rerunComponent();
            Fun.submitFromModal("modal2body");
          }}
        >
          Save
        </button>,
      ],
      body: (
        <Builder
          module="unifiles"
          view="filedetails"
          moduleView={builder.state.view}
          key={Fun.uuid()}
          parameters={parameters}
          ref={(refComponent) => {
            window["modal2body"] = refComponent;
          }}
        ></Builder>
      ),
    });
  },
  readingsPreviewFile: function (args) {
    let mime = args.mimetype;
    if (!mime) {
      mime = "application/pdf";
    }

    let download = args.download;

    let mimeImage = UniFun.mimeTypeIcon(mime);

    if (args.isVideoRecording) {
      mimeImage = UniFun.mimeTypeIcon("recording");
    }

    if (args.isAudioRecording) {
      mimeImage = UniFun.mimeTypeIcon("audiorecording");
    }

    let modalTitle = (
      <div className="mr-4">
        <div className="row" style={{ fontSize: "initial" }}>
          <div className="col-8">
            <img
              src={mimeImage}
              style={{ height: "28px", display: "inline-block", float: "left" }}
              className="mr-2"
            ></img>
            <div
              style={{
                display: "inline-block",
                float: "left",
                marginTop: "4px",
              }}
              title={args.title}
            >
              <strong>{UniFun.substring(args.title, 0, 30)}</strong>
            </div>
          </div>
          <div className="col-4 text-right">
            {download !== false ? (
              <React.Fragment>
                <a
                  href={args.filedownloadurl}
                  target="new"
                  className="btn btn-link"
                >
                  <img
                    src="/uni/svg/download.svg"
                    style={{ height: "14px" }}
                  ></img>
                </a>
                <span className="dropdown">
                  <button
                    className="btn btn-link"
                    type="button"
                    id="dropdownMenu2"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <img
                      src="/uni/svg/more-options.svg"
                      style={{ height: "14px" }}
                    ></img>
                  </button>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenu2"
                  >
                    <a
                      href={args.filedownloadurl}
                      target="new"
                      className="dropdown-item"
                    >
                      Download
                    </a>
                  </div>
                </span>
              </React.Fragment>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    );

    let body = "";

    body = (
      <div className="embed-responsive embed-responsive-16by9">
        <iframe
          type="text"
          src={args.fileurl}
          name="fileurl"
          className="form-control vh-100"
        ></iframe>
      </div>
    );

    if (mime) {
      if (mime.startsWith("image")) {
        body = (
          <div className="p-5 bg-white text-center">
            <img src={args.fileurl} className="img-fluid" />
          </div>
        );
      } else if (mime.startsWith("video")) {
        body = (
          <div className="p-5 bg-white text-center">
            <video controls className="w-100">
              <source src={args.fileurl} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        );
      }
    }

    window["modal"].setState({
      title: modalTitle,
      size: "fullscreen modal-bg-light ",
      show: true,
      footer: [],
      body: (
        <div className="form-group " style={{ marginBottom: "30px" }}>
          <div>{body}</div>
        </div>
      ),
    });
  },
  filesPreviewFile: function (args, parentBuilder) {
    /*{
      id: rec._id.value,
      icon: icon,
      title: rec.title.value,
      path: rec.path.value,
      owner:owner
    }*/
    let builder = parentBuilder;
    let parameters = {
      replace: {
        _id: args.id,
      },
    };

    let modalTitle = (
      <div className="mr-4">
        <div className="row" style={{ fontSize: "initial" }}>
          <div className="col-8">
            <img
              src={args.icon}
              style={{ height: "40px", display: "inline-block", float: "left" }}
              className="mr-2"
            ></img>
            <div style={{ display: "inline-block", float: "left" }}>
              <strong>{UniFun.substring(args.title, 0, 25)}</strong>
              <br></br>Owner: {args.owner}
            </div>
          </div>
          {/* <div className="col-7 pl-5 pt-2">
             <span className="text-dark">
              <strong>File location: {args.path}</strong>
            </span> 
          </div> */}
          <div className="col-4 text-right">
            <a
              href={args.filedownloadurl}
              target="new"
              className="btn btn-link"
            >
              <img src="/uni/svg/download.svg" style={{ height: "14px" }}></img>
            </a>
            <span className="dropdown">
              <button
                className="btn btn-link"
                type="button"
                id="dropdownMenu2"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <img
                  src="/uni/svg/more-options.svg"
                  style={{ height: "14px" }}
                ></img>
              </button>
              <div className="dropdown-menu" aria-labelledby="dropdownMenu2">
                <a
                  href={args.filedownloadurl}
                  target="new"
                  className="dropdown-item"
                  type="button"
                >
                  Download
                </a>
                <button
                  className="dropdown-item"
                  type="button"
                  onClick={(e) => UniFun.filesFileDetail(args.id, builder)}
                >
                  View files details
                </button>
                {/* <button class="dropdown-item" type="button">
                  Copy link
                </button> */}
              </div>
            </span>
          </div>
        </div>
      </div>
    );

    let body = (
      <div>
        <div className="container" style={{ height: "400px" }}>
          <div
            className="row border rounded p-2 pb-3"
            style={{
              marginTop: "20%",
              width: "40%",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <div className="col-md-8 pt-2 ">
              <img
                src="/uni/svg/previewnotavailable.svg"
                style={{ height: "19px", marginBottom: "5px" }}
              ></img>{" "}
              <span className="ml-2">
                <strong>Preview is not available</strong>
              </span>
            </div>
            <div className="col-md-4 text-center pt-2">
              <a
                href={args.filedownloadurl}
                target="new"
                className=" text-primary"
              >
                Download
              </a>
            </div>
          </div>
        </div>
      </div>
    );
    if (args.rec.mime.value) {
      if (
        args.rec.mime.value.startsWith("image") ||
        args.rec.mime.value.startsWith("video") ||
        args.rec.mime.value.startsWith("application/pdf")
      ) {
        body = (
          <Builder
            module="unifiles"
            view="previewfile"
            parameters={parameters}
            key={Fun.uuid()}
            ref={(refComponent) => {
              window["modalbody"] = refComponent;
            }}
          ></Builder>
        );
      }
    }

    window["modal"].setState({
      title: modalTitle,
      size: "fullscreen  ",
      targetComponent: this,
      show: true,
      footer: [],
      body: body,
    });
  },
  trainingNodes: function (args) {
    let module = args.module;

    let view = args.view;
    let control = args.control;
    let modalTitle = args.modalTitle;
    let inputData = args.inputData;
    let parameters = args.parameters;
    if (typeof module === "function") {
      module = module(inputData);
    }
    if (typeof view === "function") {
      view = view(inputData);
    }

    function callback(data) {
      let currentData = window["modal2"].state.targetComponent.state.data;

      let fileId = data.file["_id"];
      let mimetype = data.file["mimetype"];
      let duration = data["duration"];
      let recording = data["recording"];
      let isAudio = data["isAudio"];

      let fileObject = {
        _id: fileId,
        title: data.title,
        mimetype: mimetype,
        recording: recording ? true : false,
        isAudio: isAudio ? true : false,
      };
      if (duration) {
        fileObject["duration"] = Array.isArray(duration)
          ? duration[0]
          : duration;
      }
      currentData.push(fileObject);
      window["modal2"].state.targetComponent.setState({
        data: currentData,
      });
    }

    window["modal2"].setState({
      title: <h3>{modalTitle}</h3>,
      size: "xl",
      targetComponent: control,
      footer: UniFun.modalCommonFooter("modal2", "Save"),
      callback: args.callback ? args.callback : callback,
      show: true,
      body: (
        <Builder
          module={module}
          view={view}
          key={Fun.uuid()}
          inputData={inputData}
          parameters={parameters}
          ref={(refComponent) => {
            window["modal2body"] = refComponent;
          }}
        ></Builder>
      ),
    });
  },
  finalResults(questions) {
    let correctAnswers = 0;

    questions?.map((itm, idx) => {
      let correctAnswer = true;

      itm.answers?.map((aitm, aidx) => {
        let isCorrect = aitm.isCorrect.toString();
        let userAnswer = aitm.userAnswer.toString();
        if (correctAnswer) {
          if (isCorrect != userAnswer) {
            correctAnswer = false;
          }
        }
      });
      if (correctAnswer) {
        correctAnswers++;
      }
    });

    let percentage = (100 * correctAnswers) / questions.length;

    return {
      correctAnswers: correctAnswers,
      totalQuestions: questions.length,
      percentage: Math.round(percentage),
      label:
        "You have answered " +
        Math.round(percentage) +
        "% (" +
        correctAnswers +
        "/" +
        questions.length +
        ") of the questions correctly",
    };
  },
  examStatus(event) {
    if (event.userExamInfo) {
      if (event.userExamInfo.value) {
        if (event.userExamInfo.value.status) {
          let status = "";

          switch (event.userExamInfo.value.status.toLowerCase()) {
            case "fail":
              return <span className="text-danger">Fail: </span>;
              break;

            case "pass":
              return <span className="text-success">Pass: </span>;
              break;
            case "pending":
              return "You haven't submitted the test yet";
              break;
          }
        }
      }
    } else {
      if (UniFun.isBetweenDates(event.from.value, event.to.value)) {
        return "You haven't submitted the test yet";
      }
    }
    return "Test has not started yet";
  },
  createStartExamBtn(event) {
    if (UniFun.userIsEligible({ forWhat: "default" })) {
      return "";
    }

    if (event.userExamInfo) {
      if (event.userExamInfo.status) {
        if (event.userExamInfo.status.toLowerCase() == "pending") {
          return (
            <a
              href={"/test/" + event._id}
              target="new"
              className="btn btn-primary"
            >
              Continue exam
            </a>
          );
        } else {
          return "";
        }
      }
    } else if (UniFun.testIsAvailable(event.from, event.to)) {
      return (
        <button
          type="button"
          className="btn btn-primary"
          onClick={(e) => UniFun.startExam(event)}
        >
          Start exam
        </button>
      );
    } else {
      return (
        <button
          type="button"
          className="btn btn-primary disabled action-btn"
          data-event-dif="0"
          disabled
          data-event-start={moment(event.from).valueOf()}
          onClick={(e) => UniFun.startExam(event)}
        >
          Start exam
        </button>
      );
    }
    return "";
  },
  startExam(event) {
    window["modal3"].setState({
      title: <h3>Start test?</h3>,
      size: "lg",
      targetComponent: this,
      callback: function () {
        let jsonData = {
          eventId: event._id,
        };

        Fun.fetch({
          url: {
            url: "/exam/user-exam",
          },
          method: "POST",
          callback: function (args) {
            window["modal3"].setState({ show: false, overflowY: "auto" });
            window["modal2"].setState({ show: false, overflowY: "auto" });
            window["modal"].setState({ show: false, overflowY: "auto" });
            let testEventId = event._id;
            setTimeout(function () {
              window.location = "/test/" + testEventId;
            }, 0);
          },
          parameters: {
            headers: {
              "content-type": "application/json",
            },
          },
          submittedData: JSON.stringify(jsonData),
        });
      },
      footer: [
        <button
          type="button"
          className="btn btn-link border"
          onClick={() => {
            window["modal3"].setState({ show: false, overflowY: "auto" });
          }}
        >
          Cancel
        </button>,
        <button
          type="button"
          className={"btn  btn-primary"}
          onClick={() => {
            if (window["modal3"].state.callback) {
              window["modal3"].state.callback();
            }
          }}
        >
          Start
        </button>,
      ],
      show: true,
      body: (
        <div className="p-5">
          <p>
            Once you enter the test page the duration of the test will start a
            countdown.<br></br>
            <br></br>
            Notice that if you navigate away from the test page:<br></br>
            <br></br>- Your progress won't be lost (all answers will be saved),
            so you can resume the test at the same point<br></br>- Time will
            continue counting when you are away<br></br>- Your progress will be
            automatically submitted when the test duration ends<br></br>
          </p>
        </div>
      ),
    });
  },
  isBetweenDates(from, to) {
    return moment().isBetween(from, to);
  },
  gradeExam: function (grade) {
    return grade > 0
      ? "Grade " + grade + "% or higher to pass"
      : "Practice test";
  },
  agendaFilesJson: function (files) {
    let output = [];

    Object.keys(files).map((itm, idx) => {
      if (files[itm]) {
        if (files[itm]._id) {
          output.push(files[itm]._id);
        }
      }
    });

    return output;
  },
  examDuration: function (examDuration) {
    let examDurationOut = "";
    if (examDuration) {
      if (examDuration.hours > 0) {
        examDurationOut = examDuration.hours + " hours";
        if (examDuration.minutes > 0) {
          examDurationOut =
            examDurationOut + " and " + examDuration.minutes + " minutes";
        }
        examDurationOut = examDurationOut + " duration";
      } else {
        if (examDuration.minutes > 0) {
          examDurationOut = examDuration.minutes + " minutes duration";
        }
      }
    }
    return examDurationOut;
  },
  trainingJSONData: function (jsondata, duplicate) {
    let out = jsondata;

    if (duplicate) {
      delete out["_id"];
    }

    if (out) {
      out.modules.map((itm, idx) => {
        if (itm["_id"] == "" || duplicate) {
          delete itm["_id"];
        }
        itm.sessions.map((sitm, sidx) => {
          if (sitm["_id"] == "" || duplicate) {
            delete sitm["_id"];
          }
          sitm["videoConference"] = [];
          if (sitm["videoPresentation"]) {
            sitm["videoPresentation"].map((vitm, vidx) => {
              sitm["videoPresentation"][vidx] = JSON.parse(vitm);
            });
          } else {
            sitm["videoPresentation"] = [];
          }
          if (sitm["reading"]) {
            sitm["reading"].map((vitm, vidx) => {
              sitm["reading"][vidx] = JSON.parse(vitm);
            });
          } else {
            sitm["reading"] = [];
          }
          if (sitm["exam"]) {
            sitm["exam"].map((vitm, vidx) => {
              sitm["exam"][vidx] = JSON.parse(vitm)["_id"];
            });
          } else {
            sitm["exam"] = [];
          }
        });
      });
    }

    return out;
  },
  logout: function (url) {
    localStorage.clear();

    if (url) {
      if (typeof url === "string") {
        //  if (url.indexOf("/conference") < 0) {
        window.location.replace("/login?redirect=" + url);
        // }
      } else {
        window.location.replace("/login");
      }
    } else {
      window.location.replace("/login");
    }
  },
  showProfileBio: function (id) {
    let parameters = {
      replace: {
        id: id,
      },
    };

    let footerBtns = [
      <button
        type="button"
        className="btn btn-link border"
        onClick={() => {
          window["modal2"].setState({ show: false, overflowY: "auto" });
        }}
      >
        Cancel
      </button>,
    ];

    if (
      UniFun.userIsEligible({ forWhat: "default" }) ||
      localStorage.getItem("userid") == id
    ) {
      footerBtns.push(
        <button
          type="button"
          className="btn btn-primary border"
          onClick={() => {
            UniFun.showProfile(id);
          }}
        >
          Edit
        </button>
      );
    }

    window["modal2"].setState({
      title: "",
      size: "xl",
      targetComponent: this,

      footer: footerBtns,
      show: true,
      body: (
        <Builder
          module="uniusers"
          view="userBio"
          parameters={parameters}
          key={Fun.uuid()}
          ref={(refComponent) => {
            window["modal2body"] = refComponent;
          }}
        ></Builder>
      ),
    });
  },
  showProfile: function (id) {
    let parameters = {
      replace: {
        id: id,
      },
    };

    window["modal"].setState({
      title: (
        <React.Fragment>
          <h3 className="pl-3">User profile</h3>
        </React.Fragment>
      ),
      size: "lg",
      targetComponent: this,
      callback: function () {
        window["modal"].setState({ show: false, overflowY: "auto" });
        Fun.createNotification({
          type: "success",
          message: "Update update was successful!",
        });
      },
      footer: UniFun.modalCommonFooter(
        "modal",
        "Save",
        "",
        UniFun.userIsEligible({ forWhat: "edit-account", userId: id })
      ),
      show: true,
      body: (
        <Builder
          module="uniusers"
          view="detail"
          parameters={parameters}
          key={Fun.uuid()}
          ref={(refComponent) => {
            window["modalbody"] = refComponent;
          }}
        ></Builder>
      ),
    });
  },
  myProfileDetails: function () {
    let parameters = {
      replace: {
        id: localStorage.getItem("userid"),
      },
    };

    window["modal"].setState({
      title: (
        <React.Fragment>
          <h3 className="pl-3">My Profile</h3>
        </React.Fragment>
      ),
      size: "lg",
      targetComponent: this,
      footer: UniFun.modalCommonFooter("modal", "Update", ""),
      callback: function (args) {
        let data = args[0];

        localStorage.setItem("profileImage", data.profileImage);
        localStorage.setItem("firstName", data.firstName);
        localStorage.setItem("lastName", data.lastName);

        window["modal"].setState({ show: false, overflowY: "auto" });
        Fun.createNotification({
          type: "success",
          message: "Update was successful!",
        });
        window.location.reload();
      },
      show: true,
      body: (
        <Builder
          module="uniusers"
          view="detail"
          parameters={parameters}
          key={Fun.uuid()}
          ref={(refComponent) => {
            window["modalbody"] = refComponent;
          }}
        ></Builder>
      ),
    });
  },
  myProfile: function () {
    let profile = {
      firstName: localStorage.getItem("firstName"),
      lastName: localStorage.getItem("lastName"),
    };

    let avatarClass = "avatar-profile pointer";
    if (
      localStorage.getItem("profileImage") &&
      localStorage.getItem("profileImage") !== "undefined" &&
      localStorage.getItem("profileImage") !== "null"
    ) {
      profile["profileImage"] = localStorage.getItem("profileImage");
      avatarClass = "avatar-default pointer";
    }

    let output = UniFun.createProfileImageBubble([profile], avatarClass, false);

    return output;
  },
  createProfileImageBubble: function (
    persons,
    avatarClass = "avatar-default",
    showFullName = true,
    customContent,
    top = "5px"
  ) {
    let output = [];

    let uniquePersons = [];
    let uniquePersonsIds = [];
    persons.map((itm, idx) => {
      if (uniquePersonsIds.indexOf(itm._id) < 0) {
        uniquePersons.push(itm);
        uniquePersonsIds.push(itm._id);
      }
    });

    uniquePersons.map((itm, idx) => {
      let content = "";

      if (itm) {
        if (itm.profileImg) {
          itm["profileImage"] = itm.profileImg;
        }
        if (
          itm.profileImage &&
          itm.profileImage !== "null" &&
          itm.profileImage !== "undefined"
        ) {
          let profileImage =
            window["baseurl"]("/agenda-file/data/" + itm.profileImage) +
            "?type=preview&jwt=" +
            localStorage.getItem("jwt");

          content = <img className=" rounded-circle" src={profileImage} />;
        } else {
          if (itm.firstName && itm.lastName) {
            let initialsName =
              itm.firstName.charAt(0).toUpperCase() +
              itm.lastName.charAt(0).toUpperCase();

            content = (
              <div className=" h-100 text-center" key={Fun.uuid()}>
                <div style={{ position: "relative", top: "15%" }}>
                  {initialsName}
                </div>
              </div>
            );
          }
        }

        if (showFullName) {
          if (itm.participants == "guest") {
            output.push(
              <div style={{ display: "contents" }} key={Fun.uuid()}>
                <span className={"avatar-circle " + avatarClass}>
                  <div className=" h-100 text-center" key={Fun.uuid()}>
                    <div style={{ position: "relative", top: "15%" }}>GU</div>
                  </div>
                </span>
                <span
                  className="ml-2 pr-4"
                  style={{ position: "relative", top: "5px" }}
                >
                  Guest
                </span>
              </div>
            );
          } else {
            output.push(
              <div style={{ display: "contents" }} key={Fun.uuid()}>
                <span className={"avatar-circle " + avatarClass}>
                  {content}
                </span>
                <span
                  className="ml-2 pr-4"
                  style={{ position: "relative", top: "5px" }}
                >
                  {itm.firstName + " " + itm.lastName}
                </span>
              </div>
            );
          }
        } else {
          output.push(
            <div key={Fun.uuid()}>
              <table>
                <tr>
                  <td>
                    <span className={"avatar-circle " + avatarClass}>
                      {content}
                    </span>
                  </td>
                  <td className="pl-2"> {customContent}</td>
                </tr>
              </table>
              {/* <div className="row">
                <div className="" style={{ float: "right" }}>
                  <span className={"avatar-circle " + avatarClass}>
                    {content}
                  </span>
                </div>
                <div className="" style={{ float: "right", maxWidth: "200px" }}>
                  <span
                    className="ml-2 pr-4"
                    style={{ position: "relative", top: top }}
                  >
                    {customContent}
                  </span>
                </div>
              </div> */}
            </div>
          );
          // output.push(
          //   <div style={{ display: "contents" }} key={Fun.uuid()}>
          //     <div className="row">
          //       <div className="" style={{ float: "right" }}>
          //         <span className={"avatar-circle " + avatarClass}>
          //           {content}
          //         </span>
          //       </div>
          //       <div className="" style={{ float: "right", maxWidth: "200px" }}>
          //         <span
          //           className="ml-2 pr-4"
          //           style={{ position: "relative", top: top }}
          //         >
          //           {customContent}
          //         </span>
          //       </div>
          //     </div>
          //   </div>
          // );
        }
      }
    });

    return output;
  },
  trainingEventJSONData: function (jsondata, deleteId = true) {
    let out = jsondata;
    let deleted = deleteId;
    // try {
    if (out) {
      if (deleted != false) {
        delete out["_id"];
      }
      if (jsondata["participants"]) {
        out["participants"] = UniFun.participantsJSONData(
          JSON.parse(jsondata["participants"])
        );
      }
      out.modules.map((itm, idx) => {
        if (deleted != false || !itm["_id"]) {
          delete itm["_id"];
        }
        itm.sessions.map((sitm, sidx) => {
          if (deleted != false || !sitm["_id"]) {
            delete sitm["_id"];
          }

          let instructors = [];
          if (sitm["instructors"]) {
            JSON.parse(sitm["instructors"]).map((vitm, vidx) => {
              let instructorsObj = vitm;
              instructors.push(instructorsObj._id);
            });
          }
          sitm["instructors"] = instructors;

          let videoConference = [];
          if (sitm["videoConference"]) {
            sitm["videoConference"].map((vitm, vidx) => {
              let videoConferenceObj = JSON.parse(vitm);

              let outObj = {
                title: videoConferenceObj.title,
                from: videoConferenceObj.from,
                to: videoConferenceObj.to,
                reminders: videoConferenceObj.reminders,
              };

              if (deleted === false) {
                if (videoConferenceObj._id) {
                  outObj["_id"] = videoConferenceObj._id;
                }
              }

              videoConference.push(outObj);
            });
          }
          sitm["videoConference"] = videoConference;

          let videoPresentation = [];
          if (sitm["videoPresentation"]) {
            sitm["videoPresentation"].map((vitm, vidx) => {
              let videoPresentationObj = JSON.parse(vitm);

              let outObj = {
                title: videoPresentationObj.title,
                mimetype: videoPresentationObj.mimetype,
                recording: videoPresentationObj.recording ? true : false,
                isAudio: videoPresentationObj.isAudio ? true : false,
              };

              if (deleted === false) {
                if (videoPresentationObj._id) {
                  outObj["_id"] = videoPresentationObj._id;
                }
              }

              videoPresentation.push(outObj);
            });
          }
          sitm["videoPresentation"] = videoPresentation;

          let reading = [];
          if (sitm["reading"]) {
            sitm["reading"].map((vitm, vidx) => {
              let readingObj = JSON.parse(vitm);

              let outObj = {
                title: readingObj.title,
                mimetype: readingObj.mimetype,
              };

              if (readingObj["duration"]) {
                outObj["duration"] = Array.isArray(readingObj["duration"])
                  ? readingObj["duration"][0]
                  : readingObj["duration"];
              }

              if (deleted === false) {
                if (readingObj._id) {
                  outObj["_id"] = readingObj._id;
                }
              }

              reading.push(outObj);
            });
          }
          sitm["reading"] = reading;

          let exams = [];
          if (sitm["exam"]) {
            sitm["exam"].map((vitm, vidx) => {
              let examObj = JSON.parse(vitm);

              let exam = {
                _id: examObj._id,
                title: examObj.title,
                from: examObj.from,
                to: examObj.to,
                examDuration: examObj.examDuration,
                reminders: examObj.reminders,
                participants: [],
                evaluators: examObj.evaluators ? examObj.evaluators : [],
              };

              if (examObj.questions) {
                let evaluators = [];
                if (examObj.evaluators) {
                  examObj.evaluators.map((evitm, evidx) => {
                    let evaluatorsObj = evitm;
                    evaluators.push(evaluatorsObj._id);
                  });
                }
                exam["evaluators"] = evaluators;
                if (Array.isArray(examObj.questions)) {
                  examObj.questions.forEach((qitm, qidx) => {
                    if (qitm.image) {
                      if (qitm.image == "null") {
                        qitm.image = null;
                      } else {
                        let image = qitm.image;

                        try {
                          image = JSON.parse(qitm.image);

                          if (Object.keys(image).length > 0) {
                            for (var k in image) {
                              qitm.image = image[k];
                            }
                          } else {
                            qitm.image = null;
                          }
                        } catch (e) {
                          console.log(e);
                          qitm.image = null;
                        }
                      }
                    }

                    qitm.answers?.forEach((aitm, aidx) => {
                      if (!aitm.isCorrect) {
                        aitm.isCorrect = "false";
                      }
                    });
                  });

                  exam.questions = examObj.questions;
                }
              }
              if (examObj.passingGrade) {
                exam.passingGrade = examObj.passingGrade;
              }
              if (examObj.instructions) {
                exam.instructions = examObj.instructions;
              }
              exams.push(exam);
            });
          }
          sitm["exam"] = exams;
        });
      });
    }
    // } catch (e) {
    //   console.log(e);
    // }

    return out;
  },
  participantsFormatter: function (participants) {
    let output = [];

    if (Array.isArray(participants)) {
      participants?.map((itm, idx) => {
        if (itm._id) {
          let fullName = itm.fullName;

          if (!fullName) {
            fullName =
              itm["firstName"] +
              " " +
              itm["lastName"] +
              " (" +
              itm["email"] +
              ")";
          }

          output.push({ _id: itm._id, name: fullName });
        } else {
          output.push({ _id: itm, name: itm + " - Guest" });
        }
      });

      return output;
    }
    return participants;
  },
  participantsJSONData: function (jsondata) {
    let out = [];
    if (jsondata) {
      jsondata.map((itm, idx) => {
        let participant = {};
        if (itm["_id"]) {
          participant["_id"] = itm["_id"];
          //  out.push(itm["_id"]);
        } else {
          participant["_id"] = itm["name"];
          // out.push(itm["name"]);
        }

        participant["type"] = itm.group ? itm.group : "participant";
        out.push(participant);
      });
    }
    return out;
  },
  validateTrainingTest: function (validator, data) {
    if (Fun.validate(validator, data)) {
      let from = UniFun.fromToDateFormat(
        data.fromToDateTime[0].fromDateTime[0].fromDate,
        data.fromToDateTime[0].fromDateTime[0].fromTime
      );

      let to = UniFun.fromToDateFormat(
        data.fromToDateTime[0].toDateTime[0].toDate,
        data.fromToDateTime[0].toDateTime[0].toTime
      );

      if (!this.fromToValidation(from, to)) {
        return false;
      }

      if (
        data["examDuration"][0]["hours"] == "0" &&
        data["examDuration"][0]["minutes"] == "0"
      ) {
        Fun.createNotification({
          type: "danger",
          message: "Please set the duration of the test",
        });
        return false;
      }

      return true;
    }
    return false;
  },
  validateTest: function (validator, data) {
    if (Fun.validate(validator, data)) {
      let from = UniFun.fromToDateFormat(
        data.fromToDateTime[0].fromDateTime[0].fromDate,
        data.fromToDateTime[0].fromDateTime[0].fromTime
      );

      let to = UniFun.fromToDateFormat(
        data.fromToDateTime[0].toDateTime[0].toDate,
        data.fromToDateTime[0].toDateTime[0].toTime
      );

      if (!this.fromToValidation(from, to)) {
        return false;
      }

      if (
        data["examDuration"][0]["hours"] == "0" &&
        data["examDuration"][0]["minutes"] == "0"
      ) {
        Fun.createNotification({
          type: "danger",
          message: "Please set the duration of the test",
        });
        return false;
      }
      if (!data.questions) {
        Fun.createNotification({
          type: "danger",
          message: "You should at least add one question",
        });
        return false;
      }

      return true;
    }
    return false;
  },
  formJSONData: function (jsondata) {
    let out = jsondata;
    if (out["_id"] == "") {
      delete out["_id"];
    }
    if (out) {
      out.questions?.map((itm, idx) => {
        if (itm["_id"] == "") {
          delete itm["_id"];
        }
        if (itm["isRequired"] == "") {
          itm["isRequired"] = true;
        }
        if (itm.type == "multipleChoice") {
          itm.answers.map((sitm, sidx) => {
            if (sitm["_id"] == "") {
              delete sitm["_id"];
            }
          });
          itm.image = null;
        }
        if (itm.type == "essayType") {
          itm.answers = [];

          let image = itm.image ? JSON.parse(itm.image) : null;

          if (image) {
            if (Object.keys(image).length > 0) {
              itm.image = image[Object.keys(image)[0]]._id;
            } else {
              itm.image = null;
            }
          } else {
            itm.image = null;
          }
        }
      });
    }

    return out;
  },
  examJSONData: function (jsondata) {
    let out = jsondata;
    delete out["_id"];
    if (out) {
      out.questions?.map((itm, idx) => {
        if (itm["_id"] == "") {
          delete itm["_id"];
        }
        if (itm.type == "multipleChoice") {
          itm.answers.map((sitm, sidx) => {
            if (sitm["_id"] == "") {
              delete sitm["_id"];
            }
            if (sitm["isCorrect"]) {
              sitm["isCorrect"] = true;
            } else {
              sitm["isCorrect"] = false;
            }
          });
          itm.image = null;
        }
        if (itm.type == "essayType") {
          itm.answers = [];

          let image = itm.image ? JSON.parse(itm.image) : null;

          if (image) {
            if (Object.keys(image).length > 0) {
              itm.image = image[Object.keys(image)[0]]._id;
            } else {
              itm.image = null;
            }
          } else {
            itm.image = null;
          }
        }
      });
    }

    return out;
  },
  fromToDateFormat: function (date, time) {
    let output = Moment(date).format("YYYY-MM-DDT" + time + ".000Z");

    return output;
  },
  deleteTestQuestion: function (args) {
    let idx = args.idx;
    let questionTitle = args.title;
    let object = args.object;

    window["modal3"].setState({
      title: <h3>Delete Question?</h3>,
      size: "lg",
      targetComponent: this,
      footer: [
        <button
          type="button"
          className="btn btn-link border"
          onClick={(e) => {
            window["modal3"].setState({ show: false, overflowY: "auto" });
          }}
        >
          Cancel
        </button>,
        <button
          type="button"
          className="btn btn-danger"
          onClick={(e) => {
            window["modal3"].setState({ show: false, overflowY: "auto" });
            object.deleteControl(idx);
          }}
        >
          Delete
        </button>,
      ],
      show: true,
      body: (
        <div className="p-5">
          <span>
            Are you sure you want to delete {questionTitle}? This action cannot
            be undone.
          </span>
        </div>
      ),
    });
  },
  UniAgendaSelectEvent: function (event) {
    let title = event.title;
    let moduleTitle = "";

    if (event.data.training) {
      if (event.data.training.title) {
        title = event.data.training.title;
      }
    }
    if (event.data.module) {
      if (event.data.module.title) {
        moduleTitle = event.data.module.title;
      }
    }

    let color = event.hex;

    let eventType = "";

    if (event.eventType) {
      eventType = event.eventType;
    }

    let modalTitle = (
      <div className="mr-4">
        <div className="row" style={{ fontSize: "initial" }}>
          <div className="col-8">
            <h3>
              <i className="fas fa-square mr-2" style={{ color: color }}></i>
              {UniFun.substring(title, 0, 50)}
            </h3>
            <span className="ml-5">{UniFun.substring(moduleTitle, 0, 30)}</span>
          </div>
          <div className="col-4 text-right">
            {eventType.toLowerCase() != "stream" ? (
              <span className="dropdown">
                <button
                  className="btn btn-link"
                  type="button"
                  id="dropdownMenu2"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <img
                    src="/uni/svg/more-options.svg"
                    style={{ height: "14px" }}
                  ></img>
                </button>
                <div className="dropdown-menu" aria-labelledby="dropdownMenu2">
                  <a
                    href="javascript:void(0)"
                    className="text-black dropdown-item pointer"
                    onClick={(e) => UniFun.createICS(event)}
                  >
                    Download Event
                  </a>
                  {/* <a
                  href="javascript:void(0)"
                  className=" text-danger dropdown-item pointer"
                >
                  Delete
                </a> */}
                </div>
              </span>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    );

    window["modal"].setState({
      title: modalTitle,
      size: "lg",
      targetComponent: this,
      callback: function (args) {
        window["modal"].setState({ show: false, overflowY: "auto" });
      },
      show: true,
      footer: [],
      body: (
        <Builder
          module="uniagenda"
          view="detail"
          key={Fun.uuid()}
          footer={[]}
          ref={(refComponent) => {
            window["modalbody"] = refComponent;
          }}
          inputData={[event]}
        ></Builder>
      ),
    });
  },
  trainingNodesCustomLayout: function (args) {
    let value = this.state.defaultValue;

    let parent = this.props.parent;
    let multipleIndex = this.props.multipleIndex;
    let mimeTypeClass = args.object.props.field.mimeTypeClass;
    let mimetype = args.object.props.field.mimetype
      ? args.object.props.field.mimetype
      : value.mimetype;
    let title = value.title;
    let showSchedule = args.object.props.field.showSchedule ? true : false;
    let scheduleDateTime = "";
    let customCallback = args.object.props.field.callback;

    if (showSchedule) {
      if (value.from) {
        let fromTime = Moment(value.from).format("ddd DD MMM YY, HH:mm");
        let toTime = Moment(value.to).format("ddd DD MMM YY, HH:mm");
        let fromDate = Moment(value.from, "DD-MM-YYYY");
        let toDate = Moment(value.to, "DD-MM-YYYY");
        let isSame = fromDate.isSame(toDate);
        scheduleDateTime = (
          <span
            className="badge badge-light p-1"
            style={{ fontSize: "100%", fontWeight: 0, color: "#000" }}
          >
            {/* {fromTime}{" "}
            {isSame ? "to " + moment(value.to).format("HH:mm") : "to " + toTime} */}
            {UniFun.fromToDateString(value.from, value.to)}
          </span>
        );
      } else {
        scheduleDateTime = (
          <span
            className="badge badge-primary p-1"
            style={{ fontSize: "100%", fontWeight: 0, color: "#fff" }}
          >
            Select date/time
          </span>
        );
      }
    }

    function editNode(args) {
      let module = args.object.props.field.modalModule;

      let modalTitle = args.object.props.field.modalTitle
        ? args.object.props.field.modalTitle
        : "";
      let view = args.object.props.field.viewModule
        ? args.object.props.field.viewModule
        : "detail";

      function callback(data) {
        if (data["file"]) {
          let fileId = data["file"]["_id"];
          let mimetype = data["file"]["mimetype"];
          let duration = data["duration"];
          let recording = data["recording"];
          let isAudio = data["isAudio"];

          let fileObject = {
            _id: fileId,
            title: data.title,
            mimetype: mimetype,
            isAudio: isAudio ? true : false,
            recording: recording ? true : false,
          };

          if (duration) {
            fileObject["duration"] = Array.isArray(duration)
              ? duration[0]
              : duration;
          }

          if (fileId) {
            window["modal2"].state.targetComponent.setState({
              defaultValue: fileObject,
            });
          }
        }
      }

      let data = args.object.state.defaultValue;

      UniFun.trainingNodes({
        module: module,
        view: view,
        control: args.object,
        callback: customCallback ? customCallback : callback,
        modalTitle: modalTitle,
        inputData: {
          data: [data],
        },
      });
    }

    let output = (
      <div className="row p-0 m-0 rowhover rounded">
        <div className="col-md-8 pl-0">
          <div className="d-flex  mt-2 pb-2 ">
            <img
              src={UniFun.mimeTypeIcon(mimetype)}
              style={{ height: "25px" }}
              className={mimeTypeClass}
            />
            <span className="pl-2 font-14 text-black">
              <a href="javascript:;">
                {title}
                <span className="ml-2"> {scheduleDateTime}</span>
              </a>
            </span>

            {args.control}
          </div>
        </div>
        <div className="col-md-4 text-right  mt-2 pb-1">
          <button
            type="button"
            className="close "
            data-dismiss="modal"
            aria-label="Close"
            onClick={(e) => {
              parent.deleteControl(multipleIndex);
            }}
          >
            <i className="fas fa-times mr-4 pointer"></i>
          </button>
          <button
            type="button"
            className="close "
            data-dismiss="modal"
            aria-label="Close"
            onClick={(e) => editNode(args)}
          >
            <i className="far fa-edit mr-4 pointer"></i>
          </button>
        </div>
      </div>
    );
    return output;
  },
  modalCommonFooter: function (
    modalName,
    submitCaption,
    submitClass,
    eligibleToSave
  ) {
    let submitModalName = modalName + "body";
    if (!submitClass) {
      submitClass = "btn-primary";
    }

    let buttons = [
      <button
        type="button"
        className="btn btn-link border"
        onClick={() => {
          window[modalName].setState({ show: false, overflowY: "auto" });
        }}
      >
        Cancel
      </button>,
    ];

    if (typeof eligibleToSave !== "undefined") {
      if (eligibleToSave) {
        buttons.push(
          <button
            type="button"
            className={"btn  " + submitClass}
            onClick={() => {
              Fun.submitFromModal(submitModalName);
            }}
          >
            {submitCaption}
          </button>
        );
      }
    } else {
      if (submitCaption) {
        buttons.push(
          <button
            type="button"
            className={"btn  " + submitClass}
            onClick={() => {
              Fun.submitFromModal(submitModalName);
            }}
          >
            {submitCaption}
          </button>
        );
      }
    }

    return buttons;
  },
  createSessionPreview(session) {
    let reading = [];
    let videoConference = [];
    let exams = [];
    let videoPresentation = [];
    let output = [];
    let instructors = session.instructors;

    session.videoPresentation.map((itm, idx) => {
      videoPresentation.push(
        <div
          className=" p-3 session-row rounded row pointer"
          key={Fun.uuid()}
          onClick={(e) => {
            UniFun.readingsPreviewFile(
              {
                title: itm.title,
                mimetype: "video/mp4",
                fileurl: window["baseurl"](
                  "/agenda-file/data/" +
                    itm._id +
                    "?type=preview&jwt=" +
                    localStorage.getItem("jwt")
                ),
                filedownloadurl: window["baseurl"](
                  "/agenda-file/data/" +
                    itm._id +
                    "?type=download&jwt=" +
                    localStorage.getItem("jwt")
                ),
              },
              null
            );
          }}
        >
          <div className="col-md-10 pt-3">
            <h5 className="pb-0 mb-1">
              <img
                src="/uni/svg/pending.svg"
                style={{ width: "16px", paddingBottom: "3px" }}
              ></img>{" "}
              {itm.title}
            </h5>
            <span className="text-dark small pl-19px">Self paced</span>
          </div>
          <div className="col-md-2 text-center">
            <span className="grid-controls">
              <button
                type="button"
                onClick={(e) => {
                  UniFun.readingsPreviewFile(
                    {
                      title: itm.title,
                      mimetype: "video/mp4",
                      fileurl: window["baseurl"](
                        "/agenda-file/data/" +
                          itm._id +
                          "?type=preview&jwt=" +
                          localStorage.getItem("jwt")
                      ),
                      filedownloadurl: window["baseurl"](
                        "/agenda-file/data/" +
                          itm._id +
                          "?type=download&jwt=" +
                          localStorage.getItem("jwt")
                      ),
                    },
                    null
                  );
                }}
                className="btn btn-primary border mt-2"
              >
                Open
              </button>
            </span>
          </div>
        </div>
      );
    });

    session.reading.map((itm, idx) => {
      reading.push(
        <div
          className=" p-3 session-row rounded row pointer"
          key={Fun.uuid()}
          onClick={(e) => {
            UniFun.readingsPreviewFile(
              {
                title: itm.title,
                fileurl: window["baseurl"](
                  "/agenda-file/data/" +
                    itm._id +
                    "?type=preview&jwt=" +
                    localStorage.getItem("jwt")
                ),
                filedownloadurl: window["baseurl"](
                  "/agenda-file/data/" +
                    itm._id +
                    "?type=download&jwt=" +
                    localStorage.getItem("jwt")
                ),
              },
              null
            );
          }}
        >
          <div className="col-md-10 pt-3">
            <h5 className="pb-0 mb-1">
              <img
                src="/uni/svg/pending.svg"
                style={{ width: "16px", paddingBottom: "3px" }}
              ></img>{" "}
              {itm.title}
            </h5>
            <span className="text-dark small pl-19px">Self paced</span>
          </div>
          <div className="col-md-2 text-center">
            <span className="grid-controls">
              <button
                type="button"
                onClick={(e) => {
                  UniFun.readingsPreviewFile(
                    {
                      title: itm.title,
                      fileurl: window["baseurl"](
                        "/agenda-file/data/" +
                          itm._id +
                          "?type=preview&jwt=" +
                          localStorage.getItem("jwt")
                      ),
                      filedownloadurl: window["baseurl"](
                        "/agenda-file/data/" +
                          itm._id +
                          "?type=download&jwt=" +
                          localStorage.getItem("jwt")
                      ),
                    },
                    null
                  );
                }}
                className="btn btn-primary border mt-2"
              >
                Show
              </button>
            </span>
          </div>
        </div>
      );
    });
    session.videoConference.map((itm, idx) => {
      let meetingLink = "";

      if (UniFun.meetingIsAvailable(itm.from, itm.to)) {
        meetingLink = "/meeting/v-" + itm._id;
      }

      videoConference.push(
        <div
          className=" p-3 session-row rounded row pointer"
          onClick={(e) => {
            if (meetingLink) {
              window.open(meetingLink, "_blank");
            }
          }}
        >
          <div className="col-md-10">
            <h5 className="pb-0 mb-1">
              <img
                src="/uni/svg/pending.svg"
                style={{ width: "16px", paddingBottom: "3px" }}
              ></img>{" "}
              {itm.title}
            </h5>
            <span className="text-dark small pl-19px">
              {UniFun.fromToDateString(itm.from, itm.to)}
            </span>
          </div>
          <div className="col-md-2 text-center">
            <span className="grid-controls">
              {UniFun.meetingIsAvailable(itm.from, itm.to) ? (
                <a
                  href={"/meeting/v-" + itm._id}
                  target="new"
                  className="btn btn-primary "
                >
                  Join
                </a>
              ) : (
                <button
                  type="button"
                  className="btn btn-primary disabled action-btn"
                  data-event-start={moment(itm.from).valueOf()}
                  disabled
                  data-event-url={"/meeting/v-" + itm._id}
                >
                  Join
                </button>
              )}
            </span>
          </div>
        </div>
      );
    });
    session.exam.map((itm, idx) => {
      exams.push(
        <div className=" p-3 session-row rounded row">
          <div className="col-md-10">
            <h5 className="pb-0 mb-1">
              <img
                src="/uni/svg/pending.svg"
                style={{ width: "16px", paddingBottom: "3px" }}
              ></img>{" "}
              {itm.title}
              <span className="small bg-light p-1 pl-2 pr-2 rounded ml-2">
                {UniFun.gradeExam(itm.passingGrade)}
              </span>
            </h5>
            <span className="text-dark small pl-19px">
              {UniFun.fromToDateString(itm.from, itm.to)}
            </span>
          </div>
          <div className="col-md-2 text-center">
            {UniFun.createStartExamBtn(itm)}
            {/* <button type="button" className="btn btn-link border mt-2"></button> */}
          </div>
        </div>
      );
    });

    if (instructors.length > 0) {
      instructors = (
        <div className=" mt-4 session-section">
          <div className="mb-3">
            <h4>
              <img
                src="/uni/svg/meeting_participants.svg"
                className="mr-2 mb-1 filter-light-grey"
                style={{ width: "20px" }}
              />
              Instructors ({instructors.length})
            </h4>
          </div>
          <div className="pl-12px p-3">
            {UniFun.createProfileImageBubble(instructors)}
          </div>
        </div>
      );
      output.push(instructors);
    }

    if (videoConference.length > 0) {
      videoConference = (
        <div className=" mt-4 session-section">
          <div className="mb-3">
            <h4>
              <img
                src="/uni/svg/videocall.svg"
                className="mr-2 mb-1 filter-light-grey"
                style={{ width: "20px" }}
              />
              Video Conferences ({videoConference.length})
            </h4>
          </div>
          <div className="pl-12px p-3">{videoConference}</div>
        </div>
      );
      output.push(videoConference);
    }

    if (videoPresentation.length > 0) {
      videoPresentation = (
        <div className=" mt-4  session-section">
          <div className="mb-3">
            <h4>
              <img
                src="/uni/svg/video.svg"
                className="mr-2 mb-1 filter-light-grey"
                style={{ width: "20px" }}
              />
              Video Presentations ({videoPresentation.length})
            </h4>
          </div>
          <div className="pl-12px p-3">{videoPresentation}</div>
        </div>
      );
      output.push(videoPresentation);
    }

    if (reading.length > 0) {
      reading = (
        <div className=" mt-4 session-section">
          <div className="mb-3">
            <h4>
              <img
                src="/uni/svg/reading.svg"
                className="mr-2 mb-1 filter-light-grey"
                style={{ width: "20px" }}
              />
              Reading ({reading.length})
            </h4>
          </div>
          <div className="pl-12px p-3">{reading}</div>
        </div>
      );
      output.push(reading);
    }

    if (exams.length > 0) {
      exams = (
        <div className="mt-4 session-section">
          <div className="mb-3">
            <h4>
              <img
                src="/uni/svg/taketest.svg"
                className="mr-2 mb-1 filter-light-grey"
                style={{ width: "20px" }}
              />
              Tests ({exams.length})
            </h4>
          </div>
          <div className="pl-12px p-3">{exams}</div>
        </div>
      );
      output.push(exams);
    }

    return <div className=" session-sections">{output}</div>;
  },

  commonHeader: function () {
    let headerTitle = "";
    let headerTitleIcon = "";
    let headerClass = "";
    let headerCustomClass = "";
    let headerSubtitle = "";
    let headerIconUrl = "";

    if (this.props.builder.state.module.templateConfig.headerTitle) {
      headerTitle = this.props.builder.state.module.templateConfig.headerTitle;
      if (typeof headerTitle === "function") {
        headerTitle = headerTitle();
      }
    }
    if (this.props.builder.state.module.templateConfig.headerClass) {
      headerClass = this.props.builder.state.module.templateConfig.headerClass;
    }
    if (this.props.builder.state.module.templateConfig.headerCustomClass) {
      headerCustomClass =
        this.props.builder.state.module.templateConfig.headerCustomClass;
    }
    if (this.props.builder.state.module.templateConfig.headerIconUrl) {
      headerIconUrl =
        this.props.builder.state.module.templateConfig.headerIconUrl;
    }

    if (this.props.builder.state.module.templateConfig.headerCustomClass) {
      headerCustomClass =
        this.props.builder.state.module.templateConfig.headerCustomClass;
    }
    if (this.props.builder.state.module.templateConfig.headerTitleIcon) {
      headerTitleIcon =
        this.props.builder.state.module.templateConfig.headerTitleIcon;
    }
    if (this.props.builder.state.module.templateConfig.headerSubtitle) {
      if (
        typeof this.props.builder.state.module.templateConfig.headerSubtitle ==
        "function"
      ) {
        headerSubtitle =
          this.props.builder.state.module.templateConfig.headerSubtitle();
      } else {
        headerSubtitle =
          this.props.builder.state.module.templateConfig.headerSubtitle;
      }
    }
    return (
      <div className={"uniTopHeader " + headerClass}>
        <TopTemplateHeader
          customClass={"mb-4 uniFixedHeader " + headerCustomClass}
          subtitle={headerSubtitle}
          title={
            <React.Fragment>
              {headerIconUrl ? (
                <Link to={headerIconUrl}>
                  <img
                    src={headerTitleIcon}
                    className="pr-3"
                    style={{ width: "49px", paddingBottom: "7px" }}
                  ></img>
                </Link>
              ) : (
                <img
                  src={headerTitleIcon}
                  className="pr-3"
                  style={{ width: "49px", paddingBottom: "7px" }}
                ></img>
              )}{" "}
              <span title={headerTitle}>
                {UniFun.substring(headerTitle, 0, 25)}
              </span>
            </React.Fragment>
          }
        ></TopTemplateHeader>
      </div>
    );
  },
  substring(value, start, end) {
    let out = value;

    if (value) {
      if (value.length > end) {
        out = out.substring(start, end) + "...";
      }
    }

    return out;
  },
  stripTags(str) {
    var tmp = document.createElement("DIV");
    tmp.innerHTML = str;
    return tmp.textContent || tmp.innerText || "";
  },
  trainingNodeFileUI: function (file, removeFun, fileIdx) {
    if (file && file.title) {
      return (
        <div
          className="pb-3 pl-3 pr-3 mt-1 file-upload pointer"
          key={Fun.uuid()}
        >
          <span className=" font-14 text-dark" style={{ minWidth: "200px" }}>
            <img
              src={UniFun.mimeTypeIcon(file.mimetype)}
              style={{
                width: "16px",
                paddingBottom: "2px",
                marginRight: "3px",
              }}
            />
            <span title={file.title}>
              {UniFun.substring(file.title, 0, 55)}
            </span>
            <button
              type="button"
              className="close float-right"
              data-dismiss="modal"
              aria-label="Close"
              onClick={(e) => removeFun(fileIdx)}
            >
              <span aria-hidden="true" style={{ fontSize: "20px" }}>
                &times;
              </span>
            </button>
          </span>
        </div>
      );
    }
    return "";
  },
  fileProgress: function (args) {
    let control = args.control;
    let files = args.files;
    let parentFolder = args.parent;
    let builder = args.builder;
    let type = args.type;
    let filesName = args.filesName ? args.filesName : "file";
    let rerunComponent = args.rerunComponent;
    let showModalOnDelete = args.showModalOnDelete === false ? false : true;
    let url = args.url ? args.url : "/file";

    Array.from(files).map((file, fidx) => {
      let submittedData = new FormData();
      if (type) {
        submittedData.set("type", type);
      }
      submittedData.set(filesName, file);

      if (parentFolder) {
        submittedData.set("folder", parentFolder);
      }

      let progressRef = React.createRef();

      let axiosCancelToken = axios.CancelToken.source();

      let progressbar = (
        <ProgressBarControl
          field={{ config: { max: file.size, min: 0, now: 0 } }}
          ref={progressRef}
          key={Fun.uuid()}
        ></ProgressBarControl>
      );

      function cancelUpload(axiosCancelToken, control, contentKey, fileName) {
        let currentDefaultValue = JSON.parse(control.state.defaultValue);

        let modalBody = (
          <p className="p-4">
            Are you sure you want to cancel uploading '
            <strong>{fileName}'</strong> file?
          </p>
        );

        if (currentDefaultValue[contentKey]) {
          modalBody = (
            <p className="p-4">
              Are you sure you want to delete the '<strong>{fileName}'</strong>{" "}
              file?
            </p>
          );
        }

        function deleteFile() {
          try {
            let currentContent = control.state.content;
            let content = [];

            let defaultValue = {};

            currentContent.map((itm, idx) => {
              if (itm.key != contentKey) {
                content.push(itm);
              }
            });

            Object.keys(currentDefaultValue).map((itm, idx) => {
              if (itm != contentKey) {
                defaultValue[itm] = currentDefaultValue[itm];
              }
            });
            control.setState({
              content: content,
              defaultValue: JSON.stringify(defaultValue),
            });

            window["modal3"].setState({ show: false, overflowY: "auto" });
            if (!currentDefaultValue[contentKey]) {
              axiosCancelToken.cancel();
            }
          } catch (e) {
            console.log(e);
          }
        }

        if (showModalOnDelete || !currentDefaultValue[contentKey]) {
          window["modal3"].setState({
            title: <h3>Attention</h3>,
            size: "",
            footer: [
              <button
                type="button"
                className="btn btn-link border"
                onClick={() => {
                  window["modal3"].setState({ show: false, overflowY: "auto" });
                }}
              >
                Cancel
              </button>,
              <button
                type="button"
                className="btn btn-danger "
                onClick={(e) => deleteFile(e)}
              >
                Remove
              </button>,
            ],
            show: true,
            body: modalBody,
          });
        } else {
          deleteFile();
        }
      }

      let contentKey = Fun.uuid();

      let contentRef = React.createRef();

      let content = (
        <div
          className="pb-3 pl-3 pr-3  mt-1 file-upload pointer"
          key={contentKey}
          ref={contentRef}
        >
          <span className=" font-14 text-dark" style={{ minWidth: "200px" }}>
            <img
              src={UniFun.mimeTypeIcon(file.type)}
              style={{
                width: "16px",
                paddingBottom: "2px",
                marginRight: "3px",
              }}
            />
            <span title={file.name}>{UniFun.substring(file.name, 0, 55)}</span>
            <button
              type="button"
              className="close float-right"
              data-dismiss="modal"
              aria-label="Close"
              onClick={(e) =>
                cancelUpload(axiosCancelToken, control, contentKey, file.name)
              }
            >
              <span aria-hidden="true" style={{ fontSize: "20px" }}>
                &times;
              </span>
            </button>
            <div className="ml-2 mt-1">{progressbar}</div>
          </span>
        </div>
      );

      setTimeout(function () {
        let contents = [];
        let pending = control.state.pending ? control.state.pending : [];
        control.state.content?.map((citm, cidx) => {
          contents.push(citm);
        });
        contents.push(content);
        pending.push(contentKey);
        control.setState({ content: contents, pending: pending });
      }, 0);

      let argsUpload = {
        url: {
          url: url,
          parameters: {
            headers: {},
            replace: {},
          },
          formatter: function (data) {
            let formattedData = [];
            if (data) {
              let dataResp = data.data;
              if (!Array.isArray(dataResp)) {
                dataResp = [dataResp];
              }
              dataResp.map((itm, idx) => {
                let formattedRecord = {};

                formattedRecord["_id"] = itm._id;
                formattedRecord["title"] = itm.title
                  ? itm.title
                  : itm.originalname;
                formattedRecord["mimetype"] = itm.mimetype;
                if (type == "usersimport") {
                  formattedRecord["fileKey"] = itm.fileKey;
                }
                formattedData.push(formattedRecord);
              });
            }

            return formattedData;
          },
        },
        cancelToken: axiosCancelToken.token,
        method: "POST",
        callback: function (args, object) {
          try {
            let controlValue = control.state.defaultValue
              ? JSON.parse(control.state.defaultValue)
              : {};

            if (Array.isArray(controlValue)) {
              controlValue.push(args[0]);
              let pending = [];

              control.state.pending.map((itm, idx) => {
                if (itm != contentKey) {
                  pending.push(itm);
                }
              });

              control.setState({
                defaultValue: JSON.stringify(controlValue),
                pending: pending,
              });
            } else {
              progressbar.ref.current.setState({
                show: false,
                overflowY: "auto",
              });
              controlValue[contentKey] = args[0];
              control.setState({
                defaultValue: JSON.stringify(controlValue),
              });
            }
          } catch (e) {
            console.log(e);
          }

          if (rerunComponent) {
            if (builder) {
              builder.rerunComponent();
            }
          }
        },
        onUploadProgress: function (p) {
          try {
            progressbar.ref.current.setState({ now: p.loaded });
          } catch (e) {}
        },
        submittedData: submittedData,
      };
      control.uploadFiles(argsUpload);
    });
  },
};

export default UniFun;
