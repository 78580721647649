import React, { Component } from "react";
import Fun from "../../../lib/functions";
import { Link } from "react-router-dom";

class Squares extends Component {
  state = {
    data: [],
    loaded: false,
    templateTop: "",
    templateBottom: "",
    templateLeft: "",
    templateRight: "",
    panelTop: "",
    panelBottom: "",
    panelLeft: "",
    panelRight: "",
    header: "",
    colsTemplateCount: 12,
    colsFormCount: 12,
    squaresConfig: {
      titleAttribute: "title",
      colorAttribute: "color",
      iconAttribute: '<i class="fas fa-infinity"></i>',
      dataSections: [],
    },
  };

  constructor(props) {
    super(props);

    // this.createBody = this.createBody.bind(this);
    this.createSqueares = this.createSquares.bind(this);
    this.onClick = this.onClick.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.action) {
      return false;
    }
    return true;
  }

  componentDidMount() {
    let builder = this.props.builder.state;
    let templateConfig = builder.module.templateConfig;
    let useCard =
      typeof templateConfig.useCard !== "undefined"
        ? templateConfig.useCard
        : true;
    let templateTop = templateConfig.templateTop
      ? templateConfig.templateTop
      : () => {};

    this["templateTop"] = templateTop.bind(this);
    let templateBottom = templateConfig.templateBottom
      ? templateConfig.templateBottom
      : () => {};
    this["templateBottom"] = templateBottom.bind(this);
    let templateLeft = templateConfig.templateLeft
      ? templateConfig.templateLeft
      : () => {};
    this["templateLeft"] = templateLeft.bind(this);
    let templateRight = templateConfig.templateRight
      ? templateConfig.templateRight
      : () => {};
    this["templateRight"] = templateRight.bind(this);
    let cardTemplateTop = templateConfig.cardTemplateTop
      ? templateConfig.cardTemplateTop
      : () => {};
    this["cardTemplateTop"] = cardTemplateTop.bind(this);
    let cardTemplateBottom = templateConfig.cardTemplateBottom
      ? templateConfig.cardTemplateBottom
      : () => {};
    this["cardTemplateBottom"] = cardTemplateBottom.bind(this);
    let panelTop = templateConfig.panelTop ? templateConfig.panelTop : () => {};
    this["panelTop"] = panelTop.bind(this);
    let panelBottom = templateConfig.panelBottom
      ? templateConfig.panelBottom
      : () => {};
    this["panelBottom"] = panelBottom.bind(this);
    let panelLeft = templateConfig.panelLeft
      ? templateConfig.panelLeft
      : () => {};
    this["panelLeft"] = panelLeft.bind(this);
    let panelRight = templateConfig.panelRight
      ? templateConfig.panelRight
      : () => {};
    this["panelRight"] = panelRight.bind(this);
    let header = templateConfig.header ? templateConfig.header : () => {};
    this["header"] = header.bind(this);

    let colsTemplateCount = templateConfig.colsTemplateCount
      ? templateConfig.colsTemplateCount
      : this.state.colsTemplateCount;
    let colsFormCount = templateConfig.colsFormCount
      ? templateConfig.colsFormCount
      : this.state.colsFormCount;

    let squaresTitleAttribute = this.state.squaresConfig.titleAttribute;
    let squaresColorAttribute = this.state.squaresConfig.colorAttribute;
    let squaresIconAttribute = this.state.squaresConfig.iconAttribute;
    let squaresDataSections = this.state.squaresConfig.dataSections;

    if (templateConfig.squaresConfig) {
      if (templateConfig.squaresConfig.titleAttribute) {
        squaresTitleAttribute = templateConfig.squaresConfig.titleAttribute;
      }
      if (templateConfig.squaresConfig.colorAttribute) {
        squaresColorAttribute = templateConfig.squaresConfig.colorAttribute;
      }
      if (templateConfig.squaresConfig.iconAttribute) {
        squaresIconAttribute = templateConfig.squaresConfig.iconAttribute;
      }
      if (templateConfig.squaresConfig.dataSections) {
        squaresDataSections = templateConfig.squaresConfig.dataSections;
      }
    }

    this.setState({
      data: this.props.data,
      loaded: true,
      templateTop: templateTop,
      templateBottom: templateBottom,
      templateLeft: templateLeft,
      templateRight: templateRight,
      panelTop: panelTop,
      panelBottom: panelBottom,
      panelLeft: panelLeft,
      panelRight: panelRight,
      header: header,
      colsTemplateCount: colsTemplateCount,
      colsFormCount: colsFormCount,
      squaresConfig: {
        titleAttribute: squaresTitleAttribute,
        colorAttribute: squaresColorAttribute,
        iconAttribute: squaresIconAttribute,
        dataSections: squaresDataSections,
      },
    });
  }

  onClick(e, itm) {
    this.props.builder.setState({ loaded: false });
    this.props.builder.rerunComponent();
  }

  // createBody() {
  //   let rows = [];

  //   let dataSections = this.state.squaresConfig.dataSections;

  //   if (dataSections.length > 0) {
  //     let sections = [];
  //     dataSections.map((sec, secId) => {
  //       let data = this.state.data;
  //       let sectionSquares = [];

  //       data.map((itm, idx) => {
  //         sectionSquares.push(this.createSquares(itm));
  //       });

  //       let collapseUuid = sec["label"];

  //       let collapsed = sec["collapsed"] ? "collapsed" : "";
  //       let show = sec["collapsed"] ? "" : "show";

  //       sections.push(
  //         <React.Fragment>
  //           <li
  //             className="c-sidebar-nav-item c-sidebar-nav-dropdown  "
  //             key={Fun.uuid()}
  //           >
  //             <a
  //               className={
  //                 "c-sidebar-nav-link c-sidebar-nav-dropdown-toggle c-has-children font-weight-600 font-size-15 " +
  //                 collapsed
  //               }
  //               data-toggle="collapse"
  //               data-target={"#" + collapseUuid}
  //               key={Fun.uuid()}
  //             >
  //               {sec["label"] + " (" + data.length + ")"}
  //             </a>
  //             <div
  //               className={
  //                 "row mt-3 collapse c-squares c-square-section " + show
  //               }
  //               id={collapseUuid}
  //             >
  //               {sectionSquares}
  //             </div>
  //           </li>
  //           {secId < dataSections.length - 1 ? (
  //             <li>
  //               <hr></hr>
  //             </li>
  //           ) : (
  //             ""
  //           )}
  //         </React.Fragment>
  //       );
  //     });

  //     rows.push(
  //       <ul className="c-squares  c-sidebar-nav  mt-20 ">{sections}</ul>
  //     );
  //   } else {
  //     let data = this.state.data;

  //     data.map((itm, idx) => {
  //       rows.push(this.createSqueares(itm));
  //     });
  //   }

  //   return <div className="row mt-3">{rows}</div>;
  // }

  createSquares() {
    let output = [];

    this.state.data.map((itm, idx) => {
      output.push(
        <div className="col-xl-2 col-lg-4 col-md-4 col-sm-6 p-2 squaresCol ">
          {itm["_id"].data.onClick ? (
            <div
              className=" rounded  rounded-125 border squaresDiv shadow  pointer d-flex align-items-center"
              onClick={(e) => itm["_id"].data.onClick(this.props.builder)}
              style={{
                backgroundColor:
                  itm[this.state.squaresConfig.colorAttribute].value,
              }}
            >
              <div className="box w-100">
                {Fun.parse(itm[this.state.squaresConfig.iconAttribute].value)}
              </div>
            </div>
          ) : (
            <Link
              className=" rounded  rounded-125 border squaresDiv shadow  d-flex align-items-center"
              to={itm["url"].value}
              style={{
                backgroundColor:
                  itm[this.state.squaresConfig.colorAttribute].value,
              }}
            >
              <div className="box w-100">
                {Fun.parse(itm[this.state.squaresConfig.iconAttribute].value)}
              </div>
            </Link>
          )}
          <div className="squaresTitle">
            {itm[this.state.squaresConfig.titleAttribute].value}
          </div>
        </div>
      );
    });

    return <div className="row">{output}</div>;
  }

  render() {
    if (this.state.loaded) {
      return (
        <React.Fragment>
          {this.header()}
          {this.panelTop()}
          <div className="container-fluid">
            {this.templateTop()}
            <div className="row">
              {this.panelLeft()}
              <div className={"col-" + this.state.colsTemplateCount}>
                <div className="row">
                  {this.templateLeft()}
                  <div className={"col-" + this.state.colsFormCount}>
                    {this.cardTemplateTop()}
                    <div className="container-fluid">
                      {this.createSquares()}
                    </div>
                    {this.cardTemplateBottom()}
                  </div>
                  {this.templateRight()}
                </div>
              </div>
              {this.panelRight()}
            </div>
            {this.templateBottom()}
          </div>
          {this.panelBottom()}
        </React.Fragment>
      );
    } else {
      return "loading...";
    }
  }
}

export default Squares;
