import React, { Component } from "react";
import Control from "../../control";
import SimpleReactValidator from "simple-react-validator";
import Fun from "../../../../../lib/functions";
import UniFun from "../../../../../modules/uni/functions";
import moment from "moment";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";

class Cookies extends Component {
  state = {
    data: this.props.data,
    loaded: false,
    showCookie: true,
  };

  constructor(props) {
    super(props);

    this.cookieAccept = this.cookieAccept.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.action) {
      return false;
    }
    return true;
  }

  componentDidMount() {
    let showCookie = true;

    if (localStorage.getItem("acceptCookie") === "true") {
      showCookie = false;
    }

    this.setState({
      loaded: true,
      showCookie: showCookie,
    });

    if (this.componentDidMountEvent) {
      this.componentDidMountEvent();
    }
  }

  cookieAccept() {
    localStorage.setItem("acceptCookie", "true");
    this.setState({
      showCookie: false,
    });
  }

  render() {
    return (
      <div className={this.state.showCookie ? "cookies p-3" : "d-none"}>
        <div className="row">
          <div className="col-8">
            <h3>About Cookies On This Site</h3>
            <span>
              We use cookies to create the best experience for you. For more
              information on how we use cookies, please see our{" "}
              <Link to={"/events/conferences/privacy"} className="text-primary">
                Privacy Policy
              </Link>
              .
            </span>
          </div>
          <div className="col-4 pt-2 text-right">
            <button
              className="btn btn-primary mr-3"
              onClick={this.cookieAccept}
            >
              accept
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Cookies);
