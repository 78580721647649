import React, { Component } from "react";
import Control from "./../../control";
import SimpleReactValidator from "simple-react-validator";
import Fun from "../../../../../lib/functions";
import UniFun from "../../../../../modules/uni/functions";
import moment from "moment";
import TopTemplateHeader from "./../../topTemplateHeader";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";

class Session extends Component {
  state = {
    data: this.props.data,
    loaded: false,
    colsTemplateCount: 12,
    colsFormCount: 12,
    cardBodyCustomClass: "",
    module: {
      _id: "",
      title: "",
      description: "",
    },
    session: {},
  };

  constructor(props) {
    super(props);
    let builder = this.props.builder.state;
    let templateConfig = builder.module.templateConfig;
    if (templateConfig.events) {
      Object.keys(templateConfig.events).map((itm, idx) => {
        this[itm] = templateConfig.events[itm].bind(this);
      });
    }
    this.sessionsMenu = this.sessionsMenu.bind(this);
    this.sessionsCards = this.sessionsCards.bind(this);
    this.form = React.createRef();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.action) {
      return false;
    }
    return true;
  }

  componentDidMount() {
    let builder = this.props.builder.state;
    let templateConfig = builder.module.templateConfig;
    let useCard =
      typeof templateConfig.useCard !== "undefined"
        ? templateConfig.useCard
        : true;

    let templateTop = templateConfig.templateTop
      ? templateConfig.templateTop
      : () => {};
    this["templateTop"] = templateTop.bind(this);
    let templateBottom = templateConfig.templateBottom
      ? templateConfig.templateBottom
      : () => {};
    this["templateBottom"] = templateBottom.bind(this);
    let templateLeft = templateConfig.templateLeft
      ? templateConfig.templateLeft
      : () => {};
    this["templateLeft"] = templateLeft.bind(this);
    let templateRight = templateConfig.templateRight
      ? templateConfig.templateRight
      : () => {};
    this["templateRight"] = templateRight.bind(this);
    let cardTemplateTop = templateConfig.cardTemplateTop
      ? templateConfig.cardTemplateTop
      : () => {};
    this["cardTemplateTop"] = cardTemplateTop.bind(this);
    let cardTemplateBottom = templateConfig.cardTemplateBottom
      ? templateConfig.cardTemplateBottom
      : () => {};
    this["cardTemplateBottom"] = cardTemplateBottom.bind(this);
    let panelTop = templateConfig.panelTop ? templateConfig.panelTop : () => {};
    this["panelTop"] = panelTop.bind(this);
    let panelBottom = templateConfig.panelBottom
      ? templateConfig.panelBottom
      : () => {};
    this["panelBottom"] = panelBottom.bind(this);
    let panelLeft = templateConfig.panelLeft
      ? templateConfig.panelLeft
      : () => {};
    this["panelLeft"] = panelLeft.bind(this);
    let panelRight = templateConfig.panelRight
      ? templateConfig.panelRight
      : () => {};
    this["panelRight"] = panelRight.bind(this);
    let header = templateConfig.header ? templateConfig.header : () => {};
    this["header"] = header.bind(this);

    let colsTemplateCount = templateConfig.colsTemplateCount
      ? templateConfig.colsTemplateCount
      : this.state.colsTemplateCount;
    let colsFormCount = templateConfig.colsFormCount
      ? templateConfig.colsFormCount
      : this.state.colsFormCount;

    let cardBodyCustomClass = templateConfig.cardBodyCustomClass
      ? templateConfig.cardBodyCustomClass
      : this.state.cardBodyCustomClass;

    this.setState({
      loaded: true,
      rawData: this.props.rawData,
      colsTemplateCount: colsTemplateCount,
      colsFormCount: colsFormCount,
      cardBodyCustomClass: cardBodyCustomClass,
      useCard: useCard,
    });

    this.validator = this.props.builder.validator;
    if (this.componentDidMountEvent) {
      this.componentDidMountEvent();
    }
  }

  sessionsCards() {
    let output = [];

    let data = this.state.rawData.data[0];

    let speakersIds = [];
    let speakers = [];
    let about = "";
    let recording = "";
    data.days?.forEach((itm, idx) => {
      //if (itm._id == Fun.getURLPathValue(2)) {
      itm.sessions.forEach((sitm, sidx) => {
        if (sitm._id == Fun.getURLPathValue(4)) {
          about = sitm.description;
          recording = sitm.recording;
          sitm.speakersRaw.forEach((spitm, spidx) => {
            if (speakersIds.indexOf(spitm._id) < 0) {
              speakersIds.push(spitm._id);

              let name = (
                <span style={{ display: "inline-block" }}>
                  <span className="pt-2">
                    <strong className="text-primary">
                      {spitm.firstName + " " + spitm.lastName}
                    </strong>
                    <br></br>
                    {spitm.jobTitle}
                  </span>
                </span>
              );
              speakers.push(
                <div
                  className="mt-3 pointer "
                  onClick={(e) => {
                    this.speakerInfoModal(spitm);
                  }}
                >
                  {UniFun.createProfileImageBubble(
                    [spitm],
                    "avatar-default",
                    false,
                    name,
                    "0px"
                  )}
                </div>
              );
            }
          });

          //   output.push(
          //     <div className="col-md-4 ">
          //       <Link to={"/conference/" + data._id + "/session/" + sitm._id}>
          //         <div className="card h-100">
          //           <div className="card-body">
          //             <h3>{sitm.title}</h3>
          //             <span className="d-block mt-4">
          //               {sitm.speakersRaw.map((spitm, spidx) => {
          //                 return (
          //                   <React.Fragment>
          //                     <span className="text-strong">
          //                       {spitm.firstName + " " + spitm.lastName}
          //                     </span>
          //                     {", " + spitm.jobTitle}
          //                   </React.Fragment>
          //                 );
          //               })}
          //             </span>
          //             <span className="d-block mt-4">
          //               {UniFun.substring(
          //                 UniFun.stripTags(sitm.description),
          //                 0,
          //                 300
          //               )}
          //             </span>
          //           </div>
          //           <div className="card-footer">
          //             <div className="row">
          //               <div className="col-md-10">
          //                 <span className="d-block pt-1 ">
          //                   {moment(itm.date).format("ddd DD MMM YYYY")}
          //                   {" | "}
          //                   {moment(sitm.fromRaw).format("HH:mm")}
          //                   {" - "}
          //                   {moment(sitm.toRaw).format("HH:mm")}
          //                 </span>
          //               </div>
          //               <div className="col-md-2">
          //                 <button
          //                   type="button"
          //                   className="btn btn-primary disabled action-btn"
          //                   data-event-start={moment(sitm.from).valueOf()}
          //                   disabled
          //                   data-event-url={"/conference/live/m" + data._id.value}
          //                 >
          //                   Join
          //                 </button>
          //               </div>
          //             </div>
          //           </div>
          //         </div>
          //       </Link>
          //     </div>
          //   );
        }
      });
      //  }
    });

    let videoRecording = [];
    if (recording) {
      videoRecording = (
        <div className="row">
          <div className="col-md-12 pl-4 pr-4">
            <div className="pb-3">
              <video controls className="w-100">
                <source
                  src={window["baseurl"](
                    "/agenda-file/data/" +
                      recording +
                      "?type=preview&jwt=" +
                      localStorage.getItem("jwt")
                  )}
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>
      );
    }

    return (
      <React.Fragment>
        {videoRecording}
        <div className="row">
          <div className="col-md-12 pl-4 pr-4">
            <div className="card shadow ">
              <div className="card-body">
                <div className="row mt-3">
                  <div className="col-md-4">
                    <h5>
                      <strong>Speakers</strong>
                    </h5>
                    <div className="pl-3">{speakers}</div>
                  </div>
                  <div className="col-md-8">
                    <h5>
                      <strong>About</strong>
                    </h5>

                    <div className="pt-2">{Fun.parse(about)}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  sessionsMenu() {
    let output = [];

    let data = this.state.data[0];

    data.days.value?.forEach((itm, idx) => {
      let colorClass = "text-dark";
      if (itm._id == Fun.getURLPathValue(4)) {
        colorClass = "text-black text-underline";
      }

      if (!Fun.getURLPathValue(4) && idx == 0) {
        colorClass = "text-black text-underline";
      }

      output.push(
        <li className="nav-link">
          <Link
            to={"/conference/" + data._id.value + "/sessions/" + itm._id}
            className={colorClass}
          >
            Day {idx + 1}
          </Link>
        </li>
      );
    });

    return (
      <ul className="nav" style={{ paddingLeft: "3px" }}>
        {output}
      </ul>
    );
  }

  render() {
    if (this.state.loaded) {
      let data = this.state.data[0];

      let sessionsMenu = this.sessionsMenu();

      let sessionTitle = "";
      let sessionSubtitle = "";
      let joinEvent = "Ff";

      this.state.rawData.data[0].days?.forEach((itm, idx) => {
        if (itm.sessions) {
          itm.sessions.forEach((sitm, sidx) => {
            if (sitm._id == Fun.getURLPathValue(4)) {
              joinEvent =
                moment(sitm.toRaw) > moment() ? (
                  <button
                    type="button"
                    className="btn btn-primary disabled action-btn mt-3"
                    data-event-start={moment(sitm.fromRaw).valueOf()}
                    data-event-end={moment(sitm.toRaw).valueOf()}
                    data-target-url="new"
                    data-event-url={
                      "/conference/live/" +
                      data._id.value +
                      "/" +
                      itm._id +
                      "/" +
                      sitm._id
                    }
                  >
                    Join session
                  </button>
                ) : null;
              sessionTitle = sitm.title;
              sessionSubtitle =
                moment(itm.date).format("ddd DD MMM YYYY") +
                " | " +
                moment(sitm.fromRaw).format("HH:mm") +
                " - " +
                moment(sitm.toRaw).format("HH:mm");
            }
          });
        }
      });

      return (
        <React.Fragment>
          <div className={"uniTopHeader "}>
            <TopTemplateHeader
              customClass="mb-4 uniFixedHeader"
              title={
                <React.Fragment>
                  <Link
                    to={"/conference/" + Fun.getURLPathValue(2) + "/sessions"}
                  >
                    <img
                      src="/uni/svg/trainings/go_back.svg"
                      className="pr-3"
                      style={{ width: "49px", paddingBottom: "7px" }}
                    ></img>{" "}
                  </Link>
                  <span>{sessionTitle}</span>
                </React.Fragment>
              }
              subtitle={<span className="pl-56">{sessionSubtitle}</span>}
              rightColumn={joinEvent}
            ></TopTemplateHeader>
          </div>
          <div className="container-fluid mt-5">{this.sessionsCards()}</div>
        </React.Fragment>
      );
    } else {
      return "loading...";
    }
  }
}

export default withRouter(Session);
