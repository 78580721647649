import React, { Component } from "react";
import { Link } from "react-router-dom";

class NewLoginForm extends Component {
  constructor(props) {
    super(props);

    let builder = this.props.builder.state;
    let templateConfig = builder.module.templateConfig;
    if (templateConfig.events) {
      Object.keys(templateConfig.events).map((itm, idx) => {
        this[itm] = templateConfig.events[itm].bind(this);
      });
    }

    this.state = {
      loaded: false,
      isLoggedIn: false,
    };
    this.form = React.createRef();
  }

  componentDidMount() {
    this.setState({ loaded: true });

    // document.addEventListener("DOMContentLoaded", function () {
    var videos = [].slice.call(document.querySelectorAll("video.lazy"));

    if ("IntersectionObserver" in window) {
      var videoObserver = new IntersectionObserver(function (
        entries,
        observer
      ) {
        entries.forEach(function (video) {
          if (video.isIntersecting) {
            for (var source in video.target.children) {
              var videoSource = video.target.children[source];
              if (
                typeof videoSource.tagName === "string" &&
                videoSource.tagName === "SOURCE"
              ) {
                videoSource.src = videoSource.dataset.src;
              }
            }

            video.target.load();
            video.target.classList.remove("lazy");
            videoObserver.unobserve(video.target);
          }
        });
      });

      videos.forEach(function (video) {
        videoObserver.observe(video);
      });
    }
    // });
  }

  render() {
    if (this.state.loaded) {
      return (
        <React.Fragment>
          {/* <video
            id="myVideo"
            autoplay="true"
            muted
            loop
            playsinline
            width="100%"
            height="auto"
            poster=""
            class="lazy"
            style={{
              position: "fixed",
              right: "0",
              bottom: "0",
              minWidth: "100%",
              minHeight: "100%",
              zIndex: "-10",
              objectFit: "cover",
              objectPosition: "center center",
            }}
          >
            <source src="/uni/videos/pella.mp4" type="video/mp4" />
          </video> */}
          <div
            style={{
              backgroundImage: "url(/uni/videos/jumbotron.jpg)",
              backgroundSize: "cover",
              backgroundPosition: "center",
              position: "fixed",
              right: "0",
              bottom: "0",
              minWidth: "100%",
              minHeight: "100%",
              boxShadow: "rgb(0 0 0 / 20%) 0px 0px 0px 2000px inset",
            }}
          ></div>
          <div className="container-fluid w-75">
            <div className="row">
              <div className="col-md-6 pb-4 ">
                <div className="pella-login-title text-left">
                  <span className=" ">THE</span>
                  <br></br>PELLA<br></br>
                  CHALLENGE
                </div>
                <div className="text-let">
                  <small>
                    The PELLA CHALLENGE is an invitation only initiative.
                    <br></br>
                    If you wish to explore opportunities reach out pella [at]
                    interfima.org
                  </small>
                </div>
              </div>
              <div className="col-md-1"></div>
              <div className="col-md-5" style={{ display: "table" }}>
                <div
                  style={{
                    display: "table-cell",
                    height: "100%",
                    verticalAlign: "middle",
                  }}
                >
                  <form
                    onSubmit={this.onSubmit}
                    ref={this.form}
                    className="ml-auto mr-auto"
                  >
                    <div className="form-group pt-3">
                      {/* <label className="text-white">Email address</label> */}
                      <div>
                        <input
                          className="form-control height-100 pella-form-control rounded-25"
                          type="text"
                          name="uni_username"
                          placeholder="Type your email address"
                        />
                      </div>
                    </div>
                    <div className="form-group pt-3">
                      {/* <label className="text-white">Password</label> */}

                      <div>
                        <input
                          className="form-control height-100 pella-form-control rounded-25"
                          type="password"
                          name="uni_password"
                          placeholder="Type your password"
                        />
                      </div>
                    </div>

                    <div className="form-group pt-3">
                      <button
                        className="btn  pella-bg-white btn-block text-black text-strong rounded-25"
                        type="submit"
                        style={{ height: "45px" }}
                      >
                        LOGIN
                      </button>
                    </div>
                    <div className="form-group text-right ">
                      <Link to="/forgot-password" className="text-white">
                        Forgot password
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    }
    return "";
  }
}

export default NewLoginForm;
