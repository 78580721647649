import React, { Component } from "react";
import Fun from "../../../lib/functions";
import Builder from "../../../lib/builder";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import UniFun from "../../../modules/uni/functions";

class TopMenuPDF extends Component {
  state = {
    data: [],
    loaded: false,
    output: null,
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.setState({ data: this.props.data, loaded: true });
    let _this = this;

    Fun.fetch({
      url: {
        url: "/agenda-file/{fileId}/isDownloadable",
        parameters: {
          headers: {
            "content-type": "application/json",
          },
          replace: {
            fileId: function () {
              return Fun.getURLPathValue(3);
            },
          },
        },
      },
      callback: function (data) {
        if (data) {
          _this.setState({
            showDownload: data.data[0],
          });
        }
      },
    });
  }

  render() {
    if (this.state.loaded) {
      let _this = this;
      return (
        <header className=" c-header c-header-light c-header-fixed c-header-with-subheader ">
          <div className="row w-100">
            <div className="col-9">
              <div className="mt-2">
                {this.props.history.length > 1 ? (
                  <img
                    src="/uni/svg/trainings/go_back.svg"
                    className="pr-3 pointer ml-3"
                    style={{ width: "45px", paddingBottom: "5px" }}
                    onClick={(e) => {
                      //console.log(_this.props.history);

                      _this.props.history.goBack();
                    }}
                  ></img>
                ) : (
                  ""
                )}
                <img
                  src="/uni/svg/files/pdf.svg"
                  className="ml-3"
                  style={{ width: "30px", paddingBottom: "5px" }}
                ></img>{" "}
                <strong>
                  {Fun.getParameterByName("title")
                    ? Fun.getParameterByName("title")
                    : "PDF file"}
                </strong>
              </div>
            </div>
            <div className="col-3 text-right">
              {this.state.showDownload ? (
                <div className="pt-3">
                  <a
                    href={window["baseurl"](
                      "/agenda-file/data/" +
                        Fun.getURLPathValue(3) +
                        "?type=download&jwt=" +
                        localStorage.getItem("jwt")
                    )}
                    target="new"
                  >
                    <img
                      src="/uni/svg/download.svg"
                      style={{ height: "15px" }}
                    ></img>
                  </a>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </header>
      );
    } else {
      return "loading...";
    }
  }
}

export default withRouter(TopMenuPDF);
