import React, { Component } from "react";
import Text from "./text/text";
import Submit from "./submit/submit";
import Multiple from "./multiple/multiple";
import Tabs from "./tabs/tabs";
import Module from "./module/module";
import Button from "./button/button";
import Hidden from "./hidden/hidden";
import TextArea from "./textarea/textarea";
import Upload from "./upload/upload";
import Group from "./group/group";
import Select from "./select/select";
import Tags from "./tags/tags";
import DateTime from "./datetime/datetime";
import Accordion from "./accordion/accordion";
import Charts from "./charts/charts";
import ToogleSwitch from "./switch/switch";
import DropdownButton from "./dropdownButton/dropdownButton";
import Checkbox from "./checkbox/checkbox";
import Editor from "./editor/editor";
import IFrame from "./iframe/iframe";
import Dropzone from "./dropzone/dropzone";
import ProgressBarControl from "./progressbar/progressbar";
import NumberWidget from "./number/number";
import Maps from "./maps/maps";
import Countdowntimer from "./countdowntimer/countdowntimer";
import Label from "./label/label";
import Ajax from "./ajax/ajax";
import ExtraFields from "./extraFields/extraFields";
import Pagination from "./pagination/pagination";
import GeneralSearch from "./generalsearch/generalsearch";

const Controls = {
  ajax: Ajax,
  text: Text,
  generalsearch: GeneralSearch,
  countdowntimer: Countdowntimer,
  number: NumberWidget,
  submit: Submit,
  multiple: Multiple,
  tabs: Tabs,
  module: Module,
  button: Button,
  dropdownbutton: DropdownButton,
  hidden: Hidden,
  textarea: TextArea,
  upload: Upload,
  group: Group,
  select: Select,
  tags: Tags,
  datetime: DateTime,
  accordion: Accordion,
  charts: Charts,
  switch: ToogleSwitch,
  checkbox: Checkbox,
  editor: Editor,
  iframe: IFrame,
  dropzone: Dropzone,
  progressbar: ProgressBarControl,
  maps: Maps,
  label: Label,
  extrafields: ExtraFields,
  pagination: Pagination,
};

export default Controls;
