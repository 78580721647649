import React, { Component } from "react";
import Fun from "../../../lib/functions";
import Builder from "../../../lib/builder";
import { Link } from "react-router-dom";
import UniFun from "../../../modules/uni/functions";
import GeneralSearch from "../../../controls/generalsearch/generalsearch";

const boldString = function (str, substr) {
  // var strRegExp = new RegExp(substr, "g");
  // return str.replace(strRegExp, "<strong>" + substr + "</strong>");
  substr = substr
    .replace("/Contacts", "")
    .replace("/Events", "")
    .replace("/Files", "")
    .trim();

  const n = str.toUpperCase();
  const q = substr.toUpperCase();
  const x = n.indexOf(q);
  if (!q || x === -1) {
    return str; // bail early
  }
  const l = q.length;
  return (
    str.substr(0, x) + "<b>" + str.substr(x, l) + "</b>" + str.substr(x + l)
  );
};

const searchOpenResult = function (data, linkTitle) {
  if (data.mimetype.startsWith("application/epub")) {
    return (
      <Link
        to={"/epub/viewer/" + data._id + "?title=" + data.rawTitle}
        target="new"
        className="d-block search-result-link pt-2 pb-2 pl-2 "
      >
        {linkTitle}
      </Link>
    );
  } else if (
    data.mimetype.startsWith("application/pdf") &&
    data.fileType != "common"
  ) {
    return (
      <Link
        to={"/pdf/viewer/" + data._id + "?title=" + data.rawTitle}
        target="new"
        className="d-block search-result-link pt-2 pb-2 pl-2 "
      >
        {linkTitle}
      </Link>
    );
  } else if (
    data.mimetype.startsWith("video/mp4") &&
    data.fileType != "common"
  ) {
    return (
      <Link
        to={"/video/viewer/" + data._id + "?title=" + data.rawTitle}
        target="new"
        className="d-block search-result-link pt-2 pb-2 pl-2 "
      >
        {linkTitle}
      </Link>
    );
  } else if (data.mimetype.startsWith("user-profile")) {
    return (
      <span
        className="d-block search-result-link pt-2 pb-2 pl-2"
        onClick={(e) => UniFun.showProfileBio(data._id)}
      >
        {linkTitle}
      </span>
    );
  } else if (data.mimetype.startsWith("events")) {
    return (
      <Link
        to={data.link}
        target="new"
        className="d-block search-result-link pt-2 pb-2 pl-2 "
      >
        {linkTitle}
      </Link>
    );
  } else {
    return (
      <span
        className="d-block search-result-link pt-2 pb-2 pl-2 "
        onClick={(e) => {
          data.fileType == "common"
            ? UniFun.readingsPreviewFile(
                {
                  title: data.rawTitle,
                  mimetype: data.mimetype,
                  download: true,
                  fileurl: window["baseurl"](
                    "/file/data/" +
                      data._id +
                      "?type=preview&jwt=" +
                      localStorage.getItem("jwt")
                  ),
                  filedownloadurl: window["baseurl"](
                    "/file/data/" +
                      data._id +
                      "?type=download&jwt=" +
                      localStorage.getItem("jwt")
                  ),
                },
                null
              )
            : UniFun.readingsPreviewFile(
                {
                  title: data.rawTitle,
                  mimetype: data.mimetype,
                  download: true,
                  fileurl: window["baseurl"](
                    "/agenda-file/data/" +
                      data._id +
                      "?type=preview&jwt=" +
                      localStorage.getItem("jwt")
                  ),
                  filedownloadurl: window["baseurl"](
                    "/agenda-file/data/" +
                      data._id +
                      "?type=download&jwt=" +
                      localStorage.getItem("jwt")
                  ),
                },
                null
              );
        }}
        title={data.rawTitle}
      >
        {linkTitle}
      </span>
    );
  }
};

const searchFeatures = function (control) {
  return (
    <div className="pb-2 ">
      <span className="d-block pl-2 text-grey">Search features</span>
      <ul className="list-group ">
        {/* {UniFun.userIsEligible({ forWhat: "module" }) ? ( */}
        <li className={"list-group-item border-0 p-0 "}>
          <span
            className="d-block search-result-link pt-2 pb-2 pl-2 "
            onClick={(e) => {
              control.setState({ defaultValue: "/Contacts " });

              setTimeout(function () {
                document
                  .getElementsByClassName("general-search")[0]
                  .dispatchEvent(new Event("input", { bubbles: true }));

                document.getElementsByClassName("general-search")[0].focus();
              }, 10);
            }}
          >
            <span>
              <img
                src="/uni/svg/search.svg"
                className=" mr-2"
                style={{
                  width: "18px",
                  paddingBottom: "2px",
                }}
              />
            </span>
            <span className="pb-1  text-black">Contacts</span>
          </span>
        </li>
        {/* ) : (
          ""
        )} */}
        <li className={"list-group-item border-0 p-0 "}>
          <span
            onClick={(e) => {
              control.setState({
                defaultValue: "/Events ",
              });
              setTimeout(function () {
                document
                  .getElementsByClassName("general-search")[0]
                  .dispatchEvent(new Event("input", { bubbles: true }));
                document.getElementsByClassName("general-search")[0].focus();
              }, 10);
            }}
            className="d-block search-result-link pt-2 pb-2 pl-2 "
          >
            <span>
              <img
                src="/uni/svg/search.svg"
                className=" mr-2"
                style={{
                  width: "18px",
                  paddingBottom: "2px",
                }}
              />
            </span>
            <span className="pb-1 text-black">Events</span>
          </span>
        </li>
        <li className={"list-group-item border-0 p-0 "}>
          <span
            onClick={(e) => {
              control.setState({
                defaultValue: "/Files ",
              });
              setTimeout(function () {
                document
                  .getElementsByClassName("general-search")[0]
                  .dispatchEvent(new Event("input", { bubbles: true }));
                document.getElementsByClassName("general-search")[0].focus();
              }, 10);
            }}
            className="d-block search-result-link pt-2 pb-2 pl-2 "
          >
            <span>
              <img
                src="/uni/svg/search.svg"
                className=" mr-2"
                style={{
                  width: "18px",
                  paddingBottom: "2px",
                }}
              />
            </span>
            <span className="pb-1 text-black">Files</span>
          </span>
        </li>
      </ul>
    </div>
  );
};

class TopMenu extends Component {
  state = {
    data: [],
    loaded: false,
    output: null,
  };

  constructor(props) {
    super(props);

    this.createMenu = this.createMenu.bind(this);
  }

  componentDidMount() {
    this.setState({ data: this.props.data, loaded: true });
  }

  onClick(itm) {
    if (itm["onclick"]) {
      itm["onclick"](itm);
    }
  }

  createMenu() {
    let data = this.state.data;

    let output = [];

    data.map((itm, idx) => {
      output.push(
        <li className="c-header-nav-item px-3" key={Fun.uuid()}>
          <Link
            className="c-header-nav-link text-white"
            to={itm["url"]["value"]}
            key={Fun.uuid()}
          >
            {Fun.parse(itm["title"]["value"])}
          </Link>
        </li>
      );
    });

    if (output.length > 0) {
      output = (
        <ul className="c-header-nav d-md-down-none " key={Fun.uuid()}>
          {output}
        </ul>
      );
    }

    return output;
  }

  createMobileMenu() {
    let data = this.state.data;

    let output = [];

    data.map((itm, idx) => {
      output.push(
        <Link
          className="dropdown-item pointer"
          to={itm["url"]["value"]}
          key={Fun.uuid()}
        >
          {Fun.parse(itm["title"]["value"])}
        </Link>
      );
    });

    return output;
  }

  render() {
    if (this.state.loaded) {
      let leftMenuMobile = [];
      if (window["index"].state.leftpanel) {
        if (window["index"].state.leftpanel.length > 0) {
          leftMenuMobile = (
            <button
              className="c-header-toggler c-class-toggler d-lg-none mfe-auto"
              type="button"
              data-target="#sidebar"
              data-class="c-sidebar-show"
              responsive="true"
            >
              <i className="fas fa-bars text-white font-size-19"></i>
            </button>
          );
        }
      }
      let placeholder = "Search contacts, events and files...";

      // UniFun.userIsEligible({ forWhat: "module" })
      //   ? "Search contacts, events and files..."
      //   : "Search events and files...";
      return (
        <header className="c-header c-header-light c-header-fixed c-header-with-subheader uniDark">
          {leftMenuMobile}
          {this.createMenu()}
          <div className="flex-grow-100">
            <GeneralSearch
              field={{
                attributes: {
                  className:
                    "w-75 height-100 ml-2 general-search bg-464545 pl-3",
                },
                alias: "generalSearch",
                placeholder: placeholder,
                events: {
                  onFocus: function () {
                    this.setState({
                      showResults: true,
                      results: this.state.results
                        ? this.state.results
                        : searchFeatures(this),
                    });
                  },
                  onBlur: function () {
                    //this.setState({ showResults: false });
                  },
                  onInput: function (e) {
                    let _this = this;

                    Fun.fetch({
                      url: {
                        url: "/searchResults/{query}",
                        formatter: function (args) {
                          let formattedData = {};

                          if (args) {
                            let data = args.data;
                            if (data) {
                              data.forEach((itm, idx) => {
                                let type = itm.type;
                                itm.rawTitle = itm.title;

                                if (type.indexOf("file") >= 0) {
                                  itm.title = Fun.parse(
                                    boldString(itm.title, e.target.value)
                                  );
                                  type = "file";
                                } else if (type == "user") {
                                  type = "user";
                                  itm.mimetype = "user-profile";
                                  itm.fullName = Fun.parse(
                                    boldString(itm.fullName, e.target.value)
                                  );
                                  itm.email = Fun.parse(
                                    boldString(itm.email, e.target.value)
                                  );
                                  itm.title = (
                                    <span className="text-black">
                                      {itm.fullName}
                                      <span className="ml-3 small text-grey">
                                        {itm.email}
                                      </span>
                                    </span>
                                  );
                                } else {
                                  itm.title = Fun.parse(
                                    boldString(itm.title, e.target.value)
                                  );
                                  itm.mimetype = "events";
                                  type = "events";
                                }

                                if (!formattedData[type]) {
                                  formattedData[type] = {
                                    type: type,
                                    groupLabel: "",
                                    results: [],
                                  };
                                }
                                let formattedRecord = {};
                                formattedRecord["_id"] = itm._id;
                                formattedRecord["type"] = type;
                                formattedRecord["rawTitle"] = itm.rawTitle;

                                formattedRecord["icon"] = "";
                                formattedRecord["mimetype"] = itm.mimetype;

                                switch (itm.type) {
                                  case "user":
                                    formattedRecord["title"] = "";
                                    formattedData[type]["groupLabel"] =
                                      "Contacts";
                                    formattedRecord["link"] = "";
                                    formattedRecord["icon"] =
                                      UniFun.createProfileImageBubble(
                                        [itm],
                                        "avatar-23",
                                        false,
                                        itm.title,
                                        "0px"
                                      );
                                    break;

                                  case "training-test":
                                    formattedRecord["title"] = (
                                      <>Training test:{itm.title}</>
                                    );
                                    formattedRecord["link"] =
                                      "/event/training/" + itm._id + "/results";
                                    formattedData[type]["groupLabel"] =
                                      "Events";
                                    formattedRecord["icon"] = (
                                      <i
                                        className="fas fa-square mr-2 font-size-23"
                                        style={{ color: "rgb(43, 53, 138)" }}
                                      ></i>
                                    );
                                    break;

                                  case "training":
                                    formattedRecord["title"] = (
                                      <>Training:{itm.title}</>
                                    );

                                    formattedData[type]["groupLabel"] =
                                      "Events";
                                    formattedRecord["link"] =
                                      "/event/training/" + itm._id;
                                    formattedRecord["icon"] = (
                                      <i
                                        className="fas fa-square mr-2 font-size-23"
                                        style={{ color: "rgb(43, 53, 138)" }}
                                      ></i>
                                    );
                                    break;

                                  case "meeting":
                                    formattedRecord["title"] = (
                                      <>Meeting: {itm.title}</>
                                    );

                                    formattedData[type]["groupLabel"] =
                                      "Events";
                                    formattedRecord["link"] =
                                      "/event/meeting/" + itm._id;
                                    formattedRecord["icon"] = (
                                      <i
                                        className="fas fa-square mr-2 font-size-23"
                                        style={{ color: "rgb(49, 18, 89)" }}
                                      ></i>
                                    );
                                    break;

                                  case "stream":
                                    formattedRecord["title"] = (
                                      <>Conference: {itm.title}</>
                                    );

                                    formattedData[type]["groupLabel"] =
                                      "Events";
                                    formattedRecord["link"] =
                                      "/conference/" + itm._id;
                                    formattedRecord["icon"] = (
                                      <i
                                        className="fas fa-square mr-2 font-size-23"
                                        style={{ color: "rgb(91, 46, 125)" }}
                                      ></i>
                                    );
                                    break;

                                  case "test":
                                    formattedRecord["title"] = (
                                      <>Test: {itm.title}</>
                                    );
                                    formattedData[type]["groupLabel"] =
                                      "Events";
                                    formattedRecord["link"] =
                                      "/event/test/" + itm._id;
                                    formattedRecord["icon"] = (
                                      <i
                                        className="fas fa-square mr-2 font-size-23"
                                        style={{ color: "rgb(18, 76, 89)" }}
                                      ></i>
                                    );
                                    break;

                                  case "training-file":
                                    formattedRecord["title"] = (
                                      <>
                                        {itm.title}
                                        <span className="ml-3 small text-grey">
                                          Training: {itm.trainingTitle}
                                        </span>
                                      </>
                                    );
                                    formattedData[type]["groupLabel"] = "Files";
                                    formattedRecord["icon"] = (
                                      <img
                                        src={UniFun.mimeTypeIcon(itm.mimetype)}
                                        className=" mr-2"
                                        style={{
                                          width: "23px",
                                          paddingBottom: "2px",
                                        }}
                                      />
                                    );
                                    break;

                                  case "meeting-file":
                                    formattedRecord["title"] = (
                                      <>
                                        {itm.title}
                                        <span className="ml-3 small text-grey">
                                          Meeting: {itm.meetingTitle}
                                        </span>
                                      </>
                                    );
                                    formattedData[type]["groupLabel"] = "Files";

                                    formattedRecord["icon"] = (
                                      <img
                                        src={UniFun.mimeTypeIcon(itm.mimetype)}
                                        className=" mr-2"
                                        style={{
                                          width: "23px",
                                          paddingBottom: "2px",
                                        }}
                                      />
                                    );
                                    break;

                                  case "file":
                                    formattedRecord["fileType"] = "common";
                                    formattedRecord["title"] = (
                                      <>
                                        <span>{itm.title}</span>
                                        {itm.keywordFound ? (
                                          <span
                                            className="small text-grey d-block"
                                            style={{ paddingLeft: "31px" }}
                                          >
                                            Keywords:{" "}
                                            {Fun.parse(
                                              boldString(
                                                itm.keywordFound,
                                                e.target.value
                                              )
                                            )}
                                          </span>
                                        ) : (
                                          ""
                                        )}
                                      </>
                                    );
                                    formattedData[type]["groupLabel"] = "Files";
                                    formattedRecord["icon"] = (
                                      <img
                                        src={UniFun.mimeTypeIcon(itm.mimetype)}
                                        className=" mr-2"
                                        style={{
                                          width: "23px",
                                          paddingBottom: "2px",
                                        }}
                                      />
                                    );
                                    break;

                                  default:
                                    formattedRecord["title"] = itm.title;
                                    // formattedData[type]["groupLabel"] = "Other";
                                    break;
                                }

                                formattedData[type]["results"].push(
                                  formattedRecord
                                );
                              });
                            }
                          }
                          return formattedData;
                        },
                      },
                      parameters: {
                        replace: {
                          query: function () {
                            return e.target.value.replace("/", "%2F");
                          },
                        },
                      },
                      callback: function (data) {
                        let output = [searchFeatures(_this)];
                        _this.setState({ results: output });

                        let totalResults = 0;

                        if (data) {
                          Object.keys(data)?.forEach((itm, idx) => {
                            let results = [];

                            data[itm].results?.forEach((ritm, ridx) => {
                              let hiddenClass = "";

                              if (ridx >= 5) {
                                hiddenClass = "d-none";
                              }

                              totalResults++;

                              results.push(
                                <li
                                  className={
                                    "list-group-item border-0 p-0 " +
                                    hiddenClass +
                                    " search-group-" +
                                    idx
                                  }
                                >
                                  {searchOpenResult(
                                    ritm,
                                    <>
                                      <span>{ritm.icon}</span>
                                      <span className="pb-1 text-black">
                                        {ritm.title}
                                      </span>
                                    </>
                                  )}
                                </li>
                              );
                            });

                            if (results.length > 5) {
                              results.push(
                                <li
                                  className={"list-group-item border-0 pl-2 "}
                                >
                                  <span
                                    className="text-primary text-strong pointer"
                                    style={{ paddingLeft: "30px" }}
                                    onClick={(e) => {
                                      let elements =
                                        document.getElementsByClassName(
                                          "search-group-" + idx + " d-none"
                                        );

                                      let i = 0;
                                      for (let item of elements) {
                                        if (i < 5) {
                                          item.classList.remove("d-none");
                                        }
                                        i++;
                                      }

                                      if (elements.length < 5) {
                                        e.target.parentElement.classList.add(
                                          "d-none"
                                        );
                                      }
                                    }}
                                  >
                                    Load more
                                  </span>
                                </li>
                              );
                            }

                            if (results.length > 0) {
                              let border = "";
                              if (idx == 0) {
                                border = "border-top";
                              }

                              output.push(
                                <div className={"mb-3 pt-2 " + border}>
                                  <span className="d-block pl-2 text-grey">
                                    {data[itm].groupLabel} (
                                    {data[itm].results.length})
                                  </span>
                                  <ul
                                    className={
                                      "list-group  search-group-" + idx
                                    }
                                  >
                                    {results}
                                  </ul>
                                </div>
                              );
                            }
                          });

                          if (totalResults == 0) {
                            if (
                              e.target.value
                                .replace("/Contacts", "")
                                .replace("/Events", "")
                                .replace("/Files", "")
                                .trim()
                            ) {
                              output.push(
                                <ul className="list-group   border-top ">
                                  <li
                                    className={"list-group-item border-0 p-0 "}
                                  >
                                    <span className="d-block pt-2 pb-2 pl-2 ">
                                      <span>
                                        <img
                                          src="/uni/svg/noresults.svg"
                                          className=" mr-2"
                                          style={{
                                            width: "22px",
                                            paddingBottom: "2px",
                                          }}
                                        />
                                      </span>
                                      <span className="pb-1">
                                        We couldn't find any results for{" "}
                                        {e.target.value
                                          .replace("/Contacts", "")
                                          .replace("/Events", "")
                                          .replace("/Files", "")}
                                        .
                                      </span>
                                    </span>
                                  </li>
                                </ul>
                              );
                            }
                          }
                          _this.setState({
                            results: output,
                          });
                        }
                      },
                    });
                  },
                },
              }}
            ></GeneralSearch>
          </div>
          <ul className="c-header-nav ml-auto mr-4">
            <li
              className="c-header-nav-item dropdown mr-2 d-lg-none"
              key={Fun.uuid()}
            >
              <a className="c-header-nav-link pointer" data-toggle="dropdown">
                <i className="fas fa-sitemap text-white font-size-19"></i>
              </a>
              <div className="dropdown-menu dropdown-menu-right">
                {this.createMobileMenu()}
              </div>
            </li>
            <li className="c-header-nav-item dropdown" key={Fun.uuid()}>
              <a className="c-header-nav-link" data-toggle="dropdown">
                {UniFun.myProfile()}
              </a>
              <div className="dropdown-menu dropdown-menu-right">
                <a
                  className="dropdown-item pointer"
                  onClick={UniFun.myProfileDetails}
                >
                  My profile
                </a>
                <a className="dropdown-item pointer" onClick={UniFun.logout}>
                  Logout
                </a>
              </div>
            </li>
          </ul>
        </header>
      );
    } else {
      return "loading...";
    }
  }
}

export default TopMenu;
