import React, { Component } from "react";
import { FileDrop } from "react-file-drop";
import Fun from "../../lib/functions";

class Dropzone extends Component {
  state = {
    name: "",
    id: "",
    placeholder: "",
    attributes: "",
    value: "",
    defaultValue: "",
    loaded: false,
    editable: true,
    component: null,
    attributes: "",
    events: {},
    customLayout: null,
    useCustomLayout: true,
    editable: true,
    content: [],
    pending: [],
  };

  constructor(props) {
    super(props);

    let events = {};

    this.onFrameDragEnter = function () {};
    this.onFrameDragLeave = function () {};
    this.onFrameDrop = function () {};
    this.onDragOver = function () {};
    this.onDragLeave = function () {};
    this.onDrop = function () {};
    this.uploadFiles = this.uploadFiles.bind(this);
    this.onFileInputChange = this.onFileInputChange.bind(this);
    this.onTargetClick = this.onTargetClick.bind(this);

    if (this.props.field.events) {
      Object.keys(this.props.field.events).map((itm, idx) => {
        this[itm] = this.props.field.events[itm];
        this[itm] = this[itm].bind(this);
        events[itm] = this[itm];
      });
    }

    this.editable = this.editable.bind(this);
    this.nonEditable = this.nonEditable.bind(this);

    this.placeholder = function () {
      return "Drop files here";
    };

    if (this.props.field.config) {
      if (this.props.field.config.placeholder) {
        if (typeof this.props.field.config.placeholder === "function") {
          this.placeholder = this.props.field.config.placeholder.bind(this);
        }
      }
    }

    let defaultValue = this.props.value ? this.props.value : [];

    if (this.props.field.config) {
      if (this.props.field.config.formatDefaultValue) {
        if (typeof this.props.field.config.formatDefaultValue === "function") {
          defaultValue = this.props.field.config.formatDefaultValue(
            this.props.value
          );
        }
      }
    }

    if (typeof defaultValue === "object") {
      defaultValue = JSON.stringify(defaultValue);
    }

    this.state = {
      events: events,
      editable:
        this.props.field.editable != null
          ? this.props.field.editable
          : this.state.editable,
      defaultValue: defaultValue,
    };
  }

  uploadFiles(args) {
    Fun.fetch({
      url: args.url,
      method: args.method,
      submittedData: args.submittedData,
      object: this,
      callback: args.callback ? args.callback : null,
      cancelToken: args.cancelToken ? args.cancelToken : null,
      onUploadProgress: args.onUploadProgress ? args.onUploadProgress : null,
    });
  }

  componentDidMount() {
    this.setState({
      attributes: this.props.field.attributes,
      name: this.props.field.alias,
      fileInputRef: React.createRef(),
      placeholder: this.placeholder,
      editable:
        this.props.field.editable != null
          ? this.props.field.editable
          : this.state.editable,
      customContentLayout: this.props.field.customContentLayout,
      customLayout: this.props.field.customLayout,
      useCustomLayout:
        typeof this.props.field.useCustomLayout !== "undefined"
          ? this.props.field.useCustomLayout
          : true,
    });
  }

  onFileInputChange = (event) => {
    const { files } = event.target;
    // do something with your files...

    this.onDrop(files, event);
  };

  onTargetClick = () => {
    this.state.fileInputRef.current.click();
  };

  editable() {
    if (this.state.customContentLayout) {
      this.state.customContentLayout =
        this.state.customContentLayout.bind(this);
    }

    let output = (
      <div>
        <FileDrop
          onFrameDragEnter={(event) => this.onFrameDragEnter(event)}
          onFrameDragLeave={(event) => this.onFrameDragLeave(event)}
          onFrameDrop={(event) => this.onFrameDrop(event)}
          onDragOver={(event) => this.onDragOver(event)}
          onDragLeave={(event) => this.onDragLeave(event)}
          onDrop={(files, event) => this.onDrop(files, event)}
          className={
            this.state.attributes ? this.state.attributes["className"] : ""
          }
        >
          {this.state.customContentLayout
            ? this.state.customContentLayout(this)
            : this.state.content
            ? this.state.content
            : this.state.placeholder}
        </FileDrop>
        <input
          onChange={this.onFileInputChange}
          ref={this.state.fileInputRef}
          onClick={(event) => {
            event.target.value = null;
          }}
          type="file"
          style={{ display: "none" }}
          multiple
        />
        <input
          type="hidden"
          defaultValue={this.state.defaultValue}
          name={this.state.name}
        ></input>
      </div>
    );
    if (this.state.customLayout && this.state.useCustomLayout == true) {
      this.state.customLayout = this.state.customLayout.bind(this);
      return this.state.customLayout({ control: output, object: this });
    }

    return output;
  }

  nonEditable() {
    let output = this.state.defaultValue;

    if (this.state.customLayout && this.state.useCustomLayout == true) {
      this.state.customLayout = this.state.customLayout.bind(this);
      return this.state.customLayout({ control: output, object: this });
    }

    return output;
  }

  render() {
    let output = this.state.editable ? this.editable() : this.nonEditable();

    return output;
  }
}

export default Dropzone;
