import React, { Component } from "react";
import moment from "moment";
import Fun from "../../../lib/functions";
import TopTemplateHeader from "../../../themes/default/templates/topTemplateHeader";
import UniFun from "../functions";
import { parse } from "uuid";

const UniTestEvent = {
  auth: "",
  views: {
    detail: {
      auth: "",
      apis: {
        view: {
          url: "/template/exam/{id}",
          parameters: {
            replace: {},
            headers: {},
          },
          formatter: function (args) {
            return UniTestEventFormatter(args);
          },
        },
        update: {
          url: "/event/exam",
          parameters: {
            headers: {
              "Content-Type": "application/json",
            },
            replace: {},
          },
          formatter: function (data) {
            return UniTestEventFormatter(data);
          },
        },
      },
      template: "form",
      templateConfig: {
        events: {
          onSubmit: function (e) {
            e.preventDefault();

            let submitData = Fun.getSubmitJSON(e);

            if (
              !UniFun.validateTest(
                this.props.builder.validator,
                Fun.getSubmitJSON(e)
              )
            ) {
              return;
            }
            let jsonData = UniFun.examJSONData(submitData);

            delete jsonData["_id"];

            let from = UniFun.fromToDateFormat(
              jsonData.fromToDateTime[0].fromDateTime[0].fromDate,
              jsonData.fromToDateTime[0].fromDateTime[0].fromTime
            );

            let to = UniFun.fromToDateFormat(
              jsonData.fromToDateTime[0].toDateTime[0].toDate,
              jsonData.fromToDateTime[0].toDateTime[0].toTime
            );
            jsonData["from"] = from;
            jsonData["to"] = to;
            jsonData["examDuration"] = jsonData["examDuration"][0];
            jsonData["reminders"] = [JSON.parse(jsonData["reminders"])];
            if (jsonData["participants"]) {
              jsonData["participants"] = UniFun.participantsJSONData(
                JSON.parse(jsonData["participants"])
              );
            }

            jsonData["evaluators"] = UniFun.participantsJSONData(
              JSON.parse(jsonData["evaluators"])
            );
            jsonData["questions"].map((itm, idx) => {
              delete itm["_id"];
            });

            delete jsonData["fromToDateTime"];

            Fun.update(e, {
              url: this.props.builder.state.module.apis.update,
              method: "POST",
              callback: function (
                submittedData,
                status,
                responsedata,
                responsestatus
              ) {
                if (responsestatus == "200") {
                  if (window["modal"].state.callback) {
                    window["modal"].state.callback(submittedData);
                  }
                } else {
                  if (responsedata.data.exceptions) {
                    responsedata.data.exceptions.map((itm, idx) => {
                      Fun.createNotification({
                        message: itm.errorDescription,
                        type: "danger",
                      });
                    });
                  }
                }
              },
              parameters: {
                headers: {
                  "content-type": "application/json",
                },
              },
              submittedData: JSON.stringify(jsonData),
            });
          },
        },
        colsTemplateCount: 12,
        includeProperties: [
          "_id",
          "fromToDateTime",
          "examDuration",
          "reminders",
          "participants",
          "evaluators",
          "title",
          "instructions",
          "passingGrade",
          "labelQuestions",
          "questions",
        ],
      },
      pk: "_id",
      properties: UniTestEventProperties({ editable: true }),
    },
    editEvent: {
      auth: "",
      apis: {
        view: {
          url: "/event/exam/{id}",
          parameters: {
            replace: {},
            headers: {},
          },
          formatter: function (args) {
            return UniTestEventFormatter(args);
          },
        },
        update: {
          url: "/event/exam",
          parameters: {
            headers: {
              "Content-Type": "application/json",
            },
            replace: {},
          },
          formatter: function (data) {
            return UniTestEventFormatter(data);
          },
        },
      },
      template: "form",
      templateConfig: {
        events: {
          onSubmit: function (e) {
            e.preventDefault();

            if (
              !UniFun.validateTest(
                this.props.builder.validator,
                Fun.getSubmitJSON(e)
              )
            ) {
              return;
            }

            let submitJSON = Fun.getSubmitJSON(e);
            let examId = submitJSON["_id"];
            let jsonData = UniFun.examJSONData(submitJSON);
            jsonData["_id"] = examId;

            let from = UniFun.fromToDateFormat(
              jsonData.fromToDateTime[0].fromDateTime[0].fromDate,
              jsonData.fromToDateTime[0].fromDateTime[0].fromTime
            );

            let to = UniFun.fromToDateFormat(
              jsonData.fromToDateTime[0].toDateTime[0].toDate,
              jsonData.fromToDateTime[0].toDateTime[0].toTime
            );

            jsonData["from"] = from;
            jsonData["to"] = to;
            jsonData["reminders"] = [JSON.parse(jsonData["reminders"])];
            // jsonData["participants"] = JSON.parse(jsonData["participants"]);
            if (jsonData["participants"]) {
              jsonData["participants"] = UniFun.participantsJSONData(
                JSON.parse(jsonData["participants"])
              );
            }

            jsonData["evaluators"] = UniFun.participantsJSONData(
              JSON.parse(jsonData["evaluators"])
            );

            jsonData["questions"].map((itm, idx) => {
              delete itm["_id"];
            });

            jsonData["examDuration"] = jsonData["examDuration"][0];
            delete jsonData["fromToDateTime"];

            Fun.update(e, {
              url: this.props.builder.state.module.apis.update,
              method: "PATCH",
              callback: function (
                submittedData,
                status,
                responsedata,
                responsestatus
              ) {
                if (responsestatus == "200") {
                  if (window["modal"].state.callback) {
                    window["modal"].state.callback(submittedData);
                  }
                } else {
                  if (responsedata.data.exceptions) {
                    responsedata.data.exceptions.map((itm, idx) => {
                      Fun.createNotification({
                        message: itm.errorDescription,
                        type: "danger",
                      });
                    });
                  }
                }
              },
              parameters: {
                headers: {
                  "content-type": "application/json",
                },
              },
              submittedData: JSON.stringify(jsonData),
            });
          },
        },
        colsTemplateCount: 12,
        includeProperties: [
          "_id",
          "fromToDateTime",
          "examDuration",
          "reminders",
          // "participants",
          "evaluators",
          "title",
          "instructions",
          "passingGrade",
          "labelQuestions",
          "questions",
        ],
      },
      pk: "_id",
      properties: UniTestEventProperties({ editable: true }),
    },
    editTrainingTest: {
      auth: "",
      apis: {
        view: {
          url: "/event/exam/{id}",
          parameters: {
            replace: {},
            headers: {},
          },
          formatter: function (args) {
            return UniTestEventFormatter(args);
          },
        },
        update: {
          url: "/event/exam",
          parameters: {
            headers: {
              "Content-Type": "application/json",
            },
            replace: {},
          },
          formatter: function (data) {
            return UniTestEventFormatter(data);
          },
        },
      },
      template: "form",
      templateConfig: {
        events: {
          onSubmit: function (e) {
            e.preventDefault();
            if (
              !UniFun.validateTest(
                this.props.builder.validator,
                Fun.getSubmitJSON(e)
              )
            ) {
              return;
            }
            let submitJSON = Fun.getSubmitJSON(e);
            if (window["modal2"].state.callback) {
              window["modal2"].state.callback(submitJSON);
              window["modal2"].setState({ show: false, overflowY: "auto" });
            }
            // let submitJSON = Fun.getSubmitJSON(e);
            // let examId = submitJSON["_id"];
            // let jsonData = UniFun.examJSONData(submitJSON);
            // jsonData["_id"] = examId;

            // let from = UniFun.fromToDateFormat(
            //   jsonData.fromToDateTime[0].fromDateTime[0].fromDate,
            //   jsonData.fromToDateTime[0].fromDateTime[0].fromTime
            // );

            // let to = UniFun.fromToDateFormat(
            //   jsonData.fromToDateTime[0].toDateTime[0].toDate,
            //   jsonData.fromToDateTime[0].toDateTime[0].toTime
            // );

            // jsonData["from"] = from;
            // jsonData["to"] = to;
            // jsonData["reminders"] = [JSON.parse(jsonData["reminders"])];
            // jsonData["participants"] = JSON.parse(
            //   jsonData["participantsHidden"]
            // );

            // delete jsonData["participantsHidden"];

            // jsonData["evaluators"] = UniFun.participantsJSONData(
            //   JSON.parse(jsonData["evaluators"])
            // );

            // jsonData["questions"].map((itm, idx) => {
            //   delete itm["_id"];
            // });

            // jsonData["examDuration"] = jsonData["examDuration"][0];
            // delete jsonData["fromToDateTime"];

            // Fun.update(e, {
            //   url: this.props.builder.state.module.apis.update,
            //   method: "PATCH",
            //   callback: function (
            //     submittedData,
            //     status,
            //     responsedata,
            //     responsestatus
            //   ) {
            //     if (responsestatus == "200") {
            //       console.log("asd");
            //       if (window["modal2"].state.callback) {
            //         window["modal2"].state.callback(submittedData);
            //         window["modal2"].setState({ show: false });
            //       }
            //     } else {
            //       if (responsedata.data.exceptions) {
            //         responsedata.data.exceptions.map((itm, idx) => {
            //           Fun.createNotification({
            //             message: itm.errorDescription,
            //             type: "danger",
            //           });
            //         });
            //       }
            //     }
            //   },
            //   parameters: {
            //     headers: {
            //       "content-type": "application/json",
            //     },
            //   },
            //   submittedData: JSON.stringify(jsonData),
            // });
          },
        },
        colsTemplateCount: 12,
        includeProperties: [
          "_id",
          "fromToDateTime",
          "examDuration",
          "reminders",
          "participantsHidden",
          "evaluators",
          "title",
          "instructions",
          "passingGrade",
          "labelQuestions",
          "questions",
        ],
      },
      pk: "_id",
      properties: UniTestEventProperties({ editable: true }),
    },
  },
};

function UniTestEventFormatter(data) {
  let formattedData = [];
  if (data) {
    if (data.data) {
      data.data.map((itm, idx) => {
        try {
          let formattedRecord = {};

          formattedRecord["_id"] = itm._id;
          formattedRecord["title"] = itm.title;
          formattedRecord["instructions"] = itm.instructions;
          formattedRecord["passingGrade"] = itm.passingGrade;
          formattedRecord["questions"] = itm.questions;

          formattedRecord["examDuration"] = null;
          if (itm.examDuration) {
            formattedRecord["examDuration"] = itm.examDuration[0]
              ? itm.examDuration[0]
              : itm.examDuration;
          }

          formattedRecord["modified"] = itm.updatedAt;
          formattedRecord["participants"] = UniFun.participantsFormatter(
            itm.participants
          );
          formattedRecord["participantsHidden"] = UniFun.participantsFormatter(
            itm.participants
          );

          formattedRecord["evaluators"] = UniFun.participantsFormatter(
            itm.evaluators
          );

          const start = moment();
          const halfhour = 30 - (start.minute() % 30);
          const dateFromTime = moment(start)
            .add(halfhour, "minutes")
            .format("HH:mm:00");
          const dateToTime = moment(start)
            .add(halfhour, "minutes")
            .add("3", "hours")
            .format("HH:mm:00");

          let fromTime = dateFromTime;
          if (itm.from) {
            fromTime = moment(itm.from).format("HH:mm:00");
          }

          let toTime = dateToTime;
          if (itm.to) {
            toTime = moment(itm.to).format("HH:mm:00");
          }

          if (!itm.from && !itm.to) {
            if (
              moment(start)
                .add(halfhour, "minutes")
                .isBefore(
                  moment(start).add(halfhour, "minutes").add("3", "hours")
                )
            ) {
              itm.to = moment(start)
                .add(halfhour, "minutes")
                .add("3", "hours")
                .add("1", "days");
            }
          }

          formattedRecord["reminders"] = itm.reminders
            ? JSON.stringify({
                type: itm.reminders[0].type,
                value: itm.reminders[0].value,
              })
            : {};

          formattedRecord["fromToDateTime"] = {
            fromDateTime: {
              fromDate: itm.from,
              fromTime: fromTime,
            },
            toDateTime: {
              toDate: itm.to,
              toTime: toTime,
            },
          };
          formattedData.push(formattedRecord);
        } catch (e) {
          console.log(e);
        }
      });
    }
  }
  return formattedData;
}

function UniTestEventProperties(input) {
  let editable = input.editable;
  return {
    _id: {
      alias: "_id",
      control: "hidden",
      editable: editable,
      attributes: {},
    },
    title: {
      alias: "title",
      control: "text",
      label: "Name",
      placeholder: "Type the name of the test",
      validations: "required",
      editable: editable,
      attributes: {
        className: "form-control",
      },
    },
    fromToDateTime: {
      alias: "fromToDateTime",
      control: "group",
      label: "Availability",
      editable: editable,
      customLayout: function (args) {
        return (
          <React.Fragment>
            <label>{args.object.props.field.label}</label>
            <div className="row mb-3">
              <div className="col-md-5 pr-0 availability-calendar">
                {args.control[0]}
              </div>
              <div className="col-md-1 text-center pr-0 pt-2 pl-0">to</div>
              <div className="col-md-5 pl-0 availability-calendar">
                {args.control[1]}
              </div>
              <div className="col-md-1"></div>
            </div>
          </React.Fragment>
        );
      },
      useCustomLayout: true,
      config: {
        properties: {
          fromDateTime: {
            alias: "fromDateTime",
            control: "group",
            editable: editable,
            customLayout: function (args) {
              return (
                <React.Fragment>
                  <div className="row mb-3">
                    <div className="col-md-8 pr-0">{args.control[0]}</div>
                    <div className="col-md-4 ">{args.control[1]}</div>
                  </div>
                </React.Fragment>
              );
            },
            useCustomLayout: true,
            config: {
              properties: {
                fromDate: {
                  alias: "fromDate",
                  control: "datetime",
                  config: {
                    fromFormat: "YYYY-MM-DDT00:00:00.000Z",
                    toFormat: "YYYY-MM-DDT00:00:00.000Z",
                    includeTime: false,
                    valueEditFormat: { dateStyle: "short" },
                    valueDisplayFormat: { dateStyle: "short" },
                  },
                  editable: editable,
                },
                fromTime: {
                  alias: "fromTime",
                  control: "select",
                  // placeholder: function () {
                  //   const start = moment();
                  //   const halfhour = 30 - (start.minute() % 30);

                  //   const dateTime = moment(start)
                  //     .add(halfhour, "minutes")
                  //     .format("hh:mm:ss");

                  //   console.log(dateTime);
                  //   return "12:30:00";
                  // },
                  config: {
                    options: UniFun.timeSelect,
                  },
                  editable: editable,
                  attributes: {
                    className: "form-control",
                  },
                },
              },
            },
          },
          toDateTime: {
            alias: "toDateTime",
            control: "group",
            editable: editable,
            customLayout: function (args) {
              return (
                <React.Fragment>
                  <div className="row mb-3">
                    <div className="col-md-8 pr-0">{args.control[0]}</div>
                    <div className="col-md-4 ">{args.control[1]}</div>
                  </div>
                </React.Fragment>
              );
            },
            useCustomLayout: true,
            config: {
              properties: {
                toDate: {
                  alias: "toDate",
                  control: "datetime",
                  config: {
                    fromFormat: "YYYY-MM-DDT00:00:00.000Z",
                    toFormat: "YYYY-MM-DDT00:00:00.000Z",
                    includeTime: false,
                    valueEditFormat: { dateStyle: "short" },
                    valueDisplayFormat: { dateStyle: "short" },
                  },
                  editable: editable,
                },
                toTime: {
                  alias: "toTime",
                  control: "select",
                  config: {
                    options: UniFun.timeSelect,
                  },
                  editable: editable,
                  attributes: {
                    className: "form-control",
                  },
                },
              },
            },
          },
        },
      },
    },
    examDuration: {
      alias: "examDuration",
      control: "group",
      label: "Duration",
      editable: editable,
      customLayout: function (args) {
        return (
          <React.Fragment>
            <label>{args.object.props.field.label}</label>
            <div className="row mb-3">
              <div className="col-md-3 pr-0">
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <label className="input-group-text">Hours</label>
                  </div>
                  {args.control[0]}
                </div>
              </div>
              <div className="col-md-3 ">
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <label className="input-group-text">Minutes</label>
                  </div>
                  {args.control[1]}
                </div>
              </div>
              <div className="col-md-6"></div>
            </div>
          </React.Fragment>
        );
      },
      useCustomLayout: true,
      config: {
        properties: {
          hours: {
            alias: "hours",
            control: "number",
            editable: editable,
            attributes: {
              className: "form-control",
            },
          },
          minutes: {
            alias: "minutes",
            control: "number",
            editable: editable,
            attributes: {
              className: "form-control",
            },
          },
        },
      },
    },

    // participants: {
    //   alias: "participants",
    //   control: "textarea",
    //   label: "Choose participants",
    //   editable: editable,
    //   attributes: {
    //     className: "form-control",
    //   },
    // },
    participantsHidden: {
      alias: "participantsHidden",
      label: "",
      placeholder: "",
      control: "hidden",
      editable: editable,
      attributes: {
        className: "form-control",
      },
    },

    participants: {
      alias: "participants",
      label: "Choose participants",
      placeholder: "Select the participants of the test",
      control: "tags",
      editable: editable,
      config: {
        idAttribute: "_id",
        nameAttribute: "name",
        apis: {
          search: {
            url: "/user/autocomplete/{query}?type=participants",
            formatter: function (args) {
              let formattedData = [];
              if (args) {
                let data = args.data;
                if (data) {
                  data.map((itm, idx) => {
                    if (itm["_id"]) {
                      let fullName =
                        itm["firstName"] +
                        " " +
                        itm["lastName"] +
                        " - " +
                        itm["email"];

                      if (itm.type == "group") {
                        fullName = itm["firstName"] + " - " + itm["lastName"];
                      }
                      formattedData.push({
                        _id: itm["_id"],
                        name: fullName,
                        group: itm.type,
                      });
                    } else {
                      if (UniFun.getURLPathValue(1) !== "conference") {
                        formattedData.push({ id: "", name: itm });
                      }
                    }
                  });
                }
              }
              return formattedData;
            },
            parameters: {
              replace: {
                query: "",
              },
            },
          },
        },
      },
      events: {
        onAddition: function (tag) {
          if (!tag._id) {
            // this.ref.current.input.current.input.current.blur();
            if (Fun.getURLPathValue(1) !== "conference") {
              if (UniFun.isEmail(tag.name)) {
                tag["_id"] = tag.name.trim();
                tag["name"] = tag.name.trim();
                const tags = [].concat(this.state.tags, tag);
                this.setState({ tags });
              } else {
                let splitParticipants = [];
                let notValidEmails = [];

                tag.name = tag.name.replaceAll(",", " ");
                tag.name = tag.name.replaceAll(";", " ");

                tag.name.split(" ").forEach((itm, idx) => {
                  if (itm) {
                    if (UniFun.isEmail(itm.trim())) {
                      splitParticipants.push({
                        id: itm.trim(),
                        name: itm.trim(),
                      });
                    } else {
                      notValidEmails.push(
                        <span className="d-block">{itm.trim()}</span>
                      );
                    }
                  }
                });

                if (notValidEmails.length > 0) {
                  Fun.createNotification({
                    message: (
                      <span>
                        The following inputs are not valid emails:<br></br>
                        {notValidEmails}
                      </span>
                    ),
                    type: "danger",
                  });
                }

                const tags = [].concat(this.state.tags, splitParticipants);
                this.setState({ tags });
              }
            }
          } else {
            const tags = [].concat(this.state.tags, tag);
            this.setState({ tags });
          }
        },
      },
      attributes: {
        className: "form-control",
      },
    },
    evaluators: {
      alias: "evaluators",
      label: "Choose evaluators",
      placeholder: "Select the evaluators of the test",
      control: "tags",
      editable: editable,
      config: {
        idAttribute: "_id",
        nameAttribute: "name",
        apis: {
          search: {
            url: "/user/autocomplete/{query}?type=instructors",
            formatter: function (args) {
              let formattedData = [];
              if (args) {
                let data = args.data;
                if (data) {
                  data.map((itm, idx) => {
                    let fullName =
                      itm["firstName"] +
                      " " +
                      itm["lastName"] +
                      " - " +
                      itm["email"];
                    if (itm.type == "group") {
                      fullName = itm["firstName"] + " - " + itm["lastName"];
                    }
                    formattedData.push({ _id: itm["_id"], name: fullName });
                  });
                }
              }
              return formattedData;
            },
            parameters: {
              replace: {
                query: "",
              },
            },
          },
        },
      },
      attributes: {
        className: "form-control",
      },
    },
    reminders: {
      alias: "reminders",
      control: "select",
      config: {
        options: [
          {
            label: "5 minutes before",
            value: JSON.stringify({ type: "minutes", value: 5 }),
          },
          {
            label: "10 minutes before",
            value: JSON.stringify({ type: "minutes", value: 10 }),
          },
          {
            label: "30 minutes before",
            value: JSON.stringify({ type: "minutes", value: 30 }),
          },
          {
            label: "1 hour before",
            value: JSON.stringify({ type: "minutes", value: 60 }),
          },
          {
            label: "1 day before",
            value: JSON.stringify({ type: "days", value: 1 }),
          },
        ],
      },
      label: "Reminder",
      editable: editable,
      attributes: {
        className: "form-control",
      },
    },
    passingGrade: {
      alias: "passingGrade",
      control: "text",
      label: "Passing Grade",
      placeholder: "0",
      editable: editable,
      attributes: {
        className: "form-control  text-center",
        style: { width: "80px", flex: "initial" },
      },
      customLayout: function (args) {
        let examType = "Practice Test";

        if (this.state.defaultValue) {
          if (parseFloat(this.state.defaultValue) > 0) {
            examType = "Graded Test";
          }
        }

        return (
          <div style={{ width: "400px" }}>
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <label className="input-group-text">Passing Grade (%)</label>
              </div>
              {args.control}
              <div class="input-group-append">
                <span class="input-group-text bg-white border-0 text-dark">
                  {examType}
                </span>
              </div>
            </div>
          </div>
        );
      },
      useCustomLayout: true,
      events: {
        onChange: function (event) {
          this.setState({ defaultValue: event.target.value });
        },
      },
    },
    instructions: {
      alias: "instructions",
      control: "editor",
      label: "Instructions",
      placeholder: "Write the instructions of the test",
      editable: editable,
      attributes: {
        className: "form-control",
      },
    },
    labelQuestions: {
      alias: "labelQuestions",
      control: "label",
      label: (
        <h4 className="text-black">
          <strong>Questions</strong>
        </h4>
      ),
      // tooltip:"- You can add as many multiple choice questions as you need.</br>-In each question you can choose one or more correct answers.</br>-If you want you can add a points to each question",
    },
    questions: {
      alias: "questions",
      control: "multiple",
      label: "Questions",
      validations: "required",
      customLayout: function (args) {
        let controls = args.control;

        let output = [];

        controls.map((itm, idx) => {
          output.push(
            <div className="pt-2">
              <strong>Question {idx + 1}: </strong>
              <span>
                <button
                  className=" pl-2 btn btn-link "
                  type="button"
                  onClick={(e) =>
                    UniFun.deleteTestQuestion({
                      title: "Question " + (idx + 1),
                      idx: idx,
                      object: this,
                    })
                  }
                >
                  <span className="text-danger">
                    <i className="fas fa-times"></i> Delete
                  </span>
                </button>
              </span>
              <div className="row pt-3">
                <div
                  className="col-1"
                  style={{ maxWidth: "32px", marginRight: "20px" }}
                >
                  <img
                    src="/uni/svg/test_fill.svg"
                    style={{ width: "33px" }}
                    className="filter-light-green"
                  ></img>
                </div>
                <div className="col-9  pr-0">
                  {itm[0].props.control.component}
                  {itm[1].props.control.component}
                  {itm[2].props.control.component}
                </div>
                <div className="col-2">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">Points</span>
                    </div>
                    {itm[3].props.control.component}
                  </div>
                </div>
              </div>
              {itm[1].props.control.component.props.value == "essayType" ? (
                <React.Fragment>
                  <div className="row pt-3">
                    <div
                      className="col-1"
                      style={{ maxWidth: "32px", marginLeft: "20px" }}
                    ></div>
                    <div className="col-9">
                      {itm[5].props.control.component.props.value &&
                      itm[5].props.control.component.props.value != "null" ? (
                        <img
                          src={
                            window["baseurl"](
                              "/agenda-file/data/" +
                                itm[5].props.control.component.props.value
                            ) +
                            "?type=preview&jwt=" +
                            localStorage.getItem("jwt")
                          }
                          className="mb-2 mt-2"
                          style={{ height: "120px" }}
                        ></img>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="col-2"></div>
                    <div
                      className="col-1"
                      style={{ maxWidth: "32px", marginLeft: "20px" }}
                    ></div>
                    <div className="col-9">
                      <div className="form-group">
                        <span>
                          <label className="text-dark">
                            {itm[5].props.control.component.props.field.label}
                          </label>
                        </span>
                        {itm[5].props.control.component}
                      </div>
                    </div>
                    <div className="col-2"></div>
                  </div>
                </React.Fragment>
              ) : (
                itm[4].props.control.component
              )}
            </div>
          );
        });

        let _this = this;

        return (
          <React.Fragment>
            {output}
            {/* <button
              type="button"
              className="btn btn-link"
              onClick={(e) => this.addNew()}
            >
              +Add new question
            </button> */}
            <div className="dropdown">
              <button
                className="btn btn-link dropdown-toggle"
                type="button"
                data-toggle="dropdown"
              >
                + Add new question
              </button>
              <div className="dropdown-menu">
                <button
                  type="button"
                  className="dropdown-item"
                  onClick={(e) => {
                    _this.setState({
                      newDataObject: { type: "multipleChoice" },
                    });
                    setTimeout(_this.addNew, 50);
                  }}
                >
                  Add multiple choice
                </button>
                <button
                  type="button"
                  className="dropdown-item"
                  href="#"
                  onClick={(e) => {
                    _this.setState({ newDataObject: { type: "essayType" } });
                    setTimeout(_this.addNew, 50);
                  }}
                >
                  Add essay type
                </button>
              </div>
            </div>
          </React.Fragment>
        );
      },
      useCustomLayout: true,
      config: {
        addNewLabel: "+ Add mulitple choice",
        properties: {
          _id: {
            alias: "_id",
            control: "hidden",
            label: "",
            editable: editable,
          },
          type: {
            alias: "type",
            control: "hidden",
            label: "",
            defaultValue: "multipleChoice",
            editable: editable,
          },
          question: {
            alias: "question",
            control: "editor",
            label: "Question",
            placeholder: "Type a question",
            editable: editable,
            attributes: {
              className: "form-control",
            },
          },
          weight: {
            alias: "weight",
            control: "text",
            label: "Points",
            placeholder: "",
            editable: editable,

            attributes: {
              className: "form-control",
            },
          },
          answers: {
            alias: "answers",
            control: "multiple",
            editable: editable,
            customLayout: function (args) {
              let output = [];

              if (args.control.length <= 1) {
                this.addNew();
                this.addNew();
              }

              args.control.map((itm, idx) => {
                let addNewBtn = "";

                if (idx == args.control.length - 1) {
                  addNewBtn = (
                    <button
                      type="button"
                      className="btn btn-link text-dark"
                      onClick={this.addNew}
                    >
                      <i className="fas fa-plus"></i>
                    </button>
                  );
                }

                output.push(
                  <div className="row pt-3">
                    <div
                      className="col-1"
                      style={{ maxWidth: "32px", marginLeft: "20px" }}
                    >
                      {itm[0].props.control.component}
                      {itm[1].props.control.component}
                    </div>
                    <div className="col-9 pr-0">
                      {itm[2].props.control.component}
                    </div>
                    <div className="col-2">
                      <button
                        type="button"
                        className="btn btn-link text-secondary"
                        onClick={(e) => this.deleteControl(idx)}
                      >
                        <i className="fas fa-times"></i>
                      </button>
                      {addNewBtn}
                    </div>
                  </div>
                );
              });

              return (
                <React.Fragment>
                  {output}
                  <div className="pt-5"></div>
                </React.Fragment>
              );
            },
            useCustomLayout: true,
            config: {
              addNewLabel: "+ Add answer",
              properties: {
                _id: {
                  alias: "_id",
                  control: "hidden",
                  label: "",
                  editable: editable,
                },
                isCorrect: {
                  alias: "isCorrect",
                  control: "checkbox",
                  config: {
                    useTrueFalseValue: true,
                  },
                  editable: editable,
                  attributes: {
                    className: "form-check-input form-control mt-0",
                  },
                },
                answer: {
                  alias: "answer",
                  control: "text",
                  placeholder:
                    "Type answer and check the box if it's the correct one",
                  editable: editable,
                  attributes: {
                    className: "form-control",
                  },
                },
              },
            },
          },
          image: {
            alias: "image",
            control: "dropzone",
            label: "Do you want to include an image with your question?",
            editable: editable,
            customContentLayout: function () {
              let files = [];

              try {
                files = JSON.parse(this.state.defaultValue);
              } catch (e) {}

              if (!files) {
                files = [];
              }

              let content = [];

              Object.keys(files).map((itm, idx) => {
                content.push(
                  UniFun.trainingNodeFileUI(files[itm], this.removeFile, itm)
                );
              });

              this.state.content?.map((itm, idx) => {
                if (typeof files[itm.key] === "undefined") {
                  content.push(itm);
                }
                // if (this.state.pending.indexOf(itm.key) >= 0) {
                //   content.push(itm);
                // }
              });
              return (
                <React.Fragment>
                  {/* {this.state.placeholder()} */}
                  {content.length > 0 ? "" : this.state.placeholder()}
                  <div className="p-3 pb-1">{content}</div>
                  {/* {content.length > 0 ? "" : this.state.placeholder()} */}
                </React.Fragment>
              );
            },

            useCustomLayout: true,
            config: {
              formatDefaultValue: function (value) {
                if (value == "null") {
                  return null;
                }
                if (value) {
                  return {
                    [value]: {
                      _id: value,
                      mimetype: "image/jpeg",
                      title: "Question Image",
                    },
                  };
                }
                return value;
              },
              placeholder: function () {
                return (
                  <div className="p-3 pb-1">
                    <strong>
                      Drag & drop here or{" "}
                      <a
                        href="javascript:void(0);"
                        className="text-primary"
                        onClick={this.onTargetClick}
                      >
                        <strong>browse</strong>
                      </a>{" "}
                      file to attach
                    </strong>
                    <br></br>
                    <span className="text-dark">
                      Maximum size: 50 MB - File type supported: png,jpeg,jpg
                    </span>
                  </div>
                );
              },
            },
            events: {
              removeFile: function (id) {
                let defaultValue = {};

                Object.keys(JSON.parse(this.state.defaultValue)).map(
                  (itm, idx) => {
                    if (itm != id) {
                      defaultValue[itm] = JSON.parse(this.state.defaultValue)[
                        itm
                      ];
                    }
                  }
                );

                let content = [];
                this.state.content?.map((itm, idx) => {
                  if (itm.key != id) {
                    content.push(itm);
                  }
                });

                this.setState({
                  defaultValue: JSON.stringify(defaultValue),
                  content: content,
                });

                return;
              },
              onDrop: function (files) {
                this.setState({
                  defaultValue: JSON.stringify({}),
                  content: [],
                });

                if (files.length > 1) {
                  Fun.createNotification({
                    message:
                      "Only one file is allowed to upload. Only png,jpeg,jpg files are allowed to upload",
                    type: "danger",
                  });
                  return;
                }

                if (
                  files[0].type == "image/jpeg" ||
                  files[0].type == "image/jpg" ||
                  files[0].type == "image/png"
                ) {
                  UniFun.fileProgress({
                    control: this,
                    files: files,
                    url: "/agenda-file",
                    type: "meeting",
                  });
                } else {
                  Fun.createNotification({
                    message: "Only png,jpeg,jpg files are allowed to upload",
                    type: "danger",
                  });
                }
              },
            },
            attributes: {
              className: "border rounded  dropzone-trainings",
            },
          },
        },
      },
    },
    createdBy: {
      alias: "createdBy",
      control: "text",
      label: "Created by",
      editable: editable,
      attributes: {
        className: "form-control",
      },
    },
    modified: {
      alias: "modified",
      control: "datetime",
      label: "Last modified",
      editable: editable,
      attributes: {
        className: "form-control",
      },
    },
  };
}

export default UniTestEvent;
