import React, { Component } from "react";
import Control from "./control";
import SimpleReactValidator from "simple-react-validator";
import Fun from "../../../lib/functions";
import UniFun from "../../../modules/uni/functions";
import moment from "moment";
import { Link } from "react-router-dom";

class TrainingOverview extends Component {
  state = {
    data: this.props.data,
    loaded: false,
    colsTemplateCount: 12,
    colsFormCount: 12,
    cardBodyCustomClass: "",
  };

  constructor(props) {
    super(props);
    let builder = this.props.builder.state;
    let templateConfig = builder.module.templateConfig;
    if (templateConfig.events) {
      Object.keys(templateConfig.events).map((itm, idx) => {
        this[itm] = templateConfig.events[itm].bind(this);
      });
    }

    // this.validator = new SimpleReactValidator({
    //   autoForceUpdate: this,
    //   locale: "en",
    //   validators: {
    //     phone: {
    //       message: "Phone number is incorrect",
    //       rule: (val, params, validator) => {
    //         return (
    //           validator.helpers.testRegex(val, /^(2|6)[0-9\ \(\)\+]{9}$/i) &&
    //           params.indexOf(val) === -1
    //         );
    //       },
    //       messageReplace: (message, params) =>
    //         message.replace(":values", this.helpers.toSentence(params)),
    //     },
    //   },
    // });

    this.form = React.createRef();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.action) {
      return false;
    }
    return true;
  }

  componentDidMount() {
    let builder = this.props.builder.state;
    let templateConfig = builder.module.templateConfig;
    let useCard =
      typeof templateConfig.useCard !== "undefined"
        ? templateConfig.useCard
        : true;

    let templateTop = templateConfig.templateTop
      ? templateConfig.templateTop
      : () => {};
    this["templateTop"] = templateTop.bind(this);
    let templateBottom = templateConfig.templateBottom
      ? templateConfig.templateBottom
      : () => {};
    this["templateBottom"] = templateBottom.bind(this);
    let templateLeft = templateConfig.templateLeft
      ? templateConfig.templateLeft
      : () => {};
    this["templateLeft"] = templateLeft.bind(this);
    let templateRight = templateConfig.templateRight
      ? templateConfig.templateRight
      : () => {};
    this["templateRight"] = templateRight.bind(this);
    let cardTemplateTop = templateConfig.cardTemplateTop
      ? templateConfig.cardTemplateTop
      : () => {};
    this["cardTemplateTop"] = cardTemplateTop.bind(this);
    let cardTemplateBottom = templateConfig.cardTemplateBottom
      ? templateConfig.cardTemplateBottom
      : () => {};
    this["cardTemplateBottom"] = cardTemplateBottom.bind(this);
    let panelTop = templateConfig.panelTop ? templateConfig.panelTop : () => {};
    this["panelTop"] = panelTop.bind(this);
    let panelBottom = templateConfig.panelBottom
      ? templateConfig.panelBottom
      : () => {};
    this["panelBottom"] = panelBottom.bind(this);
    let panelLeft = templateConfig.panelLeft
      ? templateConfig.panelLeft
      : () => {};
    this["panelLeft"] = panelLeft.bind(this);
    let panelRight = templateConfig.panelRight
      ? templateConfig.panelRight
      : () => {};
    this["panelRight"] = panelRight.bind(this);
    let header = templateConfig.header ? templateConfig.header : () => {};
    this["header"] = header.bind(this);

    let colsTemplateCount = templateConfig.colsTemplateCount
      ? templateConfig.colsTemplateCount
      : this.state.colsTemplateCount;
    let colsFormCount = templateConfig.colsFormCount
      ? templateConfig.colsFormCount
      : this.state.colsFormCount;

    let cardBodyCustomClass = templateConfig.cardBodyCustomClass
      ? templateConfig.cardBodyCustomClass
      : this.state.cardBodyCustomClass;

    this.setState({
      loaded: true,
      rawData: this.props.rawData,
      colsTemplateCount: colsTemplateCount,
      colsFormCount: colsFormCount,
      cardBodyCustomClass: cardBodyCustomClass,
      useCard: useCard,
    });

    this.validator = this.props.builder.validator;
    if (this.componentDidMountEvent) {
      this.componentDidMountEvent();
    }
  }

  progress(module) {
    if (
      !UniFun.userIsEligible({ forWhat: "module" }) &&
      !UniFun.userIsEligible({ forWhat: "isInstructor" }) &&
      !UniFun.userIsEligible({ forWhat: "viewer" })
    ) {
      let full = module.full;
      let progress = module.progress;

      if (progress == 0) {
        return <span className="text-purple">Not Started (0/{full})</span>;
      }

      if (progress < full) {
        return (
          <span className="text-primary">
            In progress ({progress}/{full})
          </span>
        );
      }

      if (progress == full) {
        return (
          <span className="text-success">
            Completed ({progress}/{full})
          </span>
        );
      }
    }

    return "";
  }

  nextEventCard(nextEvent) {
    let isMember = true;

    if (!UniFun.userIsEligible({ forWhat: "member" })) {
      isMember = false;
    }
    if (UniFun.userIsEligible({ forWhat: "isInstructor" })) {
      isMember = false;
    }
    if (UniFun.userIsEligible({ forWhat: "viewer" })) {
      isMember = false;
    }

    switch (nextEvent.type) {
      case "videoConference":
        return (
          <div
            className="card pointer card-hover action-btn"
            data-event-start={moment(nextEvent.from).valueOf()}
            data-event-end={moment(nextEvent.to).valueOf()}
            data-event-url={
              UniFun.userIsEligible({ forWhat: "member" })
                ? "/meeting/v-" + nextEvent._id
                : ""
            }
          >
            <div className="card-body p-4">
              <strong>
                <img
                  src="/uni/svg/joinvideocall.svg"
                  style={{ width: "20px" }}
                  className="mr-2"
                />
                Video conference
              </strong>
              <div className="mt-2 text-dark">{nextEvent.title}</div>
              <div className="mt-3 text-dark row">
                <div className="col-8 text-grey">
                  {moment(nextEvent.from).format("ddd DD MMM YYYY, HH:mm")}
                </div>
                <div className="col-4 text-right">
                  <img
                    src="/uni/svg/trainings/event_locked.svg"
                    style={{ width: "20px" }}
                    className="mr-2 action-btn event-icon"
                    data-tip="Event locked"
                    data-event-start={moment(nextEvent.from).valueOf()}
                    data-event-end={moment(nextEvent.to).valueOf()}
                  />
                </div>
              </div>
            </div>
          </div>
        );
        break;

      case "exam":
        if (UniFun.userIsEligible({ forWhat: "isInstructor" })) {
          return "";
        }

        return (
          <div
            className="card pointer card-hover action-btn"
            data-event-start={moment(nextEvent.from).valueOf()}
            data-event-end={moment(nextEvent.to).valueOf()}
            data-event-dif="0"
            onClick={(e) => {
              if (!UniFun.userIsEligible({ forWhat: "module" })) {
                if (!nextEvent.done) {
                  if (
                    moment(nextEvent.from) <= moment() &&
                    moment(nextEvent.to) >= moment()
                  ) {
                    UniFun.startExam(nextEvent);
                  }
                }
              }
            }}
          >
            <div className="card-body p-4">
              <strong>
                <img
                  src="/uni/svg/test.svg"
                  style={{ width: "20px" }}
                  className="mr-2"
                />
                {nextEvent.passingGrade && nextEvent.passingGrade > 0
                  ? "Graded test"
                  : "Practice test"}
              </strong>
              <div className="mt-2 text-dark">
                <span
                  data-tip={
                    nextEvent.title.length >= 140 ? nextEvent.title : ""
                  }
                >
                  {UniFun.substring(nextEvent.title, 0, 140)}
                </span>
              </div>

              <div className="mt-3  text-dark row">
                <div className="col-8  text-grey">
                  {moment(nextEvent.from).format("ddd DD MMM YYYY, HH:mm")}
                </div>{" "}
                <div className="col-4 text-right">
                  {nextEvent.passingGrade && nextEvent.passingGrade > 0 ? (
                    <React.Fragment>
                      <img
                        src="/uni/svg/trainings/grade.svg"
                        style={{ width: "20px" }}
                        className="mr-2 mb-2"
                        data-tip={
                          nextEvent.passingGrade + "% or higher to pass"
                        }
                      />
                    </React.Fragment>
                  ) : (
                    ""
                  )}
                  {nextEvent.done && isMember ? (
                    <img
                      src="/uni/svg/trainings/event_locked.svg"
                      style={{ width: "20px" }}
                      className="mr-2 action-btn  mb-2"
                      data-tip="Event locked"
                    />
                  ) : (
                    <img
                      src="/uni/svg/trainings/event_locked.svg"
                      style={{ width: "20px" }}
                      className="mr-2 action-btn event-icon mb-2"
                      data-tip="Event locked"
                      data-event-start={moment(nextEvent.from).valueOf()}
                      data-event-end={moment(nextEvent.to).valueOf()}
                      data-event-dif="0"
                    />
                  )}
                  {/* <img
                    src="/uni/svg/trainings/event_locked.svg"
                    style={{ width: "20px" }}
                    className="mr-2 action-btn event-icon"
                    data-tip="Event locked"
                    data-event-start={moment(nextEvent.from).valueOf()}
                    data-event-end={moment(nextEvent.to).valueOf()}
                    data-event-dif="0"
                  /> */}
                </div>
              </div>
            </div>
          </div>
        );
        break;
    }
  }

  output() {
    let out = [];

    let trainingModules = [];
    let nextEvent = null;

    this.state.data.forEach((itm, idx) => {
      itm.modules.value.forEach((module, midx) => {
        module.sessions.forEach((session, sidx) => {
          Object.keys(session).forEach((material, matidx) => {
            if (Array.isArray(session[material])) {
              session[material].forEach((matObj, matIdx) => {
                matObj.type = material;
                if (itm.nextEvent) {
                  if (itm.nextEvent.value) {
                    if (itm.nextEvent.value._id == matObj._id) {
                      nextEvent = { ...matObj };
                    }
                  }
                }

                // if (matObj.from) {
                //   if (moment(matObj.to) < moment()) {
                //     if (!nextEvent) {
                //       nextEvent = matObj;
                //     }

                //     if (moment(matObj.from) <= moment(nextEvent.from)) {
                //       nextEvent = { ...matObj };
                //     }
                //   }
                // }
              });
            }
          });
        });

        trainingModules.push(
          <div className="col-sm-3">
            <Link
              to={"/event/training/" + itm._id.value + "/module/" + module._id}
            >
              <div className="card pointer card-hover">
                <div className="card-body p-4">
                  <strong>Module {midx + 1}:</strong>{" "}
                  <span
                    data-tip={module.title.length >= 140 ? module.title : ""}
                  >
                    {UniFun.substring(module.title, 0, 140)}
                  </span>
                  <div className="mt-3">{this.progress(module)}</div>
                </div>
              </div>
            </Link>
          </div>
        );
      });
    });

    return (
      <React.Fragment>
        {nextEvent ? (
          this.nextEventCard(nextEvent) ? (
            <React.Fragment>
              <div className="row">
                <div className="col mb-3">
                  <strong>Next event (quick access)</strong>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-3 mb-3">
                  {this.nextEventCard(nextEvent)}
                </div>
              </div>
            </React.Fragment>
          ) : (
            ""
          )
        ) : (
          ""
        )}
        <div className="row">
          <div className="col mb-3">
            <strong>Training Modules ({trainingModules.length})</strong>
          </div>
        </div>
        <div className="row">{trainingModules}</div>
      </React.Fragment>
    );
  }

  render() {
    if (this.state.loaded) {
      return (
        <React.Fragment>
          {this.header()}
          {this.panelTop()}
          <div className="container-fluid">{this.output()}</div>
        </React.Fragment>
      );
    } else {
      return "loading...";
    }
  }
}

export default TrainingOverview;
