import React, { Component } from "react";

class Label extends Component {
  state = {
    name: "",
    id: "",
    placeholder: "",
    attributes: "",
    value: "",
    defaultValue: "",
    loaded: false,
    editable: true,
    component: null,
    attributes: "",
    events: {},
    customLayout: null,
    useCustomLayout: true,
    editable: true,
  };

  constructor(props) {
    super(props);
  }

  validator() {}

  componentDidMount() {}

  render() {
    return "";
  }
}

export default Label;
