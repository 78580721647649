import React, { Component } from "react";
import Fun from "../../lib/functions";
import Builder from "../../lib/builder";
import UniFun from "./functions";

const duplicateTemplate = function (templateId, parentBuilder) {
  let builder = parentBuilder;
  let parameters = {
    replace: {
      id: templateId,
    },
  };
  window["modal2"].setState({
    title: <h3>Duplicate Template?</h3>,
    size: "lg",
    targetComponent: this,
    callback: function () {
      window["modal2"].setState({ show: false, overflowY: "auto" });
      Fun.createNotification({
        type: "success",
        message: "Copy of template was successful!",
      });

      builder.rerunComponent();
    },
    footer: UniFun.modalCommonFooter("modal2", "Copy"),
    show: true,
    body: (
      <Builder
        module="unitrainingtemplates"
        view="duplicateTemplate"
        parameters={parameters}
        key={Fun.uuid()}
        ref={(refComponent) => {
          window["modal2body"] = refComponent;
        }}
      ></Builder>
    ),
  });
};

const deleteTemplate = function (templateId, parentBuilder) {
  let builder = parentBuilder;
  let parameters = {
    replace: {
      id: templateId,
    },
  };
  window["modal2"].setState({
    title: <h3>Delete Template?</h3>,
    size: "lg",
    targetComponent: this,
    callback: function () {
      window["modal2"].setState({ show: false, overflowY: "auto" });
      Fun.createNotification({
        type: "success",
        message: "delete was successful!",
      });

      builder.rerunComponent();
    },
    footer: UniFun.modalCommonFooter("modal2", "Delete", "btn-danger"),
    show: true,
    body: (
      <Builder
        module="unitrainingtemplates"
        view="deleteTemplate"
        parameters={parameters}
        key={Fun.uuid()}
        ref={(refComponent) => {
          window["modal2body"] = refComponent;
        }}
      ></Builder>
    ),
  });
};

const newTemplate = function (parentBuilder) {
  let builder = parentBuilder;

  window["modal"].setState({
    title: <h3>New training template</h3>,
    size: "xl",
    targetComponent: this,
    callback: function () {
      window["modal"].setState({ show: false, overflowY: "auto" });
      builder.rerunComponent();
    },
    footer: UniFun.modalCommonFooter("modal", "Create"),
    show: true,
    body: (
      <Builder
        module="unitrainingtemplates"
        view="add"
        key={Fun.uuid()}
        ref={(refComponent) => {
          window["modalbody"] = refComponent;
        }}
      ></Builder>
    ),
  });
};

const editTrainingTemplate = function (id, parentBuilder) {
  let builder = parentBuilder;
  let parameters = {
    replace: {
      id: id,
    },
  };

  window["modal"].setState({
    title: <h3>Edit training template</h3>,
    size: "xl",
    targetComponent: this,
    callback: function () {
      window["modal"].setState({ show: false, overflowY: "auto" });
      Fun.createNotification({
        type: "success",
        message: "Update update was successful!",
      });
      builder.rerunComponent();
    },
    footer: UniFun.modalCommonFooter("modal", "Update"),
    show: true,
    body: (
      <Builder
        module="unitrainingtemplates"
        view="detail"
        parameters={parameters}
        key={Fun.uuid()}
        ref={(refComponent) => {
          window["modalbody"] = refComponent;
        }}
      ></Builder>
    ),
  });
};

const UniTrainingTemplates = {
  auth: "",
  views: {
    list: {
      auth: "",
      apis: {
        view: {
          url: "/template/training?start={start}&offset={offset}",
          parameters: {
            headers: {},
            replace: {
              start: 0,
              offset: 100,
            },
          },
          formatter: function (data) {
            return UniTrainingTemplatesFormatter(data);
          },
        },
      },
      template: "grid",
      templateConfig: {
        headerTitle: "Training Templates",
        headerTitleIcon: "/uni/svg/trainings/go_back.svg",
        headerIconUrl: "/events/overview",
        colsTemplateCount: 12,
        includeProperties: ["title", "createdBy", "modified"],
        excludeProperties: [],
        gridConfig: {
          emptyFolder: function () {
            return (
              <div className="row mt-4 " style={{ height: "500px" }}>
                <div className="col-12 text-center">
                  <h5>
                    <strong>There are not any templates created</strong>
                  </h5>

                  <p>
                    {UniFun.userIsEligible({ forWhat: "default" }) ? (
                      <a
                        href="javascript:void(0);"
                        className="text-primary"
                        onClick={(e) => newTemplate(this.props.builder)}
                      >
                        New Template
                      </a>
                    ) : (
                      ""
                    )}
                  </p>
                </div>
              </div>
            );
          },
          headers: {
            title: {
              attributes: {
                className: "w-50",
              },
            },
          },
          cellTemplates: {
            title: function (data, rec, obj) {
              let uid = Fun.uuid();

              let builder = obj.props.builder;

              return (
                <React.Fragment>
                  {data}
                  <span className="float-right mr-4 grid-controls">
                    <i
                      className="far fa-edit mr-4 pointer"
                      onClick={(e) =>
                        editTrainingTemplate(rec._id.value, builder)
                      }
                    ></i>
                    <i
                      className="fas fa-ellipsis-h pointer"
                      data-toggle="collapse"
                      data-target={"#" + uid}
                    ></i>
                    <div
                      className="position-absolute bg-white ml-4 mt-2 collapse"
                      id={uid}
                    >
                      <ul className="list-group " style={{ width: "200px" }}>
                        <li
                          className="list-group-item p-2 pl-3"
                          onClick={(e) =>
                            editTrainingTemplate(rec._id.value, builder)
                          }
                        >
                          Edit
                        </li>
                        <li
                          className="list-group-item p-2 pl-3"
                          onClick={(e) =>
                            duplicateTemplate(rec._id.value, builder)
                          }
                        >
                          Duplicate
                        </li>

                        <li
                          className="list-group-item p-2  pl-3 text-danger"
                          onClick={(e) =>
                            deleteTemplate(rec._id.value, builder)
                          }
                        >
                          Delete
                        </li>
                      </ul>
                    </div>
                  </span>
                </React.Fragment>
              );
            },
          },
        },
        cardTemplateTop: function () {
          let data = this.state.data;
          return (
            <div className="row">
              <div className="col-6 pt-2 pl-4">
                <span className="text-dark ">
                  There are {data.length} training templates
                </span>
              </div>
              <div className="col-6 text-right p-2 pr-4">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={(e) => newTemplate(this.props.builder)}
                >
                  New Template
                </button>
              </div>
            </div>
          );
        },
        header: UniFun.commonHeader, //trainingTemplatesHeader,
      },
      pk: "_id",
      properties: UniTrainingTemplatesProperties({ editable: false }),
    },
    detail: {
      auth: "",
      apis: {
        view: {
          url: "/template/training/{id}",
          parameters: {
            headers: {},
            replace: {},
          },
          formatter: function (data) {
            return UniTrainingTemplatesFormatter(data);
          },
        },
        update: {
          url: "/template/training",
          parameters: {
            headers: {
              "Content-Type": "application/json",
            },
            replace: {},
          },
          formatter: function (data) {
            return UniTrainingTemplatesFormatter(data);
          },
        },
      },
      template: "form",
      templateConfig: {
        events: {
          onSubmit: function (e) {
            e.preventDefault();
            let jsonData = UniFun.trainingJSONData(Fun.getSubmitJSON(e));

            let parameters = {
              replace: {
                id: jsonData["_id"],
              },
            };

            Fun.update(e, {
              url: this.props.builder.state.module.apis.update,
              method: "PATCH",
              parameters: parameters,
              callback: function (
                submittedData,
                status,
                responsedata,
                responsestatus
              ) {
                if (responsestatus == "200") {
                  if (window["modal"].state.callback) {
                    window["modal"].state.callback(submittedData);
                  }
                } else {
                  if (responsedata.data.exceptions) {
                    responsedata.data.exceptions.map((itm, idx) => {
                      Fun.createNotification({
                        message: itm.errorDescription,
                        type: "danger",
                      });
                    });
                  }
                }
              },
              parameters: {
                headers: {
                  "content-type": "application/json",
                },
              },
              submittedData: JSON.stringify(jsonData),
            });
          },
        },
        colsTemplateCount: 12,
        includeProperties: ["_id", "title", "description", "modules"],
      },
      pk: "_id",
      properties: UniTrainingTemplatesProperties({ editable: true }),
    },
    add: {
      auth: "",
      apis: {
        view: {
          data: { data: [{}] },
          parameters: {
            headers: {},
            replace: {},
          },
          formatter: function (data) {
            return UniTrainingTemplatesFormatter(data);
          },
        },
        save: {
          url: "/template/training",
          parameters: {
            headers: {
              "Content-Type": "application/json",
            },
            replace: {},
          },
          formatter: function (data) {
            return UniTrainingTemplatesFormatter(data);
          },
        },
      },
      template: "form",
      templateConfig: {
        events: {
          onSubmit: function (e) {
            e.preventDefault();

            let submittedData = Fun.formDataToJSON(e);

            if (Fun.validate(this.props.builder.validator, submittedData)) {
              let jsonData = UniFun.trainingJSONData(Fun.getSubmitJSON(e));
              Fun.update(e, {
                url: this.props.builder.state.module.apis.save,
                method: "POST",
                callback: function (
                  submittedData,
                  status,
                  responsedata,
                  responsestatus
                ) {
                  if (responsestatus == "200") {
                    if (window["modal"].state.callback) {
                      window["modal"].state.callback(submittedData);
                    }
                    Fun.createNotification({
                      message: "Template has been created",
                      type: "success",
                    });
                  } else {
                    if (responsedata.data.exceptions) {
                      responsedata.data.exceptions.map((itm, idx) => {
                        Fun.createNotification({
                          message: itm.errorDescription,
                          type: "danger",
                        });
                      });
                    }
                  }
                },
                parameters: {
                  headers: {
                    "content-type": "application/json",
                  },
                },
                submittedData: JSON.stringify(jsonData),
              });
            }
          },
        },
        colsTemplateCount: 12,
        includeProperties: ["title", "description", "modules"],
      },
      pk: "_id",
      properties: UniTrainingTemplatesProperties({ editable: true }),
    },
    duplicateTemplate: {
      auth: "",
      apis: {
        view: {
          url: "/template/training/{id}",
          parameters: {
            headers: {},
          },
          formatter: function (args) {
            return UniTrainingTemplatesFormatter(args);
          },
        },
        duplicate: {
          url: "/template/training/",
          parameters: {
            headers: {},
            replace: {},
          },
          formatter: function (data) {
            return UniTrainingTemplatesFormatter(data);
          },
        },
      },
      template: "form",
      templateConfig: {
        useCard: false,
        colsTemplateCount: 12,
        includeProperties: ["duplicateditm"],
        excludeProperties: [],
        events: {
          onSubmit: function (e) {
            e.preventDefault();

            let data = new FormData(e.target);

            let duplicateditm = JSON.parse(data.get("duplicateditm"));

            let jsonData = duplicateditm;

            delete jsonData["_id"];
            delete jsonData["createdAt"];
            delete jsonData["updatedAt"];
            delete jsonData["deleted"];
            delete jsonData["owner"];

            jsonData["title"] = "Copy of " + jsonData["title"];

            if (jsonData) {
              jsonData.modules.map((itm, idx) => {
                delete itm["_id"];

                itm.sessions.map((sitm, sidx) => {
                  delete sitm["_id"];
                  if (sitm["videoPresentation"]) {
                    sitm["videoPresentation"].map((vitm, vidx) => {
                      if (vitm != "_id") {
                        sitm["videoPresentation"][vidx] = vitm;
                      }
                    });
                  }
                  if (sitm["reading"]) {
                    sitm["reading"].map((vitm, vidx) => {
                      sitm["reading"][vidx] = vitm;
                    });
                  }
                  if (sitm["exam"]) {
                    sitm["exam"].map((vitm, vidx) => {
                      sitm["exam"][vidx] = vitm["_id"];
                    });
                  }
                });
              });
            }

            Fun.update(e, {
              url: this.props.builder.state.module.apis.duplicate,
              method: "POST",
              callback: function (
                submittedData,
                status,
                responsedata,
                responsestatus
              ) {
                if (responsestatus == "200") {
                  if (window["modal2"].state.callback) {
                    window["modal2"].state.callback(submittedData);
                  }
                }
              },
              parameters: {
                headers: {
                  "content-type": "application/json",
                },
              },
              submittedData: JSON.stringify(jsonData),
            });
          },
        },
        templateTop: function () {
          let data = this.state.data[0];
          return (
            <div className=" p-4">
              <p>
                Are you sure you want to create a copy of the training template{" "}
                <strong>"{data.title.component}"</strong>?<br></br>
              </p>
            </div>
          );
        },
      },
      pk: "_id",
      properties: UniTrainingTemplatesProperties({ editable: false }),
    },
    deleteTemplate: {
      auth: "",
      apis: {
        view: {
          url: "/template/training/{id}",
          parameters: {
            headers: {},
          },
          formatter: function (args) {
            return UniTrainingTemplatesFormatter(args);
          },
        },
        delete: {
          url: "/template/training/{id}",
          parameters: {
            headers: {},
            replace: {},
          },
          formatter: function (data) {
            return UniTrainingTemplatesFormatter(data);
          },
        },
      },
      template: "form",
      templateConfig: {
        useCard: false,
        colsTemplateCount: 12,
        includeProperties: ["_id"],
        excludeProperties: [],
        events: {
          onSubmit: function (e) {
            let submittedData = Fun.getSubmitJSON(e);

            let builder = this.props.builder;

            Fun.update(e, {
              url: builder.state.module.apis.delete,
              method: "DELETE",
              parameters: {
                headers: {
                  "content-type": "application/json",
                },
                replace: {
                  id: submittedData["_id"],
                },
              },
              callback: function (args) {
                if (window["modal2"].state.callback) {
                  window["modal2"].state.callback(submittedData);
                }
              },
              submittedData: JSON.stringify([]),
            });
          },
        },
        templateTop: function () {
          let data = this.state.data[0];
          return (
            <div className=" p-4">
              <p>
                Are you sure you want to delete training template{" "}
                <strong>"{data.title.component}"</strong>?<br></br>
              </p>
            </div>
          );
        },
      },
      pk: "_id",
      properties: UniTrainingTemplatesProperties({ editable: false }),
    },
    selectNewOrFromTemplate: {
      auth: "",
      apis: {
        view: {
          data: {
            data: [
              {
                _id: "modalSelectTemplate",
                title: "Choose from existing templates",
              },
              {
                _id: "modalNewTemplate",
                title: "Schedule new training",
              },
            ],
          },
          parameters: {
            headers: {},
            replace: {},
          },
          formatter: function (data) {
            return UniTrainingTemplatesFormatter(data);
          },
        },
      },
      template: "selectboxes",
      templateConfig: {
        events: {
          onSubmit: function (e) {
            e.preventDefault();
            let jsonData = Fun.getSubmitJSON(e);
            let builder = this.props.builder;

            if (jsonData["selectTemplate"] == "modalSelectTemplate") {
              window["modal2"].setState({
                title: <h3>Schedule training</h3>,
                size: "lg",
                targetComponent: null,
                show: true,
                builder: window["modal2"].state.builder,
                callback: function (args) {
                  window["modal2"].setState({ show: false, overflowY: "auto" });
                  this.builder.rerunComponent();
                },
                footer: UniFun.modalCommonFooter(
                  "modal2",
                  "save",
                  "btn-primary save-template d-none"
                ),
                body: (
                  <Builder
                    module="unitrainingtemplates"
                    view="selectTraining"
                    key={Fun.uuid()}
                    ref={(refComponent) => {
                      window["modal2body"] = refComponent;
                    }}
                  ></Builder>
                ),
              });
            } else if (jsonData["selectTemplate"] == "modalNewTemplate") {
              window["modal2"].setState({ show: false, overflowY: "auto" });

              let uuid = Fun.uuid();

              window["modal"].setState({
                title: <h3>Schedule training</h3>,
                size: "xl",
                targetComponent: this,
                builder: window["modal2"].state.builder,
                footer: UniFun.modalCommonFooter("modal", "Schedule"),
                callback: function (args) {
                  window["modal"].setState({ show: false, overflowY: "auto" });
                  this.builder.rerunComponent();
                },
                show: true,
                body: (
                  <Builder
                    module="unitrainingevents"
                    view="detail"
                    inputData={{ data: [{}] }}
                    key={uuid}
                    ref={(refComponent) => {
                      window["modalbody"] = refComponent;
                    }}
                  ></Builder>
                ),
              });
            }
          },
        },
        colsTemplateCount: 12,
        templateTop: function () {
          return (
            <React.Fragment>
              <p className="pl-2 mt-4">
                <h5>I want to: </h5>
              </p>
            </React.Fragment>
          );
        },
        includeProperties: ["selectTemplate", "title"],
      },
      pk: "_id",
      properties: UniTrainingTemplatesProperties({ editable: false }),
    },
    selectTraining: {
      auth: "",
      apis: {
        view: {
          url: "/template/training?start={start}&offset={offset}",
          parameters: {
            headers: {},
            replace: {
              start: 0,
              offset: 100,
            },
          },
          formatter: function (data) {
            return UniTrainingTemplatesFormatter(data);
          },
        },
      },
      template: "selectboxes",
      templateConfig: {
        events: {
          onSubmit: function (e) {
            e.preventDefault();
            let jsonData = Fun.getSubmitJSON(e);

            let params = {
              replace: {
                id: jsonData.selectTemplate,
              },
            };

            window["modal2"].setState({ show: false, overflowY: "auto" });

            window["modal"].setState({
              title: <h3>Schedule training</h3>,
              size: "xl",
              targetComponent: this,
              footer: UniFun.modalCommonFooter("modal", "Schedule"),
              builder: window["modal2"].state.builder,
              callback: function () {
                window["modal"].setState({ show: false, overflowY: "auto" });
                this.builder.rerunComponent();
              },
              show: true,
              body: (
                <Builder
                  module="unitrainingevents"
                  view="detail"
                  parameters={params}
                  inputData={false}
                  key={Fun.uuid()}
                  ref={(refComponent) => {
                    window["modalbody"] = refComponent;
                  }}
                ></Builder>
              ),
            });
          },
        },
        colsTemplateCount: 12,
        templateTop: function () {
          if (this.state.data.length > 0) {
            if (document.getElementsByClassName("save-template")) {
              if (document.getElementsByClassName("save-template")[0]) {
                document
                  .getElementsByClassName("save-template")[0]
                  .classList.remove("d-none");
              }
            }
          }

          return (
            <React.Fragment>
              <p className="pl-2 mt-4">
                <h5>Choose training template: </h5>
                {this.state.data.length == 0 ? (
                  <span className="text-dark pt-2">No template created</span>
                ) : (
                  ""
                )}
              </p>
            </React.Fragment>
          );
        },
        includeProperties: ["title", "selectTemplate"],
      },
      pk: "_id",
      properties: UniTrainingTemplatesProperties({ editable: false }),
    },
  },
};

export default UniTrainingTemplates;

function UniTrainingTemplatesProperties(input) {
  let editable = input.editable;

  return {
    selectTemplate: {
      alias: "selectTemplate",
      control: "checkbox",
      label: "",
      editable: true,
      attributes: {
        className: "form-check-input  select-template-checkbox",
      },
      events: {
        onChange: function () {
          let value = this.props.value;

          Object.keys(this.props.builder).map((itm, idx) => {
            if (
              itm.startsWith("selectTemplate") &&
              itm != "selectTemplate-" + value
            ) {
              this.props.builder[itm].setState({
                checked: false,
              });
            }
          });

          this.props.builder.builderTemplate.current.setState({
            action: this.props.data["_id"],
          });
        },
      },
    },
    _id: {
      alias: "_id",
      control: "hidden",
      label: "",
      editable: editable,
      attributes: {},
    },
    title: {
      alias: "title",
      control: "text",
      label: "Name",
      placeholder: "Type training title",
      editable: editable,
      attributes: {
        className: "form-control",
      },
      validations: "required",
    },
    description: {
      alias: "description",
      control: "editor",
      placeholder: "Write about the training",
      nonEditableDefaultValue: "No description available",
      label: "Description",
      editable: editable,
      attributes: {
        className: "form-control",
      },
    },
    duplicateditm: {
      alias: "duplicateditm",
      control: "hidden",
      label: "",
      editable: editable,
      attributes: {
        className: "form-control",
      },
    },
    modules: {
      alias: "modules",
      control: "tabs",
      label: <h3 className="text-black mt-4">Modules</h3>,
      tooltip:
        "A training is divided into modules. You can add as many modules as you need for the training.",
      events: {
        closeTab: function (idx) {
          let object = this;
          let tabIdx = idx;
          window["modal3"].setState({
            show: true,
            title: <h3>Delete Module?</h3>,
            body: (
              <div className="p-4">
                <p style={{ paddingLeft: "10px" }}>
                  Are you sure you want to delete module {idx + 1}? This action
                  will delete all sessions added to this module!
                </p>
              </div>
            ),
            footer: [
              <button
                type="button"
                className="btn btn-link border"
                onClick={() => {
                  window["modal3"].setState({ show: false, overflowY: "auto" });
                }}
              >
                Cancel
              </button>,
              <button
                type="button"
                className="btn btn-danger border"
                onClick={() => {
                  let tabs = [...object.state.tabs];
                  tabs.splice(tabIdx, 1);
                  object.setState({ tabs: tabs });
                  window["modal3"].setState({ show: false, overflowY: "auto" });
                }}
              >
                Delete
              </button>,
            ],
          });
        },
      },
      config: {
        canClose: true,
        tabs: [
          {
            labelNew: (
              <span>
                <img
                  src="/uni/svg/add.svg"
                  className="filter-link mr-2"
                  style={{ width: "14px", paddingBottom: "4px" }}
                ></img>{" "}
                Add new module
              </span>
            ),
            label: "Module {index}",
            includeProperties: ["_id", "title", "description", "sessions"],
            isNewTab: true,
          },
        ],
        properties: {
          _id: {
            alias: "_id",
            control: "hidden",
            label: "",
            editable: editable,
          },
          title: {
            alias: "title",
            control: "text",
            label: "Title",
            placeholder: "Type module title",
            editable: editable,
            validations: "required",
            attributes: {
              className: "form-control",
            },
          },
          description: {
            alias: "description",
            control: "editor",
            label: "Description",
            placeholder: "Write module introduction...",
            nonEditableDefaultValue: "No description available",
            editable: editable,
            attributes: {
              className: "form-control pb-5",
            },
          },
          sessions: {
            alias: "sessions",
            control: "tabs",
            label: <h3 className="text-black">Sessions</h3>,
            placeholder: "Type session title",
            tooltip:
              "Each module is divided into sessions. You can add as many sessions as you need for each module.",
            events: {
              closeTab: function (idx) {
                let object = this;
                let tabIdx = idx;
                window["modal3"].setState({
                  show: true,
                  title: <h3>Delete Session?</h3>,
                  body: (
                    <div className="p-4">
                      <p style={{ paddingLeft: "10px" }}>
                        Are you sure you want to delete session {idx + 1}? This
                        action will delete all learning material added to this
                        session!
                      </p>
                    </div>
                  ),
                  footer: [
                    <button
                      type="button"
                      className="btn btn-link border"
                      onClick={() => {
                        window["modal3"].setState({
                          show: false,
                          overflowY: "auto",
                        });
                      }}
                    >
                      Cancel
                    </button>,
                    <button
                      type="button"
                      className="btn btn-danger border"
                      onClick={() => {
                        let tabs = [...object.state.tabs];
                        tabs.splice(tabIdx, 1);
                        object.setState({ tabs: tabs });
                        window["modal3"].setState({
                          show: false,
                          overflowY: "auto",
                        });
                      }}
                    >
                      Delete
                    </button>,
                  ],
                });
              },
            },
            config: {
              canClose: true,

              tabs: [
                {
                  labelNew: (
                    <span>
                      <img
                        src="/uni/svg/add.svg"
                        className="filter-link mr-2"
                        style={{ width: "14px", paddingBottom: "4px" }}
                      ></img>{" "}
                      Add new session
                    </span>
                  ),
                  label: "Session {index}",
                  includeProperties: [
                    "_id",
                    "title",
                    "label",
                    "videoPresentation",
                    "reading",
                    "exam",
                  ],
                  isNewTab: true,
                },
              ],
              properties: {
                _id: {
                  alias: "_id",
                  control: "hidden",
                  label: "",
                  editable: editable,
                },
                title: {
                  alias: "title",
                  control: "text",
                  label: "Title",
                  placeholder: "Type session title",
                  validations: "required",
                  editable: editable,
                  attributes: {
                    className: "form-control",
                  },
                },
                label: {
                  alias: "label",
                  control: "label",
                  label: (
                    <h6 className="text-black pt-3 pb-3">
                      <strong>Learning Material</strong>
                    </h6>
                  ),
                  tooltip:
                    "In each session you can add the learning material that are suitable for you.",
                },
                videoPresentation: {
                  alias: "videoPresentation",
                  control: "multiple",
                  label: "Video Presentation",

                  config: {
                    addNewLabel: (
                      <span>
                        <img
                          src="/uni/svg/add.svg"
                          className="filter-link mr-2"
                          style={{ width: "11px", paddingBottom: "5px" }}
                        ></img>{" "}
                        Add Video Presentation
                      </span>
                    ),
                    addNewFunction: function () {
                      UniFun.trainingNodes({
                        module: "univideopresentation",
                        view: "detail",
                        control: this,
                        modalTitle: "Video Presentation",
                        inputData: { data: [{}] },
                      });
                    },
                    properties: {
                      videoPresentation: {
                        alias: "",
                        control: "hidden",
                        modalTitle: "Video Presentation",
                        modalModule: "univideopresentation",
                        customLayout: UniFun.trainingNodesCustomLayout,
                        useCustomLayout: true,
                      },
                    },
                  },
                },
                reading: {
                  alias: "reading",
                  control: "multiple",
                  label: "Reading",
                  config: {
                    addNewLabel: (
                      <span>
                        <img
                          src="/uni/svg/add.svg"
                          className="filter-link mr-2"
                          style={{ width: "11px", paddingBottom: "5px" }}
                        ></img>{" "}
                        Add Reading
                      </span>
                    ),
                    addNewFunction: function () {
                      UniFun.trainingNodes({
                        module: "unireadings",
                        view: "detail",
                        control: this,
                        modalTitle: "Reading",
                        inputData: { data: [{}] },
                      });
                    },
                    properties: {
                      reading: {
                        alias: "",
                        modalTitle: "Readings",
                        modalModule: "unireadings",
                        control: "hidden",
                        customLayout: UniFun.trainingNodesCustomLayout,
                        useCustomLayout: true,
                      },
                    },
                  },
                },
                exam: {
                  alias: "exam",
                  control: "multiple",
                  label: "Test",
                  config: {
                    addNewLabel: (
                      <span>
                        <img
                          src="/uni/svg/add.svg"
                          className="filter-link mr-2"
                          style={{ width: "11px", paddingBottom: "5px" }}
                        ></img>{" "}
                        Add Test
                      </span>
                    ),
                    addNewFunction: function () {
                      window["modal2"].setState({
                        title: <h3>Test</h3>,
                        size: "lg",
                        targetComponent: this,
                        footer: UniFun.modalCommonFooter("modal2", "Save"),
                        callback: function (data) {
                          let exam = JSON.parse(data["exam"]);

                          let currentData =
                            window["modal2"].state.targetComponent.state.data;

                          let examId = exam["id"];
                          let examTitle = exam["title"];

                          let fileObject = {
                            _id: examId,
                            title: examTitle,
                            mimetype: "test",
                          };

                          currentData.push(fileObject);
                          window["modal2"].state.targetComponent.setState({
                            data: currentData,
                          });
                        },
                        show: true,
                        body: (
                          <Builder
                            module="unitrainingtests"
                            view="detail"
                            key={Fun.uuid()}
                            ref={(refComponent) => {
                              window["modal2body"] = refComponent;
                            }}
                          ></Builder>
                        ),
                      });
                    },
                    properties: {
                      exam: {
                        alias: "",
                        modalModule: "unitrainingtests",
                        modalTitle: "Test",
                        mimetype: "testfill",
                        mimeTypeClass: "filter-somon",
                        control: "hidden",
                        customLayout: UniFun.trainingNodesCustomLayout,
                        useCustomLayout: true,
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
    createdBy: {
      alias: "createdBy",
      control: "text",
      label: "Created by",
      editable: editable,
      attributes: {
        className: "form-control",
      },
    },
    modified: {
      alias: "modified",
      control: "datetime",
      label: "Last modified",
      editable: editable,
      attributes: {
        className: "form-control",
      },
    },
    edit: {
      alias: "edit",
      control: "button",
      caption: "Edit",
      apiPath: "",
      auth: "",
      validations: "",
      events: {
        onClick: function () {
          let params = {
            replace: {
              id: this.props.data._id,
            },
          };

          window["viewport"].setState({
            loaded: true,
            main: (
              <Builder
                module="meetings"
                view="detail"
                parameters={params}
                key={Fun.uuid()}
              />
            ),
          });
        },
      },
      attributes: {
        className: "btn btn-primary",
      },
    },
    newTemplate: {
      alias: "newTemplate",
      control: "button",
      caption: "New Template",
      events: {
        onClick: function () {},
      },
      attributes: {
        className: "btn btn-primary",
      },
    },
    submit: {
      alias: "submit",
      control: "submit",
      caption: "submit",
      apiPath: "",
      auth: "",
      validations: "",
      events: {
        onChange: function () {},
      },
      attributes: {
        className: "btn btn-primary float-right",
      },
    },
  };
}

function UniTrainingTemplatesFormatter(data) {
  if (data) {
    let formattedData = [];

    if (data.data) {
      data.data.map((itm, idx) => {
        let formattedRecord = {};

        formattedRecord["_id"] = itm._id;
        formattedRecord["selectTemplate"] = itm._id;
        formattedRecord["duplicateditm"] = JSON.stringify(itm);

        formattedRecord["title"] = itm.title;
        formattedRecord["createdBy"] = itm.owner ? itm.owner.fullName : "---";
        formattedRecord["modified"] = itm.updatedAt;
        formattedRecord["description"] = itm.description;
        formattedRecord["modules"] = itm.modules ? itm.modules : [];

        formattedData.push(formattedRecord);
      });
    }

    return formattedData;
  }
}
