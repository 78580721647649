import React, { Component } from "react";
import Controls from "../controls";
import Themes from "../../themes/themes";
import Fun from "../../lib/functions";

class ExtraFields extends Component {
  state = {
    controls: [],
    customLayout: null,
    useCustomLayout: true,
    loaded: false,
    uuid: Fun.uuid(),
  };

  constructor(props) {
    super(props);
    this.multipleLength = -1;
    this.controlsGroup = this.controlsGroup.bind(this);
  }

  componentDidMount() {
    let controlName = this.props.field.alias;

    let data = this.props.value ? this.props.value : [];

    this.setState({
      data: data,
      controlName: controlName,
      customLayout: this.props.field.customLayout,
      useCustomLayout:
        typeof this.props.field.useCustomLayout !== "undefined"
          ? this.props.field.useCustomLayout
          : true,
      loaded: true,
    });
  }

  controlsGroup() {
    let controlsGroup = [];
    this.state.data.map((itmData, idxData) => {
      let Control = Controls[itmData.type];

      let alias = itmData.alias ? itmData.alias : itmData.label;
      let label = itmData.label;

      let field = {
        alias: alias,
        label: label,
        editable: this.props.field.editable,
        nonEditableDefaultValue: this.props.field.nonEditableDefaultValue,
        attributes: {
          className: "form-control",
        },
      };

      const ControlComponent = (
        <Control
          field={field}
          key={Fun.uuid()}
          value={this.props.data[alias]}
          parent={this}
          data={itmData}
          builder={this.props.builder}
        ></Control>
      );

      let ControlTemplate = Themes[window["c_theme"]]["control"];

      controlsGroup.push(
        <ControlTemplate
          key={Fun.uuid()}
          control={{
            field: field,
            component: ControlComponent,
          }}
          parent={this}
        ></ControlTemplate>
      );
    });

    return controlsGroup;
  }

  render() {
    if (this.state.loaded) {
      let output = [];

      output = this.controlsGroup(); //this.state.controls;

      if (this.state.customLayout && this.state.useCustomLayout) {
        this.state.customLayout = this.state.customLayout.bind(this);
        return this.state.customLayout({ control: output, object: this });
      }

      return output;
    }
    return "";
  }
}

export default ExtraFields;
