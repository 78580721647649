import React, { Component } from "react";
import Control from "./../../control";
import SimpleReactValidator from "simple-react-validator";
import Fun from "../../../../../lib/functions";
import UniFun from "../../../../../modules/uni/functions";
import moment from "moment";
import TopTemplateHeader from "./../../topTemplateHeader";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";

class Settings extends Component {
  state = {
    data: this.props.data,
    loaded: false,
    colsTemplateCount: 12,
    colsFormCount: 12,
    cardBodyCustomClass: "",
    module: {
      _id: "",
      title: "",
      description: "",
    },
    session: {},
  };

  constructor(props) {
    super(props);
    let builder = this.props.builder.state;
    let templateConfig = builder.module.templateConfig;
    if (templateConfig.events) {
      Object.keys(templateConfig.events).map((itm, idx) => {
        this[itm] = templateConfig.events[itm].bind(this);
      });
    }

    this.sessionsCards = this.sessionsCards.bind(this);
    this.form = React.createRef();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.action) {
      return false;
    }
    return true;
  }

  componentDidMount() {
    let builder = this.props.builder.state;
    let templateConfig = builder.module.templateConfig;
    let useCard =
      typeof templateConfig.useCard !== "undefined"
        ? templateConfig.useCard
        : true;

    let templateTop = templateConfig.templateTop
      ? templateConfig.templateTop
      : () => {};
    this["templateTop"] = templateTop.bind(this);
    let templateBottom = templateConfig.templateBottom
      ? templateConfig.templateBottom
      : () => {};
    this["templateBottom"] = templateBottom.bind(this);
    let templateLeft = templateConfig.templateLeft
      ? templateConfig.templateLeft
      : () => {};
    this["templateLeft"] = templateLeft.bind(this);
    let templateRight = templateConfig.templateRight
      ? templateConfig.templateRight
      : () => {};
    this["templateRight"] = templateRight.bind(this);
    let cardTemplateTop = templateConfig.cardTemplateTop
      ? templateConfig.cardTemplateTop
      : () => {};
    this["cardTemplateTop"] = cardTemplateTop.bind(this);
    let cardTemplateBottom = templateConfig.cardTemplateBottom
      ? templateConfig.cardTemplateBottom
      : () => {};
    this["cardTemplateBottom"] = cardTemplateBottom.bind(this);
    let panelTop = templateConfig.panelTop ? templateConfig.panelTop : () => {};
    this["panelTop"] = panelTop.bind(this);
    let panelBottom = templateConfig.panelBottom
      ? templateConfig.panelBottom
      : () => {};
    this["panelBottom"] = panelBottom.bind(this);
    let panelLeft = templateConfig.panelLeft
      ? templateConfig.panelLeft
      : () => {};
    this["panelLeft"] = panelLeft.bind(this);
    let panelRight = templateConfig.panelRight
      ? templateConfig.panelRight
      : () => {};
    this["panelRight"] = panelRight.bind(this);
    let header = templateConfig.header ? templateConfig.header : () => {};
    this["header"] = header.bind(this);

    let colsTemplateCount = templateConfig.colsTemplateCount
      ? templateConfig.colsTemplateCount
      : this.state.colsTemplateCount;
    let colsFormCount = templateConfig.colsFormCount
      ? templateConfig.colsFormCount
      : this.state.colsFormCount;

    let cardBodyCustomClass = templateConfig.cardBodyCustomClass
      ? templateConfig.cardBodyCustomClass
      : this.state.cardBodyCustomClass;

    this.setState({
      loaded: true,
      rawData: this.props.rawData,
      colsTemplateCount: colsTemplateCount,
      colsFormCount: colsFormCount,
      cardBodyCustomClass: cardBodyCustomClass,
      useCard: useCard,
    });

    this.validator = this.props.builder.validator;
    if (this.componentDidMountEvent) {
      this.componentDidMountEvent();
    }
  }

  sessionsCards() {
    let output = [];

    let data = this.state.rawData.data[0];

    data.days?.forEach((itm, idx) => {
      let sessions = [];
      itm.sessions.forEach((sitm, sidx) => {
        sessions.push(
          <div className="col-md-4 mt-3">
            <div className="card h-100">
              <div className="card-body">
                <strong>Session {sidx + 1}</strong>: {sitm.title}
                <span className="d-block  text-grey mt-4">
                  Ingest Endpoint:
                </span>
                <span className="d-block mt-1">
                  {sitm.ingestEndpoint}
                  <i
                    className="far fa-clone ml-2 pointer"
                    onClick={(e) => UniFun.copyToClipboard(sitm.ingestEndpoint)}
                  ></i>
                </span>
                <span className="d-block  text-grey mt-3">Stream Key:</span>
                <span className="d-block mt-1">
                  {sitm.streamKey}
                  <i
                    className="far fa-clone ml-2 pointer"
                    onClick={(e) => UniFun.copyToClipboard(sitm.streamKey)}
                  ></i>
                </span>
              </div>
            </div>
          </div>
        );
      });

      output.push(
        <div className="mb-5">
          <div className="row">
            <div className="col-md-12 pb-3">
              <strong>Day {idx + 1}</strong>
            </div>
          </div>
          <div className="row">{sessions}</div>
        </div>
      );
    });

    return output;
  }

  render() {
    if (this.state.loaded) {
      let data = this.state.data[0];

      return (
        <React.Fragment>
          <div className={"uniTopHeader "}>
            <TopTemplateHeader
              customClass="mb-4 uniFixedHeader"
              title={
                <React.Fragment>
                  <span className="pl-3">Settings</span>
                </React.Fragment>
              }
              subtitle={<span className="pl-18px">Stream configuration</span>}
            ></TopTemplateHeader>
          </div>
          <div className="container-fluid mt-5">{this.sessionsCards()}</div>
        </React.Fragment>
      );
    } else {
      return "loading...";
    }
  }
}

export default withRouter(Settings);
