import React, { Component } from "react";
import Fun from "../../../lib/functions";
import Controls from "../../../controls/controls";
import Builder from "../../../lib/builder";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";

class TopMenuVideo extends Component {
  state = {
    data: [],
    loaded: false,
    output: null,
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    let field = {
      alias: "mimetype",
      control: "ajax",
      label: "",
      config: {
        url: {
          url: "/agenda-file/{id}",
          parameters: {
            replace: {
              id: function () {
                return Fun.getURLPathValue(3);
              },
            },
          },
        },
      },
      useCustomLayout: true,
      customLayout: function (args) {
        let data = this.state.defaultValue;

        try {
          if (data) {
            if (data.data) {
              if (data.data[0]) {
                if (data.data[0].recording) {
                  if (data.data[0].isAudio) {
                    return (
                      <img
                        src="/uni/svg/files/audio.svg"
                        className="ml-3"
                        style={{ width: "30px", paddingBottom: "5px" }}
                      ></img>
                    );
                  }

                  return (
                    <img
                      src="/uni/svg/files/rec.svg"
                      className="ml-3"
                      style={{ width: "30px", paddingBottom: "5px" }}
                    ></img>
                  );
                }
              }
            }
          }
        } catch (e) {}

        return (
          <img
            src="/uni/svg/files/video.svg"
            className="ml-3"
            style={{ width: "30px", paddingBottom: "5px" }}
          ></img>
        );
      },
    };

    let Control = Controls["ajax"];

    this.setState({
      data: this.props.data,
      loaded: true,
      mimeIcon: <Control field={field} key={Fun.uuid()}></Control>,
    });
  }

  render() {
    let _this = this;

    if (this.state.loaded) {
      return (
        <header className=" c-header c-header-light c-header-fixed c-header-with-subheader ">
          <div className="row w-100">
            <div className="col-9">
              <div className="mt-2">
                <img
                  src="/uni/svg/trainings/go_back.svg"
                  className="pr-3 pointer ml-3"
                  style={{ width: "45px", paddingBottom: "5px" }}
                  onClick={(e) => {
                    _this.props.history.goBack();
                  }}
                ></img>
                {this.state.mimeIcon}{" "}
                <strong>
                  {Fun.getParameterByName("title")
                    ? Fun.getParameterByName("title")
                    : "Video"}
                </strong>
              </div>
            </div>
            <div className="col-3 text-right">
              <div className="pt-3">
                <a
                  href={window["baseurl"](
                    "/agenda-file/data/" +
                      Fun.getURLPathValue(3) +
                      "?type=download&jwt=" +
                      localStorage.getItem("jwt")
                  )}
                  target="new"
                >
                  <img
                    src="/uni/svg/download.svg"
                    style={{ height: "15px" }}
                  ></img>
                </a>
              </div>
            </div>
          </div>
        </header>
      );
    } else {
      return "loading...";
    }
  }
}

export default withRouter(TopMenuVideo);
