import React, { Component } from "react";

class OTP extends Component {
  constructor(props) {
    super(props);

    let builder = this.props.builder.state;
    let templateConfig = builder.module.templateConfig;
    if (templateConfig.events) {
      Object.keys(templateConfig.events).map((itm, idx) => {
        this[itm] = templateConfig.events[itm].bind(this);
      });
    }

    this.changeResend = this.changeResend.bind(this);
    this.state = {
      loaded: false,
      resend: false,
      otp: ["", "", "", "", "", ""],
    };

    this.setOTP = this.setOTP.bind(this);
    this.setOTPText = this.setOTPText.bind(this);
    this.form = React.createRef();
    this.otp1 = React.createRef();
    this.otp2 = React.createRef();
    this.otp3 = React.createRef();
    this.otp4 = React.createRef();
    this.otp5 = React.createRef();
    this.otp6 = React.createRef();
  }

  componentDidMount() {
    this.setState({ loaded: true, data: this.props.data });
  }

  changeResend() {
    this.setState({ resend: true });
  }

  setOTPText(value) {
    if (value.length == 6) {
      let currentForm = this.form.current;
      setTimeout(function () {
        currentForm.dispatchEvent(
          new Event("submit", {
            cancelable: true,
            bubbles: true,
          })
        );
      }, 0);
    }
  }

  setOTP(value, otpIdx, e) {
    let otp = this.state.otp;

    otp[otpIdx - 1] = value.substr(value.length - 1);

    let otpIdxRef = parseFloat(otpIdx) + 1;

    if (value) {
      if (this["otp" + otpIdxRef]) {
        this["otp" + otpIdxRef].current.focus();
      }
    } else {
      otpIdxRef = parseFloat(otpIdx) - 1;
      if (this["otp" + otpIdxRef]) {
        this["otp" + otpIdxRef].current.focus();
      }
    }

    this.setState({ otp: otp });

    if (otpIdx == 6) {
      let currentForm = this.form.current;
      setTimeout(function () {
        currentForm.dispatchEvent(
          new Event("submit", {
            cancelable: true,
            bubbles: true,
          })
        );
      }, 0);
    }
  }

  onPaste(e) {
    let pasteData = e.clipboardData.getData("Text");

    let otp = [...this.state.otp];

    for (var i = 0; i < pasteData.length; i++) {
      otp[i + 1] = pasteData[i];
    }
    let object = this;
    this.setState({ otp: otp });

    let currentForm = this.form.current;
    setTimeout(function () {
      object["otp" + pasteData.length].current.focus();

      currentForm.dispatchEvent(
        new Event("submit", {
          cancelable: true,
          bubbles: true,
        })
      );
    }, 200);
  }

  render() {
    if (this.state.loaded) {
      let data = this.state.data[0];
      return (
        <React.Fragment>
          <div className="text-center mb-5">
            <img
              src="/uni/images/company-logo.png"
              style={{ height: "100px" }}
            ></img>
            <h1>Verify your account</h1>
            <p className="text-muted">
              We have send a verification code to your email (remember to check
              your spam). The code expires shortly, so please enter it soon.
            </p>
          </div>
          <form onSubmit={this.onSubmit} ref={this.form}>
            <div className="container mt-4">
              <div className="row">
                <div className="col-md-12">
                  <input
                    type="text"
                    name="key"
                    className="form-control otp-control text-center w-75"
                    style={{ marginLeft: "auto", marginRight: "auto" }}
                    // value={this.state.otp[0]}
                    placeholder="Type or paste the 6 digit code here..."
                    maxlength="6"
                    onChange={(e) => this.setOTPText(e.target.value)}
                    //onPaste={(e) => this.onPaste(e)}
                    //ref={this.otp1}
                  />
                </div>
                {/* <div className="col-md-2">
                  <input
                    type="text"
                    className="form-control otp-control text-center"
                    value={this.state.otp[0]}
                    onChange={(e) => this.setOTP(e.target.value, "1", e)}
                    onPaste={(e) => this.onPaste(e)}
                    ref={this.otp1}
                  />
                </div>
                <div className="col-md-2">
                  <input
                    type="text"
                    className="form-control otp-control text-center"
                    value={this.state.otp[1]}
                    onChange={(e) => this.setOTP(e.target.value, "2")}
                    ref={this.otp2}
                  />
                </div>
                <div className="col-md-2">
                  <input
                    type="text"
                    className="form-control otp-control text-center"
                    value={this.state.otp[2]}
                    onChange={(e) => this.setOTP(e.target.value, "3")}
                    ref={this.otp3}
                  />
                </div>
                <div className="col-md-2">
                  <input
                    type="text"
                    className="form-control otp-control text-center"
                    value={this.state.otp[3]}
                    onChange={(e) => this.setOTP(e.target.value, "4")}
                    ref={this.otp4}
                  />
                </div>
                <div className="col-md-2">
                  <input
                    type="text"
                    className="form-control otp-control text-center"
                    value={this.state.otp[4]}
                    onChange={(e) => this.setOTP(e.target.value, "5")}
                    ref={this.otp5}
                  />
                </div>
                <div className="col-md-2">
                  <input
                    type="text"
                    className="form-control otp-control text-center"
                    value={this.state.otp[5]}
                    onChange={(e) => this.setOTP(e.target.value, "6")}
                    ref={this.otp6}
                  />
                </div> */}
              </div>
              {/* <input type="hidden" name="key" value={this.state.otp.join("")} /> */}
              {data ? data.hash.component : ""}
            </div>
          </form>
          <form onSubmit={this.onSubmitResend}>
            <p className="text-center mt-5 pt-2 pb-0 mb-0">
              <button type="submit" className="btn btn-link">
                Didn't receive an email? Resend it!
              </button>
            </p>
          </form>
        </React.Fragment>
      );
    }
    return "";
  }
}

export default OTP;
