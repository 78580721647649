import React, { Component } from "react";
import Builder from "../../lib/builder";
import Fun from "../../lib/functions";
import UniFun from "./functions";
import { Link } from "react-router-dom";

const add = function (parentBuilder) {
  let builder = parentBuilder;
  window["modal"].setState({
    title: (
      <React.Fragment>
        <h3 className="pl-3">Add poll</h3>
      </React.Fragment>
    ),
    size: "lg",
    targetComponent: this,
    parentBuilder: builder,
    callback: function () {
      window["modal"].setState({ show: false, overflowY: "auto" });
      builder.rerunComponent();
    },
    footer: [
      <button
        type="button"
        className="btn btn-link border"
        onClick={() => {
          window["modal"].setState({ show: false, overflowY: "auto" });
        }}
      >
        Cancel
      </button>,
      <button
        type="button"
        className="btn btn-primary border"
        onClick={() => {
          Fun.submitFromModal("modalbody");
        }}
      >
        Save
      </button>,
    ],
    show: true,
    body: (
      <Builder
        module="unipolls"
        view="add"
        key={Fun.uuid()}
        ref={(refComponent) => {
          window["modalbody"] = refComponent;
        }}
      ></Builder>
    ),
  });
};

const UniPolls = {
  auth: "",
  views: {
    list: {
      auth: "",
      apis: {
        view: {
          url: "/streams/{id}/pollEvents?start={start}&offset={offset}",
          parameters: {
            replace: {
              start: 0,
              offset: 25,
              id: function () {
                return Fun.getURLPathValue(2);
              },
            },
          },
          formatter: function (data) {
            return Formatter(data);
          },
        },
      },
      template: "grid",
      templateConfig: {
        headerTitle: "Polls",
        headerTitleIcon: "/uni/svg/trainings/go_back.svg",
        colsTemplateCount: 12,
        header: UniFun.commonHeader,
        headerSubtitle: function () {
          return (
            <ul className="nav" style={{ paddingLeft: "38px" }}>
              <li className="nav-link">
                <Link to={"/conference/" + Fun.getURLPathValue(2) + "/polls"}>
                  List
                </Link>
              </li>
              <li className="nav-link">
                <Link
                  to={
                    "/conference/" + Fun.getURLPathValue(2) + "/polls/results"
                  }
                  className="text-black text-underline"
                >
                  Results
                </Link>
              </li>
            </ul>
          );
        },
        gridConfig: {
          headers: {
            title: {
              attributes: {
                className: "w-50",
              },
            },
          },
          cellTemplates: {
            title: function (data, rec, obj) {
              let canEdit = UniFun.userIsEligible({ forWhat: "default" })
                ? true
                : false;
              let uid = Fun.uuid();
              return (
                <React.Fragment>
                  {UniFun.substring(rec.title.value, 0, 70)}
                  <span className="float-right mr-4 grid-controls">
                    <i
                      class="fas fa-ellipsis-h pointer"
                      data-toggle="collapse"
                      data-target={"#" + uid}
                    ></i>
                    <div
                      className="position-absolute bg-white ml-4 mt-2 collapse"
                      id={uid}
                    >
                      <ul className="list-group " style={{ width: "200px" }}>
                        <li
                          className="list-group-item p-2 pl-3"
                          onClick={(e) =>
                            UniFun.createEventModal(
                              obj.props.builder,
                              rec._id.value,
                              "Edit poll",
                              "unipolls",
                              "detail",
                              "modal",
                              "lg",
                              "Update",
                              "",
                              {
                                replace: {
                                  streamId: function () {
                                    return Fun.getURLPathValue(2);
                                  },
                                  id: rec._id.value,
                                },
                              }
                            )
                          }
                        >
                          Edit
                        </li>
                        <li
                          className="list-group-item p-2 pl-3 text-danger"
                          onClick={(e) =>
                            UniFun.createEventModal(
                              obj.props.builder,
                              rec._id.value,
                              "Delete poll",
                              "unipolls",
                              "delete",
                              "modal",
                              "default",
                              "Delete",
                              "btn-danger"
                            )
                          }
                        >
                          Delete
                        </li>
                      </ul>
                    </div>
                  </span>
                </React.Fragment>
              );
            },
          },

          emptyFolder: function () {
            return (
              <div className="row mt-4 " style={{ height: "500px" }}>
                <div className="col-12 text-center">
                  <h5>
                    <strong>Polls are empty</strong>
                  </h5>
                  <p>Added polls will appear here</p>
                </div>
              </div>
            );
          },
        },
        includeProperties: ["title", "createdAt"],
        cardTemplateTop: function () {
          let data = this.state.data;
          let parentBuilder = this.props.builder;
          let pagination = this.state.rawData.pagination;
          return (
            <div className="row">
              <div className="col-6 pt-2 pl-4">
                <span className="text-dark ">
                  There are {data.length} polls
                </span>
              </div>
              <div className="col-6 text-right p-2 pr-4">
                <button
                  type="button"
                  className="btn btn-primary bg-navy-blue"
                  onClick={(e) => add(parentBuilder)}
                >
                  Create poll
                </button>
              </div>
            </div>
          );
        },
      },
      pk: "id",
      properties: Properties({ editable: false }),
    },
    detail: {
      auth: "",
      apis: {
        view: {
          url: "/streams/{streamId}/pollEvents/{id}",
          parameters: {
            replace: {},
          },
          formatter: function (data) {
            return Formatter(data);
          },
        },
        update: {
          url: "/streams/{streamId}/pollEvent ",
          parameters: {
            replace: {},
          },
          formatter: function (data) {
            return Formatter(data);
          },
        },
      },
      template: "form",
      templateConfig: {
        excludeProperties: ["logo", "createdAt"],
        events: {
          onSubmit: function (e) {
            let submittedData = Fun.getSubmitJSON(e);

            submittedData["questions"][0].answers.forEach((itm, idx) => {
              if (!itm._id) {
                delete itm._id;
              }
            });

            let builder = this.props.builder;

            Fun.update(e, {
              url: builder.state.module.apis.update,
              method: "PUT",
              parameters: {
                replace: {
                  streamId: function () {
                    return Fun.getURLPathValue(2);
                  },
                },
                headers: {
                  "content-type": "application/json",
                },
              },
              callback: function (
                submittedData,
                status,
                responsedata,
                responsestatus
              ) {
                if (responsestatus == 200) {
                  if (window["modal"].state.callback) {
                    window["modal"].state.callback(submittedData);
                  }
                } else {
                  window["modal"].setState({ show: false, overflowY: "auto" });
                  let message = responsedata.data.message;

                  if (responsedata.data.exceptions) {
                    if (responsedata.data.exceptions[0]) {
                      message =
                        responsedata.data.exceptions[0].errorDescription;
                    }
                  }

                  Fun.createNotification({
                    message: message,
                    type: "danger",
                  });
                }
              },
              submittedData: JSON.stringify(submittedData),
            });
          },
        },
      },
      pk: "id",
      properties: Properties({ editable: true }),
    },
    delete: {
      auth: "",
      apis: {
        view: {
          url: " /streams/{streamId}/pollEvents/{id}",
          parameters: {
            replace: {
              streamId: function () {
                return Fun.getURLPathValue(2);
              },
            },
          },
        },
        delete: {
          url: "/streams/{streamId}/pollEvents/{id}",
          parameters: {
            replace: {
              streamId: function () {
                return Fun.getURLPathValue(2);
              },
            },
          },
          formatter: function (data) {
            return Formatter(data);
          },
        },
      },
      template: "form",
      templateConfig: {
        includeProperties: ["_id"],
        useCard: false,
        events: {
          onSubmit: function (e) {
            let submittedData = Fun.getSubmitJSON(e);

            let builder = this.props.builder;

            Fun.update(e, {
              url: builder.state.module.apis.delete,
              method: "DELETE",
              parameters: {
                replace: {
                  id: submittedData["_id"],
                  streamId: function () {
                    return Fun.getURLPathValue(2);
                  },
                },
                headers: {
                  "content-type": "application/json",
                },
              },
              callback: function (
                submittedData,
                status,
                responsedata,
                responsestatus
              ) {
                if (responsestatus == 200) {
                  if (window["modal"].state.callback) {
                    window["modal"].state.callback(submittedData);
                  }
                } else {
                  window["modal"].setState({ show: false, overflowY: "auto" });
                  let message = responsedata.data.message;

                  if (responsedata.data.exceptions) {
                    if (responsedata.data.exceptions[0]) {
                      message =
                        responsedata.data.exceptions[0].errorDescription;
                    }
                  }

                  Fun.createNotification({
                    message: message,
                    type: "danger",
                  });
                }
              },
              submittedData: submittedData,
            });
          },
        },
        templateTop: function () {
          let data = this.state.data[0];
          return (
            <div className=" p-4">
              <p>
                Are you sure you want to delete poll{" "}
                <strong>"{data.title.value}"</strong>
              </p>
            </div>
          );
        },
        colsTemplateCount: 10,
      },
      pk: "id",
      properties: Properties({ editable: false }),
    },
    add: {
      auth: "",
      apis: {
        view: {
          data: { data: [{ questions: [{}] }] },
          parameters: {
            replace: {},
          },
          formatter: function (data) {
            return Formatter(data);
          },
        },
        save: {
          url: "/streams/{streamId}/pollEvent",
          parameters: {
            replace: {},
          },
          formatter: function (data) {
            return Formatter(data);
          },
        },
      },
      template: "form",
      templateConfig: {
        excludeProperties: ["_id"],
        events: {
          onSubmit: function (e) {
            let submittedData = Fun.getSubmitJSON(e);

            submittedData["questions"][0].answers.forEach((itm, idx) => {
              delete itm._id;
            });

            delete submittedData["questions"][0]._id;

            let builder = this.props.builder;

            Fun.update(e, {
              url: builder.state.module.apis.save,
              method: "POST",
              parameters: {
                replace: {
                  streamId: function () {
                    return Fun.getURLPathValue(2);
                  },
                },
                headers: {
                  "content-type": "application/json",
                },
              },
              callback: function (
                submittedData,
                status,
                responsedata,
                responsestatus
              ) {
                if (responsestatus == 200) {
                  if (window["modal"].state.callback) {
                    window["modal"].state.callback(submittedData);
                  }
                } else {
                  window["modal"].setState({ show: false, overflowY: "auto" });
                  let message = responsedata.data.message;

                  if (responsedata.data.exceptions) {
                    if (responsedata.data.exceptions[0]) {
                      message =
                        responsedata.data.exceptions[0].errorDescription;
                    }
                  }

                  Fun.createNotification({
                    message: message,
                    type: "danger",
                  });
                }
              },
              submittedData: submittedData,
            });
          },
        },
      },
      pk: "id",
      properties: Properties({ editable: true }),
    },
  },
};

export default UniPolls;

function Properties(args) {
  let editable = args.editable;

  return {
    _id: {
      alias: "_id",
      control: "hidden",
      editable: editable,
      label: "",
      attributes: {
        className: "form-control",
      },
    },
    title: {
      alias: "title",
      control: "text",
      editable: editable,
      label: "Name",
      attributes: {
        className: "form-control",
      },
    },
    questions: {
      alias: "questions",
      control: "multiple",
      label: "Questions",
      customLayout: function (args) {
        let controls = args.control;

        let output = [];

        controls.map((itm, idx) => {
          output.push(
            <div className="pt-2">
              <strong>Ask a question </strong>
              <div className="row pt-3">
                <div className="col-12 ">
                  {itm[0].props.control.component}
                  {itm[1].props.control.component}
                  {itm[2].props.control.component}
                  {itm[3].props.control.component}
                </div>
              </div>

              {/* {itm[4].props.control.component} */}
            </div>
          );
        });

        let _this = this;

        return <React.Fragment>{output}</React.Fragment>;
      },
      useCustomLayout: true,
      config: {
        addNewLabel: "+ Add mulitple choice",
        properties: {
          _id: {
            alias: "_id",
            control: "hidden",
            label: "",
            editable: editable,
          },
          type: {
            alias: "type",
            control: "hidden",
            label: "",
            defaultValue: "multipleChoice",
            editable: editable,
          },
          question: {
            alias: "question",
            control: "editor",
            label: "Question",
            placeholder: "Type a question",
            editable: editable,
            attributes: {
              className: "form-control",
            },
          },
          answers: {
            alias: "answers",
            control: "multiple",
            editable: editable,
            customLayout: function (args) {
              let output = [];

              if (args.control.length <= 1) {
                this.addNew();
                this.addNew();
              }

              args.control.map((itm, idx) => {
                let addNewBtn = "";

                if (idx == args.control.length - 1) {
                  addNewBtn = (
                    <button
                      type="button"
                      className="btn btn-link text-dark"
                      onClick={this.addNew}
                    >
                      <i className="fas fa-plus"></i>
                    </button>
                  );
                }

                output.push(
                  <div className="row pt-3">
                    <div className="col-10">
                      {itm[0].props.control.component}
                      {itm[1].props.control.component}
                    </div>

                    <div className="col-2">
                      <button
                        type="button"
                        className="btn btn-link text-secondary"
                        onClick={(e) => this.deleteControl(idx)}
                      >
                        <i className="fas fa-times"></i>
                      </button>
                      {addNewBtn}
                    </div>
                  </div>
                );
              });

              return (
                <React.Fragment>
                  {output}
                  <div className="pt-5"></div>
                </React.Fragment>
              );
            },
            useCustomLayout: true,
            config: {
              addNewLabel: "+ Add answer",
              properties: {
                _id: {
                  alias: "_id",
                  control: "hidden",
                  label: "",
                  editable: editable,
                },
                answer: {
                  alias: "answer",
                  control: "text",
                  placeholder: "Type option",
                  editable: editable,
                  attributes: {
                    className: "form-control",
                  },
                },
              },
            },
          },
        },
      },
    },
    createdAt: {
      alias: "createdAt",
      control: "datetime",
      label: "Date created",
      config: {
        toFormat: "ddd DD/MM/YYYY HH:mm",
      },
      editable: false,
      attributes: {
        className: "form-control",
      },
    },
  };
}

function Formatter(data) {
  if (data) {
    let formattedData = [];
    if (Array.isArray(data.data)) {
      data.data.map((itm, idx) => {
        let formattedRecord = {};
        formattedRecord["_id"] = itm._id;
        formattedRecord["questions"] = itm.questions;
        formattedRecord["title"] = itm.title;
        formattedRecord["createdAt"] = itm.createdAt;

        formattedData.push(formattedRecord);
      });
    }

    return formattedData;
  }
}
