import React, { Component } from "react";
import Fun from "../../../lib/functions";
import UniFun from "../functions";
import TopTemplateHeader from "../../../themes/default/templates/topTemplateHeader";
import Builder from "../../../lib/builder";
import moment from "moment";
import { Link } from "react-router-dom";

import Control from "../../../themes/default/templates/control";

const deleteMettingEvent = function (eventId, parentBuilder) {
  let parameters = {
    replace: {
      id: eventId,
    },
  };

  let builder = parentBuilder;

  window["modal2"].setState({
    title: <h3>Delete meeting?</h3>,
    size: "lg",
    targetComponent: this,
    show: true,
    callback: function () {
      window["modal2"].setState({ show: false, overflowY: "auto" });
      window.location.replace("/agenda");
    },
    footer: UniFun.modalCommonFooter("modal2", "Delete", "btn-danger"),
    body: (
      <Builder
        module="unimeetingevent"
        view="deleteEvent"
        parameters={parameters}
        ref={(refComponent) => {
          window["modal2body"] = refComponent;
        }}
      ></Builder>
    ),
  });
};

const eventMeetingHeader = function () {
  let data = this.state.data[0];

  let organizerId = "";

  if (data.organizer) {
    if (data.organizer.value) {
      if (data.organizer.value[0]) {
        organizerId = data.organizer.value[0]._id;
      }
    }
  }
  return (
    <div className={"uniTopHeader "}>
      <TopTemplateHeader
        customClass="mb-4 uniFixedHeader"
        title={
          <React.Fragment>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              style={{ paddingBottom: "5px" }}
              className="pr-3"
              width="39"
              height="39"
              viewBox="0 0 176 176"
            >
              <path
                fill="#744fa2"
                fill-rule="evenodd"
                d="M55 0h66c30.35532635 0 55 24.64467365 55 55v66c0 30.35532635-24.64467365 55-55 55H55c-30.35532635 0-55-24.64467365-55-55V55C0 24.64467365 24.64467365 0 55 0z"
              />
            </svg>{" "}
            <span title={data.title.value}>
              {UniFun.substring(data.title.value, 0, 25)}
            </span>
            <div style={{ display: "inline-block" }}>
              {" "}
              {UniFun.userIsEligible({
                forWhat: "meeting",
                organizerId: organizerId,
              })
                ? data.editdropdown.component
                : ""}
            </div>
          </React.Fragment>
        }
      ></TopTemplateHeader>
    </div>
  );
};

const UniMeetingEvent = {
  auth: "",
  views: {
    previewevent: {
      auth: "",
      apis: {
        view: {
          url: "/meeting/{id}",
          parameters: {
            replace: {},
            headers: {},
          },
          formatter: function (args) {
            return UniMeetingEventFormatter(args);
          },
        },
      },
      template: "form",
      templateConfig: {
        useCard: false,
        events: {
          onSubmit: function (e) {
            e.preventDefault();
          },
        },
        header: eventMeetingHeader,
        templateBottom: function () {
          let data = this.state.data[0];
          return (
            <React.Fragment>
              <div>
                <h5 className="pb-1 text-black">
                  <strong>General information</strong>
                </h5>
                <div className="row ">
                  <div className="col-12">
                    <div className="card " key={Fun.uuid()}>
                      <div className="card-body">
                        <div className=" session-sections">
                          <div className="pt-4 session-section session-row pointer">
                            <div className="mb-3 row">
                              <div
                                className="col-md-10"
                                style={{ lineHeight: "39px" }}
                              >
                                <img
                                  src="/uni/svg/datetime.svg"
                                  className="mr-2 mb-1 filter-light-grey"
                                  style={{ width: "20px" }}
                                />
                                {UniFun.fromToDateString(
                                  data.from.value,
                                  data.to.value
                                )}
                              </div>
                              <div className="col-md-2 text-center">
                                <span className="grid-controls">
                                  {UniFun.meetingIsAvailable(
                                    data.from.value,
                                    data.to.value
                                  ) ? (
                                    <a
                                      href={"/meeting/m-" + data._id.value}
                                      target="new"
                                      className="btn btn-primary "
                                    >
                                      Join
                                    </a>
                                  ) : (
                                    <button
                                      type="button"
                                      className="btn btn-primary disabled action-btn"
                                      data-event-start={moment(
                                        data.from.value
                                      ).valueOf()}
                                      disabled
                                      data-event-url={
                                        "/meeting/m-" + data._id.value
                                      }
                                    >
                                      Join
                                    </button>
                                  )}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="pt-4 session-section  session-row pointer ">
                            <div className="mb-3 row pb-2">
                              <div
                                className="col-md-10"
                                style={{ lineHeight: "39px" }}
                              >
                                <img
                                  src="/uni/svg/meeting_participants.svg"
                                  className="mr-2 mb-1 filter-light-grey"
                                  style={{ width: "20px" }}
                                />
                                {data.participants.value
                                  ? data.participants.value.length
                                  : "0"}{" "}
                                participants
                              </div>
                              <div className="col-md-2 text-center">
                                <span className="grid-controls">
                                  <Link
                                    to={
                                      "/event/meeting/" +
                                      data._id.value +
                                      "/participants"
                                    }
                                    className="btn btn-secondary border"
                                  >
                                    View
                                  </Link>
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="pt-4 session-section  session-row pointer pb-2">
                            <div className="mb-3 row">
                              <div
                                className="col-md-10 pb-2"
                                style={{ lineHeight: "39px" }}
                              >
                                <img
                                  src="/uni/svg/graded_practicetest.svg"
                                  className="mr-2 mb-1 filter-light-grey"
                                  style={{ width: "20px" }}
                                />
                                {data.files.value
                                  ? data.files.value.length
                                  : "0"}{" "}
                                files included
                              </div>
                              <div className="col-md-2 text-center">
                                <span className="grid-controls">
                                  <Link
                                    to={
                                      "/event/meeting/" +
                                      data._id.value +
                                      "/files"
                                    }
                                    className="btn btn-secondary border"
                                  >
                                    View
                                  </Link>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <h5 className="pb-1 text-black">
                  <strong>About</strong>
                </h5>
                <div className="row ">
                  <div className="col-12">
                    <div className="card " key={Fun.uuid()}>
                      <div className="card-body">
                        {data.description.component}
                        <h5 className="text-dark mt-4 mb-4">Organizer</h5>
                        {UniFun.createProfileImageBubble(
                          data.organizer.value.concat(data.organizersRaw.value)
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </React.Fragment>
          );
        },
        colsTemplateCount: 12,
        includeProperties: ["_id"],
      },
      pk: "_id",
      properties: UniMeetingEventProperties({ editable: false }),
    },
    meetingFiles: {
      auth: "",
      apis: {
        view: {
          url: "/meeting/{id}",
          parameters: {
            replace: {},
            headers: {},
          },
          formatter: function (args) {
            return UniMeetingEventFormatter(args);
          },
        },
      },
      template: "form",
      templateConfig: {
        useCard: false,
        events: {
          onSubmit: function (e) {
            e.preventDefault();
          },
        },
        header: eventMeetingHeader,
        colsTemplateCount: 12,
        includeProperties: ["meetingFiles"],
      },
      pk: "_id",
      properties: UniMeetingEventProperties({ editable: false }),
    },
    meetingParticipants: {
      auth: "",
      apis: {
        view: {
          url: "/meeting/{id}",
          parameters: {
            replace: {},
            headers: {},
          },
          formatter: function (args) {
            return UniMeetingEventFormatter(args);
          },
        },
      },
      template: "form",
      templateConfig: {
        useCard: false,
        events: {
          onSubmit: function (e) {
            e.preventDefault();
          },
        },
        header: eventMeetingHeader,
        colsTemplateCount: 12,
        includeProperties: ["meetingParticipants"],
      },
      pk: "_id",
      properties: UniMeetingEventProperties({ editable: false }),
    },
    editEvent: {
      auth: "",
      apis: {
        view: {
          url: "/meeting/{id}",
          parameters: {
            headers: {},
            replace: {},
          },
          formatter: function (data) {
            return UniMeetingEventFormatter(data);
          },
        },
        update: {
          url: "/meeting",
          parameters: {
            headers: {
              "Content-Type": "application/json",
            },
            replace: {},
          },
          formatter: function (data) {
            return UniMeetingEventFormatter(data);
          },
        },
      },
      template: "form",
      templateConfig: {
        events: {
          onSubmit: function (e) {
            e.preventDefault();
            let submittedData = Fun.getSubmitJSON(e);
            let from = UniFun.fromToDateFormat(
              submittedData.fromToDateTime[0].fromDateTime[0].fromDate,
              submittedData.fromToDateTime[0].fromDateTime[0].fromTime
            );

            let to = UniFun.fromToDateFormat(
              submittedData.fromToDateTime[0].toDateTime[0].toDate,
              submittedData.fromToDateTime[0].toDateTime[0].toTime
            );

            if (
              Fun.validate(
                this.props.builder.validator,
                Fun.formDataToJSON(e)
              ) &&
              UniFun.fromToValidation(from, to)
            ) {
              let duration = moment
                .duration(moment(to).diff(moment(from)))
                .asMinutes();

              let organizers = [];
              try {
                JSON.parse(submittedData.organizers)?.forEach((itm, idx) => {
                  organizers.push(itm._id);
                });
              } catch (e) {}
              submittedData["organizers"] = organizers;

              let jsonData = {
                _id: submittedData._id,
                title: submittedData.title,
                from: from,
                to: to,
                options: submittedData.options[0],
                organizers: organizers,
                // options: {
                //   allowCameraForParticipants:
                //     submittedData.options[0].allowCameraForParticipants == "true"
                //       ? true
                //       : false,
                //   recording:
                //     submittedData.options[0].recording == "true" ? true : false,
                //   recordingMethod: submittedData.options[0].recordingMethod,
                // },
                files: submittedData["files"]
                  ? UniFun.agendaFilesJson(JSON.parse(submittedData["files"]))
                  : [],
                description: submittedData.description,
                // participants: UniFun.participantsJSONData(
                //   JSON.parse(submittedData.participants)
                // ),
                reminders: [JSON.parse(submittedData.reminders)],
                duration: duration,
              };

              let builder = this.props.builder;

              Fun.update(e, {
                url: builder.state.module.apis.update,
                method: "PATCH",
                callback: function (
                  submittedData,
                  status,
                  responsedata,
                  responsestatus
                ) {
                  if (responsestatus == "200") {
                    if (window["modal2"].state.callback) {
                      window["modal2"].state.callback(submittedData);
                    } else {
                      if (window["modal2"].state.targetComponent) {
                        window["modal2"].state.targetComponent.setState({
                          data: submittedData,
                        });
                      }
                    }
                  } else {
                    if (responsedata.data.exceptions) {
                      responsedata.data.exceptions.map((itm, idx) => {
                        Fun.createNotification({
                          message: itm.errorDescription,
                          type: "danger",
                        });
                      });
                    }
                  }
                },
                parameters: {
                  headers: {
                    "content-type": "application/json",
                  },
                },
                submittedData: JSON.stringify(jsonData),
              });
            }
          },
        },
        colsTemplateCount: 12,
        includeProperties: [
          "_id",
          "title",
          "fromToDateTime",
          "organizers",
          // "participants",
          "description",
          // "files",
          "reminders",
          "options",
        ],
      },
      pk: "_id",
      properties: UniMeetingEventProperties({ editable: true }),
    },
    deleteEvent: {
      auth: "",
      apis: {
        view: {
          url: "/meeting/{id}",
          parameters: {
            headers: {},
            replace: {},
          },
          formatter: function (data) {
            return UniMeetingEventFormatter(data);
          },
        },
        delete: {
          url: "/meeting/{id}",
          parameters: {
            headers: {},
            replace: {},
          },
          formatter: function (data) {
            return UniMeetingEventFormatter(data);
          },
        },
      },
      template: "form",
      templateConfig: {
        useCard: false,
        colsTemplateCount: 12,
        includeProperties: ["_id"],
        excludeProperties: [],
        events: {
          onSubmit: function (e) {
            let submittedData = Fun.getSubmitJSON(e);

            let builder = this.props.builder;

            Fun.update(e, {
              url: builder.state.module.apis.delete,
              method: "DELETE",
              parameters: {
                headers: {
                  "content-type": "application/json",
                },
                replace: {
                  id: submittedData["_id"],
                },
              },
              callback: function (args) {
                if (window["modal2"].state.callback) {
                  window["modal2"].state.callback(submittedData);
                }
              },
              submittedData: JSON.stringify([]),
            });
          },
        },
        templateTop: function () {
          let data = this.state.data[0];
          return (
            <div className=" p-4">
              <p>
                Are you sure you want to delete meeting "
                <strong>{data.title.value}</strong>"?<br></br>
              </p>
            </div>
          );
        },
      },
      pk: "_id",
      properties: UniMeetingEventProperties({ editable: false }),
    },
  },
};

function UniMeetingEventProperties(input) {
  let editable = input.editable;

  return {
    _id: {
      alias: "_id",
      control: "hidden",
      label: "",
      editable: editable,
      apiPath: "",
      auth: "",
      validations: "",
      attributes: {},
    },
    title: {
      alias: "title",
      label: "Title",
      control: "text",
      placeholder: "Type the title of the meeting",
      editable: editable,
      apiPath: "",
      auth: "",
      validations: "required",
      attributes: {
        className: "form-control",
      },
    },
    fromToDateTime: {
      alias: "fromToDateTime",
      control: "group",
      label: "Availability",
      editable: editable,
      customLayout: function (args) {
        return (
          <React.Fragment>
            <label>{args.object.props.field.label}</label>
            <div className="row mb-3">
              <div className="col-md-5 pr-0 availability-calendar">
                {args.control[0]}
              </div>
              <div className="col-md-1 text-center pr-0 pt-2">to</div>
              <div className="col-md-5 pl-0 availability-calendar">
                {args.control[1]}
              </div>
              <div className="col-md-1"></div>
            </div>
          </React.Fragment>
        );
      },
      useCustomLayout: true,
      config: {
        properties: {
          fromDateTime: {
            alias: "fromDateTime",
            control: "group",
            editable: editable,
            customLayout: function (args) {
              return (
                <React.Fragment>
                  <div className="row mb-3">
                    <div className="col-md-8 pr-0">{args.control[0]}</div>
                    <div className="col-md-4 pr-0">{args.control[1]}</div>
                  </div>
                </React.Fragment>
              );
            },
            useCustomLayout: true,
            config: {
              properties: {
                fromDate: {
                  alias: "fromDate",
                  control: "datetime",
                  config: {
                    fromFormat: "YYYY-MM-DDT00:00:00.000Z",
                    toFormat: "YYYY-MM-DDT00:00:00.000Z",
                    includeTime: false,
                    valueEditFormat: { dateStyle: "short" },
                    valueDisplayFormat: { dateStyle: "short" },
                  },
                  editable: editable,
                },
                fromTime: {
                  alias: "fromTime",
                  control: "select",
                  config: {
                    options: UniFun.timeSelect,
                  },
                  editable: editable,
                  attributes: {
                    className: "form-control",
                  },
                },
              },
            },
          },
          toDateTime: {
            alias: "toDateTime",
            control: "group",
            editable: editable,
            customLayout: function (args) {
              return (
                <React.Fragment>
                  <div className="row mb-3">
                    <div className="col-md-8 pr-0">{args.control[0]}</div>
                    <div className="col-md-4 pr-0">{args.control[1]}</div>
                  </div>
                </React.Fragment>
              );
            },
            useCustomLayout: true,
            config: {
              properties: {
                toDate: {
                  alias: "toDate",
                  control: "datetime",
                  config: {
                    fromFormat: "YYYY-MM-DDT00:00:00.000Z",
                    toFormat: "YYYY-MM-DDT00:00:00.000Z",
                    includeTime: false,
                    valueEditFormat: { dateStyle: "short" },
                    valueDisplayFormat: { dateStyle: "short" },
                  },
                  editable: editable,
                },
                toTime: {
                  alias: "toTime",
                  control: "select",
                  config: {
                    options: UniFun.timeSelect,
                  },
                  editable: editable,
                  attributes: {
                    className: "form-control",
                  },
                },
              },
            },
          },
        },
      },
    },
    instructors: {
      alias: "instructors",
      label: "Instructors",
      placeholder: "Select the instructors of the meeting",
      control: "tags",
      editable: editable,
      config: {
        idAttribute: "_id",
        nameAttribute: "name",
        apis: {
          search: {
            url: "/user/autocomplete/{query}",
            formatter: function (args) {
              let formattedData = [];
              if (args) {
                let data = args.data;
                data.map((itm, idx) => {
                  let fullName =
                    itm["firstName"] +
                    " " +
                    itm["lastName"] +
                    " - " +
                    itm["email"];
                  if (itm.type == "group") {
                    fullName = itm["firstName"] + " - " + itm["lastName"];
                  }
                  formattedData.push({
                    _id: itm["_id"],
                    name: fullName,
                    group: itm.type,
                  });
                });
              }
              return formattedData;
            },
            parameters: {
              replace: {
                query: "",
              },
            },
          },
        },
      },
      attributes: {
        className: "form-control",
      },
    },
    participants: {
      alias: "participants",
      label: "Choose participants",
      placeholder: "Select the participants of the meeting",
      control: "tags",
      editable: editable,
      config: {
        idAttribute: "_id",
        nameAttribute: "name",
        apis: {
          search: {
            url: "/user/autocomplete/{query}?type=all",
            formatter: function (args, com) {
              let formattedData = [];
              if (args) {
                let data = args.data;
                data.map((itm, idx) => {
                  if (itm["_id"]) {
                    let fullName =
                      itm["firstName"] +
                      " " +
                      itm["lastName"] +
                      " - " +
                      itm["email"];
                    if (itm.type == "group") {
                      fullName = itm["firstName"] + " - " + itm["lastName"];
                    }
                    formattedData.push({
                      _id: itm["_id"],
                      name: fullName,
                      group: itm.type,
                    });
                  } else {
                    formattedData.push({ id: "", name: itm });
                  }
                });
              }
              return formattedData;
            },
            parameters: {
              replace: {
                query: "",
              },
            },
          },
        },
      },
      attributes: {
        className: "form-control",
      },
      events: {
        onAddition: function (tag) {
          if (!tag._id) {
            if (UniFun.isEmail(tag.name)) {
              tag["_id"] = tag.name;
              tag["name"] = tag.name;
              const tags = [].concat(this.state.tags, tag);
              this.setState({ tags });
            }
          } else {
            const tags = [].concat(this.state.tags, tag);
            this.setState({ tags });
          }
        },
      },
    },
    organizersRaw: {
      alias: "organizersRaw",
      label: "",
      control: "hidden",
    },
    organizers: {
      alias: "organizers",
      label: "Organizers", //"Select additional hosts (you are already a host)",
      placeholder: "Type email or name...",
      control: "tags",
      editable: editable,
      config: {
        idAttribute: "_id",
        nameAttribute: "name",
        apis: {
          search: {
            url: "/user/autocomplete/{query}?type=all",
            formatter: function (args) {
              let formattedData = [];
              if (args) {
                let data = args.data;
                if (data) {
                  data.map((itm, idx) => {
                    if (itm.type != "group") {
                      let fullName =
                        itm["firstName"] +
                        " " +
                        itm["lastName"] +
                        " - " +
                        itm["email"];

                      // fullName = itm["firstName"] + " - " + itm["lastName"];

                      formattedData.push({
                        _id: itm["_id"],
                        name: fullName,
                        group: itm.type,
                      });
                    }
                  });
                }
              }
              return formattedData;
            },
            parameters: {
              replace: {
                query: "",
              },
            },
          },
        },
      },
      attributes: {
        className: "form-control",
      },
    },
    description: {
      alias: "description",
      label: "Description",
      control: "editor",
      placeholder: "Write a description of the meeting",
      editable: editable,
      attributes: {
        className: "form-control",
      },
    },
    createdBy: {
      alias: "createdBy",
      label: "Organizer",
      control: "text",
      editable: editable,
      attributes: {
        className: "form-control",
      },
    },
    organizer: {
      alias: "organizer",
      label: "Organizer",
      control: "text",
      editable: editable,
      attributes: {
        className: "form-control",
      },
    },
    meetingFiles: {
      alias: "meetingFiles",
      control: "module",
      editable: editable,
      config: {
        module: "uniparticipantsandfilesevent",
        view: "files",
        moduleParams: { type: "meetings" },
        parameters: {
          replace: {
            path: function () {
              return "/meeting/" + Fun.getURLPathValue(3) + "";
            },
          },
        },
      },
    },

    meetingParticipants: {
      alias: "meetingParticipants",
      control: "module",
      editable: editable,
      config: {
        module: "uniparticipantsandfilesevent",
        view: "participants",
        moduleParams: { type: "meetings" },
        parameters: {
          replace: {
            path: function () {
              return "/meeting/" + Fun.getURLPathValue(3) + "/participants";
            },
          },
        },
      },
    },
    files: {
      alias: "files",
      control: "dropzone",
      label: "Attach files",
      editable: editable,
      customContentLayout: function () {
        let files = JSON.parse(this.state.defaultValue);

        let content = [];

        Object.keys(files).map((itm, idx) => {
          content.push(
            UniFun.trainingNodeFileUI(files[itm], this.removeFile, itm)
          );
        });

        this.state.content?.map((itm, idx) => {
          // if (typeof files[itm.key] === "undefined" && itm.ref != null) {
          //   content.push(itm);
          // }
          if (this.state.pending.indexOf(itm.key) >= 0) {
            content.push(itm);
          }
        });

        return (
          <React.Fragment>
            {this.state.placeholder()}
            {content}
            {/* {content.length > 0 ? "" : this.state.placeholder()} */}
          </React.Fragment>
        );
      },

      useCustomLayout: true,
      config: {
        placeholder: function () {
          return (
            <div className="p-3">
              <strong>
                Drag & drop here or{" "}
                <a
                  href="javascript:void(0);"
                  className="text-primary"
                  onClick={this.onTargetClick}
                >
                  <strong>browse file</strong>
                </a>{" "}
                to attach
              </strong>
              <br></br>
              <span className="text-dark">Maximum size: 50MB</span>
            </div>
          );
        },
      },
      events: {
        removeFile: function (id) {
          let defaultValue = {};

          Object.keys(JSON.parse(this.state.defaultValue)).map((itm, idx) => {
            if (itm != id) {
              defaultValue[itm] = JSON.parse(this.state.defaultValue)[itm];
            }
          });

          let content = [];
          this.state.content?.map((itm, idx) => {
            if (itm.key != id) {
              content.push(itm);
            }
          });

          this.setState({
            defaultValue: JSON.stringify(defaultValue),
            content: content,
          });

          return;
        },
        onDrop: function (files) {
          // this.setState({ defaultValue: JSON.stringify({}) });

          UniFun.fileProgress({
            control: this,
            files: files,
            url: "/agenda-file",
            type: "meeting",
          });
        },
      },
      attributes: {
        className: "border rounded  dropzone-trainings",
      },
    },
    reminders: {
      alias: "reminders",
      control: "select",
      config: {
        options: [
          {
            label: "5 minutes before",
            value: JSON.stringify({ type: "minutes", value: 5 }),
          },
          {
            label: "10 minutes before",
            value: JSON.stringify({ type: "minutes", value: 10 }),
          },
          {
            label: "30 minutes before",
            value: JSON.stringify({ type: "minutes", value: 30 }),
          },
          {
            label: "1 hour before",
            value: JSON.stringify({ type: "minutes", value: 60 }),
          },
          {
            label: "1 day before",
            value: JSON.stringify({ type: "days", value: 1 }),
          },
        ],
      },
      label: "Reminder",
      editable: editable,
      attributes: {
        className: "form-control",
      },
    },
    options: {
      alias: "options",
      label: <span className="d-block mb-2 text-strong">Options</span>,
      control: "group",
      config: {
        properties: {
          allowCameraForParticipants: {
            alias: "allowCameraForParticipants",
            label: "Allow camera for participants",
            control: "switch",
            editable: editable,
            config: {
              options: [
                { label: "Yes", value: true },
                { label: "No", value: false },
              ],
            },
            attributes: {
              className: "form-control",
            },
          },
          recording: {
            alias: "recording",
            label: "Record meeting",
            control: "switch",
            editable: editable,
            config: {
              options: [
                { label: "Yes", value: true },
                { label: "No", value: false },
              ],
            },
            useCustomLayout: true,
            customLayout: function (args) {
              if (localStorage.getItem("meetingRecording") == "true") {
                return (
                  <div className="form-group" style={{ marginBottom: "30px" }}>
                    <span>
                      <label className="text-dark">
                        {args.object.props.field.label}
                      </label>
                    </span>
                    {args.control}
                  </div>
                );
              }

              return "";
            },
            events: {
              onChange: function (checked) {
                if (checked) {
                  document
                    .getElementById("recordingMethod")
                    .classList.remove("d-none");
                } else {
                  document
                    .getElementById("recordingMethod")
                    .classList.add("d-none");
                }
              },
            },
            attributes: {
              className: "form-control",
            },
          },
          recordingMethod: {
            alias: "recordingMethod",
            label: "Select recording method",
            control: "select",
            editable: editable,
            useCustomLayout: true,
            customLayout: function (args) {
              if (localStorage.getItem("meetingRecording")) {
                let classHidden = "";

                if (!args.object.props.data.options.recording) {
                  classHidden = "d-none";
                }
                return (
                  <div
                    className={"form-group " + classHidden}
                    style={{ marginBottom: "30px" }}
                    id="recordingMethod"
                  >
                    <span>
                      <label className="text-dark">
                        {args.object.props.field.label}
                      </label>
                    </span>
                    {args.control}
                  </div>
                );
              }

              return "";
            },
            config: {
              options: function () {
                let control = this;

                Fun.fetch({
                  url: {
                    url: "/recordingMethods",
                    parameters: {
                      replace: {},
                      headers: {},
                    },
                  },
                  callback: function (args) {
                    let output = [];

                    let data = args.data;

                    data.map((itm, idx) => {
                      output.push({
                        label: itm.label,
                        value: itm.value,
                      });
                    });

                    control.setState({ options: output });
                  },
                });

                return [];
              },
            },
            attributes: {
              className: "form-control",
            },
          },
        },
      },
    },
    editdropdown: {
      alias: "editdropdown",
      control: "dropdownbutton",
      caption: (
        <img src="/uni/svg/more-options.svg" style={{ width: "14px" }}></img>
      ),
      editable: true,
      config: {
        removeToggleIcon: true,
        options: {
          edit: {
            label: "Edit",
            events: {
              onClick: function () {
                let builder = this.props.builder;

                let params = {
                  replace: {
                    id: this.props.value,
                  },
                };
                window["modal2"].setState({
                  title: <h3>Edit Meeting</h3>,
                  size: "xl",
                  targetComponent: this,
                  footer: UniFun.modalCommonFooter("modal2", "Update"),
                  callback: function () {
                    window["modal2"].setState({
                      show: false,
                      overflowY: "auto",
                    });
                    builder.rerunComponent();
                  },
                  show: true,
                  body: (
                    <Builder
                      module="unimeetingevent"
                      view="editEvent"
                      parameters={params}
                      ref={(refComponent) => {
                        window["modal2body"] = refComponent;
                      }}
                    ></Builder>
                  ),
                });
              },
            },
          },
          delete: {
            label: <span className="text-danger">Delete</span>,
            events: {
              onClick: function () {
                let builder = this.props.builder;
                deleteMettingEvent(this.props.value, builder);
              },
            },
          },
        },
      },
      attributes: {
        className: "btn btn-link m-2 ",
      },
    },
    from: {
      alias: "from",
      label: "",
      control: "text",
      editable: editable,
      attributes: {
        className: "form-control",
      },
    },
    to: {
      alias: "to",
      label: "",
      control: "text",
      editable: editable,
      attributes: {
        className: "form-control",
      },
    },
  };
}

function UniMeetingEventFormatter(data) {
  if (data) {
    let formattedData = [];

    data?.data?.map((itm, idx) => {
      let formattedRecord = {};

      formattedRecord["_id"] = itm._id;
      formattedRecord["editdropdown"] = itm._id;

      formattedRecord["title"] = itm.title;
      formattedRecord["from"] = itm.from;
      formattedRecord["to"] = itm.to;
      formattedRecord["options"] = {};
      try {
        formattedRecord["options"] = itm.options;
      } catch (e) {}
      formattedRecord["participants"] = UniFun.participantsFormatter(
        itm.participants
      );

      formattedRecord["organizersRaw"] = itm.organizers ? itm.organizers : [];

      formattedRecord["organizers"] = UniFun.participantsFormatter(
        itm.organizers
      );

      const start = moment();
      const halfhour = 30 - (start.minute() % 30);
      const dateFromTime = moment(start)
        .add(halfhour, "minutes")
        .format("HH:mm:00");
      const dateToTime = moment(start)
        .add(halfhour, "minutes")
        .add("3", "hours")
        .format("HH:mm:00");

      let fromTime = dateFromTime;
      if (itm.from) {
        fromTime = moment(itm.from).format("HH:mm:00");
      }
      let toTime = dateToTime;
      if (itm.to) {
        toTime = moment(itm.to).format("HH:mm:00");
      }

      if (!itm.from && !itm.to) {
        if (
          moment(start)
            .add(halfhour, "minutes")
            .isBefore(moment(start).add(halfhour, "minutes").add("3", "hours"))
        ) {
          itm.to = moment(start)
            .add(halfhour, "minutes")
            .add("3", "hours")
            .add("1", "days");
        }
      }

      formattedRecord["fromToDateTime"] = {
        fromDateTime: {
          fromDate: itm.from,
          fromTime: fromTime,
        },
        toDateTime: {
          toDate: itm.to,
          toTime: toTime,
        },
      };

      // "from": "2021-07-17T04:30:00.000Z",
      //      "to": "2021-07-17T04:30:00.000Z",
      formattedRecord["description"] = itm.description;
      formattedRecord["files"] = itm.files;
      formattedRecord["reminders"] = itm.reminders
        ? JSON.stringify({
            type: itm.reminders[0].type,
            value: itm.reminders[0].value,
          })
        : {};
      formattedRecord["createdBy"] = [itm.createdBy];
      formattedRecord["organizer"] = [itm.organizer];

      formattedData.push(formattedRecord);
    });

    return formattedData;
  }
}

export default UniMeetingEvent;
