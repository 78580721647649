import Spaces from "./uni/spaces";
import UniMeetings from "./uni/meetings";
import UniAgenda from "./uni/agenda";
import UniLeftMenu from "./uni/leftmenu";
import UniTopMenu from "./uni/topmenu";
import UniFiles from "./uni/files";
import UniTrainingTemplates from "./uni/trainingTemplates";
import UniVideoConference from "./uni/trainingTemplates/videoconference";
import UniVideoPresentation from "./uni/trainingTemplates/videopresentation";
import UniTestTemplates from "./uni/testTemplates";
import UniTrainingTests from "./uni/trainingTemplates/tests";
import UniReadings from "./uni/trainingTemplates/readings";
import UniTrainingEvents from "./uni/events/trainingEvents";
import UniUsers from "./uni/users";
import UniTakeTest from "./uni/taketest";
import Upload from "./upload";
import UniUpload from "./uni/upload";
import UniLogin from "./uni/login";
import UniTestEvent from "./uni/events/testEvents";
import UniMeetingEvent from "./uni/events/meetingEvent";
import UniParticipantsAndFilesEvent from "./uni/events/participantsAndFilesEvent";
import UniDashboard from "./uni/dashboard";
import UniStreamEvent from "./uni/events/streamEvent";
import UniStreams from "./uni/streams";
import UniTestResults from "./uni/testresults";
import UniConferences from "./uni/events/conferences";
import UniEvents from "./uni/events/events";
import UniSponsors from "./uni/sponsors";
import UniPolls from "./uni/polls";
import UniForms from "./uni/uniforms";

const Modules = {
  upload: Upload,
  spaces: Spaces,
  uniagenda: UniAgenda,
  uniforms: UniForms,
  unileftmenu: UniLeftMenu,
  unitopmenu: UniTopMenu,
  unitesttemplates: UniTestTemplates,
  unitrainingtemplates: UniTrainingTemplates,
  univideoconference: UniVideoConference,
  univideopresentation: UniVideoPresentation,
  unireadings: UniReadings,
  unitestevent: UniTestEvent,
  unifiles: UniFiles,
  unimeetings: UniMeetings,
  unistreams: UniStreams,
  uniusers: UniUsers,
  unitaketest: UniTakeTest,
  unimeetingevent: UniMeetingEvent,
  uniparticipantsandfilesevent: UniParticipantsAndFilesEvent,
  uniupload: UniUpload,
  unitrainingtests: UniTrainingTests,
  unitrainingevents: UniTrainingEvents,
  unilogin: UniLogin,
  unidashboard: UniDashboard,
  unistreamevent: UniStreamEvent,
  unitestresults: UniTestResults,
  uniconferences: UniConferences,
  unievents: UniEvents,
  unisponsors: UniSponsors,
  unipolls: UniPolls,
};

export default Modules;
