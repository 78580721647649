import React, { Component } from "react";
import Fun from "../../lib/functions";
import UniFun from "./functions";

const UniTopMenu = {
  auth: "",
  views: {
    list: {
      auth: "",
      apis: {
        view: {
          data: {
            data: [
              {
                id: "1",
                title: "Dashboard",
                url: "/dashboard",
              },
              {
                id: "2",
                title: "Spaces",
                module: "spaces",
                view: "list",
                url: "/spaces",
                // auth: function () {
                //   return (
                //     UniFun.userIsEligible({ forWhat: "viewer" }) ||
                //     UniFun.userIsEligible({ forWhat: "member" })
                //   );
                // },
              },
              {
                id: "3",
                title: "Agenda",
                url: "/agenda",
              },
              {
                id: "4",
                title: "Contacts",
                url: "/contacts",
                // auth: function () {
                //   return UniFun.userIsEligible({ forWhat: "module" });
                // },
              },
              {
                id: "5",
                title: "Library",
                url: "/files",
              },
              // {
              //   id: "6",
              //   title: "Template",
              //   url: "/templates",
              //   auth: function () {
              //     return UniFun.userIsEligible({ forWhat: "module" });
              //   },
              // },
              {
                id: "8",
                title: "Events",
                url: "/events",
              },

              {
                id: "7",
                title: function () {
                  if (UniFun.userIsEligible({ forWhat: "viewer" })) {
                    return "";
                  }
                  if (UniFun.userIsEligible({ forWhat: "isInstructor" })) {
                    return "Assessments";
                  }
                  return "Reporting";
                },
                url: "/testresults",
                auth: function () {
                  return (
                    UniFun.userIsEligible({ forWhat: "member" }) &&
                    !UniFun.userIsEligible({ forWhat: "isHost" })
                  );
                },
              },
              {
                id: "9",
                title: "Forms",
                url: "/forms",
                auth: function () {
                  return UniFun.userIsEligible({ forWhat: "module" });
                },
              },
              // {
              //   _id: "trainings",
              //   title: "Trainings",

              //   url: "/agenda/list/trainings",
              // },
              // {
              //   id: "7",
              //   title: "Chat",
              //   url: "/chat",
              // },
            ],
          },
          parameters: {
            headers: {},
          },
          formatter: function (data) {
            return UniTopMenuFormatter(data);
          },
        },
      },
      template: "topmenu",
      templateConfig: {
        colsTemplateCount: 10,
        // includeProperties: ["title", "description"],
        // excludeProperties: [],
        templateTop: function () {},
        templateBottom: function () {},
        templateLeft: function () {},
        templateRight: function () {},
        panelTop: function () {},
        panelBottom: function () {},
        panelLeft: function () {
          return (
            <div style={{ backgroundColor: "#EAEAEA" }} className="col-2"></div>
          );
        },
        panelRight: function () {},
        header: function () {
          return (
            <nav className="navbar navbar-expand-md bg-dark navbar-dark">
              <a className="navbar-brand" href="#">
                Business Name
              </a>
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#collapsibleNavbar"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
            </nav>
          );
        },
      },
      pk: "id",
      properties: UniTopMenuProperties({ editable: true }),
    },
    topmenutest: {
      auth: "",
      apis: {
        view: {
          url: "/exam/event/user-exam/{_id}",
          parameters: {
            headers: {},
          },
          formatter: function (data) {
            return UniTopMenuFormatter(data);
          },
        },
      },
      template: "topmenutest",
      templateConfig: {
        colsTemplateCount: 10,
      },
      pk: "id",
      properties: UniTopMenuProperties({ editable: false }),
    },
    topmenutestresults: {
      auth: "",
      apis: {
        view: {
          url: "/exam/user-exam/{id}",
          parameters: {
            headers: {},
          },
          formatter: function (data) {
            return UniTopMenuResultsFormatter(data);
          },
        },
      },
      template: "topmenutestresults",
      templateConfig: {
        colsTemplateCount: 10,
        includeProperties: ["id", "control", "participant"],
      },
      pk: "id",
      properties: UniTopMenuResultsProperties({ editable: false }),
    },
    topmenuepub: {
      auth: "",
      apis: {
        view: {
          data: { data: [{}] },
          parameters: {
            headers: {},
          },
          formatter: function (data) {
            return UniTopMenuResultsFormatter(data);
          },
        },
      },
      template: "topmenuepub",
      templateConfig: {
        colsTemplateCount: 10,
        includeProperties: [],
      },
      pk: "id",
      properties: UniTopMenuResultsProperties({ editable: false }),
    },
    topmenuvideo: {
      auth: "",
      apis: {
        view: {
          data: { data: [{}] },
          parameters: {
            headers: {},
          },
          formatter: function (data) {
            return UniTopMenuResultsFormatter(data);
          },
        },
      },
      template: "topmenuvideo",
      templateConfig: {
        colsTemplateCount: 10,
        includeProperties: [],
      },
      pk: "id",
      properties: UniTopMenuResultsProperties({ editable: false }),
    },
    topmenupdf: {
      auth: "",
      apis: {
        view: {
          data: { data: [{}] },
          parameters: {
            headers: {},
          },
          formatter: function (data) {
            return UniTopMenuResultsFormatter(data);
          },
        },
      },
      template: "topmenupdf",
      templateConfig: {
        colsTemplateCount: 10,
        includeProperties: [],
      },
      pk: "id",
      properties: UniTopMenuResultsProperties({ editable: false }),
    },
  },
};

export default UniTopMenu;

function UniTopMenuResultsProperties(input) {
  let editable = input.editable;

  return {
    id: {
      control: "text",
      alias: "id",
      editable: false,
    },
    updatedAt: {
      control: "datetime",
      alias: "updatedAt",
      editable: false,
      config: {
        toFormat: "ddd DD MMMM YYYY, HH:mm",
      },
      useCustomLayout: true,
      customLayout: function (args) {
        return (
          <div className="pt-3">
            <span className="text-dark mr-2">Submitted:</span>
            <strong>{args.control}</strong>
          </div>
        );
      },
    },
    participant: {
      control: "text",
      alias: "participant",
      editable: false,
      useCustomLayout: true,
      customLayout: function () {
        if (this.props.value) {
          let person = JSON.parse(this.props.value);

          let output = UniFun.createProfileImageBubble([person]);

          return <div className="mt-1 pl-5 ">{output}</div>;
        }
        return "";
      },
    },
  };
}

function UniTopMenuResultsFormatter(data) {
  let formattedData = [];
  if (data) {
    if (data.data) {
      data.data.map((itm, idx) => {
        let formattedRecord = {};

        formattedRecord["id"] = itm._id;
        formattedRecord["participant"] = JSON.stringify(itm.participant);
        formattedRecord["updatedAt"] = itm.updatedAt;

        formattedData.push(formattedRecord);
      });
    }
  }
  return formattedData;
}

function UniTopMenuProperties(input) {
  let editable = input.editable;

  return {
    id: {
      control: "text",
      alias: "id",
      editable: false,
    },
    title: {
      control: "text",
      alias: "title",
      editable: false,
    },
    availability: {
      control: "text",
      alias: "availability",
      editable: false,
    },
    remainingTime: {
      control: "countdowntimer",
      alias: "remainingTime",
      editable: false,
      events: {
        onTimesUp: function () {
          window["mainref"].builderTemplate.current.setState({
            action: "finalizeauto",
          });
          setTimeout(function () {
            window[
              "mainref"
            ].builderTemplate.current.form.current.dispatchEvent(
              new Event("submit", {
                cancelable: true,
                bubbles: true,
              })
            );
          }, 0);
        },
      },
      config: {
        intervalFun: function () {
          let status = window["mainref"].builderTemplate.current.state.action;

          if (status !== "finalize" && status !== "finalizeauto") {
            window["mainref"].builderTemplate.current.setState({
              action: "autosave",
            });

            window["topmenuref"].rerunComponent();

            setTimeout(function () {
              window[
                "mainref"
              ].builderTemplate.current.form.current.dispatchEvent(
                new Event("submit", {
                  cancelable: true,
                  bubbles: true,
                })
              );
            }, 0);
          }
        },
        intervalEvery: 5000,
      },
    },
    url: {
      control: "text",
      alias: "url",
      editable: false,
    },
  };
}

function UniTopMenuFormatter(data) {
  if (data) {
    let formattedData = [];

    data.data.map((itm, idx) => {
      let formattedRecord = {};
      let isEligbile = true;

      if (typeof itm.auth !== "undefined") {
        isEligbile = itm.auth;
      }

      if (isEligbile) {
        formattedRecord["id"] = itm.id;
        formattedRecord["title"] = itm.title;
        formattedRecord["availability"] = "";
        formattedRecord["remainingTime"] = itm.remainingTime;

        if (itm.examEvent) {
          formattedRecord["availability"] = UniFun.testAvailability(
            itm.examEvent.from,
            itm.examEvent.to
          );
        }
        formattedRecord["url"] = itm.url;

        formattedData.push(formattedRecord);
      }
    });

    return formattedData;
  }
}
