import React, { Component } from "react";
import Fun from "../../lib/functions";
import Builder from "../../lib/builder";
import UniFun from "./functions";
import moment from "moment";

const UniStreams = {
  auth: "",
  views: {
    detail: {
      auth: "",
      apis: {
        view: {
          url: "/stream/{id}",
          parameters: {
            headers: {},
            replace: {},
          },
          formatter: function (data) {
            return UniStreamFormatter(data);
          },
        },
        update: {
          url: "/stream",
          parameters: {
            headers: {
              "Content-Type": "application/json",
            },
            replace: {},
          },
          formatter: function (data) {
            return UniStreamFormatter(data);
          },
        },
      },
      template: "form",
      templateConfig: {
        events: {
          onSubmit: function (e) {
            e.preventDefault();
            let submittedData = Fun.getSubmitJSON(e);

            let from = UniFun.fromToDateFormat(
              submittedData.fromToDateTime[0].fromDateTime[0].fromDate,
              submittedData.fromToDateTime[0].fromDateTime[0].fromTime
            );

            let to = UniFun.fromToDateFormat(
              submittedData.fromToDateTime[0].toDateTime[0].toDate,
              submittedData.fromToDateTime[0].toDateTime[0].toTime
            );

            let duration = moment
              .duration(moment(to).diff(moment(from)))
              .asMinutes();

            let jsonData = {
              title: submittedData.title,
              from: from,
              to: to,
              streamURL: submittedData["streamURL"],
              isPublic: submittedData["isPublic"],
              files: submittedData["files"]
                ? UniFun.agendaFilesJson(JSON.parse(submittedData["files"]))
                : [],
              description: submittedData.description,
              participants: UniFun.participantsJSONData(
                JSON.parse(submittedData.participants)
              ),
              reminders: JSON.parse(submittedData.reminders),
              duration: duration,
            };
          },
        },
        colsTemplateCount: 10,
        includeProperties: [
          "_id",
          "title",
          "streamURL",
          "isPublic",
          "fromToDateTime",
          "participants",
          "description",
          "reminders",
        ],
        excludeProperties: [],
      },
      pk: "_id",
      properties: UniStreamProperties({ editable: true }),
    },
    stream: {
      auth: "",
      apis: {
        view: {
          url: "/streamInfo/{id}",
          parameters: {
            headers: {},
            replace: {
              id: function () {
                return Fun.getURLPathValue(2);
              },
            },
          },
          formatter: function (data) {
            return UniStreamFormatter(data);
          },
        },
        public: {
          url: "/stream/public/{id}",
          parameters: {
            headers: {},
            replace: {
              id: function () {
                return Fun.getURLPathValue(2);
              },
            },
          },
          formatter: function (data) {
            return UniStreamFormatter(data);
          },
        },
        private: {
          url: "/stream/{id}",
          parameters: {
            headers: {},
            replace: {
              id: function () {
                return Fun.getURLPathValue(2);
              },
            },
          },
          formatter: function (data) {
            return UniStreamFormatter(data);
          },
        },
      },
      template: "stream",
      templateConfig: {
        events: {
          onSubmit: function (e) {},
          componentDidMountEvent: function () {
            this.setState({ loaded: false });
            let data = this.state.data[0];
            if (
              this.props.builder.state.streamAPI != "public" &&
              this.props.builder.state.streamAPI != "private"
            ) {
              if (data.isPublic.value == "true") {
                this.props.builder.setState({ streamAPI: "public" });
                this.props.builder.rerunComponent({
                  api: this.props.builder.state.module.apis.public,
                });
              } else {
                if (localStorage.getItem("isLoggedIn")) {
                  this.props.builder.rerunComponent({
                    api: this.props.builder.state.module.apis.private,
                  });
                  this.props.builder.setState({ streamAPI: "private" });
                } else {
                  window.location.replace("/login?redirect=" + window.location);
                }
              }
            } else {
              this.setState({ loaded: true });
            }
          },
        },
        colsTemplateCount: 10,
        includeProperties: [],
        excludeProperties: [],
      },
      pk: "_id",
      properties: UniStreamProperties({ editable: false }),
    },
    add: {
      auth: "",
      apis: {
        view: {
          data: { data: [{}] },
          parameters: {
            headers: {
              "Content-Type": "application/json",
            },
            replace: {},
          },
          formatter: function (data) {
            return UniStreamFormatter(data);
          },
        },
        update: {
          url: "/stream/",
          parameters: {
            headers: {
              "Content-Type": "application/json",
            },
            replace: {},
          },
          formatter: function (data) {
            return UniStreamFormatter(data);
          },
        },
      },
      template: "form",
      templateConfig: {
        events: {
          onSubmit: function (e) {
            e.preventDefault();
            let submittedData = Fun.getSubmitJSON(e);
            let from = UniFun.fromToDateFormat(
              submittedData.fromToDateTime[0].fromDateTime[0].fromDate,
              submittedData.fromToDateTime[0].fromDateTime[0].fromTime
            );

            let to = UniFun.fromToDateFormat(
              submittedData.fromToDateTime[0].toDateTime[0].toDate,
              submittedData.fromToDateTime[0].toDateTime[0].toTime
            );

            let duration = moment
              .duration(moment(to).diff(moment(from)))
              .asMinutes();

            let jsonData = {
              title: submittedData.title,
              from: from,
              to: to,
              streamURL: submittedData["streamURL"],
              isPublic: submittedData["isPublic"],
              files: submittedData["files"]
                ? UniFun.agendaFilesJson(JSON.parse(submittedData["files"]))
                : [],
              description: submittedData.description,
              participants: UniFun.participantsJSONData(
                JSON.parse(submittedData.participants)
              ),
              reminders: [JSON.parse(submittedData.reminders)],
              duration: duration,
            };

            Fun.update(e, {
              url: this.props.builder.state.module.apis.update,
              method: "POST",
              callback: function (
                submittedData,
                status,
                responsedata,
                responsestatus
              ) {
                if (responsestatus == "200") {
                  if (window["modal2"].state.callback) {
                    window["modal2"].state.callback(submittedData);
                  } else {
                    if (window["modal2"].state.targetComponent) {
                      window["modal2"].state.targetComponent.setState({
                        data: submittedData,
                      });
                    }
                  }
                  Fun.createNotification({
                    type: "success",
                    message: "The stream was created!",
                  });
                } else {
                  if (responsedata.data.exceptions) {
                    responsedata.data.exceptions.map((itm, idx) => {
                      Fun.createNotification({
                        message: itm.errorDescription,
                        type: "danger",
                      });
                    });
                  }
                }
              },
              parameters: {
                headers: {
                  "content-type": "application/json",
                },
              },
              submittedData: JSON.stringify(jsonData),
            });
          },
        },
        colsTemplateCount: 12,
        includeProperties: [
          "title",
          "streamURL",
          "isPublic",
          "fromToDateTime",
          "participants",
          "description",
          "reminders",
        ],
      },
      pk: "_id",
      properties: UniStreamProperties({ editable: true }),
    },
  },
};

export default UniStreams;

function UniStreamProperties(input) {
  let editable = input.editable;

  return {
    _id: {
      alias: "_id",
      control: "hidden",
      label: "",
      editable: editable,
      apiPath: "",
      auth: "",
      validations: "",
      attributes: {},
    },
    title: {
      alias: "title",
      label: "Title",
      control: "text",
      placeholder: "Type the title of the stream",
      editable: editable,
      apiPath: "",
      auth: "",
      validations: "",
      attributes: {
        className: "form-control",
      },
    },
    streamURL: {
      alias: "streamURL",
      label: "Stream URL",
      control: "text",
      placeholder: "Type the URL of the stream",
      editable: editable,
      apiPath: "",
      auth: "",
      validations: "",
      attributes: {
        className: "form-control",
      },
    },
    isPublic: {
      alias: "isPublic",
      label: "Public available",
      control: "select",
      editable: editable,
      config: {
        options: [
          { label: "Yes", value: "true" },
          { label: "No", value: "false" },
        ],
      },
      apiPath: "",
      auth: "",
      validations: "",
      attributes: {
        className: "form-control",
      },
    },
    fromToDateTime: {
      alias: "fromToDateTime",
      control: "group",
      label: "Availability",
      editable: editable,
      customLayout: function (args) {
        return (
          <React.Fragment>
            <label>{args.object.props.field.label}</label>
            <div className="row mb-3">
              <div className="col-md-5 pr-0 availability-calendar">
                {args.control[0]}
              </div>
              <div className="col-md-1 text-center pr-0 pt-2 ">to</div>
              <div className="col-md-5 pl-0 availability-calendar">
                {args.control[1]}
              </div>
              <div className="col-md-1"></div>
            </div>
          </React.Fragment>
        );
      },
      useCustomLayout: true,
      config: {
        properties: {
          fromDateTime: {
            alias: "fromDateTime",
            control: "group",
            editable: editable,
            customLayout: function (args) {
              return (
                <React.Fragment>
                  <div className="row mb-3">
                    <div className="col-md-8 pr-0">{args.control[0]}</div>
                    <div className="col-md-4 pr-0">{args.control[1]}</div>
                  </div>
                </React.Fragment>
              );
            },
            useCustomLayout: true,
            config: {
              properties: {
                fromDate: {
                  alias: "fromDate",
                  control: "datetime",
                  config: {
                    fromFormat: "YYYY-MM-DDT00:00:00.000Z",
                    toFormat: "YYYY-MM-DDT00:00:00.000Z",
                    includeTime: false,
                    valueEditFormat: { dateStyle: "short" },
                    valueDisplayFormat: { dateStyle: "short" },
                  },
                  editable: editable,
                },
                fromTime: {
                  alias: "fromTime",
                  control: "select",
                  config: {
                    options: UniFun.timeSelect,
                  },
                  editable: editable,
                  attributes: {
                    className: "form-control",
                  },
                },
              },
            },
          },
          toDateTime: {
            alias: "toDateTime",
            control: "group",
            editable: editable,
            customLayout: function (args) {
              return (
                <React.Fragment>
                  <div className="row mb-3">
                    <div className="col-md-8 pr-0">{args.control[0]}</div>
                    <div className="col-md-4 pr-0">{args.control[1]}</div>
                  </div>
                </React.Fragment>
              );
            },
            useCustomLayout: true,
            config: {
              properties: {
                toDate: {
                  alias: "toDate",
                  control: "datetime",
                  config: {
                    fromFormat: "YYYY-MM-DDT00:00:00.000Z",
                    toFormat: "YYYY-MM-DDT00:00:00.000Z",
                    includeTime: false,
                    valueEditFormat: { dateStyle: "short" },
                    valueDisplayFormat: { dateStyle: "short" },
                  },
                  editable: editable,
                },
                toTime: {
                  alias: "toTime",
                  control: "select",
                  config: {
                    options: UniFun.timeSelect,
                  },
                  editable: editable,
                  attributes: {
                    className: "form-control",
                  },
                },
              },
            },
          },
        },
      },
    },
    participants: {
      alias: "participants",
      label: "Choose participants",
      control: "tags",
      placeholder: "Select the participants of the stream",
      editable: editable,
      config: {
        idAttribute: "_id",
        nameAttribute: "name",
        apis: {
          search: {
            url: "/user/autocomplete/{query}?type=all",
            formatter: function (args) {
              let formattedData = [];
              if (args) {
                try {
                  let data = args.data;
                  data.map((itm, idx) => {
                    let fullName =
                      itm["firstName"] +
                      " " +
                      itm["lastName"] +
                      " - " +
                      itm["email"];
                    formattedData.push({ _id: itm["_id"], name: fullName });
                  });
                } catch (e) {}
              }
              return formattedData;
            },
            parameters: {
              replace: {
                query: "",
              },
            },
          },
        },
      },
      attributes: {
        className: "form-control",
      },
    },
    description: {
      alias: "description",
      label: "Agenda",
      control: "editor",
      placeholder: "Write the agenda of the stream",
      nonEditableDefaultValue: "No description available",
      editable: editable,
      attributes: {
        className: "form-control",
      },
    },
    files: {
      alias: "files",
      control: "dropzone",
      label: "Attach file",
      editable: editable,
      customContentLayout: function () {
        let files = JSON.parse(this.state.defaultValue);

        let content = [];

        Object.keys(files).map((itm, idx) => {
          content.push(
            UniFun.trainingNodeFileUI(files[itm], this.removeFile, itm)
          );
        });

        this.state.content?.map((itm, idx) => {
          if (typeof files[itm.key] === "undefined") {
            content.push(itm);
          }
        });
        return (
          <React.Fragment>
            {this.state.placeholder()}
            {content}
            {/* {content.length > 0 ? "" : this.state.placeholder()} */}
          </React.Fragment>
        );
      },

      useCustomLayout: true,
      config: {
        placeholder: function () {
          return (
            <div className="p-3 pb-1">
              <strong>
                Drag & drop here or{" "}
                <a
                  href="javascript:void(0);"
                  className="text-primary"
                  onClick={this.onTargetClick}
                >
                  <strong>browse file</strong>
                </a>{" "}
                to attach
              </strong>
              <br></br>
              <span className="text-dark">Maximum size: 50MB</span>
            </div>
          );
        },
      },
      events: {
        removeFile: function (id) {
          let defaultValue = {};

          Object.keys(JSON.parse(this.state.defaultValue)).map((itm, idx) => {
            if (itm != id) {
              defaultValue[itm] = JSON.parse(this.state.defaultValue)[itm];
            }
          });

          let content = [];
          this.state.content?.map((itm, idx) => {
            if (itm.key != id) {
              content.push(itm);
            }
          });

          this.setState({
            defaultValue: JSON.stringify(defaultValue),
            content: content,
          });

          return;
        },
        onDrop: function (files) {
          this.setState({ defaultValue: JSON.stringify({}) });

          UniFun.fileProgress({
            control: this,
            files: files,
            url: "/agenda-file",
            type: "stream",
          });
        },
      },
      attributes: {
        className: "border rounded  dropzone-trainings",
      },
    },
    reminders: {
      alias: "reminders",
      control: "select",
      config: {
        options: [
          {
            label: "5 minutes before",
            value: JSON.stringify({ type: "minutes", value: 5 }),
          },
          {
            label: "10 minutes before",
            value: JSON.stringify({ type: "minutes", value: 10 }),
          },
          {
            label: "30 minutes before",
            value: JSON.stringify({ type: "minutes", value: 30 }),
          },
          {
            label: "1 hour before",
            value: JSON.stringify({ type: "minutes", value: 60 }),
          },
          {
            label: "1 day before",
            value: JSON.stringify({ type: "days", value: 1 }),
          },
        ],
      },
      label: "Reminder",
      editable: editable,
      attributes: {
        className: "form-control",
      },
    },
  };
}

function UniStreamFormatter(data) {
  if (data) {
    let formattedData = [];

    data.data.map((itm, idx) => {
      let formattedRecord = {};

      formattedRecord["_id"] = itm._id;
      formattedRecord["title"] = itm.title;
      formattedRecord["from"] = itm.from;
      formattedRecord["to"] = itm.to;
      formattedRecord["streamURL"] = itm.streamURL;
      formattedRecord["isPublic"] = itm.isPublic ? "true" : "false";

      const start = moment();
      const halfhour = 30 - (start.minute() % 30);
      const dateFromTime = moment(start)
        .add(halfhour, "minutes")
        .format("HH:mm:00");
      const dateToTime = moment(start)
        .add(halfhour, "minutes")
        .add("3", "hours")
        .format("HH:mm:00");

      let fromTime = dateFromTime;
      if (itm.from) {
        fromTime = moment(itm.from).format("HH:mm:00");
      }
      let toTime = dateToTime;
      if (itm.to) {
        toTime = moment(itm.to).format("HH:mm:00");
      }

      if (!itm.from && !itm.to) {
        if (
          moment(start)
            .add(halfhour, "minutes")
            .isBefore(moment(start).add(halfhour, "minutes").add("3", "hours"))
        ) {
          itm.to = moment(start)
            .add(halfhour, "minutes")
            .add("3", "hours")
            .add("1", "days");
        }
      }

      formattedRecord["fromToDateTime"] = {
        fromDateTime: {
          fromDate: itm.from,
          fromTime: fromTime,
        },
        toDateTime: {
          toDate: itm.to,
          toTime: toTime,
        },
      };

      formattedRecord["description"] = itm.description;
      formattedRecord["files"] = itm.files;
      formattedRecord["reminders"] = itm.reminders
        ? JSON.stringify({
            type: itm.reminders[0].type,
            value: itm.reminders[0].value,
          })
        : {};

      formattedData.push(formattedRecord);
    });

    return formattedData;
  }
}
