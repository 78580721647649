import moment from "moment";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Fun from "../../../lib/functions";
import UniFun from "../../../modules/uni/functions";

class AgendaOverview extends Component {
  state = {
    data: [],
    loaded: false,
    colsTemplateCount: 12,
    colsFormCount: 12,
  };

  constructor(props) {
    super(props);

    this.createHeaders = this.createHeaders.bind(this);
    this.createBody = this.createBody.bind(this);
  }

  componentDidMount() {
    let builder = this.props.builder.state;
    let templateConfig = builder.module.templateConfig;
    let templateTop = templateConfig.templateTop
      ? templateConfig.templateTop
      : () => {};
    this["templateTop"] = templateTop.bind(this);
    let templateBottom = templateConfig.templateBottom
      ? templateConfig.templateBottom
      : () => {};
    this["templateBottom"] = templateBottom.bind(this);
    let templateLeft = templateConfig.templateLeft
      ? templateConfig.templateLeft
      : () => {};
    this["templateLeft"] = templateLeft.bind(this);
    let templateRight = templateConfig.templateRight
      ? templateConfig.templateRight
      : () => {};
    this["templateRight"] = templateRight.bind(this);
    let cardTemplateTop = templateConfig.cardTemplateTop
      ? templateConfig.cardTemplateTop
      : () => {};
    this["cardTemplateTop"] = cardTemplateTop.bind(this);
    let cardTemplateBottom = templateConfig.cardTemplateBottom
      ? templateConfig.cardTemplateBottom
      : () => {};
    this["cardTemplateBottom"] = cardTemplateBottom.bind(this);
    let content = templateConfig.content ? templateConfig.content : null;
    if (content) {
      this["content"] = content.bind(this);
    }
    let panelTop = templateConfig.panelTop ? templateConfig.panelTop : () => {};
    this["panelTop"] = panelTop.bind(this);
    let panelBottom = templateConfig.panelBottom
      ? templateConfig.panelBottom
      : () => {};
    this["panelBottom"] = panelBottom.bind(this);
    let panelLeft = templateConfig.panelLeft
      ? templateConfig.panelLeft
      : () => {};
    this["panelLeft"] = panelLeft.bind(this);
    let panelRight = templateConfig.panelRight
      ? templateConfig.panelRight
      : () => {};
    this["panelRight"] = panelRight.bind(this);
    let header = templateConfig.header ? templateConfig.header : () => {};
    this["header"] = header.bind(this);

    let gridConfig = templateConfig.gridConfig;

    let emptyFolder = templateConfig.gridConfig.emptyFolder
      ? templateConfig.gridConfig.emptyFolder
      : () => {};
    this["emptyFolder"] = emptyFolder.bind(this);

    let colsTemplateCount = templateConfig.colsTemplateCount
      ? templateConfig.colsTemplateCount
      : this.state.colsTemplateCount;
    let colsFormCount = templateConfig.colsFormCount
      ? templateConfig.colsFormCount
      : this.state.colsFormCount;

    this.setState({
      data: this.props.data,
      loaded: true,
      gridConfig: gridConfig,
      colsTemplateCount: colsTemplateCount,
      colsFormCount: colsFormCount,
    });
  }

  createHeaders() {
    let headers = [];
    let data = this.state.data[0];

    if (data) {
      Object.keys(data).map((itm, idx) => {
        if (data[itm].showPropertyOnTemplate) {
          let attributes = [];
          if (this.state.gridConfig) {
            if (this.state.gridConfig.headers) {
              if (this.state.gridConfig.headers[itm]) {
                if (this.state.gridConfig.headers[itm]["attributes"]) {
                  attributes = this.state.gridConfig.headers[itm]["attributes"];
                }
              }
            }
          }

          headers.push(
            <th scope="col" key={Fun.uuid()} {...attributes}>
              {data[itm].field.label}
            </th>
          );
        }
      });

      return (
        <thead>
          <tr>{headers}</tr>
        </thead>
      );
    }
    return "";
  }

  createBody() {
    let output = [];

    let data = this.state.data;

    let translations = {
      today: "Today",
      tomorrow: "Tomorrow",
      thisweek: "Later this week",
      nextweek: "Next week",
      thismonth: "Later this month",
      nextmonth: "Next month",
      later: "Later",
    };

    let timeSlots = {
      today: [],
      tomorrow: [],
      thisweek: [],
      nextweek: [],
      thismonth: [],
      nextmonth: [],
      later: [],
    };

    data.map((itm, idx) => {
      let fromDay = moment(itm.from.value).startOf("day").toDate();
      let endDay = moment(itm.to.value).endOf("day").toDate();

      if (moment().isBetween(fromDay, endDay)) {
        timeSlots.today.push(itm);
      } else if (moment().add(1, "days").isBetween(fromDay, endDay)) {
        timeSlots.tomorrow.push(itm);
      } else if (
        moment(fromDay).isBetween(
          moment().startOf("isoWeek"),
          moment().endOf("isoWeek")
        ) &&
        moment(fromDay).isAfter(moment())
      ) {
        timeSlots.thisweek.push(itm);
      } else if (
        moment(fromDay).isBetween(
          moment().add(1, "weeks").startOf("isoWeek"),
          moment().add(1, "weeks").endOf("isoWeek")
        )
      ) {
        timeSlots.nextweek.push(itm);
      } else if (
        moment(fromDay).isBetween(
          moment().startOf("month"),
          moment().endOf("month")
        )
      ) {
        timeSlots.thismonth.push(itm);
      } else if (
        moment(fromDay).isBetween(
          moment().add(1, "month").startOf("month"),
          moment().add(1, "month").endOf("month")
        )
      ) {
        timeSlots.nextmonth.push(itm);
      } else {
        if (moment().isSameOrBefore(endDay)) {
          timeSlots.later.push(itm);
        }
      }
    });

    Object.keys(timeSlots).map((itm, idx) => {
      let out = [];

      timeSlots[itm].map((titm, tid) => {
        let link = titm.event.value.moreLink;

        out.push(
          <a
            href="javascript:void(0);"
            onClick={(e) => UniFun.UniAgendaSelectEvent(titm.event.value)}
          >
            <div className=" p-3 session-row rounded row rowhover pointer">
              <div className="col-md-10">
                <h5 className="pb-0 ">
                  {/* <img
                    src="/uni/svg/trainingmain.svg"
                    style={{ width: "16px", paddingBottom: "3px" }}
                    className="mr-2"
                  ></img> */}
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ marginBottom: "5px" }}
                      className="mr-2"
                      width="16"
                      height="16"
                      viewBox="0 0 176 176"
                    >
                      <path
                        // fill="rgb(55,117,210)"
                        fill={titm.event.data.hex}
                        fill-rule="evenodd"
                        d="M55 0h66c30.35532635 0 55 24.64467365 55 55v66c0 30.35532635-24.64467365 55-55 55H55c-30.35532635 0-55-24.64467365-55-55V55C0 24.64467365 24.64467365 0 55 0z"
                      />
                    </svg>
                  </span>{" "}
                  <span className=" ">
                    {titm.eventType.component}
                    {": "}
                    {titm.title.component}
                  </span>
                </h5>
                <span className="text-dark small pl-29px d-inline-block">
                  <img
                    src={titm.icon.value}
                    style={{ width: "16px", paddingBottom: "3px" }}
                    className="mr-2"
                  ></img>{" "}
                  {titm.subtitle.component}
                </span>
              </div>
              <div className="col-md-2 text-center pt-2">
                <span className="grid-controls">
                  {titm.joinBtn.component}
                  {/* {titm.editBtn.component} */}
                </span>
              </div>
            </div>
          </a>
        );
      });

      if (out.length > 0) {
        output.push(
          <div>
            <h5 className="pb-1 text-black">
              <strong>{translations[itm]}</strong>
            </h5>
            <div className="row ">
              <div className="col-12">
                <div className="card " key={Fun.uuid()}>
                  <div className="card-body">
                    <div className=" session-sections">{out}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }
    });

    return output;
  }

  render() {
    if (this.state.loaded) {
      return (
        <React.Fragment>
          {this.header()}
          {this.panelTop()}
          <div className="container-fluid">
            <div className="row">
              {this.panelLeft()}
              <div className={"col-" + this.state.colsTemplateCount}>
                <div className="row">
                  {this.templateLeft()}
                  <div className={"col-" + this.state.colsFormCount}>
                    {this.templateTop()}
                    <div>
                      {this.cardTemplateTop()}
                      {this.content ? this.content() : this.createBody()}
                      {this.createBody().length <= 0 ? this.emptyFolder() : ""}
                      {this.cardTemplateBottom()}
                    </div>
                    {this.templateBottom()}
                  </div>
                  {this.templateRight()}
                </div>
              </div>
              {this.panelRight()}
            </div>
          </div>
          {this.panelBottom()}
        </React.Fragment>
      );
    } else {
      return "loading...";
    }
  }
}

export default AgendaOverview;
