import React, { Component } from "react";
import Moment, { isDate } from "moment";
import { DateTimePicker, Localization } from "react-widgets";
import { DateLocalizer } from "react-widgets/Localization";
import "react-widgets/styles.css";

class DateTime extends Component {
  state = {
    name: "",
    id: "",
    placeholder: "",
    attributes: "",
    value: "",
    defaultValue: "",
    loaded: false,
    editable: true,
    component: null,
    attributes: "",
    events: "",
    customLayout: null,
    useCustomLayout: true,
    editable: true,
  };

  constructor(props) {
    super(props);

    let events = {};
    if (this.props.field.events) {
      Object.keys(this.props.field.events).map((itm, idx) => {
        this[itm] = this.props.field.events[itm];
        this[itm] = this[itm].bind(this);
        events[itm] = this[itm];
      });
    }

    this.editable = this.editable.bind(this);
    this.nonEditable = this.nonEditable.bind(this);

    this.state = {
      events: events,
      min: new Date(1900, 0, 1),
      max: new Date(2199, 11, 31),
      step: 30,
      fromFormat: "YYYY-MM-DDTHH:mm:ss.sssZ",
      toFormat: "ddd DD MMM YY, HH:mm",
      includeTime: true,
    };
  }

  onChange = (e) => {
    try {
      if (e != "Invalid Date") {
        this.setState({ defaultValue: e });
        if (this.props.field.events) {
          if (this.props.field.events.onChange) {
            this.props.field.events.onChange({
              object: this,
              defaultValue: e,
            });
          }
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  componentDidMount() {
    let min = this.state.min;
    let max = this.state.max;
    let step = this.state.step;
    let fromFormat = this.state.fromFormat;
    let toFormat = this.state.toFormat;
    let includeTime = this.state.includeTime;
    let valueEditFormat = this.state.valueEditFormat;
    let valueDisplayFormat = this.state.valueDisplayFormat;

    if (this.props.field.config) {
      min = this.props.field.config.min ? this.props.field.config.min : min;
      max = this.props.field.config.max ? this.props.field.config.max : max;
      step = this.props.field.config.step ? this.props.field.config.step : step;
      valueEditFormat = this.props.field.config.valueEditFormat
        ? this.props.field.config.valueEditFormat
        : valueEditFormat;
      valueDisplayFormat = this.props.field.config.valueDisplayFormat
        ? this.props.field.config.valueDisplayFormat
        : valueDisplayFormat;
      includeTime = this.props.field.config.includeTime ? true : false;
      fromFormat = this.props.field.config.fromFormat
        ? this.props.field.config.fromFormat
        : fromFormat;
      toFormat = this.props.field.config.toFormat
        ? this.props.field.config.toFormat
        : toFormat;
    }

    this.setState({
      min: min,
      max: max,
      step: step,
      fromFormat: fromFormat,
      toFormat: toFormat,
      valueEditFormat: valueEditFormat,
      valueDisplayFormat: valueDisplayFormat,
      includeTime: includeTime,
      defaultValue: this.props.value ? new Date(this.props.value) : new Date(),
      attributes: this.props.field.attributes,
      name: this.props.field.alias,
      editable:
        this.props.field.editable != null
          ? this.props.field.editable
          : this.state.editable,
      customLayout: this.props.field.customLayout,
      useCustomLayout:
        typeof this.props.field.useCustomLayout !== "undefined"
          ? this.props.field.useCustomLayout
          : true,
    });
  }

  editable() {
    let output = (
      <React.Fragment>
        <Localization>
          <DateTimePicker
            step={this.state.step}
            {...this.state.attributes}
            includeTime={this.state.includeTime}
            valueEditFormat={this.state.valueEditFormat}
            valueDisplayFormat={this.state.valueDisplayFormat}
            defaultValue={this.state.defaultValue}
            value={this.state.defaultValue}
            max={this.state.max}
            min={this.state.min}
            onChange={this.onChange.bind(this)}
          />
          <input
            type="hidden"
            name={this.state.name}
            value={Moment(
              this.state.defaultValue,
              this.state.fromFormat
            ).toISOString()}
          ></input>
        </Localization>
      </React.Fragment>
    );
    if (this.state.customLayout && this.state.useCustomLayout == true) {
      this.state.customLayout = this.state.customLayout.bind(this);
      return this.state.customLayout({ control: output, object: this });
    }

    return output;
  }

  nonEditable() {
    let output = "-";

    if (Moment(this.state.defaultValue).isValid()) {
      output = Moment(new Date(this.state.defaultValue)).format(
        this.state.toFormat
      );
    }

    if (this.state.customLayout && this.state.useCustomLayout == true) {
      this.state.customLayout = this.state.customLayout.bind(this);
      return this.state.customLayout({ control: output, object: this });
    }

    return output;
  }

  render() {
    let output = this.state.editable ? this.editable() : this.nonEditable();

    return output;
  }
}

export default DateTime;
