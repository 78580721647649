import React, { Component } from "react";
import Fun from "../../../lib/functions";
import Builder from "../../../lib/builder";
import { Link } from "react-router-dom";

class TopMenuTestResults extends Component {
  state = {
    data: [],
    loaded: false,
    output: null,
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.setState({ data: this.props.data, loaded: true });
  }

  render() {
    if (this.state.loaded) {
      return (
        <header
          className="c-header c-header-light c-header-fixed c-header-with-subheader "
          style={{ borderBottom: "1px solid #d8dbe0" }}
        >
          <div className="row w-100">
            <div className="col-9">
              <div className="mt-1">
                {this.state.data[0].participant.component}
              </div>
            </div>
            <div className="col-3 text-right">
              {this.state.data[0].updatedAt.component}
            </div>
          </div>
        </header>
      );
    } else {
      return "loading...";
    }
  }
}

export default TopMenuTestResults;
