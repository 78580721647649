import React, { Component } from "react";
import ReactTooltip from "react-tooltip";
import Fun from "../../../lib/functions";

class ValidationControl extends Component {
  state = {
    data: [],
    validation: "",
    loaded: false,
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.setState({
      validations: this.props.validations,
      label: this.props.label,
      alias: this.props.alias,
      loaded: true,
    });
  }

  render() {
    if (this.state.validation) {
      return (
        <span className="text-danger mt-1 pt-1 d-block">
          {this.state.validation.replace(
            "{" + this.state.alias + "}",
            this.state.label
          )}
        </span>
      );
    }

    return "";
  }
}

export default ValidationControl;
