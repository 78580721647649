import React, { Component } from "react";

class IFrame extends Component {
  state = {
    name: "",
    id: "",
    placeholder: "",
    attributes: "",
    value: "",
    defaultValue: "",
    loaded: false,
    editable: true,
    component: null,
    attributes: "",
    events: {},
    customLayout: null,
    useCustomLayout: true,
    editable: true,
  };

  constructor(props) {
    super(props);

    let events = {};
    if (this.props.field.events) {
      Object.keys(this.props.field.events).map((itm, idx) => {
        this[itm] = this.props.field.events[itm];
        this[itm] = this[itm].bind(this);
        events[itm] = this[itm];
      });
    }

    this.editable = this.editable.bind(this);

    this.state = {
      events: events,
      editable:
        this.props.field.editable != null
          ? this.props.field.editable
          : this.state.editable,
      defaultValue: this.props.value ? this.props.value : "",
    };
  }

  validator() {}

  componentDidMount() {
    this.setState({
      attributes: this.props.field.attributes,
      name: this.props.field.alias,
      editable:
        this.props.field.editable != null
          ? this.props.field.editable
          : this.state.editable,
      customLayout: this.props.field.customLayout,
      useCustomLayout:
        typeof this.props.field.useCustomLayout !== "undefined"
          ? this.props.field.useCustomLayout
          : true,
    });
  }

  editable() {
    let output = (
      <iframe
        type="text"
        src={this.state.defaultValue}
        name={this.state.name}
        {...this.state.attributes}
        {...this.state.events}
      ></iframe>
    );
    if (this.state.customLayout && this.state.useCustomLayout == true) {
      this.state.customLayout = this.state.customLayout.bind(this);
      return this.state.customLayout({ control: output, object: this });
    }

    return output;
  }

  render() {
    let output = this.editable();

    return output;
  }
}

export default IFrame;
