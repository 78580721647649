import React, { Component } from "react";
import Fun from "../../lib/functions";
import UniFun from "./functions";

const UniLeftMenu = {
  auth: "",
  views: {
    trainingevents: {
      auth: "",
      apis: {
        view: {
          url: "/event/training/{_id}",
          parameters: {
            headers: { "content-type": "application/json" },
          },
          formatter: function (data, builder) {
            let selectedId = null;
            if (builder) {
              if (builder.state) {
                if (builder.props.moduleParams) {
                  selectedId = builder.props.moduleParams["selectedMenuId"];
                }
              }
            }

            if (data) {
              let formattedData = [
                {
                  _id: "0",
                  title: "Training",
                  children: [
                    {
                      _id: "10",
                      title: "Overview",
                      class: "pl-32",
                      url: "/event/training/" + data.data[0]._id,
                      children: [],
                      selected: selectedId == 1 ? true : false,
                    },
                    {
                      _id: "4",
                      title: "Files",
                      class: "pl-32",
                      selected: selectedId == 4 ? true : false,
                      children: [],
                      url: "/event/training/" + data.data[0]._id + "/files",
                    },
                    {
                      _id: "5",
                      title: "Tests",
                      class: "pl-32",
                      selected: selectedId == 5 ? true : false,
                      children: [],
                      url: "/event/training/" + data.data[0]._id + "/results",
                      auth: function () {
                        if (
                          UniFun.userIsEligible({ forWhat: "isInstructor" })
                        ) {
                          return false;
                        }

                        return true;
                      },
                    },
                    {
                      _id: "3",
                      title: "Participants",
                      class: "pl-32",
                      selected: selectedId == 3 ? true : false,
                      children: [],
                      url:
                        "/event/training/" + data.data[0]._id + "/participants",
                    },
                    // {
                    //   _id: "1",
                    //   title: "Modules",
                    //   class: "pl-4",
                    //   children: [],
                    //   liclass: " c-show",
                    //   //selected: selectedId == 1 ? true : false,
                    // },
                    // {
                    //   _id: "2",
                    //   title: "Test Results",
                    //   class: "pl-4",
                    //   children: [],
                    //   selected: selectedId == 2 ? true : false,
                    // },
                  ],
                },
              ];
              let i = 0;

              // data.data[0].modules.map((itm, idx) => {
              //   let formattedRecord = {};

              //   let url =
              //     "/event/training/" + data.data[0]._id + "/module/" + itm._id;

              //   formattedRecord["id"] = itm._id;
              //   formattedRecord["name"] = itm.title;
              //   formattedRecord["title"] =
              //     '<span class="pl-3">' +
              //     UniFun.substring(itm.title, 0, 20) +
              //     "</span>";
              //   formattedRecord["children"] = [];
              //   formattedRecord["class"] = "pl-32";
              //   formattedRecord["selected"] =
              //     itm._id == Fun.getURLPathValue(5) && !Fun.getURLPathValue(7)
              //       ? true
              //       : false;
              //   formattedRecord["url"] = url;

              //   i++;
              //   formattedData[0].children[1].children.push(formattedRecord);
              // });
              // data.data[0].modules.map((itm, idx) => {
              //   let formattedRecord = {};

              //   let url =
              //     "/event/training/" +
              //     data.data[0]._id +
              //     "/module/" +
              //     itm._id +
              //     "/results";

              //   formattedRecord["id"] = "r" + itm._id;
              //   formattedRecord["name"] = itm.title;
              //   formattedRecord["title"] =
              //     '<span class="pl-3">' +
              //     UniFun.substring(itm.title, 0, 20) +
              //     "</span>";
              //   formattedRecord["children"] = [];
              //   formattedRecord["class"] = "pl-32";
              //   formattedRecord["selected"] =
              //     "r" + itm._id == "r" + Fun.getURLPathValue(5) &&
              //     Fun.getURLPathValue(7)
              //       ? true
              //       : false;
              //   formattedRecord["url"] = url;

              //   i++;
              //   // formattedData[0].children[2].children.push(formattedRecord);
              // });

              // formattedData[0].children.push({
              //   _id: "4",
              //   title: "Files",
              //   class: "pl-32",
              //   selected: selectedId == 4 ? true : false,
              //   children: [],
              //   url: "/event/training/" + data.data[0]._id + "/files",
              // });

              // formattedData[0].children.push({
              //   _id: "3",
              //   title: "Participants",
              //   class: "pl-32",
              //   selected: selectedId == 3 ? true : false,
              //   children: [],
              //   url: "/event/training/" + data.data[0]._id + "/participants",
              // });

              return formattedData;
            }
          },
        },
      },
      template: "leftmenu",
      templateConfig: {
        events: {},
        colsTemplateCount: 10,
      },
      pk: "id",
      properties: LeftMenuProperties({ editable: true }),
    },
    previewmeetingevent: {
      auth: "",
      apis: {
        view: {
          data: {
            data: [
              {
                _id: "0",
                title: "Meeting",
                children: [
                  {
                    _id: "1",
                    title: "Overview",
                    children: [],
                    class: "pl-32",
                    url: "/event/meeting/{id}",
                  },
                  {
                    _id: "2",
                    title: "Participants",
                    children: [],
                    class: "pl-32",
                    url: "/event/meeting/{id}/participants",
                  },
                  {
                    _id: "3",
                    title: "Files",
                    children: [],
                    class: "pl-32",
                    url: "/event/meeting/{id}/files",
                  },
                ],
              },
            ],
          },
          parameters: {
            headers: { "content-type": "application/json" },
          },
          formatter: function (data, builder) {
            return LeftMenuFormatter(data, builder);
          },
        },
      },
      template: "leftmenu",
      templateConfig: {
        events: {},
        colsTemplateCount: 10,
      },
      pk: "id",
      properties: LeftMenuProperties({ editable: true }),
    },
    testresults: {
      auth: "",
      apis: {
        view: {
          data: {
            data: [
              {
                _id: "0",
                title: function () {
                  if (UniFun.userIsEligible({ forWhat: "isInstructor" })) {
                    return "Assessments";
                  }
                  return "Reporting";
                },
                children: [
                  // {
                  //   _id: "1",
                  //   title: "Trainings",
                  //   children: [],
                  //   class: "pl-32",
                  //   url: "/testresults/trainings",
                  // },
                  {
                    _id: "2",
                    // title: "Trainings",
                    title:
                      "<span> <i class='fas fa-square mr-2 text-info'></i>Tests</span> ",
                    children: [],
                    class: "pl-32",
                    url: "/testresults/other",
                  },
                ],
              },
            ],
          },
          parameters: {
            headers: { "content-type": "application/json" },
          },
          formatter: function (data, builder) {
            return LeftMenuFormatter(data, builder);
          },
        },
      },
      template: "leftmenu",
      templateConfig: {
        events: {},
        colsTemplateCount: 10,
      },
      pk: "id",
      properties: LeftMenuProperties({ editable: true }),
    },
    previewstreamevent: {
      auth: "",
      apis: {
        view: {
          data: {
            data: [
              {
                _id: "0",
                title: "Stream",
                children: [
                  {
                    _id: "1",
                    title: "Overview",
                    children: [],
                    class: "pl-32",
                    url: "/event/stream/{id}",
                  },
                  {
                    _id: "2",
                    title: "Participants",
                    children: [],
                    class: "pl-32",
                    url: "/event/stream/{id}/participants",
                  },
                ],
              },
            ],
          },
          parameters: {
            headers: { "content-type": "application/json" },
          },
          formatter: function (data, builder) {
            return LeftMenuFormatter(data, builder);
          },
        },
      },
      template: "leftmenu",
      templateConfig: {
        events: {},
        colsTemplateCount: 10,
      },
      pk: "id",
      properties: LeftMenuProperties({ editable: true }),
    },
    previewTest: {
      auth: "",
      apis: {
        view: {
          url: "/event/exam/{id}",
          // url: "/exam/user-exam/{id}",
          // data: {
          //   data: function () {
          //     let out = [
          //       {
          //         _id: "0",
          //         title: "Test",
          //         children: [
          //           {
          //             _id: "1",
          //             title: "Overview",
          //             children: [],
          //             class: "pl-32",
          //             url: "/event/test/{id}",
          //           },
          //           {
          //             _id: "2",
          //             title: "Results",
          //             children: [],
          //             class: "pl-32",
          //             url: "/event/test/{id}/results",
          //           },
          //         ],
          //       },
          //     ];

          //     if (UniFun.userIsEligible({ forWhat: "default" })) {
          //       out[0].children.push({
          //         _id: "3",
          //         title: "Participants",
          //         children: [],
          //         class: "pl-32",
          //         url: "/event/test/{id}/participants",
          //       });
          //     }

          //     return out;
          //   },
          // },
          parameters: {
            headers: { "content-type": "application/json" },
            replace: {
              id: function () {
                return Fun.getURLPathValue(3);
              },
            },
          },
          formatter: function (data, builder) {
            let out = [
              {
                _id: "0",
                title: "Test",
                children: [
                  {
                    _id: "1",
                    title: "Overview",
                    children: [],
                    class: "pl-32",
                    url: "/event/test/{id}",
                  },
                ],
              },
            ];

            // userExamInfo;

            let respData = [];

            if (
              UniFun.userIsEligible({ forWhat: "default" }) ||
              UniFun.userIsEligible({ forWhat: "viewer" })
            ) {
              out[0].children.push({
                _id: "2",
                title: "Results",
                children: [],
                class: "pl-32",
                url: "/event/test/{id}/results",
              });
              out[0].children.push({
                _id: "3",
                title: "Participants",
                children: [],
                class: "pl-32",
                url: "/event/test/{id}/participants",
              });
            } else {
              let examId = "";
              let status = "";
              let resultsURL = "/event/test/{id}/";
              let classDisabled = "disabled-link";
              let keylock = "/uni/svg/padlock.svg";
              if (data) {
                if (data.data) {
                  if (data.data[0]) {
                    if (data.data[0].userExamInfo) {
                      examId = data.data[0].userExamInfo._id;
                      status = data.data[0].userExamInfo.status.toLowerCase();
                      if (status == "fail" || status == "pass") {
                        resultsURL = "/event/test/" + examId + "/result";
                        classDisabled = "";
                        keylock = "/uni/svg/unlock_padlock.svg";
                      }
                    }
                  }
                }
              }
              out[0].children.push({
                _id: "2",
                title:
                  "<span> <img src='" +
                  keylock +
                  "' style='width:14px;padding-bottom:6px;' class='mr-2'>Results</span> ",
                children: [],
                class: "pl-32 " + classDisabled,
                url: resultsURL,
              });
            }

            let newdata = { data: out };

            return LeftMenuFormatter(newdata, builder);
          },
        },
      },
      template: "leftmenu",
      templateConfig: {
        events: {},
        colsTemplateCount: 10,
      },
      pk: "id",
      properties: LeftMenuProperties({ editable: true }),
    },
    spaces: {
      auth: "",
      apis: {
        view: {
          url: "/root-spaces",
          // data: {
          //   data: [
          //     {
          //       _id: "0",
          //       title: "Spaces",
          //       children: [
          //         {
          //           _id: "1",
          //           title: "Home",
          //           children: [],
          //           class: "pl-32",
          //           url: "/spaces",
          //         },
          //         {
          //           _id: "2",
          //           title: "My spaces",
          //           children: [],
          //           class: "pl-32",
          //         },
          //       ],
          //     },
          //   ],
          // },
          parameters: {
            headers: { "content-type": "application/json" },
          },
          formatter: function (data, builder) {
            return LeftMenuSpaceFormatter(data, builder);
          },
        },
      },
      template: "leftmenu",
      templateConfig: {
        events: {},
        colsTemplateCount: 10,
      },
      pk: "id",
      properties: LeftMenuProperties({ editable: true }),
    },
    trainings: {
      auth: "",
      apis: {
        view: {
          data: {
            data: [
              {
                _id: "0",
                title: "Events",
                children: [
                  {
                    _id: "trainings",
                    title: "Trainings",
                    children: [],
                    class: "pl-32",
                    url: "/agenda/list/trainings",
                  },
                ],
              },
            ],
          },
          parameters: {
            headers: { "content-type": "application/json" },
          },
          formatter: function (data, builder) {
            return LeftMenuFormatter(data, builder);
          },
        },
      },
      template: "leftmenu",
      templateConfig: {
        events: {},
        colsTemplateCount: 10,
      },
      pk: "id",
      properties: LeftMenuProperties({ editable: true }),
    },
    forms: {
      auth: "",
      apis: {
        view: {
          data: {
            data: [
              {
                _id: "0",
                title: "Forms",
                children: [
                  {
                    _id: "overview",
                    title: "Overview",
                    children: [],
                    class: "pl-32",
                    url: "/forms",
                    selected: function () {
                      if (!Fun.getURLPathValue(2)) {
                        return true;
                      }
                      return false;
                    },
                  },
                  {
                    _id: "results",
                    title: "Results",
                    children: [],
                    class: "pl-32",
                    url: "/forms/results",
                    selected: function () {
                      if (
                        Fun.getURLPathValue(3) == "results" ||
                        Fun.getURLPathValue(2) == "results"
                      ) {
                        return true;
                      }
                      return false;
                    },
                  },
                ],
              },
            ],
          },
          parameters: {
            headers: { "content-type": "application/json" },
          },
          formatter: function (data, builder) {
            return LeftMenuFormatter(data, builder);
          },
        },
      },
      template: "leftmenu",
      templateConfig: {
        events: {},
        colsTemplateCount: 10,
      },
      pk: "id",
      properties: LeftMenuProperties({ editable: true }),
    },
    agenda: {
      auth: "",
      apis: {
        view: {
          data: {
            data: [
              {
                _id: "0",
                title: "Agenda",
                children: [
                  {
                    _id: "1",
                    title: "Overview",
                    children: [],
                    class: "pl-32",
                    url: "/agenda/overview",
                  },
                  {
                    _id: "2",
                    title: "Calendar",
                    children: [],
                    class: "pl-32",
                    url: "/agenda/calendar",
                  },
                  // {
                  //   _id: "meetings",
                  //   title: "Meetings",
                  //   children: [],
                  //   class: "pl-32",
                  //   url: "/agenda/list/meetings",
                  // },
                  // {
                  //   _id: "trainings",
                  //   title: "Trainings",
                  //   children: [],
                  //   class: "pl-32",
                  //   url: "/agenda/list/trainings",
                  // },
                  // {
                  //   _id: "tests",
                  //   title: "Tests",
                  //   children: [],
                  //   class: "pl-32",
                  //   url: "/agenda/list/tests",
                  // },
                  // {
                  //   _id: "streams",
                  //   title: "Streams",
                  //   children: [],
                  //   class: "pl-32",
                  //   url: "/agenda/list/streams",
                  // },
                  // {
                  //   _id: "3",
                  //   title: "List",
                  //   children: [],
                  //   class: "pl-32",
                  //   url: "/agenda/list",
                  // },
                ],
              },
            ],
          },
          parameters: {
            headers: { "content-type": "application/json" },
          },
          formatter: function (data, builder) {
            return LeftMenuFormatter(data, builder);
          },
        },
      },
      template: "leftmenu",
      templateConfig: {
        events: {},
        colsTemplateCount: 10,
      },
      pk: "id",
      properties: LeftMenuProperties({ editable: true }),
    },
    conference: {
      auth: "",
      apis: {
        view: {
          data: {
            data: [
              {
                _id: "0",
                title: "Conference",
                children: [
                  {
                    _id: "1",
                    title: "Reception",
                    children: [],
                    class: "pl-32 ",
                    url: function () {
                      return "/conference/" + Fun.getURLPathValue(2);
                    },
                    selected: function () {
                      if (!Fun.getURLPathValue(3)) {
                        return true;
                      }
                      return window.location.pathname.indexOf("/reception") > 0;
                    },
                  },
                  {
                    _id: "2",
                    title: "Sessions",
                    liclass: function () {
                      return window.location.pathname.indexOf("sessions") != 0
                        ? "c-show"
                        : "";
                    },
                    children: [],
                    class: "pl-32",
                    url: function () {
                      return (
                        "/conference/" + Fun.getURLPathValue(2) + "/sessions"
                      );
                    },
                    selected: function () {
                      return window.location.pathname.indexOf("/sessions") > 0;
                    },
                  },
                  {
                    _id: "3",
                    title: "Speakers",
                    liclass: function () {
                      return window.location.pathname.indexOf("/speakers") > 0
                        ? "c-show"
                        : "";
                    },
                    children: [],
                    class: "pl-32",
                    url: function () {
                      return (
                        "/conference/" + Fun.getURLPathValue(2) + "/speakers"
                      );
                    },
                    selected: function () {
                      return window.location.pathname.indexOf("/speakers") > 0;
                    },
                  },
                  {
                    _id: "4",
                    title: "Partners",
                    liclass: function () {
                      return window.location.pathname.indexOf("/sponsors") > 0
                        ? "c-show"
                        : "";
                    },
                    children: [],
                    class: "pl-32",
                    url: function () {
                      return (
                        "/conference/" + Fun.getURLPathValue(2) + "/sponsors"
                      );
                    },
                    selected: function () {
                      return window.location.pathname.indexOf("/sponsors") > 0;
                    },
                  },
                  {
                    _id: "5",
                    title: "Participants",
                    liclass: function () {
                      return window.location.pathname.indexOf("/participants") >
                        0
                        ? "c-show"
                        : "";
                    },
                    children: [],
                    class: "pl-32",
                    url: function () {
                      return (
                        "/conference/" +
                        Fun.getURLPathValue(2) +
                        "/participants"
                      );
                    },
                    auth: function () {
                      return (
                        UniFun.userIsEligible({ forWhat: "isHost" }) ||
                        UniFun.userIsEligible({ forWhat: "module" }) ||
                        UniFun.userIsEligible({ forWhat: "viewer" }) ||
                        UniFun.userIsEligible({ forWhat: "isInstructor" })
                      );
                    },
                    selected: function () {
                      return (
                        window.location.pathname.indexOf("/participants") > 0
                      );
                    },
                  },
                  // {
                  //   _id: "6",
                  //   title: "Polls",
                  //   liclass: function () {
                  //     return window.location.pathname.indexOf("/polls") > 0
                  //       ? "c-show"
                  //       : "";
                  //   },
                  //   children: [],
                  //   class: "pl-32",
                  //   url: function () {
                  //     return "/conference/" + Fun.getURLPathValue(2) + "/polls";
                  //   },
                  //   auth: function () {
                  //     return UniFun.userIsEligible({ forWhat: "isHost" });
                  //   },
                  //   selected: function () {
                  //     return window.location.pathname.indexOf("/polls") > 0;
                  //   },
                  // },
                  {
                    _id: "6",
                    title: "Settings",
                    liclass: function () {
                      return window.location.pathname.indexOf("/settings") > 0
                        ? "c-show"
                        : "";
                    },
                    children: [],
                    auth: function () {
                      return (
                        UniFun.userIsEligible({ forWhat: "isHost" }) ||
                        UniFun.userIsEligible({ forWhat: "module" })
                      );
                    },
                    class: "pl-32",
                    url: function () {
                      return (
                        "/conference/" + Fun.getURLPathValue(2) + "/settings"
                      );
                    },
                    selected: function () {
                      return window.location.pathname.indexOf("/settings") > 0;
                    },
                  },
                ],
              },
            ],
          },
          parameters: {
            headers: { "content-type": "application/json" },
          },
          formatter: function (data, builder) {
            return LeftMenuFormatter(data, builder);
          },
        },
      },
      template: "leftmenu",
      templateConfig: {
        events: {},
        colsTemplateCount: 10,
      },
      pk: "id",
      properties: LeftMenuProperties({ editable: true }),
    },
    events: {
      auth: "",
      apis: {
        view: {
          data: {
            data: [
              {
                _id: "0",
                title: "Events",
                children: [
                  {
                    _id: "1",
                    title: "Overview",
                    children: [],
                    class: "pl-32 ",
                    url: "/events/overview",
                  },
                  {
                    _id: "20",
                    title: "All events",
                    liclass: function () {
                      return window.location.pathname.startsWith("/events")
                        ? "c-show"
                        : "";
                    },
                    children: [
                      {
                        _id: "2",
                        title: "Meetings",
                        children: [],
                        liclass: "pl-18",
                        url: "/events/meetings",
                        selected: function () {
                          return window.location.pathname.startsWith(
                            "/events/meetings"
                          );
                        },
                      },
                      {
                        _id: "3",
                        title: "Trainings",
                        children: [],
                        liclass: "pl-18",
                        url: "/events/trainings",
                        selected: function () {
                          return window.location.pathname.startsWith(
                            "/events/trainings"
                          );
                        },
                        auth: function () {
                          return !UniFun.userIsEligible({
                            forWhat: "isHost",
                          });
                        },
                      },
                      {
                        _id: "4",
                        title: "Tests",
                        children: [],
                        liclass: "pl-18",
                        url: "/events/tests",
                        selected: function () {
                          return window.location.pathname.startsWith(
                            "/events/tests"
                          );
                        },
                        auth: function () {
                          return (
                            !UniFun.userIsEligible({
                              forWhat: "isInstructor",
                            }) &&
                            !UniFun.userIsEligible({
                              forWhat: "isHost",
                            })
                          );
                        },
                      },
                      {
                        _id: "5",
                        title: "Conferences",
                        children: [],
                        liclass: "pl-18",
                        url: "/events/conferences",
                        selected: function () {
                          return window.location.pathname.startsWith(
                            "/events/conferences"
                          );
                        },
                      },
                    ],
                    class: "pl-32 c-has-children",
                    url: "/events",
                    selected: function () {
                      return window.location.pathname.startsWith("/events");
                    },
                  },
                  {
                    _id: "30",
                    title: "Templates",
                    selected: function () {
                      return window.location.pathname.startsWith("/templates");
                    },
                    auth: function () {
                      if (UniFun.userIsEligible({ forWhat: "module" })) {
                        return true;
                      }

                      return false;
                    },
                    liclass: function () {
                      return window.location.pathname.startsWith("/templates")
                        ? "c-show"
                        : "";
                    },
                    children: [
                      {
                        _id: "31",
                        title: "Trainings",
                        children: [],
                        liclass: "pl-18",
                        url: "/templates/trainings",
                        selected: function () {
                          return window.location.pathname.startsWith(
                            "/templates/trainings"
                          );
                        },
                      },
                      {
                        _id: "32",
                        title: "Tests",
                        children: [],
                        liclass: "pl-18",
                        url: "/templates/tests",
                        selected: function () {
                          return window.location.pathname.startsWith(
                            "/templates/tests"
                          );
                        },
                      },
                    ],
                    class: "pl-32 c-has-children",
                    url: "/templates",
                  },
                ],
              },
            ],
          },
          parameters: {
            headers: { "content-type": "application/json" },
          },
          formatter: function (data, builder) {
            return LeftMenuFormatter(data, builder);
          },
        },
      },
      template: "leftmenu",
      templateConfig: {
        events: {},
        colsTemplateCount: 10,
      },
      pk: "id",
      properties: LeftMenuProperties({ editable: true }),
    },
    files: {
      auth: "",
      apis: {
        view: {
          data: {
            data: [
              {
                _id: "0",
                title: "Library",
                children: [
                  {
                    _id: "1",
                    title: "Personal files",
                    children: [],
                    class: "pl-32",
                    url: "/files/ownedbyme",
                  },
                  // {
                  //   _id: "2",
                  //   title: "Shared with me",
                  //   children: [],
                  //   class: "pl-32",
                  //   url: "/files/shared",
                  // },
                  {
                    _id: "3",
                    title: "Company files",
                    children: [],
                    class: "pl-32",
                    url: "/files/library",
                  },
                  {
                    _id: "4",
                    title: "Favorites",
                    children: [],
                    class: "pl-32",
                    url: "/files/favorites",
                  },
                  {
                    _id: "5",
                    title: "Spaces",
                    children: [],
                    class: "pl-32",
                    url: "/files/gfiles",
                  },
                ],
              },
            ],
          },
          parameters: {
            headers: { "content-type": "application/json" },
          },
          formatter: function (data, builder) {
            return LeftMenuFormatter(data, builder);
          },
        },
      },
      template: "leftmenu",
      templateConfig: {
        events: {},
        colsTemplateCount: 10,
      },
      pk: "id",
      properties: LeftMenuProperties({ editable: true }),
    },
    contacts: {
      auth: "",
      apis: {
        view: {
          data: {
            data: [
              {
                _id: "0",
                title: "Users",
                children: [
                  {
                    _id: "1",
                    title: "Organization",
                    children: [],
                    class: "pl-32",
                    url: "/contacts",
                  },
                  // {
                  //   _id: "2",
                  //   title: "Roles",
                  //   children: [],
                  //   class: "pl-32",
                  //   url: "/contacts/roles",
                  // },
                ],
              },
            ],
          },
          parameters: {
            headers: { "content-type": "application/json" },
          },
          formatter: function (data, builder) {
            return LeftMenuFormatter(data, builder);
          },
        },
      },
      template: "leftmenu",
      templateConfig: {
        events: {},
        colsTemplateCount: 10,
      },
      pk: "id",
      properties: LeftMenuProperties({ editable: true }),
    },
    templates: {
      auth: "",
      apis: {
        view: {
          data: {
            data: [
              {
                _id: "0",
                title: "Templates",
                children: [
                  {
                    _id: "1",
                    title:
                      "<span> <i class='fas fa-square mr-2 text-primary'></i>Training</span> ",
                    children: [],
                    class: "pl-32",
                    url: "/templates/trainings",
                  },
                  {
                    _id: "2",
                    title:
                      "<span> <i class='fas fa-square mr-2 text-info'></i>Tests</span> ",
                    children: [],
                    class: "pl-32",
                    url: "/templates/tests",
                  },
                ],
              },
            ],
          },
          parameters: {
            headers: { "content-type": "application/json" },
          },
          formatter: function (data, builder) {
            return LeftMenuFormatter(data, builder);
          },
        },
      },
      template: "leftmenu",
      templateConfig: {
        events: {},
        colsTemplateCount: 10,
      },
      pk: "id",
      properties: LeftMenuProperties({ editable: true }),
    },
  },
};

export default UniLeftMenu;

function LeftMenuProperties(input) {
  let editable = input.editable;

  return {
    id: {
      alias: "id",
      control: "text",
      apiPath: "",
      auth: "",
      validations: "",
      attributes: {
        className: "form-control",
      },
    },
    title: {
      alias: "title",
      control: "text",
      apiPath: "",
      auth: "",
      validations: "",
      attributes: {
        className: "form-control",
      },
    },
    name: {
      alias: "name",
      control: "text",
      apiPath: "",
      auth: "",
      validations: "",
      attributes: {
        className: "form-control",
      },
    },
    url: {
      alias: "url",
      control: "text",
      apiPath: "",
      auth: "",
      validations: "",
      attributes: {
        className: "form-control",
      },
    },
    selectedId: {
      alias: "selectedId",
      control: "text",
      apiPath: "",
      auth: "",
      validations: "",
      attributes: {
        className: "form-control",
      },
    },
    children: {
      alias: "children",
      control: "text",
      apiPath: "",
      auth: "",
      validations: "",
      attributes: {
        className: "form-control",
      },
    },
  };
}

function LeftMenuSpaceFormatter(data, builder) {
  let selectedId = null;
  let urlid = "";
  if (builder) {
    if (builder.state) {
      if (builder.props.moduleParams) {
        selectedId = builder.props.moduleParams["selectedMenuId"];
        urlid = builder.props.moduleParams["urlid"];
      }
    }
  }

  let formattedData = [];

  if (data) {
    formattedData = [
      {
        _id: "0",
        title: "Spaces",
        children: [
          {
            _id: "1",
            title: "Overview",
            children: [],
            class: "pl-32",
            url: "/spaces",
          },
          {
            _id: "2",
            title: "My spaces (" + data.data.length + ")",
            children: [],
            class: "pl-24",
            liclass: "c-show",
          },
        ],
      },
    ];

    let i = 0;

    data.data.map((itm, idx) => {
      try {
        let formattedRecord = {};
        if (!data.colours[i]) {
          i = 0;
        }

        formattedRecord["id"] = itm._id;
        let color = "#" + data.colours[i]; // idx % 2 ? "#009F9F" : "#556FCE";
        formattedRecord["name"] = itm.name;
        formattedRecord["title"] =
          "<span> <i class='fas fa-square mr-2 ' style='color:" +
          color +
          "'></i>" +
          UniFun.substring(itm.name, 0, 20) +
          "</span>";

        formattedRecord["children"] = [];
        formattedRecord["selected"] =
          itm._id == Fun.getURLPathValue(2) ? true : false;
        formattedRecord["color"] = "#" + data.colours[i]; // idx % 2 ? "#009F9F" : "#556FCE";
        formattedRecord["url"] = "/spaces/" + itm._id + "/groups";
        formattedRecord["icon"] =
          '<i class="fas fa-infinity" style="font-size:40px;color:white;"></i>';

        formattedData[0].children[1].children.push(formattedRecord);
        i++;
      } catch (e) {
        console.log(e);
      }
    });
  }
  return formattedData;
}

function LeftMenuFormatter(data, builder) {
  let selectedId = null;
  let urlid = "";
  if (builder) {
    if (builder.state) {
      if (builder.props.moduleParams) {
        if (
          typeof builder.props.moduleParams["selectedMenuId"] === "function"
        ) {
          selectedId = builder.props.moduleParams["selectedMenuId"]();
        } else {
          selectedId = builder.props.moduleParams["selectedMenuId"];
        }
        urlid = builder.props.moduleParams["urlid"];
      }
    }
  }

  if (data) {
    let formattedData = [];
    let i = 0;

    data.data.map((itm, idx) => {
      let formattedRecord = {};

      formattedRecord["id"] = itm._id;
      formattedRecord["title"] = itm.title;

      let children = [];
      itm.children.map((mitm, midx) => {
        mitm.url = mitm.url ? mitm.url.replace("{id}", urlid) : "";
        children.push(mitm);
      });

      formattedRecord["children"] = children;
      formattedRecord["class"] = "";
      formattedRecord["selectedId"] = selectedId;

      if (itm.url) {
        formattedRecord["url"] = itm.url.replace("{id}", urlid);
      }

      i++;
      formattedData.push(formattedRecord);
    });

    return formattedData;
  }
}
