import React, { Component } from "react";
import Control from "./../../control";
import SimpleReactValidator from "simple-react-validator";
import Fun from "../../../../../lib/functions";
import UniFun from "../../../../../modules/uni/functions";
import moment from "moment";
import TopTemplateHeader from "./../../topTemplateHeader";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";

class Reception extends Component {
  state = {
    data: this.props.data,
    loaded: false,
    colsTemplateCount: 12,
    colsFormCount: 12,
    cardBodyCustomClass: "",
    module: {
      _id: "",
      title: "",
      description: "",
    },
    session: {},
  };

  constructor(props) {
    super(props);
    let builder = this.props.builder.state;
    let templateConfig = builder.module.templateConfig;
    if (templateConfig.events) {
      Object.keys(templateConfig.events).map((itm, idx) => {
        this[itm] = templateConfig.events[itm].bind(this);
      });
    }

    this.form = React.createRef();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.action) {
      return false;
    }
    return true;
  }

  componentDidMount() {
    let builder = this.props.builder.state;
    let templateConfig = builder.module.templateConfig;
    let useCard =
      typeof templateConfig.useCard !== "undefined"
        ? templateConfig.useCard
        : true;

    let templateTop = templateConfig.templateTop
      ? templateConfig.templateTop
      : () => {};
    this["templateTop"] = templateTop.bind(this);
    let templateBottom = templateConfig.templateBottom
      ? templateConfig.templateBottom
      : () => {};
    this["templateBottom"] = templateBottom.bind(this);
    let templateLeft = templateConfig.templateLeft
      ? templateConfig.templateLeft
      : () => {};
    this["templateLeft"] = templateLeft.bind(this);
    let templateRight = templateConfig.templateRight
      ? templateConfig.templateRight
      : () => {};
    this["templateRight"] = templateRight.bind(this);
    let cardTemplateTop = templateConfig.cardTemplateTop
      ? templateConfig.cardTemplateTop
      : () => {};
    this["cardTemplateTop"] = cardTemplateTop.bind(this);
    let cardTemplateBottom = templateConfig.cardTemplateBottom
      ? templateConfig.cardTemplateBottom
      : () => {};
    this["cardTemplateBottom"] = cardTemplateBottom.bind(this);
    let panelTop = templateConfig.panelTop ? templateConfig.panelTop : () => {};
    this["panelTop"] = panelTop.bind(this);
    let panelBottom = templateConfig.panelBottom
      ? templateConfig.panelBottom
      : () => {};
    this["panelBottom"] = panelBottom.bind(this);
    let panelLeft = templateConfig.panelLeft
      ? templateConfig.panelLeft
      : () => {};
    this["panelLeft"] = panelLeft.bind(this);
    let panelRight = templateConfig.panelRight
      ? templateConfig.panelRight
      : () => {};
    this["panelRight"] = panelRight.bind(this);
    let header = templateConfig.header ? templateConfig.header : () => {};
    this["header"] = header.bind(this);

    let colsTemplateCount = templateConfig.colsTemplateCount
      ? templateConfig.colsTemplateCount
      : this.state.colsTemplateCount;
    let colsFormCount = templateConfig.colsFormCount
      ? templateConfig.colsFormCount
      : this.state.colsFormCount;

    let cardBodyCustomClass = templateConfig.cardBodyCustomClass
      ? templateConfig.cardBodyCustomClass
      : this.state.cardBodyCustomClass;

    this.setState({
      loaded: true,
      rawData: this.props.rawData,
      colsTemplateCount: colsTemplateCount,
      colsFormCount: colsFormCount,
      cardBodyCustomClass: cardBodyCustomClass,
      useCard: useCard,
    });

    this.validator = this.props.builder.validator;
    if (this.componentDidMountEvent) {
      this.componentDidMountEvent();
    }
  }

  render() {
    if (this.state.loaded) {
      let data = this.state.data[0];

      let minHeightCards = "300px";
      let minHeightInfo = "250px";
      let numberOfSessions = 0;
      let numberOfSpeakers = 0;
      let speakersIds = [];
      let numberOfSponsors = data.sponsors.value.length;
      let banner = data.banner.value[0]
        ? window["baseurl"](
            "/agenda-file/data/" +
              data.banner.value[0]._id +
              "?type=preview&jwt=" +
              localStorage.getItem("jwt")
          )
        : "/uni/svg/conferencePlaceholder.jpg";

      data.days.value.forEach((itm, idx) => {
        itm.sessions.forEach((sitm, sidx) => {
          numberOfSessions++;
          sitm.speakersRaw.forEach((spitm, spidx) => {
            if (speakersIds.indexOf(spitm._id) < 0) {
              speakersIds.push(spitm._id);
              numberOfSpeakers++;
            }
          });
        });
      });

      return (
        <React.Fragment>
          <div className="container-fluid mt-5">
            <div className="row">
              <div className="col-md-8 ">
                <div
                  className="rounded"
                  style={{
                    minHeight: minHeightInfo,
                    height: "100%",
                    backgroundImage: "url(" + banner + ")",
                    backgroundSize: "cover",
                  }}
                ></div>
              </div>
              <div className="col-md-4">
                <div
                  className="card  p-2 pt-2"
                  style={{ minHeight: minHeightInfo, height: "100%" }}
                >
                  <div className="card-body">
                    <h2 className="text-dark-blue text-strong">
                      {data.title.value}
                    </h2>
                    {/* <span className="d-block mt-4">
                      <h5>Host:</h5>
                    </span>
                    <span className="d-block text-grey">
                      {localStorage.getItem("companyName")}
                    </span> */}

                    <span className="d-block text-grey mt-3 font-18">
                      {UniFun.fromToOnlyDateString(
                        this.state.rawData.data[0].startDate,
                        this.state.rawData.data[0].endDate
                      )}
                    </span>
                    <span className="d-block text-grey mt-3 font-16">
                      {UniFun.substring(
                        UniFun.stripTags(data.description.value),
                        0,
                        250
                      )}
                    </span>
                    {/* <span className="d-block mt-3">
                      <h5>Number of sessions:</h5>
                    </span>
                    <span className="d-block text-grey">
                      {numberOfSessions}
                    </span> */}
                  </div>
                  <div className="card-footer transparent  text-right pt-0">
                    <hr className="pl-2 pr-2 mt-0 pt-0 mb-4"></hr>
                    <button
                      type="button"
                      className="btn text-black border border-dark radius25"
                      onClick={(e) => this.readMore(data.description.component)}
                    >
                      Read more
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid mt-4">
            <div className="row">
              <div className="col-md-4">
                <div className="card p-2 ">
                  <div className="card-body">
                    <h3 className="text-strong">
                      <img
                        src="/uni/svg/conferences/sessions.svg"
                        className="mr-3"
                        style={{ width: "30px" }}
                      ></img>
                      Sessions
                    </h3>
                    <span className="d-block text-grey font-16">
                      {numberOfSessions} sessions included
                    </span>
                  </div>
                  <div className="card-footer transparent  text-right pt-0">
                    <hr className="pl-2 pr-2 mt-0 pt-0 mb-4"></hr>
                    <Link
                      to={"/conference/" + data._id.value + "/sessions"}
                      className="text-black border border-dark radius25 p-2"
                    >
                      View sessions
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card p-2 ">
                  <div className="card-body">
                    <h3 className="text-strong">
                      <img
                        src="/uni/svg/conferences/speakers.svg"
                        className="mr-3"
                        style={{ width: "30px" }}
                      ></img>
                      Speakers
                    </h3>
                    <span className="d-block text-grey font-16">
                      {numberOfSpeakers} speakers presenting
                    </span>
                  </div>
                  <div className="card-footer transparent  text-right pt-0">
                    <hr className="pl-2 pr-2 mt-0 pt-0 mb-4"></hr>
                    <Link
                      to={"/conference/" + data._id.value + "/speakers"}
                      className="text-black border border-dark radius25 p-2"
                    >
                      View speakers
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card p-2 ">
                  <div className="card-body">
                    <h3 className="text-strong">
                      <img
                        src="/uni/svg/conferences/sponsors.svg"
                        className="mr-3"
                        style={{ width: "30px" }}
                      ></img>
                      Partners
                    </h3>
                    <span className="d-block text-grey font-16">
                      {numberOfSponsors} partners contributed
                    </span>
                  </div>
                  <div className="card-footer transparent  text-right pt-0">
                    <hr className="pl-2 pr-2 mt-0 pt-0 mb-4"></hr>
                    <Link
                      to={"/conference/" + data._id.value + "/sponsors"}
                      className="text-black border border-dark radius25 p-2"
                    >
                      View partners
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    } else {
      return "loading...";
    }
  }
}

export default withRouter(Reception);
