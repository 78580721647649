import React, { Component } from "react";
import Fun from "../../../lib/functions";
import UniFun from "../functions";

const UniReadings = {
  auth: "",
  views: {
    detail: {
      auth: "",
      apis: {
        view: {
          data: { data: [{}] },
          parameters: {
            headers: {},
            replace: {},
          },
          formatter: function (data) {
            return UniReadingsFormatter(data);
          },
        },
      },
      template: "form",
      templateConfig: {
        events: {
          onSubmit: function (e) {
            e.preventDefault();

            if (
              Fun.validate(this.props.builder.validator, Fun.formDataToJSON(e))
            ) {
              let submittedData = Fun.getSubmitJSON(e);
              let file = JSON.parse(submittedData["file"]);

              submittedData["file"] = file[Object.keys(file)[0]];
              window["modal2"].setState({ data: submittedData });

              if (window["modal2"].state.callback) {
                window["modal2"].state.callback(submittedData);
              } else {
                if (window["modal2"].state.targetComponent) {
                  window["modal2"].state.targetComponent.setState({
                    data: JSON.stringify({ submittedData }),
                  });
                }
              }

              window["modal2"].setState({ show: false, overflowY: "auto" });
            }
          },
        },
        includeProperties: ["title", "duration", "file"],
      },
      pk: "_id",
      properties: UniReadingsProperties({ editable: true }),
    },
  },
};

export default UniReadings;

function UniReadingsProperties(input) {
  let editable = input.editable;

  return {
    _id: {
      alias: "_id",
      control: "hidden",
      editable: editable,
    },
    title: {
      alias: "title",
      control: "text",
      label: "Title",
      placeholder: "Type reading title",
      editable: editable,
      validations: "required",
      attributes: {
        className: "form-control",
      },
    },
    duration: {
      alias: "duration",
      control: "group",
      label: "Reading time",
      editable: editable,
      customLayout: function (args) {
        return (
          <React.Fragment>
            <label>{args.object.props.field.label}</label>
            <div className="row mb-3">
              <div className="col-md-3 pr-0">
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <label className="input-group-text">Hours</label>
                  </div>
                  {args.control[0]}
                </div>
              </div>
              <div className="col-md-3 ">
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <label className="input-group-text">Minutes</label>
                  </div>
                  {args.control[1]}
                </div>
              </div>
              <div className="col-md-6"></div>
            </div>
          </React.Fragment>
        );
      },
      useCustomLayout: true,
      config: {
        properties: {
          hours: {
            alias: "hours",
            control: "number",
            editable: editable,
            attributes: {
              className: "form-control",
            },
          },
          minutes: {
            alias: "minutes",
            control: "number",
            editable: editable,
            attributes: {
              className: "form-control",
            },
          },
        },
      },
    },
    file: {
      alias: "file",
      control: "dropzone",
      label: "Attach file",
      validations: "required",
      editable: editable,
      customContentLayout: function () {
        let files = JSON.parse(this.state.defaultValue);

        let content = [];

        Object.keys(files).map((itm, idx) => {
          content.push(
            UniFun.trainingNodeFileUI(files[itm], this.removeFile, itm)
          );
        });

        this.state.content?.map((itm, idx) => {
          if (typeof files[itm.key] === "undefined") {
            content.push(itm);
          }
        });
        return (
          <React.Fragment>
            {this.state.placeholder()}
            {content}
            {/* {content.length > 0 ? "" : this.state.placeholder()} */}
          </React.Fragment>
        );
      },

      useCustomLayout: true,
      config: {
        placeholder: function () {
          return (
            <div className="p-3">
              <strong>
                Drag & drop here or{" "}
                <a
                  href="javascript:void(0);"
                  className="text-primary"
                  onClick={this.onTargetClick}
                >
                  <strong>browse file</strong>
                </a>{" "}
                to attach
              </strong>
              <br></br>
              <span className="text-dark">
                Maximum size: 50MB - File type supported: pdf
              </span>
            </div>
          );
        },
      },
      events: {
        removeFile: function (id) {
          let defaultValue = {};

          Object.keys(JSON.parse(this.state.defaultValue)).map((itm, idx) => {
            if (itm != id) {
              defaultValue[itm] = JSON.parse(this.state.defaultValue)[itm];
            }
          });

          let content = [];
          this.state.content?.map((itm, idx) => {
            if (itm.key != id) {
              content.push(itm);
            }
          });

          this.setState({
            defaultValue: JSON.stringify(defaultValue),
            content: content,
          });

          return;
        },
        onDrop: function (uploadFiles) {
          this.setState({ defaultValue: JSON.stringify({}), content: [] });

          let object = this;
          let files = uploadFiles;

          if (files.length > 1) {
            files = [files[0]];
          }
          if (
            files[0].type == "application/pdf" ||
            files[0].type == "application/epub+zip"
          ) {
            UniFun.fileProgress({
              control: object,
              files: files,
              url: "/agenda-file",
              type: "training",
            });
          } else {
            Fun.createNotification({
              message: "Only pdf files are allowed to upload",
              type: "danger",
            });
          }
        },
      },
      attributes: {
        className: "border rounded  dropzone-trainings",
      },
    },
  };
}

function UniReadingsFormatter(data) {
  let formattedData = [];
  if (data) {
    data.data.map((itm, idx) => {
      let formattedRecord = {};

      formattedRecord["_id"] = itm._id;
      formattedRecord["title"] = itm.title;
      formattedRecord["duration"] = itm.duration;
      formattedRecord["file"] = itm._id
        ? {
            [itm._id]: {
              _id: itm._id,
              title: itm.title,
              mimetype: itm.mimetype,
            },
          }
        : {};
      formattedData.push(formattedRecord);
    });

    return formattedData;
  }
}
