import React, { Component } from "react";
import { Link } from "react-router-dom";
import Fun from "../../../lib/functions";
import UniFun from "../../../modules/uni/functions";
import Control from "./control";

class UserProfile extends Component {
  state = {
    data: this.props.data,
    loaded: false,
    colsTemplateCount: 12,
    colsFormCount: 12,
  };

  constructor(props) {
    super(props);
    let builder = this.props.builder.state;
    let templateConfig = builder.module.templateConfig;
    if (templateConfig.events) {
      Object.keys(templateConfig.events).map((itm, idx) => {
        this[itm] = templateConfig.events[itm].bind(this);
      });
    }

    this.form = React.createRef();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.action) {
      return false;
    }
    return true;
  }

  componentDidMount() {
    let builder = this.props.builder.state;
    let templateConfig = builder.module.templateConfig;
    let useCard =
      typeof templateConfig.useCard !== "undefined"
        ? templateConfig.useCard
        : true;

    let templateTop = templateConfig.templateTop
      ? templateConfig.templateTop
      : () => {};
    this["templateTop"] = templateTop.bind(this);
    let templateBottom = templateConfig.templateBottom
      ? templateConfig.templateBottom
      : () => {};
    this["templateBottom"] = templateBottom.bind(this);
    let templateLeft = templateConfig.templateLeft
      ? templateConfig.templateLeft
      : () => {};
    this["templateLeft"] = templateLeft.bind(this);
    let templateRight = templateConfig.templateRight
      ? templateConfig.templateRight
      : () => {};
    this["templateRight"] = templateRight.bind(this);
    let cardTemplateTop = templateConfig.cardTemplateTop
      ? templateConfig.cardTemplateTop
      : () => {};
    this["cardTemplateTop"] = cardTemplateTop.bind(this);
    let cardTemplateBottom = templateConfig.cardTemplateBottom
      ? templateConfig.cardTemplateBottom
      : () => {};
    this["cardTemplateBottom"] = cardTemplateBottom.bind(this);
    let panelTop = templateConfig.panelTop ? templateConfig.panelTop : () => {};
    this["panelTop"] = panelTop.bind(this);
    let panelBottom = templateConfig.panelBottom
      ? templateConfig.panelBottom
      : () => {};
    this["panelBottom"] = panelBottom.bind(this);
    let panelLeft = templateConfig.panelLeft
      ? templateConfig.panelLeft
      : () => {};
    this["panelLeft"] = panelLeft.bind(this);
    let panelRight = templateConfig.panelRight
      ? templateConfig.panelRight
      : () => {};
    this["panelRight"] = panelRight.bind(this);
    let header = templateConfig.header ? templateConfig.header : () => {};
    this["header"] = header.bind(this);

    let colsTemplateCount = templateConfig.colsTemplateCount
      ? templateConfig.colsTemplateCount
      : this.state.colsTemplateCount;
    let colsFormCount = templateConfig.colsFormCount
      ? templateConfig.colsFormCount
      : this.state.colsFormCount;

    this.setState({
      loaded: true,
      rawData: this.props.rawData,
      colsTemplateCount: colsTemplateCount,
      colsFormCount: colsFormCount,
      useCard: useCard,
    });

    this.validator = this.props.builder.validator;
    if (this.componentDidMountEvent) {
      this.componentDidMountEvent();
    }
  }

  output() {
    let out = [];

    this.state.data.map((itm, idx) => {
      Object.keys(itm).map((objItm, objIdx) => {
        if (itm[objItm]["showPropertyOnTemplate"]) {
          let ControlUI = (
            <Control control={itm[objItm]} key={idx + "-" + objIdx}></Control>
          );
          out.push(ControlUI);
        }
      });
    });

    return out;
  }

  render() {
    if (this.state.loaded) {
      let data = this.state.data[0];

      let profileImage = "";

      if (data.profileImage.value) {
        profileImage = data.profileImage.value
          ? window["baseurl"]("/agenda-file/data/" + data.profileImage.value) +
            "?type=preview&jwt=" +
            localStorage.getItem("jwt")
          : "";
      }

      let extraFields = [];

      data.extraFields.value.map((itm, idx) => {
        if (data.extraFields.data[itm.label]) {
          extraFields.push(
            <div className="mt-4 mb-4">
              <span className="text-dark d-block">{itm.label}</span>
              <span className="d-block text-black">
                {data.extraFields.data[itm.label]}
              </span>
            </div>
          );
        }
      });

      let spaces = [];

      if (data.groups.value) {
        data.groups.value.map((itm, idx) => {
          itm.groups.map((gitm, gidx) => {
            let to =
              "/spaces/" +
              itm.rootSpace._id +
              "/groups/" +
              gitm._id +
              "/participants";

            spaces.push(
              <Link
                to={to}
                className="btn-link ml-2"
                onClick={() => window["modal2"].setState({ show: false })}
              >
                {gitm.name}
              </Link>
            );

            spaces.push(",");
          });
        });

        spaces.pop();
      }

      let events = [];

      if (data.events.value) {
        Object.keys(data.events.value).map((itm, idx) => {
          let title = "";
          let url = "";

          switch (itm) {
            case "training":
              title = "Trainings";
              url = "/event/training/";
              break;
            case "exam":
              title = "Tests";
              url = "/event/test/";
              break;

            case "stream":
              title = "Conferences";
              url = "/conference/";
              break;

            case "meeting":
              title = "Meetings";
              url = "/event/meeting/";
              break;
          }
          if (data.events.value[itm].length > 0) {
            events.push(
              <div className="mt-2">
                <span className="text-dark">{title}:</span>
                {data.events.value[itm].map((gitm, gidx) => {
                  let to = url + gitm._id;
                  return (
                    <>
                      <Link
                        to={to}
                        className="btn-link ml-2"
                        onClick={() =>
                          window["modal2"].setState({ show: false })
                        }
                      >
                        {gitm.title}
                      </Link>
                      {gidx < data.events.value[itm].length - 1 ? "," : ""}
                    </>
                  );
                })}
              </div>
            );
          }
        });
      }

      if (!profileImage && data.biography.value && extraFields.length > 0) {
        return (
          <React.Fragment>
            <div className="container p-5">
              <h2 className=" text-black">{data.fullName.value}</h2>
              <h3 className="d-block mb-4 text-black">{data.jobTitle.value}</h3>
              <div className="row">
                <div className="col-md-3">
                  <div className="mb-4">
                    <span className="text-dark d-block">Email address:</span>
                    <a href={"mailto:" + data.email.value} className="btn-link">
                      {data.email.value}
                    </a>
                  </div>
                  {extraFields}
                </div>
                <div className="col-md-9">
                  {data.biography.value ? Fun.parse(data.biography.value) : ""}
                </div>
              </div>
            </div>
          </React.Fragment>
        );
      }

      return (
        <React.Fragment>
          <div className="container p-5">
            <div className="row">
              {profileImage ? (
                <div className="col-md-4">
                  <img
                    src={profileImage}
                    className="mr-3 rounded img-fluid"
                  ></img>
                  <div className="mt-4 mb-4">
                    <span className="text-dark d-block">Email address:</span>
                    <a href={"mailto:" + data.email.value} className="btn-link">
                      {data.email.value}
                    </a>
                  </div>
                  {extraFields}
                </div>
              ) : (
                ""
              )}
              <div className={profileImage ? "col-md-8" : "col-md-12"}>
                <h2 className=" text-black">{data.fullName.value}</h2>
                <h3 className="d-block mb-4 text-black">
                  {data.jobTitle.value}
                </h3>
                {profileImage ? (
                  ""
                ) : (
                  <>
                    <div className="mt-4 mb-4">
                      <span className="text-dark d-block">Email address:</span>
                      <a
                        href={"mailto:" + data.email.value}
                        className="btn-link"
                      >
                        {data.email.value}
                      </a>
                    </div>
                    {extraFields}
                  </>
                )}
                {data.biography.value ? Fun.parse(data.biography.value) : ""}
                {UniFun.userIsEligible({ forWhat: "default" }) ||
                localStorage.getItem("userid") == data._id.value ||
                UniFun.userIsEligible({ forWhat: "viewer" }) ? (
                  <>
                    {spaces.length > 0 ? (
                      <div className="mt-4">
                        <strong>Spaces:</strong>
                        {spaces}
                      </div>
                    ) : (
                      ""
                    )}
                    {events.length > 0 ? (
                      <div className="mt-4">
                        <strong>Events</strong>
                        {events}
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    } else {
      return "loading...";
    }
  }
}

export default UserProfile;
