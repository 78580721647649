import React, { Component } from "react";
import Fun from "../../../lib/functions";
import Builder from "../../../lib/builder";
import { Link } from "react-router-dom";

class TopMenuTest extends Component {
  state = {
    data: [],
    loaded: false,
    output: null,
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.setState({ data: this.props.data, loaded: true });
  }

  render() {
    if (this.state.loaded) {
      return (
        <header
          className="c-header c-header-light c-header-fixed c-header-with-subheader "
          style={{ borderBottom: "1px solid #d8dbe0" }}
        >
          <div className="row w-100">
            <div className="col-9">
              <div className="pl-5">
                <h4 className="mb-1 mt-1">
                  {this.state.data[0].title.component}
                </h4>
                <span className="pb-2">
                  Availability:{" "}
                  <span className="text-strong ml-1">
                    {this.state.data[0].availability.component}
                  </span>
                </span>
              </div>
            </div>
            <div className="col-3 text-right">
              <div className="pr-5 mt-3">
                <h4 className="mb-1 mt-1 text-dark">
                  Time remaining:{" "}
                  <span className="text-strong ml-1">
                    {this.state.data[0].remainingTime.component}
                  </span>
                </h4>
              </div>
            </div>
          </div>
        </header>
      );
    } else {
      return "loading...";
    }
  }
}

export default TopMenuTest;
