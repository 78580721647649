import React, { Component } from "react";
import Fun from "../../lib/functions";
import Builder from "../../lib/builder";
import TopTemplateHeader from "../../themes/default/templates/topTemplateHeader";
import { Link } from "react-router-dom";
import UniFun from "./functions";
import { parse } from "uuid";
import { Breadcrumb } from "react-bootstrap";

const filesBreadcrumb = function (breadCrumb, data, builder) {
  let breadCrumbArray = [];

  let breadCrumbData = breadCrumb.value;

  let groupFolders = [];
  if (
    builder.state.view == "groupsFiles" ||
    builder.state.view == "groupSpaces"
  ) {
    breadCrumbData.unshift({ name: "All spaces", id: "spaces", type: "space" });
  }

  breadCrumbData?.map((itm, idx) => {
    let name = UniFun.substring(itm.name, 0, 10);

    let totalCount = data[0]
      ? data[0].totalCount
        ? data[0].totalCount.value
        : 0
      : 0;
    let id = itm._id;

    let url = "?_id=" + id;

    if (itm.type == "spacegroup") {
      url = "?spacegroups=" + id;
    }

    if (builder.state.view == "trainingFiles") {
      url =
        "/event/training/" + Fun.getURLPathValue(3) + "/files/modules/" + id;
    }

    if (idx == 0) {
      name = "All files";
      if (builder.state.view == "trainingFiles") {
        name = "Modules";
        url = "/event/training/" + id + "/files/";
      }
      if (itm.type) {
        if (itm.type == "space") {
          name = itm.name;
          url = "/files/gfiles";
          if (breadCrumbData[1]) {
            breadCrumbData[1].type = "spacegroup";
          }
        }
      }
    }

    if (idx == breadCrumbData.length - 1) {
      name = <strong>{name}</strong>;
    }

    if (
      breadCrumbData.length > 3 &&
      idx > 0 &&
      idx < breadCrumbData.length - 1
    ) {
      groupFolders.push(
        <div className="p-2 dropdown-item">
          <Link className="pointer font-16 " to={url}>
            {name}
          </Link>
        </div>
      );
    } else {
      if (idx == breadCrumbData.length - 1) {
        breadCrumbArray.push(
          <span className=" font-16  text-dark">
            <strong>
              {" "}
              {name} ({totalCount})
            </strong>
          </span>
        );
      } else {
        breadCrumbArray.push(
          <Link className="pointer font-16 hover-underline text-dark" to={url}>
            {name}
          </Link>
        );
      }

      breadCrumbArray.push(<i className="fas fa-chevron-right ml-2 mr-2"></i>);
    }
  });

  if (groupFolders.length > 0) {
    breadCrumbArray.push(<i className="fas fa-chevron-right ml-2 mr-2"></i>);

    let group = (
      <React.Fragment>
        <div className="dropdown text-dark" style={{ display: "inline-block" }}>
          <span
            type="button"
            data-toggle="dropdown"
            className="dropdown-item pl-1 pr-1"
          >
            <i className="fas fa-ellipsis-h"></i>
          </span>
          <div className="dropdown-menu">{groupFolders}</div>
        </div>
        <i className="fas fa-chevron-right ml-2 mr-2"></i>
      </React.Fragment>
    );

    breadCrumbArray.splice(2, 0, group);
    breadCrumbArray.pop();
  }

  breadCrumbArray.pop();

  if (breadCrumbArray.length == 0) {
    breadCrumbArray.push(
      <span className=" font-16  text-dark">
        <strong>All files</strong> (0)
      </span>
    );
  }

  return breadCrumbArray;
};

const filesNewFolder = function (parentBuilder) {
  let builder = parentBuilder;

  let parent = Fun.getParameterByName("_id");

  if (!parent) {
    switch (builder.state.view) {
      case "ownedbyme":
        parent = localStorage.getItem("personalFolderDirectory");
        break;

      case "library":
        parent = localStorage.getItem("library");
        break;

      case "spaceFiles":
        parent = builder.state.parentData.spaceFileDirectory;
        break;
    }
  }

  window["modal"].setState({
    title: <h3>New Folder</h3>,
    size: "lg",
    targetComponent: this,
    callback: function () {
      window["modal"].setState({ show: false, overflowY: "auto" });
      Fun.createNotification({
        type: "success",
        message: "New folder has been created",
      });
      builder.rerunComponent();
    },
    footer: UniFun.modalCommonFooter("modal", "Create"),
    show: true,
    body: (
      <Builder
        module="unifiles"
        view="newFolder"
        moduleParams={builder.state.view}
        inputData={{
          data: [
            {
              parent: parent,
            },
          ],
        }}
        key={Fun.uuid()}
        ref={(refComponent) => {
          window["modalbody"] = refComponent;
        }}
      ></Builder>
    ),
  });
};

const filesUploadFile = function (parent) {
  // let builder = parentBuilder;
  let inputFileRef = parent.onTargetClick();
  //   fileInputRef
  // window["modal"].setState({
  //   title: <h3>Upload File</h3>,
  //   size: "modal-lg",
  //   callback: function () {
  //     window["modal"].setState({ show: false });
  //     builder.rerunComponent();
  //     Fun.createNotification({
  //       type: "success",
  //       message: "Update update was successful!",
  //     });
  //   },
  //   footer: UniFun.modalCommonFooter("modal", "Upload"),
  //   show: true,
  //   body: (
  //     <Builder
  //       module="uniupload"
  //       key={Fun.uuid()}
  //       view="detail"
  //       ref={(refComponent) => {
  //         window["modalbody"] = refComponent;
  //       }}
  //     ></Builder>
  //   ),
  // });
};

const filesToggleFavorite = function (
  isFavorite,
  fileid,
  parentBuilder,
  isFolder,
  title
) {
  let msg = "'" + title + "' has been added to favorites";
  let url = "/file/{fileid}/favorite/";
  if (isFolder) {
    url = "/folder/{fileid}/favorite/";
  }

  if (isFavorite) {
    msg = "'" + title + "' has been removed from favorites";
    url = "/file/{fileid}/unfavorite/";
    if (isFolder) {
      url = "/folder/{fileid}/unfavorite/";
    }
  }

  let builder = parentBuilder;
  Fun.fetch({
    url: { url: url },
    method: "PATCH",
    callback: function (args) {
      Fun.createNotification({
        type: "success",
        message: msg,
      });

      builder.rerunComponent();
    },
    parameters: {
      headers: {
        "content-type": "application/json",
      },
      replace: {
        fileid: fileid,
      },
    },
    submittedData: JSON.stringify([]),
  });
};

const filesDeleteFile = function (fileid, parentBuilder) {
  let parameters = {
    replace: {
      _id: fileid,
    },
  };

  let builder = parentBuilder;

  window["modal2"].setState({
    title: <h3>Delete file?</h3>,
    size: "lg",
    targetComponent: this,
    show: true,
    callback: function () {
      builder.rerunComponent();
      window["modal2"].setState({ show: false, overflowY: "auto" });
    },
    footer: UniFun.modalCommonFooter("modal2", "Delete", "btn-danger"),
    body: (
      <Builder
        module="unifiles"
        view="filedelete"
        parameters={parameters}
        ref={(refComponent) => {
          window["modal2body"] = refComponent;
        }}
      ></Builder>
    ),
  });
};

const filesDeleteFolder = function (folderid, parentBuilder) {
  let parameters = {
    replace: {
      _id: folderid,
    },
  };

  let builder = parentBuilder;

  window["modal2"].setState({
    title: <h3>Delete Folder?</h3>,
    size: "lg",
    targetComponent: this,
    show: true,
    callback: function () {
      builder.rerunComponent();
      window["modal2"].setState({ show: false, overflowY: "auto" });
    },
    footer: UniFun.modalCommonFooter("modal2", "Delete", "btn-danger"),
    body: (
      <Builder
        module="unifiles"
        view="folderdelete"
        parameters={parameters}
        ref={(refComponent) => {
          window["modal2body"] = refComponent;
        }}
      ></Builder>
    ),
  });

  // window["modal"].setState({
  //   title: <h3>Delete Folder?</h3>,
  //   size: "modal-lg",
  //   targetComponent: this,
  //   show: true,
  //   footer: UniFun.modalCommonFooter("modal", "Delete"),
  //   body: (
  //     <Builder
  //       module="unifiles"
  //       view="deletefolder"
  //       parameters={parameters}
  //       ref={(refComponent) => {
  //         window["modalbody"] = refComponent;
  //       }}
  //     ></Builder>
  //   ),
  // });
};

const filesFolderDetail = function (folderid, parentBuilder) {
  let parameters = {
    replace: {
      _id: folderid,
    },
  };

  let builder = parentBuilder;

  window["modal2"].setState({
    title: <h3>Folder details</h3>,
    size: "lg",
    targetComponent: this,
    show: true,
    footer: UniFun.modalCommonFooter("modal2", "Save"),
    callback: function () {
      window["modal2"].setState({ show: false, overflowY: "auto" });
      builder.rerunComponent();
    },
    body: (
      <Builder
        module="unifiles"
        view="folderdetails"
        parameters={parameters}
        ref={(refComponent) => {
          window["modal2body"] = refComponent;
        }}
      ></Builder>
    ),
  });
};

const filesCardTemplateTop = function () {
  let data = this.state.data;
  let builder = this.props.builder;

  let hasParentFolder = false;
  if (data[0]) {
    if (data[0].isParentFolder.value == true) {
      hasParentFolder = true;
    }
  }

  let countFiles = hasParentFolder ? data.length - 1 : data.length;

  let tools = [
    <button
      type="button"
      className="btn  btn-light"
      onClick={(e) => filesNewFolder(builder)}
    >
      New Folder
    </button>,
    <button
      type="button"
      className="btn btn-primary ml-3 bg-purple"
      onClick={this.onTargetClick}
    >
      Upload File
    </button>,
  ];

  if (builder.state.view == "library") {
    if (!UniFun.userIsEligible({ forWhat: "files.library" })) {
      tools = [];
    }
  }

  if (builder.state.view == "groupsFiles") {
    if (!UniFun.userIsEligible({ forWhat: "groupsFiles" })) {
      tools = [];
    }
  }

  if (builder.state.view == "spaceFiles") {
    if (UniFun.userIsEligible({ forWhat: "default" })) {
      tools = [
        <button
          type="button"
          className="btn  btn-light"
          onClick={(e) => filesNewFolder(builder)}
        >
          New Folder
        </button>,
        <button
          type="button"
          className="btn btn-primary ml-3 bg-purple"
          onClick={this.onTargetClick}
        >
          Upload File
        </button>,
      ];
    } else {
      tools = [];
    }
  }

  let breadCrumbData = data[0] ? data[0]["breadCrumb"] : { value: [] };

  let breadCrumb = filesBreadcrumb(breadCrumbData, data, builder);
  return (
    <React.Fragment>
      <div className="row">
        <div className="col-lg-6 col-md-12 pt-2 pl-4">
          <div>{breadCrumb}</div>
          {/* <span className="text-dark ">There are {countFiles} files</span> */}
        </div>

        <div className="col-lg-6 col-md-12 text-right p-2 pr-4">{tools}</div>
      </div>
    </React.Fragment>
  );
};

const filesEmptyFolder = function () {
  let builder = this.props.builder;

  if (builder.state.view == "favorites") {
    return "";
  }

  let folderName = "";

  if (this.state.rawData) {
    if (this.state.rawData.data[0]) {
      if (this.state.rawData.data[0].name) {
        folderName = this.state.rawData.data[0].name;
      }
    }
  }
  //if (!UniFun.userIsEligible({ forWhat: "default" })) {
  // console.log(builder.state.view);
  // if (
  //   ((builder.state.view == "spaceFiles" ||
  //     builder.state.view == "library" ||
  //     builder.state.view == "favorites") &&
  //     folderName == "") ||
  //   folderName == "Library"
  // ) {
  //   return (
  //     <div className="row mt-4 " style={{ height: "500px" }}>
  //       <div className="col-12 text-center">
  //         <h5>
  //           <strong>All files is empty </strong>
  //         </h5>
  //         <p>
  //           {builder.state.view == "library"
  //             ? "Files and folders shared in Library will appear here."
  //             : ""}
  //         </p>
  //       </div>
  //     </div>
  //   );
  // }
  //}

  let title = "All files";
  if (
    folderName != "" &&
    folderName.toLowerCase() != "library" &&
    folderName != "/"
  ) {
    title = folderName;
  }
  let subtitle = "";

  switch (builder.state.view) {
    case "spaceFiles":
      if (UniFun.userIsEligible({ forWhat: "default" })) {
        subtitle = (
          <React.Fragment>
            Drag and drop files into this window or create a{" "}
            <a
              href="javascript:void(0);"
              className="text-primary"
              onClick={(e) => filesNewFolder(builder)}
            >
              <strong>new folder</strong>
            </a>
          </React.Fragment>
        );
      }
      break;

    case "trainingFiles":
      title = this.state.rawData.data[0].breadCrumb[2].name;
      subtitle = (
        <React.Fragment>
          Files included in this session will appear here
        </React.Fragment>
      );

      break;

    case "library":
      if (UniFun.userIsEligible({ forWhat: "default" })) {
        subtitle = (
          <React.Fragment>
            Drag and drop files into this window or create a{" "}
            <a
              href="javascript:void(0);"
              className="text-primary"
              onClick={(e) => filesNewFolder(builder)}
            >
              <strong>new folder</strong>
            </a>
          </React.Fragment>
        );
      } else {
        subtitle =
          "Files and folders shared in " + folderName + " will appear here.";
      }
      break;

    case "ownedbyme":
      subtitle = (
        <React.Fragment>
          Drag and drop files into this window or create a{" "}
          <a
            href="javascript:void(0);"
            className="text-primary"
            onClick={(e) => filesNewFolder(builder)}
          >
            <strong>new folder</strong>
          </a>
        </React.Fragment>
      );

      break;

    case "groupsFiles":
      if (this.state.rawData) {
        if (this.state.rawData.data[0]) {
          if (this.state.rawData.data[0].breadCrumb) {
            title =
              this.state.rawData.data[0].breadCrumb[
                this.state.rawData.data[0].breadCrumb.length - 1
              ].name;
          }
        }
      }
      // return title;
      break;

    case "groupSpaces":
      if (this.state.rawData.data.breadCrumb) {
        title = subtitle =
          this.state.rawData.data.breadCrumb[
            this.state.rawData.data.breadCrumb.length - 1
          ].name;
      }
      return (
        <div className="row mt-4 " style={{ height: "500px" }}>
          <div className="col-12 text-center">
            <h5>
              <strong>{title} is empty </strong>
            </h5>

            <p>Files shared to {subtitle} will appear here</p>
          </div>
        </div>
      );
      break;
  }

  return (
    <div className="row mt-4 " style={{ height: "500px" }}>
      <div className="col-12 text-center">
        <h5>
          <strong>{title} is empty </strong>
        </h5>

        <p>{subtitle}</p>
      </div>
    </div>
  );
};

const cellTemplatesTitle = function (data, rec, obj) {
  let uid = Fun.uuid();

  let builder = obj.props.builder;

  let icon = [];

  let mimetype = rec.mime.value;

  icon = UniFun.mimeTypeIcon(mimetype);

  let fileChoices = [
    <li
      className="list-group-item p-2 pl-3 pointer"
      onClick={(e) =>
        UniFun.filesPreviewFile(
          {
            id: rec._id.value,
            icon: icon,
            title: rec.title.value,
            path: rec.path.value,
            owner: rec.owner.value,
            filedownloadurl: rec.filedownloadurl.value,
            rec: rec,
          },
          builder
        )
      }
    >
      Preview
    </li>,
    <li className="list-group-item p-2 pl-3 pointer">
      <a href={rec.filedownloadurl.value} target="new">
        Download
      </a>
    </li>,
    <li
      className="list-group-item p-2 pl-3 pointer"
      onClick={(e) => UniFun.filesFileDetail(rec._id.value, builder)}
    >
      View file details
    </li>,
  ];

  if (obj.props.builder.state.view != "spaceFiles") {
    fileChoices.push(
      <li
        className="list-group-item p-2 pl-3 pointer"
        onClick={(e) =>
          filesToggleFavorite(
            rec.isFavorite.value,
            rec._id.value,
            builder,
            rec.isFolder.value,
            rec.title.value
          )
        }
      >
        {rec.isFavorite.value == true
          ? "Remove from favorites"
          : "Add to favorites"}
      </li>
    );
  }

  if (obj.props.builder.state.view != "favorites") {
    if (
      obj.props.builder.state.view == "library" ||
      obj.props.builder.state.view == "groupsFiles"
    ) {
      if (UniFun.userIsEligible({ forWhat: "default" })) {
        fileChoices.push(
          <li
            className="list-group-item p-2  pl-3 text-danger  pointer"
            onClick={(e) => filesDeleteFile(rec._id.value, builder)}
          >
            Delete
          </li>
        );
      }
    }
  } else {
    fileChoices.push(
      <li
        className="list-group-item p-2  pl-3 text-danger  pointer"
        onClick={(e) => filesDeleteFile(rec._id.value, builder)}
      >
        Delete
      </li>
    );
  }

  let linkTitle = (
    <span
      className="pointer"
      title={rec.title.value}
      onClick={(e) =>
        UniFun.filesPreviewFile(
          {
            id: rec._id.value,
            icon: icon,
            title: rec.title.value,
            path: rec.path.value,
            owner: rec.owner.value,
            filedownloadurl: rec.filedownloadurl.value,
            rec: rec,
          },
          builder
        )
      }
    >
      <img
        src={icon}
        className="mr-2"
        style={{ width: "21px", paddingBottom: "2px" }}
      ></img>{" "}
      {UniFun.substring(rec.title.value, 0, 55)}
    </span>
  );

  if (rec.isFolder) {
    if (rec.isFolder.value) {
      let prefix = "";

      if (rec["root"]) {
        if (rec["root"].value) {
          if (rec["root"].value == "library") {
            prefix = "/files/library/";
          }
          if (rec["root"].value == "user") {
            prefix = "/files/ownedbyme/";
          }
        }
      }

      mimetype = "folder";

      fileChoices = [
        <li className="list-group-item p-2 pl-3 pointer">
          <Link
            className="pointer d-flex"
            to={prefix + "?_id=" + rec["_id"]["value"]}
          >
            Open
          </Link>
        </li>,
        <li className="list-group-item p-2 pl-3 pointer">
          <a href={rec.filedownloadurl.value} target="new">
            Download
          </a>
        </li>,
        <li
          className="list-group-item p-2 pl-3 pointer"
          onClick={(e) => filesFolderDetail(rec._id.value, builder)}
        >
          View folder details
        </li>,
        <li
          className="list-group-item p-2 pl-3 pointer"
          onClick={(e) =>
            filesToggleFavorite(
              rec.isFavorite.value,
              rec._id.value,
              builder,
              rec.isFolder.value,
              rec.title.value
            )
          }
        >
          {rec.isFavorite.value == true
            ? "Remove from favorites"
            : "Add to favorites"}
        </li>,
      ];

      if (obj.props.builder.state.view != "favorites") {
        if (
          obj.props.builder.state.view == "library" ||
          obj.props.builder.state.view == "groupsFiles"
        ) {
          if (UniFun.userIsEligible({ forWhat: "default" })) {
            fileChoices.push(
              <li
                className="list-group-item p-2  pl-3 text-danger  pointer"
                onClick={(e) => filesDeleteFolder(rec._id.value, builder)}
              >
                Delete
              </li>
            );
          }
        } else {
          fileChoices.push(
            <li
              className="list-group-item p-2  pl-3 text-danger  pointer"
              onClick={(e) => filesDeleteFolder(rec._id.value, builder)}
            >
              Delete
            </li>
          );
        }
      }

      let url = prefix + "?_id=" + rec["_id"]["value"];

      if (rec["type"]["value"] == "spaceroot") {
        url = prefix + "?spacegroups=" + rec["_id"]["value"];
      }
      linkTitle = (
        <Link className="pointer" to={url} title={rec.title.value}>
          <img
            src={icon}
            className="mr-2"
            style={{ width: "21px", paddingBottom: "2px" }}
          ></img>{" "}
          {UniFun.substring(rec.title.value, 0, 55)}
        </Link>
      );
    }
  }

  let favicon = (
    <i
      className="far fa-star ml-2 pointer"
      onClick={(e) =>
        filesToggleFavorite(
          false,
          rec._id.value,
          builder,
          rec.isFolder.value,
          rec.title.value
        )
      }
      title="Add to favorites"
    ></i>
  );

  if (rec.isFavorite.value == true) {
    favicon = (
      <i
        className="fas fa-star ml-2  pointer"
        onClick={(e) =>
          filesToggleFavorite(
            true,
            rec._id.value,
            builder,
            rec.isFolder.value,
            rec.title.value
          )
        }
        title="Remove from favorites"
      ></i>
    );
  }

  if (obj.props.builder.state.view == "spaceFiles") {
    favicon = "";
  }

  let isParentFolder = false;

  if (rec.isParentFolder) {
    isParentFolder = rec.isParentFolder.value;
  }

  if (rec.mime.value.startsWith("application/epub")) {
    linkTitle = (
      <Link
        className="row pointer rowhover"
        to={"/epub/viewer/" + rec._id.value + "?title=" + rec.title.value}
      >
        {UniFun.substring(rec.title.value, 0, 55)}
      </Link>
    );
  }

  return (
    <React.Fragment>
      {linkTitle}
      <span className="float-right mr-4 grid-controls">
        {isParentFolder == true ? (
          ""
        ) : (
          <React.Fragment>
            <a href={rec.filedownloadurl.value} target="new" title="Download">
              <img
                src="/uni/svg/download.svg"
                style={{ width: "14px" }}
                className="ml-2 "
              ></img>
            </a>
            {favicon}
            <i
              className="fas fa-ellipsis-h pointer ml-2"
              data-toggle="collapse"
              data-target={"#" + uid}
            ></i>
            <div
              className="position-absolute bg-white ml-4 mt-2 collapse"
              id={uid}
            >
              <ul className="list-group " style={{ width: "200px" }}>
                {fileChoices}
              </ul>
            </div>
          </React.Fragment>
        )}
      </span>
    </React.Fragment>
  );
};

const UniFiles = {
  auth: "",
  views: {
    spaceFiles: {
      auth: "",
      apis: {
        view: {
          url: "/folder/{id}?space=true",
          parameters: {
            headers: {},
            replace: {},
          },
          formatter: function (args) {
            return UniFilesFormatter(args);
          },
        },
      },
      template: "foldergrid",
      templateConfig: {
        colsTemplateCount: 12,
        includeProperties: ["title", "updatedAt", "size"],
        excludeProperties: [],
        headerTitle: "Personal files",
        headerTitleIcon: "/uni/svg/Filesmain.svg",
        events: {
          onDrop: function (files) {
            let parent = this.state.rawData.data[0]._id;

            UniFun.fileProgress({
              control: window["fileupload"],
              files: files,
              parent: parent,
              showModalOnDelete: false,
              builder: this.props.builder,
              rerunComponent: true,
            });
          },
        },
        gridConfig: {
          emptyFolder: filesEmptyFolder,
          headers: {
            title: {
              attributes: {
                className: "w-50",
              },
            },
            updatedAt: {
              attributes: {
                className: "d-none d-md-table-cell",
              },
            },
            size: {
              attributes: {
                className: "d-none d-md-table-cell",
              },
            },
          },
          cell: {
            updatedAt: {
              attributes: {
                className: "d-none d-md-table-cell",
              },
            },
            size: {
              attributes: {
                className: "d-none d-md-table-cell",
              },
            },
          },
          cellTemplates: {
            title: cellTemplatesTitle,
          },
        },

        cardTemplateTop: filesCardTemplateTop,
      },
      pk: "_id",
      properties: UniFilesProperties({ editable: false }),
    },
    meetingFiles: {
      auth: "",
      apis: {
        view: {
          url: "/folder/{id}?space=true",
          parameters: {
            headers: {},
            replace: {},
          },
          formatter: function (args) {
            return UniFilesFormatter(args);
          },
        },
      },
      template: "foldergrid",
      templateConfig: {
        colsTemplateCount: 12,
        includeProperties: ["title", "updatedAt", "size"],
        excludeProperties: [],
        headerTitle: "Personal files",
        headerTitleIcon: "/uni/svg/Filesmain.svg",
        events: {
          onDrop: function (files) {
            let parent = this.state.rawData.data[0]._id;

            UniFun.fileProgress({
              control: window["fileupload"],
              files: files,
              parent: parent,
              showModalOnDelete: false,
              builder: this.props.builder,
              rerunComponent: true,
            });
          },
        },
        gridConfig: {
          emptyFolder: filesEmptyFolder,
          headers: {
            title: {
              attributes: {
                className: "w-50",
              },
            },
            updatedAt: {
              attributes: {
                className: "d-none d-md-table-cell",
              },
            },
            size: {
              attributes: {
                className: "d-none d-md-table-cell",
              },
            },
          },
          cell: {
            updatedAt: {
              attributes: {
                className: "d-none d-md-table-cell",
              },
            },
            size: {
              attributes: {
                className: "d-none d-md-table-cell",
              },
            },
          },
          cellTemplates: {
            title: cellTemplatesTitle,
          },
        },

        cardTemplateTop: filesCardTemplateTop,
      },
      pk: "_id",
      properties: UniFilesProperties({ editable: false }),
    },
    ownedbyme: {
      auth: "",
      apis: {
        view: {
          url: "/folder/{id}",
          parameters: {
            headers: {},
            replace: {
              id: function () {
                if (Fun.getParameterByName("_id")) {
                  return Fun.getParameterByName("_id");
                }
                return localStorage.getItem("personalFolderDirectory");
              }, // "60b65c86566f5c2efac9e9a4",
            },
          },
          formatter: function (data) {
            return UniFilesFormatter(data);
          },
        },
      },
      template: "foldergrid",
      templateConfig: {
        colsTemplateCount: 12,
        includeProperties: ["title", "updatedAt", "size"],
        excludeProperties: [],
        headerTitle: "Personal files",
        headerTitleIcon: "/uni/svg/Filesmain.svg",
        events: {
          onDrop: function (files) {
            let parent = this.state.rawData.data[0]._id;

            UniFun.fileProgress({
              control: window["fileupload"],
              files: files,
              parent: parent,
              showModalOnDelete: false,
              builder: this.props.builder,
              rerunComponent: true,
            });
          },
        },
        gridConfig: {
          emptyFolder: filesEmptyFolder,
          headers: {
            title: {
              attributes: {
                className: "w-50",
              },
            },
            updatedAt: {
              attributes: {
                className: "d-none d-md-table-cell",
              },
            },
            size: {
              attributes: {
                className: "d-none d-md-table-cell",
              },
            },
          },
          cell: {
            updatedAt: {
              attributes: {
                className: "d-none d-md-table-cell",
              },
            },
            size: {
              attributes: {
                className: "d-none d-md-table-cell",
              },
            },
          },
          cellTemplates: {
            title: cellTemplatesTitle,
            updatedAt: function (data, rec) {
              if (rec.isParentFolder.value === true) {
                return "";
              }
              return data;
            },
          },
        },

        cardTemplateTop: filesCardTemplateTop,
        header: UniFun.commonHeader,
      },
      pk: "_id",
      properties: UniFilesProperties({ editable: false }),
    },
    shared: {
      auth: "",
      apis: {
        view: {
          //url: "https://business-v2-api.interfima.org/folder/:folderId",
          data: {
            data: [
              {
                title: "Contract",
                members: "You / other name",
                modified: "25/12/2020 18:30",
                isFolder: true,
                mime: "",
                size: "500KB",
              },
              {
                title: "Financial 2021.pdf",
                members: "You / other name",
                modified: "25/12/2020 18:30",
                isFolder: false,
                mime: "application/pdf",
                size: "30KB",
              },
              {
                title: "Vacation Forms 2021.xlsx",
                members: "You / other name",
                modified: "25/12/2020 18:30",
                isFolder: false,
                mime: "application/xlsx",
                size: "13KB",
              },
              {
                title: "Product User Manual.docx",
                members: "You / other name",
                modified: "25/12/2020 18:30",
                isFolder: false,
                mime: "application/docx",
                size: "5.2MB",
              },
            ],
          },
          parameters: {
            headers: {},
            replace: {},
          },
          formatter: function (data) {
            return UniFilesFormatter(data);
          },
        },
      },
      template: "grid",
      templateConfig: {
        colsTemplateCount: 12,
        includeProperties: ["title", "owner", "updatedAt", "size"],
        excludeProperties: [],
        headerTitle: "Shared with me",
        headerTitleIcon: "/uni/svg/Filesmain.svg",
        gridConfig: {
          headers: {
            title: {
              attributes: {
                className: "w-50",
              },
            },
          },
          cellTemplates: {
            title: cellTemplatesTitle,
          },
        },
        header: UniFun.commonHeader,
      },
      pk: "_id",
      properties: UniFilesProperties({ editable: false }),
    },
    favorites: {
      auth: "",
      apis: {
        view: {
          url: "{url}",
          parameters: {
            headers: {},
            replace: {
              url: function () {
                if (Fun.getParameterByName("_id")) {
                  return "/folder/" + Fun.getParameterByName("_id");
                }
                return "/file/" + localStorage.getItem("userid") + "/favorites";
              },
            },
          },
          formatter: function (data) {
            return UniFileFormatter(data);
          },
        },
      },
      template: "grid",
      templateConfig: {
        colsTemplateCount: 12,
        includeProperties: ["title", "updatedAt", "size"],
        excludeProperties: [],
        headerTitle: "Favorites",
        headerTitleIcon: "/uni/svg/Filesmain.svg",
        gridConfig: {
          emptyFolder: function () {
            return (
              <div className="row mt-4 " style={{ height: "500px" }}>
                <div className="col-12 text-center">
                  <h5>
                    <strong>All files is empty </strong>
                  </h5>

                  <p>Add files and folders to favorites to find them easily</p>
                </div>
              </div>
            );
          },
          headers: {
            title: {
              attributes: {
                className: "w-50",
              },
            },
            updatedAt: {
              attributes: {
                className: "d-none d-md-table-cell",
              },
            },
            size: {
              attributes: {
                className: "d-none d-md-table-cell",
              },
            },
          },
          cell: {
            updatedAt: {
              attributes: {
                className: "d-none d-md-table-cell",
              },
            },
            size: {
              attributes: {
                className: "d-none d-md-table-cell",
              },
            },
          },
          cellTemplates: {
            title: cellTemplatesTitle,
          },
        },
        cardTemplateTop: function () {
          let data = this.state.data;
          let builder = this.props.builder;

          let hasParentFolder = false;
          if (data[0]) {
            if (data[0].isParentFolder.value == true) {
              hasParentFolder = true;
            }
          }

          let countFiles = hasParentFolder ? data.length - 1 : data.length;

          let breadCrumbData = data[0] ? data[0]["breadCrumb"] : { value: [] };
          let breadCrumb = filesBreadcrumb(breadCrumbData, data, builder);
          return (
            <React.Fragment>
              <div className="row">
                <div className="col-6 pt-2 pl-4">
                  <div>{breadCrumb}</div>
                  {/* <span className="text-dark ">
                    There are {data.length} files
                  </span> */}
                </div>
                <div className="col-6 text-right p-2"></div>
              </div>
            </React.Fragment>
          );
        },
        header: UniFun.commonHeader,
      },
      pk: "_id",
      properties: UniFilesProperties({ editable: false }),
    },
    library: {
      auth: "",
      apis: {
        view: {
          url: "{url}",
          parameters: {
            headers: {},
            replace: {
              url: function () {
                if (Fun.getParameterByName("_id")) {
                  return "/folder/" + Fun.getParameterByName("_id");
                }
                return "/library";
              },
            },
          },
          formatter: function (data) {
            return UniFilesFormatter(data);
          },
        },
      },
      template: function () {
        if (UniFun.userIsEligible({ forWhat: "default" })) {
          return "foldergrid";
        }
        return "grid";
      },
      templateConfig: {
        colsTemplateCount: 12,
        includeProperties: ["title", "authorsCount", "updatedAt", "size"],
        excludeProperties: [],
        headerTitle: "Company files",
        headerTitleIcon: "/uni/svg/Filesmain.svg",
        events: {
          onDrop: function (files) {
            let parent = this.state.rawData.data[0]._id;

            UniFun.fileProgress({
              control: window["fileupload"],
              files: files,
              parent: parent,
              showModalOnDelete: false,
              builder: this.props.builder,
              rerunComponent: true,
            });
          },
        },
        gridConfig: {
          emptyFolder: filesEmptyFolder,
          headers: {
            title: {
              attributes: {
                className: "w-50",
              },
            },
            authorsCount: {
              attributes: {
                className: "d-none d-md-table-cell",
              },
            },
            updatedAt: {
              attributes: {
                className: "d-none d-md-table-cell",
              },
            },
            size: {
              attributes: {
                className: "d-none d-md-table-cell",
              },
            },
          },
          cell: {
            authorsCount: {
              attributes: {
                className: "d-none d-md-table-cell",
              },
            },
            updatedAt: {
              attributes: {
                className: "d-none d-md-table-cell",
              },
            },
            size: {
              attributes: {
                className: "d-none d-md-table-cell",
              },
            },
          },
          cellTemplates: {
            title: cellTemplatesTitle,
            updatedAt: function (data, rec) {
              if (rec.isParentFolder.value === true) {
                return "";
              }
              return data;
            },
          },
        },
        cardTemplateTop: filesCardTemplateTop,
        header: UniFun.commonHeader,
      },
      pk: "_id",
      properties: UniFilesProperties({ editable: false }),
    },
    groupsFiles: {
      auth: "",
      apis: {
        view: {
          url: "{url}",
          parameters: {
            headers: {},
            replace: {
              url: function () {
                if (Fun.getParameterByName("spacegroups")) {
                  return (
                    "/spaces/root/" +
                    Fun.getParameterByName("spacegroups") +
                    "?treeview=false"
                  );
                }

                if (Fun.getParameterByName("_id")) {
                  return "/folder/" + Fun.getParameterByName("_id");
                }
                return "/root-spaces";
              },
            },
          },
          formatter: function (data) {
            return UniFilesFormatter(data);
          },
          // formatter: function (data) {
          //   // let folders = data;

          //   // if (data) {
          //   //   if (data.data) {
          //   //     let foldersData = data.data.map((itm, idx) => {
          //   //       itm["type"] = "spaceroot";
          //   //       if (itm.fileDirectory) {
          //   //         itm["type"] = "spacegroups";
          //   //         itm["_id"] = itm.fileDirectory;
          //   //       }
          //   //       return itm;
          //   //     });

          //   //     if (!Fun.getParameterByName("_id")) {
          //   //       folders = { data: [{ folders: data.data }] };
          //   //     }
          //   //   }
          //   // }

          //   return UniFilesFormatter(data);
          // },
        },
      },
      template: function () {
        if (
          UniFun.userIsEligible({ forWhat: "default" }) ||
          UniFun.userIsEligible({ forWhat: "viewer" })
        ) {
          return "foldergrid";
        }
        return "grid";
      },
      templateConfig: {
        colsTemplateCount: 12,
        includeProperties: ["title", "updatedAt", "size"],
        excludeProperties: [],
        headerTitle: "Spaces",
        headerTitleIcon: "/uni/svg/Filesmain.svg",
        events: {
          onDrop: function (files) {
            let parent = this.state.rawData.data[0]._id;

            UniFun.fileProgress({
              control: window["fileupload"],
              files: files,
              parent: parent,
              showModalOnDelete: false,
              builder: this.props.builder,
              rerunComponent: true,
            });
          },
        },
        gridConfig: {
          emptyFolder: filesEmptyFolder,
          headers: {
            title: {
              attributes: {
                className: "w-50",
              },
            },
            authorsCount: {
              attributes: {
                className: "d-none d-md-table-cell",
              },
            },
            updatedAt: {
              attributes: {
                className: "d-none d-md-table-cell",
              },
            },
            size: {
              attributes: {
                className: "d-none d-md-table-cell",
              },
            },
          },
          cell: {
            authorsCount: {
              attributes: {
                className: "d-none d-md-table-cell",
              },
            },
            updatedAt: {
              attributes: {
                className: "d-none d-md-table-cell",
              },
            },
            size: {
              attributes: {
                className: "d-none d-md-table-cell",
              },
            },
          },
          cellTemplates: {
            title: cellTemplatesTitle,
            updatedAt: function (data, rec) {
              if (rec.isParentFolder.value === true) {
                return "";
              }
              return data;
            },
          },
        },
        cardTemplateTop: function () {
          let breadCrumb = { value: this.state.rawData.data[0].breadCrumb };

          // if (breadCrumb[0]) {
          //   breadCrumb[0].type = "space";
          // }

          // let breadCrumbGroups = { value: [breadCrumb[0]] };
          // // breadCrumb.value = {};
          // // breadCrumb.value = breadCrumb;
          let builder = this.props.builder;
          let tools = [];

          if (!UniFun.userIsEligible({ forWhat: "viewer" })) {
            tools = [
              <button
                type="button"
                className="btn  btn-light"
                onClick={(e) => filesNewFolder(builder)}
              >
                New Folder
              </button>,
              <button
                type="button"
                className="btn btn-primary ml-3 bg-purple"
                onClick={this.onTargetClick}
              >
                Upload File
              </button>,
            ];
          }

          return (
            <React.Fragment>
              <div className="row">
                <div className="col-lg-6 col-md-12 pt-2 pl-4">
                  <div>
                    {filesBreadcrumb(
                      breadCrumb,
                      [
                        {
                          totalCount: {
                            value: this.state.rawData.data[0].files.length,
                          },
                        },
                      ],
                      this.props.builder
                    )}
                  </div>
                </div>

                <div className="col-lg-6 col-md-12 text-right p-2 pr-4">
                  {tools}
                </div>
              </div>
            </React.Fragment>
          );
        },
        header: UniFun.commonHeader,
      },
      pk: "_id",
      properties: UniFilesProperties({ editable: false }),
    },
    trainingFiles: {
      auth: "",
      apis: {
        view: {
          url: "/session/{id}/files",
          parameters: {
            headers: {},
            replace: {
              url: function () {
                return Fun.getURLPathValue(8);
              },
            },
          },
          formatter: function (args) {
            return UniFilesFormatter(args);
          },
        },
      },
      template: function () {
        if (UniFun.userIsEligible({ forWhat: "default" })) {
          return "foldergrid";
        }
        return "grid";
      },
      templateConfig: {
        colsTemplateCount: 12,
        includeProperties: ["title", "updatedAt", "size"],
        excludeProperties: [],
        events: {
          onDrop: function (files) {
            let parent = this.state.rawData.data[0]._id;

            UniFun.fileProgress({
              control: window["fileupload"],
              files: files,
              parent: parent,
              showModalOnDelete: false,
              builder: this.props.builder,
              rerunComponent: true,
            });
          },
        },
        gridConfig: {
          emptyFolder: filesEmptyFolder,
          headers: {
            title: {
              attributes: {
                className: "w-50",
              },
            },
            authorsCount: {
              attributes: {
                className: "d-none d-md-table-cell",
              },
            },
            updatedAt: {
              attributes: {
                className: "d-none d-md-table-cell",
              },
            },
            size: {
              attributes: {
                className: "d-none d-md-table-cell",
              },
            },
          },
          cell: {
            authorsCount: {
              attributes: {
                className: "d-none d-md-table-cell",
              },
            },
            updatedAt: {
              attributes: {
                className: "d-none d-md-table-cell",
              },
            },
            size: {
              attributes: {
                className: "d-none d-md-table-cell",
              },
            },
          },
          cellTemplates: {
            title: function (data, rec) {
              let icon = UniFun.mimeTypeIcon(rec._id.data.mime);
              let isAudioRecording = false;
              let isVideoRecording = false;

              if (rec._id.data.recording) {
                if (rec._id.data.mime.startsWith("audio")) {
                  icon = UniFun.mimeTypeIcon("audiorecording");
                  isAudioRecording = true;
                } else {
                  icon = UniFun.mimeTypeIcon("recording");
                  isVideoRecording = true;
                }
              }

              if (rec._id.data.mime.startsWith("application/epub")) {
                return (
                  <Link
                    className="pointer"
                    to={
                      "/epub/viewer/" +
                      rec._id.value +
                      "?title=" +
                      rec.title.value
                    }
                  >
                    <img
                      src={icon}
                      className="mr-2"
                      style={{ width: "21px", paddingBottom: "2px" }}
                    ></img>
                    {UniFun.substring(rec.title.value, 0, 55)}
                  </Link>
                );
              } else if (rec._id.data.mime.startsWith("application/pdf")) {
                return (
                  <React.Fragment>
                    <Link
                      className="pointer"
                      to={
                        "/pdf/viewer/" +
                        rec._id.value +
                        "?title=" +
                        rec.title.value
                      }
                    >
                      <img
                        src={icon}
                        className="mr-2"
                        style={{ width: "21px", paddingBottom: "2px" }}
                      ></img>
                      {UniFun.substring(rec.title.value, 0, 55)}
                    </Link>
                    {UniFun.allowDownloadPdfForTraining() ? (
                      <span className="float-right mr-4 grid-controls">
                        <a
                          href={window["baseurl"](
                            "/agenda-file/data/" +
                              rec._id.value +
                              "?type=download&jwt=" +
                              localStorage.getItem("jwt")
                          )}
                          target="new"
                        >
                          <img
                            src="/uni/svg/download.svg"
                            style={{ height: "14px" }}
                          ></img>
                        </a>
                      </span>
                    ) : (
                      ""
                    )}
                  </React.Fragment>
                );
              } else if (rec._id.data.mime.startsWith("video/mp4")) {
                return (
                  <Link
                    className="pointer"
                    to={
                      "/video/viewer/" +
                      rec._id.value +
                      "?title=" +
                      rec.title.value
                    }
                  >
                    <img
                      src={icon}
                      className="mr-2"
                      style={{ width: "21px", paddingBottom: "2px" }}
                    ></img>
                    {UniFun.substring(rec.title.value, 0, 55)}
                  </Link>
                );
              } else {
                return (
                  <span
                    className="pointer"
                    onClick={(e) => {
                      UniFun.readingsPreviewFile(
                        {
                          title: rec.title.value,
                          mimetype: rec._id.data.mime,
                          isAudioRecording: isAudioRecording,
                          isVideoRecording: isVideoRecording,
                          fileurl: window["baseurl"](
                            "/agenda-file/data/" +
                              rec._id.value +
                              "?type=preview&jwt=" +
                              localStorage.getItem("jwt")
                          ),
                          filedownloadurl: window["baseurl"](
                            "/agenda-file/data/" +
                              rec._id.value +
                              "?type=download&jwt=" +
                              localStorage.getItem("jwt")
                          ),
                        },
                        null
                      );
                    }}
                    title={rec.title.value}
                  >
                    <img
                      src={icon}
                      className="mr-2"
                      style={{ width: "21px", paddingBottom: "2px" }}
                    ></img>{" "}
                    {UniFun.substring(rec.title.value, 0, 55)}
                  </span>
                );
              }
            },
            updatedAt: function (data, rec) {
              if (rec.isParentFolder.value === true) {
                return "";
              }
              return data;
            },
          },
        },
        cardTemplateTop: function () {
          let breadCrumb = this.state.rawData.data[0]
            ? { value: this.state.rawData.data[0].breadCrumb }
            : { value: [] };

          //  let breadCrumbData = data[0] ? data[0]["breadCrumb"] : { value: [] };

          // if (breadCrumb[0]) {
          //   breadCrumb[0].type = "space";
          // }

          // let breadCrumbGroups = { value: [breadCrumb[0]] };
          // // breadCrumb.value = {};
          // // breadCrumb.value = breadCrumb;
          let builder = this.props.builder;
          let tools = [];

          if (UniFun.userIsEligible({ forWhat: "default" })) {
            tools = [
              // <button
              //   type="button"
              //   className="btn btn-primary ml-3 bg-purple"
              //   onClick={this.onTargetClick}
              // >
              //   Upload File
              // </button>,
            ];
          }

          return (
            <React.Fragment>
              <div className="row">
                <div className="col-lg-6 col-md-12 pt-2 pl-4">
                  <div>
                    {filesBreadcrumb(
                      breadCrumb,
                      [
                        {
                          totalCount: {
                            value: this.state.data.length,
                          },
                        },
                      ],
                      this.props.builder
                    )}
                  </div>
                </div>

                <div className="col-lg-6 col-md-12 text-right p-2 pr-4">
                  {tools}
                </div>
              </div>
            </React.Fragment>
          );
        },
      },
      pk: "_id",
      properties: UniFilesProperties({ editable: false }),
    },
    rootSpaces: {
      auth: "",
      apis: {
        view: {
          url: "/root-spaces",
          parameters: {
            headers: {},
            replace: {},
          },
          formatter: SpacesFormatter,
          formatter1: function (data) {
            let folders = data;

            if (data) {
              if (data.data) {
                let foldersData = data.data.map((itm, idx) => {
                  itm["type"] = "spaceroot";
                  if (itm.fileDirectory) {
                    itm["type"] = "spacegroups";
                    itm["_id"] = itm.fileDirectory;
                  }
                  return itm;
                });

                if (!Fun.getParameterByName("_id")) {
                  folders = { data: [{ folders: data.data }] };
                }
              }
            }

            return UniFilesFormatter(folders);
          },
        },
      },
      // template: "cells",
      template: "spacesfiles",
      templateConfig: {
        colsTemplateCount: 12,
        squaresConfig: {
          titleAttribute: "title",
          colorAttribute: "color",
          iconAttribute: "icon",
          dataSections: [
            { section: "spaces", label: "Spaces", collapsed: false },
            { section: "tags", label: "Tags", collapsed: true },
          ],
        },
        cellsConfig: {
          emptyFolder: function () {
            return (
              <div className="row mt-4 " style={{ height: "500px" }}>
                <div className="col-12 text-center">
                  <h5>
                    <strong>All spaces are empty</strong>
                  </h5>
                </div>
              </div>
            );
          },
          titleAttribute: "title",
          cellTemplate: function (itm) {
            return (
              <Link
                className="pointer"
                to={"/files/gfiles?spacegroups=" + itm._id.value}
                title={itm.title.value}
              >
                <img
                  src="/uni/svg/spaces/rootfolder.svg"
                  style={{ width: "150px" }}
                ></img>
                <div>{itm.title.value}</div>
              </Link>
            );
          },
        },
        includeProperties: ["title"],
        excludeProperties: [],
        headerTitle: "Spaces",
        headerTitleIcon: "/uni/svg/Filesmain.svg",
        events: {
          onDrop: function (files) {
            let parent = this.state.rawData.data[0]._id;

            UniFun.fileProgress({
              control: window["fileupload"],
              files: files,
              parent: parent,
              showModalOnDelete: false,
              builder: this.props.builder,
              rerunComponent: true,
            });
          },
        },

        cardTemplateTop: function () {
          return (
            <div className="row">
              <div className="col-lg-6 col-md-12 pt-2 pl-4">
                <span className=" font-16  text-dark">
                  <strong>All groups</strong> ({this.state.data.length})
                </span>
              </div>
            </div>
          );
        },
        header: UniFun.commonHeader,
      },
      pk: "_id",
      properties: UniFilesProperties({ editable: false }),
    },
    groupSpaces: {
      auth: "",
      apis: {
        view: {
          url: "/spaces/root/{id}?treeview=false",
          parameters: {
            headers: {},
            replace: {
              id: function () {
                return Fun.getParameterByName("spacegroups");
              },
            },
          },
          formatter: function (data) {
            let formattedData = [];

            if (data) {
              if (data.data) {
                if (data.data.spaces) {
                  data.data.spaces.map((itm, idx) => {
                    let formattedRecord = {};
                    formattedRecord["_id"] = itm.fileDirectory;
                    formattedRecord["title"] = itm.name;
                    formattedData.push(formattedRecord);
                  });
                }
              }
            }

            return formattedData;
          },
        },
      },
      template: "grid",
      templateConfig: {
        colsTemplateCount: 12,
        gridConfig: {
          emptyFolder: filesEmptyFolder,
          cellTemplates: {
            title1: function (data, rec) {
              return (
                <Link
                  className="pointer"
                  to={"/files/gfiles?_id=" + rec._id.value}
                >
                  <img
                    src="/uni/svg/spaces/rootfolder.svg"
                    style={{ width: "24px" }}
                  />
                  {data}
                </Link>
              );
            },
            _id: function (data, rec, obj) {
              let uid = Fun.uuid();
              return (
                <React.Fragment>
                  <Link to={"/files/gfiles?_id=" + rec._id.value}>
                    <span>
                      <img
                        src="/uni/svg/signs/closeddropdown.svg"
                        className="mr-2 ml-4"
                        style={{ width: "14px" }}
                      ></img>
                      {rec.title.value}
                    </span>
                  </Link>
                  <span className="float-right mr-5 grid-controls pr-5">
                    <React.Fragment>
                      <i
                        className="fas fa-ellipsis-h pointer ml-2"
                        data-toggle="collapse"
                        data-target={"#" + uid}
                      ></i>
                      <div
                        className="position-absolute bg-white mt-2 collapse"
                        id={uid}
                        style={{ right: "100px" }}
                      >
                        <ul className="list-group " style={{ width: "200px" }}>
                          <li className="list-group-item p-2 pl-3 pointer">
                            <Link
                              to={"/files/gfiles?_id=" + rec._id.value}
                              style={{ display: "flex" }}
                            >
                              Open
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </React.Fragment>
                  </span>
                </React.Fragment>
              );
            },
          },
        },
        includeProperties: ["_id"],
        excludeProperties: [],
        headerTitle: "Spaces",
        headerTitleIcon: "/uni/svg/Filesmain.svg",
        cardTemplateTop: function () {
          // let breadCrumb = [];

          // if (this.state.rawData.data[0]) {
          //   breadCrumb = this.state.rawData.data[0].breadCrumb;
          // }

          // if (breadCrumb[0]) {
          //   breadCrumb[0].type = "space";
          // }
          let breadCrumb = this.state.rawData.data
            ? { value: this.state.rawData.data.breadCrumb }
            : { value: [{ name: "asd", id: "Asd" }] };

          //  let breadCrumbGroups = { value: [breadCrumb[0]] };
          // breadCrumb.value = {};
          // breadCrumb.value = breadCrumb;

          return (
            <div className="row">
              <div className="col-lg-6 col-md-12 pt-2 pl-4 ">
                <span className=" font-16  text-dark">
                  {filesBreadcrumb(
                    breadCrumb,
                    [{ totalCount: { value: this.state.data.length } }],
                    this.props.builder
                  )}
                </span>
              </div>
            </div>
          );
        },
        header: UniFun.commonHeader,
        events: {
          onDrop: function (files) {
            let parent = this.state.rawData.data[0]._id;

            UniFun.fileProgress({
              control: window["fileupload"],
              files: files,
              parent: parent,
              showModalOnDelete: false,
              builder: this.props.builder,
              rerunComponent: true,
            });
          },
        },
      },
      pk: "_id",
      properties: UniFilesProperties({ editable: false }),
    },
    filedetails: {
      auth: "",
      apis: {
        view: {
          url: "/file/{_id}",
          parameters: {
            headers: {},
            replace: {},
          },
          formatter: function (data) {
            return UniFileFormatter(data);
          },
        },
        update: {
          url: "/file",
          parameters: {
            headers: {},
            replace: {},
          },
          formatter: function (data) {
            return UniFileFormatter(data);
          },
        },
      },
      template: "form",
      templateConfig: {
        colsTemplateCount: 12,
        templateTop: function () {
          let canEdit = false;

          if (this.props.builder.props.moduleView == "library") {
            if (UniFun.userIsEligible({ forWhat: "default" })) {
              canEdit = true;
            }
          } else {
            if (
              this.state.data[0].ownerId.value ===
              localStorage.getItem("userid")
            ) {
              canEdit = true;
            }
          }

          if (canEdit) {
            if (document.getElementsByClassName("filedetails-save")) {
              if (document.getElementsByClassName("filedetails-save")[0]) {
                document
                  .getElementsByClassName("filedetails-save")[0]
                  .classList.remove("d-none");
              }
            }
          }
          return "";
        },
        events: {
          onSubmit: function (e) {
            let submittedData = Fun.getSubmitJSON(e);

            let builder = this.props.builder;
            let authors = [];

            let keywords = [];

            try {
              JSON.parse(submittedData["authors"]).map((itm, idx) => {
                authors.push(itm.name);
              });
            } catch (e) {}

            try {
              JSON.parse(submittedData["keywords"]).map((itm, idx) => {
                keywords.push(itm.name);
              });
            } catch (e) {}

            submittedData["authors"] = authors;
            submittedData["keywords"] = keywords;

            Fun.update(e, {
              url: builder.state.module.apis.update,
              method: "PATCH",
              parameters: {
                headers: {
                  "content-type": "application/json",
                },
              },
              callback: function (
                submittedData,
                status,
                responsedata,
                responsestatus
              ) {
                if (responsestatus == "200") {
                  if (window["modal2"].state.callback) {
                    window["modal2"].state.callback(submittedData);
                  }
                  Fun.createNotification({
                    message: "File details updated",
                    type: "success",
                  });
                  builder.rerunComponent();
                }
              },
              submittedData: JSON.stringify(submittedData),
            });
          },
        },
        includeProperties: [
          "_id",
          "title",
          "createdAt",
          "updatedAt",
          "description",
          "keywords",
          "authors",
          "description",
          "folder",
        ],
        excludeProperties: [],
      },
      pk: "_id",
      properties: UniFilesProperties({
        editable: function (args) {
          let data = args.data;
          let ownerId = "";
          if (data.ownerId) {
            ownerId = data.ownerId;
          }
          if (UniFun.userIsEligible({ forWhat: "default" })) {
            return true;
          } else if (args.builder.props.moduleView == "library") {
            return false;
          } else if (
            UniFun.userIsEligible({ forWhat: "files", ownerId: ownerId })
          ) {
            return true;
          }

          return false;
        },
        show: function (args) {
          if (args.field.alias == "authors") {
            if (args.builder.props.moduleView == "ownedbyme") {
              return false;
            }
          }

          return true;
        },
      }),
    },
    folderdelete: {
      auth: "",
      apis: {
        view: {
          url: "/folder/{_id}",
          parameters: {
            headers: {},
            replace: {},
          },
          formatter: function (data) {
            return UniFileFormatter(data);
          },
        },
        delete: {
          url: "/folder/{_id}",
          parameters: {
            headers: {},
            replace: {},
          },
          formatter: function (data) {
            return UniFileFormatter(data);
          },
        },
      },
      template: "form",
      templateConfig: {
        useCard: false,
        colsTemplateCount: 12,
        includeProperties: ["_id"],
        excludeProperties: [],
        events: {
          onSubmit: function (e) {
            let submittedData = Fun.getSubmitJSON(e);

            let builder = this.props.builder;
            let folderTitle = this.state.data[0].title.value;

            Fun.update(e, {
              url: builder.state.module.apis.delete,
              method: "DELETE",
              parameters: {
                headers: {
                  "content-type": "application/json",
                },
                replace: {
                  _id: submittedData["_id"],
                },
              },
              callback: function (
                submittedData,
                status,
                responsedata,
                responsestatus
              ) {
                if (responsestatus == "200") {
                  if (window["modal2"].state.callback) {
                    window["modal2"].state.callback(submittedData);
                  }
                  Fun.createNotification({
                    message: "'" + folderTitle + "' has been deleted",
                    type: "success",
                  });
                }
              },
              submittedData: JSON.stringify([]),
            });
          },
        },
        templateTop: function () {
          let data = this.state.data[0];
          return (
            <div className=" p-4">
              <p>
                Are you sure you want to delete folder "
                <strong>{data.title.value}</strong>"?<br></br>
                <br></br> This action will delete the folder and its content
                permanently.
              </p>
            </div>
          );
        },
      },
      pk: "_id",
      properties: UniFilesProperties({ editable: false }),
    },
    filedelete: {
      auth: "",
      apis: {
        view: {
          url: "/file/{_id}",
          parameters: {
            headers: {},
            replace: {},
          },
          formatter: function (data) {
            return UniFileFormatter(data);
          },
        },
        delete: {
          url: "/file/{_id}",
          parameters: {
            headers: {},
            replace: {},
          },
          formatter: function (data) {
            return UniFileFormatter(data);
          },
        },
      },
      template: "form",
      templateConfig: {
        useCard: false,
        colsTemplateCount: 12,
        includeProperties: ["_id"],
        excludeProperties: [],
        events: {
          onSubmit: function (e) {
            let submittedData = Fun.getSubmitJSON(e);

            let fileTitle = this.state.data[0].title.value;
            let builder = this.props.builder;

            Fun.update(e, {
              url: builder.state.module.apis.delete,
              method: "DELETE",
              parameters: {
                headers: {
                  "content-type": "application/json",
                },
                replace: {
                  _id: submittedData["_id"],
                },
              },
              callback: function (
                submittedData,
                status,
                responsedata,
                responsestatus
              ) {
                if (responsestatus == "200") {
                  if (window["modal2"].state.callback) {
                    window["modal2"].state.callback(submittedData);
                  }
                  Fun.createNotification({
                    message: "'" + fileTitle + "' has been deleted",
                    type: "success",
                  });
                }
              },
              submittedData: JSON.stringify([]),
            });
          },
        },
        templateTop: function () {
          let data = this.state.data[0];
          return (
            <div className=" p-4">
              <p>
                Are you sure you want to delete file "
                <strong>{data.title.value}</strong>"?<br></br>
                <br></br> This action will delete the file permanently.
              </p>
            </div>
          );
        },
      },
      pk: "_id",
      properties: UniFilesProperties({ editable: false }),
    },
    folderdetails: {
      auth: "",
      apis: {
        view: {
          url: "/folder/{_id}",
          parameters: {
            headers: {},
            replace: {},
          },
          formatter: function (data) {
            return UniFolderFormatter(data);
          },
        },
        update: {
          url: "/folder",
          parameters: {
            headers: {},
            replace: {},
          },
          formatter: function (data) {
            return UniFolderFormatter(data);
          },
        },
      },
      template: "form",
      templateConfig: {
        events: {
          onSubmit: function (e) {
            let submittedData = Fun.getSubmitJSON(e);

            let builder = this.props.builder;

            submittedData["name"] = submittedData["title"];

            delete submittedData["title"];

            Fun.update(e, {
              url: builder.state.module.apis.update,
              method: "PATCH",
              parameters: {
                headers: {
                  "content-type": "application/json",
                },
              },
              callback: function (args) {
                if (window["modal2"].state.callback) {
                  window["modal2"].state.callback(submittedData);
                }
              },
              submittedData: JSON.stringify(submittedData),
            });
          },
        },
        colsTemplateCount: 12,
        includeProperties: [
          "_id",
          "title",
          "description",
          "createdAt",
          "updatedAt",
        ],
        excludeProperties: [],
      },
      pk: "_id",
      properties: UniFilesProperties({
        editable: function (args) {
          let data = args.data;
          let ownerId = "";
          if (data.ownerId) {
            ownerId = data.ownerId;
          }
          if (UniFun.userIsEligible({ forWhat: "default" })) {
            return true;
          } else if (args.builder.props.moduleView == "library") {
            return false;
          } else if (
            UniFun.userIsEligible({ forWhat: "files", ownerId: ownerId })
          ) {
            return true;
          }

          return false;
        },
      }),
    },
    previewfile: {
      auth: "",
      apis: {
        view: {
          url: "/file/{_id}",
          parameters: {
            headers: {},
            replace: {},
          },
          formatter: function (data) {
            return UniFileFormatter(data);
          },
        },
      },
      template: "form",
      templateConfig: {
        colsTemplateCount: 12,
        includeProperties: ["fileurl"],
        excludeProperties: [],
      },
      pk: "_id",
      properties: UniFilesProperties({ editable: false }),
    },
    eventfiles: {
      auth: "",
      apis: {
        view: {
          url: "{path}",
          parameters: {
            headers: {},
          },
          formatter: function (data) {
            return UniFilesFormatter(data);
          },
        },
      },
      template: "foldergrid",
      templateConfig: {
        colsTemplateCount: 12,
        includeProperties: ["title", "updatedAt", "size"],
        excludeProperties: [],
        gridConfig: {
          headers: {
            title: {
              attributes: {
                className: "w-50",
              },
            },
          },
          cellTemplates: {
            title: cellTemplatesTitle,
          },
        },

        cardTemplateTop: filesCardTemplateTop,
      },
      pk: "_id",
      properties: UniFilesProperties({ editable: false }),
    },
    newFolder: {
      auth: "",
      apis: {
        save: {
          url: "/folder",
          parameters: {
            headers: {},
            replace: {},
          },
          formatter: function (data) {
            return UniFilesFormatter(data);
          },
        },
        view: {
          data: {
            data: [{}],
          },
          parameters: {
            headers: {},
            replace: {},
          },
          formatter: function (data) {
            let output = [];

            if (data) {
              data.data.map((itm, idx) => {
                output.push(itm);
              });
            }

            return output;
          },
        },
      },
      template: "form",
      templateConfig: {
        colsTemplateCount: 12,
        includeProperties: ["title", "description", "parent"],
        events: {
          onSubmit: function (e) {
            let submittedData = Fun.getSubmitJSON(e);

            let folderJSON = {
              name: submittedData["title"],
              description: submittedData["description"],
              parent: submittedData["parent"],
              // parent: Fun.getParameterByName("_id")
              //   ? Fun.getParameterByName("_id")
              //   : "60b65c86566f5c2efac9e9a4",
            };

            Fun.update(e, {
              url: this.props.builder.state.module.apis.save,
              method: "POST",
              parameters: {
                headers: {
                  "content-type": "application/json",
                },
              },
              callback: function (args) {
                window["modal"].setState({
                  show: false,
                  data: args[0],
                  overflowY: "auto",
                });

                if (window["modal"].state.callback) {
                  window["modal"].state.callback(args[0]);
                } else {
                  if (window["modal"].state.targetComponent) {
                    window["modal"].state.targetComponent.setState({
                      data: args[0],
                    });
                  }
                }
              },
              submittedData: JSON.stringify(folderJSON),
            });
          },
        },
      },
      pk: "_id",
      properties: UniFilesProperties({ editable: true }),
    },
    epubPreview: {
      auth: "",
      apis: {
        view: {
          data: { data: [] },
          parameters: {
            headers: {},
            replace: {},
          },
          formatter: function (data) {
            return UniFileFormatter(data);
          },
        },
        reading: {
          url: "/training/{id}/duration",
          parameters: {
            headers: {},
            replace: {},
          },
          formatter: function (data) {
            return UniFileFormatter(data);
          },
        },
      },
      template: "epubviewer",
      templateConfig: {
        colsTemplateCount: 12,
        includeProperties: ["fileurl"],
        excludeProperties: [],
        events: {
          componentDidMountEvent: function () {
            let module = this.props.builder.state.module;
            if (localStorage.getItem("intervalChecker")) {
              window["readingCheckerInterval"] = setInterval(function () {
                Fun.fetch({
                  url: module.apis.reading,
                  method: "PATCH",
                  parameters: {
                    replace: {
                      id: function () {
                        return Fun.getURLPathValue(3);
                      },
                    },
                  },
                });
              }, parseInt(localStorage.getItem("intervalChecker")));
            }
          },
          componentWillUnmount: function () {
            clearInterval(window["readingCheckerInterval"]);
          },
        },
      },
      pk: "_id",
      properties: UniFilesProperties({ editable: false }),
    },
    pdfPreview: {
      auth: "",
      apis: {
        view: {
          data: { data: [] },
          parameters: {
            headers: {},
            replace: {},
          },
          formatter: function (data) {
            return UniFileFormatter(data);
          },
        },
        reading: {
          url: "/training/{id}/duration",
          parameters: {
            headers: {},
            replace: {},
          },
          formatter: function (data) {
            return UniFileFormatter(data);
          },
        },
      },
      template: "pdfviewertemplate",
      templateConfig: {
        colsTemplateCount: 12,
        includeProperties: ["fileurl"],
        excludeProperties: [],
        events: {
          componentDidMountEvent: function () {
            let module = this.props.builder.state.module;
            if (localStorage.getItem("intervalChecker")) {
              window["readingCheckerInterval"] = setInterval(function () {
                Fun.fetch({
                  url: module.apis.reading,
                  method: "PATCH",
                  parameters: {
                    replace: {
                      id: function () {
                        return Fun.getURLPathValue(3);
                      },
                    },
                  },
                });
              }, parseInt(localStorage.getItem("intervalChecker")));
            }
          },
          componentWillUnmount: function () {
            clearInterval(window["readingCheckerInterval"]);
          },
        },
      },
      pk: "_id",
      properties: UniFilesProperties({ editable: false }),
    },
    videoPreview: {
      auth: "",
      apis: {
        view: {
          data: { data: [] },
          parameters: {
            headers: {},
            replace: {},
          },
          formatter: function (data) {
            return UniFileFormatter(data);
          },
        },
      },
      template: "videoviewertemplate",
      templateConfig: {
        colsTemplateCount: 12,
        includeProperties: ["fileurl"],
        excludeProperties: [],
        events: {},
      },
      pk: "_id",
      properties: UniFilesProperties({ editable: false }),
    },
  },
};

export default UniFiles;

function UniFilesProperties(input) {
  let editable = input.editable;
  let show = typeof input.show != "undefined" ? input.show : true;

  return {
    _id: {
      alias: "_id",
      control: "hidden",
      label: "",
      editable: editable,
      attributes: {},
    },
    breadCrumb: {
      alias: "breadCrumb",
      control: "text",
      label: "",
      editable: editable,
      attributes: {},
    },
    title: {
      alias: "title",
      control: "text",
      placeholder: "Add title",
      label: "Name",
      editable: editable,
      attributes: {
        className: "form-control",
      },
    },
    description: {
      alias: "description",
      control: "textarea",
      label: "Description",
      nonEditableDefaultValue: "No description",
      placeholder: "Add description",
      editable: editable,
      attributes: {
        className: "form-control",
      },
    },
    owner: {
      alias: "owner",
      control: "text",
      label: "Owner",
      editable: false,
      attributes: {
        className: "form-control",
      },
    },
    ownerId: {
      alias: "ownerId",
      control: "hidden",
      label: "",
      editable: false,
    },
    author: {
      alias: "author",
      control: "text",
      label: "Author",
      editable: false,
      attributes: {
        className: "form-control",
      },
    },
    type: {
      alias: "type",
      control: "text",
      label: "",
      editable: false,
      attributes: {
        className: "form-control",
      },
    },
    authorsCount: {
      alias: "authorsCount",
      control: "text",
      label: "Authors",
      editable: false,
      customLayout: function () {
        let data = this.state.defaultValue ? this.state.defaultValue : [];

        let uid = Fun.uuid();

        if (data.length < 1) {
          return "---";
        }

        return (
          <React.Fragment>
            <div
              className="pointer"
              data-toggle="collapse"
              data-target={"#" + uid}
            >
              {data.length} Authors
            </div>
            <div className="position-absolute bg-white mt-2 collapse" id={uid}>
              <ul className="list-group " style={{ width: "200px" }}>
                {data?.map((itm, idx) => {
                  return (
                    <li className="list-group-item p-2 pl-3 pointer">{itm}</li>
                  );
                })}
              </ul>
            </div>
          </React.Fragment>
        );
      },
      useCustomLayout: true,
      attributes: {
        className: "form-control",
      },
    },
    authors: {
      alias: "authors",
      control: "tags",
      nonEditableDefaultValue: "No authors",
      label: "Author(s)",
      placeholder: "Add author(s)",
      show: show,
      config: {
        idAttribute: "id",
        nameAttribute: "name",
      },
      editable: editable,
      attributes: {
        className: "form-control rounded",
      },
    },
    keywords: {
      alias: "keywords",
      control: "tags",
      nonEditableDefaultValue: "No keywords",
      placeholder: "Add keywords",
      config: {
        idAttribute: "id",
        nameAttribute: "name",
      },
      label: "Keywords",
      editable: editable,
      attributes: {
        className: "form-control rounded",
      },
    },
    mime: {
      alias: "mime",
      control: "text",
      label: "Mime",
      editable: false,
      attributes: {
        className: "form-control",
      },
    },
    recording: {
      alias: "recording",
      control: "hidden",
      label: "",
      editable: false,
      attributes: {
        className: "form-control",
      },
    },
    isFolder: {
      alias: "isFolder",
      control: "text",
      label: "",
      editable: false,
      attributes: {
        className: "form-control",
      },
    },
    folder: {
      alias: "folder",
      control: "hidden",
      label: "",
      editable: false,
      attributes: {
        className: "form-control",
      },
    },
    root: {
      alias: "root",
      control: "text",
      label: "",
      editable: false,
      attributes: {
        className: "form-control",
      },
    },
    isFavorite: {
      alias: "isFavorite",
      control: "text",
      label: "",
      editable: false,
      attributes: {
        className: "form-control",
      },
    },
    isParentFolder: {
      alias: "isParentFolder",
      control: "text",
      label: "",
      editable: false,
      attributes: {
        className: "form-control",
      },
    },
    createdAt: {
      alias: "createdAt",
      control: "datetime",
      label: "Created On",
      config: {
        toFormat: "ddd DD/MM/YYYY HH:mm",
      },
      editable: false,
      attributes: {
        className: "form-control",
      },
    },
    color: {
      alias: "color",
      control: "hidden",
      label: "",
      editable: false,
    },
    icon: {
      alias: "icon",
      control: "hidden",
      label: "",
      editable: false,
    },
    participantsCount: {
      alias: "participantsCount",
      control: "hidden",
      label: "",
      editable: false,
    },
    filesCount: {
      alias: "filesCount",
      control: "hidden",
      label: "",
      editable: false,
    },
    url: {
      alias: "url",
      control: "hidden",
      label: "",
      editable: false,
    },
    updatedAt: {
      alias: "updatedAt",
      control: "datetime",
      label: "Last modified",
      config: {
        toFormat: "ddd DD/MM/YYYY HH:mm",
      },
      editable: false,
      attributes: {
        className: "form-control",
      },
    },
    fileurl: {
      alias: "fileurl",
      control: "iframe",
      label: "",
      editable: false,
      customLayout: function (args) {
        let mime = this.props.data.mime.toLowerCase();

        if (mime.startsWith("image")) {
          return (
            <div className="p-5 bg-white text-center">
              <img src={this.props.value} className="img-fluid" />
            </div>
          );
        } else if (mime.startsWith("video")) {
          return (
            <div className="p-5 bg-white text-center">
              <video controls>
                <source src={this.props.value} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          );
        } else {
          return (
            <div className="embed-responsive embed-responsive-16by9">
              {args.control}
            </div>
          );
        }
      },
      useCustomLayout: true,
      attributes: {
        className: "form-control vh-100 embed-responsive-item",
      },
    },
    filedownloadurl: {
      alias: "filedownloadurl",
      control: "text",
      label: "",
      editable: false,
      attributes: {
        className: "form-control vh-100",
      },
    },
    size: {
      alias: "size",
      control: "text",
      label: "Size",
      editable: false,
      attributes: {
        className: "form-control",
      },
    },
    path: {
      alias: "path",
      control: "text",
      label: "",
      editable: false,
      attributes: {
        className: "form-control",
      },
    },
    totalCount: {
      alias: "totalCount",
      control: "text",
      label: "",
      editable: false,
      attributes: {
        className: "form-control",
      },
    },
    parent: {
      alias: "parent",
      control: "hidden",
      label: "",
      editable: false,
      attributes: {
        className: "form-control",
      },
    },
    cancel: {
      control: "button",
      caption: "Cancel",
      alias: "cancel",
      events: {
        onClick: function () {
          window["modal2"].setState({ show: false, overflowY: "auto" });
        },
      },
      attributes: {
        className: "btn btn-link border float-right",
      },
    },
    submit: {
      alias: "submit",
      control: "submit",
      caption: "Submit",
      apiPath: "",
      auth: "",
      validations: "",
      events: {
        onChange: function () {},
      },
      attributes: {
        className: "btn btn-primary float-right ml-2",
      },
    },
  };
}

function UniFolderFormatter(data) {
  if (data) {
    let formattedData = [];

    data.data.map((itm, idx) => {
      let formattedRecord = {};

      formattedRecord = {};
      formattedRecord["_id"] = itm._id;
      formattedRecord["title"] = itm.name;
      formattedRecord["description"] = itm.description;
      formattedRecord["createdAt"] = itm.createdAt;
      formattedRecord["updatedAt"] = itm.updatedAt;
      formattedRecord["updatedAt"] = itm.updatedAt;
      formattedRecord["totalCount"] = itm.fileCount;
      formattedRecord["isFolder"] = false;
      formattedData.push(formattedRecord);
    });
    return formattedData;
  }
}

function UniFileFormatter(data) {
  if (data) {
    let formattedData = [];

    data.data.reverse().map((itm, idx) => {
      let formattedRecord = {};

      formattedRecord = {};
      formattedRecord["_id"] = itm._id;
      formattedRecord["title"] = itm.folders ? itm.name : itm.title;
      formattedRecord["description"] = itm.description;
      formattedRecord["createdAt"] = itm.createdAt;
      formattedRecord["updatedAt"] = itm.updatedAt;
      formattedRecord["path"] = itm.path;
      formattedRecord["fileurl"] = window["baseurl"](
        "/file/data/" +
          itm._id +
          "?type=preview&jwt=" +
          localStorage.getItem("jwt")
      );
      formattedRecord["filedownloadurl"] = window["baseurl"](
        "/file/data/" +
          itm._id +
          "?type=download&jwt=" +
          localStorage.getItem("jwt")
      );
      //formattedRecord["fileurl"] = "http://localhost/loading.gif";
      formattedRecord["size"] = itm.size ? itm.size : "---";
      formattedRecord["mime"] = itm.folders ? "folder" : itm.mimetype;
      formattedRecord["isFolder"] = itm.folders ? true : false;
      formattedRecord["root"] = itm.root;
      formattedRecord["isFavorite"] = itm.isFavorite;

      let authors = [];

      itm.authors?.map((a, b) => {
        authors.push({ name: a });
      });

      formattedRecord["authors"] = authors;

      let keywords = [];

      itm.keywords?.map((a, b) => {
        keywords.push({ name: a });
      });

      formattedRecord["keywords"] = keywords;
      formattedRecord["folder"] = itm.folder;
      formattedRecord["owner"] = itm.owner ? itm.owner.fullName : "---";
      formattedRecord["ownerId"] = itm.owner ? itm.owner._id : "";

      formattedData.push(formattedRecord);
    });
    return formattedData;
  }
}

function SpacesFormatter(data) {
  let formattedData = [];
  if (data) {
    let i = 0;
    if (data.data) {
      data.data.map((itm, idx) => {
        let formattedRecord = {};

        if (data.colours) {
          if (!data.colours[i]) {
            i = 0;
          }
        }

        formattedRecord["_id"] = itm._id;
        formattedRecord["spaceTree"] = itm._id;

        formattedRecord["title"] = itm.name;
        formattedRecord["parentId"] = itm.parentId;
        formattedRecord["files"] = itm._id;
        formattedRecord["spaceFileDirectory"] = itm.spaceFileDirectory;
        formattedRecord["description"] = itm.description;
        formattedRecord["children"] = [];
        formattedRecord["color"] = data.colours ? data.colours[i] : "#fff"; //idx % 2 ? "#009F9F" : "#556FCE";
        formattedRecord["url"] = "/files/gfiles?spacegroups=" + itm._id;
        formattedRecord["participantsCount"] = "";
        formattedRecord["filesCount"] = itm.numberOfFiles
          ? itm.numberOfFiles
          : "0";
        formattedRecord["icon"] = "";

        i++;

        formattedData.push(formattedRecord);
      });
    }

    return formattedData;
  }
}

function UniFilesFormatter(data) {
  let formattedData = [];
  if (data) {
    data.data?.map((itm, idx) => {
      let formattedRecord = {};

      if (itm.parentFolder) {
        formattedRecord = {};
        formattedRecord["_id"] = itm.parentFolder.id;
        formattedRecord["title"] = "...";
        formattedRecord["isFolder"] = true;
        formattedRecord["mime"] = "parentFolder";
        formattedRecord["size"] = "";
        formattedRecord["author"] = "";
        formattedRecord["updatedAt"] = "";
        formattedRecord["isParentFolder"] = true;
        formattedRecord["parent"] = itm.parent;
        formattedRecord["breadCrumb"] = itm.breadCrumb;
        formattedRecord["totalCount"] = itm.fileCount;

        formattedData.push(formattedRecord);
      }

      itm["folders"]?.map((folder, folderIdx) => {
        formattedRecord = {};
        formattedRecord["_id"] = folder._id;
        formattedRecord["title"] = folder.name;
        formattedRecord["description"] = folder.description;
        formattedRecord["createdAt"] = folder.createdAt;
        formattedRecord["updatedAt"] = folder.updatedAt;
        formattedRecord["isFolder"] = true;
        formattedRecord["type"] = folder.type;
        formattedRecord["isFavorite"] = folder.isFavorite;
        formattedRecord["mime"] = "folder";
        formattedRecord["size"] = "---";
        formattedRecord["newFolder"] = folder;
        formattedRecord["isParentFolder"] = false;
        formattedRecord["parent"] = itm.parent;
        formattedRecord["authorsCount"] = folder.authors;
        formattedRecord["totalCount"] = itm.fileCount;
        formattedRecord["ownerId"] = folder.owner ? folder.owner._id : "";
        formattedRecord["filedownloadurl"] = window["baseurl"](
          "/folder/downloadContents/" +
            folder._id +
            "?type=download&jwt=" +
            localStorage.getItem("jwt")
        );
        formattedRecord["author"] = folder.owner
          ? folder.owner.fullName
          : "---";
        formattedRecord["breadCrumb"] = itm.breadCrumb;
        formattedData.push(formattedRecord);
      });

      itm["files"]?.map((file, fileIdx) => {
        formattedRecord = {};
        formattedRecord["_id"] = file._id;
        formattedRecord["title"] = file.title;
        formattedRecord["description"] = file.description;
        formattedRecord["createdAt"] = file.createdAt;
        formattedRecord["updatedAt"] = file.updatedAt;
        formattedRecord["size"] = file.size;
        formattedRecord["path"] = file.path;
        formattedRecord["mime"] = file.mimetype;
        formattedRecord["isFolder"] = false;
        formattedRecord["isFavorite"] = file.isFavorite;
        formattedRecord["newFolder"] = file;
        formattedRecord["parent"] = itm.parent;
        formattedRecord["recording"] = file.recording;
        formattedRecord["authorsCount"] = file.authors;
        formattedRecord["totalCount"] = itm.fileCount;
        formattedRecord["author"] = file.owner ? file.owner.fullName : "---";
        formattedRecord["ownerId"] = file.owner ? file.owner._id : "";
        formattedRecord["fileurl"] = window["baseurl"](
          "/file/data/" +
            file._id +
            "?type=preview&jwt=" +
            localStorage.getItem("jwt")
        );
        formattedRecord["filedownloadurl"] = window["baseurl"](
          "/file/data/" +
            file._id +
            "?type=download&jwt=" +
            localStorage.getItem("jwt")
        );
        formattedRecord["owner"] = file.owner ? file.owner.fullName : "---";
        formattedRecord["breadCrumb"] = itm.breadCrumb;

        formattedData.push(formattedRecord);
      });
    });
  }

  return formattedData;
}
