import React, { Component } from "react";
import Control from "./../../control";
import SimpleReactValidator from "simple-react-validator";
import Fun from "../../../../../lib/functions";
import UniFun from "../../../../../modules/uni/functions";
import moment from "moment";
import TopTemplateHeader from "./../../topTemplateHeader";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import Header from "./header";
import Footer from "./footer";
import Cells from "../../cells";

class PublicReception extends Component {
  state = {
    data: this.props.data,
    loaded: false,
    colsTemplateCount: 12,
    colsFormCount: 12,
    cardBodyCustomClass: "",
    module: {
      _id: "",
      title: "",
      description: "",
    },
    session: {},
  };

  constructor(props) {
    super(props);
    let builder = this.props.builder.state;
    let templateConfig = builder.module.templateConfig;
    if (templateConfig.events) {
      Object.keys(templateConfig.events).map((itm, idx) => {
        this[itm] = templateConfig.events[itm].bind(this);
      });
    }

    this.form = React.createRef();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.action) {
      return false;
    }
    return true;
  }

  componentDidMount() {
    let builder = this.props.builder.state;
    let templateConfig = builder.module.templateConfig;
    let useCard =
      typeof templateConfig.useCard !== "undefined"
        ? templateConfig.useCard
        : true;

    let templateTop = templateConfig.templateTop
      ? templateConfig.templateTop
      : () => {};
    this["templateTop"] = templateTop.bind(this);
    let templateBottom = templateConfig.templateBottom
      ? templateConfig.templateBottom
      : () => {};
    this["templateBottom"] = templateBottom.bind(this);
    let templateLeft = templateConfig.templateLeft
      ? templateConfig.templateLeft
      : () => {};
    this["templateLeft"] = templateLeft.bind(this);
    let templateRight = templateConfig.templateRight
      ? templateConfig.templateRight
      : () => {};
    this["templateRight"] = templateRight.bind(this);
    let cardTemplateTop = templateConfig.cardTemplateTop
      ? templateConfig.cardTemplateTop
      : () => {};
    this["cardTemplateTop"] = cardTemplateTop.bind(this);
    let cardTemplateBottom = templateConfig.cardTemplateBottom
      ? templateConfig.cardTemplateBottom
      : () => {};
    this["cardTemplateBottom"] = cardTemplateBottom.bind(this);
    let panelTop = templateConfig.panelTop ? templateConfig.panelTop : () => {};
    this["panelTop"] = panelTop.bind(this);
    let panelBottom = templateConfig.panelBottom
      ? templateConfig.panelBottom
      : () => {};
    this["panelBottom"] = panelBottom.bind(this);
    let panelLeft = templateConfig.panelLeft
      ? templateConfig.panelLeft
      : () => {};
    this["panelLeft"] = panelLeft.bind(this);
    let panelRight = templateConfig.panelRight
      ? templateConfig.panelRight
      : () => {};
    this["panelRight"] = panelRight.bind(this);
    let header = templateConfig.header ? templateConfig.header : () => {};
    this["header"] = header.bind(this);

    let colsTemplateCount = templateConfig.colsTemplateCount
      ? templateConfig.colsTemplateCount
      : this.state.colsTemplateCount;
    let colsFormCount = templateConfig.colsFormCount
      ? templateConfig.colsFormCount
      : this.state.colsFormCount;

    let cardBodyCustomClass = templateConfig.cardBodyCustomClass
      ? templateConfig.cardBodyCustomClass
      : this.state.cardBodyCustomClass;

    this.setState({
      loaded: true,
      rawData: this.props.rawData,
      colsTemplateCount: colsTemplateCount,
      colsFormCount: colsFormCount,
      cardBodyCustomClass: cardBodyCustomClass,
      useCard: useCard,
    });

    this.validator = this.props.builder.validator;
    if (this.componentDidMountEvent) {
      this.componentDidMountEvent();
    }
  }

  render() {
    if (this.state.loaded) {
      let data = this.state.data[0];
      let minHeightCards = "300px";
      let minHeightInfo = "350px";
      let numberOfSessions = 0;
      let numberOfSpeakers = 0;
      let speakersIds = [];
      let numberOfSponsors = data.sponsors.value.length;
      let banner = data.banner.value[0]
        ? window["baseurl"](
            "/agenda-file/data/" +
              data.banner.value[0]._id +
              "?type=preview&jwt=" +
              localStorage.getItem("jwt")
          )
        : "/uni/svg/conferencePlaceholder.jpg";

      data.days.value.forEach((itm, idx) => {
        itm.sessions.forEach((sitm, sidx) => {
          numberOfSessions++;
          sitm.speakersRaw.forEach((spitm, spidx) => {
            if (speakersIds.indexOf(spitm._id) < 0) {
              speakersIds.push(spitm._id);
              numberOfSpeakers++;
            }
          });
        });
      });

      return (
        <React.Fragment>
          <Header
            data={this.state.data}
            rawData={this.state.rawData}
            interestFormModal={this.interestFormModal}
          ></Header>

          <div
            style={{
              backgroundImage: "url(" + banner + ")",
              backgroundSize: "cover",
              boxShadow: "inset 0 0 0 2000px rgba(0, 0, 0, 0.7)",
              minHeight: "100vh",
            }}
          >
            <div
              style={{
                minHeight: "calc(100vh - 150px)",
                width: "100%",
                display: "table",
              }}
            >
              <div
                style={{
                  display: "table-cell",
                  height: "100%",
                  verticalAlign: "middle",
                }}
                className="text-white"
              >
                <div className="container">
                  <h1 className="conference-tile-public">{data.title.value}</h1>
                  <p className="text-white">
                    {Fun.parse(data.description.value)}
                  </p>
                </div>
              </div>
            </div>
            <Footer></Footer>
          </div>

          {/* <div className="container-fluid p-0 m-0">
            <div
              className="rounded"
              style={{
                minHeight: minHeightInfo,
                height: "100%",
                backgroundImage: "url(" + banner + ")",
                backgroundSize: "cover",
              }}
            ></div>
          </div>

          <div className="container ">
            <div className="row">
            </div>
            <div className="row mt-5">
              <div className="col-md-8">
                <h3 className="header-title">
                  <strong>About</strong>
                </h3>
                <span className="d-block mt-3">
                  {Fun.parse(data.description.value)}
                </span>
              </div>
              <div className="col-md-4">
                <div className="card p-2 ">
                  <div className="card-body">
                    <Link to={"/conference/" + data._id.value + "/sessions"}>
                      <h3 className="text-strong">
                        <img
                          src="/uni/svg/conferences/sessions.svg"
                          className="mr-3"
                          style={{ width: "30px" }}
                        ></img>
                        Sessions
                      </h3>
                      <span className="d-block text-grey font-16">
                        {numberOfSessions} sessions included
                      </span>
                    </Link>
                  </div>
                </div>
                <div className="card p-2 ">
                  <div className="card-body">
                    <Link to={"/conference/" + data._id.value + "/speakers"}>
                      <h3 className="text-strong">
                        <img
                          src="/uni/svg/conferences/speakers.svg"
                          className="mr-3"
                          style={{ width: "30px" }}
                        ></img>
                        Speakers
                      </h3>
                      <span className="d-block text-grey font-16">
                        {numberOfSpeakers} speakers presenting
                      </span>
                    </Link>
                  </div>
                </div>
                <div className="card p-2 ">
                  <div className="card-body">
                    <Link to={"/conference/" + data._id.value + "/sponsors"}>
                      <h3 className="text-strong">
                        <img
                          src="/uni/svg/conferences/sponsors.svg"
                          className="mr-3"
                          style={{ width: "30px" }}
                        ></img>
                        Partners
                      </h3>
                      <span className="d-block text-grey font-16">
                        {numberOfSponsors} partners contributed
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </React.Fragment>
      );
    } else {
      return "loading...";
    }
  }
}

export default withRouter(PublicReception);
