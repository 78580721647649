import React, { Component } from "react";

class Submit extends Component {
  state = {
    name: "",
    id: "",
    placeholder: "",
    attributes: "",
    value: "",
    defaultValue: "",
    loaded: false,
    editable: true,
    component: null,
    attributes: "",
    events: "",
    caption: "",
  };

  constructor(props) {
    super(props);

    let events = {};
    if (this.props.field.events) {
      Object.keys(this.props.field.events).map((itm, idx) => {
        this[itm] = this.props.field.events[itm];
        this[itm] = this[itm].bind(this);
        events[itm] = this[itm];
      });
    }

    this.onClickAction = this.onClickAction.bind(this);

    events["onClick"] = this.onClickAction;

    this.state = {
      action: this.props.field.action ? this.props.field.action : "",
      events: events,
    };
  }

  onClickAction() {
    this.props.builder.builderTemplate.current.setState({
      action: this.state.action,
    });
  }

  componentDidMount() {
    this.setState({
      defaultValue: this.props.value,
      attributes: this.props.field.attributes,
      caption: this.props.field.caption,
    });
  }

  render() {
    return (
      <button type="submit" {...this.state.attributes} {...this.state.events}>
        {this.state.caption}
      </button>
    );
  }
}

export default Submit;
