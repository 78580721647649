import React, { Component } from "react";
import Fun from "../lib/functions";

const Upload = {
  auth: "",
  views: {
    detail: {
      auth: "",
      apis: {
        view: {
          data: { data: [{}] },
          parameters: {},
          formatter: function (data) {
            return UploadFormatter(data);
          },
        },
        upload: {
          url: "/media/upload",
          parameters: {
            headers: {},
            replace: {
              start: 0,
              offset: 10,
            },
          },
          formatter: function (data) {
            return UploadFormatter(data);
          },
        },
      },
      template: "form",
      templateConfig: {
        excludeProperties: ["objectKey"],
        events: {
          onSubmit: function (e) {
            let submittedData = new FormData(e.target);

            Fun.update(e, {
              url: this.props.builder.state.module.apis.upload,
              method: "POST",
              submittedData: submittedData,
              callback: function (args) {
                window["modal"].setState({
                  show: false,
                  data: args[0],
                  overflowY: "auto",
                });

                if (window["modal"].state.callback) {
                  window["modal"].state.callback(args[0]);
                } else {
                  if (window["modal"].state.targetComponent) {
                    window["modal"].state.targetComponent.setState({
                      data: args[0],
                    });
                  }
                }
              },
            });
          },
        },
        colsTemplateCount: 12,
        includeProperties: ["file", "alternativeText", "submit"],
        excludeProperties: [],
        templateTop: function (data) {},
        templateBottom: function (data) {},
        templateLeft: function (data) {},
        templateRight: function (data) {},
        panelTop: function (data) {},
        panelBottom: function (data) {},
        panelLeft: function (data) {},
        panelRight: function (data) {},
        header: function (data) {},
      },
      pk: "id",
      properties: UploadProperties({ editable: true }),
    },
  },
};

export default Upload;

function UploadProperties(input) {
  let editable = input.editable;

  return {
    id: {
      alias: "id",
      control: "hidden",
    },
    file: {
      alias: "file",
      control: "upload",
      label: "",
      editable: editable,
      apiPath: "",
      auth: "",
      validations: "",
      attributes: {
        className: "form-control-file",
      },
    },
    objectKey: {
      alias: "objectKey",
      control: "text",
      label: "",
      editable: editable,
      apiPath: "",
      auth: "",
      validations: "",
      attributes: {
        className: "form-control-file",
      },
    },
    alternativeText: {
      alias: "alternativeText",
      control: "text",
      label: "Title",
      editable: editable,
      apiPath: "",
      auth: "",
      validations: "",
      attributes: {
        className: "form-control",
      },
    },
    submit: {
      alias: "submit",
      label: "",
      control: "submit",
      caption: "submit",
      apiPath: "",
      auth: "",
      validations: "",
      attributes: {
        className: "btn btn-primary float-right",
      },
    },
  };
}

function UploadFormatter(data) {
  if (data) {
    let formattedData = [];

    if (data.data) {
      data = data.data;
    } else {
      if (!Array.isArray(data)) {
        data = [data];
      }
    }

    data.map((itm, idx) => {
      let formattedRecord = {};

      formattedRecord["id"] = itm.id;
      formattedRecord["alternativeText"] = itm.alternativeText;
      formattedRecord["objectKey"] = itm.objectKey;

      formattedData.push(formattedRecord);
    });

    return formattedData;
  }
}
