import React, { Component } from "react";
import Tree from "rc-tree";
import "rc-tree/assets/index.css";
import Fun from "../../../lib/functions";
import classNames from "classnames";

const arrowPath =
  "M869 487.8L491.2 159.9c-2.9-2.5-6.6-3.9-10.5-3.9h-88" +
  ".5c-7.4 0-10.8 9.2-5.2 14l350.2 304H152c-4.4 0-8 3.6-8 8v60c0 4.4 3." +
  "6 8 8 8h585.1L386.9 854c-5.6 4.9-2.2 14 5.2 14h91.5c1.9 0 3.8-0.7 5." +
  "2-2L869 536.2c14.7-12.8 14.7-35.6 0-48.4z";

const getSvgIcon = (path, iStyle = {}, style = {}) => (
  <i style={iStyle}>
    <svg
      viewBox="0 0 1024 1024"
      width="1em"
      height="1em"
      fill="currentColor"
      style={{ verticalAlign: "-.125em", ...style }}
    >
      <path d={path} />
    </svg>
  </i>
);

class TreeView extends Component {
  state = {
    data: [],
    loaded: false,
    templateTop: "",
    templateBottom: "",
    templateLeft: "",
    templateRight: "",
    panelTop: "",
    panelBottom: "",
    panelLeft: "",
    panelRight: "",
    header: "",
    colsTemplateCount: 12,
    colsFormCount: 12,
    treeviewConfig: {
      keyAttribute: "id",
      titleAttribute: "title",
      treeDataAttribute: "",
    },
  };

  constructor(props) {
    super(props);

    let builder = this.props.builder.state;
    let templateConfig = builder.module.templateConfig;
    if (templateConfig.events) {
      Object.keys(templateConfig.events).map((itm, idx) => {
        this[itm] = templateConfig.events[itm].bind(this);
      });
    }

    this.onLoadData = function () {
      return new Promise((resolve) => {
        resolve();
      });
    };

    this.formatTitle = function (data) {
      return data;
    };

    if (templateConfig.treeviewConfig) {
      if (templateConfig.treeviewConfig.onLoadData) {
        this.onLoadData = templateConfig.treeviewConfig.onLoadData.bind(this);
      }
      if (templateConfig.treeviewConfig.onSelect) {
        this.onSelect = templateConfig.treeviewConfig.onSelect.bind(this);
      }
      if (templateConfig.treeviewConfig.formatTitle) {
        this.formatTitle = templateConfig.treeviewConfig.formatTitle.bind(this);
      }
    }

    this.createTree = this.createTree.bind(this);
    this.findTreeNode = this.findTreeNode.bind(this);
  }

  componentDidMount() {
    let builder = this.props.builder.state;
    let templateConfig = builder.module.templateConfig;
    let templateTop = templateConfig.templateTop
      ? templateConfig.templateTop
      : () => {};
    this["templateTop"] = templateTop.bind(this);
    let templateBottom = templateConfig.templateBottom
      ? templateConfig.templateBottom
      : () => {};
    this["templateBottom"] = templateBottom.bind(this);
    let templateLeft = templateConfig.templateLeft
      ? templateConfig.templateLeft
      : () => {};
    this["templateLeft"] = templateLeft.bind(this);
    let templateRight = templateConfig.templateRight
      ? templateConfig.templateRight
      : () => {};
    this["templateRight"] = templateRight.bind(this);
    let panelTop = templateConfig.panelTop ? templateConfig.panelTop : () => {};
    this["panelTop"] = panelTop.bind(this);
    let panelBottom = templateConfig.panelBottom
      ? templateConfig.panelBottom
      : () => {};
    this["panelBottom"] = panelBottom.bind(this);
    let panelLeft = templateConfig.panelLeft
      ? templateConfig.panelLeft
      : () => {};
    this["panelLeft"] = panelLeft.bind(this);
    let panelRight = templateConfig.panelRight
      ? templateConfig.panelRight
      : () => {};
    this["panelRight"] = panelRight.bind(this);
    let header = templateConfig.header ? templateConfig.header : () => {};
    this["header"] = header.bind(this);

    let colsTemplateCount = templateConfig.colsTemplateCount
      ? templateConfig.colsTemplateCount
      : this.state.colsTemplateCount;
    let colsFormCount = templateConfig.colsFormCount
      ? templateConfig.colsFormCount
      : this.state.colsFormCount;

    let treeviewKeyAttribute = this.state.treeviewConfig.keyAttribute;
    let treeviewTitleAttribute = this.state.treeviewConfig.titleAttribute;
    let treeDataAttribute = this.state.treeviewConfig.treeDataAttribute;

    if (templateConfig.treeviewConfig) {
      if (templateConfig.treeviewConfig.keyAttribute) {
        treeviewKeyAttribute = templateConfig.treeviewConfig.keyAttribute;
      }
      if (templateConfig.treeviewConfig.titleAttribute) {
        treeviewTitleAttribute = templateConfig.treeviewConfig.titleAttribute;
      }
      if (templateConfig.treeviewConfig.treeDataAttribute) {
        treeDataAttribute = templateConfig.treeviewConfig.treeDataAttribute;
      }
    }

    let treeData = [];
    if (treeDataAttribute) {
      treeData = this.props.data[0][treeDataAttribute].value;
    } else {
      this.props.data.map((itm, idx) => {
        treeData.push({
          rawName: itm[treeviewTitleAttribute]["value"],
          key: itm[treeviewKeyAttribute]["value"],
          [treeviewTitleAttribute]: this.formatTitle(
            itm[treeviewTitleAttribute]["value"],
            itm
          ),
          children: itm["children"]["value"],
        });
      });
    }
    this.treeRef = React.createRef();

    this.setState({
      data: treeData,
      loaded: true,
      templateTop: templateTop,
      templateBottom: templateBottom,
      templateLeft: templateLeft,
      templateRight: templateRight,
      panelTop: panelTop,
      panelBottom: panelBottom,
      panelLeft: panelLeft,
      panelRight: panelRight,
      header: header,
      colsTemplateCount: colsTemplateCount,
      colsFormCount: colsFormCount,
      treeviewConfig: {
        keyAttribute: treeviewKeyAttribute,
        titleAttribute: treeviewTitleAttribute,
        treeDataAttribute: treeDataAttribute,
      },
    });
  }

  findTreeNode = (data, key, children) => {
    if (data) {
      try {
        let found = false;
        return data.map((itm, idx) => {
          if (itm["key"] == key) {
            found = true;
            itm.children = children;
            return itm;
          }
          if (!found) {
            return this.findTreeNode(itm.children, key, children);
          }
        });
      } catch (e) {
        console.log(e);
      }
    }
    return data;
  };

  createTree() {
    const switcherIcon = (obj) => {
      if (obj.createNode) {
        return (
          <i>
            <img src="" style={{ width: "21px", marginBottom: "7px" }}></img>
          </i>
        );
      }
      if (obj.expanded) {
        return (
          <i>
            <img
              src="/uni/svg/spaces/minus.svg"
              style={{ width: "21px", marginBottom: "7px" }}
            ></img>
          </i>
        );
      }
      return (
        <i>
          <img
            src="/uni/svg/spaces/add.svg"
            style={{ width: "21px", marginBottom: "7px" }}
          ></img>
        </i>
      );
    };
    const treeCls = `myCls${(this.state.useIcon && " customIcon") || ""}`;

    return (
      <Tree
        className={treeCls}
        ref={this.treeRef}
        showLine
        showIcon={false}
        switcherIcon={switcherIcon}
        checkable={false}
        // defaultExpandAll={true}
        defaultExpandedKeys={[0]}
        treeData={this.state.data}
        //loadData={this.onLoadData}
        title={this.state.treeviewConfig.titleAttribute}
        onSelect={this.onSelect}
      />
    );
  }

  render() {
    if (this.state.loaded) {
      return (
        <React.Fragment>
          {this.header()}
          {this.panelTop()}
          <div className="container-fluid">
            <div className="row">
              {this.panelLeft()}
              <div className={"col-" + this.state.colsTemplateCount}>
                <div className="row">
                  {this.templateLeft()}
                  <div className={"col-" + this.state.colsFormCount}>
                    {this.templateTop()}
                    <div className="card">
                      <div className="card-body ">{this.createTree()}</div>
                    </div>
                    {this.templateBottom()}
                  </div>
                  {this.templateRight()}
                </div>
              </div>
              {this.panelRight()}
            </div>
          </div>
          {this.panelBottom()}
        </React.Fragment>
      );
    } else {
      return "loading...";
    }
  }
}

export default TreeView;
