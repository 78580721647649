import React, { Component } from "react";
import { Editor as TinyMCE } from "@tinymce/tinymce-react";
import Fun from "../../lib/functions";
import ReactQuill from "react-quill";

import "quill/dist/quill.snow.css";

class Editor extends Component {
  state = {
    name: "",
    id: "",
    placeholder: "",
    attributes: "",
    value: "",
    defaultValue: "",
    loaded: false,
    editable: true,
    component: null,
    attributes: "",
    events: {},
    customLayout: null,
    useCustomLayout: true,
    editable: true,
  };

  constructor(props) {
    super(props);

    let events = {};
    if (this.props.field.events) {
      Object.keys(this.props.field.events).map((itm, idx) => {
        this[itm] = this.props.field.events[itm];
        this[itm] = this[itm].bind(this);
        events[itm] = this[itm];
      });
    }

    this.editable = this.editable.bind(this);
    this.nonEditable = this.nonEditable.bind(this);

    let nonEditableDefaultValue = "";
    if (this.props.field.nonEditableDefaultValue) {
      nonEditableDefaultValue = this.props.field.nonEditableDefaultValue;
    }

    this.state = {
      events: events,
      placeholder: this.props.field.placeholder
        ? this.props.field.placeholder
        : "",
      editable:
        this.props.field.editable != null
          ? this.props.field.editable
          : this.state.editable,
      nonEditableDefaultValue: this.props.value
        ? this.props.value
        : nonEditableDefaultValue,
      defaultValue: this.props.value ? this.props.value : "",
    };
  }

  validator() {}

  componentDidMount() {
    this.setState({
      attributes: this.props.field.attributes,
      name: this.props.field.alias,

      editable:
        this.props.field.editable != null
          ? this.props.field.editable
          : this.state.editable,
      customLayout: this.props.field.customLayout,
      useCustomLayout:
        typeof this.props.field.useCustomLayout !== "undefined"
          ? this.props.field.useCustomLayout
          : true,
    });
  }

  editable() {
    var toolbarOptions = [
      ["bold", "italic", "underline", "strike", "link"],
      [{ list: "ordered" }, { list: "bullet" }],
    ];

    let output = (
      <div className="pb-5">
        <ReactQuill
          theme="snow"
          placeholder={this.state.placeholder}
          value={this.state.defaultValue}
          onChange={this.handleEditorChange}
          style={{ height: "150px" }}
          modules={{
            toolbar: toolbarOptions,
          }}
        ></ReactQuill>
        <input
          type="hidden"
          name={this.props.field.alias}
          value={this.state.defaultValue}
        ></input>
      </div>
    );
    if (this.state.customLayout && this.state.useCustomLayout == true) {
      this.state.customLayout = this.state.customLayout.bind(this);
      return this.state.customLayout({ control: output, object: this });
    }

    return output;
  }

  handleEditorChange = (content, editor) => {
    this.setState({ defaultValue: content });
  };

  // onInit = (content, editor) => {
  //   editor.save();
  // };

  nonEditable() {
    let output = (
      <div style={{ maxHeight: "400px", overflowY: "auto" }}>
        {Fun.parse(this.state.defaultValue)}
      </div>
    );

    if (this.state.nonEditableDefaultValue && !this.state.defaultValue) {
      output = this.state.nonEditableDefaultValue;
    }

    if (this.state.customLayout && this.state.useCustomLayout == true) {
      this.state.customLayout = this.state.customLayout.bind(this);
      return this.state.customLayout({ control: output, object: this });
    }

    return output;
  }

  render() {
    let output = this.state.editable ? this.editable() : this.nonEditable();

    return output;
  }
}

export default Editor;
