import React, { Component } from "react";
import Fun from "../../../lib/functions";

import Modal from "react-bootstrap/Modal";

class ModalWidget extends Component {
  state = {
    data: [],
    loaded: false,
    title: [],
    body: [],
    footer: [],
    show: false,
    size: "",
    showClose: true,
    overflowY: "auto",
  };

  constructor(props) {
    super(props);

    this.close = this.close.bind(this);
  }

  componentDidMount() {
    this.setState({ data: this.props.data, loaded: true });
  }

  close() {
    // if (this.state.onClose) {
    //   this.state.onClose();
    // } else {

    this.setState({
      title: [],
      body: [],
      footer: [],
      show: false,
      size: "",
      close: null,
      onClose: null,
      showClose: true,
      overflowY: "auto",
    });
    //}
  }

  render() {
    let output = (
      <Modal
        show={this.state.show}
        centered={true}
        backdrop="static"
        onHide={this.close}
        size={this.state.size}
        key={Fun.uuid()}
        onExit={this.close}
        onExiting={this.close}
      >
        {this.state.title ? (
          <Modal.Header>
            <Modal.Title className="mb-0 w-100 pb-1">
              {this.state.title}
            </Modal.Title>
            {/* <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={this.close}
          >
            <span aria-hidden="true" style={{ fontSize: "30px" }}>
              &times;
            </span>
          </button> */}
            {this.state.showClose ? (
              <img
                src="/uni/svg/close.svg"
                onClick={this.close}
                className="pointer"
                style={{ width: "15px", marginTop: "11px" }}
              ></img>
            ) : (
              ""
            )}
          </Modal.Header>
        ) : (
          ""
        )}
        <Modal.Body
          style={{
            maxHeight: "calc(100vh - 210px)",
            overflowY: this.state.overflowY,
          }}
        >
          {this.state.body}
        </Modal.Body>
        <Modal.Footer>{this.state.footer}</Modal.Footer>
      </Modal>
    );

    if (this.state.loaded) {
      return output;
    } else {
      return "";
    }
  }
}

export default ModalWidget;
