import React, { Component } from "react";
import Fun from "../../lib/functions";
import Builder from "../../lib/builder";
import UniFun from "./functions";
import Control from "../../themes/default/templates/control";
import { Link } from "react-router-dom";
import ProgressBarControl from "../../controls/progressbar/progressbar";
import moment from "moment";

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const UniDashboard = {
  auth: "",
  views: {
    list: {
      auth: "",
      apis: {
        view: {
          data: { data: [{}] },
          parameters: {
            headers: { "content-type": "application/json" },
          },
          formatter: function (data, builder) {
            return UniDashboardFormatter(data, builder);
          },
        },
      },
      template: "plain",
      templateConfig: {
        events: {},
        useCard: false,
        headerTitle: "Dashboard",
        headerTitleIcon: "/uni/svg/dashboard.svg",
        headerCustomClass: "pl-49px",
        colsTemplateCount: 12,
        includeProperties: [""],
        cardTemplateTop: function () {
          let data = this.state.data[0];

          return (
            <div className="row">
              <div className="col-md-4">
                {data.events.component}
                {data.templates.component}
                {!UniFun.userIsEligible({ forWhat: "module" }) &&
                !UniFun.userIsEligible({ forWhat: "member" })
                  ? data.trainings.component
                  : ""}
              </div>
              <div className="col-md-4">
                {data.files.component}
                {data.spaces.component}
                {/* {data.results.component} */}
                {/* {UniFun.userIsEligible({ forWhat: "module" })
                  ? data.trainings.component
                  : ""} */}
              </div>
              <div className="col-md-4">
                {data.profile.component}
                {data.plan.component}
              </div>
            </div>
          );
        },
        header: UniFun.commonHeader,
      },
      pk: "id",
      properties: UniDashboardProperties({ editable: false }),
    },
    plan: {
      auth: function () {
        return UniFun.userIsEligible({ forWhat: "module" });
      },
      apis: {
        view: {
          url: "/company/{id}/getInfoForDashboard",
          parameters: {
            headers: {},
            replace: {
              id: localStorage.getItem("companyId"),
            },
          },
          formatter: function (data, builder) {
            let formattedData = [];
            if (data) {
              if (data.data) {
                data.data.map((itm, idx) => {
                  let formattedRecord = {};

                  formattedRecord["plan"] = JSON.stringify(itm.plan);
                  formattedRecord["totalUsers"] = itm.totalUsers;
                  formattedRecord["currentUsers"] = itm.currentUsers;
                  formattedRecord["fileStorage"] = itm.fileStorage;
                  formattedRecord["currentFileStorage"] =
                    itm.currentFileStorage;
                  formattedRecord["currentTimeForConferences"] =
                    itm.currentTimeForConferences;
                  formattedRecord["totalTimeForConferences"] =
                    itm.totalTimeForConferences;
                  formattedRecord["currentTimeForStreaming"] =
                    itm.currentTimeForStreaming;
                  formattedRecord["totalTimeForStreaming"] =
                    itm.totalTimeForStreaming;

                  formattedData.push(formattedRecord);
                });
              }
            }

            return formattedData;
          },
        },
      },
      template: "form",
      templateConfig: {
        gridConfig: {
          cardBodyClass: "dashboard-grid p-3",
          showTableHeaders: false,
        },
        events: {},
        colsTemplateCount: 12,
        includeProperties: ["title"],
        cardTemplateTop: function () {
          let data = this.state.data[0];

          let totalUsers = parseFloat(data.totalUsers.value);
          let currentUsers = parseFloat(data.currentUsers.value);
          let fileStorage = parseInt(data.fileStorage.value);
          let currentFileStorage = parseInt(data.currentFileStorage.value);
          let currentTimeForConferences = parseInt(
            data.currentTimeForConferences.value
          );
          let totalTimeForConferences = parseInt(
            data.totalTimeForConferences.value
          );

          let currentTimeForStreaming = parseInt(
            data.currentTimeForStreaming.value
          );
          let totalTimeForStreaming = parseInt(
            data.totalTimeForStreaming.value
          );

          return (
            <React.Fragment>
              <div className="mb-2 pl-2">
                <strong className="text-grey">
                  <i
                    className="fas fa-square mr-2"
                    style={{ color: "#35A9B9" }}
                  ></i>
                  Plan
                </strong>
              </div>
              {/* <h1 className="pl-2 pb-2 mb-0 rounded-125">Overview</h1> */}
              <div>
                <div className="p-2">
                  <div className="mb-1">{data.plan.component}</div>
                </div>
                <div className="p-2">
                  <div className="mb-1">
                    <img
                      src="/uni/svg/user.svg"
                      style={{ width: "21px" }}
                      className="mr-2 filter-grey"
                    ></img>{" "}
                    {currentUsers}/{totalUsers} registered users
                  </div>
                  <ProgressBarControl
                    field={{
                      config: { max: totalUsers, min: 0, now: currentUsers },
                    }}
                    key={Fun.uuid()}
                  ></ProgressBarControl>
                </div>
                <div className="p-2">
                  <div className="mb-1">
                    <img
                      src="/uni/svg/folder_storage.svg"
                      style={{ width: "21px" }}
                      className="mr-2 filter-grey"
                    ></img>{" "}
                    {currentFileStorage}/{numberWithCommas(fileStorage)}GB
                    storage used
                  </div>
                  <ProgressBarControl
                    field={{
                      config: {
                        max: fileStorage,
                        min: 0,
                        now: currentFileStorage,
                      },
                    }}
                    key={Fun.uuid()}
                  ></ProgressBarControl>
                </div>
                <div className="p-2">
                  <div className="mb-1">
                    <img
                      src="/uni/svg/videocall.svg"
                      style={{ width: "21px" }}
                      className="mr-2 filter-grey"
                    ></img>{" "}
                    {currentTimeForConferences}/
                    {numberWithCommas(totalTimeForConferences)} hours used
                  </div>
                  <ProgressBarControl
                    field={{
                      config: {
                        max: totalTimeForConferences,
                        min: 0,
                        now: currentTimeForConferences,
                      },
                    }}
                    key={Fun.uuid()}
                  ></ProgressBarControl>
                </div>
                <div className="p-2">
                  <div className="mb-1">
                    <img
                      src="/uni/svg/stream_overiew_dashboard.svg"
                      style={{ width: "21px" }}
                      className="mr-2 filter-grey"
                    ></img>{" "}
                    {currentTimeForStreaming}/
                    {numberWithCommas(totalTimeForStreaming)} hours used
                  </div>
                  <ProgressBarControl
                    field={{
                      config: {
                        max: totalTimeForStreaming,
                        min: 0,
                        now: currentTimeForStreaming,
                      },
                    }}
                    key={Fun.uuid()}
                  ></ProgressBarControl>
                </div>
              </div>
            </React.Fragment>
          );
        },
      },
      pk: "id",
      properties: {
        title: {
          alias: "title",
          control: "text",
          editable: false,
        },
        totalUsers: {
          alias: "totalUsers",
          control: "text",
          editable: false,
        },
        currentUsers: {
          alias: "currentUsers",
          control: "text",
          editable: false,
        },
        fileStorage: {
          alias: "fileStorage",
          control: "text",
          editable: false,
        },
        currentFileStorage: {
          alias: "currentFileStorage",
          control: "text",
          editable: false,
        },
        currentTimeForConferences: {
          alias: "currentTimeForConferences",
          control: "text",
          editable: false,
        },
        totalTimeForConferences: {
          alias: "totalTimeForConferences",
          control: "text",
          editable: false,
        },
        totalTimeForStreaming: {
          alias: "totalTimeForStreaming",
          control: "text",
          editable: false,
        },
        currentTimeForStreaming: {
          alias: "currentTimeForStreaming",
          control: "text",
          editable: false,
        },
        plan: {
          alias: "plan",
          control: "text",
          editable: false,
          useCustomLayout: true,
          customLayout: function () {
            let value = this.state.defaultValue;
            let output = "";
            try {
              value = JSON.parse(value);

              output = (
                <div>
                  <span className="d-block">
                    <strong>Plan information:</strong>
                  </span>
                  <span className="d-block text-grey">
                    {value["type"]}
                    {" plan | Subscription expires on "}
                    {moment(value["expires"]).format("DD MMM YYYY")}
                  </span>
                  <span className="d-block mt-2">
                    <strong>Server location:</strong>
                  </span>
                  <span className="d-block text-grey">
                    {value["serverLocation"]}
                  </span>
                </div>
              );
            } catch (e) {}

            return output;
          },
        },
      },
    },
    spaces: {
      auth: function () {
        return true; // UniFun.userIsEligible({ forWhat: "module" });
      },
      apis: {
        view: {
          url: "/root-spaces",
          parameters: {
            headers: {},
            replace: {},
          },
          formatter: function (data, builder) {
            let formattedData = [];

            if (data) {
              if (data.data) {
                data.data.map((itm, idx) => {
                  if (idx < 5) {
                    let formattedRecord = {};

                    formattedRecord["_id"] = itm._id;
                    formattedRecord["title"] = itm.name;
                    formattedRecord["enrichedtitle"] = (
                      <Link to={"/spaces/" + itm._id + "/groups"}>
                        {itm.name}
                      </Link>
                    );

                    formattedData.push(formattedRecord);
                  }
                });
              }
            }

            return formattedData;
          },
        },
      },
      template: "grid",
      templateConfig: {
        gridConfig: {
          cardBodyClass: "dashboard-grid p-3",
          showTableHeaders: false,
        },
        events: {},
        colsTemplateCount: 12,
        includeProperties: ["enrichedtitle"],
        cardTemplateBottom: function () {
          return (
            <Link to="/spaces" className="text-primary pl-2 mt-2 d-block">
              View all
            </Link>
          );
        },
        cardTemplateTop: function () {
          return (
            <React.Fragment>
              <div className="mb-2 pl-2">
                <strong>
                  <i
                    className="fas fa-square mr-2 "
                    style={{ color: "#63B935" }}
                  ></i>
                  Spaces
                </strong>
              </div>
              <h1 className="pl-2 pb-2 mb-0 rounded-125">
                {this.state.rawData.data.length}
              </h1>
            </React.Fragment>
          );
        },
      },
      pk: "id",
      properties: {
        title: {
          alias: "title",
          control: "text",
          editable: false,
        },
        enrichedtitle: {
          alias: "enrichedtitle",
          control: "text",
          editable: false,
        },
      },
    },
    files: {
      auth: "",
      apis: {
        view: {
          url: "/files/dashboard/info",
          parameters: {
            headers: {},
            replace: {},
          },
          formatter: function (data, builder) {
            let formattedData = [];
            if (data) {
              if (data.data) {
                let sum =
                  parseFloat(data.data[0].ownedByMeFiles) +
                  parseFloat(data.data[0].libraryFilesCount);
                formattedData.push({
                  title: data.data[0].ownedByMeFiles + " personal files",
                  sum: sum,
                  enrichedtitle: (
                    <Link to="/files/ownedbyme">
                      {data.data[0].ownedByMeFiles + " personal files"}
                    </Link>
                  ),
                });
                formattedData.push({
                  title: data.data[0].libraryFilesCount + " company files",
                  sum: sum,
                  enrichedtitle: (
                    <Link to="/files/library">
                      {data.data[0].libraryFilesCount + " company files"}
                    </Link>
                  ),
                });
                formattedData.push({
                  title: data.data[0].favoriteFiles + " favorite files",
                  sum: sum,
                  enrichedtitle: (
                    <Link to="/files/favorites">
                      {data.data[0].favoriteFiles + " favorite files"}
                    </Link>
                  ),
                });
              }
            }

            return formattedData;
          },
        },
      },
      template: "grid",
      templateConfig: {
        gridConfig: {
          cardBodyClass: "dashboard-grid p-3",
          showTableHeaders: false,
        },
        events: {},
        colsTemplateCount: 12,
        includeProperties: ["enrichedtitle"],
        cardTemplateTop: function () {
          return (
            <React.Fragment>
              <div className="mb-2 pl-2 text-grey">
                <strong>
                  <i
                    className="fas fa-square mr-2 "
                    style={{ color: "#644986" }}
                  ></i>
                  Library
                </strong>
              </div>
              <h1 className="pl-2 pb-2 mb-0 rounded-125">
                {this.state.data[0].sum.value}
              </h1>
            </React.Fragment>
          );
        },
      },
      pk: "id",
      properties: {
        sum: {
          alias: "sum",
          control: "text",
          editable: false,
        },
        title: {
          alias: "title",
          control: "text",
          editable: false,
        },
        enrichedtitle: {
          alias: "enrichedtitle",
          control: "text",
          editable: false,
        },
      },
    },
    templates: {
      auth: function () {
        return UniFun.userIsEligible({ forWhat: "module" });
      },
      apis: {
        view: {
          url: "/template/getInfoForDashboard",
          parameters: {
            headers: {},
            replace: {},
          },
          formatter: function (data, builder) {
            let formattedData = [];
            if (data) {
              if (data.data) {
                let sum =
                  parseFloat(data.data[0].trainingTemplateCount) +
                  parseFloat(data.data[0].examTemplatesCount);

                formattedData.push({
                  title:
                    data.data[0].trainingTemplateCount + " training templates",
                  sum: sum,
                  enrichedtitle: (
                    <Link to="/templates/trainings">
                      {data.data[0].trainingTemplateCount +
                        " training templates"}
                    </Link>
                  ),
                });
                formattedData.push({
                  title: data.data[0].examTemplatesCount + " test templates",
                  sum: sum,
                  enrichedtitle: (
                    <Link to="/templates/tests">
                      {data.data[0].examTemplatesCount + " test templates"}
                    </Link>
                  ),
                });
              }
            }

            return formattedData;
          },
        },
      },
      template: "grid",
      templateConfig: {
        gridConfig: {
          cardBodyClass: "dashboard-grid p-3",
          showTableHeaders: false,
        },
        events: {},
        colsTemplateCount: 12,
        includeProperties: ["enrichedtitle"],
        cardTemplateTop: function () {
          return (
            <React.Fragment>
              <div className="mb-2 pl-2 text-grey">
                <strong>
                  <i
                    className="fas fa-square mr-2 "
                    style={{ color: "#B935A8" }}
                  ></i>
                  Templates
                </strong>
              </div>
              <h1 className="pl-2 pb-2 mb-0 rounded-125">
                {this.state.data[0].sum.value}
              </h1>
            </React.Fragment>
          );
        },
      },
      pk: "id",
      properties: {
        sum: {
          alias: "sum",
          control: "text",
          editable: false,
        },
        title: {
          alias: "title",
          control: "text",
          editable: false,
        },
        enrichedtitle: {
          alias: "enrichedtitle",
          control: "text",
          editable: false,
        },
      },
    },
    results: {
      auth: function () {
        return UniFun.userIsEligible({ forWhat: "member" });
      },
      apis: {
        view: {
          url: "/results/dashboard/info",
          parameters: {
            headers: {},
            replace: {},
          },
          formatter: function (data, builder) {
            let formattedData = [];
            if (data) {
              if (data.data) {
                formattedData.push({
                  title: "Trainings",
                  sum: data.data[0].results,
                  enrichedtitle: (
                    <Link to="/testresults/trainings">Trainings</Link>
                  ),
                });
                formattedData.push({
                  title: "Other tests",
                  sum: data.data[0].results,
                  enrichedtitle: (
                    <Link to="/testresults/others">Other tests</Link>
                  ),
                });
              }
            }

            return formattedData;
          },
        },
      },
      template: "grid",
      templateConfig: {
        gridConfig: {
          cardBodyClass: "dashboard-grid p-3",
          showTableHeaders: false,
        },
        events: {},
        colsTemplateCount: 12,
        includeProperties: ["enrichedtitle"],
        cardTemplateTop: function () {
          return (
            <React.Fragment>
              <div className="mb-2 pl-2 text-grey">
                <strong>
                  <i
                    className="fas fa-square mr-2 "
                    style={{ color: "#B935A8" }}
                  ></i>
                  Test results
                </strong>
              </div>
              <h1 className="pl-2 pb-2 mb-0 rounded-125">
                {this.state.data[0].sum.value}
              </h1>
            </React.Fragment>
          );
        },
      },
      pk: "id",
      properties: {
        sum: {
          alias: "sum",
          control: "text",
          editable: false,
        },
        title: {
          alias: "title",
          control: "text",
          editable: false,
        },
        enrichedtitle: {
          alias: "enrichedtitle",
          control: "text",
          editable: false,
        },
      },
    },
    events: {
      auth: "",
      apis: {
        view: {
          // url: "/event/all?&filters={filters}",
          url: '/event/all?filters=[{"operator":"is","key":"event","value":"today"}]',
          parameters: {
            headers: {},
            replace: {
              filters: function () {
                let filter = [
                  { value: "exams,trainings,meetings" },
                  {
                    key: "to",
                    operator: "gt",
                    value: moment().startOf("day").toString(),
                  },
                  {
                    key: "to",
                    operator: "lt",
                    value: moment().endOf("day").toString(),
                  },
                ];

                return JSON.stringify(filter);
              },
            },
          },
          formatter: function (data, builder) {
            let formattedData = [];

            if (data) {
              if (data.data) {
                data.data.map((itm, idx) => {
                  if (idx < 5) {
                    let formattedRecord = {};

                    let actionIcon = "";
                    let moreLink = "";
                    let eventType = "";
                    let hex = "";
                    switch (itm.type) {
                      case "exam":
                        actionIcon = "/uni/svg/test.svg";
                        hex = "#4f93a2";
                        if (itm.training) {
                          hex = "#3775d2";
                        }
                        moreLink = itm.training
                          ? "/event/training/" +
                            itm.training._id +
                            "/module/" +
                            itm.module._id
                          : "/event/test/" + itm._id;
                        eventType = "Test";
                        break;

                      case "conference":
                        actionIcon = "/uni/svg/joinvideocall.svg";
                        hex = "#3775d2";
                        moreLink = itm.training._id
                          ? "/event/training/" +
                            itm.training._id +
                            "/module/" +
                            itm.module._id
                          : "";
                        eventType = "Training";
                        break;

                      case "meeting":
                        actionIcon = "/uni/svg/joinvideocall.svg";
                        eventType = "Meeting";
                        hex = "#744fa2";
                        moreLink = itm.training
                          ? "/event/training/" +
                            data.data.training._id +
                            "/module/" +
                            data.data.module._id
                          : "/event/meeting/" + itm._id;
                        break;

                      case "stream":
                        actionIcon = "/uni/svg/stream_overiew_dashboard.svg";
                        eventType = "Stream";
                        hex = "#A2954F";
                        moreLink = "/conference/" + itm._id;
                        break;
                    }

                    formattedRecord["id"] = itm._id;
                    formattedRecord["title"] = itm.title;
                    //itm["event"]["data"] = itm;

                    let event = { ...itm };
                    event["id"] = itm._id;
                    event["eventType"] = eventType;
                    event["moreLink"] = moreLink;

                    event["data"] = { ...itm };
                    event["data"]["id"] = itm._id;
                    event["data"]["eventType"] = eventType;
                    event["data"]["moreLink"] = moreLink;
                    event["data"]["start"] = itm.from;
                    event["data"]["end"] = itm.to;
                    event["data"]["hex"] = hex;

                    event["hex"] = hex;
                    event["start"] = itm.from;
                    event["end"] = itm.to;
                    formattedRecord["enrichedtitle"] = (
                      <a
                        href="javascript:void(0);"
                        onClick={(e) => UniFun.UniAgendaSelectEvent(event)}
                      >
                        <span className="pointer text-dark">
                          <img
                            src={actionIcon}
                            style={{ width: "21px" }}
                            className="mr-2 filter-grey"
                          ></img>
                          {itm.title}
                        </span>
                      </a>
                    );

                    formattedData.push(formattedRecord);
                  }
                });
              }
            }

            return formattedData;
          },
        },
      },
      template: "grid",
      templateConfig: {
        gridConfig: {
          cardBodyClass: "dashboard-grid p-3",
          showTableHeaders: false,
        },
        events: {},
        colsTemplateCount: 12,
        includeProperties: ["enrichedtitle"],
        cardTemplateBottom: function () {
          return (
            <Link
              to="/agenda/overview"
              className="text-primary pl-2 mt-2 d-block"
            >
              View all
            </Link>
          );
        },
        cardTemplateTop: function () {
          return (
            <React.Fragment>
              <div className="mb-2 pl-2 text-grey">
                <strong>
                  <i
                    className="fas fa-square mr-2 "
                    style={{ color: "#4F5EA2" }}
                  ></i>
                  Today's events
                </strong>
              </div>
              <h1 className="pl-2 pb-2 mb-0 rounded-125">
                {this.state.rawData.data.length}
              </h1>
            </React.Fragment>
          );
        },
      },
      pk: "id",
      properties: {
        id: {
          alias: "id",
          control: "text",
          editable: false,
        },
        title: {
          alias: "title",
          control: "text",
          editable: false,
        },
        enrichedtitle: {
          alias: "enrichedtitle",
          control: "text",
          editable: false,
        },
      },
    },
    profile: {
      auth: "",
      apis: {
        view: {
          data: { data: [{}] },
          parameters: {
            headers: {},
            replace: {},
          },
          formatter: function (data, builder) {
            let formattedData = [];

            if (data) {
              if (data.data) {
              }
            }

            return formattedData;
          },
        },
      },
      template: "form",
      templateConfig: {
        events: {},
        colsTemplateCount: 12,
        includeProperties: ["profile"],
        cardTemplateTop: function () {
          let profile = {
            firstName: localStorage.getItem("firstName"),
            lastName: localStorage.getItem("lastName"),
          };

          let avatarClass = "avatar-default pointer";
          if (
            localStorage.getItem("profileImage") &&
            localStorage.getItem("profileImage") !== "undefined"
          ) {
            profile["profileImage"] = localStorage.getItem("profileImage");

            avatarClass = "avatar-default pointer";
          }

          let output = UniFun.createProfileImageBubble([profile], avatarClass);

          return (
            <React.Fragment>
              <div className="mb-4 pl-2 text-grey">
                <strong>
                  <i
                    className="fas fa-square mr-2 "
                    style={{ color: "#CCC23C" }}
                  ></i>
                  Profile
                </strong>
              </div>
              <div
                className="pl-2 pb-2 mb-0 rounded-125 mt-2 pointer"
                onClick={(e) => UniFun.myProfileDetails()}
              >
                {output}
              </div>
            </React.Fragment>
          );
        },
      },
      pk: "id",
      properties: {
        id: {
          alias: "id",
          control: "text",
          editable: false,
        },
      },
    },
    trainings: {
      auth: "",
      apis: {
        view: {
          // url: "/event/all?&filters={filters}",
          url: "/event/training/list/all",
          parameters: {
            headers: {},
            replace: {},
          },
          formatter: function (data, builder) {
            let formattedData = [];

            if (data) {
              if (data.data) {
                data.data.map((itm, idx) => {
                  if (idx < 5) {
                    let formattedRecord = {};

                    formattedRecord["enrichedtitle"] = (
                      <Link to={"/event/training/" + itm._id}>
                        <span className="pointer text-dark">{itm.title}</span>
                      </Link>
                    );

                    formattedData.push(formattedRecord);
                  }
                });
              }
            }

            return formattedData;
          },
        },
      },
      template: "grid",
      templateConfig: {
        gridConfig: {
          cardBodyClass: "dashboard-grid p-3",
          showTableHeaders: false,
        },
        events: {},
        colsTemplateCount: 12,
        includeProperties: ["enrichedtitle"],
        cardTemplateBottom: function () {
          return (
            <Link
              to="/agenda/list/trainings"
              className="text-primary pl-2 mt-2 d-block"
            >
              View all
            </Link>
          );
        },
        cardTemplateTop: function () {
          return (
            <React.Fragment>
              <div className="mb-2 pl-2 text-grey">
                <strong>
                  <i
                    className="fas fa-square mr-2 "
                    style={{ color: "#3C90CC" }}
                  ></i>
                  Trainings
                </strong>
              </div>
              <h1 className="pl-2 pb-2 mb-0 rounded-125">
                {this.state.rawData.data.length}
              </h1>
            </React.Fragment>
          );
        },
      },
      pk: "id",
      properties: {
        id: {
          alias: "id",
          control: "text",
          editable: false,
        },
        title: {
          alias: "title",
          control: "text",
          editable: false,
        },
        enrichedtitle: {
          alias: "enrichedtitle",
          control: "text",
          editable: false,
        },
      },
    },
  },
};

function UniDashboardFormatter(data) {
  let formattedData = [];
  if (data) {
    if (data.data) {
      data.data.map((itm, idx) => {
        let formattedRecord = {};
        formattedRecord["events"] = itm.id;
        formattedRecord["templates"] = itm.id;
        formattedData.push(formattedRecord);
      });
    }
  }
  return formattedData;
}

function UniDashboardProperties(args) {
  let editable = args.editable;

  return {
    events: {
      alias: "events",
      control: "module",
      editable: editable,
      config: {
        module: "unidashboard",
        view: "events",
      },
      label: "",
      attributes: {
        className: "form-control",
      },
    },
    templates: {
      alias: "templates",
      control: "module",
      editable: editable,
      config: {
        module: "unidashboard",
        view: "templates",
      },
      label: "",
      attributes: {
        className: "form-control",
      },
    },
    spaces: {
      alias: "spaces",
      control: "module",
      editable: editable,
      config: {
        module: "unidashboard",
        view: "spaces",
      },
      label: "",
      attributes: {
        className: "form-control",
      },
    },
    results: {
      alias: "results",
      control: "module",
      editable: editable,
      config: {
        module: "unidashboard",
        view: "results",
      },
      label: "",
      attributes: {
        className: "form-control",
      },
    },
    plan: {
      alias: "plan",
      control: "module",
      editable: editable,
      config: {
        module: "unidashboard",
        view: "plan",
      },
      label: "",
      attributes: {
        className: "form-control",
      },
    },
    profile: {
      alias: "profile",
      control: "module",
      editable: editable,
      config: {
        module: "unidashboard",
        view: "profile",
      },
      label: "",
      attributes: {
        className: "form-control",
      },
    },
    trainings: {
      alias: "trainings",
      control: "module",
      editable: editable,
      config: {
        module: "unidashboard",
        view: "trainings",
      },
      label: "",
      attributes: {
        className: "form-control",
      },
    },
    files: {
      alias: "files",
      control: "module",
      editable: editable,
      config: {
        module: "unidashboard",
        view: "files",
      },
      label: "",
      attributes: {
        className: "form-control",
      },
    },
  };
}

export default UniDashboard;
